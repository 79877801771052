/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Popup} from 'semantic-ui-react';


const TimeSortButton = ({ sortOrder, sortField, updateSortOrder, color }) => {

  return (
    <Popup
    content="This option sorts records either by oldest or newest, placing the selected option at the top of the list."
    on="hover"
    mouseEnterDelay={500}
    trigger ={<div
      css={{
        cursor: 'pointer',
        userSelect: 'none', 
        // marginRight: '60px',
        width:'max-content',
        marginBottom:'1px',
        whiteSpace:'nowrap',
        '&:hover': {
          '& .oldest': {
            color: `${color}80`,
            backgroundColor: 'white',
            // borderRight:`1px solid ${color}`
          },
          '& .newest' : {
            color: `${color}80`,
            backgroundColor: 'white',
            // borderLeft:`1px solid ${color}`
          }
        },
      }}
      className="row"
    >
      <div
       onClick={() => updateSortOrder('asc')}
        className="oldest"
        css={{
          fontWeight: 'bold',
          fontSize:'13px',
          backgroundColor: sortField === 'id' && sortOrder === 'asc' ? color : 'white',
          borderTopLeftRadius:'4px',
          borderBottomLeftRadius:'4px',
          borderRight:  sortField !=='id' ? `1px solid ${color}` :null,
          paddingLeft:'10px',
          paddingRight:'5px',
          height:'35px',
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          color: sortField === 'id' && sortOrder === 'asc' ? 'white' : '#1A1A1A',
          borderTop:`1px solid ${color}`,
          borderBottom: `1px solid ${color}`,
          borderLeft:`1px solid ${color}`,
          '&:hover': {
            color: `${color}`,
            backgroundColor: 'white',
          }
        }}
      >
        Oldest &nbsp;
      </div>
      <div
       onClick={() => updateSortOrder('desc')}
        className="newest"
        css={{
          fontWeight: 'bold',
          fontSize:'13px',
          backgroundColor: sortField === 'id' && sortOrder === 'desc' ? color : 'white',
          color: sortField === 'id' && sortOrder === 'desc' ? 'white' : '#1A1A1A',
          paddingRight:'10px',
          paddingLeft:'5px',
          height:'35px',
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          borderTopRightRadius:'4px',
          borderBottomRightRadius:'4px',
          borderTop:`1px solid ${color}`,
          borderBottom: `1px solid ${color}`,
          borderRight:`1px solid ${color}`
        }}
      >
        Newest
      </div>
    </div>}
    />
  );
};
export default TimeSortButton;
