/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { mdiChatProcessing,mdiEmail } from '@mdi/js';
import TopNavBar from './TopNavBar';
import Messages from '../../MessageView/Messages';
import EmailMessages from '../../MessageView/EmailMessages';
import { addrelationalvalue } from '../../../../../api/crud';
import { addorupdateValues } from '../../../../../api/crud';
import { AccountContext } from '../../../../../providers/AccountProvider';
import DataList from '../../DataList/DataList';
import ActionButton from '../../ActionBar/ActionButton';
import { GetRelatedTablesInfo, updateTableInfoAPI } from '../../../../../api/tables';
import ControlList from '../../Detailview/ControlList';
import CollaboratorMessages from '../../MessageView/CollaboratorMessages';
import History from '../../Detailview/History';
import UpdateRecord from '../../../../../api/updateRecord';
import Global from '../../../../../clients/global'

const RightNav = (props) => {
  const { color, tableid, currentRecord, tableinfo, role, id, blockid,isExpanded,showBookmarkBar } = props;
  const [control, setControl] = useState('messages');
  const [items, setItems] = useState([]);
  const [selectedTable, setSelectedTable] = useState(0);
  const [relationalItems, setRelationalItems] = useState([]);
  const { userInfo } = useContext(AccountContext);
  const schema = `schema_${Global.zoneid}`;
  const [displayMode, setDisplayMode] = useState('review');
  const [isLoading, setIsLoading] = useState(false);
  const [changes, setChanges] = useState([]);
  const [index, setIndex] = useState(Date.now()); // we need a way to trigger controlist to update. When changes are complete, we update this value.
  const [dataItem, setDataItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [messageControl,setMessageControl] =useState(null);
  const [toAddress,setToAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    if (currentRecord !== '') {
      fetchData();
    }
  }, [id ]); //selectedTable

  const fetchData = async () => {
    setIsLoading(true);
    const dItem = {
      tableinfo: tableinfo,
      tableid: tableid,
      id: currentRecord.id,
      selectedRow: currentRecord,
    };


    const data = await GetRelatedTablesInfo(schema, id, dItem, displayMode,role,userInfo.blocks);
    /* date returns array of all relational tables with the following fields
    name, 
    field, 
    returnfield, 
    blockid(source.blockid), 
    tableid (source.lookuptableid), 
    jointable, 
    lookupfield,
    lookup,
    icon,
    count, 
    tableinfo(source/relational),
    filters,
    useSecurity
    */

     //6-15-23 check of table has email field setup for email delivery. If yes, get the current
    //to address from row, which is needed to pass to EmailMessages component. 
    let emailValue=null;
    let msgControl=null;
    let phoneValue=null;

    const emailIdx = dItem.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages);
    const textIdx = dItem.tableinfo.columns.findIndex(col=>col.uitype===12);

    if(emailIdx !==-1) {
      if(currentRecord[dItem.tableinfo.columns[emailIdx].data] !==null) {
          emailValue = currentRecord[dItem.tableinfo.columns[emailIdx].data];
      } else if(dItem.tableinfo.columns[emailIdx].alternateEmailField !==undefined) {
          emailValue = currentRecord[dItem.tableinfo.columns[emailIdx].alternateEmailField];
          if(typeof emailValue==='object' && emailValue !==null) {
            emailValue=emailValue[0].data
          }
      }
  }

    if(textIdx !==-1) {
      const col = dItem.tableinfo.columns[textIdx];
      const lookupNumber =currentRecord[col.phonefield] ?? currentRecord[col.altPhoneField] 
      if(Array.isArray(lookupNumber)){
         phoneValue = lookupNumber[0].data;
      } else {
          phoneValue = lookupNumber;
      }
    }

    //Set default messages control 
    if(userInfo.defaultMessageControl ==='text' && textIdx !==-1) {
      msgControl='text'
    }  else if(userInfo.defaultMessageControl ==='email' && emailIdx !==-1) {
      msgControl='email'
    } else if(emailIdx !==-1){
      msgControl='email'
    } else if(textIdx !==-1) {
      msgControl='text'
    }

    setItems(data);
    setIsLoading(false);
    setDataItem(dItem);
    setToAddress(emailValue);
    setPhoneNumber(phoneValue);
    setMessageControl(msgControl);
  };

  const changeRelatedTable = (newindex) => {
    setIsLoading(true);
    setSelectedTable(newindex);
    setIsLoading(false);
  };

  const AddItem = async (newid, field,isNewRecord) => {

    const tempitems = [...relationalItems];

    if (items[selectedTable].lookup === 'multiple') {
      tempitems.push({ id: parseInt(newid), data: field, type: 0 });
      setRelationalItems(tempitems);
      if(isNewRecord) {
        await ApplyChanges(tempitems);
      }
    }
  };

  const RemoveItem = async (newid, mode, field) => {
    const mainItem = items[selectedTable];

    const item = [{ id: newid, data: field, type: -1 }];
    await addrelationalvalue(
      dataItem.id,
      `tbl_${dataItem.tableid}`,
      mainItem.field,
      item,
      '',
      `tbl_${mainItem.tableid}`,
      mainItem.jointable,
      mainItem.name,
      mainItem.returnfield,
      dataItem.tableinfo,
    );

    const tempItems = [...items];
    tempItems[selectedTable].count = tempItems[selectedTable].count - 1;
    setItems(tempItems);
  };

  const ApplyChanges = async (newItem=null) => {
    
    if(newItem===null && relationalItems.length===0) {
      return false;
    }

    const mainItem = items[selectedTable];
    const tempItems = [...items];
    tempItems[selectedTable].count = parseInt(tempItems[selectedTable].count) + relationalItems.length;
    
    const finalItems = newItem ?? relationalItems

    await addrelationalvalue(
      dataItem.id,
      `tbl_${dataItem.tableid}`,
      mainItem.field,
      finalItems,
      '',
      `tbl_${mainItem.tableid}`,
      mainItem.jointable,
      mainItem.name,
      mainItem.returnfield,
      dataItem.tableinfo,
    );

    setRelationalItems([]);
    setItems(tempItems);
  };

  const ChangeDisplayMode = () => {
    setDisplayMode('review');
  };

  const ChangeMessageMode =(control)=> {
    userInfo.defaultMessageControl = control;
    setMessageControl(control)
  }

  const updateValue = (field, value, uitype = null) => {
    const tempChanges = { ...changes };
    tempChanges[field] = value;
    setChanges(tempChanges);

    // if(uitype===1 && value[0].type===-1){
    //   dataItem.selectedRow[field] = null;
    // } else {
    //   dataItem.selectedRow[field] = value;
    // }
  };

  const submitChanges = async () => {
    if (changes.length === 0) {
      return;
    }
    setIsLoading(true);

    const result = await UpdateRecord(changes, tableinfo, tableid, id);

    if (result === 'success') {
      Object.keys(changes).map((itm) => {
        const idx = tableinfo.columns.findIndex((col) => col.data === itm);
        if (idx !== -1) {
          if (tableinfo.columns[idx].uitype === 1 && changes[itm][0].type === -1) {
            dataItem.selectedRow[itm] = null;
          } else {
            dataItem.selectedRow[itm] = changes[itm];
          }
        }
      });

      setChanges([]);
      setIndex(Date.now());
    }

    setIsLoading(false);

    // if (changes.length === 0) {
    //   return;
    // }
    // setIsLoading(true)

    // const table = `tbl_${dataItem.tableid}`;
    // const field = Object.keys(changes)[0];
    // const newvalue = Object.values(changes)[0];
    // const colInfo = dataItem.tableinfo.columns.filter((itm) => itm.data === field)[0];

    // let jointable = colInfo.source !== undefined ? colInfo.source.jointable : '';
    // let lookuptable = colInfo.source !== undefined ? colInfo.source.lookuptable : '';
    // let lookup = colInfo.lookup !== undefined ? colInfo.lookup : 'text';

    // await addorupdateValues(
    //   id,
    //   table,
    //   field,
    //   newvalue,
    //   null,
    //   null,
    //   null,
    //   lookup,
    //   colInfo.jointype,
    //   lookuptable,
    //   jointable,
    //   schema,
    //   userInfo.id,
    //   colInfo.uitype,
    //   null,
    //   null,
    //   false,
    //   colInfo,
    //   dataItem.tableinfo
    // );

    // setChanges([]);
    // setIndex(Date.now());
    // setIsLoading(false)
  };

  const updateTable = async (tableid, fullList) => {
    const fields = fullList.filter((itm) => itm.data !== undefined);
    const sections = fullList.filter((itm) => itm.name !== undefined); // we expect sections to have names but column/fields to not have tihs attribute

    // const currentTable = JSON.parse(JSON.stringify(dataItem.tableinfo));

    dataItem.tableinfo.columns.forEach((itm) => {
      const rowindex = fields.findIndex((field) => field.data === itm.data);
      itm.detailviewsort = rowindex;
    });

    dataItem.tableinfo.sections = sections;
    // dataItem.tableinfo = currentTable;
    setDataItem(dataItem);
    await updateTableInfoAPI(tableid, dataItem.tableinfo);
  };

  const updateSectionName = async (sectionIndex, value) => {
    const tempChanges = { ...changes };
    tempChanges[sectionIndex] = value;
    setChanges(tempChanges);
  };

  const submitSectionName = async (sectionNumber) => {
    if (changes.length === 0) {
      return;
    }

    const tableinfo = JSON.parse(JSON.stringify(dataItem.tableinfo));
    tableinfo.sections[sectionNumber - 1].name = Object.values(changes)[0];
    await updateTableInfoAPI(dataItem.tableid, tableinfo);
    dataItem.tableinfo = tableinfo;
    setDataItem(dataItem);
    setChanges([]);
    setIndex(Date.now());
  };

  const deleteSection = async (sectionNumber) => {
    const tableinfo = dataItem.tableinfo;

    tableinfo.sections.splice(sectionNumber - 1, 1);
    const tableid = dataItem.tableid;
    await updateTableInfoAPI(tableid, tableinfo);
    dataItem.tableinfo = tableinfo;
    setDataItem(dataItem);
    setIndex(Date.now());
  };

  const addSection = async () => {
    const tableinfo = JSON.parse(JSON.stringify(dataItem.tableinfo));
    const section = {
      name: `New section - ${tableinfo.sections.length + 1}`,
      count: 0,
      display: true,
      collapse: false,
      insertAt: tableinfo.columns.length + tableinfo.sections.length,
      sectionNumber: tableinfo.sections.length,
    };
    tableinfo.sections.push(section);
    await updateTableInfoAPI(dataItem.tableid, tableinfo);
    dataItem.tableinfo = tableinfo;
    setDataItem(dataItem);
    setIndex(Date.now());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'flex-start',
        width: `calc(100vw - ${isExpanded ? '765px' : '580px'})`,
        // height:'100%',
        // width:'100%',
        height: !showBookmarkBar ? 'calc(100vh - 160px)' : 'calc(100vh - 185px)',
        overflow: 'auto',
      }}
    >
    
      <TopNavBar
        color={color}
        setControl={setControl}
        control={control}
        hasRelatedRecords={items.length > 0}
      />

      {control === 'datalist' || control==='messages' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '10px',
            paddingTop: '5px',
            alignItems: 'center',
            backgroundColor: '#F1EFEF',
            height: '50px',
            width: '100%',
          }}
        >
          {items.length > 0 && control === 'datalist'
            ? items.map((itm, index) => (
                <ActionButton
                  key={index}
                  label={`${itm.name} (${itm.count})`}
                  actionIcon={itm.icon}
                  Action={changeRelatedTable}
                  ActionValue={index}
                  color={color}
                  showBottom={true}
                  isSelected={index === selectedTable}
                />
              ))
            : null}

            {control==='messages' ? (<>
                        {dataItem && dataItem?.tableinfo.columns.findIndex(col=>col.uitype===12) !==-1 ?(
                            <ActionButton height="50px" key={'text'} label={'Text'} actionIcon={mdiChatProcessing} Action={ChangeMessageMode} ActionValue={'text'} color={color} showBottom={true} isSelected={messageControl==='text'}/>
                        ):null}
                        {dataItem && dataItem?.tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages) !==-1 ? (
                            <ActionButton height="50px" key={'email'} label={'Email'} actionIcon={mdiEmail} Action={ChangeMessageMode} ActionValue={'email'} color={color} showBottom={true} isSelected={messageControl==='email'} />
                        ):null}
                </>
              ): null}

        </div>
      ) : null}

      {control === 'messages' && currentRecord !== ''  && messageControl==='text' ? (
        <Messages
          color={color}
          tableid={tableid}
          dataID={currentRecord.id}
          number={phoneNumber}
          tableinfo={tableinfo}
          RID={currentRecord.CustomID}
          canMessage={true} // only used by shares
          callingComponent="chatview"
          role={role}
        />
      ) : null}

        {control==='messages' && currentRecord !== '' && messageControl==='email' ? (
           <div style={{height: 'calc(100vh - 300px)'}} >
              <EmailMessages
                 color={color}
                 tableid={dataItem.tableid}
                 dataID={dataItem.id}
                 tableinfo={dataItem.tableinfo}
                 RID={dataItem.selectedRow.CustomID}
                 callingComponent="chatview"
                 toAddress={toAddress}
                 canMessage={true}
                 role={role}
             /></div>
         ): null}

      {control === 'datalist' && currentRecord !== '' && !isLoading ? (
        <>
          <DataList
            displayMode={displayMode}
            tableid={items[selectedTable].tableid}
            sourcetableid={dataItem.tableid}
            renderfield={items[selectedTable].lookupfield}
            filters={items[selectedTable].filters}
            tableinfo={items[selectedTable].tableinfo}
            nestedFields={
              dataItem.tableinfo.columns.filter((el) => el.data === items[selectedTable].field)[0]
                .source.nestedFields
            }
            color={color}
            id={id}
            blockid={items[selectedTable].blockid}
            AddItem={AddItem}
            RemoveItem={RemoveItem}
            Apply={ApplyChanges}
            count={items[selectedTable].count}
            Close={ChangeDisplayMode}
            selectMode={items[selectedTable].lookup}
            callingComponent="detailview"
            canEdit={currentRecord.canEdit}
            loadRelatedRecord={null}
            relationalSecurity={items[selectedTable].useSecurity}
            role={role}
            newRecordOnly={items[selectedTable].isSubTable}
          />
        </>
      ) : null}
      {control === 'details' && !isLoading && id !== undefined ? (
        <ControlList
          dataItem={dataItem}
          role={role}
          color={color}
          updateValue={updateValue}
          updateTable={updateTable}
          updateSectionName={updateSectionName}
          submitSectionName={submitSectionName}
          deleteSection={deleteSection}
          tableid={tableid}
          addSection={addSection}
          submitChanges={submitChanges}
          index={index}
          setIsEditMode={setIsEditMode}
        />
      ) : null}
      {control === 'collaborators' && !isLoading && id !== undefined ? (
        <CollaboratorMessages
          color={color}
          tableid={tableid}
          RID={currentRecord.CustomID}
          recordName={dataItem.tableinfo.recordname}
          dataID={currentRecord.id}
          blockid={blockid}
          canCollaborate={true} //only used by shares
          canViewComments={true} //only used by shares
          canEdit={currentRecord.canEdit} // 6-8-2022 added so that we can turn off auto-focus viewing from share.
        />
      ) : null}
      {control === 'history' && !isLoading && id !== undefined ? (
        <History 
        canUnmask={tableinfo.security ==undefined || tableinfo.security.unmask===undefined || tableinfo.security.unmask < role}
        tableinfo={tableinfo}
        color={color} 
        tableid={tableid} 
        id={currentRecord.id} />
      ) : null}
    </div>
  );
};

export default RightNav;
