/* eslint-disable */
import React, { useEffect } from 'react';

const SelectTag =({value,enableColors}) => {

    return (
    value && value.map(el=> el.data && (
        <div
        key={el.optionid}
        style={{
          width: 'max-content',
          backgroundColor: enableColors && el.attributes && el.attributes.color,
          color: enableColors && el.attributes && el.attributes.text,
          display: 'inline-flex',
          flexDirection: 'row',
          margin: '3px',
          paddingTop: '2px',
          paddingLeft: '5px',
          paddingRight: '5px',
          alignItems: 'center',
          borderRadius: '7px',
        }}
      >
        {el.data}
      </div>
     ))
    )

}


export default SelectTag