/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { mdiChevronDown, mdiChevronUp,mdiViewGridOutline } from '@mdi/js';
import Icon from '@mdi/react';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import { getBlockMetrics } from '../../api/zone';
import DougnutControl from './DougnutControl';
import { plans } from './plans';

const BlockInformation = ({ zoneInfo,toggleZoneSettings }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(
    plans.filter((itm) => itm.type === zoneInfo.plan)[0],
  );

  useEffect(() => {
    const getMetrics = async () => {
      const result = await getBlockMetrics();
      setBlocks(result);
    };
    getMetrics();
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{marginLeft:'5px'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          marginBottom:'10px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
          width: '90%',
        }}
      >
        <div style={{ marginLeft: '20px' }}>
          <TextButton
            Action={toggleExpand}
            fontSize="13px"
            icon={isExpanded ? mdiChevronUp : mdiChevronDown}
            textColor="black"
            text="Blocks"
            iconPosition="left"
          />
        </div>
        <div style={{ marginRight: '20px',display:'flex', flexDirection:'row', alignItems:'center' }}>
        <GBButton
              Action={toggleZoneSettings}
              icon={mdiViewGridOutline}
              iconHoverColor="White"
              width="130px"
              borderRadius="10px"
              iconHoverBackgroundColor="#757575"
              textColor="#757575"
              hoverBorder="2px solid #757575"
              textHoverColor="#75757580"
              iconPosition="left"
              text="View Blocks"
            />

        </div>
      </div>
      {isExpanded ? (
      <div style={{ overflow: 'auto', height: 'calc(100vh - 220px)',marginLeft:'25px' }}>
        {blocks.length > 0
          ? blocks.map((itm) => (
              <div key={itm.blockid} style={{ marginBottom:'10px',display: 'flex', flexDirection: 'row', alignItems: 'flex-start',justifyContent:'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '75px',
                      height: '75px',
                      backgroundColor: itm.color,
                      borderRadius:'10px'
                    }}
                  >
                    <Icon path={itm.icon} size="60px" color="white" />
                  </div>
                  <div style={{width:'100px'}}>{itm.name}</div>
                </div>
                <div style={{ width: '90px', height: '75px',marginLeft:'5px' }}>
                  <DougnutControl
                    fontSize="10px"
                    label="Records"
                    primaryColor="#707070"
                    secondaryColor={itm.color}
                    labelWidth="90px"
                    currentValue={itm.rows}
                    totalMetric={currentPlan.records}
                  />
                </div>
                <div style={{ width: '100px', height: '75px',marginLeft:'5px' }}>
                  <DougnutControl
                   fontSize="10px"
                    label="Tabs"
                    primaryColor="#707070"
                    secondaryColor={itm.color}
                    labelWidth="100px"
                    currentValue={itm.tabs}
                    totalMetric={currentPlan.tabs}
                  />
                </div>
              </div>
            ))
          : null}
      </div>
      ): null}
    </div>
  );
};

export default BlockInformation;
