/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/core'
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import { Icon,Dropdown,Input } from 'semantic-ui-react';
import {getOptionsAPI} from '../../../../api/options'
import GBDropdownComponent from '../../../../components/GBDropdown/GBDropDownasComponent';

class MultipleEditor extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      renderResult: null,
      value: null,
      selectedItems: [],
      displayfield: '',
      workingItems: [],
      source:'',
      selectmode: '',
      tableid:0,
      uitype: '',
      left: 20,
      top: 30,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
      startingWidth:0,
      // height: ,
      isExpanded: false,
      display: 'none',
      hasData: false,
      isChanged: false,
      expandDown:true,
      val:null
    };
  }

  fetchTableData = async (field,tableid ) => {
    if(!this.state.hasData){
      const result = await getOptionsAPI(field,tableid,'list');
      this.setState({ field: result,hasData:true });
    }
  };


  displayOptions = () => {
    const {val,value} = this.state;
    let textOptions=[];

    this.state.field?.map(itm=>{
      const control=<div style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',whiteSpace:'wrap',maxWidth:'max-content'}}>{itm.option}</div>
      textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
    })
    return textOptions
  }

  renderLabel = (label) => {
    const { value, text } = label;

    const customLabel = (
      <div 
      style={{
        // backgroundColor: 'blue',
        // width:'50px',
        // overflow:'hidden',
        padding: '1px',
        color: 'white',
        borderRadius: '7px',
        display: 'inline-flex', // Use 'inline-flex' to make sure the label and the remove icon are in the same line
        alignItems: 'center', // Center items vertically
        marginRight: '8px', // Add some space between labels
      }}>
        {text}
      </div>
    );

    return {
      color: 'white',
      content: customLabel
    };

  }


  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
    // e.preventDefault();
  }

  stopKeydownPropagation(e) {
    e.stopImmediatePropagation();
    // e.preventDefault();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }


  getValue() {
    const {selectmode,val,value} = this.state;
    return  value 
  }


  open() {
    this.setState({ display: 'block' });
  }

  // expand =(isExpanded,isCancel) => {
   
  //   this.setState({isExpanded, width: isExpanded ? '250px' : this.state.startingWidth})
  //   if(!isExpanded){
  //     if(isCancel){
  //       this.props.Cancel()
  //     } 
  //   }
  // }

  close= ()=> {

    this.setState({
      pickedColor: null,
      dataID: 0,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: [],
      workingItems: [],
      value: null,
      val:null
    });

    // this.finishEditing();

  }


  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const item = cellProperties.data;
    const tdPosition = td.getBoundingClientRect();
    const halfHeight = window.innerHeight*.5
    const expandDown = tdPosition.y<halfHeight;

    if (this.state[item] == null) {
      // && this.state[item] != undefined (removed, not sure why i needed it?)
      this.fetchTableData(cellProperties.data, this.props.tableid);
    } 

    let tempValue =null;
    if(cellProperties.selectmode==='single' && originalValue !==null && originalValue[0] !==undefined)  {
      tempValue= originalValue[0].optionid.toString()
    } else if(cellProperties.selectmode==='multiple' && originalValue !==null && originalValue !=='') {
      tempValue =  originalValue.map(el=>el.optionid.toString());
    }

    this.setState({
      selectedItems: originalValue,
      displayfield: cellProperties.data,
      selectmode: cellProperties.selectmode,
      enableColors:cellProperties.enableColors,
      uitype: cellProperties.uitype,
      source: cellProperties.source,
      tableid:  this.props.tableid,
      width: cellProperties.width-3,
      height: this.props.cellHeight,
      startingWidth: cellProperties.width,
      val: tempValue,
      // dataID: this.props.GetRowData(row),
      left: `${tdPosition.left+1}px`,
      top: `${tdPosition.top+2}`,
      expandDown:expandDown,
      uitype: cellProperties.uitype
    });
    

  }

  updateItems =(items) => {
    const {selectmode} = this.state;

    if(items==='' && selectmode==='single'){
      items=[];
      this.setState({value:[],val:null})
      if(selectmode==="single")
      {
        this.setState(
          () => {
            isExpanded:false
            // return { value: selectedItems };
            return this.state.value;
          },
          () => {
            this.finishEditing();
          },
        );
      }
    }


    this.setState({val:items})
    
    if(this.state.selectmode==='single') {
  
      const idx = this.state.field.findIndex(el=>el.optionid===items);
      if(idx !==-1) {
        const itm =this.state.field[idx];
        const newItem=[{data:itm.option,optionid:itm.optionid,attributes:itm.attributes}]
        this.setState({value:newItem},()=> this.finishEditing())
      }
    } else {
      const finalValues=[];
      items.map(itm=>{
        const idx = this.state.field.findIndex(el=>el.optionid===itm);
        if(idx !==-1) {
          const itm = this.state.field[idx];
          const newItem={data:itm.option,optionid:itm.optionid,attributes:itm.attributes}
          finalValues.push(newItem);
        }
      })
      this.setState({value:finalValues});
    }

  }


  searchItems =(options, query) =>{
    let textOptions=[];

    this.state.field.filter(el=>el.option.toLowerCase().startsWith(query)).map(itm=>{
      const control=<div style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',width:'max-Content'}}>{itm.option}</div>
      textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
    })
 
    return textOptions
  }

  render() {
    let renderResult = null;
    const {val,expandDown,selectmode,uitype} = this.state;

    const heightCalc =this.state.field?.length <5 ? this.state.field?.length *70 : uitype===7 ? 280 : 300

    if (this.props.isEditor && this.state.display==='block') {
      renderResult = (
        <NativeListener 
        onMouseDown={this.stopMousedownPropagation} 
        onKeyDown={this.stopKeydownPropagation}
        >
          <div
            tabIndex="0"
            css={{
              // '&& .ui.scrolling.dropdown .menu, && .ui.dropdown .scrolling.menu': {
              //   maxHeight: '800px !important', // This increases the specificity
              // },
              // '&& .ui.dropdown .menu': {
              //   maxHeight: '250px !important'
              //  },
              // '&& .ui.selection.dropdown': {
              //   backgroundColor:`${this.props.color}1A !important`,
              //  },
              position: 'absolute',
              zIndex: 2001,
              backgroundColor: 'white',
              marginLeft:'1px',
              marginRight:'1px',
              left: this.state.left,
              top: expandDown ? `${this.state.top}px` :  `${this.state.top-heightCalc}px`,
              // height: heightCalc,  //1-29-24 commentd out and removed overflow. renders propperly now
              // overflow: uitype===7 ? 'hidden' : 'auto',
              width:  this.state.width,
              display: this.state.display,
            }}
          >

           <Dropdown 
           selectOnBlur={false}
            scrolling={selectmode==='multiple'}
            multiple={selectmode==='multiple'}
            text={val===null ? 'Select an option' : null}
            key={new Date().getTime()} 
            search={this.searchItems} 
            renderLabel={this.renderLabel}
            fluid 
            clearable 
            open={true} 
            selection 
            value={Array.isArray(val) ? val.map(e=>parseInt(e)) : parseInt(val) }  
            options={this.displayOptions()} 
            onChange={(e,data)=>this.updateItems(data.value)} 
            />

           {/* <GBDropdownComponent
           source={this.state.source} 
           enableColors={this.state.enableColors}
           field={this.state.displayfield}  
           color={this.props.color}
           tableid={this.state.tableid}
           uitype={this.state.uitype}
           lookup={this.state.selectmode}
           width={this.state.width}
           expand={this.expand}
           gridExpandState={this.state.isExpanded} //this.state.isExpanded
           optionValues={selectedItems}
           callingComponent="Grid"
           updateValue={this.updateItems}
           /> */}
          </div>
        </NativeListener>
      );
    } 

    return <>{renderResult}</>;
  }
}

export default MultipleEditor;
