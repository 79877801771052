/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import classNames from 'classnames';
// import styled from 'styled-components';

const FormControl = ({
  className,
  size,
  svg,
  leftIcon,
  rightIcon,
  name,
  onRightClick,
  color,
  ...props
}) => {
  const handleFocus = (event) => event.target.select();
  return (
    <div
      className={classNames('input-group', className, {
        'with-left-icon': !!leftIcon,
        'with-right-icon': !!rightIcon,
      })}
    >
      <div className="input-group-prepend input-group-left-padding" style={{ background: color }} />
      {!!svg && <img className="input-group-left-icon" src={svg} alt="icon" />}
      {!!leftIcon && (
        <button type="button" className={classNames('left-icon')} tabIndex="-1">
          {leftIcon}
        </button>
      )}
      <BootstrapForm.Control size={size} name={name} {...props} onFocus={handleFocus} />
      {!!rightIcon && (
        <button
          type="button"
          className={classNames('right-icon', onRightClick && 'as-button')}
          onClick={onRightClick}
          tabIndex="-1"
        >
          {rightIcon}
        </button>
      )}
    </div>
  );
};

FormControl.defaultProps = {
  onRightClick: undefined,

  className: null,
  svg: null,
  leftIcon: null,
  rightIcon: null,
  size: 'lg',
  color: '#120233',
};

FormControl.propTypes = {
  onRightClick: PropTypes.func,

  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  svg: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default FormControl;
