/* eslint-disable */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Redirect,useLocation } from '@reach/router';
import { useQuery } from '@bizico/react-promise';
import firebase, { getIdTokenResult, getIdToken } from '../clients/firebase';
import Spinner from '../components/Spinner/Spinner';
import { getUserInfoAPI } from '../api/auth';
import { switchZoneAPI } from '../api/zone';
import { ROUTES } from '../constants';
import Global from '../clients/global'

const AccountContext = React.createContext();

const switchZoneAPIWrapper = (zoneID) => switchZoneAPI(zoneID).then(getIdTokenResult);

const AccountProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const [loadingInfo, setLoadingInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [userTokenResult, setUserTokenResult] = useState(null);
  const [ws, setWs] = useState(null); // WebSocket connection state
  const [messageHandlers, setMessageHandlers] = useState([]); // Array to store message handlers

  const location = useLocation();

  const [, switchToZoneAPI] = useQuery((_, zoneID) => switchZoneAPIWrapper(zoneID), {
    skip: true,
  });

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSignOut = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setUserInfo(null);
      })
      .catch((error) => {
        console.log('Sign Out Error', error);
      });
  }, [setUser, setUserInfo]);

  const handleUpdateUserInfo = useCallback(
    (v) => {
      setUserInfo(v);
    },
    [setUserInfo],
  );

  const handleUpdateUserZones = useCallback(
    (zones) => {
      setUserInfo((prev) => ({
        ...prev,
        zones,
      }));
    },
    [setUserInfo],
  );

  const handleUpdateUserZone = useCallback(
    (zone, data) => {
      setUserInfo((prev) => {
        const zones = prev.zones.map((z) => {
          if (z.id === zone.id) {
            return { ...z, ...data };
          }
          return z;
        });

        return {
          ...prev,
          zones,
        };
      });
    },
    [setUserInfo],
  );

  const handleUpdateUserTokenResult = useCallback(
    (value) => {
      setUserTokenResult((prev) => ({ ...prev, ...value }));
    },
    [setUserTokenResult],
  );

  const handleSwitchToZone = useCallback(
    (zoneId) => {
      const prevZoneId = Global.zoneid;
      setUserTokenResult((prev) => ({ ...prev, zoneId, zoneSwitching: true }));
      return switchToZoneAPI(zoneId)
        .then((value) => {
          setUserTokenResult((prev) => ({ ...prev, ...value, zoneSwitching: false }));
        })
        .catch((e) => {
          setUserTokenResult((prev) => ({ ...prev, zoneId: prevZoneId, zoneSwitching: false }));
          return Promise.reject(e);
        });
    },
    [switchToZoneAPI, userTokenResult],
  );

  const listener = useCallback(
    (u) => {
      if (u) {
        setUser(u);                

        Promise.all([getUserInfoAPI(), getIdTokenResult(), getIdToken(),delay(2000)])
          .then(([values, tokenResult]) => {
            
            /** CODE TO AUTO_LOGOUT USER IF A ZONE TIMOUT LIMIT HAS BEEN EXCEEDED  */
            
            const z = values.zones.filter((zone)=>zone.attributes.reverify).sort((a, b) => (a.attributes.reverify > b.attributes.reverify ? 1 : -1));
           if(z.length>0){
            const days = z[0].attributes.reverify;
            const lastSignInTime = new Date(u.metadata.lastSignInTime);
            const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);
            const LogOutTimeStamp = Math.round(new Date().getTime() / 1000) - (24 * days * 3600);
            if(lastSignInTimeTimeStamp < LogOutTimeStamp){
              firebase.auth().signOut();
            }
           }
           /** CODE TO AUTO_LOGOUT USER IF A ZONE TIMOUT LIMIT HAS BEEN EXCEEDED  */

          // 8/20/24 added websocket support.  
          //Establish WebSocket connection if the user is authenticated and no connection exists
          //do not try to create websocket when viewing forms/workflow or apidocs. 
          
          if (!ws && !location.pathname.includes('form') && !location.pathname.includes('workflow') && !location.pathname.includes('api') ) {
            const apiUrl =process.env.REACT_APP_API_URL;
            let wsURL = apiUrl.replace('https', 'wss');
            if (apiUrl.includes('localhost')) {
              wsURL = apiUrl.replace('http', 'ws');
            }
            wsURL =wsURL +`?uid=${values.id}`
            const newWs = new WebSocket(wsURL);

            newWs.onopen = () => {
              console.log('WebSocket connection established');
              setWs(newWs);
            };

            newWs.onmessage = (event) => {
              // const message = JSON.parse(event.data);
              console.log(event)
              const message = event.data;
              // Call all registered message handlers
              messageHandlers.forEach(handler => handler(message));
            };

            newWs.onclose = () => {
              console.log('WebSocket connection closed');
              setWs(null);
            };

            return () => {
              newWs.close();
            };
          }

            setUserInfo({ ...values });
            setUserTokenResult({ ...tokenResult });
            setLoadingInfo(false);

      

          })
          .catch((error) => {
            console.log(error)
            setLoadingInfo(false);
          });
      } else {
        setUser(null);
        setUserInfo(null);
        setUserTokenResult(null);
        setLoadingInfo(false);
        // Close the WebSocket connection if the user logs out
        if (ws) {
          ws.close();
        }
      }
      setLoading(false);
    },
    [setLoading, setLoadingInfo, setUser, setUserInfo, setUserTokenResult,ws],
  );

  // 8/20/24 added these two websocket methods. We can now listen to incoming messages anywhere
  //in app, as well as send direct websocket messages. 
  const sendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message));
    }
  };

  const onmessage = useCallback((handler) => {
    setMessageHandlers(prevHandlers => [...prevHandlers, handler]);
  }, []); // Empty dependency array


  useEffect(() => {
    const unsubribe = firebase.auth().onAuthStateChanged(listener);
    return () => {
      unsubribe();
    };
  }, [listener]);

  const value = useMemo(
    () => ({
      user,
      userInfo,
      userTokenResult,
      signOut: handleSignOut,
      updateUserInfo: handleUpdateUserInfo,
      updateUserZones: handleUpdateUserZones,
      updateUserZone: handleUpdateUserZone,
      updateUserTokenResult: handleUpdateUserTokenResult,
      switchToZone: handleSwitchToZone,
      ws,
      sendMessage,
      onmessage
    }),
    [
      user,
      userInfo,
      userTokenResult,
      handleSignOut,
      handleUpdateUserInfo,
      handleUpdateUserZones,
      handleUpdateUserZone,
      handleUpdateUserTokenResult,
      handleSwitchToZone,
      ws,
      sendMessage,
      onmessage
    ],
  );

  if (loading || loadingInfo) {
    // return <Loader type="fixed" />;
    return  <Spinner isLoading isDone={false} color="#AE007B" />
  }

  if (!user || !userInfo) {
    return <Redirect to={ROUTES.REDIRECTION.SIGN_IN} noThrow />;
  }

  if (!userInfo.phoneNumber) {
    return <Redirect to={ROUTES.REDIRECTION.AUTH_PROCESS} noThrow />;
  }
  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export default AccountProvider;
export { AccountContext };
