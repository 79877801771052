/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useContext } from 'react';
import { css, jsx } from '@emotion/core'
import Icon from '@mdi/react';
import onClickOutside from 'react-onclickoutside';
import { mdiChevronDown, mdiChevronUp, mdiClose } from '@mdi/js';
import { Input, Icon as SIcon, Checkbox } from 'semantic-ui-react';
import { AccountContext } from '../../providers/AccountProvider';
import UserItem from './UserItem';
import SelectedUserItem from './SelectedUserItem';
import { getTableUsersAPI,getBlockUsersAPI,getUsersAPI } from '../../api/users';
import Global from '../../clients/global';


// const clickOutsideConfig = {
//   handleClickOutside: () => UserDropdown.handleClickOutside
// }

const clickOutsideConfig = {
  handleClickOutside: ({props}) => UserDropdownHtml['handleClickOutside_' + props.field]
}

// 11-10-2021 changed to function syntax to support click-outside usage with a functional component.
function UserDropdownHtml ({row,field,uitype, updateFilter, color, lookup, selectedUsers,width="100%",filter,tablename,blockid,type,callingComponent,expand,userType='zone',definedUsers=[]}){
  
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(true);

  UserDropdownHtml['handleClickOutside_'+field] =() =>{
    setIsExpanded(false)
  };

  const filterItems = async (value) => {
    setSearchTerm(value);
    setItems(allItems.filter((el) => el.firstname.toLowerCase().includes(value)));
   
  };

  useEffect(() => {
    const newWidth = parseInt(width.toString().replace('px',''))
    const fetchData = async () => {
    let result = [];
      if(uitype===26 && type==='grid')
      {
        // result = await getNonBlockUsersAPI(blockid) 
        result = await getBlockUsersAPI(blockid,false)  //second param indicates if you want users assigned to block or not yet assigned.
      } else if (uitype===26 && type==='filter') {  //11-8-2021 added support for collaboration type (8)
        // result = await getBlockUsersAPI(blockid,true) 
        result = await getUsersAPI()
      } else if (uitype===8 && type==='filter') {
        // 1-26-23 if filtering on collab, show all users regardless of active/inactive
        result = await getUsersAPI() 
      } else if (uitype===8 && type==='restricted') {
        // 1-26-23 Collaboration type can either be show all users in zone, regardless of status (type='filter')
        //or restricted based on defined "userType" param. 
        if(userType==='zone' || userType==='specific') {
          const filters=[{field:"status",value:['Active']}]
          result = await getUsersAPI(filters,false,"raw",userType==='specific' ? definedUsers : null) 
        } else if(userType==='block') {
          result = await getBlockUsersAPI(blockid,true)
        }
      }
      else if (uitype===24 || uitype===25 )
      {
        // 12-8-2021 updating logic to include active Block users AND any users who previously added rows
        // to the table, as those users could be in-activated in the future, and you still need to search.
       result = await getTableUsersAPI(schema, tablename, uitype);
       const result2 = await getBlockUsersAPI(blockid,true) 
       result2.map(itm=>{
         const idx =  result.findIndex(el=>el.userid===itm.userid)
         if(idx ===-1){
           result.push(itm)
         }
       })
      //  console.log(result2);
      //  result = result.concat(result2)
      }
      const users=[];
      const selUsers =[];

      result.length > 0 && result.forEach((itm) => {
        
        // 2-11-2021 as we add new values to the user attributes json, it continues to break the block user table ability
        // to select a new user to associate as the value must match exact. Therefore we will specfially create
        // the attributes with the common required to display a user: text, color and avatar.
        const newattributes = {color:itm.attributes.color, text:itm.attributes.text, avatar:itm.attributes.avatar !==undefined ? itm.attributes.avatar : null}
        
        const useritem = {      
          userid:  itm.userid ,
          firstname: itm.firstname,
          lastname: itm.lastname ,
          email:itm.email,
          status:itm.status,   
          mobile: itm.mobile ,
          address: itm.address,        
          attributes: newattributes,
        };
        users.push(useritem);

        users.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));

       
        if(filter !==undefined && filter.value) {
          
          if(filter.value.includes(itm.userid)){
                selUsers.push(useritem)
            }
        }

      })
      if(selectedUsers !==null && selectedUsers !==undefined  && selectedUsers.length>0) {
        setSelectedItems(selectedUsers)
      } else if(selUsers.length>0) {
        setSelectedItems(selUsers)
      } else {
        setSelectedItems([])
      }

      setItems(users)
      setAllItems(users)
    };
    fetchData();

    // setIsExpanded(gridExpandState)
    if(selectedItems !== undefined && selectedItems !== null){
      setSelectedItems(selectedItems)
    } else {
      setSelectedItems([])
    }
    
  },[uitype,selectedUsers,row])

  const selectItem = (user) => {
      
      let temparray=[];
      if(lookup==='multiple')
      {
        temparray = [...selectedItems]
        temparray.unshift(user)
        setSelectedItems(temparray)
      } else {
        temparray = [user]
        setSelectedItems(temparray)
        setIsExpanded(false)
        //   expand !==undefined? expand(false,false) : null
      }

      if(callingComponent==='NewRecord') {
        updateFilter(field,temparray,uitype)
      } else {
        updateFilter(temparray)
      }

  };


  const removeItem = (id) => {
    const temparray = selectedItems.filter((el) => el.userid !== id);
    setSelectedItems(temparray);
    // updateFilter(temparray)

    if(callingComponent==='NewRecord') {
      updateFilter(field,temparray,uitype)
    } else {
      updateFilter(temparray)
    }
  };

  const isSelected = (id) => {
    const rowindex = selectedItems.findIndex((el) => el.userid === id);
    return rowindex !== -1;
  };

  const UpdateItem = (isChecked, user) => {
    if (isChecked) {
      selectItem(user);
    } else {
      removeItem(user.userid);
    }
  };

  const expandCollapse = (isCancel) => {
    setIsExpanded(!isExpanded);
    expand !==undefined  ? expand(!isExpanded,isCancel) : null
  };

  return (
    <div
    key={field}
      style={{
        paddingLeft: '3px',
        border: isExpanded ? `1px solid ${color}` : '1px solid #DEDEDF',
        minWidth: isExpanded ? '200px' : `${width}px`,
        width,
        borderRadius: '5px',
        // marginBottom:'20px'
      }}
    >
      <div
        style={{
          borderBottom: isExpanded ? `1px solid ${color}` : null,
          minHeight: '30px',
          width:'100%',
          display:'flex',
          flexDirection:'row',
          alignItems:'flex-start'
        }}
      >
        <div
          style={{
            float: 'left',
            display: 'flex',
            flexWrap:'wrap',
            flexDirection: 'row',
            overflow: 'wrap',
            // width:'100%',
            width: width !=='100%' ? `${width-30}px` : '100%'
            // width: parseInt(width.toString().replace('px', '')) - 30,
          }}
        >
          {selectedItems.map((el2) => (
            <SelectedUserItem
              key={el2.userid}
              Action={removeItem}
              item={el2}
              backgroundColor='white'
              color={el2.attributes ? el2.attributes.text : 'white'}
            />
          ))}
        </div>
        <div
          css={{
            float: 'right',
            cursor: 'pointer',
            marginRight: '5px',
            marginTop: '3px',
            '&:hover': {
              svg: {
                color: 'white',
                backgroundColor: `${color}80`,
                borderRadius: '20px',
                transition: 'all .3s ease',
              },
            },
          }}
        >
          <Icon
            onClick={() => expandCollapse(true)}
            path={isExpanded ? mdiChevronUp : mdiChevronDown}
            size="20px"
          />
        </div>
      </div>
      {isExpanded ? (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '10px',
              borderBottom: `1px solid ${color}80`,
            }}
          >
            <SIcon name="search" style={{ marginRight: '10px' }} />
            <Input
              autoFocus
              onFocus={() => setSearchActive(true)}
              onBlur={() => setSearchActive(false)}
              value={searchTerm}
              transparent
              placeholder="find..."
              onChange={(e, data) => filterItems(data.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // marginLeft: '10px',
              overflow: 'auto',
              height: '250px',
            }}
          >
            {items.map((el) => (
              <UserItem 
              key={el.userid}
              item={el}
              lookup={lookup}
              Action={selectItem} 
              isSelected={isSelected}
              checkBoxHandler={UpdateItem} 
              backgroundColor={el.attributes ? el.attributes.color : `${color}80`}
              color={el.attributes ? el.attributes.text : 'white'}/>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

// export default UserDropdown;
export default onClickOutside(UserDropdownHtml,clickOutsideConfig);
