/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core'
import FinalForm, { Form, FormField, FormCard, validators } from '../../components/Form';
import TextButton from '../../components/TextButton/TextButton';
import {ChangePassword as upatePassword} from '../../api/auth';

const ChangePassword = ({ color, close }) => {

  const [submitError, setSubmitError] = useState('')

  const formValidators = {
    password1: validators.composeValidators(
      validators.required,
      validators.minLength(8),
      validators.password,
    ),
    password: validators.composeValidators(validators.required, validators.minLength(1)),
  };

  const equalToPassword = (value, values) => {
    if (value !== values.password1) {
      return 'Passwords are not equal.';
    }
    return undefined;
  };

  const onSubmit = async (values) => {
    const response = await upatePassword(values.currentpassword,values.password1)
    if(response==='success') {
        close(false)
    } else {
        setSubmitError(response);
    }
  };

  return (
    <div style={{zIndex:'2000'}}>
      <div
        style={{
          zIndex:'200',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: color,
          textAlign: 'center',
          paddingLeft: '20px',
          color: 'white',
          verticalAlign: 'center',
          height: '40px',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
        }}
      >
        Change password
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ margin: '5px', display: 'inline',marginLeft:'20px' }}>
          To change your password, first enter your current password and then enter and confirm your
          new password.
        </div>
        <FinalForm onSubmit={onSubmit}>
          {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
            return (
              <>
                <div style={{ margin: '20px' }}>
                  <FormField
                    name="currentpassword"
                    label="Current Password"
                    color={color}
                    placeholder="Current password."
                    validate={formValidators.password}
                    control={Form.PasswordControl}
                    controlId="password"
                  />
                  {submitError !=='' ? (<div style={{color:'red',marginTop:'-10px'}}>{submitError}</div>): null}

                  <FormField
                    name="password1"
                    label="New Password"
                    placeholder="Enter your new password."
                    validate={formValidators.password1}
                    control={Form.PasswordControl}
                    color={color}
                    controlId="id_password1"
                    helpText="Use 8 or more characters using letters, numbers and symbols."
                    validateFields={['password2']}
                    showLevelTooltip
                  />
                  <FormField
                    name="password2"
                    label="Confirm New Password"
                    placeholder="Re-enter your password."
                    color={color}
                    validate={equalToPassword}
                    control={Form.PasswordControl}
                    controlId="id_password2"
                    hideErrors={!!errors.password1}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'rows',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    <TextButton
                      text="Cancel"
                      textColor="black"
                      hoverColor="#75757580"
                      Action={() => close()}
                      ActinValue={false}
                    />
                  </div>
                  <button
                    css={{
                      backgroundColor: color,
                      color: 'white',
                      borderRadius: '10px',
                      width:'100px',
                      height:'35px',
                      marginLeft:'10px',
                      outline:'0px',
                      paddingTop: '5px',
                      border:'0px solid transparent',
                      paddingBottom:'5px',
                      paddingLeft:'15px',
                      paddingRight:'15px',
                      '&:hover': {
                        transition: 'all .3s ease',
                        color: 'white',
                        boxShadow: '0px 3px 6px #757575',
                        },
                        '&:focus' : {
                            outline:'0px'
                        }
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                
              </>
            );
          }}
        </FinalForm>
      </div>
    </div>
  );
};

export default ChangePassword;
