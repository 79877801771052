/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';

import { mdiDesktopClassic, mdiLinkVariant, mdiWeb, mdiCamera } from '@mdi/js';
import ActionButton from '../ActionBar/ActionButton';
import DesktopFiles from './DesktopFiles';
import URLFiles from './URLFiles';
import WebSearch from './WebSearch';

const AttachmentCategory = ({ categories, uploadFiles, fetchImageUrl,color,selectmode,GetImage,allowCategories }) => {
  const [uploadType, setUploadType] = useState('Device');
  const [category, setCategory] = useState({
    optionid: '1',
    option: 'Uncategorized',
    attributes: { color: '#757575', text: '#FFFFFF' },
  });

  useEffect(()=>{
    if(categories.length>0 && allowCategories) {
      setCategory(categories[0])
    }
  },[])

  const createtags = () => {
    if (categories[0].optionid === '1') {
      categories.push(categories.splice(0, 1)[0]);
    }
    return categories.map((el) =>
      el && el.option ? (
        <div
          onClick={() => setCategory(el)}
          key={el.optionid}
          css={{
            cursor: 'pointer',
            width: 'max-content',
            backgroundColor: el.optionid === category.optionid ? el.attributes.color : '#FFFFFF',
            color: el.optionid === category.optionid ? el.attributes.text : '#757575',
            border: `2px solid ${el.attributes.color}`,
            paddingRight:'10px',
            paddingLeft: '10px',
            paddingTop: '12px',
            paddingBottom: '10px',
            height: '17px',
            whiteSpace: 'nowrap',
            marginRight: '10px',
            display: 'inline-flex',
            verticalAlign: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingRight: '10px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: `${el.attributes.color}80`,
              color: '#ffffff',
            },
          }}
        >
          {el.option}
        </div>
      ) : null,
    );
  };

  const ChangeUploadType = (type) => {
    setUploadType(type);
  };

  return (
    <div style={{ marginLeft: '20px' }}>
      {/* We only show list of categories if more than just standard "Uncategorized" */}
      {categories.length > 1 ? (
        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            width: '100%',
            marginLeft: '10px',
            // border:'1px solid red',
            overflow:'auto',
            scrollbarWidth:'thin'
          }}
        >
          {createtags()}
        </div>
      ) : null}
      <div
        css={{
          height: '400px',
          border: `.5px solid ${category.attributes.color}`,
          margin: '10px',
          borderRadius: '5px',
          marginBottom: '30px',
        }}
      >
        <div
          className="buttons"
          css={{
            display: 'flex',
            marginTop: '4px',
            borderBottom: '1px solid #757575',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ActionButton
            Action={ChangeUploadType}
            ActionValue="Device"
            label="Device"
            color={categories.length > 1 ? category.attributes.color : color}
            actionIcon={mdiDesktopClassic}
            isSelected={uploadType === 'Device'}
            showBottom={true}
            marginBottom="0px"
          />
          <div style={{ width: '20px' }}></div>
          <ActionButton
            Action={ChangeUploadType}
            ActionValue="URL"
            label="Link (URL)"
            color={categories.length > 1 ? category.attributes.color : color}
            actionIcon={mdiLinkVariant}
            isSelected={uploadType === 'URL'}
            showBottom={true}
            marginBottom="0px"
          />
          <div style={{ width: '20px' }}></div>
          <ActionButton
            Action={ChangeUploadType}
            ActionValue="Web"
            label="Web image search"
            color={categories.length > 1 ? category.attributes.color : color}
            actionIcon={mdiWeb}
            isSelected={uploadType === 'Web'}
            showBottom={true}
            marginBottom="0px"
          />
          <div style={{ width: '20px' }}></div>
          <ActionButton
            Action={ChangeUploadType}
            ActionValue="camera"
            label="Camera"
            color={categories.length > 1 ? category.attributes.color : color}
            actionIcon={mdiCamera}
            isSelected={uploadType === 'camera'}
            showBottom={true}
            marginBottom="0px"
          />
          {/* <div style={{marginLeft:'20px',width:'470px',marginBottom:'10px', height:'60px'}} ></div> */}
        </div>
        {uploadType === 'Device' ? (
          <DesktopFiles
            color={categories.length > 1 ? category.attributes.color : color}
            uploadFiles={uploadFiles}
            category={category}
            GetImage={GetImage}
            selectmode={selectmode}
          />
        ) : null}
        {uploadType === 'URL' ? (
          <URLFiles
            color={categories.length > 1 ? category.attributes.color : color}
            addUrl={fetchImageUrl}
            category={category}
            selectmode={selectmode}
          />
        ) : null}
        {uploadType === 'Web' ? (
          <WebSearch
            color={categories.length > 1 ? category.attributes.color : color}
            addUrl={fetchImageUrl}
            category={category}
            selectmode={selectmode}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AttachmentCategory;
