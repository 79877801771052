/* eslint-disable */
import React, { useEffect, useState } from 'react';
import GBLogo from './Powered.svg';

const SocialShare = ({ form, fInfo,token, searchParams }) => {
  const [currentForm, setCurrentForm] = useState();
  const [formInfo, setFormInfo] = useState();

  useEffect(() => {
    setCurrentForm(form);
    setFormInfo(fInfo)
  }, []);

  return currentForm && formInfo ? (
    <center>
      <div
        style={{
          width: '1200px',
          height: '1200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: currentForm.settings.banner ===undefined ? currentForm.settings.accentColors.attributes.color : null
        }}
      >
        <div style={{height: '285px',backgroundColor:`${currentForm.settings.accentColors.attributes.color}1A`,width:'100%' }}></div>
        <div style={{ position: 'relative', overflow:'hidden', height: '630px', width: '1200px',backgroundColor:`${currentForm.settings.accentColors.attributes.color}` }}>
          {currentForm.settings.banner ? (
          <img width="1200px"  src={currentForm.settings.banner.url} style={{opacity: currentForm.settings.overlayOpacity / 100}} />
          ): null}
          {currentForm.settings.logo ? (
          <div style={{position:'absolute', top:20,left:'50%',transform:'translate(-50%)'}}>
          <img height={currentForm.settings.logo.height} src={currentForm.settings.logo.url} />
          </div>
          ): null}

          <div
            id="social-share-content"
            style={{
              backgroundColor:'#FFFFFF80',
            //   height:'100px',
              width:'70%',
              lineHeight:'55px',
              marginTop:'30px',
              padding:'40px',
              position: 'absolute',
              top: 100,
              fontFamily:'Arial',
              left: 600,
              fontSize: '50px',
              fontWeight:'bold',
              color: 'black',
              transform: 'translate(-50%,50%)',
            }}
          >
           {currentForm.settings.socialShareTitle}
          </div>
          
          <div
            style={{
              position:'absolute',
              bottom:0,
              left: 600,
              transform: 'translate(-50%,-100%)',
              backgroundColor: 'black',
              width: '180px',
              padding: '10px',
              // paddingTop:'10px',
              // paddingBottom:'10px',
              borderRadius: '10px',
            }}
          >
            <img src={GBLogo} height="30px" />
          </div>
        </div>
        <div style={{height: '285px',backgroundColor:`${currentForm.settings.accentColors.attributes.color}1A`,width:'100%' }}></div>
      </div>
    </center>
  ) : (
    <div>Loading...</div>
  );
};

export default SocialShare;
