/* eslint-disable */
import React,{useState} from 'react';
import { ToastContainer } from 'react-toastify';
import Router from './components/Router';
import Auth from './containers/Auth';
import Form from './containers/Form';

import DashboardPage from './containers/Dashboard';
import { ROUTES } from './constants';

function App() {
  return (<>
      <Router>
        <Auth path={ROUTES.AUTH.CHILDREN} />
        <DashboardPage path={ROUTES.DASHBOARD.CHILDREN} />
        <Form path={ROUTES.FORMS.CHILDREN} />
      </Router>
      <ToastContainer />
 </> );
}

export default App;
