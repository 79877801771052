/* eslint-disable */
import React, { useCallback, useMemo,useState,useContext,useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link } from '@reach/router';
import { AccountContext } from '../../../providers/AccountProvider';
import FinalForm, { Form, FormField, validators } from '../../../components/Form';
import {updateBlockAPI} from '../../../api/blocks';
import TextButton from '../../../components/TextButton/TextButton';
import GBSubmit from '../../../components/GBSubmit/GBSubmit';
import { COLORS, ICONS } from '../constants';
import GBIcons from '../../../utils/GBIcons';
import AutoSave from './AutoSave';
import '../styles/styles.add.scss';
import Global from '../../../clients/global'


const formValidators = {
  name: validators.composeValidators(validators.required),
  password: undefined,
};

const BlockEditor= ({ block, onCancel, show, onChange,updateBlockInfo,ShowError }) => {
  
  // const defaultInformation='Information entered here will appear on the Block Homepage to help Block users understand the what to do inside the application you build. You can edit this information later by going to Block Settings. <i class="cog icon"></i>';
  const { userInfo} = useContext(AccountContext);


  const [newValues,setNewValues] = useState()

  // useEffect(()=>{
  //   console.log(block)
  // },[])

  // const handleClose = () => setShow(false);
  const handleClose =() =>onCancel()

  const initialValues = useMemo(
    () => ({
      color: block.color,
      icon: block.icon,
      name: block.name,
      statement:block.statement,
      // draft: block.draft ? block.draft : defaultEditor,
      draft: block.information 
    }),
    [block], 
  );

  const handleSubmit = async (values) => {
   
      const activeZone = [...userInfo.zones];
      const tempZone = activeZone.filter(itm=>itm.id===parseInt(Global.zoneid))[0];
      const colorIdx = COLORS.findIndex((itm) => itm === values.color);

      if(tempZone.plan !=='Free' || colorIdx !== -1)
      {

          const newblock = {...block}
          newblock.color= values.color ?? newblock.color;
          newblock.name= values.name ?? newblock.name;
          newblock.icon = values.icon ?? newblock.icon;
          newblock.statement= values.statement ?? '';
          newblock.draft = values.draft ?? null;
          await updateBlockAPI(newblock); //Updates DB

          // let information = draftToHtml(convertToRaw(newblock.draft.getCurrentContent()))
          newblock.information=values.draft;
          updateBlockInfo(newblock) // this updates block info in Block/main grid page.
          onCancel()
      } else if (tempZone.role === 'Zone owner') {
        ShowError(
          <div style={{ margin: '5px' }}>
            The free plan does not support custom colors. Upgrade now by clicking the option in
            the top right corner of the {` `}
            <Link to="/" style={{color:'white'}}><u><b>Zone dashboard</b></u></Link>
          </div>,
        );
      } else {
        ShowError(
          <div style={{ margin: '5px' }}>
            The Free plan does not support custom colors, consider upgrading. Contact your Zone
            owner.
          </div>,
        );
      }

     
  }

  const handleChange = useCallback(
    (values) => {
      if(!values.color.includes('#')) {
        values.color='#' + values.color
      }
      block.color = values.color
    // setNewValues(values)
   },
   [block, onChange],
 );

  // const toggle

  const selectedColor = block.color;

  return (
  <Modal
     enforceFocus={false}
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      dialogClassName="add-block-dialog-window"
    >
      <Modal.Header style={{ backgroundColor: selectedColor }}>
        <Modal.Title>
        <div style={{display:'flex',alignItems:'center',color:'#fff', fontSize:'15px',height:'42px'}}>&nbsp;Edit Block properties (Block ID: {block.blockid})</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={initialValues}
          subscription={{ values: true }}
          onSubmit={handleSubmit}
          id="addBlockForm"
        >
          {({ values: { color } }) => {
            return (
              <>
                <AutoSave debounce={0} onSave={handleChange} />
                <div className="add-block-dialog-content">
                  <div className="add-block-dialog-content-data">
                    <div style={{ maxWidth: '250px' }}>
                      <FormField
                        name="name"
                        label="Name"
                        placeholder="New block."
                        validate={formValidators.name}
                        controlId="id_name"
                        size="sm"
                        color={color}
                      />
                    </div>
                    <FormField
                      name="draft"
                      label="Information"
                      controlId="id_information"
                      size="sm"
                      color={color}
                      control={Form.DraftControl}
                    />
                    <FormField
                      name="statement"
                      label="Persistent Statement"
                      placeholder="Enter a statement here if one is needed on every page of this block; this can be used for legal notices."
                      controlId="id_statement"
                      maxLength="255"
                      size="sm"
                      color={color}
                    />
                   <div style={{marginleft:'200px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                     <TextButton textColor="black" text="Cancel" hoverColor="#75757580" Action={handleClose}/>
                     <GBSubmit text="Save" color={selectedColor} />
                     </div>
                  </div>
                  <div className="add-block-dialog-content-colors">
                    <FormField
                      name="color"
                      label="Color"
                      controlId="id_color"
                      size="sm"
                      colors={COLORS}
                      control={Form.ColorPickerControl}
                    />
                  </div>
                  <div className="add-block-dialog-content-icons">
                    <FormField
                      name="icon"
                      controlId="id_icon"
                      size="sm"
                      icons={GBIcons}
                      control={Form.IconPickerControl}
                      color={color}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </FinalForm>
        </Modal.Body>
    </Modal>
       
  );
};


BlockEditor.defaultProps = {
  onCancel: () => {},
  onChange: () => {},
  show: false,
};

BlockEditor.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  block: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  show: PropTypes.bool,
};

export default BlockEditor
