/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';

import { mdiGrid, mdiChat } from '@mdi/js';
import { Popup } from 'semantic-ui-react';

const MultiActionButton = ({
  label,
  buttons, 
  viewMode,
  color,
  Action,
  size = '23px',
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        color: '#312602',
        fontSize: '12px',
        marginBottom: '11px',
        justifyContent: 'center',
        marginLeft: '10px',
        height: null,
        // borderBottom:'8px solid transparent',
        marginRight: '10px',
        alignItems: 'center',
        outline: '0px',
        // border:'1px solid blue',
        '&:hover': {
          transition: 'all .3s ease',
          color: `${color}80`,
        },
        '&:active': {
          color: `${color}`,
        },
      }}
    >
      <div>{label}</div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
       {buttons.map(button => (<div key={button.param}>
         <div
        
         css={{
           color: viewMode ===button.param ? color : '#312602',
           '&:hover': {
             svg: {
               color: `${color}80`,
               transition: 'all .3s ease',
             },
           },
           '&:active': {
             color: `${color}`,
             svg: {
               color: `${color}`,
             },
           },
         }}
       >
         <Popup
           disabled={button.toolTip===undefined}
           content={button.toolTip}
           trigger={
             <Icon
               onClick={Action !== null ? () => Action(button.param) : null}
               //   color={selectedHeightIcon === 1 ? 'rgba(13, 71, 161, 1)' : 'rgba(13, 71, 161, .25)'}
               path={button.icon}
               size={size}
             />
           }
         />
       </div>
       <div style={{ width: '3px' }}> </div></div>
       ))}
      </div>
    </div>
  );
};

export default MultiActionButton;
