/* eslint-disable */
import _ from 'lodash';

export const groupDataTest2 =(data) => {
  const d= structuredClone(data);
  d.map(el=>{
    el.field_1 = el.field_1 !==undefined ?  el.field_1.map(el2=>el2.data).join() : '';
  })

  // console.log(d)
  var result = _(d)
            .groupBy(x => x.field_1)
            .map((value, key) => ({color: key, data: value}))
            .value()
   
}


const getMedian = (values) =>{
  if(values.length ===0) throw new Error("No inputs");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
}

const groupBy = (rawArray, groupbyFields, sumFields,level,nextCounter,rowCounter=0) => {
  const field = groupbyFields[0]
  const fieldValues = new Set()

  rawArray.forEach(e => fieldValues.add(e[field]))
  let counter=0
  const array = []
  let rowCounterTemp=rowCounter;

  for (const fieldValue of fieldValues.values()) {
      let data = rawArray.filter(e => e[field] === fieldValue)
      rowCounterTemp = data.length 
     
    
      if (groupbyFields.length > 1) {
          data = groupBy(data, groupbyFields.slice(1), sumFields,level+1,counter,rowCounterTemp)
      }

      const obj = { data }
      obj.SumCounter = rowCounterTemp;

      sumFields.forEach(sumField => {
          const sumFieldName = `${ sumField }Sum`
          const sumFieldNameCalc = `${ sumField.replace('_flat','') }_SumCalc`
          const values= data.map(e => e[sumField] ?? _.get(e,`${sumFieldNameCalc}.values`,null))
          const lng2 = values.flat(1).filter(el=>el !==null).length;
          const sum = data.map(e => e[sumField] ?? e[sumFieldName] ?? 0).reduce((g, c) => g + c, 0)
        
          const lng = data.map(e => e[sumField] ?? e[sumFieldName] ?? null).filter(el=>el !==null).length
          let avg=null;
          if(lng>0){
            avg = (sum/lng2)
          }
          let min=null;
          let max=null;
          let median=null;

          if(data.map(e => e[sumField] ?? _.get(e,`${sumFieldNameCalc}.min`,null)).filter(el=>el !==null).length>0) {
           min = data.map(e => e[sumField] ?? _.get(e,`${sumFieldNameCalc}.min`,null)).filter(el=>el !==null)
          .reduce((acc, val) => {
            return acc < val ? acc : val;
          })
        }

          if(data.map(e => e[sumField] ?? _.get(e,`${sumFieldNameCalc}.max`,null)).filter(el=>el !==null).length>0) {
            max = data.map(e => e[sumField] ?? _.get(e,`${sumFieldNameCalc}.max`,null)).filter(el=>el !==null)
          .reduce((acc, val) => {
            return acc > val ? acc : val;
          })
        }

          let tempValues = data.map(e =>e[sumField] ?? null).filter(el=>el !==null);
          if(tempValues.length>0){
            median = getMedian(tempValues);
          } else if(values.flat(1).filter(el=>el !==null).length>0) {
            median = getMedian(values.flat(1).filter(el=>el !==null))
          }

        // if(data.map(e => e[sumField] ?? values.flat(1)).filter(el=>el>0).length>0) {
        //   const valuesTemp = data.map(e => e[sumField] ?? values.flat(1)).filter(el=>el>0)
        //   median = getMedian(valuesTemp);
        // }

          const field=`${sumField.replace('_flat','')}_SumCalc`

          obj[sumFieldName] = sum
          obj[field] ={sum,avg,min,max,median,lng,values:values.flat(1)}
          obj.SumCounter= rowCounterTemp;
      })

      obj[field] = fieldValue
      obj['level'] = `${level}-${nextCounter}-${counter}`
      counter++;
      array.push(obj)
  }

  return array
}

export const GroupData =(data,groups,tableinfo,calcFields) =>{

  const d= structuredClone(data);

  // 11-17-22 First step is for any defined group by field, we need to detect if it's complext type
  // if it is, we need to flatten it to single string value upon which to group by.
  const finalGroups=[];

  groups.map(grp =>{
    const idx = tableinfo.columns.findIndex(col=>col.data===grp)
    if(idx !==-1) {
      const node = tableinfo.columns[idx];
      
    
      if([1,6,7,8,18,24,25,16].includes(node.uitype) ) {
        finalGroups.push(`${node.data}_flat`)
      } else {
        finalGroups.push(node.data)
      }

      d.map(el=>{
        // console.log(el)
        if([1,6,7,18,16].includes(node.uitype) && ![24,25,8].includes(node.source?.lookupuitype)) {                                               //el[node.data].map(el2=>el2.data).join()  // JSON.stringify(el[node.data])
          el[`${node.data}_flat`] =  el[node.data] !==undefined && el[node.data] !==null ?  el[node.data].map(el2=>el2.data).join(', '): ''
        } 
        else if([24,25,8].includes(node.uitype) || [24,25,8].includes(node.source?.lookupuitype)) {
          el[`${node.data}_flat`] =  el[node.data] !==undefined && el[node.data] !==null ? JSON.stringify(el[node.data]) : ''
        }
        // else if(node.uitype===8) {
        //   el[`${node.data}_flat`] =  el[node.data] !==undefined && el[node.data] !==null ?  JSON.stringify(el[node.data])  : ''
        // } else if(node.uitype===24 || node.uitype===25) {
        //   el[`${node.data}_flat`] =  el[node.data] !==undefined && el[node.data] !==null ? JSON.stringify(el[node.data]) : ''
        // } 
      })
    }
  })
  
  const numberFields =[];

  // tableinfo.columns.filter(col=>[17,23].includes(col.uitype) && col.data !=='id' || (col.uitype===18 && [17,23].includes(col.source?.lookupuitype) && !['all','unique'].includes(col.specialfunction)) || col.fieldType==='numeric').map(col=>{
    tableinfo.columns.filter(col=>calcFields.includes(col.data)).map(col=>{  
    //create list of numberfields. for lookup of number fields, we use [field]_flat as format, so original
    //data structure will still render properly in quickview.
    // ['sum','avg','nummin','nummax','count','countdistinct','median']
    if((col.uitype===18 && [17,23].includes(col.source?.lookupuitype) && (!['all','unique'].includes(col.specialfunction) || col.source.lookup==='single')  ) || col.fieldType==='numeric') {
      numberFields.push(`${col.data}_flat`)
    } else {
      numberFields.push(col.data);
    }
   
    //for lookup of number fields/special number fields, create new field of format [field]_flat to store the number (versus array of [id,data])
    d.map(el=>{
      if((col.uitype===18 && [17,23].includes(col.source?.lookupuitype)  && (!['all','unique'].includes(col.specialfunction) || col.source.lookup==='single' ))  || col.fieldType==='numeric' ) {
        el[`${col.data}_flat`] =  el[col.data] !==undefined && el[col.data] !==null && el[col.data][0].data !==null ? parseFloat(el[col.data][0].data) : null
      } else if(el[col.data] !==null){
        el[col.data] = parseFloat(el[col.data])
      }
    })  
  
  })

  // const fields=['field_1_flat']
  const result = groupBy(d,finalGroups,numberFields,0,0)

  return {data:result,groups:finalGroups,numberFields:numberFields};

  
}