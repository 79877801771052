/* eslint-disable */
import React,{useCallback,useState,useEffect} from 'react';
import { Popup } from 'semantic-ui-react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { mdiAlert,mdiInformationOutline} from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { Link,navigate } from '@reach/router';
import { ROUTES } from '../../../constants';
import FinalForm, { Form, FormField, FormCard, validators,FORM_ERROR } from '../../../components/Form';
import Button from '../../../components/Button';
import GoogleButton from '../../../components/GoogleButton';
import MicrosoftButton from '../../../components/MicrosoftButton/MicrosoftButton';
import Spinner from '../../../components/Spinner/Spinner';
import { EmailIcon } from '../../../components/Icon';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';
import { signInInvitedGoogleAPI,signUpInvitedUserAPI,signInInvitedMicrosoftAPI, signOutAPI } from '../../../api/auth';
import GBButton from '../../../components/GBButton/GBButton';
import GBConfirm from '../../../components/GBConfirm/GBConfirm';


import './styles.scss';


const formValidators = {
  firstName: validators.composeValidators(validators.required, validators.minLength(1)),
  lastName: validators.composeValidators(validators.required, validators.minLength(2)),
  email: validators.composeValidators(validators.required, validators.email),
  password1: validators.composeValidators(
    validators.required,
    validators.minLength(8),
    validators.password,
  ),
};


const equalToPassword = (value, values) => {
  if (value !== values.password1) {
    return 'Passwords are not equal.';
  }
  return undefined;
};

const SignUpInvite = ({registeremail,zoneid,trxid,blockname}) => {
    const { REACT_APP_CURRENT_VERSION } = process.env;
    const [loading, setLoading] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [inviteCompleted, setInviteCompleted] = useState(false);
    const { REACT_APP_API_CLOUDRUN_URL } = process.env;
    const [zoneName, setZoneName] = useState('');
    const [invitedBy, setInvitedBy] = useState('');
       

    useEffect(() => {
      const payload={token: trxid,email:registeremail,zoneid}
      setLoading(true)

      const checkToken = async () => {
        const result = await axios({
          url: `${REACT_APP_API_CLOUDRUN_URL}/validateInviteToken`,
          headers: { 'Content-Type': 'application/json' },
          method: 'post',
          data: {
              payload,
          },
      });
      
      if(result.data.length>0)
      {
        setZoneName(result.data[0].Zone.name)
        setInvitedBy(result.data[0].AddedBy.firstname)
      } 

      setIsTokenValid(result.data.length>0)
      setLoading(false)
      }
        checkToken()        
    },[])

    const showError = (message) => {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title="An error occurred"
              action={onClose}
              showCancelButton={false}
              buttonLabel="OK"
              message={message}
              showInput={false}
              height="300px"
            />
          );
        },
      });

    }


    const handleSubmit = async (values) => {
          values.trxid=trxid;
          values.zoneid=zoneid;
          values.blockname=blockname;         
          setLoading(true);

          try {
              // if account email matches email in URL, we need to match signup user fbid with associated userid in system
              // then show a "completed" page.
              // If not match, show alert/toast, and let them proceed with regular sign up with no association to zone.
              
              const isValidEmailMatch = await signUpInvitedUserAPI(values)
              
              if(!isValidEmailMatch)
              {
                navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
              } else
              {
                setInviteCompleted(true);
              }

          } catch(error) {
            setLoading(false);
            showError(error.message)
          }
            setLoading(false);
            return true
        }
      
        const confirmAction = (values,ACTION) => {
          
        const message=(<div style={{display:'flex', alignItems:'center',flexDirection:'column',marginRight:'20px', marginLeft:'20px',marginTop:'10px'}}>
                  The email you are using to create this account is not the email {invitedBy} used to invite you to collaborate inside {blockname==='zone' ? zoneName : `${zoneName}: ${blockname}`}. If you continue, you will create a new GraceBlocks account and can build your own Blocks, however, {invitedBy} will need to re-invite you to {blockname==='zone' ? zoneName : `${zoneName}: ${blockname}`}  using this new email address.
                  <p/><p/>
                  Alternatively, use the email where {invitedBy} sent the original invitation, it is already linked to {blockname==='zone' ? zoneName : `${zoneName}: ${blockname}`}. Click OK to continue, or cancel to change the email address.
                  </div>
                  )

          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <GBConfirm
                  title="Email mismatch"
                  action={ACTION}
                  actionValue={values}
                  showCancelButton={true}
                  buttonLabel="OK"
                  width="400px"
                  height="400px"
                  message={message}
                  onClose={onClose}
                  showInput={false}
                />
              );
            },
          });
        }

        const handleSubmitProxy =  async (values) => {

          if (values.email.toLowerCase() !== registeremail.toLowerCase()) {
            confirmAction(values,handleSubmit);
          } else {
            handleSubmit(values)
          }
        }

        const GoogleUserNextStep = (isNewUser) => {
          if (isNewUser) {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          } else {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
          }
        }

      const handleGoogleSignIn = useCallback(() => {
        setLoading(true);
        signInInvitedGoogleAPI(registeremail,trxid,zoneid)
          .then(({ user, isNewUser,isValidMatch }) => {
            
            if(isValidMatch)
            {
              setLoading(true);
              setInviteCompleted(true)
            } else {
              setLoading(false);
              confirmAction(isNewUser,GoogleUserNextStep);
            }
          })
          .catch((error) => {
            setLoading(false);
            alert.show({ message: error.message });
            return { [FORM_ERROR]: error.message };
          });
      }, [alert, navigate,invitedBy]);


      const handleMicrosoftSignIn = useCallback(() => {
        setLoading(true);
        signInInvitedMicrosoftAPI(registeremail,trxid,zoneid)
          .then(({ user, isNewUser,isValidMatch }) => {
            
            if(isValidMatch)
            {
              setLoading(true);
              setInviteCompleted(true)
            } else {
              setLoading(false);
              confirmAction(isNewUser,GoogleUserNextStep);
            }
          })
          .catch((error) => {
            setLoading(false);
            return { [FORM_ERROR]: error.message };
          });
      }, [alert, navigate,invitedBy]);

    const getInvalidMessage = () => {
      return loading ? '' : 'This link is no longer valid'
    }

    
  return (
    <>
    {isTokenValid && !inviteCompleted ? (
    <>
      <div className="help-to-sign-in small" style={{fontSize:'13px'}}>
        Already have an account? <Link to="/auth/sign-in">Sign in</Link>.
      </div>

      <img src={LogoIcon} alt="Logo" />
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize:'13px',
        }}
      >
        Open Beta&nbsp; <Popup content={`${REACT_APP_CURRENT_VERSION}`} trigger={<Icon path={mdiInformationOutline} size="15px" color="black" />} />
      </div>
     
      <FinalForm
       
        onSubmit={handleSubmitProxy}
        initialValues={{
          email:registeremail,
        }}
      >
        {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
          return (
            <>
           
              <FormCard title="Sign Up">
              <div style={{textAlign:'left', marginBottom:'10px'}}>{invitedBy} has invited you to collaborate in a system built with GraceBlocks. To join this system <strong>({blockname==='zone' ? zoneName : `${zoneName}: ${blockname}`})</strong> and collaborate with {invitedBy}, first create your GraceBlocks account using the options below.  </div>
                <GoogleButton className="mb-4" onClick={handleGoogleSignIn}>
                  Sign up with Google
                </GoogleButton>

                <MicrosoftButton className="mb-4" onClick={handleMicrosoftSignIn}>
                  Sign up with Microsoft
                </MicrosoftButton>

                <FormCard.Subtitle className="my-2">or sign up manually</FormCard.Subtitle>
                <FormField
                  name="firstName"
                  label="First"
                  placeholder="Enter your first name."
                  validate={formValidators.firstName}
                  controlId="id_firstName"
                />
                <FormField
                  name="lastName"
                  label="Last"
                  placeholder="Enter your last name."
                  validate={formValidators.lastName}
                  controlId="id_lastName"
                />
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Enter your email address."
                  validate={formValidators.email}
                  leftIcon={<EmailIcon />}
                  controlId="id_email"
                />
                <FormField
                  name="password1"
                  label="Password"
                  placeholder="Create your password."
                  validate={formValidators.password1}
                  control={Form.PasswordControl}
                  controlId="id_password1"
                  helpText="Use 8 or more characters using letters, numbers and symbols."
                  validateFields={['password2']}
                  showLevelTooltip
                />
                <FormField
                  name="password2"
                  label="Password confirmation"
                  placeholder="Re-enter your password."
                  validate={equalToPassword}
                  control={Form.PasswordControl}
                  controlId="id_password2"
                  hideErrors={!!errors.password1}
                />
              </FormCard>
              <div className="text-center" style={{marginTop:'20px'}}>
                <Button long type="submit" disabled={hasSubmitErrors && !dirtySinceLastSubmit}>
                  Sign up for free
                </Button>
                <div className="small pt-1">
                  By creating an account you agree to the{' '}
                  <a
                    href="http://www.graceblocks.com/termsofservice"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                  .
                </div>
              </div>
              {loading && <Spinner isLoading isDone={false} color="#AE007B" />}
            </>
          );
        }}
      </FinalForm>
    </> ) : null}
    {!isTokenValid ? <div>{getInvalidMessage()} </div> : null}
    {isTokenValid && inviteCompleted ? (
      <div style={{display:'flex', flexDirection:'column', alignItems:'center', fontSize:'20px'}}>
        <div>Use the button below to access {blockname==='zone' ? zoneName : `${zoneName}: ${blockname}`} and collaborate with {invitedBy}.</div>
        <div style={{height:'50px'}} />
        <GBButton text={zoneName} fontSize="20px" height='75px' color='#120233' Action={() =>navigate(ROUTES.REDIRECTION.DASHBOARD)} width="400px" />
      </div>
    ) : null}
    </>
      
  );
};

SignUpInvite.defaultProps = {
  registeremail: '',
};

SignUpInvite.propTypes = {
  registeremail: PropTypes.string,
};

export default SignUpInvite;
