/* eslint-disable */
import React, {useEffect, useState,useContext} from 'react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import {Checkbox } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { AccountContext } from '../../providers/AccountProvider';
import {getUserSystemAttributes, updateUserSystemAttributesAPI} from '../../api/users';
import GBButton from '../../components/GBButton/GBButton';
import { mdiKey, mdiContentCopy} from '@mdi/js';

const Authentication=({color,name,blockKey, tablename}) => {

    const [showAPIKey, setShowAPIKey] = useState(false);
    const [showMyKey,setShowMyKey] = useState(false)
    const {userInfo } = useContext(AccountContext);
    const [apiKey,setApiKey] = useState(null)
    const [systemattributes, setSystemAttributes] = useState(null)

    const { REACT_APP_API_URL } = process.env;

    useEffect(()=>{
      getSettings();
    },[])

    const generateKey = async () =>{
        const token = uuidv4();
        systemattributes.apiKey=token;
        await updateUserSystemAttributesAPI(userInfo.id,systemattributes)
        setApiKey(token);
    }

    const copyToClipboard = (content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success(<div style={{margin:'10px'}}> 
        API Key Copied!
     </div>, {
         position: toast.POSITION.BOTTOM_CENTER,
         autoClose: 2000,
       });
      };

    const getSettings =async() =>{
        const settings = await getUserSystemAttributes();
        if(settings.systemattributes.apiKey !==undefined)
        {
            setApiKey(settings.systemattributes.apiKey);
        }
        setSystemAttributes(settings.systemattributes);
    }

   

    return (
        <div style={{marginTop:'50px', display:'flex',flexDirection:'row', alignItems:'flex-start',scrollMargin: '20px'}} id="authenticate">
            <div style={{width:'50%'}} >
                <div style={{marginLeft:'10px', display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <Icon path={mdiKey} size="40px" color={color} />
                    <span style={{fontSize:'25px', color:color,marginLeft:'10px'}}>Authentication</span>
                </div>
                <div style={{margin:'10px'}}>
                The <b>{name}</b> API uses API keys to authenticate requests. Each user can generate an API key that is associated with their user account. It's the same key across all Zones where the user has access and is able to read and write data according to the user account profile's security access level in any given Zone, Block and tab. Go generate your API key or reset it, visit the my profile page.
                <p/><p/>
                You authenticate to the API by providing your API key in the HTTP authorization bearer token header.
                <p/><p/>
                All API requests must be made over HTTPS. Plain HTTP calls and calls lacking authentication will fail.
                </div>
            </div>

            <div style={{width:'50%'}}>
            <div style={{margin:'10px',border:`2px solid ${color}`, backgroundColor:`${color}1A`, height:'145px', borderRadius:'15px'}}>
                   <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:'15px', height:'30px', borderTopRightRadius:'15px',color:'white', borderTopLeftRadius:'10px',  backgroundColor:color}}>
                       <div>{userInfo.firstName} {userInfo.lastName} API key</div>
                       <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Checkbox  checked={showMyKey} onChange={(e,data)=>setShowMyKey(data.checked)} /><div style={{width:'5px'}}></div> Show API Key
                        <div style={{width:'20px'}}></div>
                    </div>
                   </div>
                   {apiKey !==null ? (
                   <div style={{margin:'10px'}}>
                     <b> {userInfo.firstName} {userInfo.lastName} API KEY</b>: {showMyKey && apiKey !==undefined ? <span style={{color:'green'}}>{apiKey}</span> : <span style={{color:'red'}}>******************************</span> }
                      <p/><p/>
                      This is your personal API Key which has the same permissions as your account login. It works across zones you have access to. Keep it safe/hidden!
                   </div>
                   ): (<div style={{margin:'10px', alignSelf:'center'}}>
                       <div>You have not yet generated your API Key. Click the button below to generate your key. Note this API key is 
                           specific to your personal account and should be kept secure & safe.
                       </div>
                       <GBButton width="250px" color={color} Action={generateKey} text="GENERATE MY API KEY"/>
                    </div>)}
                </div>

            <div style={{backgroundColor:'black', color:'white',margin:'10px',borderRadius:'10px',paddingBottom:'50px'}}> 
                <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between',  padding:'10px', height:'40px', backgroundColor:'#41355C', color:'white',borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
                    <div>Authentication request</div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Checkbox  checked={showAPIKey} onChange={(e,data)=>setShowAPIKey(data.checked)} /><div style={{width:'5px'}}></div> Show API Key
                        <div style={{width:'20px'}}></div>
                    </div>
                    {apiKey !==null ? (
                        <div style={{cursor:'pointer'}} onClick={()=>copyToClipboard(apiKey)}>
                        <Icon path={mdiContentCopy} size="15px" color="white" />
                        <span style={{marginLeft:'5px'}}>Copy to clipboard</span>
                 </div>
                    ):null}
                    
                </div>
                <div style={{margin:'20px'}}>
                    <span style={{color:'grey'}}>EXAMPLE</span>
                    <p/>
                    $ curl {REACT_APP_API_URL}/v1/{blockKey}/{encodeURIComponent(tablename)} 
                    <br/>-H "Authorization: Bearer <span style={{color:'#FFBE24'}}>{showAPIKey && apiKey!==null ? apiKey : 'YOUR_API_KEY'}</span>"
              </div>
            </div>
               
            </div>

         


        </div>
    )
}

export default Authentication