/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import prettyJson from './prettyJSON';
import FilterDocs from './filterdocs';


const ListRecords = ({ tableData, block, blockKey, response }) => {

  const { REACT_APP_API_URL } = process.env;
  const stringColor='#fde1ab';


  useEffect(()=>{
    // console.log(response);
  },[])
  const getExampleJson = () => {
    const  final = '<pre style="color:white">'+prettyJson(JSON.stringify(response,null, 2))+'</pre>';
    return  <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>
  }

  return (
    <div style={{ margin: '10px',marginBottom:'20px', scrollMargin: '20px'}} id="listRecords" >
      <div style={{ color: block.color, fontSize: '24px' }} >
        List records in {tableData.name} tab
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px',alignItems:'flex-start' }}
      >
        <div style={{ width: '60%' }}>
          To list records in{' '}
          <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '}
          tab, issue a <b>GET</b> request to the{' '}
          <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '}
          endpoint. Note that table names and table ids can be used interchangeably. Using table ids
          means table name changes do not require modifications to your API request.
          <p/><p/>
          Results are returned in reverse chronological order.
          <p/><p/>
          <div>Returned records do not include any fields with "empty" values</div>
          <p/><p/>
           The response will return the following three attributes: totalRecords, count, records
           <p/>
           <b>totalRecords</b>: This is the total number of records in this tab. You can use this value when needing to page through the results.
           <p/>
           <b>count</b>: This represents total number of records from filtered results. If no filter is applied, it is equivalent to the total records.
           <p/>
           <b>records</b>: array of records from the table. 
           <p/><p/><p/><p/>
          <div>
            You can filter, sort, and page through the results with the following query parameters.
            Note that these parameters need to be URL encoded.
          </div>
          <p/><p/>
          <div style={{display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{width:'20%',flexDirection:'column',textAlign:'right',marginRight:'25px'}}>
                  <div><b>filters</b></div>
                  <div style={{color:'green',whiteSpace:'nowrap'}}>array of filter objects</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%'}}>
                You can pass in an array of filter objects. This array then must be url encoded.
                <Popup flowing
           hoverable
           on="['click']"
            mouseEnterDelay={1000}
            position="top center"
            trigger={<div style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>View Filter parameters</div>}>
              <FilterDocs  tableData={tableData}  blockKey={blockKey} />
            </Popup>
              </div>
          </div>

          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>limit</b></div>
                  <div  style={{color:'green'}}>number</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
                The maximum total number of records that will be returned in your requests. Must be Less than or equal to 100.
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>starting_after</b></div>
                  <div style={{color:'green'}}>number</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%'}}>
               The records returned will start after the value specified here. This allows
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>bookmark</b></div>
                  <div style={{color:'green'}}>string</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
               The name of the bookmark which should be used to filter results. Must be url encoded and match bookmark name in system.
               If no match is found, it is ignored. 
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>sortField</b></div>
                  <div style={{color:'green'}}>string</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
               The field which you would like to sort results by. You can pass in either the field name or field id.
               Note that you cannot sort on multi-value fields.
               The default sort field is the system "id". 
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>sortOrder</b></div>
                  <div style={{color:'green'}}>string</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
               pass in the value of "asc" for ascneding or or "desc" for "descening" order. The default sort order is "asc"
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>format</b></div>
                  <div style={{color:'green'}}>string</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
               The format of the response data for complex field types. The valid options are "json" and "text".
               The "text" format converts the objects into a simplified comma delimted string of text. This is the preferred format
               for reporting type solutions.
               <p/><p/>
               The default format is json.
              </div>
          </div>
          <div style={{marginTop:'30px', display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
              <div style={{display:'flex', flexDirection:'column',width:'20%',textAlign:'right',marginRight:'25px'}}>
                <div><b>returnFieldIds</b></div>
                  <div style={{color:'green'}}>boolean</div>
                  <div>optional</div>
              </div>
              <div style={{width:'80%',marginRight:'20px'}}>
               An optional parameter to specify if returned objects should use field id's versus field names.
               This is recommended when building integrations, so that changing a field name will not break the integration.
               <p/><p/>
               The default value is false.
              </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#303336',
            padding: '10px',
            color: 'white',
            width: '40%',
            alignSelf: 'stretch',
          }}
        >
          <div style={{color:'#98999B'}}>EXAMPLE REQUEST</div>
          <p/><p/>
          <div>
            curl <span style={{color:stringColor}}>"{REACT_APP_API_URL}/v1/{blockKey}/{encodeURI(tableData.name)}?limit=3"</span> \
            <p/>-H  <span style={{color:stringColor}}>"Authorization: Bearer YOUR_API_KEY"</span> 
          </div>
          <p/><p/>
          <div>
          <div style={{color:'#98999B'}}>EXAMPLE RESPONSE</div>
          <p/><p/>
          <div>{getExampleJson()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListRecords;
