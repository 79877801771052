import React from 'react';
import PropTypes from 'prop-types';
import FinalForm, { FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';

const formValidators = {
  code: validators.composeValidators(validators.required),
};

const Form = ({ onSubmit, onCancel }) => {
  return (
    <>
      <FormCard.Description className="mb-2 mb-4 text-left">
        Validation code sent, please enter it below.
      </FormCard.Description>

      <FinalForm onSubmit={onSubmit}>
        {({ invalid }) => {
          return (
            <>
              <FormField
                name="code"
                label="Validation Code"
                placeholder="Enter the validation code."
                validate={formValidators.code}
                controlId="id_code"
              />
              <div className="mb-4">&nbsp;</div>
              <Button long type="submit" disabled={invalid} className="mb-3">
                Submit
              </Button>
              <Button onClick={onCancel} long type="submit" variant="secondary">
                Start again
              </Button>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

Form.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Form;
