/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import { mdiPlus, mdiCloseCircle, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox } from 'semantic-ui-react';
import IconButton from '../FilterData/IconButton';
import { getBlocksAPI } from '../../../../api/blocks';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';

const BlockUserControl = ({ items, userid, color, Apply,Close }) => {
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [blk, setBlk] = useState([]);
  const [widget, setWidget] = useState(['one', 'two']);

  useEffect(() => {
    getBlocks();
    items.sort((a, b) => (a.name > b.name ? 1 : -1));
    setSelectedBlocks(items);
  }, []);

  const getBlocks = async () => {
    const myblocks = await getBlocksAPI(0, 'all');
    setBlocks(myblocks);
  };

  const saveUpdates = () => {
      Apply(selectedBlocks)
  }

  const addremoveblocks = (blockid) => {
    let tempBlocks = [...selectedBlocks];
    const inList = tempBlocks.findIndex((el) => el.blockid === blockid) !== -1;
    if (!inList) {
      // the "items" array of blocks passed in includes user specific block settings (if they exist)
      // and userid is already defined. Here, a user could deselect/then reselect a block. In this
      // case we don't want to lose the settings. so we first look in "items" to see if it's there,
      // otherwise we add blank block and set the userid attribute and set settings = null
      let newblock= null;
      const idx = items.findIndex(el=>el.blockid===blockid)
      if(idx !== -1)
      {
        newblock = items[idx]
      } else {
        newblock = blocks.filter((itm) => itm.blockid === blockid)[0];
        newblock.userid=userid;
        newblock.settings=null;
        newblock.role='Block user (General)'
      }

      tempBlocks.push(newblock);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));
      setSelectedBlocks(tempBlocks);
    } else {
      tempBlocks = tempBlocks.filter((el) => el.blockid !== blockid);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));

      setSelectedBlocks(tempBlocks);
    }
  };

  const isCheckedHandler = (blockid) => {
    return selectedBlocks.map((el) => el.blockid).includes(blockid);
  };

  const updateRole = (blockid,role) => {
    const tempBlocks = [...selectedBlocks]
     const idx = tempBlocks.findIndex(itm=>itm.blockid===blockid)
     if(idx !== -1) {
      tempBlocks[idx].role = role;
      setSelectedBlocks(tempBlocks)
     }
  }

  const displayBlocks = () =>
    blocks.map((el) => (
      <div
        key={el.blockid}
        style={{ display: 'flex', margin: '10px', flexDirection: 'row', aligntItems: 'center' }}
      >
        <Checkbox
          onChange={() => addremoveblocks(el.blockid)}
          checked={isCheckedHandler(el.blockid)}
        />
        <div style={{ width: '10px' }} />
        <Icon path={el.icon} size="20px" />
        <div style={{ width: '5px' }} />
        {el.name}
      </div>
    ));

  const displayRoles = () =>
    selectedBlocks.map((el) => (
      <div key={el.blockid} style={{display:'flex', flexDirection:'column',marginBottom:'10px'}}>
        <div style={{ display: 'flex', margin: '5px', flexDirection: 'row', alignItems: 'center',justifyContent:'space-between',marginRight:'0px' }}>
        <div
          style={{ display: 'flex',marginLeft:'10px', margin: '5px', flexDirection: 'row', alignItems: 'center' }}
          key={el.blockid}
        >
          <Icon path={el.icon} size="20px" /> {el.name}
        </div>
        {/* <div><Icon path={mdiClose} size="20px" color="red"/></div> */}
          <div
              onClick={() => addremoveblocks(el.blockid)}
              css={{
                svg: { color: 'red' },
                borderRadius: '50px',
                marginRight: '30px',
                '&:hover': {
                  transition: 'all .3s ease',
                  svg: { color: 'white', borderRadius: '30px', backgroundColor: '#F6685E' },
                },
              }}
            >
              <Icon path={mdiClose} size="15px" />
            </div>
        </div>
        {/* <div style={{width:'100%'}}> */}
        <select onChange={(e)=>updateRole(el.blockid,e.target.value)} value={el.role} style={{height:'35px',marginRight:'30px',marginLeft:'10px'}}>
          <option value="Block user (General)">Block user (General)</option>
          <option value="Block user (L1 Security)">Block user (L1 Security)</option>
          <option value="Block user (L2 Security)">Block user (L2 Security)</option>
          <option value='Block builder'>Block builder</option>
        </select>
        {/* </div> */}
      </div>
    ));

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            height: '250px',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
              width: '500px',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              padding: '10px',
              backgroundColor: color,
            }}
          >
            <div style={{ color: 'white', paddingLeft: '10px' }}>
              {userid===0 ? `Step 2: Select the Blocks the user(s) can access.` : `Step 1: Select Blocks this user can access.`}
            </div>
          </div>

          <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
              width: '500px',
              border: `1px solid ${color}`,
              height: '300px',
            }}
          >
            {/* <GBButton text="Add block" color={color} Action={toggleExpand} /> */}
            {displayBlocks()}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            height: '250px',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
              width: '500px',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              padding: '10px',
              backgroundColor: color,
            }}
          >
            <div style={{ color: 'white', paddingLeft: '10px' }}>
              {userid===0 ? `Step 3: Specify a role per Block for the user(s).` : `Step 2: Specify a role per Block for this user.`}
            </div>
          </div>

          <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
              width: '500px',
              border: `1px solid ${color}`,
              height: '350px',
            }}
          >
            {selectedBlocks.length > 0 ? displayRoles() : 'No Blocks selected'}
          </div>
        </div>

        <div style={{ display:'flex',marginTop:'20px', flexDirection:'row',justifyContent:'flex-end',marginRight:'50px'}}>
          <TextButton text="Cancel" hoverColor="#75757580" Action={Close} textColor="black"  />
          <div style={{width:"20px"}}></div>
          <GBButton text="Save" color={color} width="75px" Action={saveUpdates} />
        </div>
      </div>
    </div>
  );
};

export default BlockUserControl;
