/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from 'react';
import Icon from '@mdi/react';
import { Popup,Input,Button,TextArea,Form } from 'semantic-ui-react';
import { mdiAlert, mdiAccountCircle,mdiInformationOutline,mdiEmail } from '@mdi/js';
import Papa from 'papaparse';
import GBButton from '../../../../../components/GBButton/GBButton';
import GBConfirm from '../../../../../components/GBConfirm/GBConfirm';
import Spinner from '../../../../../components/Spinner/Spinner';
import { AccountContext } from '../../../../../providers/AccountProvider';
import { getZoneAPI } from '../../../../../api/zone';
import { generateText } from '../../../../../api/ai';
import { confirmAlert } from 'react-confirm-alert';
import invalidUSRegions from '../../../../../utils/invalidUSRegions';
import CsvLoader from './csvLoader';
import Global from '../../../../../clients/global'


const TemplateStartPage = ({ color, setTemplateMode, saveTemplate,loadCSVFile }) => {
  const { userInfo } = useContext(AccountContext);
  const [isConfigured, setIsConfigured] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading]= useState(false);
  const [hasPreviousNumbers, setHasPreviousNumbers] = useState(false);
  const [query,setQuery] = useState('');
  const divRef = useRef();

  const templates = [
    {
      templateid: 1,
      name: 'Contacts',
      record: 'Contact',
      icon:
        'M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z',
    },
    {
      templateid: 2,
      name: 'Email templates',
      record: 'Template',
      icon: "M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z",
    },
    
  ];

  useEffect(() => {
    // We check if the zone has any phonenumbers already provisioned.
    // The existence of the attributes and phoneNumbers attribute indicate it's already configured.
    const checkNumber = async () => {
      const zoneInfo = await getZoneAPI(parseInt(Global.zoneid));
      if (zoneInfo.attributes !== null && zoneInfo.attributes.PhoneNumbers !== undefined) {
       
       setHasPreviousNumbers(true);
        let hasActiveNumber=false
        zoneInfo.attributes.PhoneNumbers.map(el=>{
         if(el.isActive) {
           hasActiveNumber=true
         }
       })

        if(hasActiveNumber)
        {
          setIsConfigured(true);
          //8-13-2021 Need to filter on "isActive" numbers, not all numbers.
          const currentActiveNumber = zoneInfo.attributes.PhoneNumbers.filter(el=>el.isActive)[0];
          setPhoneNumber(currentActiveNumber.number); 
        }

      }
    };
    checkNumber();
  }, []);

  const addTemplate = async (templateid) => {
    setIsLoading(true)
    const template = templates.filter((el) => el.templateid === templateid)[0];

    if(templateid===1 && ((!isConfigured && divRef.current.value === 'activate') || isConfigured)) {
      // if 1st time number, we pass in empty phoneNumber to savetemplate. It checks if emptry string, create it.
       await saveTemplate(templateid, isConfigured, phoneNumber, userInfo.phoneNumber, template, userInfo.plan);
    }  else if(templateid===2) {
      await saveTemplate(templateid, null, null, null, template, userInfo.plan);
    }
    setIsLoading(false)

  };

  const getAICSV = async() =>{
    setIsLoading(true);
    const data = await generateText(query,true);
    const results = Papa.parse(data);
    loadCSVFile(results.data);
    setIsLoading(false);
  }


  const confirmAction = (templateid,title,message,showInput=true) => {
    
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={title}
            action={showInput ? addTemplate : onClose}
            actionValue={templateid}
            buttonLabel="OK"
            message={message}
            width="400px"
            divRef={divRef}
            showInput={showInput}
            showCancelButton={showInput}
            confirmAction=""
            confirmWord="activate"
            height="430px"
            onClose={onClose}
          />
        );
      },
    });

  };




  const MobileRequiredMessage = () => {

    const message =<div>In order to add text messaging, we need a validated phone number on file associated
              with your record. Please go to your Profile page
              <Icon path={mdiAccountCircle} size="25px" />
              in the top right area of this page, add a mobile phone number, and verify it. Then you
              will be able to proceed with this action.</div>

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Phone validation"
            action={onClose}
            showCancelButton={false}
            buttonLabel="OK"
            message={message}
            showInput={false}
            height="430px"
          />
        );
      },
    });
  };

  const checkMessagingConfiguration = async () => {
    setIsLoading(true);


    if (userInfo.mobileverifydate===null) {
      MobileRequiredMessage();
    } else if (!isConfigured) {

      const currentRole=userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].role;
      const areaCode = parseInt(userInfo.phoneNumber.slice(2,5))
      let message='';

      if(phoneNumber==='' && hasPreviousNumbers){
        message= <div>This Zone currently has no active phone numbers. In order to use messaging, please purchase a phone number from the Zone settings page.</div>
        confirmAction(1,"No active phone numbers",message,false);
      }
      else if(userInfo.phoneNumber.slice(0,2)==='+1' && !invalidUSRegions.includes(areaCode)){
        message= <div>Messaging has not yet been activated in this Zone. By using the text messaging template, you will activate the free 30-day messaging trial which allows send/receive your first few messages using some initial funds we have provided. To continue using messages beyond this time-period or amount, a Zone Owner will need to upgrade this Zone to a paid plan and also purchase message funds. <p/>Enter the word{' '}
        <strong><u>activate</u></strong> into the text box below to continue.</div>
        confirmAction(1,"Text messaging activation warning",message,true);
      } 
      else if ((userInfo.phoneNumber.slice(0,2) !=='+1' || invalidUSRegions.includes(areaCode)) && currentRole==='Zone owner'){
        message=<div>
        This Zone is currently on the free plan. Text messaging can not be activated until you upgrade to a paid plan. Click Upgrade Now in the top left corner of from the View all my GraceBlocks Homepage to get started. You will also need to purchase a phone number in your desired country and message funds once you are on a paid plan. These steps can be completed from the page you will land on once you complete the upgrade process. (Zone settings page - also accessed via the gear icon next to the Zone name in the left side navigation panel of the View all my GraceBlocks Homepage.)
      </div>
        confirmAction(1,"Upgrade to activate text messaging",message,false);
      } else if ((userInfo.phoneNumber.slice(0,2) !=='+1' || invalidUSRegions.includes(areaCode)) && currentRole !=='Zone owner') {
        message=<div>
           This Zone is currently on the free plan and text messaging can not be activated until you upgrade to a paid plan. Contact your Zone owner for more details.
          </div>
        confirmAction(1,"Upgrade to activate text messaging",message,false);
      }
    } else {
      await addTemplate(1);
    }

    setIsLoading(false);
  };

  return (
    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
    {!isLoading ? (<>
      <div style={{ marginLeft: '35px' }}>Select how you'd like to initiate creating this tab:</div>
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          flexDirection: 'row',
          alignItems: 'center',

        }}
      >
        <hr style={{ marginLeft: '30px', border: '1px solid #707070', width: '100%' }} />
        <div
          style={{
            width: '100px',
            marginLeft: '10px',
            marginRight: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          FROM SCRATCH
        </div>
        <hr style={{ marginRight: '30px', border: '1px solid #707070', width: '100%' }} />
      </div>
     

      <div style={{display:'flex', justifyContent:'center',  marginTop: '20px' }}>
       
        <GBButton
          Action={setTemplateMode}
          textHoverColor="#757575"
          border="1px solid #707070"
          borderRadius="5px"
          textColor="#312602"
          ActionValue="edit"
          text="Start from scratch (no template)"
          width="250px"
          height="50px"
          color="white"
        />
      </div>

      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          flexDirection: 'row',
          alignItems: 'center',

        }}
      >
        <hr style={{ marginLeft: '30px', border: '1px solid #707070', width: '100%' }} />
        <div
          style={{
            width: '110px',
            marginLeft: '10px',
            marginRight: '35px',
            whiteSpace: 'nowrap',
          }}
        >
          FROM A CSV FILE <Popup
            content={<div>
              Click to upload or drag into this box to proceed. This file must be a .csv (comma separated file).
              The first row will be converted to field names.<p/><p/>
              For more support visit this help article <a href="https://www.graceblocks.com/support-article/adding-a-tab-by-uploading-a-csv-file" target="_newwindow">Adding a tab by uploading a CSV file</a>
            </div>}
            hoverable
            mouseEnterDelay={200}
            // mouseLeaveDelay={1000}
            // on='click'
            trigger={
                <Icon path={mdiInformationOutline} size="20px" />
            }
          />

        </div>
        <hr style={{ marginRight: '30px', border: '1px solid #707070', width: '100%' }} />
      </div>
      <div style={{display:'flex', justifyContent:'center',  marginTop: '20px' }}>
      <CsvLoader color={color} loadCSVFile={loadCSVFile} plan={userInfo.plan} />
      </div>

      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          flexDirection: 'row',
          alignItems: 'center',

        }}
      >
        <hr style={{ marginLeft: '30px', border: '1px solid #707070', width: '100%' }} />
        <div
          style={{
            width: '190px',
            marginLeft: '10px',
            marginRight: '35px',
            whiteSpace: 'nowrap',
          }}
        >
          GENERATE USING GOOGLE AI <Popup
            content={<div>
               Example 1: Generate a list with the following fields: First Name, Last Name, Email, Phone Number, City, State, Postal (the tab will be created with these fields populated with a few rows of dummy date).
               <br/><br/>
               Example 2: Generate a list of ice cream stores in Boston include their name, address and phone number. (The tab will be created with a list of ice cream stores in Boston with three columns (name, address, phone number).
               <br/><br/>
              <a href="https://www.graceblocks.com/support-article/graceblocks-ai-capabilities-for-builders" target="_newwindow">Click here to learn more</a>
            </div>}
            hoverable
            mouseEnterDelay={200}
            // mouseLeaveDelay={1000}
            // on='click'
            trigger={
                <Icon path={mdiInformationOutline} size="20px" />
            }
          />

        </div>
        <hr style={{ marginRight: '30px', border: '1px solid #707070', width: '100%' }} />
      </div>
      <div style={{ marginTop: '20px',width:'80%', alignSelf:'center' }}>
      <Form>
    <TextArea value={query} placeholder='Enter a prompt that begins with "Generate a list..." hover over the information icon to see examples' onChange={(e,data)=>setQuery(data.value)}  />
    <div style={{height:'10px'}}/>
    <div style={{marginLeft:'300px'}}>
    <GBButton
          Action={getAICSV}
          textHoverColor="#757575"
          border="1px solid #707070"
          borderRadius="5px"
          textColor="#312602"
          ActionValue="edit"
          text="Generate tab from prompt"
          width="200px"
          height="40px"
          color="white"
        />
        </div>
  </Form>
        {/* <Input placeholder="Type in the fields you want included, or type prompt to import list of data" value={query} fluid onChange={(e,data)=>setQuery(data.value)}  action={
            <React.Fragment>
            <Button icon="play circle" onClick={() => getAICSV()} />
          </React.Fragment>
        } />  */}
      </div>


      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          marginBottom: '30px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <hr style={{ marginLeft: '30px', border: '1px solid #707070', width: '100%' }} />
        <div
          style={{
            width: '100px',
            marginLeft: '10px',
            marginRight: '20px',
            whiteSpace: 'nowrap',
          }}
        >
          FROM A TEMPLATE
        </div>
        <hr style={{ marginRight: '30px',marginLeft:'10px', border: '1px solid #707070', width: '100%' }} />
      </div>
      <div style={{ alignSelf: 'center',paddingBottom:'20px' }}>
        <GBButton text="Text messaging" height="50px" borderRadius="5px" Action={checkMessagingConfiguration} color={color} width="250px" />
        <div style={{height:'20px'}}></div>
        <GBButton text="Email templates" height="50px" borderRadius="5px" Action={addTemplate} ActionValue={2} color={color} width="250px" />
      </div>
      </>) : (<Spinner color={color} />)}
    </div>
  );
};

export default TemplateStartPage;
