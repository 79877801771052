/* eslint-disable */
import React, { useEffect } from 'react';
import { useState } from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiKey, mdiFormatPageBreak,mdiBrightness1 } from '@mdi/js';
import { getTablesAPI } from '../../api/tables';

const TabBar = ({ blockId, color, LoadTableApi }) => {
  const [tables, setTables] = useState([]);
  const [hoveringKey, setHoveringKey] = useState(0);
  const [selectedKey, setSelectedKey] = useState(0);
  const [subKey, setSubKey] = useState(null);

  useEffect(() => {
    loadTables();
  }, []);

  const updateSelection = (key) => {
    setSubKey(null);
    setSelectedKey(key);
    LoadTableApi(key,null);

  };

  const updateSubSection = (subKey) => {
      setSubKey(subKey)
      LoadTableApi(selectedKey,subKey);
  }

  const loadTables = async () => {
    const tbls = await getTablesAPI(blockId, 3);
    const GS = {
      gbicon: mdiBrightness1,
      isSystemTable: false,
      key: 'start',
      recordname: 'start',
      text: 'Getting started',
      value: 'start',
    };
    const authentication = {
      gbicon: mdiKey,
      isSystemTable: false,
      key: 'authenticate',
      recordname: 'authenticate',
      text: 'Authentication',
      value: 'authenticate',
    };
    const errors = {
      gbicon: mdiAlertCircleOutline,
      isSystemTable: false,
      key: 'errors',
      recordname: 'errors',
      text: 'Errors',
      value: 'errors',
    };
    const paging = {
      gbicon: mdiFormatPageBreak,
      isSystemTable: false,
      key: 'paging',
      recordname: 'paging',
      text: 'Pagination',
      value: 'paging',
    };

    tbls.unshift(paging);
    tbls.unshift(errors);
    tbls.unshift(authentication);
    tbls.unshift(GS);

    console.log(tbls);
    setTables(tbls);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        borderRight: '1px solid black',
        overflow: 'auto',
      }}
    >
      {tables.length > 0
        ? tables.map((tbl) => (
            <>
              <div
                onMouseOver={() => setHoveringKey(tbl.key)}
                onMouseOut={() => setHoveringKey(null)}
                onClick={() => updateSelection(tbl.key)}
                style={{
                  backgroundColor:
                    selectedKey === tbl.key && subKey===null ? color : hoveringKey === tbl.key ? `${color}1A` : null,
                  color: selectedKey === tbl.key && subKey===null ? 'white' : color,
                  whiteSpace: 'nowrap',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Icon
                  path={tbl.gbicon}
                  size="25px"
                  color={selectedKey === tbl.key && subKey===null ? 'white' : color}
                />
                <div
                  style={{
                    marginLeft: '10px',
                    fontSize: '15px',
                    color: selectedKey === tbl.key && subKey===null ? 'white' : color,
                  }}
                >
                  {tbl.text}{' '}
                </div>
              </div>
              {selectedKey === tbl.key && !['start','authenticate','errors','paging'].includes(tbl.key) ? (
                <>
                  <div
                    style={{
                      cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'fields' ? color : null,
                      color: subKey === 'fields' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('fields')}
                  >
                    Fields
                  </div>
                  <div
                    style={{
                        cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'listRecords' ? color : null,
                      color: subKey === 'listRecords' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('listRecords')}
                  >
                    List records
                  </div>
                  <div
                    style={{
                        cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'getRecord' ? color : null,
                      color: subKey === 'getRecord' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('getRecord')}
                  >
                    Get record
                  </div>
                  <div
                    style={{
                        cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'createRecords' ? color : null,
                      color: subKey === 'createRecords' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('createRecords')}
                  >
                    Create records
                  </div>
                  <div
                    style={{
                        cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'updateRecords' ? color : null,
                      color: subKey === 'updateRecords' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('updateRecords')}
                  >
                    Update records
                  </div>
                  <div
                    style={{
                      cursor:'pointer',
                      fontSize:'16px',
                      padding:'5px',
                      paddingLeft:'45px',
                      backgroundColor: subKey === 'deleteRecords' ? color : null,
                      color: subKey === 'deleteRecords' ? 'white' : null,
                    }}
                    onClick={() => updateSubSection('deleteRecords')}
                  >
                    Delete records
                  </div>
                </>
              ) : null}
            </>
          ))
        : null}
    </div>
  );
};

export default TabBar;
