/* eslint-disable */
import React, { useState, useEffect, table } from 'react';
import { mdiClose } from '@mdi/js';
import { toast } from 'react-toastify';
import { Form, TextArea } from 'semantic-ui-react';
import BlockUserControl from '../Renderers/BlockUserControl';
import TextButton from '../../../../components/TextButton/TextButton';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import {addNewUsers} from '../../../../api/users'

const AddUsers = ({ color, table, close, closeModalandUpdate  }) => {
  const [emails, setEmails] = useState();
  const [sendInvitation,setSendInvitation] =useState(false);

  const toggleSend=()=>{
    setSendInvitation(!sendInvitation);
  }

  const updateUsers = async (blocks) => {
    if(blocks.length>0 && emails !==undefined) {
        const result = await addNewUsers(emails,blocks,sendInvitation);
        if(result.error){
            const finalError=<div>{result.errorMessage} Please update under Zone settings &#62; Plan information if you wish to add more user licenses. <u><a style={{color:'white'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newWindow">Learn more here.</a></u></div>
            toast.info(finalError, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:7000,
              })
        } else {
            closeModalandUpdate();
        }
    }
  };

  return (
    <div>
      <div
        style={{
          height: '42px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
          backgroundColor: color,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            Add new user(s)
          </div>
        </div>
        <TextButton
          text="Hide panel when done"
          textColor="white"
          hoverColor="#FFFFFF80"
          icon={mdiClose}
          Action={close}
        />
      </div>

      <div
        style={{
          display: 'flex',
          height: '200px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            width: '500px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '10px',
            backgroundColor: color,
          }}
        >
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            Step 1: Enter the email address for the user(s) you wish to add.
          </div>
        </div>

        <div
          style={{
            paddingTop: '10px',
            overflow: 'auto',
            paddingLeft: '10px',
            width: '500px',
            border: `1px solid ${color}`,
            height: '300px',
          }}
        >
          <Form>
            <TextArea
              style={{
                fontSize: '12px',
                border: '1px solid black',
                borderRadius: '10px',
                width: '470px',
              }}
              onChange={(e, data) => setEmails(data.value)}
              value={emails}
              placeholder="Enter one or multiple email addresses. Separate multiple emails using a semicolon without spaces. 
          Example: jsmith123@graceblocks.com;mwallace132@hotmail.com"
            />
          </Form>
          <p/><p/>
          <GBSwitch isChecked={sendInvitation} color={color} text="Send invitation when saving" Action={toggleSend} />
        </div>
      </div>
      <BlockUserControl Close={close} items={[]} Apply={updateUsers} userid={0} color={color} />
      <div style={{marginBottom:'20px'}}></div>
    </div>
  );
};

export default AddUsers;
