/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';


export const getWorkFlows = async () => {
    const payload={dbfunction:'getWorkFlows'}
    const dataresponse = await cloudrun.post('/getWorkflowsAPI', {payload})

     return dataresponse
  }

export const getWorkflow = async (workflowid) =>{
    const payload={dbfunction:'getWorkflow',workflowid}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

export const getWorkflowHistory = async (workflowid,tableid) =>{
   const payload={workflowid,tableid}
   const dataresponse = await cloudrun.post('/workflowHistory', {payload})
   return dataresponse
}

export const updateWorkFlow = async (workflow) =>{
   const payload={workflow, dbfunction:'updateWorkflow'}
   const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

   await cloudrun.post('/refreshWorkflowCache', {payload})

   return dataresponse
}

export const updateWorkFlowName = async (name, workflowid) =>{
   const payload={name,workflowid, dbfunction:'updateWorkFlowName'}
   const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
   return dataresponse
}

export const updateWorkFlowStatus = async (workflowid,isactive) =>{

   const payload={isactive,workflowid, dbfunction:'updateWorkFlowStatus'}
   const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

   await cloudrun.post('/refreshWorkflowCache', {payload})

   return dataresponse
}

export const addWorkFlow = async (name) =>{
   const payload={name, dbfunction:'addWorkflow'}
   const result = await cloudrun.post('/executeQueryAPI', {payload})
   return result[0].workflowid;
}

export const deleteWorkFlow = async (workflowid) =>{
   const payload={workflowid, dbfunction:'deleteWorkflow'}
   await cloudrun.post('/executeQueryAPI', {payload})
   return "success";
}

export const duplicateWorkFlow = async (workflowid) =>{
   const payload={workflowid, dbfunction:'duplicateWorkflow'}
   await cloudrun.post('/executeQueryAPI', {payload})
   return "success";
}

  