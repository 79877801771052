/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input,Form, Radio } from 'semantic-ui-react';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import { MapOptionValuesToText } from '../../../../api/GetData';

class MaskedField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo, selectedUIType } = this.props;

    this.state = {
      showAdvanced: false,
      maxlength: columnConfigData.maxlength !== undefined ? columnConfigData.maxlength : 255,
      callout: columnConfigData.callout,
      defaultText: columnConfigData.defaultText,
      uitype: selectedUIType,
      tableinfo,
      specificvalue: columnConfigData.maskType !==undefined ?  columnConfigData.maskType : 'full',
      maskCharacters: columnConfigData.maskCharacters !==undefined ? columnConfigData.maskCharacters : 4,
      uniformLength:columnConfigData.uniformLength !==undefined ? columnConfigData.uniformLength : null,
      isPrivate:columnConfigData.isPrivate ?? false
      //   defaultText: '',
    };
  }

  static contextType = AccountContext;

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedUIType !== prevProps.selectedUIType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ uitype: this.props.selectedUIType });
    }
  }

  handleChange = (value) => {
    // const { specificvalue } = this.state;
    this.setState({ specificvalue: value });
  };

  Save = async () => {
    const { maxlength, callout, defaultText, tableinfo, uitype,specificvalue,maskCharacters,uniformLength,isPrivate } = this.state;
    const { field, tableid, header, selectedUIType, columnConfigData } = this.props;

    const originalUIType = columnConfigData.uitype;

    try {
      // Update physical field in DB with default text.
      if (defaultText !== undefined) {
        const payload = { field, tableid, defaultText, uitype: selectedUIType };
        await cloudrun.post('/updateFieldDefaultText', { payload });
      }

      const updatedNode = structuredClone(columnConfigData);
      updatedNode.header= header;
      updatedNode.uitype=uitype;
      updatedNode.displayfield=field;
      updatedNode.maskType = specificvalue;
      updatedNode.maskCharacters=maskCharacters;
      if(uniformLength !==null && uniformLength !=='') {
      updatedNode.uniformLength=uniformLength;
      } else {
        delete updatedNode.uniformLength
      }

      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }
      
      updatedNode.renderer='TextRender';
      updatedNode.editor='MaskEditor';

      // Update Tableinfo with new settings.
      const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
      if (rowindex !== -1) {
        if (maxlength !== '') {
          updatedNode.maxlength = maxlength >255 ? 255 : maxlength;
        } else if(uitype===2){
          updatedNode.maxlength=255;
        } else {
          delete updatedNode.maxlength
        }

        updatedNode.callout = callout;
         // if changed uitype remove concat attribute.
         // main index calls the api to recalcualte view.
        if(originalUIType !==uitype){
          delete updatedNode.concat
        }
        
        updatedNode.defaultText = defaultText;
        tableinfo.columns[rowindex] = updatedNode;

        // if we are coming from numeric field OR an ID field, convert numeric back to text.
        if (originalUIType === 23) {
          const payload = {
            tableid,
            tableinfo,
            field,
            newfieldtype: 'citext',
            castFunction: 'cast_numeric_to_text',
          };
          await cloudrun.post('/convertField', { payload });
        } else if (originalUIType === 22) {
          const payload = {
            tableid,
            tableinfo,
            field,
            newfieldtype: 'citext',
            castFunction: 'cast_date_to_text',
          };
          await cloudrun.post('/convertField', { payload });
        } else if(originalUIType ===17) {
          const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
          await cloudrun.post('/convertField', { payload })
        } else if(originalUIType===3 || maxlength < columnConfigData.maxlength) {
          const max = maxlength !== undefined ? maxlength : 255
          const payload = {tableid,field,maxlength:max,dbfunction:'truncateFieldText'};
          await cloudrun.post('/executeQueryAPI', { payload })
        }

        // if we're coming from select field, we need to map optios to text values
        // and then remove associated/related tables. This is doen via removefield API passing removeStructureOnly=true
        else if (originalUIType === 6 || originalUIType === 7) {
          delete updatedNode.lookup;
          delete updatedNode.enableColors;

          const table = `tbl_${tableid}`;
          await MapOptionValuesToText(table, columnConfigData.data,maxlength);

          const payload = {
            tableid,
            fieldname: columnConfigData.data,
            removeRelatedStructureOnly: true,
          };
          await cloudrun.post('/removeField', { payload });
        } else if(originalUIType===10 || originalUIType===11){
          delete updatedNode.validator;
        }

        // we update the meta data last, otherwise functions above would be retreiving wrong starting metadata.
        await updateTableInfoAPI(tableid, tableinfo,columnConfigData);

        return 'success';
      }
    } catch (error) {
      console.log(error);
      return `An Error Occurred: ${error.message}`;
    }
    return 'Error, no matching column foiund';
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  render() {
    const { showAdvanced, callout, defaultText, uitype,specificvalue,maskCharacters,uniformLength,isPrivate } = this.state;
    const { color } = this.props;
    return (
      <div>
        <div style={{marginTop: '5px' }}>
        <div>
              A masked field allows you to mask the entire value or do a partial mask displaying trailing specified number of characters.
        </div>
        <div style={{fontWeight:'bold',marginTop:'10px'}}>Mask display</div>
        <div>
          <Form>
            <Radio
                      label="Fully masked"
                      name="radioGroup1"
                      value="full"
                      checked={specificvalue === 'full'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Radio
                      label="Mask all but last"
                      name="radioGroup1"
                      value="partial"
                      checked={specificvalue=== 'partial'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'5px',paddingBottom:'10px',width:'100px'}}>
                   <div style={{width:'75px',marginRight:'5px'}}>
                    <Input type="number" style={{width:'75px'}} fluid value={maskCharacters} onChange={(e,data)=>this.setState({maskCharacters:data.value})} />
                    </div>
                   
                    <div>characters</div>
                    </div>
                    </div>
             {specificvalue==='full' ? (
             <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
               <div style={{marginRight:'5px'}}> Uniform Mask Length: </div> <Input type="number" style={{width:'100px'}} fluid value={uniformLength} onChange={(e,data)=>this.setState({uniformLength:data.value})} />
           </div>) : null}
          </Form>
        </div>
        </div>
        
        <div style={{height:'15px'}}></div>
        <GBSwitch
          color={color}
          text="More field attributes"
          isChecked={showAdvanced}
          Action={this.showAdvancedHandler}
        />

        {showAdvanced ? (
          <div>
             <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                <div style={{ marginBottom: '3px' }}>
                  <strong>Maximum field length</strong>
                </div>
                <div
                  style={{
                    width: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    size="small"
                    type="number"
                    max="255"
                    value={this.state.maxlength || ''}
                    onChange={(e, data) => this.setState({ maxlength: data.value })}
                    style={{ width: '100px' }}
                    placeholder="max characters"
                  />
                  <span style={{ marginLeft: '3px' }}> Characters </span>
                </div>
              </div>
            

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <div style={{ marginBottom: '3px' }}>
                <strong>Default text</strong>
              </div>
              <Input
                size="small"
                onChange={(e, data) => this.setState({ defaultText: data.value })}
                value={defaultText || ''}
                placeholder="Enter default text (optional)"
              />
            </div>
            <Callout callout={callout} updateCallout={this.CalloutHandler} />
          </div>
        ) : null}
      </div>
    );
  }
}

MaskedField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default MaskedField;
