/* eslint-disable */
import "ace-builds/src-noconflict/mode-java";
import {functions} from './functions'

function createCustomHighlightRules(fields) {
  class CustomHighlightRules extends window.ace.acequire(
    "ace/mode/text_highlight_rules"
  ).TextHighlightRules {
    constructor() {
      super();
      const keywords =['DISTINCT','WHEN','THEN','END','ELSE','NULL']
      this.$rules = {
        start: [
          {
            token: "support.function",
            regex: '\\b(' + functions.join('|') + ')\\b'
          },
          {
            token: "variable",
            regex: /{[^{}]*}/
            },
          {
            token: "keyword",
            regex: '\\b(' + keywords.join('|') + ')\\b'
          },
          {
            token: "string",
            regex: "'[^']*'"
          }
        ]
      };
    }
  }
  return CustomHighlightRules;
}

export default class CustomMode extends window.ace.acequire("ace/mode/java").Mode {
  constructor(fields) {
    super();
    const CustomHighlightRules = createCustomHighlightRules(fields);
    this.HighlightRules = CustomHighlightRules;
  }
}