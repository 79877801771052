/* eslint-disable */
const AttachmentExtensions =
[
{ext:'jpg',contentType:'image/jpg'},
{ext:'jpeg',contentType:'image/jpg'},
{ext:'gif',contentType:'image/gif'},
{ext:'png',contentType:'image/png'},
{ext:'bmp',contentType:'image/bmp'},
{ext:'tiff',contentType:'image/tiff'},
{ext:'mp4',contentType:'video/mp4'},
{ext:'mpeg4',contentType:'video/mp4'},
{ext:'3gpp',contentType:'video/3gpp'},
{ext:'mov',contentType:'video/mov'},
{ext:'wmv',contentType:'video/wmv'},
{ext:'mpeg',contentType:'video/mpeg'},
{ext:'flv',contentType:'video/flv'},
{ext:'pdf',contentType:'application/pdf'},
{ext:'txt',contentType:'text/lain'},
{ext:'css',contentType:'text/css'},
{ext:'html',contentType:'text/plain'},
{ext:'doc',contentType:'application/msword'},
{ext:'docx',contentType:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
{ext:'xls',contentType:'application/vnd.ms-excel'},
{ext:'xlsx',contentType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
{ext:'ppt',contentType:'application/vnd.ms-powerpoint'},
{ext:'pptx',contentType:'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
{ext:'gd', contentType:'application/googledoc'}, //added as placeholder for any google doc url, so that we have value saved in system we could refer to finding this type of document.
{ext:'yt', contentType:'application/youtube'}, //added 3-21-2021 support for embedding youtube videos.
{ext:'vm', contentType:'application/mp4'}, //added 10-29-2021 support for vimeo videos
{ext:'zip',contentType:'application/zip'},
]

export default AttachmentExtensions;