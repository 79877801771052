/* eslint-disable */
import React from 'react';
import Switch  from 'react-switch';

const GBSwitch = ({isChecked=false,disabled=false,Action,ActionValue,ActionValue2=null,color,text,fontWeight="normal"}) => {
    
  // 6-15-2022 added the paddingTop:'2px' on text, as it seems to be floating up on div for some reason. can't find 
  //why , but adding padding seems to fix appearance for now.
    return (
        <div style={{display:'flex',  flexDirection:'row',alignItems:'center',width:'100%'}}>
        <Switch
          disabled={disabled}
          width={30}
          height={13}
          uncheckedIcon={false}
          handleDiameter={10}
          onColor={color}
          offColor='#d3d3d3'
          checkedIcon={false}
          onChange={ActionValue2 !== null ? ()=>Action(ActionValue,ActionValue2) : ()=>Action(ActionValue)}
          checked={isChecked}
        /> 
        <div style={{marginLeft:'10px',color,fontWeight,paddingTop:'2px',}}>{text}</div>
      </div>
    )
}

export default GBSwitch