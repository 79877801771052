/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component,useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { css, jsx } from '@emotion/core'
import { v4 as uuidv4 } from 'uuid';
import { mdiDragVertical, mdiPlus,mdiSortAlphabeticalAscending} from '@mdi/js';
import Icon from '@mdi/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { AccountContext } from '../../../../providers/AccountProvider';
import OptionColorPicker from './OptionColorPicker';
import { getOptionsAPI, updateAttachmentsAPI } from '../../../../api/options';
import OptionItem from './OptionItem';
import { updateTableInfoAPI, getTableAPI } from '../../../../api/tables';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Colors from '../../../../utils/GBColors';
import Callout from './Callout';
import Global from '../../../../clients/global'


class AttachmentField extends Component {
  grid = 4;
  
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    const { columnConfigData } = this.props;
    this.state = {
      items: [],
      deletedItems: [],
      modalIsOpen: false,
      selectedItem: {},
      x: 0,
      y: 0,
      showAdvanced: false,
      enableColors: true,
      isEditable: true,
      lookup: columnConfigData.lookup ? columnConfigData.lookup : 'multiple',
      callout: columnConfigData.callout ? columnConfigData.callout : '',
      allowCategories: columnConfigData.allowCategories ? columnConfigData.allowCategories : false, 
      ZoneToUse: {}, // Added 11-30-2020 to get current zone plan and user role. Used to determine if they can add custom colors.
      isPrivate: columnConfigData.isPrivate ?? false
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async componentDidMount() {
    const { columnConfigData,updateHeight,hasData } = this.props;
    const isrunning=false

    const { userInfo} = this.context;
    const zoneList = [...userInfo.zones];
    const tempZone = zoneList.filter(itm=>itm.id===parseInt(Global.zoneid))[0];
   
    // Fetch current list of items for this field/table if this has already been defined as attatchment field.
    if (columnConfigData.renderer === 'AttachmentsRenderer' ) {
      await this.FetchOptions();
    }

    updateHeight(600);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    width: 250,
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    width: '300px',
    padding: 2,
    margin: `0 0 ${this.grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getAlert() {
    this.alert('getAlert from Child');
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  updateItem = async (optionid, newvalue,isAddOption) => {
    const { items } = this.state;
    const tmpitems = [...items];

    tmpitems.filter((el) => el.optionid === optionid)[0].option = newvalue;
    this.setState({ items: tmpitems })
    // 6-23-2020 . Added param "isAddOption". When editing a list of items, if user clicks off item, it will
    // update item only. However, if they click "enter" it should save and then add new option.
    if(isAddOption){

      // 6-23-2020 use findindex to get current spot, then pass index+1 to addoption so new
      // option gets inserted right below the item you just edited.
      const rowIndex = tmpitems.findIndex(itm => itm.optionid===optionid)
      this.addOption('', rowIndex+1)
    }
  };

  // Adds a new option to array. We prefix the option with "id" to ensure
  // it is unique versus the retrieved id's.
  addOption = (option='',insertAt) => {
    const { items } = this.state;
    const newitems =  [...items]
    const randomid = uuidv4();
    const colorid = Math.floor(Math.random() * Math.floor(28));
    // const shortOption = option.slice(0,255)
    const newitem = {
      optionid: `id${randomid.toString()}`,
      option,
      attributes: { color: Colors[colorid].color, text: Colors[colorid].text },
    };
    
    // const tmpitems = newitems.concat(newitem);
    newitems.splice(insertAt !== undefined ? insertAt : items.length,0,newitem);
    // console.log(newitems)
   
    this.setState({ items: newitems });
  };

  updateOptionColor = (optionid, color, textcolor) => {
    const copyofItems = [...this.state.items];
    const rowIndex = copyofItems.findIndex((el) => el.optionid === optionid);
    if (rowIndex !== -1) {
      copyofItems[rowIndex].attributes = { color, text: textcolor };
      this.setState({ items: copyofItems, modalIsOpen: false });
    } else {
      this.setState({ modalIsOpen: false });
    }
  };

  deleteOption = (optionid) => {

    if(optionid===1) {
      toast.info(
        <div style={{ margin: '5px' }}>
         You cannot delete the Uncategorized category.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: true,
        },
      );
      return false;
    }

    const { items, deletedItems } = this.state;
    const filteredItems = [...items].filter((el) => el.optionid !== optionid);
    const tempDeletedItems = [...deletedItems];
    if(typeof optionid ==='number')
    {
      tempDeletedItems.push(optionid)
    }

    this.setState({ items: filteredItems, deletedItems: tempDeletedItems });

  };

  pickColor = (e, item) => {
    this.setState({ modalIsOpen: true, x: e.clientX, y: e.clientY, selectedItem: item });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    // document.body.style.overflow = this.state.originalBodyOverflow;
  };

  FetchOptions = async () => {
    const { columnConfigData,tableid } = this.props;

    if(this.state.items.length===0) {
    const options = await getOptionsAPI(columnConfigData.data,tableid);

    this.setState({
      items: options.filter(itm=>itm.optionid !== '1'),
      enableColors:
        columnConfigData.enableColors !== undefined ? columnConfigData.enableColors : true,
    });
  }
  
  };


  SortAlphaOptions = () => {
    const { items } = this.state;
    items.sort((a, b) => (a.option > b.option ? 1 : -1));
    this.setState({ items });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
   
    const { columnConfigData, tableid, uitype, header } = this.props;
    const { callout, allowCategories,lookup,isPrivate } = this.state;
    const cleanItems = this.state.items.filter(el=>el.option !=='')
    const originalUIType = columnConfigData.uitype;
  
    try {
    // Save options
    await updateAttachmentsAPI(
      cleanItems,
      this.state.deletedItems,
      columnConfigData.data,
      tableid,
      columnConfigData.uitype,
      uitype,
      lookup
    );

    //  Save callout & enableColors attributes
    const {tableinfo} = await getTableAPI(tableid);

    const rowindex = tableinfo.columns.findIndex((el) => el.data === columnConfigData.data);
    if (rowindex !== -1) {
      tableinfo.columns[rowindex].header = header;
      tableinfo.columns[rowindex].uitype = uitype;
      tableinfo.columns[rowindex].lookup = lookup;
      tableinfo.columns[rowindex].allowCategories = allowCategories;
      tableinfo.columns[rowindex].renderer = 'AttachmentsRenderer';

      tableinfo.columns[rowindex].callout = callout;
      delete tableinfo.columns[rowindex].type
      delete tableinfo.columns[rowindex].editor;
      delete tableinfo.columns[rowindex].renderandedit;
      delete tableinfo.columns[rowindex].concat; // 8-14-2020 remove concats so field is not in record identifier.

      if(isPrivate) {
        tableinfo.columns[rowindex].isPrivate =true;
      } else {
        delete tableinfo.columns[rowindex].isPrivate;
      }

      // if we are coming from numeric field, convert numeric back to text.
      if(originalUIType===23)
      {
        const payload = { tableid, tableinfo, field: columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_numeric_to_text' };
        await cloudrun.post('/convertField', { payload })
      } else if (originalUIType===22) // coming from a date field
      {
        const payload = { tableid, tableinfo, field:columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
      }  else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field:columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
      }

      await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
    }
    return "success"
  } catch (error) {
    console.log(error.message)
    return `An error occurred: ${error.message}`
  }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  enableMultipleHandler = (isChecked) => {
     const {lookup,allowCategories} = this.state;

    this.setState({lookup: lookup==='single' ? 'multiple' :'single',allowCategories:!isChecked ? false : allowCategories })
    
  }

  allowCategoriesHandler =() => {
      const {allowCategories} = this.state;
      this.setState({allowCategories: !allowCategories})
  }

  render() {
    const { showAdvanced, lookup, callout,isEditable,ZoneToUse,allowCategories,enableColors,isPrivate} = this.state;
    const {color,ShowError} =this.props;
    return (
      <div>
        <div>
          Users will be able to add one or possibly multiple attachments into this field.
        </div>
     
       <div style={{height:'20px'}}/>
       <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} /> 
    

        {showAdvanced ? (<>
          <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
        {/* <div style={{height:'10px'}}/> */}
        <GBSwitch color={color} text="Allow multiple attachments" isChecked={lookup==='multiple'} Action={this.enableMultipleHandler} />
        <div style={{height:'10px'}}/>
        <GBSwitch color={color} text="Classify attachments by type" disabled={lookup==='single'}  isChecked={allowCategories && lookup==='multiple' } Action={this.allowCategoriesHandler} />
        
        {allowCategories ? (<>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.optionid} draggableId={item.optionid} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <OptionItem
                          option={item}
                          isEditable={isEditable}
                          pickColor={this.pickColor}
                          updateItem={this.updateItem}
                          enableColors={enableColors}
                          deleteOption={this.deleteOption}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                position: 'relative',
                width: '425px',
                height: '250px',
                top: this.state.y + 5,
                left: this.state.x - 220,
                background: '#fff',
                overflow: 'auto',
                zIndex: '500',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '15px',
                boxShadow: '5px 10px #888888',
                outline: 'none',
                padding: '10px',
              },
            }}
            contentLabel="Option Color Picker"
          >
            <OptionColorPicker
              activeZone={ZoneToUse}
              OptionData={this.state.selectedItem}
              color={this.props.color}
              ShowError={ShowError}
              UpdateColor={this.updateOptionColor}
              close={this.closeModal}
              component="Attachments"
            />
          </Modal>
        </div>

        <div css={{display:'flex', flexDirection:'row', alignItems:'center', width:'155px',paddingLeft:'5px',cursor: 'pointer', marginLeft: '15px','&:hover': {backgroundColor: '#F0EFEF',borderRadius:'10px'} }}>
          <Icon path={mdiPlus} size="20px" />
          <span
            onClick={() => this.addOption()}>
            Add category type
          </span>
        </div>
        </>) : null}
      
        <div style={{height:'15px'}}/>
        
       
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '10px',
              }}
            >
            </div>
            <Callout callout={callout} updateCallout={this.CalloutHandler} />   
          </div>
        </>) : null}
      </div>
    );
  }
}

AttachmentField.propTypes = {
  tableid: PropTypes.number.isRequired,
  uitype: PropTypes.number.isRequired,
  columnConfigData: PropTypes.object.isRequired,
};

export default AttachmentField;
