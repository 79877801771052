/* eslint-disable */
export const AlphaCodes= [
    {setting:100,code:'FF'},
    {setting:99,code:'FC'},
    {setting:98,code:'FA'},
    {setting:97,code:'F7'},
    {setting:96,code:'F5'},
    {setting:95,code:'F2'},
    {setting:94,code:'F0'},
    {setting:93,code:'ED'},
    {setting:92,code:'EB'},
    {setting:91,code:'E8'},
    {setting:90,code:'E6'},
    {setting:89,code:'E3'},
    {setting:88,code:'E0'},
    {setting:87,code:'DE'},
    {setting:86,code:'DB'},
    {setting:85,code:'D9'},
    {setting:84,code:'D6'},
    {setting:83,code:'D4'},
    {setting:82,code:'D1'},
    {setting:81,code:'CF'},
    {setting:80,code:'CC'},
    {setting:79,code:'C9'},
    {setting:78,code:'C7'},
    {setting:77,code:'C4'},
    {setting:76,code:'C2'},
    {setting:75,code:'BF'},
    {setting:74,code:'BD'},
    {setting:73,code:'BA'},
    {setting:72,code:'B8'},
    {setting:71,code:'B5'},
    {setting:70,code:'B3'},
    {setting:69,code:'B0'},
    {setting:68,code:'AD'},
    {setting:67,code:'AB'},
    {setting:66,code:'A8'},
    {setting:65,code:'A6'},
    {setting:64,code:'A3'},
    {setting:63,code:'A1'},
    {setting:62,code:'9E'},
    {setting:61,code:'9C'},
    {setting:60,code:'99'},
    {setting:59,code:'96'},
    {setting:58,code:'94'},
    {setting:57,code:'91'},
    {setting:56,code:'8F'},
    {setting:55,code:'8C'},
    {setting:54,code:'8A'},
    {setting:53,code:'87'},
    {setting:52,code:'85'},
    {setting:51,code:'82'},
    {setting:50,code:'80'},
    {setting:49,code:'7D'},
    {setting:48,code:'7A'},
    {setting:47,code:'78'},
    {setting:46,code:'75'},
    {setting:45,code:'73'},
    {setting:44,code:'70'},
    {setting:43,code:'6E'},
    {setting:42,code:'6B'},
    {setting:41,code:'69'},
    {setting:40,code:'66'},
    {setting:39,code:'63'},
    {setting:38,code:'61'},
    {setting:37,code:'5E'},
    {setting:36,code:'5C'},
    {setting:35,code:'59'},
    {setting:34,code:'57'},
    {setting:33,code:'54'},
    {setting:32,code:'52'},
    {setting:31,code:'4F'},
    {setting:30,code:'4D'},
    {setting:29,code:'4A'},
    {setting:28,code:'47'},
    {setting:27,code:'45'},
    {setting:26,code:'42'},
    {setting:25,code:'40'},
    {setting:24,code:'3D'},
    {setting:23,code:'3B'},
    {setting:22,code:'38'},
    {setting:21,code:'36'},
    {setting:20,code:'33'},
    {setting:19,code:'30'},
    {setting:18,code:'2E'},
    {setting:17,code:'2B'},
    {setting:16,code:'29'},
    {setting:16,code:'29'},
    {setting:15,code:'26'},
    {setting:16,code:'29'},
    {setting:14,code:'24'},
    {setting:16,code:'29'},
    {setting:13,code:'21'},
    {setting:12,code:'1F'},
    {setting:11,code:'1C'},
    {setting:10,code:'1A'},
    {setting:9,code:'17'},
    {setting:8,code:'14'},
    {setting:7,code:'12'},
    {setting:6,code:'0F'},
    {setting:5,code:'0D'},
    {setting:4,code:'0A'},
    {setting:3,code:'08'},
    {setting:2,code:'05'},
    {setting:1,code:'03'},
    {setting:0,code:'00'}
    ]