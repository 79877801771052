/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component,useContext } from 'react';
import { css, jsx } from '@emotion/core'
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp,mdiClose } from '@mdi/js';
import onClickOutside from 'react-onclickoutside';
import { Input, Icon as SIcon, Checkbox } from 'semantic-ui-react';
import { AccountContext } from '../../providers/AccountProvider';
import { getOptionsAPI } from '../../api/options';
import {getRelationalTableData} from '../../api/tables';
import Global from '../../clients/global';

class GBDropdownComponent extends Component {
  static contextType = AccountContext;
  // const { field, source, updateValue, callingComponent, color, uitype,lookup,width="100%",expand, gridExpandState, optionValues } = this.props;

  state = {
    items: [],
    allItems: [],
    selectedItems: [],
    isExpanded: false,
    searchTerm: [],
    searchActive: [],
    enableColors:true
  }

  async componentDidMount() {
    await this.LoadOptionData()
   
  };

  async componentDidUpdate(prevProps) {
  
    if(this.props.field !==prevProps.field) {
      await this.LoadOptionData();
    } else if(this.props.optionValues !==prevProps.optionValues){
      let selected=[];
      if(this.props.optionValues!==null) {
      this.props.optionValues.forEach(el => {
        selected.push({option:el.data, optionid: el.optionid.toString(), attributes: el.attributes})
      })
      this.setState({selectedItems:selected})
      } else {
        this.setState({selectedItems:[]})
      }
    }
    // 7-1-22 commented out  two prop checks below. The dropdown was auto-closing when selecting multiple
    // instead when the optionvalue's change, I update this per the logic above. I tesed on detailview, grid
    // and new record and this seems to solve the issue.

    // else if(this.props.gridExpandState !== prevProps.gridExpandState) {
    //   await this.LoadOptionData();
    // }  else if(this.props.optionValues !==prevProps.optionValues){
    //   await this.LoadOptionData();
    // }
    
    // 12-10-2021 I commented out the section below, as it was cuasing issues with newRecord passing in OptionValues.
    // I did a quick round of testing and I can't seem to break it with the code commented out, so perhaps
    // it's no longer needed . Keeping hit here in case I run into use-case that requires it.

    // else if(this.props.optionValues !== prevProps.optionValues) {
    //   let optionValues =this.props.optionValues
    //   console.log(this.props.optionValues)

    //   if(optionValues !== undefined && optionValues !== null){
    //     if(optionValues[0] && (optionValues[0].optionid !==null ))  //|| optionValues[0].id !==null
    //     {
    //     const selected = []
    //     optionValues.forEach(el => {
    //       selected.push({option:el.data, optionid: this.props.uitype===1 ? el.optionid : el.optionid.toString(), attributes: el.attributes})
    //     })
    //     this.setState({selectedItems: selected})
        
    //   } else {
    //     this.setState({selectedItems: []})
    //   }
    //   } 
    // }

  }

  LoadOptionData = async () => {
    
    const { field, source, tableid, uitype,gridExpandState,callingComponent, optionValues,RemoveItemsOnEdit=false,rowId,blockid,colInfo,role } = this.props;

    let options=[];

      if(uitype===6 || uitype===7 || uitype===4)
      {
         options = await getOptionsAPI(field,tableid);
        this.setState({items:options, allItems:options,isExpanded:callingComponent==='Grid' || callingComponent==='Details' ? gridExpandState : false})

      } else if (uitype===1)
      {
        options = await getRelationalTableData(tableid,colInfo,role,'',50,null,"list",true);
        this.setState({items:options, allItems:options,isExpanded:callingComponent==='Grid' || callingComponent==='Details' ? gridExpandState : false})
        
      }
   
    if(optionValues !== undefined && optionValues !== null){
   
      if(optionValues[0] !==undefined && (optionValues[0].optionid !==null))
      {
      const selected = []
      optionValues.forEach(el => {
        selected.push({option:el.data, optionid: uitype===1 ? el.id : el.optionid.toString(), attributes: el.attributes})
      })
      this.setState({selectedItems: selected})

      // 2-17-2021 Adding new option that when clicking to edit a dropdown list, it deletes selected item
      // upon loading. This is used in the detail view for single select relation records. When clicking to edit, logic is to "Delete" item and show new selection option.
      // This is setup to only work for single record relational fields.
      if(RemoveItemsOnEdit) {
        await this.removeItem(optionValues[0].id)
        this.setState({isExpanded:true})
      } 
      
    } else {
      this.setState({selectedItems: []})
    }
    } else if(this.props.defaultText !==undefined && this.props.defaultText !==null) {
        const idx = options.findIndex(el=>el.option===this.props.defaultText);
        if(idx !==-1) {
          this.setState({selectedItems:[options[idx]]})
        }
    }

  }

  handleClickOutside = () => {
    this.setState({isExpanded:false})
  };


  filterItems = async (value) => {
      const {allItems} = this.state;
      const {uitype,source,rowId,blockid,colInfo,role,tableid} = this.props;
      if(uitype===1)
      {
        const options = await getRelationalTableData(tableid,colInfo,role,value,50,null,"list",true);
        // const options = await getRelationalRecordsViaTableQuery(colInfo,tableid,blockid,rowId,role,value);

        this.setState({searchTerm:value,items:options, allItems:options})

      } else {
        this.setState({searchTerm:value, items: allItems.filter(el=>el.option.toLowerCase().includes(value))})
      }
  }

   selectItem = (option) => {
        const {selectedItems} = this.state;
        const {expand,callingComponent,updateValue,uitype,field,lookup} = this.props;
        let temparray=[];
        if(lookup==='multiple')
        {
        temparray = structuredClone(selectedItems)

        // check that Item is not already in selectedItems list (can't select twice)
        const rowindex = temparray.findIndex(itm =>parseInt(itm.optionid)===option.optionid)
        
        if(rowindex===-1){
        temparray.unshift(option)
        this.setState({selectedItems:temparray})
        } else {
          this.removeItem(option.optionid)
          return
        }

        } else {
          temparray = [option]
          this.setState({selectedItems:temparray,isExpanded:false})
          // setSelectedItems(temparray)
          // setIsExpanded(false)
          expand !==undefined? expand(false,false) : null
        }

        const dataitems=[];
        temparray.forEach(el => {
          dataitems.push({data: el.option, optionid: el.optionid, attributes: el.attributes})
        })

        if(callingComponent==='Grid' || callingComponent==='Details'){
          updateValue(dataitems)
        } else if (callingComponent==='NewRecord') {
          updateValue(field,dataitems,uitype)
        }

  }

   removeItem =(optionid) => {
    const {selectedItems} = this.state;
    const {expand,callingComponent,updateValue,uitype,field,lookup} = this.props;

    const temparray = selectedItems.filter(el=>el.optionid !== optionid)
    this.setState({selectedItems:temparray})
    // setSelectedItems(temparray)

    const dataitems=[];
    temparray.forEach(el => {
      dataitems.push({data: el.option, optionid: el.optionid, attributes: el.attributes})
    })

    if(callingComponent==="Grid" || callingComponent==='Details') {
      updateValue(dataitems)
    }  else if (callingComponent==='NewRecord') {
      updateValue(field,dataitems,uitype)
    }
  }

  isSelected = (optionid) => {
    const {selectedItems} = this.state;
    const rowindex= selectedItems.findIndex(el => el.optionid===optionid)
    return rowindex!== -1;
 }

   UpdateItem=(isChecked, option) => {
    if(isChecked)
    {
        this.selectItem(option)
        this.setState({option})
    } else {
        this.removeItem(option.optionid)
    }
  }

   expandCollapse =(isCancel) => {
    const {expand} = this.props;
    const {isExpanded} = this.state;
  
    this.setState({isExpanded: !isExpanded})
    // setIsExpanded(!isExpanded)
    expand !==undefined  ? expand(!isExpanded,isCancel) : null
  }

   borderWidth =() => {
    const {callingComponent} = this.props;

    if(callingComponent==="NewRecord" ||  callingComponent==='Details') {
      return '1px solid #DEDEDF'
    } 
      return '0px solid #DEDEDF';
  }

  render() {
    const { color, lookup,width,enableColors=true,showLeftBorder=false,callingComponent } = this.props;
    const {isExpanded,selectedItems,searchTerm,items,allItems} = this.state;

    return (
      <div
        onClick={!isExpanded ? () => this.expandCollapse(true) : null}
        style={{display:'flex',flexDirection:'column',width:`${width}px`,minWidth: isExpanded ? '200px' : `${width}px`,border: isExpanded ? `1px solid ${color}` : this.borderWidth(), borderRadius: '5px', borderLeft: isExpanded ? `1px solid ${color}` : showLeftBorder && callingComponent==="NewRecord" ? `7px solid ${color}` : this.borderWidth() }}>
        <div style={{position:'relative',borderBottom: isExpanded ? `1px solid ${color}` : null, height: '30px',overflow:'hidden' }}>
          <div style={{display:'flex', flexDirection:'row', overflow:'hidden',width:`${width-30}px`}}>
          {selectedItems.map((el2,index) => (
              <div
              key={el2.optionid}
              onClick={() =>this.removeItem(el2.optionid)}
              style={{
              margin: '3px',
              backgroundColor: enableColors && el2.attributes ? el2.attributes.color : !enableColors ? null : `${color}80`,
              color: enableColors && el2.attributes ? el2.attributes.text : !enableColors ? null : 'white',
              width:'max-content',
              paddingLeft: '10px',
              paddingTop:'2px',
              paddingRight: '10px',
              // paddingBottom:'5px',
              height: '17px',
              display: 'flex',
              whiteSpace:'nowrap',
              verticalAlign: 'center',
              alignItems: 'center',
              alignContent: 'center',
              borderRadius: '7px',
            }}
          >
            {el2.option}
            <Icon path={mdiClose} size="13px" />
          </div>
          ))}
          </div>
                <div
            css={{
              position:'absolute',
              top:'0px',
              right:"5px",
              float: 'right',
              cursor: 'pointer',
              marginRight:'5px',
              marginTop:'3px',
              '&:hover': {
                svg: {
                  color: 'white',
                  backgroundColor: `${color}80`,
                  borderRadius:'20px',
                  transition: 'all .3s ease',
                },
              },
            }}
          >
            <Icon onClick={() => this.expandCollapse(true)} path={isExpanded ? mdiChevronUp : mdiChevronDown} size="20px" />
          </div>
        </div>
        {isExpanded ? (
            <div>
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px',
            borderBottom: `1px solid ${color}80`,
          }}
        >
          <SIcon name="search" style={{ marginRight: '10px' }} />
          <Input autoFocus onFocus={() => this.setState({SearchActive:true})} onBlur={() => this.setState({SearchActive:false})}  value={searchTerm} transparent placeholder="find..." onChange={(e,data) =>this.filterItems(data.value.toLowerCase())} />

        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',  overflow:'auto', height:'300px' }}>
          {items.length>0 && items.map((el,index) => (
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} key={el.optionid}>
              {lookup==='multiple' ? (
                <Checkbox checked={this.isSelected(el.optionid)} onChange={(e,data)=>this.UpdateItem(data.checked,el)} />
              ): null}
            <div
            title={el.option}
               key={el.optionid}
                onClick={() =>this.selectItem(el)}
                css={{
                  width: 'max-content',
                  // maxWidth:'100px',
                  cursor:'pointer',
                  margin: '10px',
                  backgroundColor: enableColors && el.attributes ? el.attributes.color : !enableColors ? null : `${color}`,
                  color: enableColors && el.attributes ? el.attributes.text : !enableColors ? null : 'white',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop:'3px',
                  paddingBottom:'3px',
                  borderRadius: '7px',
                  height: '16px',
                  display: 'flex',
                  verticalAlign: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  // overflowWrap:'break-word',
                  
                  overflow:'hidden',
                  whiteSpace:'noWrap',
                  '&:hover' : {
                    backgroundColor:el.attributes ? `${el.attributes.color}80` : `${color}80`
                  }
              }}
            >
              {el.option}
            </div></div>
          ))}
        </div></div>
        ): null }
      </div>
    );
  }
};

export default  onClickOutside(GBDropdownComponent);
