/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, navigate } from '@reach/router';
import cloudrun from '../../../clients/httpcloudrun'
import { FormCard } from '../../../components/Form';
import Loader from '../../../components/Loader';
import { ROUTES } from '../../../constants';
import GBButton from '../../../components/GBButton/GBButton';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';

const ConfirmInvite = ({ trxid, blockname }) => {
  const { REACT_APP_API_CLOUDRUN_URL } = process.env;
  const [zoneName, setZoneName] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] =useState(false);

  useEffect(() => {
    setIsLoading(true)
    const payload = { token: trxid };

    const checkToken = async () => {
      const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/validateConfirmToken`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
      });

      if (result.data.length > 0) {
        setZoneName(result.data[0].name);
        setInvitedBy(result.data[0].addedbyfirstname);
      }

      setIsTokenValid(result.data.length > 0);
    };
    checkToken();
    setIsLoading(false)
  }, []);

  const confirmAcceptance = async () => {
    const payload = { token: trxid, confirmAction: true };

    const result = await axios({
      url: `${REACT_APP_API_CLOUDRUN_URL}/validateConfirmToken`,
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      data: {
        payload,
      },
    });

    navigate(ROUTES.REDIRECTION.DASHBOARD)
  };

  const getZoneName = () => {
    // {blockname === 'zone' ? zoneName : `${zoneName}: ${blockname}`} 
    return <span style={{display:'block',textAlign:'left',marginLeft:'30px',marginBottom:'20px'}}>Use the button below to connect your account with <strong>{blockname === 'zone' ? zoneName : `${zoneName}: ${blockname}`}</strong> and collaborate with{' '} {invitedBy}.</span>
  }


  const getDisplay =() => {
    
    if(isTokenValid) {
    return  (
      <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      
         {getZoneName()}

        <GBButton
          text={blockname === 'zone' ? zoneName : `${zoneName}: ${blockname}`}
          height="80px"
          color="#120233"
          Action={confirmAcceptance}
          borderRadius="20px"
          fontSize="20px"
          width="400px"
        />

      </div>
      </div>
    )
    } 

      return (
        <div>This link is no longer valid.</div>
      )
    
  }

  return (
    <div>
      <img src={LogoIcon} alt="Logo" />
      {!isLoading ? (
         <FormCard title="Welcome">
           {getDisplay()}
       </FormCard>
      )  : <Loader />}
    </div>
  );
};

export default ConfirmInvite;
