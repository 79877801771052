import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BootstrapButton from 'react-bootstrap/Button';

const Button = ({ long, className, ...props }) => {
  const classes = classNames(className, { 'btn-long': long });
  return <BootstrapButton  {...props} className={classes} />;
};

Button.defaultProps = {
  long: false,
  className: undefined,
};

Button.propTypes = {
  long: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
