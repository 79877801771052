/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Dropdown, Form, Radio } from 'semantic-ui-react';
import { mdiClose,mdiPlus} from '@mdi/js';
import FieldButton from './FieldButton';
import IconButton from '../Block/components/FilterData/IconButton';

const ActionDeleteRecord = ({ localData, updateData, editMode, sourceNodes }) => {
  const [insertFields, setInsertFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [deletionLevel, setDeletionLevel] = useState(localData.deletionLevel ?? 'hard');

  useEffect(() => {
    loadUpdateRecordInfo();
  }, []);

  const updateDeletionLevel = (val) => {
    setDeletionLevel(val);
    localData.deletionLevel = val;
    updateData(localData);
  };

  const addRemoveField = (value) => {
    const idx = insertFields.findIndex((el) => el.value === value);
    const idx2 = availableFields.findIndex((el) => el.value === value);

    let tempInsert = structuredClone(insertFields);
    let tempAvailable = structuredClone(availableFields);

    if (idx === -1) {
      const fld = availableFields[idx2];
      fld.mappedValue = 'manual';
      tempInsert.push(fld);
      tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
    } else {
      const fld = insertFields[idx];
      fld.mappedValue = 'manual';
      tempAvailable.push(fld);
      tempInsert = tempInsert.filter((itm) => itm.value !== value);
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields = tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  };

  const loadUpdateRecordInfo = () => {
    localData.tableData = sourceNodes[0].data.tableData;
    const editablefields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 17, 22, 23];
    let afields = [];
    localData.tableData.tableinfo.columns
      .filter((el) => editablefields.includes(el.uitype) && el.data !== 'id')
      .map((itm) => {
        afields.push({ key: itm.data, value: itm.data, text: itm.header });
      });

    setAvailableFields(afields);

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);
    }
    if (localData.availableFields !== undefined) {
      setAvailableFields(localData.availableFields);
    }

    if(localData.deletionLevel===undefined) {
        localData.deletionLevel = 'hard'
        updateData(localData);
    }

  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div style={{ marginTop: '10px' }}>Deletion level</div>
      <div>
        <Form.Field>
          <Radio
            label="Hard delete - Fully abolish record"
            name="deletionLevel"
            value="hard"
            checked={deletionLevel === 'hard'}
            onChange={(e, data) => updateDeletionLevel(data.value)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Soft delete - Delete the identifying fields selected below, but otherwise keep record for reporting purposes."
            name="deletionLevel"
            value="soft"
            checked={deletionLevel === 'soft'}
            onChange={(e, data) => updateDeletionLevel(data.value)}
          />
        </Form.Field>
        {deletionLevel === 'soft' ? (
          <>
          <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
            <FieldButton options={availableFields} addRemoveField={addRemoveField} icon={mdiPlus} />
             <span style={{color:'red',fontSize:'20px'}}>*</span>
          </div>

            <div style={{height:'10px'}}></div>
            {insertFields.map((fld) => (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{fontSize:'15px',margin:'5px'}}>{fld.text}</div>
                <div>
                  <IconButton
                    color="red"
                    hoverColor="white"
                    hoverBackgroundColor="#F6685E"
                    icon={mdiClose}
                    Action={addRemoveField}
                    ActionValue={fld.value}
                  />
                </div>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ActionDeleteRecord;
