/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import InputControl from '../../components/InputControl/InputControl';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import TextButton from '../../components/TextButton/TextButton';
import Icon from '@mdi/react';
import { Checkbox } from 'semantic-ui-react';
import { mdiPencil, mdiAccountEdit, mdiEmail } from '@mdi/js';
import { AccountContext } from '../../providers/AccountProvider';
import ChangePassword from './ChangePassword';
import AddMultiFactor from './AddMultiFactor';
import { updateUserInfo, updateUserAttributes, disableMultiFactor } from '../../api/users';
import Avatar from '../../components/Avatar/Avatar';

import { uploadAvatar } from '../../api/images';
import GBLogo from '../../components/GoogleButton/google.svg';
import MSLogo from '../../components/MicrosoftButton/mslogo.png';

const PersonalInformation = ({ color, close }) => {
  const { userInfo, signOut, userTokenResult } = useContext(AccountContext);
  const [reverify, setReverify] = useState(null);
  const [MFRequired,setMFRequired] = useState(false);

  useEffect(() => {
    const z = userInfo.zones
      .filter((zone) => zone.attributes?.reverify)
      .sort((a, b) => (a.attributes?.reverify > b.attributes?.reverify ? 1 : -1));
    if (z.length > 0) {
      setReverify(z[0].attributes?.reverify);
    }
    
    //12-8-22. Set whether their is zone that requires multifactor. If yes, disable the checkbox that allows them to uncheck it.
    const m = userInfo.zones
    .filter((zone) => zone.attributes?.multiFactor)
    setMFRequired(m.length>0)
  }, []);

  const firstNameControl = { data: 'firstname', uitype: 2, header: 'First name: ' };
  const lastNameControl = { data: 'lastname', uitype: 2, header: 'Last name: ' };

  const myInput = useRef();

  const [firstname, setFirstname] = useState(userInfo.firstName);
  const [lastname, setLastName] = useState(userInfo.lastName);
  const [showPassword, setShowPassword] = useState(false);
  const [showMultiFactor, setShowMultiFactor] = useState(false);
  const [date, setDate] = useState(null);
  const [userItem, setUserItem] = useState({
    firstname: userInfo.firstName,
    lastname: userInfo.lastName,
    userid: userInfo.id,
    attributes: userInfo.attributes,
  });
  const [image, setImage] = useState(null);

  const updateValue = (field, value) => {
    if (field === 'firstname') {
      setFirstname(value);
    } else if (field === 'lastname') {
      setLastName(value);
    }
  };

  const confirmDisable =() =>{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Disable multifactor authentication"
            action={toggleMultiFactor}
            buttonLabel="OK"
            message={"Do you want to disable multifactor? If you continue with this action, you will be logged out and will need to log back in."}
            width="400px"
            // divRef={this.input}
            showInput={false}
            confirmAction=""
            confirmWord="continue"
            height="300px"
            onClose={onClose}
          />
        );
      },
    });
  };
  

  const toggleMultiFactor = async () => {
    //if multi-factor is not defined and showMultifactor is false, show Multi-Factor configuration
    if (userInfo.attributes.multiFactor === undefined && !showMultiFactor) {
      setShowMultiFactor(!showMultiFactor);
    } else if (userInfo.attributes.multiFactor !== undefined) {
      const tempAtt = userInfo.attributes;
      delete tempAtt.multiFactor;
      userInfo.attributes = tempAtt;
      await disableMultiFactor();
      setDate(Date.now());
      await signOut();
    } else {
      setShowMultiFactor(!showMultiFactor);
    }
  };

  const updateMultiFactor = async (number) => {

    const tempAtt = structuredClone(userInfo.attributes);
    tempAtt.multiFactor = number;
    userInfo.attributes = tempAtt;
    await updateUserAttributes(tempAtt);
    setShowMultiFactor(false);
  };

  const handleChange = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setImage(image);
      const url = await uploadAvatar(image, userInfo.attributes);
      const tempuser = userItem;
      tempuser.attributes.avatar = url;
      setUserItem(tempuser);
      setDate(Date.now());
    }
  };

  const d = new Date(userInfo.addedon);

  const submitChanges = async () => {
    // update user first/last name in db an userInfo
    const items = [
      { field: 'firstname', value: firstname },
      { field: 'lastname', value: lastname },
    ];
    userInfo.firstName = firstname;
    userInfo.lastName = lastname;
    await updateUserInfo(userInfo.id, items);
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginLeft: '10px',
          height: '40px',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '40px',
        }}
      >
        <div style={{ fontWeight: 'bold', marginLeft: '20px' }}>Personal information</div>
      </div>
      {/* <Previews /> */}
      <div
        style={{
          display: 'flex',
          marginLeft: '188px',
          marginTop: '10px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '5px' }}>Avatar:</div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            '& .avatarHover': {
              opacity: 0,
            },
            '&:hover': {
              '& .avatar': {
                opacity: 0,
                transition: 'all .3s ease',
                display: 'none',
              },
              '& .avatarHover': {
                transition: 'all .3s ease',
                borderRadius: '20px',
                color,
                opacity: 1,
                backgroundColor: 'white',
              },
            },
          }}
          onClick={(e) => myInput.current.click()}
        >
          <div className="avatar">
            <Avatar item={userItem} width="24px" height="24px" />
          </div>
          <div
            className="avatarHover"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Icon
              style={{ backgroundColor: '#FFEBBE', borderRadius: '100%' }}
              path={mdiAccountEdit}
              size="24px"
            />
            <input
              id="myInput"
              type="file"
              ref={myInput}
              style={{ display: 'none' }}
              onChange={(e) => handleChange(e)}
            />
            <div style={{ marginLeft: '10px' }}>
              Upload a personalized avatar (recommended size 24px x 24px)
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10px', marginLeft: '159px' }}>
        <InputControl
          alignControl={true}
          colInfo={firstNameControl}
          value={firstname}
          submitChanges={submitChanges}
          updateValue={updateValue}
          canEdit={true}
        />
      </div>
      <div style={{ marginTop: '10px', marginLeft: '160px' }}>
        <InputControl
          alignControl={true}
          colInfo={lastNameControl}
          value={lastname}
          submitChanges={submitChanges}
          updateValue={updateValue}
          canEdit={true}
        />
      </div>
      <div
        style={{ marginLeft: '120px', marginTop: '10px', display: 'flex', flexDirection: 'row' }}
      >
        Profile Created on: <div style={{ marginLeft: '7px' }}>{d.toLocaleDateString('en-us')}</div>
      </div>

      <div
        style={{
          marginLeft: '146px',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        Login method:{' '}
        <div
          style={{ marginLeft: '7px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {userTokenResult.signInProvider === 'google.com' ? (
            <>
              <img src={GBLogo} width="25px" /> <div style={{ marginLeft: '5px' }}>Google</div>
            </>
          ) : null}
          {userTokenResult.signInProvider === 'microsoft.com' ? (
            <>
              <img src={MSLogo} width="25px" />
              <div style={{ marginLeft: '5px' }}>Microsoft</div>
            </>
          ) : null}
          {userTokenResult.signInProvider === 'password' ? (
            <>
              <Icon path={mdiEmail} size="25px" color="black" />{' '}
              <div style={{ marginLeft: '5px' }}>Direct username/password</div>
            </>
          ) : null}

            {userTokenResult.signInProvider === 'custom' ? (
            <>
              <Icon path={mdiEmail} size="25px" color="black" />{' '}
              <div style={{ marginLeft: '5px' }}>Impersonating user</div>
            </>
          ) : null}
        </div>
      </div>

      {/* 12/6/22 We only allow changing password or enabling multifactor if they
      have signed in using password provider.  */}
      {userTokenResult.signInProvider === 'password' ? (
        <>
          <div
            style={{
              marginLeft: '167px',
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            Password: <div style={{ marginLeft: '8px' }}>************ </div>
            <div style={{ marginLeft: '10px' }}>
              <TextButton
                Action={setShowPassword}
                ActionValue={true}
                text="Change"
                icon={mdiPencil}
                iconPosition="left"
                hoverColor="#75757580"
                textColor="black"
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: '29px',
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '5px',whiteSpace:'nowrap' }}>Multifactor authentication required:</div>
            <Checkbox
              disabled={MFRequired}
              checked={userInfo.attributes.multiFactor !== undefined}
              onChange={userInfo.attributes.multiFactor !== undefined ? confirmDisable : toggleMultiFactor }
            />
            {/* <GBSwitch Action={toggleMultiFactor} text={userInfo.attributes.multiFactor===undefined ? "Multi-factor" : `Multi-factor(${userInfo.attributes.multiFactor})`} isChecked={userInfo.attributes.multiFactor !==undefined} /> */}

            {userInfo.attributes.multiFactor !== undefined  && MFRequired ? (
              <div style={{ fontSize: '11px',marginLeft:'5px' }}>
               (At least one zone you utilize requires multifactor authentication for any direct username/password login method accounts.)
              </div>
            ) : null}

              {userInfo.attributes.multiFactor !== undefined  && !MFRequired ? (
              <div style={{ fontSize: '11px',marginLeft:'5px' }}>
                Plesae note: If you disable multi-factor authentication, you will be logged out and
                will need to log back in.
              </div>
            ) : null}


          </div>
          {userInfo.attributes.multiFactor ? (
          <div style={{
              marginLeft: '85px',
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <div style={{ marginRight: '5px',whiteSpace:'nowrap' }}>Multifactor mobile phone:</div>
            <div>{userInfo.attributes.multiFactor}</div>
          </div>
          ): null}
        </>
      ) : null}
      {reverify !== null ? (
        <div
          style={{
            marginLeft: '148px',
            marginTop: '10px',
          }}
        >
          Reverify login: {reverify} days  <span style={{fontSize:'11px'}}>(This is defined by the Zone(s) you belong to and what is prescribed there.)</span>
        </div>
      ) : null}

      {showPassword ? (
        <div
          style={{
            zIndex: 100,
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid ${color}`,
            borderRadius: '20px',
            top: '100px',
            left: '150px',
            width: '500px',
            height: '400px',
          }}
        >
          <ChangePassword color={color} close={setShowPassword} />
        </div>
      ) : null}

      {showMultiFactor ? (
        <div
          style={{
            zIndex: 100,
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid ${color}`,
            borderRadius: '20px',
            top: '100px',
            left: '150px',
            width: '500px',
            height: '400px',
          }}
        >
          <AddMultiFactor
            userid={userInfo.id}
            color={color}
            close={toggleMultiFactor}
            updateMobile={updateMultiFactor}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PersonalInformation;
