/* eslint-disable */
import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import debounce from 'debounce';
import { toast } from 'react-toastify';
import {
  createNewItem,
  replaceBlock,
  deleteBlock,
  moveBlockToLeft,
  moveBlockToRight,
  calculateLastSort,
} from '../utils';
import { AccountContext } from '../../../providers/AccountProvider';
import { addBlockAPI, updateBlockAPI, deleteBlockAPI, sortAPI,copyBlock,importBlock,blocksAPI } from '../../../api/blocks';
import Global from '../../../clients/global';

const updateAPI = (block) => {
  return updateBlockAPI(block)
    .then((data) => {
      return { ...block, ...data };
    })
    .catch(() => {});
};

const updateWithDebounce = debounce(updateAPI, 1000);

const useCacheBlocks = (data = [], alert) => {
  const { userInfo, updateUserZone } = useContext(AccountContext);
  const [blocks, setBlocks] = useState(data);
  const [loading, setLoading] = useState(false);

  const sorted = useMemo(() => {
    const list = [...blocks];
    list.sort((a, b) => a.sort - b.sort);
    return list;
  }, [blocks]);


  const handleImportBlock = async (value) =>{
     const result = await importBlock(value);
     if(result==='success') {
        const b = await blocksAPI(Global.zoneid)
        setBlocks(b)
     }
      
     return result;
      
  }

  const handleAddBlock = useCallback(() => {
    const newBlock = { ...createNewItem(), sort: calculateLastSort(sorted) };
    setBlocks((prev) => [...prev, newBlock]);
    addBlockAPI(userInfo.id, Global.zoneid, newBlock)
      .then((block) => {
        setBlocks((prev) => replaceBlock(prev, { ...newBlock, ...block }));
      })
      .catch(() => {
        setBlocks([...blocks]);
        alert.show({ title: 'Error', message: "We can't add the block. Please refresh the page." });
      });
  }, [blocks, sorted, alert, userInfo.id]);

  const handleChangeBlock = useCallback(
    (values) => {
      //10-10-23 resetting information value to draft after switch to tinyMCE, this ensures
      //existing block is updated with new value in UI, so if they hit edit again, they will see latest updates.
      values.information=values.draft
      setBlocks((prev) => replaceBlock(prev, values,));
      updateWithDebounce(values);
    },
    [setBlocks],
  );

  const handleCopyBlock = async (data) =>{

    if(Global.zoneid==data.destinationZoneId){
    const newBlock = { ...createNewItem(false), sort: calculateLastSort(sorted) };
    setBlocks((prev) => [...prev, newBlock]);
    copyBlock(data)
    .then((newCopiedBlock) =>{
      setBlocks((prev) => replaceBlock(prev, { ...newBlock, ...newCopiedBlock }));
      toast.success('Block successfully copied!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    }).catch(() => {
      setLoading(false);
      toast.error('Failed to copy block! Our support team has been notified.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    });
  } else {
    try{
      await copyBlock(data)
      toast.success('Block successfully copied!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    } catch(error) {
      toast.error('Failed to copy block! Our support team has been notified.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    }
  }
  }


  const handleRightMoveBlock = useCallback(
    (block) => {
      const updatedBlock = moveBlockToRight(sorted, block);
      const newList = replaceBlock(blocks, updatedBlock);
      setBlocks(newList);

      sortAPI(userInfo.id, updatedBlock).catch(() => {
        setBlocks(data);
      });
    },
    [sorted, blocks, data, setBlocks, userInfo.id],
  );

  const handleLeftMoveBlock = useCallback(
    (block) => {
      const updatedBlock = moveBlockToLeft(sorted, block);
      const newList = replaceBlock(blocks, updatedBlock);
      setBlocks(newList);
      sortAPI(userInfo.id, updatedBlock,blocks).catch(() => {
        setBlocks(data);
      });
    },
    [sorted, blocks, data, setBlocks, userInfo.id],
  );

  const handleDeleteBlock = useCallback(
    (block) => {
      setLoading(true);
      deleteBlockAPI(block)
        .then(() => {
          setBlocks((prev) => deleteBlock(prev, block));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          alert.show({
            title: 'Error',
            message: "We can't delete the block. Please refresh the page.",
          });
        });
    },
    [setLoading, setBlocks, alert],
  );

  useEffect(() => {
    setBlocks(data);
  }, [data, setBlocks]);

  useEffect(() => {
    updateUserZone({ id: Global.zoneid }, { blocksCount: blocks.length,userCount: 1 });
  }, [updateUserZone, blocks.length, Global.zoneid]);

  const props = useMemo(() => {
    return {
      blocks: sorted,
      loading,
      onAddBlock: handleAddBlock,
      onChangeBlock: handleChangeBlock,
      onCopyBlock: handleCopyBlock,
      onImportBlock: handleImportBlock,
      onDeleteBlock: handleDeleteBlock,
      onRightMoveBlock: handleRightMoveBlock,
      onLeftMoveBlock: handleLeftMoveBlock,
    };
  }, [
    sorted,
    loading,
    handleAddBlock,
    handleChangeBlock,
    handleCopyBlock,
    handleImportBlock,
    handleDeleteBlock,
    handleRightMoveBlock,
    handleLeftMoveBlock,
  ]);

  return props;
};

export default useCacheBlocks;
