/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../Button';
import './styles.scss';

const PlanButton = ({ children, ...props }) => {
  const {upgrade,plan} = props;

  const getStyle =() => {
    if(plan==='Free') return "clear current-plan-btn";
    if(plan==='Starter') return "clear current-starter-plan-btn";
    if(plan==='Pro') return "clear current-pro-plan-btn";
    if(plan==='Public') return "clear current-public-plan-btn";
  }

  return (
    <OverlayTrigger placement='bottom-end' overlay={<Tooltip>{plan==='Free' ? 'Upgrade your plan!' : plan==='Public' ? 'Copy Block' : 'Change plan'}</Tooltip>}>
   
    <Button variant={getStyle()} {...props} onClick={upgrade}>
      <span />
      <span>{children}</span>
    </Button>
    </OverlayTrigger>
  );
};

PlanButton.defaultProps = {
  children: null,
};

PlanButton.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default PlanButton;
