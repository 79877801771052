import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Body from './Body';
import Container from './Container';
import './styles.scss';

const Content = ({ wider, children }) => {
  return (
    <div id="content" className={wider ? 'wider' : ''}>
      {children}
    </div>
  );
};

Content.defaultProps = {
  wider: false,
  children: null,
};

Content.propTypes = {
  wider: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

Content.Header = Header;
Content.Body = Body;
Content.Container = Container;

export default Content;
