/* eslint-disable */
import React from 'react';
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


class DateTimeRenderer extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.mainElementRef = React.createRef();
    this.phoneInput = React.createRef();
     this.state = {
      value: null,
      width: '125px',
      height: '200px',
      includeTime: true,
      utc: '',
      top: 30,
      left: 0,
      display: 'none',
      expandDown:true,
    };
  }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    // if (this.mainElementRef.current !== null) {
    //   this.mainElementRef.current.style.display = 'flex';
    // }
    this.setState({ display: 'block', isExpanded:false });
  }

  close() {
    // if (this.mainElementRef.current) {
    //   this.mainElementRef.current.style.display = 'none';
    // }
    this.setState({ display: 'none', isExpanded:false });
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const tdPosition = td.getBoundingClientRect();
    const halfHeight = window.innerHeight*.5
    const halfWidth = window.innerWidth*.5;
    const expandDown = tdPosition.y<halfHeight;
    const rightEdge = tdPosition.x + (cellProperties.includeTime ? 315 : 215)


    this.setState({
      width: cellProperties.cellWidth,
      height:'max-content',
      utc: cellProperties.utc,
      dateFormatEdit: cellProperties.dateFormatEdit,
      includeTime: cellProperties.includeTime,
      value: originalValue ?? null,
      expandDown:expandDown,
      left: rightEdge > window.innerWidth ? `${tdPosition.left-150}px` : `${tdPosition.left+1}px`,
      top: `${tdPosition.top+2}`,
    });


    // As the `prepare` method is triggered after selecting
    // any cell, we're updating the styles for the editor element,
    // so it shows up in the correct position.

    // if (this.mainElementRef.current !== null) {
    //   this.mainElementRef.current.style.left = `${tdPosition.left}px`;
    //   this.mainElementRef.current.style.top = `${tdPosition.top}px`;
    // }
  }

  onKeyPressHandler = (key) => {
    if (key === 'Enter') this.updateText();
  };

  updateDate = (date) => {
   
    const { includeTime } = this.state;

    if(date.getHours()===0 && date.getMinutes()===0 && includeTime) {
        const d = new Date();
        date.setHours(d.getHours())
        date.setMinutes(d.getMinutes())
    } 

    this.setState({ value: date.toISOString() });

    if (!includeTime) {
      this.setState(
        (state, props) => {
          return { value: date.toISOString() };
        },
        () => {
          // console.log('finishing...');
          this.finishEditing();
          // this.close();
        },
      );
    }
  };

  onDayChange =(date) =>{

    if(this.state.value){
      const d = new Date(this.state.value);
      if(date.toISOString() ==d.toISOString()) {

          this.setState(
            (state, props) => {
              return { value: null };
            },
            () => {
              // console.log('finishing...');
              this.finishEditing();
              // this.close();
            },
          );
        
      }
  }
  }

  updateDateTime = () => {
    const { value } = this.state;

    this.setState(
      () => {
        return { value };
      },
      () => {
        this.finishEditing();
      },
    );
  };

  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const { value, width, includeTime, height } = this.state;
    const { utc, dateFormat,color } = this.props;

    let renderResult = null;

    if (this.props.isEditor) {
      let testValue = null;
      if (value !== null && value !== '' && value !==undefined) {
        testValue = new Date(value)
      } 

      renderResult = (
        <NativeListener onMouseDown={this.stopMousedownPropagation}>
          <div
            style={{
              display: this.state.display,
              flexDirection: 'column',
              alignItems: 'center',
              // justifyContent: 'center',
              position: 'absolute',
              overflow: 'hidden',
              left: this.state.left,
              top:  !this.state.expandDown ? `${this.state.top-300}px` : `${this.state.top}px`,
              background: '#fff',
              // border: '1px solid #000',
              padding: '0px',
              zIndex: 999,
              width:  '215px',
              height: 'maxContent',
            }}
            ref={this.mainElementRef}
            id="editorElement"
          >
            <DatePicker
              inline
              selected={testValue}
              scrollableYearDropdown
              minDate={moment().subtract(80, 'years')._d}
              showYearDropdown
              dropdownMode="select"
              // onSelect={(date) => this.onDayChange(date)}
              onChange={(date) => this.updateDate(date)}
              showTimeInput={includeTime}
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
            {/* {includeTime ? (
              <div style={{width:'100%',display:'flex', flexDirection:'column', alignItems:'center'}}><GBButton Action={this.updateDateTime} text="Save" color={color} /></div>
             ): null}  */}
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const TagStyle = {
        width: this.props.cellWidth,
        height: this.props.cellHeight-7,
        // backgroundColor:'#eee',
        float: 'left',
        zIndex: 0,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight:'1',
        marginTop:'5px',
        // paddingTop:'5px',
        marginRight: '5px',
        marginLeft:'2px'
      };

      renderResult = (
        <div style={TagStyle}>
          {typeof this.props.value !== 'object' && this.props.value !== '' ? (
              <Moment format={dateFormat}>
                {this.props.value}
              </Moment>
          ) : null}
        </div>
      );
    }

    return <>{renderResult}</>;
    // return(<div>Test</div>)
  }
}

export default DateTimeRenderer;
