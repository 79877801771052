import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const Actions = ({ children }) => <Modal.Footer>{children}</Modal.Footer>;

Actions.defaultProps = {
  children: null,
};

Actions.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Actions;
