import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';


const Page = ({ twin, children,color }) => {
  let className='';
  if(color) {
    className = classNames('form-page', twin && 'master-page-twin');
  } else {
    className = classNames('master-page', twin && 'master-page-twin');
  }

  if (twin) {
    return (
      <div className={className} style={{backgroundColor:color}}>
        <div>{children}</div>
        <div />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="window">{children}</div>
    </div>
  );
};

Page.defaultProps = {
  twin: false,
  children: null,
};

Page.propTypes = {
  twin: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Page;
