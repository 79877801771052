/* eslint-disable */
import React, { useEffect, useState,useContext} from 'react';
import Global from 'src/clients/global';
import { AccountContext } from '../../../../providers/AccountProvider';
import {getRecord} from '../../../../api/GetData'
import DetailView from '../Detailview/Detailview';
import TopNav from '../Shares/TopNav';


/* 12-1-23
    1. Receive token with base64encoding and get zoneid, tableid and id params.
    2. set Global.zoneid= zoneid
    3. Get userInfo and associated zones. Check if user has access to defined zone.
    4. If no, show message that the user does not have access to this Record information.
    5. If yes, call new method getRecord(token). This method will 
         1. retreive tabledata (including tableinfo and blockid) form target zone 
         2. Retrieve rowData for the provided row and set to the selectedRow param.
    7. Get role from userInfo.Blocks 

    should have enough info to invoke DetailView.
*/

const Record = ({ token }) =>{
    const {userInfo } = useContext(AccountContext);
    const [error,setError] =useState(null);
    const [data, setData] =useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [control, setControl] = useState('details');

    useEffect(()=>{
        
        const [rawToken,zoneid] = token.split('_');
        Global.zoneid=zoneid;
        getRecordData(rawToken)

    },[])

    const getRecordData = async (token) =>{
        const recordData = await getRecord(token);
        if(recordData.error) {
            setError(recordData.message)
        } else{
            setData(recordData)
            userInfo.blocks=recordData.blockRoles
        }

      const favico = document.getElementById('favicon');
      const color = encodeURIComponent(recordData.color);
      const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:${color}' viewBox='0 0 24 24'%3E%3Cpath d='${recordData.icon}'/%3E%3C/svg%3E`;
      favico.href = svgpath;

    }

    const changeControl = (newControl) => {
        setControl(newControl);
  
      };

    const hasMessages = data !== null && data.tableinfo.columns.findIndex((el) => el.uitype === 12 || (el.uitype===10 && el.enableEmailMessages===true))  !== -1;
    const hasAttachments =  data !== null && data.tableinfo.columns.findIndex( (el) => el.uitype === 4 || (el.source && el.source.lookupuitype === 4)) !== -1;
    const hasRelated =  data !== null &&  data.tableinfo.columns.findIndex((el) => el.uitype === 1 && el.lookup === 'multiple') !== -1;

    return (
        <div> 
            {data !==null && error ==null? (<>

                {isMobile ? (
                    <TopNav
                    control={control}
                    hasAttachments={hasAttachments}
                    hasMessages={hasMessages}
                    hasRelated={hasRelated}
                    canCollaborate={true}
                    canViewComments={true}
                    color={data.color}
                    changeControl={changeControl}
                    heightInfo={{top:0 }}
                    RID={data.data.CustomID}
                    hide={null}
                    icon={data.tableinfo.icon}
                    recordname={data.tableinfo.recordname}
                    NextPrev={null}
                    activeRow={0}
                    filterCount={1}
                    />
                ) : null}

                <DetailView
                  role={data.role}
                  tableid={parseInt(data.tableid)}
                  blockid={parseInt(data.blockid)}
                  color={data.color}
                  hasMessages={hasMessages}
                  NextPrev={null}
                  isShare={true}
                  isMobileShare={isMobile}
                  shareControl={control}
                  hide={null}
                  heightInfo={{
                    heightOffset:195,
                    top: 0,
                    bottom:undefined
                  }}
                  activeRow={0}
                  SelectedRowIndex={null}
                  id={parseInt(data.id)}
                  userTabSettings={()=>{return false}}
                  updateSettingsFromDetailView={()=>{return false;}}
                  selectedRow={data.data}
                  tableinfo={data.tableinfo}
                  updateTableInfo={()=>{return false;}}
                  RefreshRow={()=>{}}
                  nextPrevBool={{}}
                />
                </>): null}
                {error !=null ? (
                    <div style={{marginTop:'20px'}}><center><h1>{error}</h1></center></div>
                ): null}
        </div>
    )
}

export default Record;