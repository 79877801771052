import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import debounce from 'debounce';
import { mdiMagnify } from '@mdi/js';
import Icon from '../../../Icon';
import './styles.scss';

const MagnifyIcon = (props) => <Icon {...props} path={mdiMagnify} />;

const fuseOptions = {
  threshold: 0.1,
  keys: ['tag', 'name'],
};

const IconPicker = ({ value, color, icons, onChange, customOnChange }) => {
  const [filter, setFilter] = useState('');
  const [filterAsync, setFilterAsync] = useState(filter);

  const setFilterAsyncWithDebounce = useMemo(() => {
    return debounce(setFilterAsync, 300);
  }, [setFilterAsync]);

  const fullList = useMemo(() => {
    return icons.map((i) => ({ item: i }));
  }, [icons]);

  const fuse = useMemo(() => {
    return new Fuse(icons, fuseOptions);
  }, [icons]);

  const options = useMemo(() => {
    if (!filterAsync) {
      return fullList;
    }
    return fuse.search(filterAsync);
  }, [fuse, filterAsync, fullList]);

  const handleChange = useCallback(
    (e) => {
      setFilter(e.target.value);
      setFilterAsyncWithDebounce(e.target.value);
    },
    [setFilter, setFilterAsyncWithDebounce],
  );

  return (
    <>
      <div className="form-label icon-picker-label">
        <span>ICON&nbsp;</span>
        {value && (
          <span style={{ background: color }}>
            <svg viewBox="0 0 24 24">
              <path d={value} fill="#fff" />
            </svg>
          </span>
        )}
      </div>
      <div className="icon-picker-control" style={{ background: color }}>
        <div className="icon-picker-control-search">
          <MagnifyIcon color="#fff" />
          <input placeholder="Find..." value={filter} onChange={handleChange} />
        </div>
        <div className="icon-picker-control-divider" />
       
         {options.length>0 ? ( <div className="icon-picker-control-body">
          {options.map(({ item }) => (
            <div
              title={`${item.name} ${item.tag}`}
              aria-label={`${item.name} Icon`}
              className={item.path === value ? 'selected' : ''}
              key={item.path}
              onClick={() => {
                onChange(item.path);
                customOnChange(item.path);
              }}
              onKeyPress={() => {
                onChange(item.path);
                customOnChange(item.path);
              }}
              role="button"
              tabIndex="-1"
            >
              <svg viewBox="0 0 24 24">
                <path d={item.path} />
              </svg>
            </div>
          ))}
          </div>): <div style={{color:'white'}}>No results, try different criteria, or remove your criteria to scroll and find a relevant icon.</div>}
      </div>
    </>
  );
};

IconPicker.defaultProps = {
  onChange: () => {},
  customOnChange: () => {},
  value: undefined,
  color: 'grey',
  icons: [],
};

IconPicker.propTypes = {
  onChange: PropTypes.func,
  customOnChange: PropTypes.func,
  value: PropTypes.string,
  color: PropTypes.string,
  icons: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default IconPicker;
