/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect,useRef } from 'react';

import Icon from '@mdi/react';
import {Popup} from 'semantic-ui-react';
import { mdiPlus, mdiClose,mdiInformationOutline } from '@mdi/js';
import { Dropdown } from 'semantic-ui-react';

const DuplicateControl = ({ type, text, color, form, updateFormAttribute,selectedFields,tableinfo,tooltip,placeholder }) => {
  const [showFields, setShowFields] = useState(false);
  const [options, setOptions] = useState([]);
  const [fields, setFields] = useState([]);
  const [dupfields, setDupFields] = useState(selectedFields!==undefined ? selectedFields : []);
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();


  const handleClickInside = () => {
    setClickedOutside(false);
    setShowFields(true)
  };

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      onBlurHandler()
      setClickedOutside(true);
      setShowFields(false)
    }
  };

  useEffect(() => {
    if(showFields){
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  });

  const onBlurHandler = () => {
    let t=[];

    if(type==='duplicateFields'){
       t = [...dupfields];
    }

    // add object that has both data & label. This way we can display proper labels
    // This control is used for both specifying the set of duplicate fields to use
    // as well as specifying the fields that should be blocked from being updated on a dup match
    
    if(type==='duplicateFields'){
        let updatedDupItem = [];

        fields.length > 0 && fields.map((itm) => {
        const item = options.filter((el) => el.value === itm)[0];
        updatedDupItem.push(itm);
        });

        if(fields.length>0){
          t.push(fields);
          }
  
         setDupFields(t);
        // update the duplicate fields on form object.
         updateFormAttribute(type, t)

   } else {
        //This is setting blocked/replace(append) fields, just using same array.
        setDupFields(fields);

        // update the blocked fields on form object.
        updateFormAttribute(type, fields)
   }

    //reset fields to empty
    setFields([]);
    setShowFields(false);
  };

  const changeFieldHandler = (fields) => {
    setFields(fields);
  };

  const getLabels =(dupFieldArray) => {

    const fieldsWithLabel=[]
    if(type==='duplicateFields'){
      dupFieldArray.map(itm=> {
          const innerArray=[]
          itm.map(el=>{
            const idx = tableinfo.columns.findIndex(fld => fld.data === el)
            if(idx !==-1){
              const field = tableinfo.columns[idx];
              innerArray.push({data:field.data,label:field.header})
            }
          })
          if(innerArray.length>0){
            fieldsWithLabel.push(innerArray)
          }
        })
      } else {
        dupFieldArray.map(itm=> {
            const idx = tableinfo.columns.findIndex(fld => fld.data === itm)
            if(idx !==-1){
              const field = tableinfo.columns[idx];
              fieldsWithLabel.push({data:field.data,label:field.header})
            }
        })
      }

      return fieldsWithLabel
  }

  const removeDupField = (idx) => {
    let tempFields = [...dupfields]
    let removed = tempFields.splice(idx,1)
    setDupFields(tempFields)
  }

  const replaceFields = form.settings.replaceMultipleFields !==undefined ? form.settings.replaceMultipleFields : []
  const blockedFields =form.settings.blockedFields !==undefined ? form.settings.blockedFields : []

  const getFilteredFields = () =>{
      let fields=[];
      if(form.columnsettings!==null){
      if(type==='duplicateFields')
      {
        fields= tableinfo.columns.filter((itm) => [2, 3, 5, 9, 10, 11, 14, 22,23,24].includes(itm.uitype) || itm.uitype===7 || (itm.lookup==='single' && [1,8].includes(itm.uitype))  )
      } else if(type==='blockedFields') {
        fields= tableinfo.columns
        .filter((itm) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 22, 23].includes(itm.uitype))
        .filter(itm=>!replaceFields.map(e=>e).includes(itm.data) )
      } else if(type==='replaceMultipleFields')
      {
        fields= tableinfo.columns
        .filter((itm) => itm.lookup==='multiple' &&  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,14, 22, 23].includes(itm.uitype))
        .filter(itm=>!blockedFields.map(e=>e).includes(itm.data) )
      }
    }
      return fields
  }

  useEffect(() => {
    const opts = [];   
    getFilteredFields().forEach((el) => {
        opts.push({ key: el.data, value: el.data, text: el.header });
      });
    opts.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    setOptions(opts);
  }, [form]);

  return (
    <div  ref={myRef} onClick={handleClickInside}>
      <div style={{display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'space-between',color: color, marginBottom: '5px', fontWeight: 'bold' }}>
       <div>{text} </div>
       <div>
         <Popup content={tooltip} hoverable trigger={
         <Icon path={mdiInformationOutline} size="15px" />
         } />
      </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: '35px',
          width: '440px',
          borderRadius: '5px',
          borderTop: '1px solid #DEDEDF',
          borderRight: '1px solid #DEDEDF',
          borderBottom: '1px solid #DEDEDF',
          borderLeft: `7px solid ${color}`,
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
          paddingLeft: '5px',
        }}
      >
        <div
          css={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            overflow:'auto'
          }}
        >
            <div css={{ svg: {
              color: color,
              borderRadius: '100%',
            },
            '&:hover': {
              svg: {
                backgroundColor: color,
                color: 'white',
                transition: '.5s ease all',
              },
            },}}>
          {type==='duplicateFields' ? (
          <Icon
            onClick={() => setShowFields(true)}
            css={{ marginRight: '5px' }}
            path={mdiPlus}
            size="15px"
          />): null}
          </div>

          {dupfields.length > 0 ? (
            <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center',width:'400px' }}>
              {getLabels(dupfields).map((itm,index) => (<>
                <div
                  css={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '3px',
                    paddingLeft:'5px',
                    paddingRight:'5px',
                    marginRight: '5px',
                    backgroundColor: `${color}CC`,
                    whiteSpace:'nowrap',
                    color: 'white',
                    borderRadius: '10px',
                    svg: {
                        color:'white',
                        borderRadius:'100%',
                    },
                    '&:hover' : {
                        svg: {
                            backgroundColor:'white',
                            borderRadius:'100%',
                            color:color,
                            transition:'.5s ease all'
                        }
                    }
                  }}
                >
                 {type==='duplicateFields' ? (
                      itm.map((el) => el.label).join()
                 ) : (
                      itm.label
                 )}
                 {type==='duplicateFields' ? (
                  <Icon onClick={()=>removeDupField(index)} css={{marginLeft:'5px'}} path={mdiClose} size="15px" />
                 ): null}
                </div>
                {type==='duplicateFields' ? <span style={{margin:'5px'}}>or</span> :null}
                </>
              ))}
            </div>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
      </div>
      {showFields ? (
        <Dropdown
          search
          selectOnBlur={false}
          onChange={(e, data) => changeFieldHandler(data.value)}
          fluid
          defaultOpen
          multiple
          options={options}
          selection
        />
      ) : null}
    </div>
  );
};

export default DuplicateControl;
