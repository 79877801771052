/* eslint-disable */
import React, { useState, useEffect, table } from 'react';
import AddBlockUsers from './AddBlockUsers';
import AddUsers from './AddUsers';

const AddUsersManager = ({ table, color, close, tableid, blockid, closeModalandUpdate }) => {
  return (
    <div>
    
      {table === 'Users' ? (
        <AddUsers
          color={color}
          close={close}
          closeModalandUpdate={closeModalandUpdate}
        />
      ) : (
        <AddBlockUsers
          color={color}
          close={close}
          tableid={tableid}
          blockid={blockid}
          closeModalandUpdate={closeModalandUpdate}
        />
      )}
    </div>
  );
};

export default AddUsersManager;
