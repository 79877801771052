/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CirclePicker } from 'react-color';
import { Link } from '@reach/router';
import { Label, Button, Icon, Input } from 'semantic-ui-react';


class OptionColorPicker extends Component {

  /*
    12-16-23 Added two new params you can pass in to control behavior. These were added to support
    new color settings on form settings for selecting text color and for text background. 
    1. toggleTextColor : This indicates whether we allow them to toggle the text color between black and white
                          When choosing background color or form text color, there is no need to allow this toggle
    2. isTextColor: Added this to support when they are selecting the text color. In this mode, the sample div
      will show the text color as the pickedcolor against a grey background, otherwise, it's changing the
      background color of the div and the text is just either black/white on this changed color.
  */

  // Colors= this.props.component==='Options' ? [
  //   { color: '#FFF9C4', text: 'black' },
  //   { color: '#FFF3E0', text: 'black' },
  //   { color: '#FFEBEE', text: 'black' },
  //   { color: '#FCE4EC', text: 'black' },
  //   { color: '#E3F2FD', text: 'black' },
  //   { color: '#D1ECE8', text: 'black' },
  //   { color: '#F5F5F5', text: 'black' },
  //   { color: '#FFF176', text: 'black' },
  //   { color: '#FCE4EC', text: 'black' },
  //   { color: '#CCFF90', text: 'black' },
  //   { color: '#B2EBF2', text: 'black' },
  //   { color: '#C5CAE9', text: 'black' },
  //   { color: '#D1C4E9', text: 'black' },
  //   { color: '#EEEEEE', text: 'black' },
  //   { color: '#FFD54F', text: 'black' },
  //   { color: '#FFCCBC', text: 'black' },
  //   { color: '#64FFDA', text: 'black' },
  //   { color: '#84FFFF', text: 'black' },
  //   { color: '#BBDEFB', text: 'black' },
  //   { color: '#EFEBE9', text: 'black' },
  //   { color: '#B0BEC5', text: 'black' },
  //   { color: '#F9A825', text: 'black' },
  //   { color: '#F8BBD0', text: 'black' },
  //   { color: '#9CCC65', text: 'black' },
  //   { color: '#80D8FF', text: 'black' },
  //   { color: '#82B1FF', text: 'black' },
  //   { color: '#D7CCC8', text: 'black' },
  //   { color: '#BDBDBD', text: 'black' }
  // ] : [
  //   { color: '#E5AE02', text: 'white' },
  //   { color: '#FFA000', text: 'white' },
  //   { color: '#F44336', text: 'white' },
  //   { color: '#E91E63', text: 'white' },
  //   { color: '#448AFF', text: 'white' },
  //   { color: '#009688', text: 'white' },
  //   { color: '#757575', text: 'white' },
  //   { color: '#C79702', text: 'white' },
  //   { color: '#DD2C00', text: 'white' },
  //   { color: '#B71C1C', text: 'white' },
  //   { color: '#880E4F', text: 'white' },
  //   { color: '#0D47A1', text: 'white' },
  //   { color: '#004D40', text: 'white' },
  //   { color: '#212121', text: 'white' },
  // ]

  Colors = [
    { color: '#FFF9C4', text: 'black' },
    { color: '#FFF3E0', text: 'black' },
    { color: '#FFEBEE', text: 'black' },
    { color: '#FCE4EC', text: 'black' },
    { color: '#E3F2FD', text: 'black' },
    { color: '#D1ECE8', text: 'black' },
    { color: '#F5F5F5', text: 'black' },
    { color: '#FFEB3B', text: 'black' },
    { color: '#FF9800', text: 'black' },
    { color: '#FF8A80', text: 'black' },
    { color: '#FF80AB', text: 'black' },
    { color: '#03A9F4', text: 'black' },
    { color: '#00BFA5', text: 'black' },
    { color: '#9E9E9E', text: 'black' },
    { color: '#E5AE02', text: 'white' },
    { color: '#FFA000', text: 'white' },
    { color: '#F44336', text: 'white' },
    { color: '#E91E63', text: 'white' },
    { color: '#448AFF', text: 'white' },
    { color: '#009688', text: 'white' },
    { color: '#757575', text: 'white' },
    { color: '#C79702', text: 'white' },
    { color: '#DD2C00', text: 'white' },
    { color: '#B71C1C', text: 'white' },
    { color: '#880E4F', text: 'white' },
    { color: '#0D47A1', text: 'white' },
    { color: '#004D40', text: 'white' },
    { color: '#212121', text: 'white' },
  ];

  constructor(props) {
    super(props);
    const { OptionData,toggleTextColor=true } = this.props;
    this.state = {
      pickedColor: OptionData.attributes ? OptionData.attributes.color : 'blue',
      isTextBlack:  OptionData.attributes ? ['#000000','black'].includes(OptionData.attributes.text) : true
    };
  }

  onPickedColor = (color) => {
    const {autoSave,OptionData} = this.props;
    const {isTextBlack}=this.state;

    const textcolor = this.Colors.filter((el) => el.color.toLowerCase() === color.hex)[0].text;
    this.setState({ pickedColor: color.hex, isTextBlack: textcolor === 'black' });
    if(autoSave) {
      this.updateColorLocal(OptionData.optionid,color.hex,isTextBlack ? 'black' : 'white')
    }

  };

  manualCodeChange = (color) => {
    const {OptionData,autoSave} = this.props;
    const {isTextBlack}=this.state;

    if (!color.includes('#')) {
      color = '#' + color;
    }
    this.setState({ pickedColor: color });
    if(autoSave) {
      this.updateColorLocal(OptionData.optionid,color,isTextBlack ? 'black' : 'white')
    }
  };

  changeTextColor = (isBlack) =>{
    const {OptionData,autoSave} = this.props;
    const {pickedColor}=this.state;

    this.setState({isTextBlack: isBlack})
    if(autoSave) {
      this.updateColorLocal(OptionData.optionid,pickedColor,isBlack ? 'black' : 'white')
    }
  }

  NavigateToDashboard = () => {
    const { activeZone } = this.props;
    navigate(`/zone/${activeZone.id}`);
  };

  updateColorLocal = (optionid, color, texcolor) => {
    const { activeZone, ShowError, UpdateColor } = this.props;
    const tempcolor = '#' + color;
    const idx = this.Colors.findIndex((itm) => itm.color.toLowerCase() === color);
    
    // if it matches one of predefined colors or they are on paid plan
    if (idx !== -1 || activeZone.plan !== 'Free') {
      UpdateColor(optionid, color, texcolor);
    } else {
        if (activeZone.role === 'Zone owner') {
          ShowError(
            <div style={{ margin: '5px' }}>
              The free plan does not support custom colors. Upgrade now by clicking the option in
              the top right corner of the {` `}
              <Link to="/" style={{color:'white'}}><u><b>Zone dashboard</b></u></Link>
            </div>,
          );
        } else {
          ShowError(
            <div style={{ margin: '5px' }}>
              The Free plan does not support custom colors, consider upgrading. Contact your Zone
              owner.
            </div>,
          );
        }
        return false
    }
  };

  render() {
    const { pickedColor, isTextBlack } = this.state;
    const { UpdateColor, OptionData, color, close,autoSave,activeZone,toggleTextColor=true,isTextColor=false } = this.props;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'start',
          }}
        >
          <CirclePicker
            // colors={this.rawColors}
            colors={this.Colors.map(el=>el.color)}
            color={pickedColor}
            width={265}
            circleSpacing={8}
            // eslint-disable-next-line react/jsx-no-bind
            onChangeComplete={this.onPickedColor.bind(this)}
          />
          <div style={{ display: 'flex', marginLeft: '10px', flexDirection: 'column' }}>
            <div style={{ marginLeft: '40px', marginBottom: '5px' }}>
              <Icon
                onClick={toggleTextColor ? () => this.changeTextColor(!isTextBlack) : null}
                name="circle"
                size="big"
                style={{
                  color: pickedColor,
                  backgroundColor: isTextBlack ? 'black' : 'white',
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            </div>
            <div>
              <Input
                disabled={activeZone && activeZone.plan==='Free'}
                style={{ width: '85px' }}
                label="#"
                value={pickedColor}
                onChange={(e, data) => this.manualCodeChange(data.value)}
                placeholder="enter hex code"
              />
              {toggleTextColor ? (
              <div style={{ width: '130px', fontSize: '10px' }}>
                Click the swatch to toggle the text color from dark to light
              </div>
              ):null}

            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginTop:'5px' }}>
          <Label style={{ backgroundColor: !isTextColor ? pickedColor : '#EEE', color: isTextColor ? pickedColor : isTextBlack ?  'black' : 'white' }}>
            {OptionData.option}
          </Label>

          {autoSave===undefined || !autoSave ? (
          <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={close}
              onKeyPress={close}
              role="button"
              tabIndex="-1"
            >
              Cancel
            </span>
            <Button
              style={{ backgroundColor: color, color: 'white', marginLeft: '10px' }}
              onClick={() =>
                this.updateColorLocal(
                  OptionData.optionid,
                  pickedColor,
                  isTextBlack ? 'black' : 'white',
                )
              }
            >
              Save
            </Button>
          </div>
          ): null}

        </div>
      </div>
    );
  }
}

OptionColorPicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  OptionData: PropTypes.object.isRequired,
  UpdateColor: PropTypes.func.isRequired,
};

export default OptionColorPicker;
