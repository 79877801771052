/* eslint-disable */
import React,{useEffect} from 'react';
import {sendEmailVerification} from  '../../api/auth'
import GBButton from '../../components/GBButton/GBButton';

const SendEmail =({color,close}) => {

    useEffect(() => {
        sendEmailVerification()
    },[])

    return (
        <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: color,
                textAlign: 'center',
                paddingLeft:'20px',
                color: 'white',
                verticalAlign: 'center',
                height: '40px',
                borderTopRightRadius: '20px',
                borderTopLeftRadius: '20px',
              }}
            >
              Verification email sent
            </div>
            <div style={{margin:'10px'}}>
                Check your email. Click the link provided in this email to validate the email address.
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <GBButton text="OK" color={color} Action={close} borderRadius="10px" />
            </div>
            </div>
    )
}

export default SendEmail