/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"


import Icon from '@mdi/react';
import {
  mdiOpenInNew,
  mdiLinkVariantPlus,
  mdiPencil,
  mdiCloudDownload,
  mdiDeleteForever,
  mdiChevronRight,
  mdiChevronLeft,
  mdiLoading
} from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import AttachmentExtensions from '../../../../utils/attachmentExtensions';
import EditAttachment from './EditAttachment';
import GBButton from 'src/components/GBButton/GBButton';
import brokenimage from '../../../../assets/icons/image-broken.svg';
import Spinner from '../../../../components/Spinner/Spinner';

const AttachmentCarousel = ({
  tempfiles,
  color,
  optionid,
  deleteFile,
  showCategories,
  allCategories,
  updateItem,
  lastUpdateItem,
  isReadOnly,
  caller,
  currentImageIndex,
  windowWidth,
  canEdit
}) => {
  const [imageIndex, setImageIndex] = useState(currentImageIndex);
  const [files, setFiles] = useState(tempfiles);
  const [showEdit, setShowEdit] = useState(false);
  const [width, setWidth] = useState(600);
  const [isImage,setIsImage] = useState(false);
  const [isDoc, setIsDoc]=useState(false);
  const [isWeb, setIsWeb] =useState(false);
  const [isNonViewable, setIsNonViewable] =useState(false);
  const imgEl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] =useState(false);
  const [currentExt,setCurrentExt] =useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [documentConfig, setDocumentConfig] = useState(null);

  const updateWidth = (imageWidth,imageHeight) => {

    if (imageWidth < (caller==='grid' ?  'calc(100vw - 300px)' : windowWidth-100))  {
      setWidth(imageWidth);
    } else {
      setWidth(caller==='grid' ? 'calc(100vw - 300px)' : windowWidth-50);
    }
  };


  // 2-1-24 added this 1 second delay to fix new document viewer, which seems to load 
  //documents much more reliably if we provide time for component to load before loading files.
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000); // Wait for 1 second
    
  //   return () => clearTimeout(timer); // Clear the timer if the component unmounts before the 2 seconds
  // }, [tempfiles,field]); 


  const fetchDocument = async (localFiles,idx) => {
  
    setErrorMessage(null); 

    // setIsLoading(true);

    try {
       const gcsUrl = localFiles[idx].gcsurl;
        // Perform a HEAD request with Axios
        const response = await axios.head(gcsUrl); 
        const fileSize = response.headers['content-length'];


        if (parseInt(fileSize)===0) { 
          setErrorMessage("The selected PDF file is invalid or corrupt."); 
        } else {
          const currentDocument = { uri: gcsUrl };
          const sampleDocuments = [currentDocument];
          setDocumentConfig({ documents: sampleDocuments});
        }
    } catch(error) {
      setErrorMessage("Error fetching document"); 
    }
  };


  // useEffect(()=>{

  //   console.log('updated...')
  //   fetchDocument(files,imageIndex)
  //   // if(files[imageIndex]?.gcsurl !==undefined) {
  //   //   const ext = files[imageIndex].guidname.split('.').pop();
  //   //   if(['doc','pdf','docx','xls','xlsx','ppt','pptx','txt','tiff'].includes(ext.toLowerCase())) {
  //   //     fetchDocument(); 
  //   //   }
  //   // }
  // },[imageIndex])


  const updateImageIndex = (newIndex) => {
    setImageIndex(newIndex)
    changeFileType(files,newIndex);
  }

  const changeFileType = (currentFiles,idx) =>{
   if(currentFiles[idx]===undefined) {
    return false;
   }

    // setIsLoading(true);

    const ext = currentFiles[idx].guidname.split('.').pop();

    setCurrentExt(ext);
  
    if(['jpg', 'jpeg', 'png', 'bmp', 'gif'].includes(ext.toLowerCase())) {
      setIsImage(true);
      setIsDoc(false);
      setIsWeb(false);
      setIsNonViewable(false)
    } else if(['doc','docx','xls','xlsx','ppt','pptx','txt','tiff'].includes(ext.toLowerCase())) {
        setIsDoc(true);
        setIsImage(false);
        setIsWeb(false);
        setIsNonViewable(false)
    } 

    else if(
      currentFiles[idx].gcsurl.includes('https://docs.google.com') ||
      currentFiles[idx].gcsurl.includes('youtube.com') ||
      currentFiles[idx].gcsurl.includes('https://youtu.be') ||
      currentFiles[idx].gcsurl.includes('vimeo.com') ||
     ext==='mp4' || ext==='svg' || ext==='htm' || ext==='web' || ext==='pdf' ) {
      setIsWeb(true);
      setIsDoc(false);
      setIsImage(false);
      setIsNonViewable(false)
    } else {
      setIsNonViewable(true)
      setIsWeb(false);
      setIsDoc(false);
      setIsImage(false);

    }
  }

  useEffect(() => {

    setDocumentConfig(null);
    setWidth(caller==='grid' ? 'calc(100vw - 300px)' : windowWidth-100);
    setFiles(tempfiles);
    setImageIndex(currentImageIndex)

    if(tempfiles.length>0 && tempfiles[imageIndex] !=null){
      changeFileType(tempfiles,imageIndex);   
    }

    fetchDocument(tempfiles,currentImageIndex)

    // || prevProps.current.lastUpdateItem !== lastUpdateItem || prevProps.current.tempfiles !== tempfiles

  }, [optionid,lastUpdateItem,tempfiles]); //optionid,tempfiles,lastUpdateItem  optionid,lastUpdateItem,tempfiles


  const handleError = (ev) => {
    ev.target.src=brokenimage
    ev.target.style.width="200px";
  }



  const OpenInNewTab = () => {
    const ext = files[imageIndex].gcsurl.split('.').pop();
    if(['txt','webm','mov','wmv','flv','html','php','tiff','zip','rar','csv'].includes(ext))
    {
      window.open(`https://docs.google.com/gview?url=${files[imageIndex].gcsurl}`, '_newTab');
    } else if (['doc','docx','ppt','pptx','xls','xlsx'].includes(ext)) {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${files[imageIndex].gcsurl}`, '_newTab');
    }
    else {
      window.open(files[imageIndex].gcsurl, '_newTab');
    }
  };

  const copy = async () => {
    const { REACT_APP_FIREBASE_API_KEY ,REACT_APP_STRIPE_ENV } = process.env;
    
    let ext=''
    if(files[imageIndex].gcsurl.includes('https://docs.google.com'))
    {
      ext='gd'
    } 
    else if(files[imageIndex].gcsurl.includes('youtube.com'))
    {
      ext='yt'
    } 
    else if(files[imageIndex].gcsurl.includes('vimeo.com'))
    {
      ext='vm'
    } 

    else{
      ext = files[imageIndex].gcsurl.split('.').pop();
    }

      let prefixUrl=''
      if(REACT_APP_STRIPE_ENV==='TEST') {
        // prefixUrl='dev.smashfly.com';
        prefixUrl='dev.graceblocks.com/link'
      } else {
        prefixUrl='my.graceblocks.com/link';
      }

      let encodeURL ='';
      let shorturl=files[imageIndex].gcsurl;

      var input = document.createElement('input');

      // 10-25-22 If the attachment is stored on our google storage API, we will shorten the URL using dynamic links
      // otherwise, we just return the original url
      if(
        files[imageIndex].gcsurl.includes('storage.googleapis.com') || 
        files[imageIndex].gcsurl.includes('firebasestorage.googleapis.com') ||
        files[imageIndex].gcsurl.includes('docs.google.com') ||
        files[imageIndex].gcsurl.includes('youtube.com') || 
        files[imageIndex].gcsurl.includes('youtube.be')
      ) {

          // if (['jpg', 'jpeg', 'png', 'bmp', 'gif','gd','yt','vm'].includes(ext.toLowerCase()))
          //10-11-23 updated logic to only use google viewer url if file format one of the supported.
          if(['txt','webm','mov','wmv','flv','html','php','tiff','zip','rar','csv'].includes(ext))
          {
            encodeURL=`https://${prefixUrl}?link=${encodeURIComponent(`https://docs.google.com/gview?url=${files[imageIndex].gcsurl}`)}`
          } else if(['doc','docx','ppt','pptx','xls','xlsx'].includes(ext))
            encodeURL=`https://${prefixUrl}?link=${encodeURIComponent(`https://view.officeapps.live.com/op/view.aspx?src=${files[imageIndex].gcsurl}`)}`
          else {
            encodeURL=`https://${prefixUrl}?link=${encodeURI(files[imageIndex].gcsurl)}`
          }
          const body = {
            "longDynamicLink": encodeURL,
            "suffix": {
              "option": "SHORT"
            }
          }

          const response = await axios({
            url:`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${REACT_APP_FIREBASE_API_KEY}`,
            method:"post",
            data:body,
            headers: { "content-type": "application/json" }
          });

          shorturl = response.data.shortLink.replace('https://','');
    }

      input.setAttribute('value', shorturl);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand('copy');
      document.body.removeChild(input);
      toast.info('Link successfully copied!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });

      return result;
  };

  const download = () => {
    // https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js/50695407

    setIsDownloading(true);


    let contentType = '';
    const ext = files[imageIndex].gcsurl.split('.').pop();
    const idx = AttachmentExtensions.findIndex((itm) => itm.ext === ext);
    if (idx !== -1) {
      contentType = AttachmentExtensions[idx].contentType;
    } else {
      contentType = 'application/octet-stream';
    }

    // https://cors-anywhere-wcxii7qk4q-uc.a.run.app/
    let finalUrl=''
    if(files[imageIndex].gcsurl.includes('https://storage.googleapis.com/graceblocks'))
    {
      finalUrl = files[imageIndex].gcsurl
    } else {
      finalUrl=`https://cors-anywhere-wcxii7qk4q-uc.a.run.app/${files[imageIndex].gcsurl}`;
    }
    
    fetch(finalUrl, {
      method: 'GET',
      headers: {
        'Content-Type': contentType,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', files[imageIndex].filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        const timer = setTimeout(() => {
          setIsDownloading(false);
        }, 1000); // Wait for 1 second

      });
  };


  return (
    <div>
      {files.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent:  'space-between' ,
              flexDirection: 'row',
              marginLeft: '20px',
              marginTop: '10px',
              marginRight: '20px',
              backgroundColor: caller==='grid' ? '#F1EFEF' : 'white',
              borderRadius: '20px',
              height: '40px',
            }}
          >
           
            <div className="row" style={{ marginLeft: '20px' }}>
              <div style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                Viewing {imageIndex + 1} of {files.length}
              </div>
              <div title={files[imageIndex]?.filename} style={{ fontWeight: 'bold', marginRight: '10px',whiteSpace:'nowrap',width:'200px',overflow:'hidden' }}>
                {files[imageIndex]?.filename}
              </div>
               {/* </>): null} */}
            </div>

            <div
              style={{
                display: 'flex',
                // marginTop: '10px',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: '10px',
                marginRight: '20px',
                justifyContent: 'center',
              }}
            >
              <TextButton
                Action={OpenInNewTab}
                hoverColor={color}
                textColor="black"
                tooltip={caller !=='grid' ? "Open in new tab" : ''}
                text= {caller=='grid' ? "Open in new tab" : ""}
                icon={mdiOpenInNew}
                iconSize="20px"
              />
              <div style={{ width: '20px' }} />
              <TextButton
                Action={copy}
                hoverColor={color}
                textColor="black"
                tooltip={caller !=='grid' ? "Copy link" : ''}
                text= {caller=='grid' ? "Copy link" : ""}
                icon={mdiLinkVariantPlus}
                iconSize="20px"
              />
              {!isReadOnly && canEdit ? (<>
              <div style={{ width: '20px' }} />
              <TextButton
                Action={setShowEdit}
                ActionValue={true}
                hoverColor={color}
                textColor="black"
                tooltip={caller !=='grid' ? "Edit" : ''}
                text={caller=='grid' ? "Edit" : ""}
                icon={mdiPencil}
                iconSize="20px"
              />
              </>): null}
              {!files[imageIndex].gcsurl.includes('https://docs.google.com') && !files[imageIndex].gcsurl.includes('youtube.com') && !files[imageIndex].guidname.includes('web') ? (<>
              <div style={{ width: '20px' }} />
              {isDownloading ? <Icon style={{marginLeft:"10px"}} path={mdiLoading} size="20px" spin="10" color="green" /> : (
              <TextButton
                Action={download}
                hoverColor={color}
                textColor="black"
                tooltip={caller !=='grid' ? "Download" : ''}
                text= {caller=='grid' ? "Download" : ""}
                icon={mdiCloudDownload}
                iconSize="20px"
              />)}

              </>): null}
              <div style={{ width: '20px' }} />
              {!isReadOnly && canEdit ? (
              <TextButton
                Action={deleteFile}
                ActionValue={files[imageIndex]}
                hoverColor={color}
                textColor="black"
                tooltip={caller !=='grid' ? "Delete" : ''}
                text= {caller=='grid' ? "Delete" : ""}
                icon={mdiDeleteForever}
                iconSize="20px"
              />) : null}
            </div>
          </div>

          {showEdit ? (
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <div
              style={{
                // position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                justifyContent: 'center',
                // zIndex: 100,
                // top: 10,
                // left: caller==='grid' ? 100 :10,
                width: caller==='grid' ? '600px' : '500px',
                border: `.3px solid ${color}`,
                height: '300px',
                backgroundColor: 'white',
                // border: '1px solid red',
              }}
            >
              <EditAttachment
                updateItem={updateItem}
                color={color}
                close={setShowEdit}
                allCategories={allCategories.sort((a, b) => (a.rank < b.rank ? 1 : -1))}
                optionid={optionid}
                file={files[imageIndex]}
                imageIndex={imageIndex}
              />
            </div>
            </div>
            ): null}
          
            <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'flex-end',marginRight:'20px',marginTop:'10px'}}>
                <div style={{display:'flex',flexDirection:'row', alignItems:'center',width:'200px'}}>
                  <GBButton Action={files.length >1 ? updateImageIndex : null} ActionValue={imageIndex===0 ? files.length-1 : imageIndex - 1} text="Prev" icon={mdiChevronLeft} iconPosition="left" color={files.length >1 ? color : '#eee'} width="75px" iconColor='white' iconHoverColor='white' />
                  <div style={{width:'10px'}}> </div>
                  <GBButton Action={files.length >1 ? updateImageIndex : null} ActionValue={imageIndex===files.length-1 ? 0 : imageIndex + 1} text="Next" icon={mdiChevronRight} iconPosition="right" color={files.length > 1 ? color : '#eee'} width="75px" iconColor='white'  iconHoverColor='white' />
              </div>
            </div>
            
          <div
            style={{
              position:'relative',
              display: 'flex',
              marginTop: '10px',
              marginBottom: '10px',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
              height:'calc(100vh - 300px)'
            }}
          >           
              {isDoc && !showEdit ? (
                <div style={{width:caller==='grid' ? 'calc(100vw - 500px)' : windowWidth-150,height:'calc(100vh - 400px)'}}>
                 {isLoading ? <Spinner color={color} /> : null}
                 {errorMessage && <div className="error-message">{errorMessage}</div>}
                 {documentConfig ? (
                  <DocViewer 
                    style={{ height: '100%' }}
                    pluginRenderers={DocViewerRenderers}
                    {...documentConfig}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false
                      }
                    }} 
                  />
                ) : null}

              </div>
               ) : null}
              {isImage ? (
                <img
                onError={handleError}
                onClick={OpenInNewTab}
                ref={imgEl}
                src={files[imageIndex].gcsurl}
                style={{ maxWidth: width,maxHeight:'calc(100vh - 320px)',cursor:'pointer' }}
                onLoad={() => updateWidth(imgEl.current.naturalWidth,imgEl.current.naturalHeight)}
              />
              ): null}

              {isWeb && !isLoading ? (
              <div style={{height:'100%',margin:'10px',width:'100%'}}>
                {!['svg','mp4'].includes(currentExt) ? (
                <div onClick={OpenInNewTab} style={{ marginBottom: '10px', cursor:'pointer',color:'#669CF6',fontSize:'12px' }}>
                The link you are trying to view may not allow viewing. If it does not display, click this link to view the file in a new tab or click the download icon (if available) to try viewing the item from your desktop.
                </div>    
                ):null}

                <iframe
                  width="100%"
                  height="100%"
                  allowtransparency="true"
                  allowfullscreen={false}
                  frameBorder="no"
                  scrolling="no"
                  id="iframeID"
                  src={files[imageIndex].guidname.split('.').pop()==='csv' ? `https://docs.google.com/gview?embedded=true&url=${files[imageIndex].gcsurl}`: files[imageIndex].gcsurl}
                ></iframe>
              </div>
              ): null}
              {isNonViewable ? (<div style={{height:'100%',margin:'10px'}}>
                  <div onClick={OpenInNewTab} style={{ marginBottom: '10px', cursor:'pointer',color:'#669CF6',fontSize:'12px' }}>
                 This file format does not offer any preview option. Click here to download.
                  </div>
                  <img src="https://storage.googleapis.com/artifacts.graceblocks-prod.appspot.com/containers/doc_small.png" />
                  </div>
              ): null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AttachmentCarousel;
