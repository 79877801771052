/* eslint-disable */
import React, { useEffect, useState, useReducer,useRef,useContext } from 'react';
import Icon from '@mdi/react';
import _ from 'lodash';
import numbro from 'numbro';
import { Dropdown, Label } from 'semantic-ui-react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import QuickViewVirtual from '../QuickView/QuickViewVirtual';
import { AccountContext } from '../../../../../providers/AccountProvider';
import { GroupData } from 'src/api/groupby';
import TextButton from '../../../../../components/TextButton/TextButton';
import DisplayMultiple from '../../Renderers/DisplayMultiple';
import RelationalTag from '../QuickView/relationalTag';
import SelectedUserItem from '../../../../../components/UserDropdown/SelectedUserItem';
import DisplayLookupValues from '../../Renderers/DisplayLookupValues';
import Spinner from '../../../../../components/Spinner/Spinner';

const GroupView = ({
  tableid,
  tableinfo,
  data,
  rowHeight,
  color,
  showDetailView,
  activeRow,
  setSortField,
  sortField,
  sortOrder,
  UpdateTableColumnWidth,
  heightOffset,
  viewedRecords,
  showBookmarkBar,
  isExpanded,
  loadData,
  recordCount,
  groups,
  calcFields
}) => {
  const [isLoading, setIsLoading] =useState(false);
  const [finalData, setFinalData] = useState([]);
  const [finalGroups, setFinalGroups] = useState([]);
  //   const [numbers, setNumbers] = useState([]);
  const [tableWidth, setTabWidth] = useState(0);
  const [showItems, setShowItems] = useState([]);
  const [levelCalcs, setLevelCalcs] = useState([]);
  const [startSummaryIndex, setStartSummaryIndex] = useState(0);
  const [summaryWidth,setSummaryWidth] = useState(0);
  const [tinfo, setTinfo] = useState(tableinfo);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { userInfo } = useContext(AccountContext);
  const divRef=useRef(null);

  const resultSize=100;

  const options = [
    { key: 'sum', value: 'sum', text: 'Sum' },
    { key: 'avg', value: 'avg', text: 'Avg' },
    { key: 'min', value: 'min', text: 'Min' },
    { key: 'max', value: 'max', text: 'Max' },
    { key: 'median', value: 'median', text: 'Median' },
    { key: 'range', value: 'range', text: 'Range' },
  ];

  const ShowDropDown = (calc, header, level, field) => {
    return (
      <Dropdown text={`${calc} ${header}`}>
        <Dropdown.Menu>
          {options.map((opt) => (
            <Dropdown.Item
              key={opt.key}
              text={opt.text}
              value={opt.text}
              onClick={(e, data) => changeLevelCalc(level, field, data.value)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const changeLevelCalc = (level, field, calc) => {
 
    const currentLevel = level.split('-')[0];
    const levelCalcsTemp = levelCalcs;
    const idx = levelCalcs.findIndex(
      (el) => el.currentLevel === currentLevel && el.field === field,
    );
    if (idx === -1) {
      levelCalcsTemp.push({ currentLevel, field, calc });
    } else {
      levelCalcsTemp[idx] = { currentLevel, field, calc };
    }
    setLevelCalcs(levelCalcsTemp);
    const calcSetting= `group_${tableid}_calculations`
    userInfo[calcSetting] = levelCalcsTemp;

    forceUpdate();
  };

  useEffect(() => {

    const res = GroupData(data, groups, tinfo,calcFields);
    setFinalData(res.data);
    setFinalGroups(res.groups);
    calculateTableWidth();
    // scrollToPosition();
  }, []);

  // 12-12-22 this useeffect autoscrolls to last position user was at, as well as resets
  // the expanded/collapsed groups and resets the calculation levels.
  useEffect(()=>{
    const group = `group_${tableid}_view`;
    const expandSetting=`group_${tableid}_expand`
    const calcSetting= `group_${tableid}_calculations`
    
    if(userInfo[expandSetting]!==undefined) {
    setShowItems(userInfo[expandSetting])
    }

    if(userInfo[calcSetting] !==undefined) {
      setLevelCalcs(userInfo[calcSetting])
    }

    if (divRef.current !== null && userInfo[group] !==undefined) {
      divRef.current.scrollTo(userInfo[group].scrollLeft,userInfo[group].scrollTop)
    }

  },[finalData])


  const calculateTableWidth = (field=null,width=null) =>{

    if(field !==null) {
      const fIdx = tinfo.columns.findIndex(col=>col.data===field)
      if(fIdx !==-1) {
        tinfo.columns[fIdx].width=width;
      }
    }

    const visibleColumns = tinfo.columns.filter(el=>(el.hide===undefined || !el.hide));
    //'sum','avg','nummin','nummax','count','countdistinct','median'
    const idx =visibleColumns.findIndex(el=> el.data !=='id' && ([17,23].includes(el.uitype) || el.uitype===18 && [17,23].includes(el.source?.lookupuitype)  && (!['all','unique'].includes(el.specialfunction) || el.source.lookup==='single' )) || el.fieldType==='numeric')

    const w = _.sumBy( visibleColumns.slice(0,idx), item=> Number(item.width)); 
    
    let idExtra=0;
    // 12-1-22, need to check the "id" width because if they use columnmanager to re-order columns, this 
    //always gets set back to 25, as you can't re-order the id column. Therefore it it's 25, we need
    //to append the either (250-25) or (150-25 if mobile) to columns remain aligned with summary fields
    if(parseInt(visibleColumns[0].width)===25 && window.innerWidth<=500){
      idExtra=125
    } else if(parseInt(visibleColumns[0].width)===25 && window.innerWidth>500){
      idExtra=225;
    }


    setStartSummaryIndex(idx)
    setSummaryWidth(w-40+idExtra);

    let x = 250;
    tinfo.columns
      .filter((el) => el.hide === undefined || !el.hide)
      .map((col) => {
        x = x + parseInt(col.width);
      });
    
    setTabWidth(x);
    setTinfo(tinfo)
  }

  const updateWidth = async (field,width) =>{
    if(UpdateTableColumnWidth !==null) {
      await UpdateTableColumnWidth(field,width);
    }

    const temp = structuredClone(tinfo)
    const fIdx = temp.columns.findIndex(el=>el.data===field)
      if(fIdx !==-1) {
        temp.columns[fIdx].width=width;
      }

   
    // const res = GroupData(data, groups, temp,calcFields);
    // setTinfo(temp);
    // setFinalData(res.data);
    // setFinalGroups(res.groups);
    
    calculateTableWidth(field,width);
   
  }

  const CollapseExpand = (isCollapse) => {
    let temp = showItems;
    if (isCollapse) {
      finalData.map((r) => {
        temp.push(r.level);
      });
    } else {
      temp = [];
    }

    //update usersetting
    const expandSetting=`group_${tableid}_expand`
    userInfo[expandSetting] = temp;

    setShowItems(temp);
    forceUpdate();
  };

  const handleScroll = (e) =>{
    const group = `group_${tableid}_view`;
    const scrollLeft=e.currentTarget.scrollLeft;
    const scrollTop = e.currentTarget.scrollTop;
    userInfo[group] = {scrollLeft,scrollTop};
  }

  const getTableWidth = (keys) => {
    const idx = finalGroups.findIndex((el) => keys.includes(el));
    return tableWidth + finalGroups.length * 20 - idx * 20;
  };

  const toggleShow = (level) => {
    let tempItems = structuredClone(showItems);
    const idx = tempItems.findIndex((el) => el === level);
    if (idx === -1) {
      tempItems.push(level);
    } else {
      tempItems = tempItems.filter((el) => el !== level);
    }
    setShowItems(tempItems);

    const expandSetting=`group_${tableid}_expand`
    userInfo[expandSetting] = tempItems;
  };

  const showLevel = (level) => {
    const idx = showItems.findIndex((el) => el === level);
    return idx === -1;
  };


  const getDisplayValue = (flatField, flatValue) => {

    const idx = tableinfo.columns.findIndex((el) => el.data === flatField.replace('_flat', ''));

    if (idx !== -1) {
      const colInfo = tableinfo.columns[idx];

      if (flatValue === null || flatValue === undefined || flatValue === '') {
        return 'No value';
      } else if (
        [6, 7].includes(colInfo.uitype) ||
        (colInfo.source && [6, 7].includes(colInfo.source.lookupuitype))
      ) {
        return (
          <DisplayMultiple
          value={[{id:0,data:flatValue}]}
            // value={JSON.parse(flatValue)}
            enableColors={colInfo.enableColors}
            uitype={colInfo.uitype}
            field={colInfo.displayfield}
            paddingTop="0px"
          />
        );
      } else if (colInfo.uitype === 1 || (colInfo.source && colInfo.source.lookupuitype === 1)) {
        // return <RelationalTag value={JSON.parse(flatValue)} color={color} />;
        return <RelationalTag value={[{id:0,data:flatValue}]} color={color} />
      } else if (
        [24, 25].includes(colInfo.uitype) ||
        (colInfo.source && [24, 25].includes(colInfo.source.lookupuitype))
      ) {
        const usr = JSON.parse(flatValue);
        return (
          <SelectedUserItem key={usr.userid} item={usr} color={usr.attributes.text} Action={null} />
        );
      } else if (colInfo.uitype === 8 || (colInfo.source && colInfo.source.lookupuitype === 8)) {
        // return <div>Test</div>
        return JSON.parse(flatValue).map((usr) => (
          <div
            key={Math.random()}
            style={{
              height: '100%',
              paddingTop: '2px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <SelectedUserItem
              key={Math.random()}
              item={usr}
              color={usr.attributes.text}
              Action={null}
            />
          </div>
        ));
      } else if ([16,18].includes(colInfo.uitype) && colInfo.renderandedit === 'LookupValues') {
        return (
          <DisplayLookupValues
            value={[{id:0,data:flatValue}]}
            isEditor={false}
            isRenderer={true}
            uitype={colInfo.uitype===16 ? colInfo.uitype : colInfo.source.lookupuitype}
            numericFormat={colInfo.numericFormat}
            dateFormat={colInfo.dateFormat}
            fieldType={colInfo.fieldType}
            UTC={colInfo.UTC}
            specialfunction={colInfo.specialfunction}
          />
        );
      } else {
        // return row[colInfo.data];
        return flatValue.toString();
      }
    } else {
      return 'none';
    }
  };

  const getSumKeyValue = (row, field, sumKeys, numericFormat, header) => {
    // const idx = sumKeys
    //   .map((el) => el.replace('_flat', '').replace('Sum', ''))
    //   .findIndex((el) => el === field);

      const idx = sumKeys
      .map((el) => el.replace('_SumCalc', ''))
      .findIndex((el) => el === field);

    if (numericFormat !== undefined) {
      numbro.setLanguage(numericFormat.culture);
    }

    if (idx !== -1) {
      let value =
        numericFormat !== undefined
          ? numbro(row[sumKeys[idx]].sum).format(numericFormat.pattern)
          : row[sumKeys[idx]].sum;
      let calc = 'Sum';
      let currentLevel = row.level.split('-')[0];
      const calcIndex = levelCalcs.findIndex(
        (el) => el.currentLevel === currentLevel && el.field === sumKeys[idx],
      );
      if (calcIndex !== -1) { 
        calc = levelCalcs[calcIndex].calc;
        if(calc==='Range') {
            let rangeMin =row[sumKeys[idx]].min
            let rangeMax =row[sumKeys[idx]].max
            if(numericFormat !==undefined && rangeMin !==null && rangeMax !==null ) {
                value =`${numbro(rangeMin).format(numericFormat.pattern)} - ${numbro(rangeMax).format(numericFormat.pattern)} `
            } else {
                value =`${rangeMin} - ${rangeMax}`
            }
        } else {
            value = row[sumKeys[idx]][calc.toLowerCase()]
            if(value !==null) value=value.toFixed(2)
            if(numericFormat !== undefined && value>0) {
              value = numbro(value).format(numericFormat.pattern)
            } 
        
        }
      }

      return (
        <>
          <div>{ShowDropDown(calc, header, row.level, sumKeys[idx])}</div>
          <div>{ value}</div>
          {/* <div>{parseFloat(value).toFixed(2)}</div> */}
        </>
      );
    } else {
      return '';
    }
  };

  const renderData = (rows) => {
    return (
      <div>
        {rows.map((row, i) => (
          <div
            key={i}
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              backgroundColor: ['0','2'].includes(row.level.split('-')[0]) ? `${color}1A` : '#FFFFFF',
              width: getTableWidth(Object.keys(row)),
              marginLeft: '10px',
              marginRight: '10px',
              border: `1px solid ${color}`,
              //   display: showLevel(row.level) ? 'block' : 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: '10px',
              }}
            >
              {Object.keys(row)
                .filter((el) => !el.includes('Sum') && el !== 'data' && el !== 'level')
                .map((el) => (
                  <>
                    <Icon
                     style={{cursor:'pointer'}}
                      path={showLevel(row.level) ? mdiChevronDown : mdiChevronUp}
                      size="25px"
                      onClick={() => toggleShow(row.level)}
                    />

                    <div
                      style={{
                        margin: '10px',
                        width: summaryWidth,
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div>{getDisplayValue(el, row[el])}</div>
                      <div style={{marginLeft:'3px'}}>({row.data[0].id !==undefined ? row.data.length : `${row.data.length} Group${row.data.length>1?'s':''} - ${row.SumCounter} Record${row.SumCounter>1 ?'s':''} `}) </div>
                    </div>
                  </>
                ))}

              {tableinfo.columns
                .filter((el) => (el.hide === undefined || !el.hide) && el.data !== 'id').slice(startSummaryIndex-1)
                .map((col) => (
                  <div style={{ width: parseInt(col.width) }}>
                    {getSumKeyValue(
                      row,
                      col.data,
                      Object.keys(row).filter((el) => el.includes('Sum')),
                      col.numericFormat,
                      col.header,
                    )}
                  </div>
                ))}

            </div>
            {row.data !== undefined && row.data[0].id === undefined && showLevel(row.level) ? (
              renderData(row.data)
            ) : (
              <div
                style={{
                  marginLeft: '3px',
                  marginBottom: '3px',
                  display: showLevel(row.level) ? 'block' : 'none',
                }}
              >
                <QuickViewVirtual
                  color={color}
                  rowHeight={rowHeight}
                  tableid={tableid}
                  tableinfo={tinfo}
                  data={row.data}
                  showDetailView={showDetailView}
                  activeRow={activeRow}
                  setSortField={setSortField}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  heightOffset={heightOffset}
                  UpdateTableColumnWidth={updateWidth}
                  showBookmarkBar={showBookmarkBar} //added so we can properly calculate height of grid.
                  isExpanded={isExpanded}
                  loadData={null}
                  recordCount={recordCount}
                  viewedRecords={viewedRecords}
                  groupTableHeight={row.data.length <resultSize ? (rowHeight * row.data.length+70) : rowHeight*resultSize+70 }
                  groupTableWidth={tableWidth}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      ref={divRef}
      onScroll={handleScroll}
      style={{
        overflow: 'auto',
        height:
          heightOffset === undefined
            ? window.innerHeight - (!showBookmarkBar ? 160 : 185)
            : `calc(100vh - ${heightOffset}px)`,
        width:
          heightOffset === undefined
            ? !isExpanded
              ? window.innerWidth - 100
              : window.innerWidth - 270
            : '100vw',
        flexDirection: 'column',
      }}
    >
      <div
        style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <TextButton
          text="Collapse all"
          textColor={color}
          hoverColor={`${color}80`}
          Action={CollapseExpand}
          ActionValue={true}
        />
        &#160; | &#160;
        <TextButton
          text="Expand all"
          textColor={color}
          hoverColor={`${color}80`}
          Action={CollapseExpand}
          ActionValue={false}
        />
      </div>
      {!isLoading ? (
        renderData(finalData)
      ): <Spinner />}
     
    </div>
  );
};

export default GroupView;
