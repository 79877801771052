import { useContext } from 'react';
import { AccountContext } from '../../../providers/AccountProvider';

const useZone = (zoneID) => {
  const {
    userInfo: { zones },
  } = useContext(AccountContext);
  const current = zones.find((z) => z.id === zoneID);
  return current;
};

export default useZone;
