/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';

import { Popup } from 'semantic-ui-react';

const RowHeightButton = ({ selectedHeightIcon, color, Action, label, labelPosition, showBar3 }) => {
  // 6-18-2020. Added the label, LabelPostion and Action params so that this could be re-usable
  // both in top table nav, as well as within DataLlist control (to control relaterecord expanding.)
  // showBar3 determines if we show the last bar (3 bars) . In datalist, we only show 2.

  // right now Action will be hard-coded to either changing rowheight or datalist item collpase/expand.
  // show update to be more flexible with various actions.

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        color: '#312602',
        fontSize: '12px',
        marginBottom: '9px',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        alignItems: 'center',
        outline: '0px',
        // border:'1px solid blue',
        '&:hover': {
          transition: 'all .3s ease',
          color: `${color}80`,
        },
        '&:active': {
          color: `${color}`,
        },
      }}
    >
      {labelPosition === 'top' ? <div>{label}</div> : null}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          css={{
            cursor:'pointer',
            color: selectedHeightIcon === 1 ? color : '#312602',
            '&:hover': {
              svg: {
                color: `${color}80`,
                transition: 'all .3s ease',
              },
            },
            '&:active': {
              color: `${color}`,
              svg: {
                color: `${color}`,
              },
            },
          }}
        >
          <Popup
            content="Short height"
            trigger={
              <Icon
                onClick={showBar3 ? () => Action(33, 1) : () => Action(false)} // right now, if showBar3 is true, that means we change row height/otehrwise expand/cllapse of datalist div.
                //   color={selectedHeightIcon === 1 ? 'rgba(13, 71, 161, 1)' : 'rgba(13, 71, 161, .25)'}
                path="M3,5V7H21V5H3Z"
                size="25px"
              />
            }
          />
        </div>
        <div
          css={{
            cursor:'pointer',
            color: selectedHeightIcon === 2 ? color : '#312602',
            '&:hover': {
              svg: {
                color: `${color}80`,
                transition: 'all .3s ease',
              },
            },
            '&:active': {
              color: `${color}`,
              svg: {
                color: `${color}`,
              },
            },
          }}
        >
          <Popup
            content="Medium height"
            trigger={
              <Icon
                onClick={showBar3 ? () => Action(60, 2) : () => Action(true)}
                //   color={selectedHeightIcon === 2 ? 'rgba(13, 71, 161, 1)' : 'rgba(13, 71, 161, .25)'}
                path="M3,11H21V9H3V11M3,5V7H21V5H3Z"
                size="25px"
              />
            }
          />
        </div>
        {showBar3 ? (
          <div
            css={{
              cursor:'pointer',
              color: selectedHeightIcon === 3 ? color : '#312602',
              '&:hover': {
                svg: {
                  color: `${color}80`,
                  transition: 'all .3s ease',
                },
              },
              '&:active': {
                color: `${color}`,
                svg: {
                  color: `${color}`,
                },
              },
            }}
          >
            <Popup
              content="Tall height"
              trigger={
                <Icon
                  onClick={() => Action(120, 3)}
                  //   color={selectedHeightIcon === 2 ? 'rgba(13, 71, 161, 1)' : 'rgba(13, 71, 161, .25)'}
                  path="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
                  size="25px"
                />
              }
            />
          </div>
        ) : null}
        {labelPosition === 'right' ? <div style={{ marginLeft: '3px' }}>{label}</div> : null}
      </div>
    </div>
  );
};

export default RowHeightButton;
