/* eslint-disable */
import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCloudDownloadOutline } from '@mdi/js';
import moment from 'moment';
import { Button } from 'semantic-ui-react';


const ExportModal = (props) => {
  const { exportdata, close, color, name,blockname } = props;

  const sentence = `A .csv file with ${exportdata.count} records has been generated.`;

  
  const filename = () => {
    const date=moment().format("MM-DD-YYYY")
    return `${name}_${date}`
  }

  return (
    <div style={{height:'200px'}}>
      {blockname !=='Share' ? (<>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '40px',
          backgroundColor: color,
          color: 'white',
        }}
      >
        <Icon path={mdiCloudDownloadOutline} size="30px" color="white" />{' '}
        <span style={{ marginLeft: '5px',fontSize:'20px',fontWeight:'bold' }}>Processing</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column',marginTop:'20px' }}>
        <div style={{ fontSize: '13px', margin: '10px' }}>
        Your download file has been requested. Please allow a few minutes for the request to be processed. Once completed, you will receive a notification via the bell icon in the top right, as well as an email informing you that the file is ready for download.
        </div>
        <div style={{ width: '140px',marginTop:'40px' }}>
          <Button fluid onClick={close}>
            OK
          </Button>
        </div>
      </div>
      </>) : null}
      
     {blockname==='Share' ? (<>
            <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              backgroundColor: color,
              color: 'white',
            }}
          >
            <Icon path={mdiCloudDownloadOutline} size="30px" color="white" />{' '}
            <span style={{ marginLeft: '5px',fontSize:'20px',fontWeight:'bold' }}>File ready for download</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ fontSize: '13px', margin: '10px' }}>
              {sentence} Click the <p style={{ display: 'inline', fontWeight: 'bold' }}>Download now</p>{' '}
              button below to save the file locally.
            </div>
            <div onClick={close} style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <CsvDownloader
                filename={filename()}
                separator=","
                // wrapColumnChar="'"
                columns={exportdata.columns}
                datas={exportdata.datas}
              >
                <Button fluid style={{ color: 'white', backgroundColor: color }}>
                  Download now
                </Button>
              </CsvDownloader>
            </div>
            <div style={{ width: '140px' }}>
              <Button fluid onClick={close}>
                Cancel
              </Button>
            </div>
          </div>
          </>) : null}

    </div>
  );
};

ExportModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  exportdata: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default ExportModal;
