/* eslint-disable */
import React, {useState,useRef,useEffect} from 'react';
import TextInput from '../../components/TextInput/TextInput';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import Spinner from '../../components/Spinner/Spinner';
import {getRegions} from '../../components/Form/Controls/PhoneNumber/phoneUtils';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';
import { confirmAlert } from 'react-confirm-alert';
import {Checkbox, Input,Dropdown} from 'semantic-ui-react';
import phoneRates from './phoneRates';
import moment from 'moment';

const AddPhoneNumber = ({color,toggleShowAdd,AddNumber}) => {

    const [useInternational,setUseInternational] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState([]);
    const [selectedCountry,setSelectedCountry]=useState('US');
    const [areaCodeSpecified, setAreaCodeSpecified] = useState('');

    const dueDate = moment().add(30,'days').format('MMM DD, YYYY')

    const divRef = useRef();

    useEffect(() => {
            const onlyCountryCodes = [
                'AU',
                'AT',
                'BE',
                'CA',
                'CL',
                'CZ',
                'DE',
                'DK',
                'EE',
                'FR',
                'IL',
                'HK',
                'HU',
                'LT',
                'MY',
                'NL',
                'PH',
                'PL',
                'PT',
                'SG',
                'ZA',
                'CH',
                'GB',
                'US',
              ];
              const regions = getRegions({onlyCountryCodes,favoriteCountryCodes: ['US']})
             setRegions(regions)
            //  console.log(regions)
    },[])

    const enableInternational =() => {
        if(divRef.current.value.toLowerCase()==='i agree') {
            setUseInternational(!useInternational)
        } 
    }

    const getNumber = async () => {
      setIsLoading(true)
        const planId = phoneRates.filter(itm=>itm.country===selectedCountry)[0].planId;
        await AddNumber(selectedCountry,areaCodeSpecified,useInternational,planId)
      setIsLoading(false)
    }

    const confirmInternational = () => {

      const message=<div>Are you sure you want to activate international texting for this number? Currently, each individual incoming or outgoing text will incur a charge based on the international rates and can be as much as $.22 US per text message segment sent and/or received. This price is subject to change. <a href="https://www.graceblocks.com/messaging-pricing" target="_blank">View current rates</a>.</div>
      
      return (
      confirmAlert({
        customUI: ({onClose}) => {
          return (
            <GBConfirm 
              title={'International text fees'}
              action={enableInternational}
              buttonLabel="OK"
              message={message}
              divRef={divRef}
              showInput={true}
              confirmAction=""
              confirmWord="I agree"
              height="450px"
              onClose={onClose}
              />
          )
        }})
      )
      };

    const GetOptions =() => {
        let options=[]
        regions.map(itm => {
            options.push({key:itm.id,value:itm.region,text:`${itm.region} +${itm.iso2}`})
        })
        return options
    }

    return (
      <div>
        {!isLoading ? (
        <div style={{width:'600px',backgroundColor:'white',zIndex:200,border:'1px solid #AE007B',borderRadius:'10px'}}>
            <div
                style={{
                display: 'flex',
                flexDirection: 'row',
                borderTopRightRadius:'10px',
                borderTopLeftRadius:'10px',
                height: '30px',
                color:'white',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#AE007B',
                }}
        >
            <div style={{ marginLeft: '20px' }}> 
            Add phone number
            </div>
        </div>
           <div style={{margin:'10px',marginLeft:'20px'}}>
           Select the country for this number.  For US or Canada a desired area code will also be requested to help in randomly selecting a good number.
           </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                
                <div style={{marginLeft:'20px',height:'60px',display:'flex', color:'#AE007B', flexDirection:'column', alignItems:'flex-start'}}> 
                <div>COUNTRY</div>
                <div style={{width:'175px'}}>
                    <Dropdown  selectOnBlur={false} value={selectedCountry} onChange={(e,data)=>setSelectedCountry(data.value)} selection options={GetOptions()} />
                    {/* <select value={selectedCountry} onChange={(e)=>setSelectedCountry(e.target.value)} style={{outline:'none',border:'1px solid #DcDCDC', margin:'2px',  borderRadius:'5px'}}>
                        {GetOptions()}
                    </select> */}
                    </div>
                </div>
                {selectedCountry==='US' || selectedCountry==='CA' ? (
                <div>
                    <div style={{display:'flex',width:'75px',marginLeft:'20px',  height:'60px',color:'#AE007B', flexDirection:'column', alignItems:'flex-start'}}>
                        <div style={{whiteSpace:'nowrap'}}>AREA CODE</div>
                        <div style={{width:'35px'}}><Input value={areaCodeSpecified} onChange={(e,data) => setAreaCodeSpecified(data.value)} style={{width:'85px'}} placeholder="area code"/></div>
                    </div>
                </div>
                ): null}

                {/* <div style={{width:'125px'}}><TextInput width="75px" color="#AE007B" field="country" name="AREA CODE" /></div> */}
                <div>
                    <div style={{display:'flex', marginLeft:'20px',height:'62px',color:'#AE007B', flexDirection:'column', alignItems:'center'}}>
                        <div>ALLOW INTERNATIONAL TEXTS</div>
                        <div style={{marginTop:'15px'}}><Checkbox checked={useInternational} onChange={!useInternational ? confirmInternational : ()=>setUseInternational(false)} /></div>
                    </div>
                </div>
              
            </div>
            <div style={{margin:'10px',marginLeft:'20px', fontWeight:'bold'}}>
           The monthly cost for a number in {phoneRates.filter(itm=>itm.country===selectedCountry)[0].name} is ${phoneRates.filter(itm=>itm.country===selectedCountry)[0].rate.toFixed(2)} per month.
           </div>

            <div style={{color:color,margin:'10px',marginLeft:'20px',  fontWeight:'bold'}}>The default payment method for this Zone will be used to process this transaction. </div>
            <div style={{margin:'20px'}}>
            You authorized GraceRock, d.b.a. GraceBlocks, to charge you the amount shown above. The ongoing monthly fee will be ${phoneRates.filter(itm=>itm.country===selectedCountry)[0].rate.toFixed(2)} US Dollars.
            Your next billing cycle is on {dueDate}. Your subscription renews automatically each month until you cancel by deactivating this number or deactivating this Zone. 
            By clicking the button below, you understand and agree to these terms and the GraceBlocks <a href=" https://www.graceblocks.com/termsofservice" target="_blank">Terms of Service</a> and <a href="https://www.graceblocks.com/privacy" target="_blank" >Privacy Policy</a>. 
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginBottom:'30px', marginTop:'20px'}}>
                <TextButton Action={toggleShowAdd} hoverColor="#75757580" text="Cancel" textColor="black" />
                <div style={{width:'20px'}}></div>
                <GBButton text="Get new number" color="#AE007B" Action={getNumber} />
            </div>
        </div>
        ) : <Spinner />}
        </div>
    )
}

export default AddPhoneNumber