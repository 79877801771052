export const AUTH = {
  PATH: '/auth',
  CHILDREN: '/auth/*',

  HOME: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_UP_INVITE: '/sign-up-invite/:registeremail/:zoneid/:trxid/:blockname',
  CONFIRM_INVITE: '/confirm-invite/:trxid/:blockname',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRM: '/forgot-password/:code',
  SEGMENT_INFO: '/segment-info',
  PROFILE: '/profile',
  PHONE_NUMBER: '/phone-number',
  PHONE_NUMBER_RESERVED: '/validate-phone-number',
  ZONE_SETUP: '/zone-setup',
  SHARELOGIN: '/share/:token',

  SIGN_UP_STEPS: '/process',
};

export const FORMS = {
  PATH: '/form',
  CHILDREN: '/form/*',
  HOME: '/',
}

export const DASHBOARD = {
  PATH: '',
  CHILDREN: '/*',

  HOME: '/',
  ZONE_PREFIX: '/zone/',
  ZONE: '/zone/:zoneID',
  BLOCK_PREFIX: '/block/',
  BLOCK: '/block/:blockID/*',
  APIDOCS_PREFIX:'/apidocs/',
  APIDOCS:'/apidocs/:blockID',
  SHARE:'/share/:token',
  RECORD:'/record/:token',
  WORKFLOW_PREFIX:'/workflow',
  WORKFLOW:'/workflow/:blockID',
};

export const ZONE_DASHBOARD = {
  PATH: '/zone',
  CHILDREN: '/zone/*',
  ZONE: '/:zoneID',
};

export const REDIRECTION = {
  AUTH: `${AUTH.PATH}${AUTH.HOME}`,
  DASHBOARD: `${DASHBOARD.PATH}${DASHBOARD.HOME}`,
  SIGN_IN: `${AUTH.PATH}${AUTH.SIGN_IN}`,
  SIGN_UP: `${AUTH.PATH}${AUTH.SIGN_UP}`,
  SIGN_UP_INVITE: `${AUTH.PATH}${AUTH.SIGN_UP_INVITE}`,
  CONFIRM_INVITE: `${AUTH.PATH}${AUTH.CONFIRM_INVITE}`,
  FORGOT_PASSWORD: `${AUTH.PATH}${AUTH.FORGOT_PASSWORD}`,
  FORGOT_PASSWORD_CONFIRM: `${AUTH.PATH}${AUTH.FORGOT_PASSWORD_CONFIRM}`,
  PROFILE: `${AUTH.PATH}${AUTH.PROFILE}`,
  PHONE_NUMBER: `${AUTH.PATH}${AUTH.PHONE_NUMBER}`,
  PHONE_NUMBER_RESERVED: `${AUTH.PATH}${AUTH.PHONE_NUMBER_RESERVED}`,
  SEGMENT_INFO: `${AUTH.PATH}${AUTH.SEGMENT_INFO}`,
  ZONE_SETUP: `${AUTH.PATH}${AUTH.ZONE_SETUP}`,
  AUTH_PROCESS: `${AUTH.PATH}${AUTH.SIGN_UP_STEPS}`,
  FORMS: `${FORMS.PATH}${FORMS.HOME}`,
  SHARELOGIN: `${AUTH.PATH}${AUTH.SHARELOGIN}`,
};
