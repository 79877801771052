/* eslint-disable */
import {UpdateUserStatus,RemoveUserFromBlock} from '../../../../api/users'
import cloudrun from '../../../../clients/httpcloudrun';

// in this method, "id" is the actual userid we are taking action on. "userid" is the person taking the action. 
// data is the status. 
const TriggerAction = async (action, data, id, userid,RefreshTable,additionalparams,blockid) => {

    //11-10-23 Note, in this code, the "id" is the table selected user, where the "userid" is the
    //person taking the action.

    let link='';
    

    switch (action) {
        case 'UpdateUserStatus': 
            if(data==="Active") {
                // this is called from "Users" table to update status to active/inactive or send invite.
                // If Active, button click="inactive" user. 
             await UpdateUserStatus(id,"Inactive",userid)
             await RefreshTable()
            } else if (data==='Inactive')
            {
                // if Inactive, button click = "Activate" user.
                await UpdateUserStatus(id,"Active",userid)
               await  RefreshTable()
            } else if (data==='Not yet invited' || data==='Invited')
            {
                 // in this case, action indicates "Send email invite"
                 // we will call GCF to create trxid, and send email to user.
                 const payload={userid:id,actionuserid:userid}
                 link = await cloudrun.post('/sendUserInvite', {payload})
                 await  RefreshTable()
            } 
            break;
        case "UpdateBlockUserStatus": 
        if(data==="Active" || data==='Inactive') {
            // this is called from "BlockUsers" table to remnove a user from Block
            await RemoveUserFromBlock(blockid, id)
            await  RefreshTable()

        } else if (data==='Not yet invited' || data==='Invited')
        {
             // in this BlockUser case, the "id" is the id column from BlockUsers, not the userid.
             // therefore we need to retrieve the userid from blockusers from this blockuserid.
             let payload={blockid, userid: id, dbfunction:'getBlockUserById'}
             const dbresponse =await cloudrun.post('/executeQueryAPI', {payload})

             const currentuserid = dbresponse[0].userid;

             // we will call GCF to create trxid, and send email to user.
             const blockinfo = JSON.parse(additionalparams)
             payload={userid:currentuserid,actionuserid:userid,...blockinfo}
            
             link = await cloudrun.post('/sendUserInvite', {payload})
             await RefreshTable()
        } 
        break;

        default:
            break;
    }
    return link;
}

export default TriggerAction