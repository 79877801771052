/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle } from '@mdi/js';
import styles from './style.scss';

const Spinner = ({ isLoading, isDone, color,showMessages=true }) => {
  const Messages = [
    'Please wait, antibody not required, so it won’t be too long.',
    'Please wait, assembling the block bits.',
    'Please wait, while we architect for you, thanks for granting us the grace of your patience.',
    'Please wait, blocks be building.',
    'Please wait, this will be much shorter than an airport screening.',
    'Please wait, pay no attention to the man behind the curtain.',
    'Please wait, and enjoy the spinning. Should hypnosis occur, it was completely accidental.',
    'Please wait, while the elves finish their knitting.',
    'Please wait, blasting through new territory for you, this will just take a few seconds.',
    'Please wait, and dream of faster computers.',
    'Please wait, the coffee is brewing.',
    'Please wait, now might be a good time to order takeout.',
    'Please wait, validating you have authorization for that action.',
    'Please wait, breath in once through your nose, hold it, now out through your nose, hmmm.',
    'Please wait, it shouldn’t be as bad as being on hold.',
    'Please wait, while we work on making things happen.',
    'Please wait, need a mantra? Try peace, be still.',
    'Please wait, we are polishing the finish.',
    'Please wait, no need to change a thing, we love you just the way you are.',
    'Please wait, while this small startup tries to fulfill your every need.',
    'Please wait, this is not a test, it’s a necessary pause in the programming.',
    'Please wait, while we test your patience.',
    'Please wait, now that we have your attention, have a great day!',
    'Please wait, and don’t forget to stop and smell the roses.',
    'Please wait, we’re bringing the donuts and trying not to go nuts.',
    'Please wait, and find something worth giving thanks for today.',    
    "Please wait, don't think of trolls.",
    'Please wait, close your eyes and what do you see?',
    'Please wait, have you had your apple yet today?',
    'Please wait, follow the yellow brick road or the rabbit, whichever suits your fancy',
    'Please wait, are you hungry?',
    'Please wait, connecting the wires.',
    'Please wait, the bits are biting.',
    'Please wait, we’ll do our best to make it worthwhile.',
    'Please wait, unless you think you could code what you need by hand faster.',
    'Please wait, have you finished your homework?',
    'Please wait, is your cell phone charging?',
    'Please wait, mastering the trade of compiling the bits into just the right order.',
    'Please wait, since you’re already here.',
    'Please wait, a circle is round and has no end. This is not that.',
'Please wait, and cheers to this moment, soon to be forgotten.',
'Please wait, and try not to be a bad influence on your friends.',
'Please wait, did you know blue in butterfly wings is from physics, not pigment?',
'Please wait, so about the Oxford comma, we like like dogs, apples, and donuts. That’s our stand. We’ll try to stick to it.',
'Please wait, by the way, things are "different from" not "different than." At least, this is what I was taught in the 80’s by Mrs. Swicker. Back then we also taught to put two spaces after periods.',
'Please wait, think outside the box, and then build it with some blocks.',
'Please wait, like an elevator, pressing more buttons at this point will only slows things down.',
'Please wait, sometimes stuff breaks, but that’s not this. ',
'Please wait, have you cleaned your closet lately?',
'Please wait, we hope to earn your trust that the wait will be short, and perhaps even a breath of fresh air.',
'Please wait, thank you kindly.',
'Please wait, while we shine a light on your creativity.',
'Please wait, and try not to get distracted.',
'Please wait, did you know you can add 😃 emoji’s 😀 to any text using [WINDOWS] [.] on Windows 10+ or [CTRL] [CTRL] [SPACE] on Mac ?',
'Please wait, and be  someone’s sunshine today. ',
'Please wait, sometimes you need to smash things to fly free; sometimes you just need a good set of building blocks.',
'Please wait, your blocks look marvelous!',
'Please wait, treat yourself to something great today.',
'Please wait, there is no shame in taking a little time for yourself.',
'Please wait, have you changed your password recently?',
'Please wait, and play some music.',
'Please wait, it’s not the oympics, but we are still hoping for record time. If not then I guess we are still that annoying kid from gym class, miserably failing while giving it their all.',
'Please wait, plan a happy for someone today.',
'Please wait, stay amazing.',
'Please wait, use this time to improve your drawing skills.',
'Please wait, if THIS IS YELLING, and this is talking, whAT iS tHiS?',
'Please wait, fish, meet bicycle.',
'Please wait, of this has been a no good, horrible, very bad day, please treat yourself to a warm self hug and a cupcake.',
'Please wait, sit back, and relax with your thoughts for a moment.',
'Please wait, remember traffic jams? This will be a breeze in comparison.',
'Please wait, hang on a spiffy, we’re working it out.',
'Please wait, pick a number between 1 and 100, and by then we’ll be ready.          (🔹→  @J_F_Grady) ',
'Please wait, think of all the things I’d say right now if I was a human, umm, uhh, mmm, like, see...right?         (🔹→  @J_F_Grady)',
'Please wait, I’m not peeking, you are!        (🔹→  @J_F_Grady)',
'Please wait, lost years are worse than lost dollars.     (🔹 Yiddish proverb  →  @jeanneachille) ',
'Please wait, experience is what we call the accumulation of our mistakes.     (🔹 Yiddish proverb  →  @jeanneachille)',
'Please wait, if you want your dreams to come true, don’t sleep. (🔹 Yiddish proverb  →  @jeanneachille)',
'Please wait, don’t bite the foot that stomps your grape. (🔹 → @JohnSumser) ',
'Please wait, if you are not part of the solution, you must be a precipitate.  (🔹 → @JohnSumser)',
'Please wait, the 1s and 0s are doing their thing. (🔹 → @JohnSumser)',
'Please wait, put “call your ...” on your to do list because they probably miss you. (🔹 → @JohnSumser)',
'Please wait, you look amazing today! (🔹 → @JohnSumser)',
'Please wait, words should be weighed, not counted. (🔹 old Yiddish saying)',
'Please wait, troubles are to man what rust is to iron. (🔹 old Yiddish saying)',
'Please wait, a person is sometimes stronger than iron and sometimes weaker than a fly. (🔹 old Yiddish saying)',
'Please wait, don’t send a dog to the butcher shop. (🔹 old Yiddish saying)',
'Please wait, don’t stick your head in a wolf’s mouth. (🔹 old Yiddish saying)',
'Please wait, what will become of the sheep if the wolf is the judge? (🔹 old Yiddish saying)',
'Please wait, you can’t empty the ocean with a spoon. (🔹 old Yiddish saying)',
'Please wait, with horses you check the teeth; with a human you check the brains. (🔹 old Yiddish saying)',
'Please wait, contemplate the rotation of the earth.',
'Please wait, count to 10 slowly. ',
'Please wait, empty barrels make the most noise. (🔹 old Yiddish saying)',
'Please wait, still water runs deep. (🔹 old Yiddish saying)',
'Please wait, when you have a lot to do, go to sleep. (🔹 old Yiddish saying)',
'Please wait, time is the best doctor. (🔹 old Yiddish saying)',
'Please wait, even the most expensive clock has no more than sixty minutes. (🔹old Yiddish saying)',
'Please wait, how does a cat cross over the water? (🔹 old Yiddish saying)',
'Please wait, we’re looking for a pin in a hay wagon. (🔹old Yiddish saying)',
'Please wait, go look for the wind in the field. (🔹old Yiddish saying)',
'Please wait, if you stay at home, you won’t wear out your boots. (🔹 old Yiddish saying)',
'Please wait, work is no shame. (🔹 old Yiddish saying)',
'Please wait, when a bear is at your heals, do not look for his footprints. (🔹 old Greek saying)',
'Please wait, if the heart be stout, a mouse can life an elephant. (🔹 old Tibetan saying)',
'Please wait, smile and be entangled (🔹 old Hindu saying)',
'Please wait, ye who rises early will gather wisdom. (🔹 old Danish saying)',
'Please wait, without health no one is rich. (🔹 old Croatian saying)',
'Please wait, don’t bolt your door with a boiled carrot. (🔹 old Irish saying)',
'Please wait, on a slimy shore it is easy to push a canoe. (🔹 old Cameroonian saying)',
'Please wait, not every opinion is truth. (🔹 old Czech saying)',
'Please wait, not every dog runs away from their tent. (🔹 old Moorish saying)',
'Please wait, he who talks much is sometimes right. (🔹 old Spanish saying)',
'Please wait, not every day is market day. (🔹 old Swiss saying)',
'Please wait, however big an eye may be, two are better. (🔹 old Nigerian saying)',
'Please wait, if heaven drops a date you must open your mouth. (🔹 old Chinese saying)',
'Please wait, it’s not a fish until it’s on the bank. (🔹 old Irish saying)',
'Please wait, big stones are held up by little ones. (🔹 old Kurdish saying)',
'Please wait, we do not carry the kings load and perspire. (🔹 old Nigerian saying)',
'Please wait, he who shares the meat is always left with the bone. (🔹 old Leplander saying)',
'Please wait, he who has too much butter greases his boots. (🔹 old Bulgarian saying)',
'Please wait, quit accepting what feels unchangeable, go make it happen.',
'Please wait, after eating nine hundred rats, the cat is now going on a pilgrimage (🔹 old Indian saying)',
'Please wait, when you buy shoes, measure your feet. (🔹 old Chinese saying)',
'Please wait, every path hath a puddle. (🔹 old English saying)',
'Please wait, the dog follows the one who has the bone. (🔹 old Irish saying) ',
'Please wait, ye who spits into an ants’ nest gets swollen lips. (🔹 old Silesian saying) ',
'Please wait, look to the end. (🔹 old Roman saying)',
'Please wait, if you lose your needle in the grass, look for it in the grass. (🔹 old Chinese saying)',
'Please wait, don’t let the place get cold. (🔹 old Chinese saying)',
'Please wait, you can’t swallow dates whole. (🔹 old Chinese saying)',
'Please wait, a buckle is a great addition to an old shoe. (🔹 old Irish saying)',
'Please wait, eat little and often. (🔹 old Kurdish saying)',
'Please wait, if the cow drinks the ox will too. (🔹 old Slovakian saying)',
'Please wait, with money one can even buy rabbit cheese. (🔹 old Romanian saying)',
'Please wait, do not eat bread which is still growing. (🔹 old Finnish saying)',
'Please wait, an ass can swim in water seven ways, but when he sees water he forgets them all. (🔹 old American saying)',
'Please wait, when fools come in the village is rich. (🔹 old Danish saying)',
'Please wait, every dog barks differently. (🔹 old Slovakian saying)',
'Please wait, do not shout dinner until you have a knife In the loaf. (🔹 old Canadian saying)',
'Please wait, the beginning and the end reach out their hands to each other. (🔹 old Chinese saying)',
'Please wait, a worn-out boat still has three thousand nails in it. (🔹 old Chinese saying)',
'Please wait, never put your fingers between the tree and the bark. (🔹 old French saying)',
'Please wait, do not measure the tail of a live wolf. (🔹 old Bulgarian saying)',
'Please wait, if you want milk feed the goat. (🔹 old Jamaican saying)',
'Please wait, if you’ve not got a smile on your face, no use to open shop. (🔹 old West Indian saying)',
'Please wait, one does not play with wool and fire at the same time. (🔹 old Turkish saying)',
'Please wait, seek shelter under a big tree. (🔹 old Japanese saying)',
'Please wait, laugh if you are wise. (🔹 old Roman saying)',
'Please wait, the empty nut is the hardest. (🔹 old Welsh saying)',
'Please wait, the hand will not reach for what the heart does not long for. (🔹 old Welsh saying)',
'Please wait, thunder will not strike one when eating rice. (🔹 old Chinese saying)',
'Please wait, steel is softer than gold. (🔹 old Latvian saying)',
'Please wait, do not salt other people’s food. (🔹 old Bulgarian saying)',
'Please wait, he who marries the wolf often looks towards the forest. (🔹 old Basque saying)',
'Please wait, a bold heart is half the battle. (🔹 old Roman saying)',
'Please wait, with patience and saliva the elephant swallows an ant. (🔹 old Colombian saying)',
'Please wait, there is no bird that cannot wear its own feathers. (🔹 old Estonian saying)',
'Please wait, ye who goes barefoot cannot do others harm. (🔹 old Polish saying)',
'Please wait, one finger can’t catch fleas. (🔹 old Creole saying)',
'Please wait, when the arm bends the mouth opens. (🔹 old Danish saying)',
'Please wait, in frosty weather, a nail is worth a horse. (🔹 old Spanish saying)',
'Please wait, ride and ox before you ride a horse. (🔹 old Japanese saying)',
'Please wait, kind words fatten the bones. (🔹 old Arabic saying)',
'Please wait, he who has taken the bear into the boat must cross over with him. (🔹 old Swedish saying)',
'Please wait, it takes a heap of licks to drive a nail into the dark. (🔹 old Livonian saying)',
'Please wait, a pigs tail will never make a good arrow. (🔹 old Spanish saying)',
'Please wait, the slower one rides the farther one goes. (🔹 old Livonian saying)',
'Please wait, do not shake chestnuts when there are children under the tree. (🔹 old Breton saying)',
'Please wait, he who cuts some wood gets warm over it. (🔹 old Moorish saying)',
'Please wait, it is better to be in an old carriage than a new ship. (🔹 old Danish saying)',
'Please wait, when one has raw meat they looks for fire. (🔹 old Jamaican saying)',
'Please wait, every person must skin their own skunk. (🔹 old Moorish saying)',
'Please wait, the key is mightier than the lock. (🔹 old Russian saying)',
'Please wait, when one is in the mill one gets dusty. (🔹 old Finnish saying)',
'Please wait, he who lies on the ground has no place to fall. (🔹 old Roman saying)',
'Please wait, there are many roads to Rome. (🔹 old Italian saying)',
'Please wait, who can plaster over the rays of the sun? (🔹 old Malayan saying)',
'Please wait, the scorpion stings the one who gets them out of the fire. (🔹 old Tamil saying)',
'Please wait, look at the light not at the lantern. (🔹 old English saying)',
'Please wait, consult even with your own knee. (🔹 old Japanese saying)',
'Please wait, at the bottom of the sack is found the worst corn. (🔹 old French saying)',
'Please wait, an elephant does not forget its resting place. (🔹 old Mozambican saying)',
'Please wait, the beetle is a beauty in the eyes of its mother. (🔹 old Egyptian saying)',
'Please wait, flattery is sweet food for those who can swallow it. (🔹 old Danish saying)',
'Please wait, there is always water in an old river. (🔹 old Japanese saying)',
'Please wait, the more eggs, the thicker the soup. (🔹 old Serbian saying)',
'Please wait, they ate our food, and forgot our names. (🔹 old Tunisian saying)',
'Please wait, every cabin has its mosquito. (🔹 old West Indian saying)',
'Please wait, life’s too short not to grab the brass ring. (🔹 old American saying)',
'Please wait, the crooked log makes a good fire. (🔹 old French saying)',
'Please wait, on one’s own bench, a person may sing. (🔹 old Malayan saying)',
'Please wait, if you want to keep your milk sweet, leave it in the cow. (🔹 old West African saying)',
'Please wait, don’t pump if the sucker is dry. (🔹 old Jamaican saying)',
'Please wait, a village in sight does not require a guide. (🔹 old Turkish saying)',
'Please wait, I gave an order to the cat, and the cat gave it to its tail. (🔹 old Chinese saying)',
'Please wait, one grows used to love and to fire. (🔹 old French saying)',
'Please wait, a good prophet is one who guesses best. (🔹 old Roman saying)',
'Please wait, in darkness all things are black. (🔹 old Slovenian saying)',
'Please wait, no grass grows on the battlefield. (🔹 old Polish saying)',
'Please wait, hot soup burns the mouth. (🔹 old Livonian saying)',
'Please wait, a bear is an unsafe bedmate. (🔹 old Indian saying)',
'Please wait, the horse that arrives early gets good drinking water. (🔹 old Zulu saying)',
'Please wait, one can think of life after the fish is in the canoe. (🔹 old Hawaiian saying)',
'Please wait, warm an object for ten days and it will cool in a day. (🔹 old Japanese saying)',
'Please wait, every cook makes their sauce. (🔹 old French saying)',
'Please wait, a good fire makes a quick cook. (🔹 old Dutch saying)',
'Please wait, keep your broken arm inside your sleeve. (🔹 old Chinese saying)',
'Please wait, a bird with sharp claws never gathers a crowd. (🔹 old Bantu saying)',
'Please wait, a living tortoise is not worn as a charm. (🔹 old Zambian saying)',
'Please wait, a misshapen gourd makes an ugly container. (🔹 old Hawaiian saying)',
'Please wait, you cannot have harmony without noise. (🔹 old Albanian saying)',
'Please wait, the lion roars in the bush. (🔹 old Mozambican saying)',
'Please wait, journey beneath the wing of the white hawk. (🔹 old Maori saying)',
'Please wait, don’t love wind more than water. (🔹 old Siamese saying)',
'Please wait, he who washes off the dirt washes off the luck.  (🔹 old Hawaiian saying)',
'Please wait, a full cabin is better than an empty castle.  (🔹 old Irish saying)',
'Please wait, as long as there are heads there will be hats.  (🔹 old Bulgarian saying)',
'Please wait, do not smoke in a powder keg. (🔹 old Russian saying)',
'Please wait, all the wealth of the world is in the weather. (🔹 old Scottish saying)',
'Please wait, to carry a light when the moon shines makes the tigers laugh. (🔹 old Malayan saying)',
'Please wait, the arrow that has left the bow never returns. (🔹 old Persian saying)',
'Please wait, there is much lost between the hand and the mouth. (🔹 old Manx saying)',
'Please wait, the licker of honey licks more than once. (🔹 old Zanzibari saying)',
'Please wait, there is no use boiling your cabbage twice. (🔹 old Irish saying)',
'Please wait, everything has two handles. (🔹 old Greek saying)',
'Please wait, love makes a good eye squint. (🔹 old English saying)',
'Please wait, the who lies on the floor never falls down. (🔹 old Norwegian saying)',
'Please wait, the more one walks the more hills one sees. (🔹 old Finnish saying)',
'Please wait, a puddle will not grow dirty. (🔹 old Welsh saying)',
'Please wait, a grasshopper does not always come when one breaks a string. (🔹 old Greek saying)',
'Please wait, one hen that bursts makes the whole pen stink. (🔹 old Thai saying)',
'Please wait, don’t cry "Hello!" till your out of the bush. (🔹 old Dutch saying)',
'Please wait, there is no stronger drink than water. (🔹 old Montenegrin saying)',
'Please wait, make your feet your friend. (🔹 old Scottish saying)',
'Please wait, the best incense comes from old trees. (🔹 old Silesian saying)',
'Please wait, the fish caught in the net starts to think. (🔹 old Zanzibari saying)',
'Please wait, hunters never share the same forest (🔹 old Sechuana saying)',
'Please wait, an old buck has stiff horns. (🔹 old Latvian saying)',
'Please wait, a mouse is not afraid of haystacks. (🔹 old Russian saying)',
'Please wait, cobblers do not judge above the shoe. (🔹 old Roman saying)',
'Please wait, those who hurry much remain on the road. (🔹 old Montenegrin saying)',
'Please wait, ye who builds according to everyone’s advise will have a crooked home. (🔹 old Dutch saying)',
'Please wait, when three women join together the stars come out in broad daylight. (🔹 old Indian saying)',
'Please wait, if nothing touches the palm leaves they do not rustle. (🔹 old Ghanaian saying)',
'Please wait, beginning and ending shake hands. (🔹 old German saying)',
'Please wait, when there is mud on the road oil the cart. (🔹 old Spanish saying)',
'Please wait, words  don’t season soup. (🔹 old Brazilian saying)',
'Please wait, when I’ve had enough I will lend you my spoon. (🔹 old Czech saying)',
'Please wait, the eye does not rise above the eyebrow. (🔹 old English saying)',
'Please wait, you can’t weave rope from sand. (🔹 old Russian saying)',
'Please wait, folded hands gain no bread. (🔹 old Latvian saying)',
'Please wait, in calm water every ship has a good captain. (🔹 old Swiss saying)',
'Please wait, if the sun shines it will be warm. (🔹 old Kenyan saying)',
'Please wait, when you know there are tigers on the hills don’t go there. (🔹 old Chinese saying)',
'Please wait, a lizard cannot stretch itself into a snake. (🔹 old Arabic saying)',
'Please wait, it is bad to lean against a falling wall. (🔹 old Danish saying)',
'Please wait, a frog beneath the coconut shell believes there is no other world. (🔹 old Malayan saying)',
'Please wait, while the pot boils the friendship lasts. (🔹 old Roman saying)',
'Please wait, one may tire of eating tarts. (🔹 old French saying)',
'Please wait, if one doesn’t have citron one doesn’t need a citron box. (🔹 old Yiddish saying)',
'Please wait, it is best to drive home in a whole wagon. (🔹 old Icelandic saying)',
'Please wait, do service and eat fruit. (🔹 old Hindu saying)',
'Please wait, ye that is afraid to shake the dice will never throw a six. (🔹 old Chinese saying)',
'Please wait, don’t tell your secrets behind a wall or a hedge. (🔹 old Spanish saying)',
'Please wait, you can’t carry what you can’t lift. (🔹 old Latvian saying)',
'Please wait, ye who has a spoon often finds the soup. (🔹 old Jamaican saying)',
'Please wait, it is no use trying to tug a glacier backwards. (🔹 old Tibetan saying)',
'Please wait, no sleep, no dream. (🔹 old Ghanaian saying)',
'Please wait, doors are not opened without keys. (🔹 old Maltese saying)',
'Please wait, don’t wait till the drum beats to grind your ax. (🔹 old Jamaican saying)',
'Please wait, a little wood will heat a little oven. (🔹 old English saying)',
'Please wait, handsome apples are sometimes sour. (🔹 old Dutch saying)',
'Please wait, there is always straw left when the road turns over. (🔹 old Finnish saying)',
'Please wait, the elbow is nearer than the wrist. (🔹 old Welsh saying)',
'Please wait, if the sky falls there will be pots broken. (🔹 old Spanish saying)',
'Please wait, sticks in a bundle are unbreakable. (🔹 old Kenyan saying)',
'Please wait, ye who treads on eggs must tread lightly (🔹 old German saying)',
'Please wait, an empty river does not roar. (🔹 old Togolese saying)',
'Please wait, light your lamp before it becomes dark. (🔹 old Arabic saying)',
'Please wait, the tongue has no bones. (🔹 old Mongolian saying)',
'Please wait, all are not hunters who blow the horn. (🔹 old French saying)',
'Please wait, water is never tires of flowing. (🔹 old Bantu saying)',
'Please wait, it’s always dark around the candle. (🔹 old Afgan saying)',
'Please wait, one cannot breath through another’s nose. (🔹 old Thai saying)',
'Please wait, when the ground is hot the worm stays in the dirt. (🔹 old Surinamese saying)',
'Please wait, wide is the door of the cottage. (🔹 old Irish saying)',
'Please wait, ye who goes down two roads at the same time splits their hip joint. (🔹 old Bantu saying)',
'Please wait, never pet a bear unless it’s a rug. (🔹 old Eskimoan saying)',
'Please wait, bad grass doesn’t make good hay. (🔹 old Italian saying)',
'Please wait, no one ever kept looking for a sleeping place till dawn. (🔹 old Asbanti saying)',
'Please wait, from the roof of a house a melon may roll one of two ways. (🔹 old Chinese saying)',
'Please wait, it won’t do to trifle with fire. (🔹 old French saying)',
'Please wait, if you wish to know a person give them authority. (🔹 old Bulgarian saying)',
'Please wait, there is often a wise head under a tattered hat. (🔹 old Slovakian saying)',
'Please wait, in a fight you forget about your hair. (🔹 old Russian saying)',
'Please wait, the noise of the kettledrum goes far. (🔹 old Syrian saying)',
'Please wait, if one wants to be acquainted with the past, one must read five cartloads of books. (🔹 old Chinese saying)',
'Please wait, the fish that is golden shall swim in the sun. (🔹 old Japanese saying)',
'Please wait, a silly song may be sung in many ways. (🔹 old Danish saying)',
'Please wait, ye who lives longest has the most old clothes. (🔹 old Zulu saying)',
'Please wait, do not catch everything that swims. (🔹 old Russian saying)',
'Please wait, it’s no use fishing in front of the net. (🔹 old English saying)',
'Please wait, many grains of sand will sink a ship. (🔹 old Danish saying)',
'Please wait, time builds a castle and demolishes it. (🔹 old Montenegrin saying)',
'Please wait, ye who draws near a good tree enjoys both its fruit and its shade. (🔹 old Catalonian saying)',
'Please wait, beware of ye who makes you presents. (🔹 old Italian saying)',
'Please wait, a crooked path is hard to find. (🔹 old Jamaican saying)',
'Please wait, ye who keeps standing is still in the same place. (🔹 old Arabic saying)',
'Please wait, any water puts out fire. (🔹 old French saying)',
'Please wait, the people go but the hills remain. (🔹 old Irish saying)',
'Please wait, strong legs are needed to carry good days. (🔹 old Icelandic saying)',
'Please wait, in the fiddler’s house everyone is a dancer. (🔹 old Spanish saying)',
'Please wait, they that come with a gift do not need to stand long at the door. (🔹 old Scottish saying)',
'Please wait, mountains do not meet. (🔹 old Masai saying)',
'Please wait, the other side of the road always looks cleanest. (🔹 old English saying)',
'Please wait, ye who goes to sea without biscuits returns without teeth. (🔹 old Corsican saying)',
'Please wait, gifts should be handed not hurled. (🔹 old Danish saying)',
'Please wait, ye who goes to bed hungry dreams of pancakes. (🔹 old Maltese saying)',
'Please wait, birds love figs but they will not plant them. (🔹 old Greek saying)',
'Please wait, thieves never steal bells. (🔹 old Tibetan saying)',
'Please wait, to pole down the stream makes the crocodiles laugh. (🔹 old Malayan saying)',
'Please wait, every village has a road to the mill. (🔹 old Arabic saying)',
'Please wait, old words are wise words. (🔹 old Basque saying)',
'Please wait, there is no hill without a slope. (🔹 old Welsh saying) ',
'Please wait, ye who waits for the moon waits for darkness. (🔹 old Bantu saying)',
'Please wait, do what you are doing. (🔹 old Roman saying)',
'Please wait, the more you k now the more luck you have. (🔹 old Burmese saying)',
'Please wait, numerous calls confuse the dog. (🔹 old Tanganyikan saying)',
'Please wait, the back and the belly can’t change places. (🔹 old Japanese saying)',
'Please wait, the sweetest grapes hang highest. (🔹 old German saying)',
'Please wait, a ripe crop must not wait for tomorrow. (🔹 old Roman saying)',
'Please wait, Lightening doesn’t strike the nettle. (🔹 old Hungarian saying)',
'Please wait, anyone can laugh on a hillside. (🔹 old Irish saying)',
'Please wait, open lips make cold teeth. (🔹 old Japanese saying)',
'Please wait, riches are not the only wealth. (🔹 old Icelandic saying)',
'Please wait, a short rest is always good. (🔹 old Danish saying)',
'Please wait, don’t love the moon more than the sun. (🔹 old Siamese saying)',
'Please wait, are there no spots on the sun? (🔹 old French saying)',
'Please wait, if you return an ass’s kicks, most of the pain is yours. (🔹 old Sardinian saying)',
'Please wait, it’s possible to talk to cattle if you have common sense. (🔹 old Swiss saying)',
'Please wait, time is longer than a rope. (🔹 old Jamaican saying)',
'Please wait, there is no need to fear the wind if your haystacks are tied down. (🔹 old Irish saying)',
'Please wait, where there is no wind, bushes do not shake. (🔹 old Afgan saying)',
'Please wait, practices the art of giving up. (🔹 old Jamaican saying)',
'Please wait, wait until the tree has fallen to hop it. (🔹 old Siamese saying)',
'Please wait, an old fig tree delights the neighbors. (🔹 old Greek saying)',
'Please wait, after a time even the dog makes a compromise with the cat. (🔹 old Hungarian saying)',
'Please wait, cake is dough. (🔹 old English saying)',
'Please wait, the nose doesn’t know the flavor of salt. (🔹 old Nigerian saying)',
'Please wait, a hearty stomach can digest a chisel. (🔹 old Russian saying)',
'Please wait, the cat the eats the candle must sit in the dark. (🔹 old Slovakian saying)',
'Please wait, every mile is two in winter. (🔹 old English saying)',

  ];

  const getMessage = () => {
    const Luckynumber = Math.floor(Math.random() * Math.floor(352));
    return Messages[Luckynumber];
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '100px',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor:'white',
      }}
    >
      {isDone ? (
        <>
          <svg className="checkmark" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </>
      ) : (
        <>
          <div className="sweet-loading">
            <ScaleLoader
              height="45px"
              margin="3px"
              width="5px"
              color={color}
              loading={isLoading}
            />
          </div>
          
          <div style={{marginRight:'30px', marginLeft:'30px'}}>
          {showMessages ? getMessage() : null}
          </div>
        </>
      )}
    </div>
  );
};

export default Spinner;
