/* eslint-disable */
import axios from 'axios';
import Qs from 'qs';
import Cookies from 'js-cookie';
import ApiError from './ApiError';
import { getIdToken } from './firebase';
import Global from './global';

// 9-6-2020
// Temp created this client to call new Cloud Run functions. 
// Eventually we will use http client and just change base url to point to cloud run.

const { REACT_APP_API_CLOUDRUN_URL } = process.env;


// const client = axios.create({ baseURL: REACT_APP_API_CLOUDRUN_URL });
const client = axios.create();
// const userURL = Cookies.get('graphserver') !== undefined ? Cookies.get('graphserver') : REACT_APP_API_CLOUDRUN_URL
const userURL =REACT_APP_API_CLOUDRUN_URL

/**
 * The default url param serializer. For all those GET methods with filters.
 * @param params
 * @returns {*|string}
 */
axios.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' });


client.interceptors.request.use(
  async (config) => {
    // console.log(Global.zoneid);
    const token = await getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.zoneid = Global.zoneid;
    config.url = `${userURL}${config.url}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { response, message } = error;
    const apiError = new ApiError(response, message);
    return Promise.reject(apiError);
  },
);

export default client;
