/* eslint-disable */
import React, { useCallback, useEffect,useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from '@reach/router';
import { AccountContext } from '../../providers/AccountProvider';
import { useNavigate } from '@reach/router';
import { mdiMagnify, mdiEarHearing, mdiMessageOutline} from '@mdi/js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Label } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import Router from '../../components/Router';
// import Zendesk from "react-zendesk";
import BlocksListPage from '../BlocksList';
import Page from '../../components/Page';
import SideBar from '../../components/SideBar';
import Content from '../../components/Content';
import PlanButton from '../../components/PlanButton';
import Button from '../../components/Button';
import Icon, {
  SettingsIcon,
  ProfileIcon,
  BellIcon,
  PowerIcon,
  HelpIcon
} from '../../components/Icon';
// import useToggle from './hooks';
import {updateUserSystemAttributesAPI} from '../../api/users'
import {getNotificationCount} from '../../api/notifications';
import ZoneDndList from './ZoneDndList';
import ZoneAdd from './ZoneAdd';
import { ZONE_DASHBOARD, REDIRECTION, DASHBOARD } from '../../constants/routes';
import UserProfile from '../User/UserProfile';
import ZoneSettings from '../Zone/ZoneSettings';
import ShowNotifications from '../Block/components/notifications/showNotifications';
import ImpersonateUser from '../User/ImpersonateUser';
import Global from '../../clients/global'


// const { Title } = Content.Header;
const MagnifyIcon = (props) => <Icon {...props} path={mdiMagnify} />;
const HearingIcon = (props) => <Icon {...props} path={mdiEarHearing} />;
// const MessageIcon = (props) => <Icon {...props} path={mdiMessageOutline} />;

const placement = 'right';

const Dashboard = ({ zones, signOut, onChangeZone, onAddZone, onlyView }) => {
  const {userInfo,userTokenResult,switchToZone } = useContext(AccountContext);
  const isCollapsed = userInfo.systemattributes.DashboardCollapse
  const [visible, toggleDrawer] = useState(!isCollapsed);
  const [userProfile, setUserProfile]= useState(false);
  const [showZoneSettings, setShowZoneSettings] = useState(false);
  const [showNotifications,setShowNotifications] =useState(false);
  const [showPlanInformation,setShowPlanInformation] = useState(false);
  const [selectedZoneId,setSelectedZoneid] = useState(0);
  const [userZoneInfo, setUserZoneInfo] = useState('');
  const [notificationCount,setNotificationCount]=useState(0);
  const [showImpersonate,setShowImpersonate] =useState(false);

  // const ZENDESK_KEY='0e2a6639-e3bf-4c6b-91cb-3f2bc7863479';
  const { REACT_APP_PUBLIC_ZONE_ID } = process.env;
  
  //We remove the public zones zone for all zone-users. Owners/builders will still have it appear.
  let myZones= zones;
  let publicZone=null;
  let publicZoneRole='Zone user';
  const idx = zones.findIndex(el=>el.id === parseInt(REACT_APP_PUBLIC_ZONE_ID))
  if(idx !==-1) {
    publicZone=zones[idx];

    publicZoneRole = zones[idx].role;
    if(publicZoneRole==='Zone user') {
      myZones = zones.filter(el=>el.id !== parseInt(REACT_APP_PUBLIC_ZONE_ID) );
    }

  }

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(userInfo)

    //5-23-2022 log out anonymous user. 
    if(userInfo.email.toLowerCase().includes('anonymous') || userTokenResult===null) {
      signOut();
      navigate('/auth/sign-in');
      return;
    }

    setUserZoneInfo(userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0])
    getZoneNotificationCount();

  },[Global.zoneid,showNotifications])

  const getZoneNotificationCount= async() => {
    const result = await getNotificationCount(null);
    setNotificationCount(result.count);
  }

  const toggleSideBar = async () => {
    userInfo.systemattributes.DashboardCollapse = visible
    await updateUserSystemAttributesAPI(userInfo.id,userInfo.systemattributes)
    toggleDrawer(!visible)
  }

  const showPublicZone =()=> {
    switchToZone(publicZone.id).catch(() => {
      navigate(`${DASHBOARD.ZONE_PREFIX}${Global.zoneid}`, { replace: true });
    });
    navigate(`${DASHBOARD.ZONE_PREFIX}${publicZone.id}`, { replace: true });
  }


  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const toggleUserProfile =() => {
    setUserProfile(!userProfile)
    if(visible){
    toggleDrawer(!visible)
    }
    if(userProfile){
      toggleDrawer(!visible)
    }
  }

  const toggleNotifications= () => {
    setShowNotifications(!showNotifications);
  }

  const toggleZoneSettings =(zoneid) => {
    setShowZoneSettings(!showZoneSettings)
    setShowPlanInformation(false)
    toggleDrawer(!visible)
  }

  const toggleImpersonate =(reload=false) =>{
    setShowImpersonate(!showImpersonate)
    if(reload){
      location.reload()
    }
  }

  const showUpgradePlans = () => {
    toggleDrawer(!visible)
    setShowPlanInformation(true)
    setShowZoneSettings(true)
  }

  useEffect(() => {
    const favico = document.getElementById('favicon');
   const path='PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4NCiAgPGcgaWQ9IkZhdmljb25fd2l0aF9iYWNrZ3JvdW5kXzIiIGRhdGEtbmFtZT0iRmF2aWNvbiB3aXRoIGJhY2tncm91bmQgMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0LjUgLTE1NCkiPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTQ4NSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTQ4NSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0NC41IDE1NCkiIGZpbGw9Im5vbmUiLz4NCiAgICA8ZyBpZD0iRmF2aWNvbl9Mb2dvIiBkYXRhLW5hbWU9IkZhdmljb24gTG9nbyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDYgMTU0KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMzI1NiIgZGF0YS1uYW1lPSJQYXRoIDEzMjU2IiBkPSJNNzYuMjE1LDE2My42NzJ2MTMuMTE2YS4zMTMuMzEzLDAsMCwxLS4zMTIuMzEySDU3LjM5MmEuMzExLjMxMSwwLDAsMC0uMzEyLjMxMnYzLjkzMmEuMzEyLjMxMiwwLDAsMCwuMzEyLjMxMmgyMy4zYS4zMTIuMzEyLDAsMCwwLC4zMTItLjMxMlYxNjMuNjcyYS4zMTIuMzEyLDAsMCwwLS4zMTItLjMxMkg3Ni41MjdhLjMxMi4zMTIsMCwwLDAtLjMxMi4zMTJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTIuMjkyIC0xNDkuNjU2KSIgZmlsbD0iI2VlMjc2MSIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMTMyNTciIGRhdGEtbmFtZT0iUGF0aCAxMzI1NyIgZD0iTTEyMy4zOTQsNTkuMDk0aC04LjkzMWEuMzE0LjMxNCwwLDAsMC0uMzE0LjMxNFY3Mi40ODZhLjMxMy4zMTMsMCwwLDAsLjMxNC4zMTRoOC45MzFhLjMxMy4zMTMsMCwwLDAsLjMxMy0uMzE0di0zLjkzYS4zMTMuMzEzLDAsMCwwLS4zMTMtLjMxNGgtNC4xNjFhLjMxNS4zMTUsMCwwLDEtLjMxNC0uMzE0VjYzLjk2NGEuMzE0LjMxNCwwLDAsMSwuMzE0LS4zMTRoOC45NDhhLjMxMy4zMTMsMCwwLDAsLjMxNC0uMzE0di04LjVhLjMxNC4zMTQsMCwwLDAtLjMxNC0uMzE0aC00LjE2MWEuMzE1LjMxNSwwLDAsMC0uMzE0LjMxNHYzLjk0N0EuMzE0LjMxNCwwLDAsMSwxMjMuMzk0LDU5LjA5NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDQuNTc0IC00OS45NDYpIiBmaWxsPSIjMTIwMjMzIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMzI1OCIgZGF0YS1uYW1lPSJQYXRoIDEzMjU4IiBkPSJNMTkuMTMzLDQuMjY1Vi4zMDlBLjMwOS4zMDksMCwwLDAsMTguODI1LDBILjMwOUEuMzA5LjMwOSwwLDAsMCwwLC4zMDlWMjcuMTM1YS4zMDkuMzA5LDAsMCwwLC4zMDkuMzA5SDQuNDhhLjMwOS4zMDksMCwwLDAsLjMwOS0uMzA5VjQuODgzYS4zMDkuMzA5LDAsMCwxLC4zMDktLjMxSDE4LjgyNWEuMzA4LjMwOCwwLDAsMCwuMzA5LS4zMDlaIiBmaWxsPSIjYWMyMDdkIi8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==';
    // const path='M8.5,5A1.5,1.5 0 0,0 7,6.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 10,6.5A1.5,1.5 0 0,0 8.5,5M10,2A5,5 0 0,1 15,7C15,8.7 14.15,10.2 12.86,11.1C14.44,11.25 16.22,11.61 18,12.5C21,14 22,12 22,12C22,12 21,21 15,21H9C9,21 4,21 4,16C4,13 7,12 6,10C2,10 2,6.5 2,6.5C3,7 4.24,7 5,6.65C5.19,4.05 7.36,2 10,2Z'
    const svgpath = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4NCiAgPGcgaWQ9IkZhdmljb25fd2l0aF9iYWNrZ3JvdW5kXzIiIGRhdGEtbmFtZT0iRmF2aWNvbiB3aXRoIGJhY2tncm91bmQgMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0LjUgLTE1NCkiPg0KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTQ4NSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTQ4NSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0NC41IDE1NCkiIGZpbGw9Im5vbmUiLz4NCiAgICA8ZyBpZD0iRmF2aWNvbl9Mb2dvIiBkYXRhLW5hbWU9IkZhdmljb24gTG9nbyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDYgMTU0KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMzI1NiIgZGF0YS1uYW1lPSJQYXRoIDEzMjU2IiBkPSJNNzYuMjE1LDE2My42NzJ2MTMuMTE2YS4zMTMuMzEzLDAsMCwxLS4zMTIuMzEySDU3LjM5MmEuMzExLjMxMSwwLDAsMC0uMzEyLjMxMnYzLjkzMmEuMzEyLjMxMiwwLDAsMCwuMzEyLjMxMmgyMy4zYS4zMTIuMzEyLDAsMCwwLC4zMTItLjMxMlYxNjMuNjcyYS4zMTIuMzEyLDAsMCwwLS4zMTItLjMxMkg3Ni41MjdhLjMxMi4zMTIsMCwwLDAtLjMxMi4zMTJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTIuMjkyIC0xNDkuNjU2KSIgZmlsbD0iI2VlMjc2MSIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfMTMyNTciIGRhdGEtbmFtZT0iUGF0aCAxMzI1NyIgZD0iTTEyMy4zOTQsNTkuMDk0aC04LjkzMWEuMzE0LjMxNCwwLDAsMC0uMzE0LjMxNFY3Mi40ODZhLjMxMy4zMTMsMCwwLDAsLjMxNC4zMTRoOC45MzFhLjMxMy4zMTMsMCwwLDAsLjMxMy0uMzE0di0zLjkzYS4zMTMuMzEzLDAsMCwwLS4zMTMtLjMxNGgtNC4xNjFhLjMxNS4zMTUsMCwwLDEtLjMxNC0uMzE0VjYzLjk2NGEuMzE0LjMxNCwwLDAsMSwuMzE0LS4zMTRoOC45NDhhLjMxMy4zMTMsMCwwLDAsLjMxNC0uMzE0di04LjVhLjMxNC4zMTQsMCwwLDAtLjMxNC0uMzE0aC00LjE2MWEuMzE1LjMxNSwwLDAsMC0uMzE0LjMxNHYzLjk0N0EuMzE0LjMxNCwwLDAsMSwxMjMuMzk0LDU5LjA5NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDQuNTc0IC00OS45NDYpIiBmaWxsPSIjMTIwMjMzIi8+DQogICAgICA8cGF0aCBpZD0iUGF0aF8xMzI1OCIgZGF0YS1uYW1lPSJQYXRoIDEzMjU4IiBkPSJNMTkuMTMzLDQuMjY1Vi4zMDlBLjMwOS4zMDksMCwwLDAsMTguODI1LDBILjMwOUEuMzA5LjMwOSwwLDAsMCwwLC4zMDlWMjcuMTM1YS4zMDkuMzA5LDAsMCwwLC4zMDkuMzA5SDQuNDhhLjMwOS4zMDksMCwwLDAsLjMwOS0uMzA5VjQuODgzYS4zMDkuMzA5LDAsMCwxLC4zMDktLjMxSDE4LjgyNWEuMzA4LjMwOCwwLDAsMCwuMzA5LS4zMDlaIiBmaWxsPSIjYWMyMDdkIi8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==`;
    //  const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:#eeeeee'  viewBox='0 0 24 24'%3E%3Cpath d='${path}'/%3E%3C/svg%3E`;
    favico.href = svgpath;
  }, []);

  const setting = {
    color: {
      theme: "#AE007B"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    offset: { vertical: '-10px',horizontal:"-10px" },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "How can we help" } }
      ]
    }
  };

  return (
    <Page>
      {userProfile ? (<UserProfile color="#AE007B" toggleUserProfile={toggleUserProfile} /> ) : null}
      {showZoneSettings ? (<ZoneSettings color="#AE007B" showPlans={showPlanInformation} toggleZoneSettings={toggleZoneSettings} zoneid={selectedZoneId} /> ) : null}
      {showNotifications ? (<ShowNotifications color="#AE007B" toggleNotifications={toggleNotifications}  /> ) : null}
      {showImpersonate ? (<ImpersonateUser color="#AE007B" toggleImpersonate={toggleImpersonate}  /> ) : null}
      <Helmet>
        <title>GraceBlocks</title>
      </Helmet>
      
      {/* {userZoneInfo !==undefined && (userZoneInfo.role==='Zone owner' || userZoneInfo.role==='Zone builder') ? (
      <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />
      ): null} */}

      <SideBar visible={visible} toggleDrawer={toggleSideBar}>
        <SideBar.Header>
          <OverlayTrigger placement={placement} overlay={<Tooltip>My profile</Tooltip>}>
            <Button variant="clear with-icon" onClick={toggleUserProfile}>
              <ProfileIcon />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={placement}
            overlay={<Tooltip>Notifications</Tooltip>}
          >
            <Button variant="clear with-icon"  onClick={toggleNotifications}>
              <BellIcon />
              {notificationCount > 0 ? <div><Label  color="red" size="mini" floating>{notificationCount}</Label></div> :null } 
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement={placement} overlay={<Tooltip>Support portal</Tooltip>}>
            <Button
              variant="clear with-icon"
              onClick={() => window.open('https://www.graceblocks.com/bots/kayla', '_blank')}
            >
              <HelpIcon />
            </Button>
          </OverlayTrigger>
          
          <OverlayTrigger placement={placement} overlay={<Tooltip>Sign out</Tooltip>}>
            <Button variant="clear with-icon" onClick={handleSignOut}>
              <PowerIcon size={1.2} style={{ margin: '-1.5px 0 0 -1.5px' }} />
            </Button>
          </OverlayTrigger>
         
        </SideBar.Header>
        <SideBar.Body>
          <div style={{ minHeight: '290px' }} >
            {/* added logic to zone header to popup impersonate user. Only availabe to publiczone admins */}
            <h5 className="font-weight-bold" onClick={publicZoneRole ==='Zone owner' || publicZoneRole==='Zone builder' ? ()=>toggleImpersonate() : null }>Zones</h5>
            <ZoneDndList data={myZones} onChange={onChangeZone} showSettings={toggleZoneSettings} />
            {userInfo.status==='Active' && userInfo.mobileverifydate !==null ? (
            <ZoneAdd onAdd={onAddZone} />
            ): null}
          
          </div>
          <h5 className="font-weight-bold pt-3">Resources</h5>
          <div className="menu">
            {publicZone !==null ? (
            <Link to="#">
            <OverlayTrigger placement='top' overlay={<Tooltip>Use a Public Block to kick-start your process.</Tooltip>}>
              <Button variant="clear"
              onClick={()=>showPublicZone()}
              >
                <MagnifyIcon size={0.8} inline />
                &nbsp;Explore Public Blocks
              </Button>
              </OverlayTrigger>
            </Link>
            ): null}
            <a href="https://www.graceblocks.com/support-article/community" target="_blank">
              <Button variant="clear">
                <HearingIcon size={0.8} inline />
                &nbsp;Community
              </Button>
            </a>
          </div>
        </SideBar.Body>
      </SideBar>
      <Content wider={!visible}>
      {userZoneInfo !==undefined && userZoneInfo.plan==='Public' ? (
         <Content.Header>
        <PlanButton upgrade={null} plan={userZoneInfo.plan}>
        Explore and copy a Block to your Zone.
      </PlanButton>
      </Content.Header>
      ) : 
      userZoneInfo !==undefined && userZoneInfo.role==='Zone owner' && userZoneInfo.plan!=='Public' ? (
        <Content.Header>
       
          <PlanButton upgrade={userZoneInfo.plan!=='Public' ? showUpgradePlans: null} plan={userZoneInfo.plan}>
            Current plan: {userZoneInfo.plan} - {userZoneInfo.plan==='Free' ? 'Upgrade now' : 'Change plan'}&nbsp;&nbsp;
            <SettingsIcon inline />
          </PlanButton>

        </Content.Header>
        ): (
          <Content.Header>
        </Content.Header>
        )}

        <Content.Body>
          {!onlyView && (
            <Router>
              <BlocksListPage path={ZONE_DASHBOARD.ZONE} />
              <Redirect noThrow from="*" to={REDIRECTION.DASHBOARD} />
            </Router>
          )}
        </Content.Body>
      </Content>
         
    </Page>
  );
};

Dashboard.defaultProps = {
  signOut: () => {},
  onChangeZone: () => {},
  onAddZone: () => {},

  zones: [],
  onlyView: false,
};

Dashboard.propTypes = {
  signOut: PropTypes.func,
  onChangeZone: PropTypes.func,
  onAddZone: PropTypes.func,

  zones: PropTypes.arrayOf(PropTypes.any),
  onlyView: PropTypes.bool,
};

export default Dashboard;
