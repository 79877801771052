/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import Icon from '@mdi/react';
import moment from 'moment';
import { mdiShareVariant, mdiClose } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import CreateShare from './CreateShare';
import ShareDetails from './shareDetails';
import History from './History';
import ListShares from './ListShares';
import {getShares} from '../../../../api/shares'

const ShareManager = ({ color, tableid, tablename, tableinfo, close,sharedRecordIds,RID,statement,role }) => {

  const [shares,setShares] = useState([]);
  const [tempName, setTempName] = useState(RID);
  // const [records, setRecords]=useState(sharedRecordIds);

  useEffect(() => {
    // console.log(RID)
      loadData();
      if(RID==='') {
        const tname = `Record share - ${moment().format("MM-DD-YYYY")}`
        setTempName(tname)
      }
  },[])


  const loadData = async () =>{
    const data = await getShares(tableid);
    if(data.length>0){
      setShares(data);
      setShareControl('list');
    } else {
      setShareControl('create');
    }
  }

  const loadShare = (existingShare) =>{
    setShareDetails(existingShare);
    setShareControl('details');
  }


  const [shareControl,setShareControl] =useState('list') //possible values are 'create','details','history','list'
  const [shareDetails, setShareDetails]=useState(null)

  const showShareDetails =(details) =>{
    setShareDetails(details);
    setShareControl('details');
  }

  const changeControl = async (control)=> {
    await loadData()
    setShareControl(control);
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          flexDirection: 'row',
          height: '40px',
          backgroundColor: color,
          color: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginLeft: '10px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiShareVariant} size="25px" />
          <div style={{ marginLeft: '5px', fontSize:'15px' }}>Share records </div>
        </div>
        <TextButton
          text="Hide panel when done"
          Action={close}
          textColor="white"
          icon={mdiClose}
          hoverColor="#FFFFFF80"
        />
      </div>
      {/* <button onClick={testStream}>TEST STREAM</button> */}
      {shareControl==='create' ? (
      <CreateShare 
        role={role}
        close={close} 
        sharedRecordIds={sharedRecordIds} 
        color={color}
        tableid={tableid} 
        tablename={tablename} 
        tableinfo={tableinfo} 
        showShareDetails={showShareDetails}
        RID={tempName}
        statement={statement}
        />): null}

      {shareControl==='details' ? (
        <ShareDetails 
          tablename={tablename} 
          close={close} 
          color={color}
          details={shareDetails}
          changeControl={changeControl}
          enableBackArrow={shares.length>0}
        />): null}

    {shareControl==='history' ? (
        <History 
          tablename={tablename} 
          close={close} 
          color={color}
          details={shareDetails}
          changeControl={changeControl}
        />): null}

  {shareControl==='list' && shares.length>0 ? (
        <ListShares
          tablename={tablename} 
          shares ={shares}
          close={close} 
          color={color}
          loadShare={loadShare}
          changeControl={changeControl}
        />): null}

    </div>
  );
};

export default ShareManager;
