/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/core'
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const ButtonRender = ({ value, color, cellWidth, cellHeight, uitype, params, systemid,rule, field, TD }) => {
  const TagStyle = {
    width: cellWidth - 3,
    height: cellHeight - 7,
    float: 'left',
    marginRight: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  // 6-1-2020. Currently, this ButtonRender is only used for the "Users" tab in Users as well as "Users" tab in any Block.
  // It is specifically used to take action on a user, depending on which block it's being rendered in. The 
  // systemid=50 is defined for main Users tab, and systemid=51 for User status in Block users tab.
  // We will want to find more elegant solution to dynamically render what appears on button based on actual value, so this
  // buttonRender could be used for other types of actions.
  // The actual logic to these buttons is contained in Actions.JS file. The Block.jsx component uses the "action" attribute from
  // tableinfo to call the right action. 
  const CreateText = () => {
    if (value !== '' && value !== null) {
        
        let displaystatus = ''
        if(systemid===50) {
        switch(value) {
            case 'Active':
                displaystatus= "Deactivate"
            break;
            case "Inactive":
                displaystatus= "Activate"
            break;
            case "Not yet invited" :
                displaystatus= "Send Invite"
            break;
            case "Invited" :
                displaystatus = "Resend Invite"
            break;
            default :
            "Send Invite"
        }
      } else if(systemid===51) {
        switch(value) {
          case 'Active':
              displaystatus= "Remove"
          break;
          case "Inactive":
              displaystatus= "Remove"
          break;
          case "Not yet invited" :
              displaystatus= "Send Invite"
          break;
          case "Invited" :
              displaystatus = "Resend Invite"
          break;
          default :
          "Send Invite"
      }
      }

      return (
        <div
          css={{
            marginTop: '3px',
            backgroundColor: value==='Inactive' ? 'white'  : color,
            padding: '3px 0',
            textAlign: 'center',
            borderRadius: '10px',
            verticalAlign: 'center',
            border: value=='Inactive' ? `1px solid ${color}` : null,
            width: cellWidth - 10,
            color: value==='Inactive' ? color : 'white',
            '&:hover' : {
                backgroundColor: value==='Inactive' ? 'white' : `${color}80`,
                color: value==='Inactive' ? `${color}80` : 'white'
            }
          }}
        >
          {displaystatus}
        </div>
      );
    }
  };

  return <div>{CreateText()}</div>;
};

ButtonRender.propTypes = {
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
};
export default ButtonRender;
