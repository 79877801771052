/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';
import axios from 'axios';


export const verifyShare = async (token) => {

    const {REACT_APP_API_CLOUDRUN_URL,REACT_APP_API_KEY } = process.env;
    const payload={ReactAPIKey: REACT_APP_API_KEY,token};

    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/verifyShare`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });
    return result.data;
}

export const getShares = async (tableid) => {

  const payload={tableid}
  const dataresponse = await cloudrun.post('/getShares', {payload})
  return dataresponse
}

export const viewShare = async (shareCode) => {
  const payload={shareCode};
  const dataresponse = await cloudrun.post('/viewShare', {payload})
  return dataresponse
}

export const viewRecordId = async (id) =>{
  const payload={id,dbfunction:'recordShareView'};
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload});
  return dataresponse
}

export const recordShareDownload = async (id,ip) =>{
  const payload={id,ip,dbfunction:'recordShareDownload'};
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload});
  return dataresponse
}

export const updateViewedRecords = async (id,rowid) =>{
  const payload={id,rowid,dbfunction:'updateViewedRecords'};
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload});
  return dataresponse
}

export const filterAndSortRows = async (sortField,sortOrder,userFilters,shareCode,offset) =>{
  const payload={sortField,sortOrder,userFilters,shareCode,offset};
  const dataresponse = await cloudrun.post('/filterShare', {payload})
  return dataresponse

}


export const createShare = async (name,tableid,bookmarkid,records,instructions,settings) => {

  const payload={name,tableid,bookmarkid,records,instructions,settings}
  const dataresponse = await cloudrun.post('/createShare', {payload})
  return dataresponse
}
export const generateNewLink = async (id) =>{
  const payload={id}
  const dataresponse = await cloudrun.post('/generateNewLink', {payload})
  return dataresponse
}

export const renameShare = async(shareid,name) =>{
  const payload={name,shareid,dbfunction:'renameShare'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse;
}

export const updateInstructions = async(shareid,instructions) =>{
  const payload={instructions,shareid,dbfunction:'updateShareInstructions'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse;
}

export const updateShareStatus = async(shareid,isActive) =>{
  const payload={shareid,isActive,dbfunction:'updateShareStatus'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse;
}

