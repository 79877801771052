/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import { toast } from 'react-toastify';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import {replaceFieldsInTableUserColumnSettings} from '../../../../api/tables'

class UserFields extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData} = this.props;

    this.state = {
      showAdvanced: false,
      callout: columnConfigData.callout,
      isPrivate: columnConfigData.isPrivate ?? false
      //   defaultText: '',
    };
  }

  static contextType = AccountContext;


  Save = async () => {
    const { callout,isPrivate } = this.state;
    const { field, tableid, header, selectedUIType,tableinfo,columnConfigData } = this.props;

    // Update Tableinfo with new settings.
    const originalField = columnConfigData.data;
    const originalUIType = columnConfigData.uitype;


    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "header": "${header}",
      "displayfield": "${field}",
      "detailviewsort": ${columnConfigData.detailviewsort}
  }`

    const updatedNode = JSON.parse(defaultNode)

    try {
    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
    if (rowindex !== -1) {
      updatedNode.callout=callout;
      updatedNode.uitype=selectedUIType;
      updatedNode.header=header;
      updatedNode.readOnly=true;
      updatedNode.renderer="UserRender";
      updatedNode.originalField=originalField;
      updatedNode.displayfield= selectedUIType===24 ? 'AddedBy' : 'UpdatedBy'  // uppercase to match relationship graphql name.
      updatedNode.data =selectedUIType===24 ? 'addedby' : 'updatedby' // use lowercase to match logic in removefieldadmin, so physical field doens't get deleted.
      
      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }
      
      tableinfo.columns[rowindex]=updatedNode;


      // if coming from single select, delete/remove related structures of that field.
      if(originalUIType===6 || originalUIType===7)
      {
        const payload={tableid,fieldname:columnConfigData.data,removeRelatedStructureOnly:true}
        await cloudrun.post('/removeField', { payload })
      }

    

       // 2-4-2021 When changing to a system field (addedon, updatedon) we need to 
        // manually update the TableUsers columnsettings fields to this value.
      await replaceFieldsInTableUserColumnSettings(tableid,columnConfigData.data,selectedUIType===24 ? 'addedby' : 'updatedby')

      await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
    }
    return "success"
  } catch (error) {
    return `An error occurred: ${error.message}`
  }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  render() {
    const { showAdvanced, callout,isPrivate } = this.state;
    const { color,selectedUIType } = this.props;
    return (
      <div>
        <div style={{marginTop: '5px' }}>
          {selectedUIType===24 ? <div>This field shows the GraceBlocks user who created the record. This field is system generated. It is not editable.</div> : null}
          {selectedUIType===25 ? <div>This field shows the GraceBlocks user who updated the record. This field is system generated. It is not editable.</div> : null}
        </div>
        <div style={{height:'15px'}} />
       <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} />

        {showAdvanced ? (<div>
           <div style={{marginTop:'10px',marginBottom:'10px'}}>
           <GBSwitch
             color={color}
             text="Private field"
             isChecked={isPrivate}
             Action={this.togglePrivate}
           />
           </div>
           <Callout callout={callout} updateCallout={this.CalloutHandler} />       
        </div>) : null}
      </div>
    );
  }
}

UserFields.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default UserFields
