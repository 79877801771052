/* eslint-disable */
import React, { useEffect, useState } from 'react';
import prettyJson from './prettyJSON';

const UpdateRecords = ({ tableData, block, blockKey, records,relationalTableNames }) => {

  const { REACT_APP_API_URL } = process.env;
  // const stringColor ='#FFBE24'
  const stringColor='#fde1ab';
  const tempRecords = JSON.parse(JSON.stringify(records));

  const getRelationTableName = (tableid) =>{
    const idx = relationalTableNames.findIndex(el=>el.id===parseInt(tableid));
    if(idx !=-1) {
        return relationalTableNames[idx].name;
    } else {
        return "Unknown";
    }
}

const getReadOnlyFields = () => {
    const readOnlyFields = [17,18,19,20,24,25];
    const fields =[];
    tableData.tableinfo.columns.map(col => {
        if(readOnlyFields.includes(col.uitype)){
            fields.push(col)
        }
    })
    
    // console.log(readfields);
    return fields;
}


  const getExampleJson = () => {
    if(records.length>0) {

      //Writable field types
      const validFields = [1,2,3,4,5,6,7,8,9,10,11,22,23];

      tempRecords.map((record,i) => {
        delete record.CustomID;
        delete record.addedon;
        delete record.updatedon;
        delete record.addedby;
        delete record.updatedby;

        //Remove all read only fields.   
        Object.keys(record).map(itm=>{
            const idx = tableData.tableinfo.columns.findIndex(el=>el.header===itm);
            if(idx !==-1) {
                const col = tableData.tableinfo.columns[idx];
                if(!validFields.includes(col.uitype)) {
                    delete record[itm]
                } 
                else if(col.uitype===8 && col.lookup==='multiple'){
                    record[itm] = record[itm].map(el2=>el2.userid);
                }
                else if(col.uitype===8 && col.lookup==='single'){
                    record[itm] = record[itm].userid;
                }
                else if(col.uitype===1 && col.lookup==='multiple'){
                    record[itm] = record[itm].map(el2=>el2.id);
                }
                else if(col.uitype===1 && col.lookup==='single'){
                    record[itm] = record[itm].id;
                }
                else if(col.uitype===4 && col.lookup==='single'){
                    record[itm] = record[itm].url;
                }
                else if(col.uitype===4 && col.lookup==='multiple'){
                    record[itm] =record[itm].map(el2=>el2.url);
                }
            }
        })
     
    })

    // const  final = '<pre style="color:white">'+prettyJson(JSON.stringify(tempRecords,null, 2))+'</pre>';
    const  final = `<pre style="color:${stringColor}">'+${JSON.stringify(tempRecords,null, 2).trim()}+'</pre>`;
    return  <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>
    } else {
        return <span style={{color:stringColor}}>Need sample data</span>
    }
  }

  const getExampleResponse = () => {
    const  final = '<pre style="color:white">'+prettyJson(JSON.stringify(records,null, 2))+'</pre>';
    return  <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>
  }

  const getFieldInstructions =(col) => {
      if(col.uitype===1 && col.lookup==='single') {
          const name =<span style={{backgroundColor:`${block.color}1A`,padding:'3px',borderRadius:'10px'}}>{getRelationTableName(col.source.lookuptableid)}</span>;
          return ( <div style={{marginTop:'10px',marginRight:'10px'}}>
                    <div>For the single relational field <b>{col.header}</b>, you can associate a single {name} record by specifying either 
                    the unique integer id of the {name} record or the string relational identifier.
                    We highly recommend using the unique integer value, as this will not change over time.
                    If using the relational identifer, it must exactly match the current relational identifier for this record.
                    Note that the relational identifier can be changed at any time and that it may not be unique.
                    If there are more than one match on the relational identifier, the system will use the first match found.
                    </div>
              </div>)
      } else if(col.uitype===1 && col.lookup==='multiple') {
        const name =<span style={{backgroundColor:`${block.color}1A`,padding:'3px',borderRadius:'10px'}}>{getRelationTableName(col.source.lookuptableid)}</span>;
        return ( <div style={{marginTop:'10px',marginRight:'10px'}}>
        <div>For the multiple relational field <b>{col.header}</b>, you can associate multiple {name} records by specifying an array of either 
        unique integer id's of the {name} record or the string relational identifier equivalent.
        We highly recommend using the unique integer values, as this will not change over time.
        If using the relational identifer, it must exactly match the current relational identifier for this record.
        Note that the relational identifier can be changed at any time and that it may not be unique.
        If there are more than one match on the relational identifier, the system will use the first match found.
        </div>
  </div>)
      }
      
      else if (col.uitype===4 && col.lookup==='single') {
          return (
            <div style={{marginTop:'10px',marginRight:'10px'}}>For the single attachment field <b>{col.header}</b>, you simply provide the url
            of the attachment.
          </div>
          )
      }
      else if (col.uitype===4 && col.lookup==='multiple') {
        return (
          <div style={{marginTop:'10px',marginRight:'10px'}}>For the multiple attachment field <b>{col.header}</b>, you provide an array of string url's
        </div>
        )
    }
    else if (col.uitype===8 && col.lookup==='single') {
        return (
          <div style={{marginTop:'10px',marginRight:'10px'}}>For the single collaborator field <b>{col.header}</b>, you specify either the integer userid of the user or the user's email address.
          Please note the user must be an active member of this zone.
        </div>
        )
    }
    else if (col.uitype===8 && col.lookup==='multiple') {
        return (
          <div style={{marginTop:'10px',marginRight:'10px'}}>For the multiple collaborator field <b>{col.header}</b>, you specify an array of either the integer userid's of the users or the user's email address.
          They can be used interchangeably. Please note the user must be an active member of this zone.
        </div>
        )
    }
  }

  return (
    <div style={{ margin: '10px',scrollMargin: '20px' }} id="updateRecords">
      <div style={{ color: block.color, fontSize: '24px' }}>
        Update {tableData.name} records
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px',alignItems:'flex-start' }}
      >
        <div style={{ width: '60%' }}>
         To update  <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '}records, issue a <b>PATCH</b> request to the{' '}
          <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '}
          endpoint. Note that table names and table ids can be used interchangeably. Using table ids means table name changes do not require modifications to your API request.
          <p/><p/>
          <div>Your request body should include an array of up to 50 record objects. Each of these objects should have an <b>id</b> property representing the record ID. </div>
          <p/><p/> <p/><p/>
          <div>You can include all, some, or none of the field values to be updated.</div>
            <p/><p/>
            {tableData.tableinfo.columns.map(col=>(
               getFieldInstructions(col)
            ))}

            <div style={{marginTop:'20px'}}>
                The following fields are automatically generated by the system.
                {getReadOnlyFields().map(col => (
                   <span style={{marginLeft:'10px',backgroundColor:`${block.color}1A`,padding:'3px',borderRadius:'10px'}}>{col.header}</span>
                ))}
            </div>
         
        </div>
        <div
          style={{
            backgroundColor: '#303336',
            padding: '10px',
            color: 'white',
            width: '40%',
            alignSelf: 'stretch',
          }}
        >
          <div style={{color:'#98999B'}}>EXAMPLE REQUEST</div>
          <p/><p/>
          <div>
            curl -v -X PATCH <span style={{color:stringColor}}>{REACT_APP_API_URL}/v1/{blockKey}/{encodeURI(tableData.name)}</span> \
            <br/>-H  <span style={{color:stringColor}}>"Authorization: Bearer YOUR_API_KEY"</span> 
            <br/>-H <span style={{color:stringColor}}>"Content-Type: application/json"</span>
          </div>
          <div>
          <div>--data '{getExampleJson()}'</div>
          <p/><p/>
          <div style={{color:'#98999B'}}>EXAMPLE RESPONSE</div>
           <div>
               {getExampleResponse()}
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateRecords;
