
/* eslint-disable */
import React, {useEffect, useState,useContext } from 'react';
import { mdiAccountSwitch} from '@mdi/js';
import {Form,Input } from 'semantic-ui-react';


import { toast } from 'react-toastify';
import firebase from '../../clients/firebase';
import { useNavigate } from '@reach/router';
import { AccountContext } from '../../providers/AccountProvider';
import TextButton from '../../components/TextButton/TextButton';
import {findUserByEmail,impersonate} from '../../api/users';
import { DASHBOARD } from '../../constants/routes';
import Global from '../../clients/global'



const ImpersonateUser = ({toggleImpersonate,color}) =>{

    const [email,setEmail] = useState('');
    const [users, setUsers] =useState([]);
    const { switchToZone } = useContext(AccountContext);
    const [code, setCode] =useState(null);
    const [fieldParams, setFieldParams] = useState(null);


    const navigate = useNavigate();

    const searchEmail = async () =>{
        const results = await findUserByEmail(email);
        setUsers(results);
    }


    const changeUser = async (fbuserid) => {
        const userToken = await impersonate(fbuserid);
        firebase.auth().signInWithCustomToken(userToken);
     
        switchToZone(parseInt(Global.zoneid)).catch(() => {
          navigate(`${DASHBOARD.ZONE_PREFIX}${Global.zoneid}`, { replace: true });
        });
        navigate(`${DASHBOARD.ZONE_PREFIX}${Global.zoneid}`, { replace: true });
        
    }

    return (
        <div style={{ position: 'absolute', border:'1px solid black', borderTopRightRadius:'10px', borderTopLeftRadius:'10px', top:200,left:400,width:'500px', height:'300px', zIndex:200,backgroundColor:'white',overflow:'auto'}}>
            <div
        style={{
          display: 'flex',
          color: 'white',
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
          height: '40px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          outline: 'none',
          backgroundColor: '#AE007B',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingLeft: '10px',
          paddingRight: '13px',
        }}
      >
        <TextButton
          text='Impersonate another user' 
          iconPosition="left"
          fontSize="14px"
          icon={mdiAccountSwitch}
          hoverColor="#FFFFFF80"
          Action={toggleImpersonate}
          ActionValue={true}
          iconSize="25px"
        />
      </div>
          <div style={{margin:'20px'}}>
            {/* <button onClick={sendEmailTest}>Email Test</button> */}
          <Form>
            <Form.Input label="USER EMAIL" 
             action={{
                icon: "search",
                onClick: () => searchEmail()
              }}
            onChange={(e,data)=>setEmail(data.value.trim())} />
          </Form>
          <div style={{margin:'10px', height:'350px', overflow:'auto'}}>
          {users.length>0 ? (
              users.map(usr => (
                  <div key={usr.email} style={{margin:'10px',cursor:'pointer'}} onClick={()=>changeUser(usr.fbuserid)} >
                      {usr.firstname} - {usr.lastname} - {usr.email}
                  </div>
              ))
          ): null}
            </div>
            {/* 10-14-23 Don't need this anymore as I've already converted content
                can remove commented out code in future release, just keeping "in case" something
                chnages. 
            */}
            {/* <div>
                <Input value={fieldParams} onChange={(e,data)=>setFieldParams(data.value)} />
                <button onClick={convertTableToHTML}>Convert</button>
                <br/><br/>
                <button onClick={convertFormJSONToHTML}>Convert Form JSON to HTML</button>
            </div> */}

            {/* 11-17-2023 no longer needed, added to main home page.
            <div>
              <div>Add Block by code (TESTING)</div>
              <Form>
            <Form.Input label="Product code" 
             action={{
                icon: "play",
                onClick: () => addProductLocal()
              }}
            onChange={(e,data)=>setCode(data.value.trim())} />
          </Form>
            </div> */}
          </div>
        </div>
    )
}

export default ImpersonateUser