/* eslint-disable */
import React from 'react';
import {
  ElementsConsumer,
  CardElement,
} from '@stripe/react-stripe-js';
import { Input, Checkbox } from 'semantic-ui-react';
import CardSection from './CardSection';
import GBSubmit from '../../components/GBSubmit/GBSubmit';
import TextButton from '../../components/TextButton/TextButton';
import Spinner from '../../components/Spinner/Spinner';
import { createStripeSubscription, updateStripeSubscription,updateZonePlan } from '../../api/zone';

class CheckoutForm extends React.Component {
  state = {
    error: '',
    useExistingPayment: false,
    isLoading: false,
  };


    componentDidMount(){
      const { stripe, elements, amount, zoneInfo, currentPlan, completePayment,lineItems,promoCodeID } = this.props;
    }

  handleSubmit = async (event) => {

    event.preventDefault();
   
    const { stripe, elements, amount, zoneInfo, currentPlan, completePayment,lineItems,promoCodeID } = this.props;

    if(amount===-1 && currentPlan.name==='Free') {
     
      this.setState({ isLoading: true });
      await updateZonePlan(zoneInfo.zoneid,"Free");
      this.setState({ isLoading: false });

      const paymentDetails = {
        currentPlan,
        userLicenses: amount,
        status: 'succeeded'
      };
      completePayment(paymentDetails);

      return false;
    }




    this.setState({ isLoading: true });
    
    let { useExistingPayment, error } = this.state;

    // 3-10-2021 If plan is Free, set useExistingPayment = true automatically
    if(currentPlan.name==='Free') {
      useExistingPayment=true;
    }

    if (!stripe || !elements) {
      return;
    }

    let paymentInfo = '';
    let isExistingPayment = false;
    let currentError = '';
    let result='';

    if (!useExistingPayment) {
      const cardelement = elements.getElement(CardElement);
      const paymentresult = await stripe.createPaymentMethod({ type: 'card', card: cardelement });
      
      if (paymentresult.error !== undefined) {
        this.setState({ error: paymentresult.error.message });
        currentError = paymentresult.error.message;
      } else {
        paymentInfo = {
          paymentId: paymentresult.paymentMethod.id,
          cardBrand: paymentresult.paymentMethod.card.brand,
          cardLast4: paymentresult.paymentMethod.card.last4,
          exp_month: paymentresult.paymentMethod.card.exp_month,
          exp_year: paymentresult.paymentMethod.card.exp_year,
          isDefault:true
        };
      }
    } else {
      paymentInfo = zoneInfo.stripeInfo.paymentInfo.filter(itm=>itm.isDefault===true)[0];
      isExistingPayment = true;
    }

    if (paymentInfo !== '') {
    
        result = zoneInfo.stripeInfo.subscriptionId===undefined ? await createStripeSubscription(
        zoneInfo.stripeInfo,
        paymentInfo,
        currentPlan,
        amount,
        isExistingPayment,
        promoCodeID
      ) 
      : result = await updateStripeSubscription(
        zoneInfo.stripeInfo,
        paymentInfo,
        currentPlan,
        amount,
        isExistingPayment
      )

      let isSuccessful='';
     
      if (result.error === undefined) {

      // if(result.latest_invoice.payment_intent !== null) {
      //   isSuccessful = result.latest_invoice.payment_intent.status === 'succeeded';
      // } else {
      //   isSuccessful = result.latest_invoice.paid===true;
      // }
       
          const paymentDetails = {
            trxid: result.latest_invoice.number,
            invoicePDF: result.latest_invoice.invoice_pdf,
            paymentInfo,
            currentPlan,
            amountDue: result.latest_invoice.amount_due,
            amountPaid: result.latest_invoice.amount_paid,
            userLicenses: amount,
            lineItems:lineItems,
            status: result.latest_invoice.payment_intent !==null ? result.latest_invoice.payment_intent.status : 'succeeded'
          };
          completePayment(paymentDetails);
        
      } else {
        this.setState({ error: result.error.message });
      }
    } else {
      this.setState({ error: currentError });
    }

    this.setState({ isLoading: false });
  };

  CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  render() {
    const { cancelPayment, zoneInfo,currentPlan,actualCost,nextBillingDate,color } = this.props;
    const { error, useExistingPayment, isLoading } = this.state;
    return (
      <div>
        <div style={{ marginLeft: '10px' }}>
          <div style={{ marginLeft: '10px' }}>
            {zoneInfo.stripeInfo.paymentInfo !== undefined && currentPlan.name !=='Free' ? (
              <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ fontWeight: 'bold' }}>Enter your payment details:</div>{' '}
                <div style={{ width: '10px' }}></div>
                <Checkbox
                  checked={useExistingPayment}
                  onChange={(e, data) => this.setState({ useExistingPayment: !useExistingPayment })}
                  label="Use payment information already on file"
                />
              </div>
            ) : null}

            <form onSubmit={this.handleSubmit}>
              {!useExistingPayment &&  currentPlan.name !=='Free' ? (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ borderBottom: '3px solid #AE007B', marginRight: '20px' }}>
                      Credit Card
                    </div>
                    {/* <div style={{ marginRight: '20px' }}>Bank Transfer</div>
                    <div>PayPal</div> */}
                  </div>
                  <div style={{ marginBottom: '10px', marginTop: '10px', borderRadius: '5px' }}>
                    <div style={{ fontWeight: 'bold' }}>Name on Card</div>
                    <div style={{ width: '500px' }}>
                      <Input fluid placeholder="Full name" />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '500px',
                      display: 'flex',
                      flexDirection: 'column',
                      border: '1px solid #75757580',
                      borderRadius: '10px',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '5px',
                        marginLeft: '5px',
                        marginTop: '5px',
                      }}
                    >
                      CREDIT CARD
                    </div>
                    <div style={{ marginLeft: '5px', marginTop: '5px' }}>
                      <CardElement options={this.CARD_ELEMENT_OPTIONS} />
                    </div>
                    {error !== '' ? (
                      <div style={{ marginLeft: '10px', color: 'red', fontSize: '11px' }}>
                        {error}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}

              {!isLoading ? (
                <>
                  {nextBillingDate !== null ? (
                  <div style={{ fontSize: '10px', marginTop: '10px',marginRight:'30px' }}>
                    You authorized GraceRock, dba GraceBlocks, to charge you the amount shown. The ongoing {currentPlan.interval} fee will be ${parseFloat(actualCost).toLocaleString('en',{ minimumFractionDigits: 2 })} US Dollars. Your next
                    billing cycle is on {nextBillingDate}. Your subscription renews automatically each <span> </span>
                    {currentPlan.interval==='monthly' ? 'month' : null} 
                    {currentPlan.interval==='annual' ? 'year' : null}
                    <span> </span>
                    until you
                    cancel. By clicking the button below,
                    you understand and agree to these terms and GraceBlocks <a href=" https://www.graceblocks.com/termsofservice" target="_blank">Terms of
                    Service</a> and <a href="https://www.graceblocks.com/privacy" target="_blank" >Privacy Policy.</a>
                  </div>
                  ): null}

                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      justifyContent: 'center',
                      // border:'1px solid red',
                      marginRight:'20px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <TextButton
                      Action={this.props.cancelPayment}
                      text="Cancel"
                      hoverColor="#75757580"
                      textColor="black"
                    />
                    <div style={{ width: '20px' }}></div>
                    <GBSubmit action={this.handleSubmit} width="125px" color="#AE007B" text="Change plan" />
                  </div>
                </>
              ) : (
                <div>
                  <Spinner color={color} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          cancelPayment={props.cancelPayment}
          actualCost={props.actualCost}
          nextBillingDate={props.nextBillingDate}
          completePayment={props.completePayment}
          zoneInfo={props.zoneInfo}
          amount={props.amount}
          promoCodeID={props.promoCodeID}
          currentPlan={props.currentPlan}
          lineItems={props.lineItems}
          color={props.color}
        />
      )}
    </ElementsConsumer>
  );
}
