import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Actions from './Actions';
import Button from '../Button';
import './styles.scss';

const Alert = ({ show, customAction, onHide, onExited, icon, title, small, children }) => {
  const className = classNames('alert-dialog', small && 'alert-dialog-small');
  const actions = [];
  const other = [];
  React.Children.forEach(children, (c) => {
    if (c && c.type === Actions) {
      actions.push(c);
    } else {
      other.push(c);
    }
  });
  return (
    <Modal
      show={show}
      size="sm"
      centered
      backdrop="static"
      onHide={onHide}
      onExited={onExited}
      dialogClassName={className}
    >
      {!!title && (
        <Modal.Header>
          <Modal.Title as="div">
            {icon}
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{other}</Modal.Body>
      {!!actions && actions}
      {!customAction && (
        <Modal.Footer>
          <Button onClick={onHide} variant="danger">
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

Alert.defaultProps = {
  onHide: () => {},
  onExited: undefined,

  show: false,
  customAction: false,
  icon: null,
  title: null,
  small: false,
  children: null,
};

Alert.propTypes = {
  onHide: PropTypes.func,
  onExited: PropTypes.func,

  show: PropTypes.bool,
  customAction: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  small: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

Alert.Actions = Actions;

export default Alert;
