/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useContext } from 'react';

import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import { Input } from 'semantic-ui-react';
import GBButton from '../../../../../components/GBButton/GBButton';
import { AccountContext } from '../../../../../providers/AccountProvider';
import { mdiImageOutline, mdiClose } from '@mdi/js';
import { uploadFiles, deleteFile } from '../../../../../api/images';
import Global from '../../../../../clients/global'

const ImageUploader = ({ addText, existingFile, saveImage, color, type }) => {
  const myInput = useRef();
  const [showUpload, setShowUpload] = useState('');
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(existingFile);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadChange = (type) => {
    setShowUpload(type);
    if (type === 'url') {
      setShowUrlInput(true);
    }
  };

  const closeInput = () => {
    setShowUrlInput(false);
    setShowUpload('');
  };

  const fetchImageUrl = () => {
    const urlItem = { url: url, guidname: url, uploadType: 'url' };

    const ext = url.split('.').pop();
    const findExt = ['jpg', 'jpeg', 'png', 'svg', 'gif'].findIndex(
      (itm) => itm === ext.toLowerCase(),
    );

    if (findExt === -1) {
      setUrl('');
      toast.info(
        <div style={{ margin: '5px' }}>
          The link was not of a media type accepted for text messaging services.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          closeButton: false,
        },
      );
      return false;
    }

    setUrl('');
    setShowUpload('');
    setShowUrlInput(false);
    saveImage(type, urlItem);
  };

  const handleImageUpload = async (e) => {
    if (e.target.files.length > 0) {
      let newArray = [];
      let hasInvalidFiles = false;
      let exceedsFileSizeLimit = false;

      // loop over uploaded files and determin if any have invalid extensions
      // For any invalid file, remove from array and set flag to show toast notification.
      const tempArray = Array.from(e.target.files);
      let toastMessage = '';

      tempArray.forEach((itm) => {
        const ext = itm.name.split('.').pop();
        const findExt = ['jpg', 'jpeg', 'png', 'svg', 'gif'].findIndex(
          (itm) => itm === ext.toLowerCase(),
        );
        if (findExt !== -1) {
          newArray.push(itm);
        } else {
          hasInvalidFiles = true;
        }
        if (itm.size > 4000000) {
          exceedsFileSizeLimit = true;
        }
      });

      if (hasInvalidFiles) {
        toastMessage += 'The files you attempted to upload is not a valid image type. ';
      }
      if (exceedsFileSizeLimit) {
        toastMessage += "Can't load file; limit of 4M exceeded.";
      }

      if (hasInvalidFiles || exceedsFileSizeLimit) {
        toast.info(<div style={{ margin: '5px' }}>{toastMessage}</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
          closeButton: false,
        });
        setIsLoading(false);
        myInput.current.value = null;
        return false;
      }

      setShowUpload('');
      setIsLoading(true);
      setShowUrlInput(false);
      const schema = `schema_${Global.zoneid}`;
      const fileInfo = await uploadFiles(schema, newArray, 'attachments');
      setIsLoading(false);
      saveImage(type, fileInfo[0]); //returns array, in this case, will always just be single image.
    }
  };

  return (
    <div css={{
      '&:hover': {
        '& .imageHover': {
          display: showUpload !== 'url' ? 'block' : 'none',
          transition: 'all .3s ease',
        },
        '& .urlHover': {
          display: 'block',
          transition: 'all .3s ease',
        },
        '& .uploadText': {
          display:'none'
        },
        '& .topDiv': {
          width:'300px'
        }
      },
    }}>
      <div
       className="topDiv"
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'space-between',
          border: '1px dashed black',
          padding: '10px',
          width: '150px',
          height: '67px',
          color: 'black',
          backgroundColor: 'white',
          opacity: 0.8,
          //   border: '1px dashed black',
        }}
      >
        <div css={{display:'flex', flexDirection:'column', }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{marginLeft:'25px', marginTop:'15px'}} className="uploadText">{addText} 
            <Icon
              onClick={(e) => myInput.current.click()}
              css={{
                cursor: 'pointer',
                marginLeft:'10px',
                '&:hover': {
                  color: `${color}80`,
                },
              }}
              path={mdiImageOutline}
              size="20px"
            />
            </div>
          
            <div
              onClick={(e) => (showUpload === '' ? myInput.current.click() : null)}
              className="imageHover"
              css={{
                marginLeft: '10px',
                display: showUpload === 'desktop' ? 'block' : 'none',
                '&:hover': { color: '#75757580' },
              }}
            >
              <Icon  path={mdiImageOutline} size="20px" /> {'From desktop'}
              <input
                id="myInput"
                type="file"
                ref={myInput}
                style={{ display: 'none' }}
                onChange={(e) => handleImageUpload(e)}
              />
            </div>
            <div
              onClick={() => (!showUrlInput ? handleUploadChange('url') : null)}
              className="urlHover"
              css={{
                display: showUpload === 'url' ? 'block' : 'none',
                marginLeft: '20px',
                '&:hover': { color: '#75757580' },
              }}
            >
              {!showUrlInput ? <div><Icon path={mdiImageOutline} size="20px" /> From url</div>  : null}
              {showUpload === 'url' && showUrlInput ? (
                <div style={{ width: '300px' }} className="row">
                  <Input
                    onChange={(e, data) => setUrl(data.value)}
                    value={url}
                    style={{ width: '50%', height: '25px', marginRight: '5px', }}
                    fluid
                    placeholder='http://'
                  />
                  <GBButton Action={fetchImageUrl} text="fetch" color={color} width="50px" />
                  <Icon
                    style={{ marginLeft: '5px' }}
                    onClick={() => closeInput()}
                    path={mdiClose}
                    size="20px"
                    color="#E06055"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="imageHover"
            css={{ fontSize:'12px', display: showUpload === 'desktop' ? 'block' : 'none',marginLeft:'10px',marginTop:'10px' }}
          >
            Supported file types: svg,jpg,png,gif (Max: 4Mb)
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
