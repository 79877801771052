/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, {useState,useEffect,Component} from 'react';

import Icon from '@mdi/react';
import GBButton from '../../../../../components/GBButton/GBButton';
import DOMPurify from 'dompurify';
import { mdiDragVertical, mdiPencil, mdiClose } from '@mdi/js';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';


const RTFElement= ({
  fieldData,
  color,
  isEditMode,
  removeItemFromSelectedArray,
  updateRTFContent,
  fontFamily,
  textColor
}) => {

 const [startValue, setStartValue] = useState(fieldData.content ?? fieldData.label);

 useEffect(() =>{
  setStartValue(fieldData.content)
 },[isEditMode])

 const createMarkup=(html) =>{
      return {
        __html: DOMPurify.sanitize(html)
      }
    }

  return (
    <div
      css={{
        // padding: '10px',
        width:'100%',
        '&:hover': {
          backgroundColor: `${color}1A`,

          '& .editItem': {
            display: 'flex',
          },
        },
      }}
    >
      {!isEditMode ? (
        <div
        // onClick={() => setIsEditMode(true)}
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontFamily
          }}
        >
          <div style={{minHeight:'30px',width:'100%',color:textColor}} dangerouslySetInnerHTML={createMarkup(fieldData.content??fieldData.label)} />
          <div
            className="editItem"
            css={{
              display: 'none',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            <Icon style={{ marginLeft: '10px' }} path={mdiDragVertical} size="20px" />
            <div
              css={{
                color: '#E06055',
                borderRadius:'100%',
                '&:hover': {
                  svg: { color: 'white' },
                  backgroundColor:'#E06055'
                },
              }}
            >
              <Icon onClick={() => removeItemFromSelectedArray(fieldData.id)} path={mdiClose} size="20px" />
            </div>
          </div>
        </div>
      ) : (
        <div
          css={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center',  cursor:'default', }}
          className="h1edit"
        >
          {/* <EditorContainer id={fieldData.id} content={fieldData.content} handleRTFClose={handleRTFClose} /> */}
          <CustomEditor initValue={startValue} updateContent={updateRTFContent} height={400} callingComponent="RTFElement" />
 
          <div style={{ width: '1px' }} />
          <Icon path={mdiDragVertical} size="20px" />
        </div>

      )}
    </div>
  );
};

export default RTFElement;
