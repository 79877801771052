import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import './styles.scss';

const Header = ({ children }) => {
  return <div className="header">{children}</div>;
};

Header.defaultProps = { children: null };

Header.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

Header.Title = Title;

export default Header;
