/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import Icon from '@mdi/react';
import {
  mdiFileDocument,
  mdiFilePowerpoint,
  mdiFileVideo,
  mdiVolumeHigh,
  mdiFileWord,
  mdiFileExcel,
  mdiFilePdfBox,
  mdiClose,
  mdiYoutube,
  mdiAlertCircleOutline,
  mdiEye
} from '@mdi/js';
import brokenimage from '../../assets/icons/image-broken.svg';

const ImageThumbnails = ({
  items,
  Action,
  height = '30px',
  maxWidth = null,
  showDeleteIcon = true,
  showHover = true,
  marginTop = '10px',
  deleteAction=null
}) => {
  // useEffect(() =>{
  //   console.log(items)
  // },[])

  const getFileIcon = (extension) => {
    if (extension === 'doc' || extension === 'docx') {
      return mdiFileWord;
    } else if (extension === 'ppt' || extension === 'pptx') {
      return mdiFilePowerpoint;
    } else if (extension === 'xls' || extension === 'xlsx') {
      return mdiFileExcel;
    } else if (extension === 'pdf') {
      return mdiFilePdfBox;
    } else if (extension === 'mp4') {
      return mdiFileVideo;
    } else if (extension === 'mp3') {
      return mdiVolumeHigh;
    } else if (extension === 'yt') {
      return mdiYoutube;
    } else {
      return mdiFileDocument;
    }
  };

  const handleError = (ev) => {
    ev.target.src = brokenimage;
  };

  const test =() =>{
    console.log('view full')
  }

  if (items !== null && items !== undefined) {
    return (
      items &&
      items.map((itm) => (
        <div
          css={{
            display: 'inline-flex',
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: marginTop,
            position: 'relative',
            cursor: 'pointer',
            '&:hover': {
              boxShadow: showHover ? '0px 3px 6px #757575' : null,
              '& .deleteIcon': {
                opacity: 1,
                transition: 'all .3s ease',
              },
            },
          }}
          key={itm.guidname}
        >
          {['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(
            itm.guidname
              .split('.')
              .pop()
              .toLowerCase(),
          ) || itm.contentType === 'image/jpeg' ? (
            <img
              onClick={Action !== null ? () => Action(itm) : null}
              onError={handleError}
              alt="Delete image"
              title={itm.filename}
              src={itm.url}
              style={{
                maxWidth: maxWidth,
                height: maxWidth === null ? height : null,
                padding: itm.attributes !== undefined ? '1px' : null,
                border: itm.attributes !== undefined ? `2px solid ${itm.attributes.color}` : null,
              }}
            />
          ) : (
            <Icon
              onClick={Action !== null ? () => Action(itm) : null}
              title={itm.filename}
              style={{
                maxHeight: height,
                padding: itm.attributes !== undefined ? '1px' : null,
                border: itm.attributes !== undefined ? `2px solid ${itm.attributes.color}` : null,
              }}
              path={getFileIcon(itm.guidname.split('.').pop())}
              size="28px"
            />
          )}
          {showDeleteIcon ? (<>
            <div
              className="deleteIcon"
              css={{ position: 'absolute', opacity: 0, right: '-10px', top: '-10px' }}
              // onClick={Action !== null ? () => Action(itm) : null}
              onClick={deleteAction !== null ? () => deleteAction(itm) : null}
            >
              <Icon
                style={{ borderRadius: '100%', backgroundColor: '#616061' }}
                path={mdiClose}
                size="15px"
                color="white"
              />
            </div>
          </>) : null}
     
    
        </div>
      ))
    );
  } else {
    return null;
  }
};

export default ImageThumbnails;
