/* eslint-disable */
import React from "react";
import NativeListener from "react-native-listener";
import { BaseEditorComponent } from "@handsontable/react";
import {Input,Button,Form,TextArea} from 'semantic-ui-react';
import MessagesWindow from '../MessageView/MessagesWindow';

class MessageEditor extends BaseEditorComponent {
  
  state = {
    value: "",
    width:'125px',
    top:0,
    left:0,
    display:'none',
    dataID:0,
    number:''
  };

  componentDidMount(){
  
  }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.setState({display:'block'})
    // if(this.mainElementRef.current !== null){
    // this.mainElementRef.current.style.display = "block";  
    // }
  }


  close=()=> {
    const {display} = this.state;
    // if(this.mainElementRef.current) this.mainElementRef.current.style.display = "none";
    this.setState({display:'none'})

  }

  onBeforeKeyDown(event) {
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);
    const datarow = this.props.GetRowData(row);
    const messagefield = this.props.tableinfo.columns.filter((itm) => itm.uitype === 12)[0];
    

    // 9-13-23 We added support for allowing phonenumber field to be lookup field. Therefore, this data
    // is stored in array on grid. If array, grab data element, otherwise use field on table. 
    // 9-14-23 also now supporting altPhoneField, so number could come from either.
    let number=''
    let lookupNumber = datarow.data[messagefield.phonefield] ?? datarow.data[messagefield.altPhoneField] 

    if(Array.isArray(lookupNumber)) {
      number= lookupNumber[0].data
    } else {
      number= lookupNumber
    }

    let dataID=0;
   
    // console.log(datarow)
    
    if(datarow !==null)
    {
      dataID=datarow.dataID 
      this.setState({display:'none',dataID,tableid:this.props.tableid,number:number})
    }
    }


  updateText=()=> {
    const {value} = this.state;
    this.setState(
      (state, props) => {
        return { value};
      },
      () => {
        this.finishEditing();
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {value,width,tableid,dataID,display,number} = this.state;
    const {color,tableinfo} = this.props;
    return (
      <NativeListener onMouseDown={this.stopMousedownPropagation}  >
        {dataID !== 0 && this.props.isEditor && this.state.display==='block' ? (
        <div
          style={{display: this.state.display,
            position: "absolute",
            left: '300px',
            top: window.innerHeight * 0.1,
            background: "#fff",
            border: `.1px solid ${color}`,
            borderRadius:'10px',
            padding: "0px",
            // zIndex: 999,
            // overflowY:'auto',
            height:'calc(100vh - 200px)',
            width: 'calc(100vw - 500px)',
          }}
        >  
           <MessagesWindow display={display} number={number} role={this.props.role} color={color} close={this.close} tableid={tableid} dataID={dataID} tableinfo={tableinfo} />
        </div>
          ): <div></div>}
      </NativeListener> 
    );
  }
}

export default MessageEditor