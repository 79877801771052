/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiHistory,mdiArrowLeft } from '@mdi/js';
import Avatar from '../../../../components/Avatar/Avatar';
import TextButton from '../../../../components/TextButton/TextButton';

const History = ({tablename, details,changeControl}) => {

    const security = details.settings.security===0 ? 'General' : details.settings.security===1 ? 'Level 1' : 'Level 2'

    const historyarray = [...details.history];
   
    return (
        <div>
           <div style={{display:'flex',flexDirection:'row',alignItems:'center',margin:'10px'}}>
            {/* <TextButton text='' Action={changeControl} ActionValue="details" iconSize="20px" icon={mdiArrowLeft} textColor="#312602" hoverColor="#31260280" iconPosition='left' />  <Icon path={mdiHistory} size="25px" />&nbsp; Share history for Tab &nbsp; <u>{tablename}</u>&nbsp; (Shared : <u>{details.bookmarkName}</u>&nbsp; / &nbsp; Security &nbsp; <u>{security}</u>) */}
            <TextButton text='' Action={changeControl} ActionValue="details" iconSize="20px" icon={mdiArrowLeft} textColor="#312602" hoverColor="#31260280" iconPosition='left' />  <Icon path={mdiHistory} size="25px" />&nbsp; Share history for &nbsp; <b>{details.name}</b>
           </div>
           <hr/>
           <div style={{display:'flex', flexDirection:'row', alignItems:'center',margin:'10px'}}>
               <div style={{width:'180px'}}>Date</div>
               <div>Activity</div>
           </div>
           <div style={{overflow:'auto'}}>
            {historyarray.reverse().map(el => (
                el.type===0 ? (
                    <div key={el.date} style={{display:'flex', flexDirection:'row', alignItems:'center',margin:'10px',overflow:'auto'}}>
                    <div style={{minWidth:'180px'}}>{el.date}</div>
                    <div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><Avatar item={el.addedby} />{el.addedby.firstname} {el.addedby.lastname}  created link </div> 
                    {el.activity}
                    </div>
               </div>
                ): (
                    <div key={el.date} style={{display:'flex', flexDirection:'row', alignItems:'center',margin:'10px',overflow:'auto'}}>
                    <div style={{minWidth:'180px'}}>{el.date}</div>
                    <div>{el.activity}</div>
                    </div>
                )
            ))}
            </div>
        </div>
    )
}

export default History