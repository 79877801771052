/* eslint-disable */
import React from 'react';
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import {getBlocksAPI} from '../../../../api/blocks';
import {Dropdown} from 'semantic-ui-react';


class SystemList extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      renderResult: null,
      value: '',
      editMode: false,
      selectedItems: [],
      displayfield: '',
      workingItems: [],
      source:'',
      selectmode: '',
      uitype: '',
      left: 20,
      top: 30,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
      startingWidth:0,
      isExpanded: false,
      display: 'none',
      isChanged: false,
      blockid: 0,
      blocks: [],
    };
  }

  fetchTableData = async () => {

    const result = await getBlocksAPI()
    this.setState({blocks:result});
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.setState({ display: 'flex', isExpanded:false });
  }

  expand =(isExpanded,isCancel) => {
   
    this.setState({isExpanded, width: isExpanded ? '250px' : this.state.startingWidth})
    if(!isExpanded){
      if(isCancel){
        this.props.Cancel()
      } 
    }
  }

  close= ()=> {
  
    const tmp = this.state.selectedItems;
    // if(this.state.dataID !== this.props.GetRowData())

    this.setState({
      pickedColor: null,
      dataID: 0,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: [],
      workingItems: [],
      value: null,
    });

    // this.finishEditing();

  }

   prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);
    // const { displayfield } = this.state;
    // td.innerHTML="<td>Hello</td";

    const item = cellProperties.data;
    const tdPosition = td.getBoundingClientRect();
    // console.log(originalValue)

    if(this.state.blocks.length===0) {
        this.fetchTableData()
    }

    this.setState({
    //   blockid: originalValue.blockid,
      selectedItems: originalValue,
    //   blockid: this.props.blockid,
      displayfield: cellProperties.data,
      selectmode: cellProperties.selectmode,
    //   value: selectItems,
      uitype: cellProperties.uitype,
      width: cellProperties.width-3,
      height: this.props.cellHeight,
      startingWidth: cellProperties.width,
      editMode:true,
      // dataID: this.props.GetRowData(row),
      left: `${tdPosition.left+1}px`,
      top: `${tdPosition.top+2}px`,
    });
    

  }

  updateItems =(event) => {
 
    const name = this.state.blocks.filter(itm =>itm.value===parseInt(event.target.value))[0].text
    const updatedBlock ={blockid:parseInt(event.target.value), name}
    this.setState({value:updatedBlock})

    if(this.state.selectmode==='single')
    {
      this.setState(
        () => {
          return this.state.value;
        },
        () => {
          this.finishEditing();
        },
      );
    }
  }

  render() {
    let renderResult = null;
    if (this.state.editMode) {
      renderResult = (
        <NativeListener onMouseDown={this.stopMousedownPropagation}>
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              backgroundColor: 'white',
              flexDirection:'column',
              marginLeft:'1px',
              marginRight:'1px',
              // borderTopLeftRadius: '10px',
              // borderTopRightRadius: '10px',
              left: this.state.left,
              top: this.state.top,
              width: this.state.width,
              height: this.state.height,
              display: this.state.display,
              overflow: this.state.isExpanded ? 'auto' : 'hidden',
            }}
          >
          <select style={{height:'30px',border:'0px'}} value={this.state.selectedItems.blockid} onChange={this.updateItems}>
            {this.state.blocks.length>0 ? this.state.blocks.map(itm => (
               <option key={itm.key} value={itm.key}>{itm.text}</option>
                )) : null}
            </select>

          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        background: this.props.value,
        width: this.state.width,
        // height: this.state.height,
        float: 'left',
        marginRight: '5px',
      };

      renderResult = (
        <>
          <div style={colorboxStyle} />
          <div>{this.props.value && this.props.value.name}</div>
        </>
      );
    }

    return <>{renderResult}</>;
  }
}

export default SystemList;
