/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import SelectedUserItem from '../../../../components/UserDropdown/SelectedUserItem'

const UserRender = ({ value, cellWidth, cellHeight }) => {
  const TagStyle = {
    width: cellWidth,
    height: cellHeight - 7,
    float: 'left',
    marginRight: '5px',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
  };

  const createtags = () => {
    if (value !==null && value !==undefined) {
      return (
            <SelectedUserItem item={value} backgroundColor={null} color={value.attributes.text} Action={null} />
          )
    }
        return '';
  };

  return <div>{createtags()}</div>;
};


export default UserRender;
