
/* eslint-disable */
import React, { useRef,useEffect,useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import TextButton from '../../../../components/TextButton/TextButton';
import {mdiClose} from '@mdi/js';

const BookmarkBar = ({color,bookmarks,hide,loadBookmark,currentBookMarkId}) =>{
    
    const [options, setOptions]= useState([]);
    const [showMore, setShowMore] = useState(false);
    const topBar = useRef(null);
    const textColor='#312602'

    useEffect(() => {

        var hasHorizontalScrollbar = topBar.current.scrollWidth >topBar.current.clientWidth;
        setShowMore(hasHorizontalScrollbar);
       
        let opt=[];
        bookmarks.map(el=>{
            opt.push({key:el.bookmarkid,value:el.bookmarkid,text:el.name})
        })
        setOptions(opt);
    },[bookmarks])


    const runBookmark = async (bookmarkid) =>{
        const currentBookmark = bookmarks.filter(el=>el.bookmarkid===bookmarkid)[0];
        await loadBookmark(bookmarkid,currentBookmark.tabsettings);
    }

    return(
        <div style={{display:'flex', width:'100%',  flexDirection:'row', alignItems:'center',justifyContent:'space-between',backgroundColor:`${color}1A`,height:'25px',padding:'5px',paddingRight:'5px'}}>
      
            <div ref={topBar} style={{fontSize:'15px',display:'flex',color:{textColor}, overflow:'hidden', flexDirection:'row', alignItems:'center',whiteSpace:'nowrap',width:'calc(100vw - 400px)'}}>
                Bookmarks bar:
            {bookmarks.map(bookmark => (
                <div key={bookmark.bookmarkid} style={{marginLeft:'15px',whiteSpace:'nowrap' }}>
                <TextButton backgroundColor={bookmark.bookmarkid===currentBookMarkId ? color : null} marginLeft="5px" backgroundHoverColor='white' hoverColor={color}  text={bookmark.name} textColor={bookmark.bookmarkid===currentBookMarkId ? 'white': textColor} Action={runBookmark} ActionValue={bookmark.bookmarkid} />
                </div>
            ))}
            </div>
            {options.length>0 && showMore? (
            <Dropdown
            onChange={(e,data) => runBookmark(data.value)}
            direction='left' trigger={<></>} options={options} floating />
            ):null}
            <div style={{marginRight:'10px'}}>
            <TextButton text='Hide bar' marginLeft="5px" textColor='black' hoverColor={color} backgroundHoverColor='white'  Action={hide} icon={mdiClose} />
            </div>
          
        </div>
    )
}

export default BookmarkBar