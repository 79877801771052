/* eslint-disable */
import React, {useState,useContext} from 'react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import {Checkbox } from 'semantic-ui-react';
import { mdiFormatPageBreak, mdiContentCopy} from '@mdi/js';

const Pagination=({color,blockKey,tablename}) => {

    const { REACT_APP_API_URL } = process.env;

    const copyToClipboard = (content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success(<div style={{margin:'10px'}}> 
        Url Copied!
     </div>, {
         position: toast.POSITION.BOTTOM_CENTER,
         autoClose: 2000,
       });
      };

    const url =  `${REACT_APP_API_URL}/v1/${blockKey}/${encodeURIComponent(tablename)}?limit=50&starting_after=100`
    const url2 = `${REACT_APP_API_URL}/v1/${blockKey}/${encodeURIComponent(tablename)}?limit=50`

    return (
        <div style={{marginTop:'50px', display:'flex',flexDirection:'row', alignItems:'flex-start',scrollMargin: '20px'}} id='paging'>
            <div style={{width:'50%'}} >
                <div style={{marginLeft:'10px', display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <Icon path={mdiFormatPageBreak} size="30px" color={color} />
                    <span style={{fontSize:'25px', color:color,marginLeft:'10px'}}>Pagination</span>
                </div>
                <div style={{margin:'10px'}}>
                  The vast majority of the API responses containing a collection of records will be paginated. When pulling records, use these two parameters to control how you receive the results: <b>limit</b> and <b>starting_after</b>.
                  <p/><p/><p/><p/>
                  <span style={{color:color,fontSize:'20px'}}>The limit parameter</span>
                  <p/><p/>
                   By default, GraceBlocks will paginate using a limit of 10 records per page. Use the limit parameter to set a different number of records per page.  In the code example to the right, the records per page has been limited to 50.
                   <p/><p/><p/><p/>
                   <span style={{color:color,fontSize:'20px'}}>The starting_after parameter</span>
                   <p/><p/>
                   GraceBlocks utilizes cursor-based pagination to take an existing record System ID value and returns records in reverse chronological order. The starting_after parameter returns records listed after the value defined in this parameter.
                   In the example to the right, records starting after record 100 in the result set will be returned, showing 50 items per page.

                </div>
            </div>

            <div style={{width:'50%'}}>
            <div style={{backgroundColor:'black', color:'white',margin:'10px',borderRadius:'10px',paddingBottom:'20px'}}> 
                <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between',  padding:'10px', height:'40px', backgroundColor:'#41355C', color:'white',borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
                    <div>limit</div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <div style={{cursor:'pointer'}} onClick={()=>copyToClipboard(url)}>
                           <Icon path={mdiContentCopy} size="15px" color="white" />
                           <span style={{marginLeft:'5px'}}>Copy to clipboard</span>
                        </div>
                    </div>
                </div>
                <div style={{margin:'20px'}}>
                    <span style={{color:'grey'}}>EXAMPLE</span>
                    <p/>
                    $ curl {url2}
              </div>
            </div>
            <div style={{backgroundColor:'black', color:'white',margin:'10px',borderRadius:'10px',paddingBottom:'20px'}}> 
                <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between',  padding:'10px', height:'40px', backgroundColor:'#41355C', color:'white',borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
                    <div>starting_after</div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <div style={{cursor:'pointer'}} onClick={()=>copyToClipboard(url2)}><Icon path={mdiContentCopy} size="15px" color="white" />
                        <span style={{marginLeft:'5px'}}>Copy to clipboard</span>
                        </div>
                    </div>
                </div>
                <div style={{margin:'20px'}}>
                    <span style={{color:'grey'}}>EXAMPLE</span>
                    <p/>
                    $ curl {url}
              </div>
            </div>
            </div>

         


        </div>
    )
}

export default Pagination