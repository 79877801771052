/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

const UpdateRows = async (schema, tableid, dataValues, tableinfo, userid,updateGrid,RefreshRows,updateRowID) => {
  
  let payload={};

  // console.log(dataValues)

  // 12-7-2021 check if a new row is included in drag/copy set of data. If no, generate the id in table
  // before processing updates..

  const idx = dataValues.findIndex(el=>el.id===null);
  if(idx !==-1) {
    const table=`tbl_${tableid}`;
    payload={table,userid,dbfunction:'insertTableEntry'}
    const result = await cloudrun.post('/executeQueryAPI',{payload})
    const newid= result[0].id
    const row = dataValues[idx].row;
    
    await updateRowID(row,newid,false);

    dataValues.forEach(itm=> {
      if(itm.id===null){
        itm.id = newid;
        itm.isNew=true
      }
    })
  }

  const ids = dataValues.map(el=>el.id);

  payload={tableid,dataValues,tableinfo,userid,dbfunction:'multiRowUpdates'}
  const dataresponse = await cloudrun.post('/multiRowUpdates', {payload})
  if(updateGrid) {
    await RefreshRows(ids)
  }
  return "success";
};

export default UpdateRows;
