import React from 'react';
import { Redirect } from '@reach/router';
import Router from '../../components/Router';
import Home from '../Home';
import BlockPage from '../Block';
import ApiPage from '../ApiDocs';
import WorkFlowPage from '../Workflow'
import SharePage from '../Block/components/Shares'
import RecordPage from '../Block/components/Record';
import ZoneDashboardPage from '../ZoneDashboard';
import AccountProvider from '../../providers/AccountProvider';
import { DASHBOARD, ZONE_DASHBOARD, REDIRECTION } from '../../constants/routes';

const Dashboard = () => {
  return (
    <AccountProvider>
      <Router>
        <Home path={DASHBOARD.HOME} />
        <ZoneDashboardPage path={ZONE_DASHBOARD.CHILDREN} />
        <BlockPage path={DASHBOARD.BLOCK} />
        <ApiPage path={DASHBOARD.APIDOCS} />
        <SharePage path={DASHBOARD.SHARE} />
        <WorkFlowPage path={DASHBOARD.WORKFLOW} />
        <RecordPage path={DASHBOARD.RECORD} />
        <Redirect noThrow from="*" to={REDIRECTION.DASHBOARD} />
      </Router>
    </AccountProvider>
  );
};

export default Dashboard;
