/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import {
  mdiPaperclip,
  mdiTransitConnectionVariant,
  mdiChatProcessing,
  mdiAccountMultiple,
  mdiDetails,
  mdiArrowLeft,
  mdiChevronLeft,
  mdiChevronRight
} from '@mdi/js';
import ActionButton from '../ActionBar/ActionButton';
import TextButton from '../../../../components/TextButton/TextButton';

/* 6-8-2022
This control is used on the Share when we detect mobile device (width<800). In thise case, it will show
the nav controls to the action detail components(attachments, messages, related records, collaboration,history,etc)

IN ADDITION, in this use-case, we turn off the topnavbar in the details view because we need to position the record
name and back arrow ABOVE these action icons, but the current actionpanel's topnavbar is in line with the components.
So, in this ccase, we will put nav bar to show the recordname and the back arrow button to mimic this functionalty.
*/

const TopNav = ({
  color,
  hasMessages,
  hasAttachments,
  hasRelated,
  canCollaborate,
  canViewComments,
  control,
  changeControl,
  heightInfo,
  RID,
  hide,
  icon,
  recordname,
  activeRow,
  filterCount,
  NextPrev
}) => {


  return (
    <div
      style={{
        position: 'absolute',
        top: hide !== null ? 0 : heightInfo.top-1,
        left: 0,
        minHeight: '100px',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 100,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent:'space-between',
          backgroundColor: color,
          height: '50px',
          width: '100vw',
          color: 'white',
          padding: '5px',
          fontSize: '20px',
        }}
      >
        <div style={{display:'flex', marginTop:'5px',flexDirection:'row',whiteSpace:'noWrap',height:'50px', overflowY:'auto',width:'100%',alignItems:'center'}}>
        {hide !== null ? (
                    
            <TextButton
              fontSize="20px"
              iconSize="25px"
              hoverColor="#FFFFFF80"
              icon={mdiArrowLeft}
              text={RID}
              iconPosition="left"
              Action={hide}
            />
     
        ) : (
          <>
            <Icon path={icon} size="25px" />
            &nbsp;
            {recordname}: {RID}
          </>
        )}
        </div>
        {/* <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>Right side</div> */}
        <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
        {activeRow > 0 ? (
              <ActionButton
                label="Prev"
                actionIcon={mdiChevronLeft}
                Action={activeRow > 0 ? NextPrev : null}
                ActionValue="prev"
                color={color}
                height="40px"
                textHoverColor={'#FFFFFF80'}
                marginBottom="0px"
                baseColor={"white"}
                isSelected={false}
                invertColors={false}
                isMobile ={true}
              />): null}
              {activeRow+1 < filterCount ? (
              <ActionButton
                label="Next"
                actionIcon={mdiChevronRight}
                Action={NextPrev}
                height="40px"
                ActionValue="next"
                marginBottom="0px"
                marginRight={'10px'}
                marginLeft={'10px'}
                color={color}
                baseColor={"white"}
                textHoverColor={'#FFFFFF80'}
                isSelected={false}
                invertColors={false}
                isMobile ={true}
              />): null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '5px',
          marginLeft: '10px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ActionButton
          marginBottom="0px"
          label="Details"
          actionIcon={mdiDetails}
          Action={changeControl}
          ActionValue="details"
          showBottom={false}
          color={color}
          isSelected={control === 'details'}
        />

        {hasAttachments ? (
          <ActionButton
            marginBottom="0px"
            label="Attachments"
            actionIcon={mdiPaperclip}
            Action={changeControl}
            ActionValue="attachments"
            showBottom={false}
            color={color}
            isSelected={control === 'attachments'}
          />
        ) : null}
        {hasRelated ? (
          <ActionButton
            marginBottom="0px"
            label="Related records"
            actionIcon={mdiTransitConnectionVariant}
            Action={changeControl}
            ActionValue="datalist"
            showBottom={false}
            color={color}
            isSelected={control === 'datalist'}
          />
        ) : null}
        {hasMessages ? (
          <ActionButton
            marginBottom="0px"
            label="Messages"
            actionIcon={mdiChatProcessing}
            Action={changeControl}
            ActionValue="messages"
            showBottom={false}
            color={color}
            isSelected={control === 'messages'}
          />
        ) : null}
        {canCollaborate || canViewComments ? (
        <ActionButton
          marginBottom="0px"
          label="Collaboration"
          actionIcon={mdiAccountMultiple}
          Action={changeControl}
          ActionValue="collaborators"
          showBottom={false}
          color={color}
          isSelected={control === 'collaborators'}
        />
        ): null}
      </div>
    </div>
  );
};

export default TopNav;
