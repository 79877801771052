import React from 'react';
import PropTypes from 'prop-types';
// import { mdiPlusCircle, mdiPlusCircleOutline, mdiClose } from '@mdi/js';
import FinalForm, { FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import { ArrowLeftIcon} from '../../../components/Icon';
// import Icon, { ArrowLeftIcon, EmailIcon } from '../../../components/Icon';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';

const formValidators = {
  // name: validators.composeValidators(validators.required),
  email: validators.composeValidators(validators.email),
};

// used with collab
// const init = [{ email: '' }];

const ZoneSetup = ({ onSubmit, onBack, loading, initialValues }) => {
  return (
    <>
      <Button variant="clear" className="back-button" onClick={onBack}>
        <ArrowLeftIcon />
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img src={LogoIcon} alt="Logo" />
      <FormCard title="ZONE SETUP">
        <FormCard.Description className="text-left mb-4">
          Set up your first GraceBlocks work area, known as a Zone, and invite others to collaborate
          with you here. Or just click Finish and get started!
        </FormCard.Description>
        <FinalForm
          onSubmit={onSubmit}
          mutators={FinalForm.arrayMutators}
          initialValues={initialValues}
        >
          {() => {
            // console.log(args);
            return (
              <>
                <FormField
                  name="name"
                  label="Zone name"
                  placeholder="Give your work area inside GraceBlocks a name."
                  validate={formValidators.name}
                  controlId="id_email"
                  maxLength="20"
                />

                {/* <FormCard.Subtitle className="py-4">ZONE SHARING</FormCard.Subtitle> */}
                {/* <FinalForm.FieldArray name="collaborators" initialValue={init}>
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <div key={name} className="position-relative">
                          <FormField
                            name={`${name}.email`}
                            label="Email"
                            placeholder="Enter collaborator email address."
                            validate={formValidators.email}
                            leftIcon={<EmailIcon />}
                            rightIcon={
                              index ? (
                                <Icon path={mdiClose} color="#E06055" size={0.65} />
                              ) : (
                                undefined
                              )
                            }
                            controlId="id_email"
                            onRightClick={() => fields.remove(index)}
                          />
                        </div>
                      ))}
                      <div className="text-left pt-2 pl-2">
                        <Button
                          type="button"
                          size="sm"
                          variant="dotted"
                          onClick={() => fields.push()}
                        >
                          <Icon path={mdiPlusCircle} className="svg-active" />
                          <Icon path={mdiPlusCircleOutline} className="svg-default" />
                          Add collaborator
                        </Button>
                      </div>
                    </>
                  )}
                </FinalForm.FieldArray> */}
                <div className="py-4" />
                <Button long type="submit">
                  Finish
                </Button>
              </>
            );
          }}
        </FinalForm>
      </FormCard>
      {loading && <Loader />}
    </>
  );
};

ZoneSetup.defaultProps = {
  onSubmit: () => {},
  onBack: () => {},
  loading: false,
  initialValues: {},
};

ZoneSetup.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ZoneSetup;
