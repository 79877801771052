/* eslint-disable */
import React from 'react';
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import { Input, Button, Form, TextArea } from 'semantic-ui-react';

class TextEditor extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      width: '125px',
      top: 30,
      left: 0,
      display: 'none',
      expandDown: false,
    };

    this.mainElementRef = React.createRef();
    this.textAreaInput = React.createRef();
  }

  componentDidMount() {
    this.textAreaInput.current.focus();
  }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.setState({ display: 'block', isExpanded: false });
    this.textAreaInput.current.focus();
  }

  close() {
    this.setState({ display: 'none', isExpanded:false });
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const tdPosition = td.getBoundingClientRect();
    const halfHeight = window.innerHeight * 0.5;
    const expandDown = tdPosition.y < halfHeight;

    this.setState({
      width: cellProperties.width,
      value: originalValue,
      expandDown,
      top: `${tdPosition.top + 2}`,
      left: `${tdPosition.left+1}px`
    });

    // As the `prepare` method is triggered after selecting
    // any cell, we're updating the styles for the editor element,
    // so it shows up in the correct position.
  }

  // updateText = () => {
  //   const { value } = this.state;
  //   this.setState(
  //     (state, props) => {
  //       return { value };
  //     },
  //     () => {
  //       this.finishEditing();
  //     },
  //   );
  // };


  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const { value, width } = this.state;
    return (
      <NativeListener
        onMouseDown={this.stopMousedownPropagation}
        onKeyDown={this.stopMousedownPropagation}
      >
        <div
          style={{
            display: this.state.display,
            position: 'absolute',
            left: this.state.left,
            top: !this.state.expandDown ? `${this.state.top-200}px` : `${this.state.top}px`,
            background: '#fff',
            border: '0px solid #000',
            padding: '0px',
            zIndex: 999,
            width: '125px',
          }}
          id="editorElement"
        >
          <Form>
            <TextArea
              ref={this.textAreaInput}
              style={{ width, height: '200px' }}
              onChange={(e, data) => this.setState({ value: data.value })}
              value={value !== null ? value : ''}
              placeholder="Enter Text"
            />
          </Form>
        </div>
      </NativeListener>
    );
  }
}

export default TextEditor;
