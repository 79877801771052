import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import zxcvbn from 'zxcvbn';
import ControlTooltip from '../../ControlTooltip';
import FormControl from '../Form.Control';
import { KeyIcon, EyeIcon, EyeOffIcon } from '../../../Icon';

// https://github.com/dropbox/zxcvbn

const FormPasswordControl = ({ className, showLevelTooltip, value, meta, ...props }) => {
  const [inputType, updateType] = useState('password');

  const toogleInput = useCallback(() => {
    updateType((prev) => {
      return prev === 'password' ? 'text' : 'password';
    });
  }, [updateType]);

  const { active, invalid, valid } = meta;
  const showErrorTooltip = active && invalid;
  const level = zxcvbn(value || '').score;
  return (
    <>
      {showLevelTooltip && (
        <>
          {showErrorTooltip && <ControlTooltip invalid />}
          {valid && value && level <= 2 && <ControlTooltip text="Weak" color="#FFA88D" />}
          {valid && value && level === 3 && <ControlTooltip text="Medium" color="#669CF6" />}
          {valid && value && level === 4 && <ControlTooltip text="Strong" />}
        </>
      )}
      <FormControl
        {...props}
        type={inputType}
        value={value}
        className={classNames(className, 'input-group-password')}
        leftIcon={<KeyIcon />}
        rightIcon={inputType === 'text' ? <EyeOffIcon color="#757575" /> : <EyeIcon size={1} />}
        onRightClick={toogleInput}
      />
    </>
  );
};

FormPasswordControl.defaultProps = {
  value: undefined,
  className: null,
  showLevelTooltip: false,
  meta: {},
};

FormPasswordControl.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  showLevelTooltip: PropTypes.bool,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default FormPasswordControl;
