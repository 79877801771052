/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';
import ImageUploader from '../../components/ImageUploader';

const BannerPlaceholder = ({banner,deleteImage,updateHeaderImages,color}) => {
    return (
        <div>
           {banner !== undefined ? (
                    <div
                      css={{
                        position: 'absolute',
                        zIndex: 50,
                        top: 10,
                        right: 10,
                        color: 'white',
                        borderRadius: '100%',
                        backgroundColor: '#E06055',
                        '&:hover': {
                          svg: {
                            backgroundColor: 'white',
                            color: '#E06055',
                            borderRadius: '100%',
                            transition: '.5s ease all',
                          },
                        },
                      }}
                    >
                      <Icon
                        onClick={() => deleteImage('banner')}
                        path={mdiCloseCircle}
                        size="20px"
                      />
                    </div>
                  ) : (
                    <div style={{ position: 'absolute', zIndex: 50, top: 0, right: 0 }}>
                      <ImageUploader
                        type="banner"
                        saveImage={updateHeaderImages}
                        addText="Add image"
                        existingFile={banner}
                        color={color}
                      />
                    </div>
                  )}
        </div>
    )
}

export default BannerPlaceholder