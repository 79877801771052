import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ children }) => (
  <h3 id="content-title" className="title font-weight-bold">
    {children}
  </h3>
);

Title.defaultProps = { children: null };

Title.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Title;
