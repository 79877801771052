import React from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import classNames from 'classnames';
import { mdiChevronDown } from '@mdi/js';
import Icon from '../../../Icon';
import './styles.scss';

const FormSelectControl = ({
  className,
  placeholder,
  name,
  options,
  value,
  hasEmpty,
  color,
  ...props
}) => {
  return (
    <>
      <div className={classNames('input-select-arrow', 'input-group', className)}>
        <div className="input-group-prepend input-group-left-padding" style={{ background: color }} />
        <BootstrapForm.Control
          as="select"
          size="lg"
          name={name}
          className="input-select"
          required={value === ''} // Solution for placeholder.
          value={value}
          {...props}
        >
          <option value="" key={placeholder} className="empty-option" hidden={!hasEmpty}>
            {placeholder}
          </option>
          {options.map(({ value: v, label }) => (
            <option key={v} value={v} className="presented-option">
              {label}
            </option>
          ))}
        </BootstrapForm.Control>
        <span>
          <Icon path={mdiChevronDown} size={0.75} />
        </span>
      </div>
    </>
  );
};

FormSelectControl.defaultProps = {
  label: null,
  placeholder: '',
  options: [],
  value: '',
  className: null,
  hasEmpty: false,
};

FormSelectControl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string || PropTypes.number,
  className: PropTypes.string,
  hasEmpty: PropTypes.bool,
};

export default FormSelectControl;
