/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component,useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { css, jsx } from '@emotion/core'
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'semantic-ui-react';
import { mdiDragVertical, mdiPlus,mdiSortAlphabeticalAscending} from '@mdi/js';
import Icon from '@mdi/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { AccountContext } from '../../../../providers/AccountProvider';
import OptionColorPicker from './OptionColorPicker';
import { getOptionsAPI, updateOptionsAPI } from '../../../../api/options';
import OptionItem from './OptionItem';
import { updateTableInfoAPI, getTableAPI } from '../../../../api/tables';
import {GetUniqueFieldValues, MapTextValuesToOptionValues} from '../../../../api/GetData';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Colors from '../../../../utils/GBColors';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import Global from '../../../../clients/global'

class OptionSelect extends Component {
  grid = 4;
  
  // eslint-disable-next-line react/sort-comp
  // eslint-disable-next-line react/static-property-placement
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    const { columnConfigData } = this.props;
    this.state = {
      items: [],
      deletedItems: [],
      modalIsOpen: false,
      selectedItem: {},
      x: 0,
      y: 0,
      showAdvanced: false,
      enableColors: true,
      callout: columnConfigData.callout ? columnConfigData.callout : '',
      defaultText: columnConfigData.defaultText,
      isEditable: true, // added 7/1/2020..when converting from text/other field to single selecte, we will import options in, but must not allow them to be editable.
      ZoneToUse: {}, // Added 11-30-2020 to get current zone plan and user role. Used to determine if they can add custom colors.
      isValid: true, // used when an a text field is being converted to option field. Will become invalid if unqieu items > 100, don't let them save in this state.
      isPrivate: columnConfigData.isPrivate ?? false
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  
  message=<div style={{margin:'10px'}}>
  Oops, too many unique options identified! We can not convert this field. Is is recommended that you de-duplicate these values and add them as a Tab. Then you can convert this field to a relational field instead. <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/field-type-relational" target="_blank">Learn more</a>.
</div>

  async componentDidMount() {
    const { columnConfigData,updateHeight,hasData } = this.props;
    const isrunning=false

    const { userInfo} = this.context;
    const zoneList = [...userInfo.zones];
    const tempZone = zoneList.filter(itm=>itm.id===parseInt(Global.zoneid))[0];
   
    // Fetch current list of items for this field/table if this is already a option table.
    if (columnConfigData.renderer === 'DisplayMultiple' ) {
      await this.FetchOptions();
    }

    // if we are coming from non option field, except for date field, load unique values from current column and create options.
    if(columnConfigData.uitype !==6 && columnConfigData.uitype !==7 && columnConfigData.uitype !==22 && hasData)
    {
      await this.LoadUniqueTextValues()
      this.setState({isEditable:false,ZoneToUse:tempZone})
    } else {
      this.setState({ZoneToUse:tempZone})
    }

    updateHeight(600);
  }

  // If we are coming from a non-optional field, grab unique values and load into options. 
  LoadUniqueTextValues = async () => {
    const {tableid,columnConfigData,ShowError} = this.props;
    const schema = `schema_${Global.zoneid}`;
    const table =`tbl_${tableid}`;

    const datavalues = await GetUniqueFieldValues(schema,table,columnConfigData.data)
    if(datavalues.error !==undefined)
    {
     
      ShowError(this.message)
      this.setState({isValid:false})
    } else {
      datavalues.forEach(itm => {
        if(itm.item !== null && itm.item !=='') {
        this.addOption(itm.item)
        }
      })
  }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    width: 250,
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    width: '300px',
    padding: 2,
    margin: `0 0 ${this.grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getAlert() {
    this.alert('getAlert from Child');
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  updateItem = async (optionid, newvalue,isAddOption) => {
    const { items } = this.state;
    const tmpitems = [...items];
    tmpitems.filter((el) => el.optionid === optionid)[0].option = newvalue;

    this.setState({ items: tmpitems })
    // 6-23-2020 . Added param "isAddOption". When editing a list of items, if user clicks off item, it will
    // update item only. However, if they click "enter" it should save and then add new option.
    if(isAddOption){

      // 6-23-2020 use findindex to get current spot, then pass index+1 to addoption so new
      // option gets inserted right below the item you just edited.
      const rowIndex = tmpitems.findIndex(itm => itm.optionid===optionid)
      this.addOption('', rowIndex+1)
    }
  };

  // Adds a new option to array. We prefix the option with "id" to ensure
  // it is unique versus the retrieved id's.
  addOption = (option='',insertAt) => {
    const { items } = this.state;
    const newitems =  [...items]
    const randomid = uuidv4();
    const colorid = Math.floor(Math.random() * Math.floor(28));
    // const shortOption = option.slice(0,255)
    const newitem = {
      optionid: `id${randomid.toString()}`,
      option,
      attributes: { color: Colors[colorid].color, text: Colors[colorid].text },
    };
    
    // const tmpitems = newitems.concat(newitem);
    newitems.splice(insertAt !== undefined ? insertAt : items.length,0,newitem);
    // console.log(newitems)
   
    this.setState({ items: newitems });
  };

  updateOptionColor = (optionid, color, textcolor) => {
    const copyofItems = [...this.state.items];
    const rowIndex = copyofItems.findIndex((el) => el.optionid === optionid);
    if (rowIndex !== -1) {
      copyofItems[rowIndex].attributes = { color, text: textcolor };
      this.setState({ items: copyofItems, modalIsOpen: false });
    } else {
      this.setState({ modalIsOpen: false });
    }
  };

  deleteOption = (optionid) => {
    const { items, deletedItems } = this.state;
    const filteredItems = [...items].filter((el) => el.optionid !== optionid);
    const tempDeletedItems = [...deletedItems];
    if(typeof optionid ==='number')
    {
      tempDeletedItems.push(optionid)
    }

    this.setState({ items: filteredItems, deletedItems: tempDeletedItems });

  };

  pickColor = (e, item) => {
    this.setState({ modalIsOpen: true, x: e.clientX, y: e.clientY, selectedItem: item });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    // document.body.style.overflow = this.state.originalBodyOverflow;
  };

  FetchOptions = async () => {
    const { columnConfigData,tableid } = this.props;

    if(this.state.items.length===0) {
    const options = await getOptionsAPI(columnConfigData.data,tableid);

    this.setState({
      items: options,
      enableColors:
        columnConfigData.enableColors !== undefined ? columnConfigData.enableColors : true,
    });
  }
  
  };

  MapExistingTextToOptions = async () => {
    const {tableid,columnConfigData}=this.props;
    const schema = `schema_${Global.zoneid}`
    const table=`tbl_${tableid}`;

    await MapTextValuesToOptionValues(schema,table,columnConfigData.data)

  }

  SortAlphaOptions = () => {
    const { items } = this.state;
    items.sort((a, b) => (a.option > b.option ? 1 : -1));
    this.setState({ items });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
   
    const { columnConfigData, tableid, uitype, header,ShowError } = this.props;
    const { callout, enableColors,isValid,defaultText,isPrivate } = this.state;

    if (defaultText !== undefined) {
      const payload = { field:columnConfigData.data, tableid, defaultText, uitype };
      await cloudrun.post('/updateFieldDefaultText', { payload });
    }

    if(!isValid) {
      // ShowError(this.message)
      return false;
    }

    const cleanItems = this.state.items.filter(el=>el.option !=='')
    const originalUIType = columnConfigData.uitype;
  
    try {
    // Save options
    await updateOptionsAPI(
      cleanItems,
      this.state.deletedItems,
      columnConfigData.data,
      tableid,
      columnConfigData.uitype,
      uitype,
    );

    //  Save callout & enableColors attributes
    const {tableinfo} = await getTableAPI(tableid);

    const rowindex = tableinfo.columns.findIndex((el) => el.data === columnConfigData.data);
    if (rowindex !== -1) {
      tableinfo.columns[rowindex].header = header;
      tableinfo.columns[rowindex].uitype = uitype;
      
      // tableinfo.columns[rowindex].callout = callout;
      // tableinfo.columns[rowindex].defaultText = defaultText;
      if(callout === '')
      {
        delete tableinfo.columns[rowindex].callout;
      } else{
        tableinfo.columns[rowindex].callout = callout;
      }
      if(defaultText==='')
      {
        delete tableinfo.columns[rowindex].defaultText;
      } else {
        tableinfo.columns[rowindex].defaultText = defaultText;
      }

      if(isPrivate) {
        tableinfo.columns[rowindex].isPrivate =true;
      } else {
        delete tableinfo.columns[rowindex].isPrivate;
      }

      tableinfo.columns[rowindex].lookup = uitype===6 ? 'multiple' : 'single';
      tableinfo.columns[rowindex].enableColors = enableColors;
      delete tableinfo.columns[rowindex].type
      delete tableinfo.columns[rowindex].concat; // 8-14-2020 remove concats so field is not in record identifier.

      // if we are coming from numeric field, convert numeric back to text.
      if(originalUIType===23)
      {
        const payload = { tableid, tableinfo, field: columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_numeric_to_text' };
        await cloudrun.post('/convertField', { payload })
      } else if (originalUIType===22) // coming from a date field
      {
        const payload = { tableid, tableinfo, field:columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
      }  else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field:columnConfigData.data, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
      }

      // We don't map date fields.
      if(originalUIType !==22 && originalUIType!==6 && originalUIType !==7)
      {
        await this.MapExistingTextToOptions();
      }

      await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
    }
    return "success"
  } catch (error) {
    console.log(error.message)
    return `An error occurred: ${error.message}`
  }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  enableColorHandler = () => {
    const {enableColors} = this.state;
    this.setState({enableColors: !enableColors})
  }

  render() {
    const { showAdvanced, enableColors, callout,isEditable,ZoneToUse,defaultText,isPrivate} = this.state;
    const {color,ShowError} =this.props;
    return (
      <div>
       {/* <input autoFocus style={{width:'0px', outline:'none', border:'0px'}} /> */}
        {isEditable ? (<>
        <div>
          Enter possible values below. Users will select one or more values from the pre-defined list. Use
          the icon to drag and reorder how the options will appear in the dropdown. Use the icon to
          choose a color for the value. 
          </div>
          <div>
          Use the <Icon path={mdiDragVertical} size="15px"></Icon> icon to drag and reorder how the options will appear in the dropdown. Use the circle icon 
          to choose a color for the value. 
        </div></>
        ) : (
          <div style={{display:'inline'}}>
             <div>
          Use the <Icon path={mdiDragVertical} size="15px"></Icon> icon to drag and reorder how the options will appear in the dropdown. Use the circle icon 
          to choose a color for the value. 
        </div><p></p>
            <div style={{ display:'inline', marginTop: '5px',marginTop:'20px', fontWeight:'bold', fontStyle:'italic' }}>
            Existing text will transform into the following list of values for users to select.
            These values can not be edited right now but their color and placement is editable. Once
            saved the values themselves will also be editable.
            </div>
          </div>
        )}

        {/* <div onClick={this.MapExistingTextToOptions}>Run test</div> */}

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.optionid} draggableId={item.optionid.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <OptionItem
                          option={item}
                          isEditable={isEditable}
                          pickColor={this.pickColor}
                          updateItem={this.updateItem}
                          enableColors={enableColors}
                          deleteOption={this.deleteOption}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div>
          {this.state.modalIsOpen ? (
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                position: 'relative',
                width: '425px',
                height: '250px',
                // top:this.state.y - 50,
                top: this.state.y - 150,
                left: this.state.x - 220,
                background: '#fff',
                overflow: 'auto',
                zIndex: '500',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '15px',
                boxShadow: '5px 10px #888888',
                outline: 'none',
                padding: '10px',
              },
            }}
            contentLabel="Option Color Picker"
          >
            <OptionColorPicker
              activeZone={ZoneToUse}
              OptionData={this.state.selectedItem}
              color={this.props.color}
              ShowError={ShowError}
              UpdateColor={this.updateOptionColor}
              close={this.closeModal}
              component="Options"
            />
          </Modal>
          ): null}
        </div>
          {isEditable ? (
        <div css={{display:'flex', flexDirection:'row', alignItems:'center', width:'155px',paddingLeft:'5px',cursor: 'pointer', marginLeft: '15px','&:hover': {backgroundColor: '#F0EFEF',borderRadius:'10px'} }}>
          <Icon path={mdiPlus} size="20px" />
          <span
            onClick={() => this.addOption()}>
            Add field value option
          </span>
        </div>
        ): null}
        <div style={{height:'15px'}}/>
        <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} /> 
        {showAdvanced ? (
          <div>
              <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '10px',
              }}
            >
              <div onClick={this.SortAlphaOptions} style={{ marginTop: '6px', cursor: 'pointer' }}>
                <Icon path={mdiSortAlphabeticalAscending} size="20px" />
                Alphabetize
              </div>
              <GBSwitch color={color} text="Enable colors" isChecked={enableColors} Action={this.enableColorHandler} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <div style={{ marginBottom: '3px' }}>
                <strong>Default value</strong>
              </div>
              <Input
                size="small"
                onChange={(e, data) => this.setState({ defaultText: data.value })}
                value={defaultText || ''}
                placeholder="Enter default value (optional)"
              />
            </div>

            <Callout callout={callout} updateCallout={this.CalloutHandler} />   
          </div>
        ) : null}
      </div>
    );
  }
}

OptionSelect.propTypes = {
  tableid: PropTypes.number.isRequired,
  uitype: PropTypes.number.isRequired,
  columnConfigData: PropTypes.object.isRequired,
};

export default OptionSelect;
