/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import prettyJson from './prettyJSON'

const FilterDocs = ({tableData,blockKey}) =>{

    const { REACT_APP_API_URL } = process.env;

    const apiurl =`${REACT_APP_API_URL}/v1/${blockKey}/${encodeURI(tableData.name)}`

    const docExample =(obj)=>{
        const  final = '<pre style="color:white">'+prettyJson(JSON.stringify(obj,null, 2))+'</pre>';
        return  <div style={{backgroundColor:'#303336'}} spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>
    }
    

    return (
        <div style={{marginRight:'20px',marginTop:'20px',width:'500px',height:'500px',overflow:'auto'}}>
            <div>The filters paramter takes an array of filter objects. The array must be a url encoded json array.</div>
            <div>
                A filter object consists of the "field" parameter, an "operator" parameter and
                a corresponding "value" paramater which varies based on the field type. The grid below provides details of how to filter by each field type.
            </div>
            <div>
                <div style={{display:'flex',marginTop:'10px', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{fontWeight:'bold',width:'20%'}}>Field type</div>
                    <div style={{fontWeight:'bold', width:'20%'}}>Operator</div>
                    <div style={{fontWeight:'bold',width:'20%'}}>Value</div>
                    <div style={{fontWeight:'bold',width:'40%'}}>Description</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>contains</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records where phrase is contained.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>notcontains</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records where phrase does not contain value</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>startswith</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records that start/begin with value</div>
                </div>
                <div style={{display:'flex',flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>equalto</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records that are an exact match to the value</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>notequalto</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records that are not an exact match</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have no value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>text</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>minmax</div>
                    <div style={{width:'20%'}}>startNumber, endNumber</div>
                    <div style={{width:'40%'}}>Both startNumber and endNumber are required. Will match records that exist between these two values,inclusive of the numbers.</div>
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>gt</div>
                    <div style={{width:'20%'}}>startNumber</div>
                    <div style={{width:'40%'}}>Matches records that are greater than number provided</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>lt</div>
                    <div style={{width:'20%'}}>startNumber</div>
                    <div style={{width:'40%'}}>Matches records that are lesser than number provided</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>eq</div>
                    <div style={{width:'20%'}}>startNumber</div>
                    <div style={{width:'40%'}}>Matches records that are exact match for value provided.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>number</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>fromto</div>
                    <div style={{width:'20%'}}>startDate, endDate</div>
                    <div style={{width:'40%'}}>startDate and endDate are required. Matches records whose date is between the two values,inclusive of the values.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>specificDate</div>
                    <div style={{width:'20%'}}>startDate</div>
                    <div style={{width:'40%'}}>Matches records have exact date as specified</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>before</div>
                    <div style={{width:'20%'}}>startDate</div>
                    <div style={{width:'40%'}}>Matches records that have date after provided date</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>after</div>
                    <div style={{width:'20%'}}>startDate</div>
                    <div style={{width:'40%'}}>Matches records that have date before provided date. </div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>timeframe</div>
                    <div style={{width:'20%'}}>timeframe</div>
                    <div style={{width:'40%'}}>Matches records that fall within timeframe. 
                    <Popup hoverable trigger={<div style={{color:'blue',textDecoration:'underline'}}>View valid values here</div>}>
                        <div>
                            <div>24 (last 24 hours)</div>
                            <div>48 (last 48 hours)</div>
                            <div>72 (last 72 hours)</div>
                            <div>168 (last 7 days)</div>
                            <div>336 (last 14 days)</div>
                            <div>720 (last 30 days)</div>
                            <div>2160 (last 90 days)</div>
                            <div>4320 (last 180 days)</div>
                            <div>8640 (last 360 days)</div>
                            <div>thisquater (current calendar quarter)</div>
                            <div>lastquater (last calendar quarter)</div>
                            <div>thisandlastquarter (current & last calendar quarter)</div>
                            <div>ytd (year to date)</div>
                            <div>lastyear (last calendar year)</div>
                        </div>
                    </Popup></div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>date</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>select fields</div>
                    <div style={{width:'20%'}}>searchvalues</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Used for single & multi select fields. Provide array of matching strings. These are case-sensative.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>select fields</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>select fields</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>user fields</div>
                    <div style={{width:'20%'}}>searchvalues</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches any records where user field matches a value in the provided array of either integer userid's or email addresses.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>user fields</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>user fields</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>attachment</div>
                    <div style={{width:'20%'}}>contains</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records whose attachment filename contains the value</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>attachment</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>attachment</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>checkbox</div>
                    <div style={{width:'20%'}}>equalto</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records that match to either "true" or "false". Must be one of those two values. </div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>message</div>
                    <div style={{width:'20%'}}>incoming-outgoing</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records whose LAST message was either "incoming" or "outgoing". Must be one of those two values.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>message</div>
                    <div style={{width:'20%'}}>contains</div>
                    <div style={{width:'20%'}}>value</div>
                    <div style={{width:'40%'}}>Matches records whose LAST message contains the value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>message</div>
                    <div style={{width:'20%'}}>timeframe</div>
                    <div style={{width:'20%'}}>timeframe</div>
                    <div style={{width:'40%'}}>Matches records whose last message was sent/recieved with the timeframe specified.
                    <Popup hoverable trigger={<div style={{color:'blue',textDecoration:'underline'}}>View valid values here</div>}>
                        <div>
                            <div>24 (last 24 hours)</div>
                            <div>48 (last 48 hours)</div>
                            <div>72 (last 72 hours)</div>
                            <div>168 (last 7 days)</div>
                            <div>336 (last 14 days)</div>
                            <div>720 (last 30 days)</div>
                            <div>2160 (last 90 days)</div>
                            <div>4320 (last 180 days)</div>
                            <div>8640 (last 360 days)</div>
                            <div>thisquater (current calendar quarter)</div>
                            <div>lastquater (last calendar quarter)</div>
                            <div>thisandlastquarter (current & last calendar quarter)</div>
                            <div>ytd (year to date)</div>
                            <div>lastyear (last calendar year)</div>
                        </div>
                    </Popup>
                    </div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>message</div>
                    <div style={{width:'20%'}}>isnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that do not have a value.</div>
                </div>
                <div style={{display:'flex',  flexDirection:'row', alignItems:'center',borderBottom:'1px solid black'}}>
                    <div style={{width:'20%'}}>message</div>
                    <div style={{width:'20%'}}>isnotnull</div>
                    <div style={{width:'20%'}}>(not used)</div>
                    <div style={{width:'40%'}}>Matches records that have a value.</div>
                </div>
            </div>
            <p/><p/>
            <div>
                <div style={{backgroundColor:'#303336',color:'white',marginTop:'10px', overflow:'auto'}}> 
                    <div style={{marginTop:'10px',paddingLeft:'10px'}}>Generic example of filtering by text field (field not from tihs table)</div>
                    <div style={{paddingLeft:'10px'}}>
                      {docExample([{field:'Game name','operator':'contains',value:'bob'}])}
                      <p/>
                      <b>URL Encoded String</b>:  %5B%7Bfield%3A%27Game%20name%27%2C%27operator%27%3A%27contains%27%2Cvalue%3A%27bob%27%7D%5D
                      <p/><p/>
                      <b>API URL</b>: {apiurl}?filters=%5B%7Bfield%3A%27Game%20name%27%2C%27operator%27%3A%27contains%27%2Cvalue%3A%27bob%27%7D%5D
                    </div>
                </div>

                <div style={{backgroundColor:'#303336',color:'white',marginTop:'10px', overflow:'auto'}}> 
                    <div style={{marginTop:'10px',paddingLeft:'10px'}}>Generic example of filtering by date & numeric fields (fields not from this table)</div>
                    <div style={{paddingLeft:'10px'}}>
                      {docExample([
                        {field:'Event Date','operator':'fromto', startDate:'3/2/2022', endDate: '4/1/2022'},
                        {field:'Cost','operator':'gt', startNumber:50}
                        ])}
                        <p/>
                      <b>URL Encoded String</b>: %22%5B%7Bfield%3A%27Event%20Date%27%2C%27operator%27%3A%27fromto%27%2C%20startDate%3A%273%2F2%2F2022%27%2C%20endDate%3A%20%274%2F1%2F2022%27%7D%2C%5Cr%5Cn%7Bfield%3A%27Cost%27%2C%27operator%27%3A%27gt%27%2C%20startNumber%3A50%7D%5D%22
                      <p/><p/>
                      <b>API URL</b>: {apiurl}?filters=%22%5B%7Bfield%3A%27Event%20Date%27%2C%27operator%27%3A%27fromto%27%2C%20startDate%3A%273%2F2%2F2022%27%2C%20endDate%3A%20%274%2F1%2F2022%27%7D%2C%5Cr%5Cn%7Bfield%3A%27Cost%27%2C%27operator%27%3A%27gt%27%2C%20startNumber%3A50%7D%5D%22
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterDocs