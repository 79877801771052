/* eslint-disable  */
import React, { useState, useCallback } from 'react';
import SignIn from './SignIn';
import { FORM_ERROR } from '../../../components/Form';
import { signInAPI, signInGoogleAPI, signInMicrosoftAPI } from '../../../api/auth';
import useAlert from './useAlert';
import { ROUTES } from '../../../constants';
import Multifactor from './Multifactor';

const withAPI = () => {
  return ({ navigate }) => {
    const handleClose = useCallback(
      (action) => {
        if (action === 'redirect') {
          navigate(ROUTES.REDIRECTION.SIGN_UP);
        }
      },
      [navigate],
    );

    const [alertComponent, alert] = useAlert({
      title: 'No account found',
      onClose: handleClose,
    });

    const [loading, setLoading] = useState(false);
    const [isMultiFactor,setIsMultiFactor] =useState(false);
    const [resolver, setResolver] =useState(null);

    const handleMultiFactor =() =>{
      navigate(ROUTES.REDIRECTION.DASHBOARD);
    }

    const close =() =>{
      setIsMultiFactor(false);
    }

    const handleSubmit = useCallback(
      (values) => {
        setLoading(true);
        return signInAPI(values)
          .then((result) => {
           if(result.user !==undefined) {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
           } else {
            setIsMultiFactor(true);
            setLoading(false);
            setResolver(result.resolver)
           }
          })
          .catch((error) => {
            setLoading(false);
            alert.show({
              message: error.message,
              customAction: true,
            });
            return { [FORM_ERROR]: error.message };
          });
      },
      [alert, navigate],
    );

    const handleGoogleSignIn = useCallback(() => {
      setLoading(true);
      signInGoogleAPI()
        .then(({ isNewUser }) => {
          if (isNewUser) {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          } else {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
          }
        })
        .catch((error) => {
          setLoading(false);
          alert.show({ message: error.message });
          return { [FORM_ERROR]: error.message };
        });
    }, [alert, navigate]);

    const handleMicrosoftSignIn = useCallback(() => {
      setLoading(true);
       signInMicrosoftAPI()
        .then((result) => {
          if (result.user !==undefined && result.isNewUser) {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          } else if (result.user !==undefined && !result.isNewUser) {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
          } else {
            setIsMultiFactor(true);
            setLoading(false);
            setResolver(result.resolver)
           }
        })
        .catch((error) => {
          setLoading(false);
          alert.show({ message: error.message });
          return { [FORM_ERROR]: error.message };
        });
    }, [alert, navigate]);

    return (
      <div style={{position:'relative'}}>
        {alertComponent}
        <SignIn onSubmit={handleSubmit} onGoogleSignIn={handleGoogleSignIn} onMicrosoftSignIn={handleMicrosoftSignIn} loading={loading} />
        {isMultiFactor && resolver !==null ? (
          <div
          style={{
            zIndex: 100,
            position: 'absolute',
            backgroundColor: 'white',
            border: `1px solid black`,
            borderRadius: '20px',
            top: '125px',
            left: '40px',
            width: '500px',
            height: '522px',
          }}
          >
            <Multifactor resolver={resolver} color="#120233" onSuccess={handleMultiFactor} close={close}  />
          </div>
        ): null}
      </div>
    );
  };
};

export default withAPI;
