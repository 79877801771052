/* eslint-disable */
import React, { useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import Avatar from 'src/components/Avatar/Avatar';
import UserCard from 'src/components/UserDropdown/UserCard';

const UserTag = ({ item }) => {

  return (
    item &&
    item.map(
      (el) =>
        el.userid && (
          <Popup
            flowing
            hoverable
            mouseEnterDelay={2000}
            position="top center"
            trigger={<div
                key={el.userid}
                style={{
                  width: 'max-content',
                  color: "black",
                  display: 'inline-flex',
                  flexDirection: 'row',
                  margin: '3px',
                  paddingTop: '2px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  alignItems: 'center',
                  borderRadius: '10px',
                }}
              >
                 <Avatar item={el} />
                {el.firstname} {el.lastname}
              </div>}>
              <UserCard user={el} />
            </Popup>
        ),
    )
  );
};

export default UserTag;
