/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';


const GBSubmit = ({ color, text,width='100px',action }) => {
  return (
    <button
      css={{
        backgroundColor: color,
        color: 'white',
        borderRadius: '10px',
        width: width,
        height: '30px',
        marginLeft: '10px',
        outline: '0px',
        fontWeight:'bold',
        paddingTop: '5px',
        border: '0px solid transparent',
        paddingBottom: '5px',
        paddingLeft: '15px',
        paddingRight: '15px',
        '&:hover': {
          transition: 'all .3s ease',
          color: 'white',
          boxShadow: '0px 3px 6px #757575',
        },
        '&:focus': {
          outline: '0px',
        },
      }}
      type="submit"
      onClick={action}
    >
      {text}
    </button>
  );
};

export default GBSubmit;
