/* eslint-disable */
import StackdriverErrorReporter from 'stackdriver-errors-js';
import firebase, { provider } from '../clients/firebase';

let errorHandler;
const {REACT_APP_ERROR_ENABLE_REPORTING,REACT_APP_FIREBASE_PROJECT_ID,REACT_APP_ERROR_API_KEY} = process.env;

if (REACT_APP_ERROR_ENABLE_REPORTING === 'TRUE') {

  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: REACT_APP_ERROR_API_KEY,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    context: {user: 'User1'}
  });
} else {
  errorHandler = {report: console.error};
}

export default errorHandler;