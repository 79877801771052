/* eslint-disable */
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import NewMessageTopBar from './NewMessageTopBar';
import SendTextMessages from './sendTextMessages';

class NewMessage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    selectedRecords: [],
  };

  handleClickOutside = () => {
    this.props.close(false);
  };

  // componentDidMount() {
  //   const {data,messageNode} = this.props;
  //   data.forEach(itm => {
  //     console.log(itm[messageNode.phonefield])
  //   })
  //   console.log(data.filter(itm=>itm[messageNode.phonefield] !==null))
  // }


  updateCurrentRecord = (currentRecord) => {
    let newRecords = [...this.state.selectedRecords];
    const rowindex = this.state.selectedRecords.findIndex((el) => el.id === currentRecord.id);

    if (rowindex === -1) {
      newRecords.push(currentRecord);
    } else {
      newRecords = newRecords.filter((itm) => itm.id !== currentRecord.id);
    }
    this.setState({ selectedRecords: newRecords });
  };

  render() {
    const { color, messageNode, tableid,close,selectedRecords,role } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: '.1px solid #aaa',
          width: '100%',
          height: '400px',
          backgroundColor: 'white',
          borderTopRightRadius:'10px',
          borderTopLeftRadius:'10px',
          boxShadow: '0px 3px 6px #757575',
        }}
      >
        <NewMessageTopBar color={color} close={close} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            // height: 'calc(100vh-400px)',
            // border: '1px solid red',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <SendTextMessages
           selectedRecords={selectedRecords}
           tableid={tableid}
           color={color}
           close={close}
           messageNode={messageNode}
           role={role}
          />
        </div>
      </div>
    );
  }
}

export default onClickOutside(NewMessage);
