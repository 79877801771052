/* eslint-disable */
import React,{useEffect} from 'react';

const NotificationDetails = (notificationDetails) => {

    return <div style={{display:'flex',margin:'20px',  height: 'calc(100vh - 220px)',overflow:'auto'}}>
        <div dangerouslySetInnerHTML={{ __html: notificationDetails.details.body }} />
    </div>
    
}

export default NotificationDetails;
