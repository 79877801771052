/* eslint-disable */
import React, {useEffect,useState} from 'react';
import FinalForm, {
  Form,
  FormField,
  validators,
} from '../../components/Form';
import GBSubmit from '../../components/GBSubmit/GBSubmit';
import TextButton from '../../components/TextButton/TextButton';
import {getRegions} from '../../components/Form/Controls/PhoneNumber/phoneUtils';

const formValidators = {
  address1: validators.composeValidators(validators.required, validators.minLength(1)),
  address2: validators.composeValidators(validators.required, validators.minLength(2)),
  city: validators.composeValidators(validators.required),
  state: validators.composeValidators(validators.required),
  postal: validators.composeValidators(validators.required),
  country: validators.composeValidators(validators.required)
};

const BillingAddress = ({ billingAddressData={}, color,close,updateAddress }) => {
  
 const handleSubmitProxy = async (values) => {
    await updateAddress(values)
  };

  const [countries,setCountries] = useState([])

  useEffect(() => {
    const onlyCountryCodes=[];
    const regions =getRegions({onlyCountryCodes,favoriteCountryCodes: ['US']})
    const countryList =[]
    regions.forEach(el=>{
        countryList.push({value:el.region,label:el.region})
    })
    setCountries(countryList)
  },[])

  return (
    <div style={{border: `.1px solid ${color}`,borderRadius:'10px'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontSize: '20px',
          color: 'white',
          width: '500px',
          backgroundColor: color,
          height: '40px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          padding:'10x',
        }}
      >
        <div style={{marginLeft:'10px'}}>Billing address</div>
      </div>
      <div style={{padding:'10px',backgroundColor:'white',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px'}}>
      <FinalForm
        onSubmit={handleSubmitProxy}
        initialValues={
          {
              address1: billingAddressData.address1 !== undefined ? billingAddressData.address1 : '',
              address2: billingAddressData.address2 !== undefined ?  billingAddressData.address2 : '',
              city: billingAddressData.city !== undefined ? billingAddressData.city : '',
              state: billingAddressData.state !== undefined ? billingAddressData.state : '',
              postal: billingAddressData.postal !== undefined ? billingAddressData.postal : '',
              country:billingAddressData.country !== undefined ?  billingAddressData.country : ''
          }
        }
      >
        {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
          return (
            <><div style={{marginLeft:'30px',marginRight:'30px'}}>
              <FormField
                name="address1"
                label="ADDRESS 1"
                color={color}
                placeholder="Enter address 1"
                validate={formValidators.address1}
                controlId="id_address1"
              />
              </div>
              <div style={{marginLeft:'30px',marginRight:'30px'}}>
              <FormField
                name="address2"
                label="ADDRESS 2"
                color={color}
                placeholder="Enter address 2"
                controlId="id_address2"
              />
              </div>
              <div style={{marginLeft:'30px',marginRight:'30px'}}>
              <FormField
                name="city"
                label="CITY / TOWN"
                color={color}
                placeholder="Enter your city/town"
                validate={formValidators.city}
                controlId="id_city"
              />
              </div>
              <div className="row" style={{marginLeft:'30px',marginRight:'30px'}}>
              <FormField
                name="state"
                label="STATE / PROVINCE"
                color={color}
                placeholder="Create your state / proviunce"
                validate={formValidators.state}
                controlId="id_state"
              />
              <div style={{width:'10px'}} />
              <FormField
                name="postal"
                label="POSTAL CODE"
                color={color}
                placeholder="Postal code"
                validate={formValidators.postal}
                controlId="id_postal"
              />
              </div>
              <div style={{marginLeft:'30px',marginRight:'30px'}}>
              <FormField
                    name="country"
                    label="COUNTRY CODE"
                    color={color}
                    controlId="id_country"
                    validate={formValidators.country}
                    control={Form.SelectControl}
                    options={countries}
                    hasEmpty
                />
                </div>

              <div className="row" style={{justifyContent:'center',marginTop:'20px',marginBottom:'20px'}}>
              <TextButton text="Cancel" Action={close} textColor="black" />
              <GBSubmit color={color} text="Save" />
              </div>

            </>
          );
        }}
      </FinalForm>
      </div>
    </div>
  );
};

export default BillingAddress;
