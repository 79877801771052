/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

import {updateUserSystemAttributesAPI} from './users';

export const getMessagesAPI = async (schema,tableid,dataID,phonefield,number,maxId,graceblockssms) =>{

  const payload={schema,tableid,id:dataID,maxId,phonefield,graceblockssms, number,dbfunction:'getMessagesAPI'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    let response =[]
    if(dataresponse[0].json_agg !==null)
    {
     response = dataresponse[0].json_agg
    }
    // console.log(response)
    return response
}

export const getEmailMessagesAPI = async (tableid,dataID,systemreplyto,toaddress,replyConfiguration) =>{

  const payload={tableid,id:dataID,systemreplyto,toaddress,replyConfiguration, dbfunction:'getEmailMessagesAPI'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse;
}

export const getAllNumbersPerRow = async (schema,tableid,rowid) => {

  const payload={schema,tableid,rowid,dbfunction:'getAllNumbersPerRow'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    let response =[]
    if(dataresponse[0].json_agg !== null)
    {
     response = dataresponse[0].json_agg;
    }
    // console.log(response)
    return response
}

export const getAllToAddressPerRow = async (tableid,rowid) =>{
  const payload={tableid,rowid,dbfunction:'getAllToAddressPerRow'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse;
}

export const getAltPhoneMessages = async (schema,graceblocksphone,altPhone) => {

    const payload={schema,graceblocksphone,altPhone,dbfunction:'getAltPhoneMessages'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    let response =[]
    if(dataresponse[0].json_agg.length > 0)
    {
     response = dataresponse[0].json_agg;
    } 
    return response
}

export const getMessageUsersAPI = async (userids) => {
  
  const payload={userids,dbfunction:'getMessageUsersAPI',useCentral:true}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    let response =[]

    if(dataresponse[0].json_agg !== null)
    {
     response = dataresponse[0].json_agg;
    }
    return response
}

export const DeleteScheduledMessage = async (schema, messageid,messageTable) => {

  const payload={messageid,messageTable,dbfunction:'deleteScheduledMessage'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const deleteScheduledEmail = async (messageid,batch_id) =>{
  const payload={messageid,batch_id}
  const dataresponse = await cloudrun.post('/deleteScheduledEmail', {payload})
  return "success"
}

export const getTotalMessagingCosts = async () => {

    const payload={dbfunction:'getTotalMessagingCosts'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse;
}


export const getMessageTemplatesAPI = async (tableid,messagefield) => {


  const payload={tableid,messagefield,dbfunction:'getMessageTemplatesAPI'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    // console.log(dataresponse)
    let response =[]
    if(dataresponse[0].json_agg !== null)
    {
     response = dataresponse[0].json_agg
    }
    return response
}

export const updateMessageSetting = async (schema, msgSetting,tableid,userInfo) => {
  const newsetting ={schema: schema, table:`tbl_${tableid}`,interval:msgSetting.interval,number:msgSetting.number }
  const newattributes = userInfo.systemattributes !==undefined ? userInfo.systemattributes : {};
  
  if(newattributes.MessageDelivery !== undefined) {
  let rowindex =  newattributes.MessageDelivery.findIndex(itm=>itm.schema=schema && itm.table===`tbl_${tableid}`)
  if(rowindex !== -1){
    newattributes.MessageDelivery[rowindex]=newsetting;
  } else{
    newattributes.MessageDelivery.push(newsetting);
  }
} else {
  newattributes.MessageDelivery =[newsetting]
}

   await updateUserSystemAttributesAPI(userInfo.id,newattributes)
   return newattributes
}

export const constructEmail = async (tableid,ids,templateInfo,blockRoles,role,templateFields) => {
  const payload={tableid,ids,templateInfo,blockRoles,role,templateFields}
  const content = await cloudrun.post('/constructEmailAPI', {payload})
  return content;
}

export const getEmailTemplate = async (tableid,templateInfo,blockRoles,role) => {
  const payload={tableid,templateInfo,blockRoles,role}
  const content = await cloudrun.post('/getEmailTemplate', {payload})
  return content;
}

export const getEmailMessageStatus = async (messageid) =>{
  const payload={messageid,dbfunction:'getEmailMessageStatus'} 
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse[0]

}