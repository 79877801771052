import React from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import classNames from 'classnames';

const FormGroup = ({ invalid, size, className, ...props }) => {
  return (
    <BootstrapForm.Group
      {...props}
      className={classNames(invalid && 'is-invalid', className, size && `form-group-${size}`)}
    />
  );
};

FormGroup.defaultProps = {
  invalid: false,
  className: null,
  size: undefined,
};

FormGroup.propTypes = {
  invalid: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default FormGroup;
