/* eslint-disable */
import React, { useEffect, useState} from 'react';
import { Input, Dropdown, TextArea,Form } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlus, mdiChat } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';
import TextButton from '../../components/TextButton/TextButton';
import { getUsersAPI } from 'src/api/users';

const ActionConfetti = ({ localData, updateData, editMode, sourceNodes }) => {
  const [emailFields, setEmailFields] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? [{ id: 1, field: 'manual', value: '' }]);
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '')
  const [allFields, setAllFields] = useState([])
  const [confettiMessage, setConfettiMessage] =useState(localData.confettiMessage ?? '')
  const [users, setUsers] = useState([]);


  useEffect(() => {
    getEmailFields();
    loadUsers();

  }, []);



  const handleEmailSelection = (fld, value) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].field = value;
    }
    setSelectedEmail(copySelectedEmail);
    
    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const updateConfettiMessage =(val) =>{
    setConfettiMessage(val);

    localData.confettiMessage= val;
    updateData(localData)
  }

  const addEmail = () => {
    const copyEmailSelected = structuredClone(selectedEmail);
    const id = Math.floor(Math.random() * 1000);
    copyEmailSelected.push({ id: id.toString(), field: 'manual', value: '' });
    setSelectedEmail(copyEmailSelected);

    localData.selectedEmail = copyEmailSelected;
    updateData(localData);
  };

  const removeEmail = (id) => {
    const copyEmailSelected = structuredClone(selectedEmail).filter((el) => el.id !== id);
    setSelectedEmail(copyEmailSelected);
    localData.selectedEmail = copyEmailSelected;
    updateData(localData);
  };


  const updateEmailValue = (fld, val) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].value = val;
    }

    setSelectedEmail(copySelectedEmail);
    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const loadUsers = async () =>{
    const users= await getUsersAPI([],false,'dropdown');
    setUsers(users);
  }

  const getEmailFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual' }];
    const allOptions =[{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype === 8 || el.uitype===24 || el.uitype===25 ||
            (el.source?.lookupuitype === 8),
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .map((col) => {
          allOptions.push({
            key: `${node.data.tableData.id}||${col.data}`,
            value:`${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
            text: `${node.data.tableData.name}||${col.header}`,
          });
        });
    });

    setEmailFields(emailOptions);
    setAllFields(allOptions);

  };



  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        User to show confetti
      </div>
      <div>
      {/* <Dropdown
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        /> */}
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}

        {selectedEmail.map((fld,idx) => (
              <div
                key={idx.toString()}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid #0D99FF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Dropdown
                    selectOnBlur={false}
                    options={emailFields}
                    value={fld.field}
                    selection
                    fluid
                    onChange={(e, data) => handleEmailSelection(fld, data.value)}
                  />
                  {fld.field === 'manual' ? (
                    <div style={{ marginTop: '10px' }}>
                    <Dropdown 
                        selectOnBlur={false}
                        multiple
                        value={fld.value}
                        options={users} 
                        fluid 
                        selection 
                        clearable 
                        onChange={(e, data) => updateEmailValue(fld, data.value)}
                        />
                    </div>
                  ) : null}
                </div>
                {fld.id !== 1 ? (
                  <div style={{ marginLeft: '20px' }}>
                    <IconButton
                      iconSize="20px"
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={removeEmail}
                      ActionValue={fld.id}
                    />
                  </div>
                ) : null}
              </div>
            ))}

        <div style={{ marginTop: '10px' }}>
              <TextButton
                hoverColor="#0D99FF80"
                textColor="#0D99FF"
                iconColor="#0D99FF"
                iconSize="20px"
                text="Add another to line"
                Action={addEmail}
                icon={mdiPlus}
                iconPosition="left"
              />
            </div>

            <div style={{marginTop:'15px'}}>
              <b>Note to accompany confetti</b>
              
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{whiteSpace:'nowrap',marginRight:'5px'}}>Field tokens</div>
              <Dropdown selectOnBlur={false} options={allFields} fluid selection onChange={(e,data)=>updateConfettiMessage(confettiMessage+`{{${data.value}}}`)} />
                </div>

              <Form>
                <TextArea
                  style={{
                    marginTop:'20px',
                    fontSize: '12px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    // width: '470px',
                  }}
                  onChange={(e, data) => updateConfettiMessage(data.value)}
                  value={confettiMessage}
                  placeholder="Enter Confetti message here"
                />
              </Form>
           </div> 

      </div>
    </div>
  );
};

export default ActionConfetti;
