/* eslint-disable */
import React, { useState, useEffect } from 'react';

const SecurityTabs = ({ securityMap, updateSecurityTab, color }) => {
  // useEffect(() => {
  //   console.log(securityMap);
  // });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>View tab and my records</div>
        <div
          onClick={() => updateSecurityTab('myRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.myRecords === 0 ? color : 'white',
            color: securityMap.myRecords === 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('myRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.myRecords === 1 ? color : 'white',
            color: securityMap.myRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('myRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.myRecords === 2 ? color : 'white',
            color: securityMap.myRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>View records without restriction</div>
        <div
          onClick={() => updateSecurityTab('allRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.allRecords === 0 ? color : 'white',
            color: securityMap.allRecords === 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('allRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.allRecords === 1 ? color : 'white',
            color: securityMap.allRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('allRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.allRecords === 2 ? color : 'white',
            color: securityMap.allRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Add new record</div>
        <div
          onClick={() => updateSecurityTab('addRecord', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.addRecord === 0 ? color : 'white',
            color: securityMap.addRecord === 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('addRecord', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.addRecord === 1 ? color : 'white',
            color: securityMap.addRecord === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('addRecord', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.addRecord === 2 ? color : 'white',
            color: securityMap.addRecord === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Edit records if creator</div>
        <div
          onClick={() => updateSecurityTab('editMyRecord', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editMyRecord === 0 ? color : 'white',
            color: securityMap.editMyRecord=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('editMyRecord', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editMyRecord=== 1 ? color : 'white',
            color: securityMap.editMyRecord === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('editMyRecord', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editMyRecord === 2 ? color : 'white',
            color: securityMap.editMyRecord === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Edit records if collaborator</div>
        <div
          onClick={() => updateSecurityTab('editOthersRecord', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editOthersRecord === 0 ? color : 'white',
            color: securityMap.editOthersRecord=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('editOthersRecord', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editOthersRecord=== 1 ? color : 'white',
            color: securityMap.editOthersRecord === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('editOthersRecord', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editOthersRecord === 2 ? color : 'white',
            color: securityMap.editOthersRecord === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Edit any record</div>
        <div
          onClick={() => updateSecurityTab('editAnyRecord', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editAnyRecord === 0 ? color : 'white',
            color: securityMap.editAnyRecord=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('editAnyRecord', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editAnyRecord=== 1 ? color : 'white',
            color: securityMap.editAnyRecord === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('editAnyRecord', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.editAnyRecord === 2 ? color : 'white',
            color: securityMap.editAnyRecord === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Share records</div>
        <div
          onClick={() => updateSecurityTab('shareRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.shareRecords === 0 ? color : 'white',
            color: securityMap.shareRecords=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('shareRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.shareRecords=== 1 ? color : 'white',
            color: securityMap.shareRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('shareRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.shareRecords === 2 ? color : 'white',
            color: securityMap.shareRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Download records</div>
        <div
          onClick={() => updateSecurityTab('downloadRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.downloadRecords === 0 ? color : 'white',
            color: securityMap.downloadRecords=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('downloadRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.downloadRecords=== 1 ? color : 'white',
            color: securityMap.downloadRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('downloadRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.downloadRecords === 2 ? color : 'white',
            color: securityMap.downloadRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Print records</div>
        <div
          onClick={() => updateSecurityTab('printRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.printRecords === 0 ? color : 'white',
            color: securityMap.printRecords=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('printRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.printRecords=== 1 ? color : 'white',
            color: securityMap.printRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('printRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.printRecords === 2 ? color : 'white',
            color: securityMap.printRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Delete records</div>
        <div
          onClick={() => updateSecurityTab('deleteRecords', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.deleteRecords === 0 ? color : 'white',
            color: securityMap.deleteRecords=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('deleteRecords', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.deleteRecords=== 1 ? color : 'white',
            color: securityMap.deleteRecords === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('deleteRecords', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.deleteRecords === 2 ? color : 'white',
            color: securityMap.deleteRecords === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Access spreadsheet view</div>
        <div
          onClick={() => updateSecurityTab('viewSpreadSheet', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewSpreadSheet === 0 ? color : 'white',
            color: securityMap.viewSpreadSheet=== 0 ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('viewSpreadSheet', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewSpreadSheet=== 1 ? color : 'white',
            color: securityMap.viewSpreadSheet === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('viewSpreadSheet', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewSpreadSheet === 2 ? color : 'white',
            color: securityMap.viewSpreadSheet === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>View private fields</div>
        <div
          onClick={() => updateSecurityTab('viewPrivate', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewPrivate === 0  || securityMap.viewPrivate===undefined ? color : 'white',
            color: securityMap.viewPrivate=== 0 || securityMap.viewPrivate===undefined ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('viewPrivate', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewPrivate=== 1 ? color : 'white',
            color: securityMap.viewPrivate === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('viewPrivate', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.viewPrivate === 2 ? color : 'white',
            color: securityMap.viewPrivate === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <div style={{ marginRight: '10px', width: '200px',textAlign:'right' }}>Allow unmasking of masked fields</div>
        <div
          onClick={() => updateSecurityTab('unmask', 0)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.unmask === 0 || securityMap.unmask===undefined ? color : 'white',
            color: securityMap.unmask=== 0 || securityMap.unmask===undefined ? 'white' : 'black',
          }}
        >
          General
        </div>
        <div
          onClick={() => updateSecurityTab('unmask', 1)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.unmask=== 1 ? color : 'white',
            color: securityMap.unmask === 1 ? 'white' : 'black',
          }}
        >
          Level 1
        </div>
        <div
          onClick={() => updateSecurityTab('unmask', 2)}
          style={{
            hover: 'pointer',
            border: `2px solid ${color}`,
            padding: '3px',
            backgroundColor: securityMap.unmask === 2 ? color : 'white',
            color: securityMap.unmask === 2 ? 'white' : 'black',
          }}
        >
          Level 2
        </div>
      </div>

    </div>
  );
};

export default SecurityTabs;
