/* eslint-disable */

import React, { useEffect, useState,useContext } from 'react';
import Icon from '@mdi/react';
import { Checkbox } from 'semantic-ui-react';
import {
    mdiClose,
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiSortAscending,
    mdiSortDescending
  } from '@mdi/js';
  import TextButton from '../../../../components/TextButton/TextButton';
  import GBButton from 'src/components/GBButton/GBButton';

const SortManager = ({tableid,tableinfo,field,role,updateSort,closeModal,sortFields,color}) =>{

    const [items,setItems] = useState([]);
    const [sortItems,setSortItems] =useState(sortFields ?? [])
    
    const isOdd=(num) => {
        return num % 2;
      }

   const canViewPrivate =tableinfo.security ==undefined || tableinfo.security.viewPrivate ==undefined || tableinfo.security.viewPrivate <= role;

    useEffect(() =>{
        const itemsTemp=[{data:'custom.fullname',header:'Record identifier',sortOrder:'asc',disableSort:false}];
        tableinfo.columns.filter(itm=>itm.data !=='id').map((el) => {
            const disableSort =(el.source && el.uitype === 18 && el.source.lookup === 'multiple') ||
            (el.lookup === 'multiple' && (['all', 'unique'].includes(el.specialfunction) || !el.specialfunction) ) ||
            el.uitype === 12 || el.uitype===13 || (el.isPrivate !==undefined && !canViewPrivate)
      
            itemsTemp.push({ header: el.header, data: el.data, disableSort });
          });
          setItems(itemsTemp.filter(el=>!el.disableSort))
    },[])

    const sortidx = (field) => {
      const idx = sortItems.findIndex((el) => el.field === field);
        return idx;
    }

    const updateSortItems = (sortItem) => {
       
        let sortItemsTemp = structuredClone(sortItems);
        const idx = sortItemsTemp.findIndex(el=>el.field===sortItem.field)
        if(idx===-1 && sortItems.length<3) {
          sortItemsTemp.push(sortItem)
        } else if(idx !==-1) {
          sortItemsTemp = sortItemsTemp.filter(el=>el.field !==sortItem.field)
        }
        setSortItems(sortItemsTemp);
    }

    const update =() =>{
        updateSort(tableid,field,sortItems);
    }
    
    const updateSortOrder =(sortItem,order) =>{
       
        let sortItemsTemp = structuredClone(sortItems);
        const idx = sortItemsTemp.findIndex(el=>el.field===sortItem.field)
        if(idx!==-1) {
          sortItemsTemp[idx].sortOrder =order
          setSortItems(sortItemsTemp)
        }
    
      }

    return (
        <div>

        <div style={{width:400,height:430,margin:'10px',overflow:'auto'}}>
           <span style={{fontWeight:'bold'}}>Choose up to three sort fields</span>
            <div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{ width: '350px',fontWeight:'bold' }}>Field name</div>
                <div style={{ width: '150px',fontWeight:'bold' }}>Sort</div>
            </div>

                {items.length>0 && items.map((item,index)=>(
                       <div
                       style={{
                         padding:'10px',
                         display: 'flex',
                         flexDirection: 'row',
                         height: '35px',
                         alignItems: 'center',
                         display: 'flex',
                         backgroundColor: isOdd(index) === 1 ? `${color}1A` : 'white',
                       }}
                     >
                       <div style={{ width: '350px' }}>{item.header}</div>
                       <div style={{ width: '150px' }}>
                       {sortidx(item.data) === -1 ? (
                 
                         <Checkbox checked={false} 
                           onChange={(e, data) => {
                           updateSortItems({ field: item.data, sortOrder: 'asc',sortHeader:item.header })}
                         }
                         />
                       ) : (
                         <div style={{display:'flex',flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
                       
                             <Icon path={sortidx(item.data)===0 ? mdiNumeric1Circle : sortidx(item.data)===1 ? mdiNumeric2Circle : mdiNumeric3Circle} size="20px" onClick={()=>updateSortItems({ field: item.data, sortOrder: 'asc' })}/>
                             {sortItems[sortidx(item.data)].sortOrder==='asc' ? (
                                <Icon path={mdiSortDescending} size="20px" onClick={()=>updateSortOrder(sortItems[sortidx(item.data)],'desc')} />
                             ) : (
                               <Icon path={mdiSortAscending} size="20px" onClick={()=>updateSortOrder(sortItems[sortidx(item.data)],'asc')} />
                             )}
                            
                         </div>
                       )}
                        </div>
                     </div>
                ))}
            </div>
          
        </div>
        <div style={{height:'50px',width:'100%', display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-evenly'}}>
            <TextButton text="Cancel" textColor='black' hoverColor="#00000080" Action={closeModal} />
            <GBButton text="Save" color={color} Action={update} width="75px"/>
        </div>
        </div>
    )
}

export default SortManager;