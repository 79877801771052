import PNL from 'google-libphonenumber';
import MESSAGES from './messages';

const phoneUtil = PNL.PhoneNumberUtil.getInstance();

export default function phoneValidator(value) {
  if (!value) return undefined;
  try {
    const phone = phoneUtil.parse(value);
    const region = phoneUtil.getRegionCodeForNumber(phone);
    if (!region || !phoneUtil.isValidNumberForRegion(phone, region)) {
      return MESSAGES.PHONE_NUMBER;
    }
  } catch (e) {
    return MESSAGES.PHONE_NUMBER;
  }
  return undefined;
}
