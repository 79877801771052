import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import Form from '../Form';

const FinalFormWrapper = ({
  onSubmit,
  validate = () => ({}),
  initialValues,
  id,
  children,
  ...other
}) => {
  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      {...other}
      render={({ handleSubmit, invalid, ...props }) => (
        <Form
          id={id}
          onSubmit={handleSubmit}
          noValidate
          // style={{ display: 'flex', flexDirection: 'column' }}
        >
          {children({ ...props, invalid })}
        </Form>
      )}
    />
  );
};

FinalFormWrapper.defaultProps = {
  validate: () => {},
  initialValues: null,
  id: undefined,
};

FinalFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  validate: PropTypes.func,

  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string,
};

FinalFormWrapper.arrayMutators = arrayMutators;
FinalFormWrapper.FieldArray = FieldArray;

export default FinalFormWrapper;
export { useFieldArray };
