/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import { Input, Dropdown, Checkbox, TextArea,Form,Icon as SIcon } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlayBox, mdiClipboardCheck } from '@mdi/js';
import Modal from 'react-modal';
import { AccountContext } from '../../providers/AccountProvider';
import Icon from '@mdi/react';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import { getBlocksAPI } from '../../api/blocks';
import IconButton from '../Block/components/FilterData/IconButton';
import GBButton from '../../components/GBButton/GBButton';
import EmailControl from '../Block/components/MessageView/EmailControl';


const ActionAddorUpdateUser = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sendNotification, setSendNotification] =useState(localData.sendNotification ?? false);
  const [emailFields, setEmailFields] = useState([]);
  const [passwordFields, setPasswordFields] =useState([]);
  const [textFields, setTextFields] =useState([]);
  const [phoneFields, setPhoneFields] =useState([]);

  const [allFields, setAllFields] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? 'manual');
  const [selectedPassword, setSelectedPassword] = useState(localData.selectedPassword ?? 'manual');
  const [selectedFirstName,setSelectedFirstName] =useState(localData.selectedFirstName ?? 'manual')
  const [selectedLastName,setSelectedLastName] =useState(localData.selectedlastName ?? 'manual')
  const [selectedMobile,setSelectedMobile] =useState(localData.selectedMobile ?? 'manual')
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '');
  const [passwordValue, setPasswordValue] = useState(localData.passwordValue ?? '');
  const [firstNameValue, setFirstNameValue] = useState(localData.firstNameValue ?? '');
  const [lastNameValue, setLastNameValue] = useState(localData.lastNameValue ?? '');
  const [mobileValue, setMobileValue] = useState(localData.mobileValue ?? '');

  const [zoneRole, setZoneRole]=useState(localData.zoneRole ?? 'Zone user');
  const [blocks, setBlocks] =useState([]);
  const [replaceOrAppendRoles, setReplaceOrAppendRoles] = useState(localData.replaceOrAppendRoles ?? 'append')
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [emailConfig, setEmailConfig] =useState(null); //new or existing. used to control which  message is updated.
  const [newUserMessage, setNewUserMessage] = useState(
    localData.newUserMessage ?? 
    {
    subject: 'You have a new GraceBlocks account!',
    message: ` Greetings,  Your GraceBlocks account has been created. 
     
    To login go to https://my.graceblocks.com.
    Thank you.`,
    fromAddress: 'clientemaildelivery@graceblocks.com',
    fromName: 'GraceBlocks Support',
    replyTo: 'support@graceblocks.com',
  });

  const [existingUserMessage, setExistingUserMessage] = useState(
    localData.existingUserMessage ?? 
    {
    subject: 'You have been added to a new zone',
    message: ` Greetings, You have been added to the Acme Zone.
     
    To login go to https://my.graceblocks.com.
    Thank you.`,
    fromAddress: 'clientemaildelivery@graceblocks.com',
    fromName: 'GraceBlocks Support',
    replyTo: 'support@graceblocks.com',
  });


  const zoneOptions=[{key:'1',value:'Zone owner',text:'Zone owner'}, {key:'2',value:'Zone builder',text:'Zone builder'},{key:'3',value:'Zone user',text:'Zone user'}]


  useEffect(() => {
    getBlocks();
    getEmailandCollaboratorFields();
  }, []);

  const updateEmailConfig =(type)=>{
    setEmailConfig(type)
    setModalIsOpen(true);
  }


  const updateSendNotification = (val) =>{
    setSendNotification(val);
    localData.sendNotification = val;
    updateData(localData);
  }

  const updateEmailValue = (val) => {
    setEmailValue(val);

    if(localData.replaceOrAppendRoles===undefined) {
      localData.replaceOrAppendRoles ='append'
    }
    
    if(localData.zoneRole ===undefined) {
      localData.zoneRole='Zone user'
    }

    localData.emailValue = val;
    updateData(localData);
  };

  const updatePasswordValue = (val) => {
    setPasswordValue(val);
    localData.passwordValue = val;
    updateData(localData);
  };

  const updateFirstNameValue = (val) => {
    setFirstNameValue(val);
    localData.firstNameValue = val;
    updateData(localData);
  };

  const updateLastNameValue = (val) => {
    setLastNameValue(val);
    localData.lastNameValue = val;
    updateData(localData);
  };

  const updateMobileValue = (val) => {
    setMobileValue(val);
    localData.mobileValue = val;
    updateData(localData);
  };

  const updateZoneRole = (val) =>{
    setZoneRole(val);
    localData.zoneRole = val;
    updateData(localData);
  }

  const updateReplaceorAppendRoles = (val) =>{
    setReplaceOrAppendRoles(val);
    localData.replaceOrAppendRoles = val;
    updateData(localData);
  }


  const getBlocks = async () => {
    const myblocks = await getBlocksAPI(0, 'all');
    setBlocks(myblocks);

    if(localData.selectedBlocks !==undefined) {
        setSelectedBlocks(localData.selectedBlocks)
    }
  };

  const configureEmail = (
    emailObjectArray,
    messageSetting,
    templateFields,
    ids,
    blockRoles,
    role,
  ) => {
    const copyEmail = structuredClone(emailConfig==='new' ?  newUserMessage : existingUserMessage);

    if (templateFields !== null) {
      copyEmail.message = templateFields.filter((el) => el.field === 'message')[0].fieldValue;
      copyEmail.fromName = templateFields.filter((el) => el.field === 'fromName')[0].fieldValue;
      copyEmail.subject = templateFields.filter((el) => el.field === 'subject')[0].fieldValue;
      copyEmail.replyTo = templateFields.filter((el) => el.field === 'replyTo')[0].fieldValue;
      if (templateFields.filter((el) => el.field === 'CC')[0].fieldValue !== '') {
        copyEmail.CC = templateFields.filter((el) => el.field === 'CC')[0].fieldValue;
      }
      if (templateFields.filter((el) => el.field === 'BCC')[0].fieldValue !== '') {
        copyEmail.BCC = templateFields.filter((el) => el.field === 'BCC')[0].fieldValue;
      }
      if (templateFields.filter((el) => el.field === 'files')[0].fieldValue !== '') {
        copyEmail.files = templateFields.filter((el) => el.field === 'files')[0].fieldValue;
      }

      emailConfig==='new' ? setNewUserMessage(copyEmail) : setExistingUserMessage(copyEmail)

    } else if (emailObjectArray !== null) {
      copyEmail.message = emailObjectArray[0].message;
      copyEmail.fromName = emailObjectArray[0].fromName;
      copyEmail.subject = emailObjectArray[0].subject;
      copyEmail.replyTo = emailObjectArray[0].replyTo;
      if (emailObjectArray[0].CC !== '') {
        copyEmail.CC = emailObjectArray[0].CC;
      }
      if (emailObjectArray[0].BCC !== '') {
        copyEmail.BCC = emailObjectArray[0].BCC;
      }
      if (emailObjectArray[0].files !== null) {
        copyEmail.files = emailObjectArray[0].files;
      }
      emailConfig==='new' ? setNewUserMessage(copyEmail) : setExistingUserMessage(copyEmail)
    }
    
    if(emailConfig==='new') {
      localData.newUserMessage = copyEmail;
    } else {
      localData.existingUserMessage = copyEmail;
    }

    updateData(localData)
    setModalIsOpen(false);
    setEmailConfig(null)
  };




  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const allOptions =[{ key: '0', value: '', text: 'Choose field' }];
    const textOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const phoneOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype === 10 ||
            (el.source?.lookupuitype === 10 && el.source.lookup === 'single'),
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    const passwordOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    sourceNodes.map((node) => {
        node.data?.tableData?.tableinfo?.columns
          .filter(
            (el) =>
              el.uitype === 14 ||
              (el.source?.lookupuitype === 14 && el.source.lookup === 'single'),
          )
          .map((col) => {
            passwordOptions.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.data}`,
              text: `${node.data.tableData.name} (${col.header})`,
            });
          });
      });

      sourceNodes.map((node) => {
        node.data?.tableData?.tableinfo?.columns
          .filter(
            (el) =>
              el.uitype === 2 ||
              (el.source?.lookupuitype ===2 && el.source.lookup === 'single'),
          )
          .map((col) => {
            textOptions.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.data}`,
              text: `${node.data.tableData.name} (${col.header})`,
            });
          });
      });

      sourceNodes.map((node) => {
        node.data?.tableData?.tableinfo?.columns
          .filter(
            (el) =>
              el.uitype === 9 ||
              (el.source?.lookupuitype ===9 && el.source.lookup === 'single'),
          )
          .map((col) => {
            phoneOptions.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.data}`,
              text: `${node.data.tableData.name} (${col.header})`,
            });
          });
      });

      sourceNodes.map((node) => {
        node.data?.tableData?.tableinfo?.columns
          .map((col) => {
            allOptions.push({
              key: `${node.data.tableData.id}||${col.data}`,
              value:`${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
              text: `${node.data.tableData.name}||${col.header}`,
            });
          });
      });

    setEmailFields(emailOptions);
    setAllFields(allOptions);
    setPasswordFields(passwordOptions);
    setTextFields(textOptions)
    setPhoneFields(phoneOptions);
    
  };

  const handleEmailSelection = (val) => {
    setSelectedEmail(val);

    if(localData.replaceOrAppendRoles===undefined) {
      localData.replaceOrAppendRoles ='append'
    }
    if(localData.zoneRole ===undefined) {
      localData.zoneRole='Zone user'
    }

    localData.selectedEmail = val;
    updateData(localData);
  };

  const handleFirstName =(val) =>{
    setFirstName(val)
    localData.FirstName=val
    updateData(localData)
  }

  const handlePasswordSelection = (val) => {
    setSelectedPassword(val);
    localData.selectedPassword = val;
    updateData(localData);
  };

  const handleFirstNameSelection = (val) => {
    setSelectedFirstName(val);
    localData.selectedFirstName = val;
    updateData(localData);
  };

  const handleLastNameSelection = (val) => {
    setSelectedLastName(val);
    localData.selectedLastName = val;
    updateData(localData);
  };

  const handleMobileSelection = (val) => {
    setSelectedMobile(val);
    localData.selectedMobile = val;
    updateData(localData);
  };

  const addremoveblocks = (blockid) => {
    let tempBlocks = [...selectedBlocks];
    const inList = tempBlocks.findIndex((el) => el.blockid === blockid) !== -1;
    if (!inList) {
      // the "items" array of blocks passed in includes user specific block settings (if they exist)
      // and userid is already defined. Here, a user could deselect/then reselect a block. In this
      // case we don't want to lose the settings. so we first look in "items" to see if it's there,
      // otherwise we add blank block and set the userid attribute and set settings = null
     
      const newblock = blocks.filter((itm) => itm.blockid === blockid)[0];
      newblock.role='Block user (General)'
      tempBlocks.push(newblock);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));
      setSelectedBlocks(tempBlocks);
    } else {
      tempBlocks = tempBlocks.filter((el) => el.blockid !== blockid);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));

      setSelectedBlocks(tempBlocks);
    }
    localData.selectedBlocks = tempBlocks;
    updateData(localData)
  };

  const isCheckedHandler = (blockid) => {
    return selectedBlocks.map((el) => el.blockid).includes(blockid);
  };

  const displayBlocks = () =>
  blocks.map((el) => (
    <div
      key={el.blockid}
      style={{ display: 'flex', margin: '10px', flexDirection: 'row', aligntItems: 'center' }}
    >
      <Checkbox
        onChange={() => addremoveblocks(el.blockid)}
        checked={isCheckedHandler(el.blockid)}
      />
      <div style={{ width: '10px' }} />
      <Icon path={el.icon} size="20px" />
      <div style={{ width: '5px' }} />
      {el.name}
    </div>
  ));

  const updateRole = (blockid,role) => {
    const tempBlocks = [...selectedBlocks]
     const idx = tempBlocks.findIndex(itm=>itm.blockid===blockid)
     if(idx !== -1) {
      tempBlocks[idx].role = role;
      setSelectedBlocks(tempBlocks)
     }
     localData.selectedBlocks = tempBlocks
     updateData(localData);
  }


  const displayRoles = () =>
  selectedBlocks.map((el) => (
    <div key={el.blockid} style={{display:'flex', flexDirection:'column',marginBottom:'10px'}}>
      <div style={{ display: 'flex', margin: '5px', flexDirection: 'row', alignItems: 'center',justifyContent:'space-between',marginRight:'0px' }}>
      <div
        style={{ display: 'flex',marginLeft:'10px', margin: '5px', flexDirection: 'row', alignItems: 'center' }}
        key={el.blockid}
      >
        <Icon path={el.icon} size="20px" /> {el.name}
      </div>
      <IconButton
                        iconSize="20px"
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={addremoveblocks}
                        ActionValue={el.blockid}
                      />
        {/* <div
            onClick={() => addremoveblocks(el.blockid)}
            css={{
              svg: { color: 'red' },
              borderRadius: '50px',
              marginRight: '30px',
              '&:hover': {
                transition: 'all .3s ease',
                svg: { color: 'white', borderRadius: '30px', backgroundColor: '#F6685E' },
              },
            }}
          >
            <Icon path={mdiClose} size="15px" />
          </div> */}
      </div>
      <select onChange={(e)=>updateRole(el.blockid,e.target.value)} value={el.role} style={{height:'35px',marginRight:'30px',marginLeft:'10px'}}>
        <option value="Block user (General)">Block user (General)</option>
        <option value="Block user (L1 Security)">Block user (L1 Security)</option>
        <option value="Block user (L2 Security)">Block user (L2 Security)</option>
        <option value='Block builder'>Block builder</option>
      </select>
    </div>
  ));



  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
           <GBSwitch 
           text="Send invitation notice to user" 
           isChecked={sendNotification}
           Action={()=>updateSendNotification(!sendNotification)} 
           color="#0D99FF"
           />
          </div>
        </div>
        <div>
          User to add <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          selectOnBlur={false}
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        />
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}

      <div style={{marginTop:'20px',fontWeight:'bold'}}>
          First name
        </div>
        <Dropdown
          selectOnBlur={false}
          options={textFields}
          value={selectedFirstName}
          selection
          fluid
          onChange={(e, data) => handleFirstNameSelection(data.value)}
        />
        {selectedFirstName === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={firstNameValue}
              fluid
              label="First name"
              onChange={(e, data) => updateFirstNameValue(data.value)}
            />
          </div>
        ) : null}

      <div style={{marginTop:'20px',fontWeight:'bold'}}>
          Last name
        </div>
        <Dropdown
          selectOnBlur={false}
          options={textFields}
          value={selectedLastName}
          selection
          fluid
          onChange={(e, data) => handleLastNameSelection(data.value)}
        />
        {selectedLastName === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={lastNameValue}
              fluid
              label="Last name"
              onChange={(e, data) => updateLastNameValue(data.value)}
            />
          </div>
        ) : null}

      <div style={{marginTop:'20px',fontWeight:'bold'}}>
          Mobile
        </div>
        <Dropdown
          selectOnBlur={false}
          options={phoneFields}
          value={selectedMobile}
          selection
          fluid
          onChange={(e, data) => handleMobileSelection(data.value)}
        />
        {selectedMobile === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={mobileValue}
              fluid
              label="Mobile"
              onChange={(e, data) => updateMobileValue(data.value)}
            />
          </div>
        ) : null}
 
 
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
         Zone role <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '} (Only used for new users)
        </div>
        <Dropdown selectOnBlur={false} fluid selection options={zoneOptions} value={zoneRole} onChange={(e,data)=> updateZoneRole(data.value)} />
        
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
         Append or Replace Block Roles <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown 
        selectOnBlur={false}
           fluid
           selection
          options=
          {[
            {key:'append',value:'append',text:'Append Block / Roles'},
            {key:'replace',value:'replace',text:'Replace Block / Roles'},
          ]}
          value ={replaceOrAppendRoles}
          onChange={(e,data)=> updateReplaceorAppendRoles(data.value)}

          />

        <div style={{marginTop:'20px',fontWeight:'bold'}}>
         Select Blocks user can access <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
            //   width: '500px',
              border: `1px solid #0D99FF`,
              height: '200px',
            }}
          >
            {displayBlocks()}
          </div>
        
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
            Select the role per Block
        </div>
        <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
              border: `1px solid #0D99FF`,
              height: '200px',
            }}
          >
            {selectedBlocks.length > 0 ? displayRoles() : 'No Blocks selected'}
          </div>
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
          New user password
        </div>
        <Dropdown
          selectOnBlur={false}
          options={passwordFields}
          value={selectedPassword}
          selection
          fluid
          onChange={(e, data) => handlePasswordSelection(data.value)}
        />
        {selectedPassword === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              type={showPassword ? 'text' :'password'}
              value={passwordValue}
              fluid
              label="Password"
              onChange={(e, data) => updatePasswordValue(data.value)}
              action={{
                // color: 'teal',
                icon: 'eye',
                onClick: ()=>setShowPassword(!showPassword)
              }}
            />
          </div>
        ) : null}

         {sendNotification ? (<>
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
            New user created
        </div>
       
        <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure email"
                    icon={mdiEmail}
                    iconSize="20px"
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => updateEmailConfig('new')}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="150px"
                    height={'30px'}
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>

        <div style={{marginTop:'20px',fontWeight:'bold'}}>
           Existing user account added
        </div>
        <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure email"
                    icon={mdiEmail}
                    iconSize="20px"
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => updateEmailConfig('existing')}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="150px"
                    height={'30px'}
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>
        </>) : null}



      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            zIndex:100,
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            border: `.3px solid #0D99FF`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 10,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        <EmailControl
          toAddress={'{{dynamic}}'}
          tableid={sourceNodes[0]?.data?.tableData?.id}
          emailField={{
            fromAddress: emailConfig==='new' ? newUserMessage.fromAddress :  existingUserMessage.fromAddress,
            replyTo: emailConfig==='new' ? newUserMessage.replyTo : existingUserMessage.replyTo,
            fromName: emailConfig==='new' ? newUserMessage.fromName : existingUserMessage.fromName,
          }}
          replyMsg={emailConfig==='new' ? newUserMessage : existingUserMessage}
          color="#0D99FF"
          sendMessage={configureEmail}
          close={() => setModalIsOpen(false)}
          role={3}
          tokens={allFields}
        />
      </Modal>

    </div>
  );
};

export default ActionAddorUpdateUser;
