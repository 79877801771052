/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useState,useRef,useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { confirmAlert } from 'react-confirm-alert';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiClose,
  mdiPlus,
  mdiContentSave,
  mdiArrowDecisionOutline,
  mdiRefresh
} from '@mdi/js';
import _ from 'lodash';
import { toast } from 'react-toastify';
import CustomNode from './CustomNode';
import TriggerNode from './TriggerNode';
import ActionNode from './ActionNode';
import 'reactflow/dist/style.css';
import './overview.css';
import GBButton from '../../components/GBButton/GBButton';
import {getTableAPI} from '../../api/tables';
import TriggerPanel from './TriggerPanel';
import ActionPanel from './ActionPanel';
import {updateWorkFlow,getWorkflow} from '../../api/workflows'
import ExtractRequiredFields from './ExtractRequiredFields';


const nodeTypes = {
  custom: CustomNode,
  trigger: TriggerNode,
  action: ActionNode
};

const minimapStyle = {
  height: 120,
};

// const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

const WorkFlow = ({ workFlowObj, refresh, close }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [rfInstance, setRfInstance] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [nodeType, setNodeType] = useState('trigger');
  // const { screenToFlowPosition, setViewport } = useReactFlow();
  const reactFlowInstance  = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [counter, setCounter] = useState(1);
  const [sourceNodes, setSourceNodes] =useState(null);
  const [isChanged, setIsChanged] =useState(false);
  const [settings, setSettings]=useState(null);
  const [isPositioned, setIsPositioned]=useState(false)
  const divRef=useRef(null);


  useEffect(()=>{
    if(workFlowObj.workflow !==undefined) {
      onRestore()
    } else {
      //Create trigger node, first time into new workflow.
      setNodeType('trigger');
      setModalIsOpen(true);
    }
  },[workFlowObj])


  useEffect(() => { 
    if (reactFlowInstance && settings && !isPositioned) { // Check if reactFlowInstance is available
      reactFlowInstance.setViewport({
        x: settings.x,
        y: settings.y,
        zoom: settings.zoom
      });
      setIsPositioned(true);
    }
  }, [nodes,edges]);

const onRestore = useCallback(() => {
  const restoreFlow = async () => {
    const flow = workFlowObj.workflow

    if (flow) {
      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      const tempSettings={x,y,zoom};
      setSettings(tempSettings)
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setCounter(flow.nodes.length)
    }
  };

  restoreFlow();
}, [setNodes,workFlowObj]);

const getId = () => {

   const maxNumber = Math.max(...nodes.map(n=>n.id).map(Number));
   const newCounter=maxNumber+1;
    setCounter(newCounter)
    return newCounter.toString()
}

const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds))
    },
    [],
  );

  const editNode = (node) => {
    const flow = rfInstance?.toObject();
    
    //Get all source nodes to this node. This will be passed to Panel for use
    //in configuring node further.
    const tempSourceNodes = flow?.nodes.filter(n=>flow.edges.filter(el=>el.target===node.id).map(e=>e.source).includes(n.id));

    node.data.sourceNodes=tempSourceNodes;
    setCurrentNode(node.data);
    setNodeType(node.type)
    setSourceNodes(tempSourceNodes);
    setModalIsOpen(true);
    
  };

  const closeNode = () => {
    setCurrentNode(null);
    setModalIsOpen(false);
  };

  const removeNodeAndEdges = (nodeId) => {
    // Remove the node by filtering out the node with the given nodeId
    const newNodes = nodes.filter(node => node.id !== nodeId);
    
    // Remove edges connected to the node
    const newEdges = edges.filter(edge => edge.source !== nodeId && edge.target !== nodeId);
    
    // Update the nodes and edges state
    setNodes(newNodes);
    setEdges(newEdges);

    setCurrentNode(null);
    setModalIsOpen(false);
  };

  const onEdgeDoubleClick = useCallback((event, edge) => {
    // Remove the edge by applying an edge change with the 'remove' action
    const changes = [{ id: edge.id, type: 'remove' }];
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);


  const closeLocal = async() =>{
    
    if(isChanged) {
      confirmChanges()
    } else {
      close(false);
    }
  }

  const saveAndClose = async () =>{
    await onSave()
    close(false)
  }
  

  const onSave = useCallback(async () => {
    if (rfInstance) {

      const flow = rfInstance.toObject();     
      const tempWorkFlow = structuredClone(workFlowObj);
      tempWorkFlow.workflow = flow;

    
      const requiredFields = ExtractRequiredFields(tempWorkFlow);
      tempWorkFlow.requiredFields=requiredFields;

      await updateWorkFlow(tempWorkFlow);
      refresh(tempWorkFlow)
      setIsChanged(false);

      toast.info(<div style={{margin:'10px'}}>Your workflow has been saved!</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    }
  }, [rfInstance]);


  const refreshSchema = async () =>{

    const wf = await getWorkflow(workFlowObj.workflowid)

    if(rfInstance) {
   
      // const flow = rfInstance.toObject();     
      const flow = wf[0].workflow

      // const tempWorkFlow = structuredClone(workFlowObj);
      const tempWorkFlow = wf[0];
   
      const requiredFields = ExtractRequiredFields(tempWorkFlow);
      tempWorkFlow.requiredFields=requiredFields;

      const updatedTableData = await getTableAPI(flow.nodes[0].data.tableData.id);
     
      for(const node of flow.nodes) {
        if(node.data.tableData?.id ===updatedTableData.id) {
          node.data.tableData = updatedTableData;
        } else if(node.data?.tableData?.id !==undefined) {
          const sData = await getTableAPI(node.data.tableData.id);
          node.data.tableData = sData;
          node.data.insertFields?.length>0 && node.data.insertFields.map((fld,index)=>{
            const idx = sData.tableinfo.columns.findIndex(col=>col.data===fld.key)
            if(idx===-1) {
            //if field no longer exists, remove it
              node.data.insertFields = node.data.insertFields.filter(i=>i.key !==fld.key);
            } else {
              fld.text = sData.tableinfo.columns[idx].header
            }
          })
        }
        // 9-11-24 now process all source nodes 
        if(node.data?.sourceNodes?.length>0) {
          for(const sourceNode of node.data.sourceNodes) {
             if(sourceNode.data?.tableData?.id !==undefined) {
              const sData = await getTableAPI(sourceNode.data.tableData.id);
              sourceNode.data.tableData = sData;
              sourceNode.data.insertFields?.length>0 && sourceNode.data.insertFields.map((fld,index)=>{
                const idx = sData.tableinfo.columns.findIndex(col=>col.data===fld.key)
                if(idx===-1) {
                //if field no longer exists, remove it
                sourceNode.data.insertFields = sourceNode.data.insertFields.filter(i=>i.key !==fld.key);
                } else {
                  fld.text = sData.tableinfo.columns[idx].header
                }
              })
             }
          }
      }

      }

      // console.log(flow)

      tempWorkFlow.workflow = flow;
      await updateWorkFlow(tempWorkFlow);
      refresh(tempWorkFlow)

      toast.info(<div style={{margin:'10px'}}>Workflow schema has been refreshed!</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    }
  }

  const confirmChanges =() =>{
 
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title={'Save changes?'}
              action={saveAndClose}
              buttonLabel="OK"
              message='Would you like to save your changes?'
              divRef={divRef}
              showInput={false}
              confirmAction="Save & close"
              height="250px"
              onClose={onClose}
            />
          );
        },
      });
   
  }


  const addNode = (label, nodeData,type) => {

    nodeData.label = label;
    const nodeId = (nodes.length + 1).toString();
    nodeData.id = nodeId;

    const newNode = {
      id: nodeId,
      type: type,
      data: nodeData,
      position: { x: window.innerWidth /2, y: 200 },
    };

    // Add the new node to the existing nodes array
    setNodes((nds) => [...nds, newNode]);
    setCurrentNode(null);
    setModalIsOpen(false);
    setIsChanged(true);
  };


  const updateNode = (nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                ...newData,
              },
            }
          : node,
      ),
    );

    setModalIsOpen(false);
    setCurrentNode(null);
    setIsChanged(true);
  };


  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);


  const onConnectEnd = _.debounce((event) =>  {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
       
        const newNode = {
          id,
          type:'action',
          position: reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `Action`,id },
          origin: [0.5, 0.0],
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id }),
        );
      }
    },300)


  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === 'custom').data.selects[edge.sourceHandle];
      edge.type = edgeType;
    } else {
      edge.type = 'smoothstep';
    }

    return edge;
  });


  return (
    <div style={{position:'relative'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          backgroundColor: '#0D99FF',
        }}
      >
        <div
          onClick={()=>closeLocal()}
          css={{
            marginLeft: '20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize:'25px',
            cursor:'pointer',
            '&:hover': {
              color: '#FFFFFF80',
              transition: 'all .3s ease',
                svg: {
                  color: '#FFFFFF80',
                  transition: 'all .3s ease',
                },
              },
          }}
        >
          <Icon path={mdiArrowLeft} size="30px" />
          <Icon path={mdiArrowDecisionOutline} size="30px" />
          <div>{workFlowObj?.workflowname}</div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginRight: '20px',
          marginTop: '10px',
          height: '40px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <GBButton
       
            text="Save"
            Action={onSave}
            // Action={handleClick}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#0D9953'}
            icon={mdiContentSave}
            iconSize="30px"
          />
          {isChanged ? (
            <div style={{marginLeft:'10px',marginRight:'10px'}}>
            <GBButton
            text="Cancel changes"
           
            Action={()=>close(true)}
            // Action={handleClick}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#F6685E'}
            icon={mdiClose}
            iconSize="30px"
          /></div>
          ): null}

           {nodes.length > 0 ? (
            <>
          <div style={{ width: '20px' }} />
          <GBButton
            text="Refresh schemas"
            width='200px'
            Action={refreshSchema}
            // Action={handleClick}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#0D9953'}
            icon={mdiRefresh}
            iconSize="30px"
          /> </>) : null}

          <div style={{ width: '20px' }} />
        </div>
      </div>

      <div style={{ width: '100vw', height: 'calc(100vh - 150px)',overflow:'hidden' }}  className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edgesWithUpdatedTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeDoubleClick={onEdgeDoubleClick}
          onNodeDoubleClick={(event, node) => {
            editNode(node);
        }}

          onConnect={!modalIsOpen ? onConnect : null}
          onConnectStart={!modalIsOpen ? onConnectStart: null}
          onConnectEnd={!modalIsOpen ? onConnectEnd : null}
          
          nodesDraggable={!modalIsOpen}
          nodesConnectable={!modalIsOpen}
          elementsSelectable={!modalIsOpen}
          // fitView ={nodes.length>2}
          // defaultViewport={nodes.length===1 ? {x:(window.innerWidth/2-100), y:200,zoom:1} : {}}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
        >
          <MiniMap style={minimapStyle} zoomable pannable />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>

      {modalIsOpen ? (
        <div
          style={{
            position: 'absolute',
            overflow: 'auto',
            zIndex: 0,
            top: 60,
            right: 0,
            backgroundColor: nodeType === 'action' ? '#ECF7FF' : '#F3FCF7',
            height: 'calc(100vh - 60px)',
            width: '550px',
          }}
        >
          {nodeType === 'trigger' ? (
            <TriggerPanel
              close={closeNode}
              addNode={addNode}
              nodeData={currentNode}
              updateNode={updateNode}
              removeNode={removeNodeAndEdges}
              hasActions={nodes.length>1}
            />
          ) : null}

        {nodeType === 'action' ? (
            <ActionPanel
              close={closeNode}
              addNode={addNode}
              sourceNodes={sourceNodes}
              nodeData={currentNode}
              updateNode={updateNode}
              removeNode={removeNodeAndEdges}
            />
          ) : null}


        </div>
      ) : null}
    </div>
  );
};



export default ({workFlowObj, refresh, close}) => (
    <ReactFlowProvider>
      <WorkFlow workFlowObj={workFlowObj} refresh={refresh} close={close} />
    </ReactFlowProvider>
  );
