/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import PNL from 'google-libphonenumber';
import FinalForm, { Form, FormField, FormCard, validators } from '../../components/Form';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import GBSubmit from '../../components/GBSubmit/GBSubmit';
import firebase, { phoneProvider } from '../../clients/firebase';
import { ReactComponent as Logo } from '../../containers/Auth/PhoneValidator/Success.svg';

const AddMultiFactor = ({ userid, color, close, updateMobile,callingComponent }) => {
  const formValidators = {
    phoneNumber: validators.composeValidators(validators.required, validators.phoneNumber),
    password: validators.composeValidators(validators.required),
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [smsCodeError, setSmsCodeError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [mobile, setMobile] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-validator', {
      size: 'invisible',
    });
  }, []);

  const sendCode = async (values) => {
    try {
      const user = firebase.auth().currentUser;
      setMobile(values.phoneNumber);

      const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.password);

      // Now you can use that to reauthenticate
      await user.reauthenticateWithCredential(credential);

      var multiFactorSession = await user.multiFactor.getSession();

      var phoneInfoOptions = {
        phoneNumber: values.phoneNumber,
        session: multiFactorSession,
      };

      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const id = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier,
      );

      setVerificationId(id);
      setIsSubmitted(true);
      setSubmitError('');
    } catch (error) {
      setSubmitError(error.message);
      setIsSubmitted(false);
    }
  };

  const verifySMSCode = async (values) => {
    setSmsCodeError('');
    const user = firebase.auth().currentUser;
    try {
      const cred = await firebase.auth.PhoneAuthProvider.credential(verificationId, values.code);

      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      await user.multiFactor.enroll(multiFactorAssertion, 'Personal phone number');

      const phoneUtil = PNL.PhoneNumberUtil.getInstance();  
       const phonenumber = phoneUtil.parse(mobile);
       const intlnumber = phoneUtil.format(phonenumber,PNL.PhoneNumberFormat.INTERNATIONAL)

      await updateMobile(intlnumber);
      setSmsCode(values.code);
    } catch (error) {
      setSmsCode('');
      if (error.message.includes('invalid')) {
        setSmsCodeError('Incorrect or expired code, please try again.');
      } else {
        setSmsCodeError(error.message);
      }
    }
  };

  const SectionHeader = () => {
    let header = '';
    if (!isSubmitted) {
      header = 'Add phone';
    } else if (isSubmitted && smsCode === '') {
      header = 'Mobile phone validation';
    } else if (isSubmitted && smsCode !== '' && smsCodeError === '') {
      header = 'Multi-factor enabled!';
    }
    return header;
  };

  return (
    <div>
      <div id="phone-validator" />
      {callingComponent !=='blocklist' ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: color,
          textAlign: 'center',
          paddingLeft: '20px',
          color: 'white',
          verticalAlign: 'center',
          height: '40px',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
        }}
      >
        {SectionHeader()}
      </div>
      ): null}

      {!isSubmitted && !isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '5px', display: 'inline' }}>
            <div style={{ marginLeft: '23px' }}>
              {callingComponent==='blocklist' ? (
                <div>Multifactor authentication has been enabled for one or more of your Zones.<br/> To continue, add your mobile phone number, and validate it. <p/>Future logins will require text verification using this number.</div>
              ): <div>Multifactor authentication requires you to add your mobile phone for text verification.</div>}
              
            </div>

            <FinalForm onSubmit={sendCode}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div style={{ margin: '20px' }}>
                      <FormField
                        name="password"
                        label="Password"
                        color={color}
                        placeholder="Enter your password."
                        validate={formValidators.password}
                        control={Form.PasswordControl}
                        controlId="id_password"
                      />
                      <FormField
                        name="phoneNumber"
                        label="Mobile Phone"
                        color={color}
                        placeholder="Enter your mobile phone number."
                        validate={formValidators.phoneNumber}
                        control={Form.PhoneNumberControl}
                      />
                      {submitError !== '' ? (
                        <div style={{ color: 'red' }}>{submitError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {close !== null ? (
                        <div style={{ marginRight: '10px' }}>
                          <TextButton
                            text="Cancel"
                            hoverColor="#75757580"
                            Action={() => close()}
                            ActinValue={false}
                            textColor="black"
                          />
                        </div>
                      ) : null}
                      <GBSubmit color={color} text="Send validation code" width="200px" />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
      {isSubmitted && !isLoading && smsCode === '' && submitError === '' ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '5px', display: 'inline' }}>
            Validation code sent. Please enter it below.
            <FinalForm onSubmit={verifySMSCode}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div style={{ margin: '20px' }}>
                      <FormField
                        name="code"
                        color={color}
                        label="Validation Code"
                        placeholder="Enter the validation code."
                        validate={formValidators.code}
                        controlId="id_code"
                      />
                      {smsCodeError !== '' ? (
                        <div style={{ color: 'red' }}>{smsCodeError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {close !== null ? (
                        <div style={{ marginRight: '10px' }}>
                          <TextButton
                            text="cancel"
                            hoverColor="#75757580"
                            Action={() => close()}
                            ActinValue={false}
                            textColor="black"
                          />
                        </div>
                      ) : null}

                      <GBSubmit color={color} text="Submit" width="100px" />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
      {isSubmitted && smsCode !== '' && smsCodeError === '' ? (
        <div
          style={{
            margin: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <Logo style={{ width: '175px' }} />
          </div>
          <div
            style={{
              marginTop: '20px',
              alignSelf: 'center',
              marginBottom: '10px',
              fontWeight: 'bold',
            }}
          >
            Success!
          </div>

          <div style={{ alignSelf: 'center', marginLeft: '-10px' }}>
            <GBButton color={color} text="Ok" Action={close} width="100px" />{' '}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddMultiFactor;
