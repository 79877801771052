/* eslint-disable */
import React, { useState, useEffect,useRef } from 'react';
import { Input, Popup, Button, Dropdown, Icon } from 'semantic-ui-react';

const GBInput = ({actions, currentValue, executeAction,clearInput,recordSet }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentAction, setCurrentAction] = useState(actions[0].value);
  const [inputValue, setInputValue] = useState(currentValue ?? '');

  const dropdownRef = useRef(null); 

  //12-14-23 added the "recordSet" param to support use of this control in block grid search.
  //When filters and/or table changes, we want to reset the search param to ''. 

  useEffect(() => {
    // Function to check if click is outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Add event listener when dropdown is shown
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]); 


  useEffect(()=>{
    // 12-20-23 added check for recordset. Currently 2 places that use this control
    //in blocklist, we don't want to reset term, but in use in Blocks, we do when the table/filter combo changes.
    
    if(recordSet!=='blockList') {
      setInputValue('')
    }
  },[recordSet])


  const handleActionSelect = (e,  {value} ) => {
    setCurrentAction(value);
    setShowDropdown(false);
  };

  const getIcon=() =>{
    const idx = actions.findIndex(itm=>itm.value===currentAction)

    if(idx !==-1) {
        return actions[idx].icon
    }
  }
  const getToolTip =()=>{
    const idx = actions.findIndex(itm=>itm.value===currentAction)

    if(idx !==-1) {
        return actions[idx].toolTip
    }
  }

  const getPlaceHolder=() =>{
    const idx = actions.findIndex(itm=>itm.value===currentAction)

    if(idx !==-1) {
        return actions[idx].placeholder
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      runActionLocal(inputValue);
    }
  };

  const runActionLocal = () =>{
    executeAction(currentAction, inputValue)
    const idx = actions.findIndex(itm=>itm.value===currentAction)
    if(actions[idx].clear) {
        setInputValue('');
    }
  }

  const clear =() =>{
    setInputValue('')
    clearInput();
  }

  return (
    <Input
      style={{ width: '100%' }}
      placeholder={getPlaceHolder()}
      fluid
      icon={
        (showDropdown && inputValue.length===0) || (actions.length===1 && inputValue.length===0) ? null : (
          <Icon
            style={{ marginRight: '40px' }}
            name={inputValue.length===0 && actions.length > 1 ? "chevron down" : 'close'}
            link
            onClick={() => inputValue.length===0 ? setShowDropdown(!showDropdown): clear()}
          />
        )
      }
      action={
        showDropdown ? (
          <div ref={dropdownRef}>
          <Dropdown
            open={true}
            button
            basic
            floating
            options={actions}
            onChange={handleActionSelect}
            onBlur={() => setShowDropdown(false)}
          />
          </div>
        ) : (
          <React.Fragment>
            <Popup content={getToolTip()} 
            trigger={
              <Button icon={getIcon()} onClick={() => runActionLocal()} />
            } />
          </React.Fragment>
        )
      }
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};

export default GBInput;
