/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import Icon from '@mdi/react';

const GBButton = ({
  text,
  color,
  Action,
  textColor = 'white',
  textHoverColor = 'white',
  border = '0px',
  ActionValue,
  ActionTwo,
  width = '160px',
  borderRadius = '5px',
  icon,
  iconColor="#1A1A1A",
  iconHoverColor="#757575",
  hoverBackgroundColor,
  hoverBorder='0px solid transparent',
  iconHoverBackgroundColor,
  padding ="4px",
  iconPosition="right",
  iconSize = '15px',
  height,
  fontSize = '13px',
  fontWeight ='bold',
  showDropShadow=true
}) => {
  const ExecuteAction = () => {
    if(Action !==null) {
    Action(ActionValue);
    ActionTwo !== undefined && ActionTwo !==null ? ActionTwo() : null;
    }
  };

  return (
    <div
      tabIndex={0}
      onClick={() => ExecuteAction()}
      onKeyPress={(e) => (e.key === 'Enter' ? ExecuteAction() : null)}
      css={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        color: textColor,
        width: width,
        height: height !==undefined ? height : null,
        border: border,
        backgroundColor: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: padding,
        paddingBottom: padding,
        outline: 'none',
        borderRadius: borderRadius,
        '& .plusicon': {
          color: iconColor,
        },
        '&:hover': Action !=null ? {
          transition: 'all .3s ease',
          color: textHoverColor,
          border: hoverBorder,
          backgroundColor: hoverBackgroundColor !== undefined ? hoverBackgroundColor : null,
          boxShadow: showDropShadow ? '0px 3px 6px #757575' : null,
          '& .plusicon': {
            transition: 'all .3s ease',
            borderRadius: '20px',
            color: iconHoverColor,
            backgroundColor: iconHoverBackgroundColor !== undefined ? iconHoverBackgroundColor : null,
          },
          '& .textContainer': {
            backgroundColor: `transparent`
          }
        } : {},
      }}
    >
      {icon !== undefined ? (
        <div
          className='textContainer'
          css={{
            display: 'flex',
            height: height,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor:color,
            justifyContent: 'center',
          }}
        >
          {iconPosition==='left' ? (
          <div css={{marginRight:'5px'}}>
            <Icon className="plusicon" path={icon} size={iconSize} />
          </div>
          ): null}
          <div css={{ marginRight: '5px', fontSize: fontSize,marginTop:'3px'}}>{text}</div>
          {iconPosition==='right' ? (
          <div css={{marginLeft:'5px'}}>
            <Icon className="plusicon" path={icon} size={iconSize} />
          </div>
          ): null}
        </div>
      ) : (
        // <div style={{ fontSize: fontSize,height:height,display:'flex', flexDirection:'row',alignItems:'center',border:'1px solid red' }}>{text}</div>
        <div>{text}</div>
      )}
    </div>
  );
};

export default GBButton;
