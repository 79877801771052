/*  eslint-disable */

import React, { useCallback,useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import CustomEditor from 'src/components/CustomEditor/CustomEditor';

const DraftControl = ({ value, onFocus, onBlur, onChange }) => {
  
  const [startValue,setStartValue] = useState('');
  useEffect(()=>{
    setStartValue(value);
  },[])

  const handleChange = useCallback(
    (state) => {
      onChange(state);
    },
    [onChange],
  );

  return (
    <div style={{ width: '550px' }}>
      <CustomEditor initValue={startValue} updateContent={handleChange} callingComponent="Block" height={300} />
    </div>
  );
};

DraftControl.defaultProps = {
  value: undefined,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
};

DraftControl.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default DraftControl;
