/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { debounce } from "debounce";
import { getRelationalTableData } from '../../api/tables';

const RelationalDropdown = ({tableid,colInfo,selectedOptions,updateFieldValue}) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isSearching,setIsSearching] =useState(false);
  const [selected,setSelected]=useState(selectedOptions);

  useEffect(() => {
    fetchOptions();
  }, []);

  const updateValue = (val) => {
    setSelected(val)
    updateFieldValue(colInfo.data,val)
  }

  const handleDropdownSearch = async (value) => {
    const listdata = await getRelationalTableData(
        tableid,
        colInfo,
        3,
        value,
        100,
        null,
        'dropdown',
        false,
        true
      );
    
    setFilteredOptions(listdata);
    setIsSearching(false);
  };

  const debouncedHandleDropdownSearch = debounce(handleDropdownSearch, 500); 


  const fetchOptions = async () => {
    const listdata = await getRelationalTableData(
        tableid,
        colInfo,
        3,
        '',
        100,
        null,
        'dropdown',
        false,
        true
      );
    
      setOptions(listdata)
  };

  return  <Dropdown
          selectOnBlur={false}
          multiple={colInfo.lookup==='multiple'}
          search
          fluid
          selection
          value={selected}
          onSearchChange={(e, data) => {
            setIsSearching(true); 
            debouncedHandleDropdownSearch(data.searchQuery);
        }} 
          options={isSearching ? [] : filteredOptions.length > 0 ? filteredOptions : options}
          onChange={(e, data) => updateValue(data.value)}
        />

};

export default RelationalDropdown;
