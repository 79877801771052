import { useEffect } from 'react';
import { useQuery } from '@bizico/react-promise';
import { blocksAPI } from '../../../api/blocks';

const useBlocks = (zoneDetail = {}) => {
  const [state, refetch] = useQuery((id) => blocksAPI(id), {
    variables: zoneDetail.id,
    skip: true,
    defaultData: [],
  });

  useEffect(() => {
    if (zoneDetail.id) {
      refetch(zoneDetail.id);
    }
  }, [refetch, zoneDetail.id]);
  return state;
};

export default useBlocks;
