/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { mdiPlayBox } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';
import {CONCAT,TO_DATE,TO_NUMBER,NOW,DATEADD} from './jsFunctions'


const EvaluateFunction = ({ functionString }) => {
  const [terms, setTerms] = useState([]);
  const [results, setResults] = useState('');
  const [localFunction, setLocalFunction] = useState(functionString);

  useEffect(() => {
    extractTerms(functionString);
  }, [functionString]);

  useEffect(() => {
    setLocalFunction(functionString);
  }, [functionString]);

  const updateValue = (term, value) => {
    const tempTerms = structuredClone(terms);
    const idx = tempTerms.findIndex((el) => el.term === term);
    if (idx !== -1) {
      tempTerms[idx].value = value;
    }
    setTerms(tempTerms);
  };


  const testFunction = () => {
    
    let testString = localFunction;
    
    terms.map((term) => {
      testString = testString.replaceAll(term.term, `'${term.value}'`);
    });

    console.log(testString)

    try {
      const availableFunctions = { DATEADD,CONCAT,TO_NUMBER,NOW,TO_DATE }; // Add other functions here

        // Dynamically build the parameter list for new Function
        const functionParamNames = Object.keys(availableFunctions).join(',');
        
        // Create the function with the available functions in its scope
        const func = new Function(functionParamNames, testString);

        // Pass the available functions as arguments
        const functionArgs = Object.values(availableFunctions);
        const data = func(...functionArgs)
        console.log(data)
      
      setResults(data);
    } catch (error) {
      console.log(error)
      setResults('Error: Invalid code');
    }
  };


  const extractTerms = (str) => {
    const regex = /{{([^}]+)}}/g; // Removed optional braces
    const matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      const term = match[0];
      const idx = matches.findIndex(itm=>itm.term===term)
      if(idx ===-1) {
        matches.push({ term: term, value: '' });
      }
    }
    setTerms(matches);
  };

  return (
    <div>
      {terms.length > 0 ? (
        <div>
          {terms.map((t) => (
            <div
              style={{
                display: 'flex',
                marginBottom: '5px',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: '5px', whiteSpace: 'nowrap', width: 'maxContent' }}>
                {t.term}
              </div>
              <div style={{ width: '100%', marginRight: '20px' }}>
                <Input
                  fluid
                  value={t.value}
                  onChange={(e, data) => updateValue(t.term, data.value)}
                />
              </div>
            </div>
          ))}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
              color="#0D99FF"
              hoverColor="white"
              hoverBackgroundColor="#0D99FF"
              icon={mdiPlayBox}
              Action={testFunction}
              size="30px"
            />
            <div>{results}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EvaluateFunction;
