import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Spinner from 'react-bootstrap/Spinner';
import Spinner from '../Spinner/Spinner';
import './styles.scss';

const TYPES = {
  FIXED: 'fixed',
  STATIC: 'static',
};

const Loader = ({ className, type }) => {
  return (
    <div
      className={classNames(
        'loader',
        className,
        type === TYPES.FIXED && 'loader-fixed',
        type === TYPES.STATIC && 'loader-static',
      )}
    >
      <div className="loader-backdrop" />
      <div className="loader-spinner">
        {/* <Spinner animation="border" variant="primary" /> */}
        <Spinner isLoading isDone={false} color="#AE007B" showMessages={false} />
      </div>
    </div>
  );
};

Loader.defaultProps = {
  className: '',
  type: '',
};

Loader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Loader;
