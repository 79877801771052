/* eslint-disable */
import invalidUSRegions from '../../utils/invalidUSRegions';
import {getZoneOwner} from '../../api/zone';

const verifyValidUSPhoneNumber = async () => {

    const zoneOwner = await getZoneOwner();
    if(zoneOwner.mobile.slice(0,2) !=='+1') return false;
    const areaCode = zoneOwner.mobile.slice(2,5)
    if(zoneOwner.mobile.slice(0,2)==='+1' && invalidUSRegions.includes(areaCode)) return false
    return true
}

export default verifyValidUSPhoneNumber