/* eslint-disable */
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import numbro from 'numbro';


const TextRender = ({
  value,
  cellWidth,
  cellHeight,
  uitype,
  colInfo,
  rule,
  numericFormat,
  minValue,
  maxValue,
  TD,
  maskType,
  maskCharacters,
  uniformLength,
  color
}) => {
  const TagStyle = {
    width: cellWidth - 10,
    height: cellHeight - 7,
    float: 'left',
    textAlign: uitype === 23 ? 'justify' : null,
    paddingTop: '5px',
    // fontSize:'12px',
    lineHeight: '1',
    marginRight: '50px',
    marginLeft: '2px',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',handleButtonClick
    textOverflow: 'ellipsis',
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    if(uitype===11 && colInfo.useButtonDisplay) {
    // Add onclick event to the button after the component is mounted
    if (buttonRef.current) {
      buttonRef.current.addEventListener('click', handleButtonClick);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener('click', handleButtonClick);
      }
    };
  }
  }, []);

  const handleButtonClick = () => {
    const test = RegExp(rule, 'i');
    const url = value.toString().startsWith('http') ? value : `http://${value}`;
    const isValid = test.test(url);
    const leftPosition = (screen.width - 800) / 2;
    const topPosition = (screen.height - 600) / 2;
    const windowFeatures = `resizable,height=600,width=800,left=${leftPosition},top=${topPosition}`;
    if(isValid) {
      window.open(`${url}`, 'pagename',windowFeatures)
    }
  };

  const CreateText = () => {
    if (uitype === 3 || uitype === 2) {
      return value;
    }

    if (uitype === 10 && rule && value !== null) {
      const test = RegExp(rule, 'i');
      // 5-26-2020. We are adding System users to processing and have a specific use-case where we need to insert
      // dummy/temp email address into system when adding an existing user. The dummy record makes it so we
      // can keep user info private until they confirm they wan tto join zone. In this use-case, we append
      // a --<zoneid> to end of actual email address to keep it unique. Here we will strip that out before displaying value.
      let modifiedValue = value;
      const index = value.indexOf('--');
      if (index !== -1) {
        modifiedValue = modifiedValue.substring(0, index);
      }

      const isValid = test.test(modifiedValue);
      if ((isValid && !modifiedValue.includes(' ')) || modifiedValue === '') {
        return (
          <a style={{ color: '#669CF6' }} href={'mailto:' + modifiedValue} target="_newtab">
            {modifiedValue}
          </a>
        );
      } else {
        return (
          <div
            style={{
              width: cellWidth,
              height: cellHeight,
              backgroundColor: '#FF000080',
              marginTop: '5px',
            }}
          >
            {modifiedValue}
          </div>
        );
        // return <a href={"mailto:"+modifiedValue} target="_newtab">{modifiedValue}</a>
      }
    }
    if (uitype === 11 && rule && value !== null) {
      const test = RegExp(rule, 'i');
      const url = value.toString().startsWith('http') ? value : `http://${value}`;
      const isValid = test.test(url);
      if(isValid && colInfo.useButtonDisplay !==undefined) {
        return (
          <button style={{border:`1px solid ${color}`,outline:'none', paddingTop:'5px', paddingBottom:'4px', backgroundColor:color,color:'white',borderRadius:'5px'}} ref={buttonRef}>{colInfo.buttonLabel}</button>
          // <a title={value} href={value} target="_newtab">
          //   <div style={{width:'max-content', marginTop:'3px', backgroundColor:color,color:'white',paddingLeft:'10px',paddingRight:'10px', paddingBottom : '3px',borderRadius:'5px'}}>{colInfo.buttonLabel}</div>
          // </a>
        )
      }
      else  if ((isValid && !value.includes(' ')) || value === '') {
        return (
          <a style={{ color: '#669CF6' }} href={url} target="_newtab">
            {value}
          </a>
        );
      } else {
        return (
          <div
            style={{
              width: cellWidth,
              height: cellHeight,
              backgroundColor: '#FF000080',
              marginTop: '5px',
            }}
          >
            {value}
          </div>
        );
      }
    } else if (uitype === 27) {
      // return <button onClick={()=>console.log('Send Link')}>Send Link</button>
      // return TD.innerHTML = `<button onclick="doIt()" type='button'>press</button>`;
      return <Button style={{ width: cellWidth, height: cellHeight }}>Send Link</Button>;
    } else if (uitype === 23 && value !== '') {
      numbro.setLanguage(numericFormat.culture);
      let isNumberValid = true;
      const test = RegExp('^-?\\d*(\\.\\d+)?$', 'i');
      const isValid = test.test(value);

      if (minValue !== undefined || maxValue !== undefined) {
        let minValid = true;
        let maxValid = true;
        if (minValue !== undefined) {
          minValid = value >= parseFloat(minValue);
        }
        if (maxValue !== undefined) {
          maxValid = value <= parseFloat(maxValue);
        }
        isNumberValid = minValid && maxValid;
      }

      if (isNumberValid && isValid) {
        return value !== null ? numbro(value).format(numericFormat.pattern) : null;
      } else {
        return value !== null ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '-5px',
              height: '33px',
              backgroundColor: '#FF000080',
              marginTop: '0px',
            }}
          >
            {value}
          </div>
        ) : null;
      }
    }
    if (uitype === 14 && value !==null && value !=='') {
      let mask='*'
      if(maskType==='full' && uniformLength) {
        return  value !==null ? mask.repeat(uniformLength) : null;
      } else if (maskType==='full') {
        return  value !==null ? value.replace(/./g, '*') : null;
      } else if (maskType==='partial' && value !=null) {
        let newval=mask.repeat(value.length);
        if(value.length > maskCharacters) {
           newval = mask.repeat(value.length-maskCharacters) + value.slice(value.length-maskCharacters,value.length);
        } 
        return  newval;
      }
    }
    return null;
  };

  return (
    <div style={TagStyle}>
      {/* {typeof(value) !== 'object' ? value : null} */}
      {CreateText()}
    </div>
  );
};

TextRender.propTypes = {
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
};
export default TextRender;
