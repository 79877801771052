/* eslint-disable  */
import {getDataRow } from '../../../../../api/forms';

const preFillForm = async (formInfo,searchParams,form,updateValues) =>{

    /*  8-2-23
        Adding this form to add the logic such that if a id value is passed in the form url,
        this logic will fetch the matching data row from the table, and then will fill in the
        fieldValues array, which will populate the form with existing data. 
    */
    const directInsertControls = [2, 3, 5, 23, 9, 10, 11, 13, 14]; // stored directly in table.

    const id = searchParams['id'];

    const results = await getDataRow(id,form.tableid,formInfo);
    let fieldValues={id:searchParams['id']};

    form.columnsettings.map(el =>{
        if(results[el.data]) {
            if(directInsertControls.includes(el.fieldInfo.uitype)) {
                fieldValues[el.data] = results[el.data];
            } 
            else if(el.fieldInfo.uitype===22) {
                const d = new Date(results[el.data])
                fieldValues[el.data] =d;
            }
            else if([6,7].includes(el.fieldInfo.uitype)) {
                const ids=[]
                const values=[];
                results[el.data].map(itm=>{
                    ids.push(itm.optionid)
                    values.push(itm.data)
                })

                fieldValues[el.data]= {id:ids,label:values}
            } else if(el.fieldInfo.uitype===8) {
                const ids=[]
                const values=[];
                results[el.data].map(itm=>{
                    ids.push(itm.userid)
                    values.push(`${itm.firstname} ${itm.lastname}`)
                })

                fieldValues[el.data]= {id:ids,label:values}
            } else if(el.fieldInfo.uitype===1) {
                const ids=[]
                const values=[];
                results[el.data].map(itm=>{
                    ids.push(itm.id)
                    values.push(itm.data)
                })

                fieldValues[el.data]= {id:ids,label:values}
            }
          
        }
    })

    // 11-28-23 For all remaining fields from getDataRow, we put them into a separate object
    // which will be used later when evaluating the condiitional filters on fields. 
    //We need to convert into format of fieldValue that form expects, so we can combine the fieldValues
    //from form and these remaining rowdata values ...so it's one logic to check conditions.
    const rowData={}
    Object.keys(results).map(itm=>{
        if(fieldValues[itm] ===undefined) {
            if(Array.isArray(results[itm]) && results[itm] !==null){
                if(results[itm][0].optionid !==undefined) {
                    rowData[itm]= {id:results[itm].map(el=>el.optionid),label:results[itm].map(el=>el.data)}
                } else  if(results[itm][0].userid !==undefined) {
                    rowData[itm]= {id:results[itm].map(el=>el.userid),label:results[itm].map(el=>el.firstname)}
                } 
                else {
                    rowData[itm]= {id:results[itm].map(el=>el.id),label:results[itm].map(el=>el.data)}
                }
            }
            else if(results[itm] !==null) {
                rowData[itm] = results[itm]
            }
        }
    })

    updateValues(fieldValues,{},rowData)

}

export default preFillForm