/* eslint-disable */
export const getLogoPlacement =(placement,logoHeight,formWidth) => {
    
    /*
    {key:1,value:1, text:'Absolute position'},
    {key:2,value:2, text:'Lower left'},
    {key:3,value:3, text:'Lower middle'},
    {key:4,value:4, text:'Lower right'},
    {key:5,value:5, text:'Middle left'},
    {key:6,value:6, text:'Middle middle'},
    {key:7,value:7, text:'Middle right'},
    {key:8,value:8, text:'Top left'},
    {key:9,value:9, text:'Top middle'},
    {key:10,value:10, text:'Top right'},
    */

    let coords ={}
    if(placement==1) {
        coords={top:0,left:0,bottom:null,right:null}
    }
    else if(placement==2) {
        coords={top:-logoHeight/2,left:20,bottom:20,right:null}
    } else if(placement==3)
    {
        coords={top:-logoHeight/2,left:'50%',bottom:20,right:null}
    } else if (placement==4)
    {   
        coords={top:-logoHeight/2,left:null,bottom:null,right:20}
    } else if(placement==5)
    {
        coords={top:'50%',left:10,bottom:null,right:null,transform:'translate(0,-50%)'}
    } else if (placement==6)
    {
        coords={top:'50%',left:'50%',bottom:null,right:null,transform:'translate(-50%,-50%)'}
    }  else if (placement==7)
    {
        coords={top:'50%',left:null,bottom:null,right:20,transform:'translate(0,-50%)'}
    }
    else if (placement==8)
    {
        coords={top:50,left:10,bottom:null,right:null,transform:null}
    }
    else if (placement==9)
    {
        coords={top:10,left:'50%',bottom:null,right:null,transform:'translate(-50%)'}
    }
    else if (placement==10)
    {
        coords={top:10,left:null,bottom:null,right:20,transform:null}
    }

    return coords
}