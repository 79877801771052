import React, { useState, useMemo, useCallback } from 'react';
import Alert from './Alert';
import { AlertIcon } from '../Icon';

const useAlert = (props = {}) => {
  const { icon, title, message, small = false, onClose = () => {} } = props;
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ icon, title, message, small });

  const handleShow = useCallback(
    ({ title: nTitle, message: nMessage }) => {
      setData((prew) => ({
        ...prew,
        title: nTitle,
        message: nMessage,
      }));
      setShow(true);
    },
    [setShow, setData],
  );

  const handleHide = useCallback(
    (...args) => {
      setShow(false);
      onClose(...args);
    },
    [setShow, onClose],
  );

  const callbacks = useMemo(
    () => ({
      show: handleShow,
      hide: handleHide,
    }),
    [handleShow, handleHide],
  );

  const Component = useMemo(
    () => (
      <Alert
        icon={data.icon || <AlertIcon color="#fff" title="Alert" inline />}
        title={data.title}
        show={show}
        onHide={handleHide}
        small={data.small}
      >
        {data.message}
      </Alert>
    ),
    [show, handleHide, data],
  );

  return useMemo(() => [Component, callbacks], [Component, callbacks]);
};

export { Alert, useAlert };
