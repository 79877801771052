/* eslint-disable */
import React,{useState} from 'react';
import { Input } from 'semantic-ui-react';

const URLFiles = ({ addUrl,category }) => {

const [url,setUrl] = useState('')

const handleClick =() => {
    const lastIndex = url.lastIndexOf('/')
    const name=url.slice(lastIndex+1);
    
    //3-21-23 for youtube and vimio urls', will try to convert to the embed format.
    let newurl=url;
    if(url.toLowerCase().startsWith('https://youtu.be')){
      newurl=`https://www.youtube.com/embed/${name}`
    } else if(url.toLowerCase().startsWith('https://vimeo.com')) {
      newurl=`https://player.vimeo.com/video/${name}`
    }

    addUrl(newurl,category,name);
    setUrl('');
}

  return (
    <div
      style={{
        display: 'flex',
        height: '200px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>
        Enter the URL for the file you wish to upload.
      </div>
      <div style={{ width: '400px' }}>
        <Input
          style={{ width: '100%' }}
          value={url}
          action={{
            content: 'Fetch',
            onClick:handleClick,
          }}
          onChange={(e,data) =>setUrl(data.value)}
          placeholder="http://"
        />
      </div>
    </div>
  );
};

export default URLFiles;
