/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core'
import { Popup } from 'semantic-ui-react';
import { Checkbox } from 'semantic-ui-react';
import UserCard from './UserCard';
import Avatar from '../../components/Avatar/Avatar';

const UserItem = ({
  item,
  lookup,
  backgroundColor,
  color,
  Action,
  isSelected,
  checkBoxHandler,
}) => {

  return (
   
    <div
      css={{
        zIndex:'30px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft:'10px',
        '&:hover': {
          transition: 'all .3s ease',
          backgroundColor: '#F1EFEF',
        },
      }}
    >
      {lookup === 'multiple' ? (
        <Checkbox
          checked={isSelected(item.userid)}
          onChange={(e, data) => checkBoxHandler(data.checked, item)}
        />
      ) : null}
       <Popup
           flowing
           hoverable
            mouseEnterDelay={1000}
            position="top center"
            trigger={
      <div
        onClick={() => Action(item)}
        css={{
          width: 'max-content',
          margin: '10px',
          // backgroundColor: backgroundColor,
          // color: color,
          paddingLeft: '3px',
          // height: '16.67px',
          display: 'flex',
          verticalAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          paddingRight: '10px',
          borderRadius: '10px',
        }}
      >
        <Avatar item={item} />
        {item.firstname} {item.lastname}
      </div>
      }>
      <UserCard user={item} />
      </Popup>
    </div>
  );
};

export default UserItem;
