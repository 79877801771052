/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input } from 'semantic-ui-react';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import { MapOptionValuesToText } from '../../../../api/GetData';

class ShortText extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo, selectedUIType } = this.props;

    this.state = {
      showAdvanced: false,
      maxlength: columnConfigData.maxlength !== undefined ? columnConfigData.maxlength : 255,
      callout: columnConfigData.callout,
      defaultText: columnConfigData.defaultText,
      uitype: selectedUIType,
      tableinfo,
      isPrivate: columnConfigData.isPrivate ?? false
      //   defaultText: '',
    };
  }

  static contextType = AccountContext;

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedUIType !== prevProps.selectedUIType) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ uitype: this.props.selectedUIType });
    }
  }

  Save = async () => {
    const { maxlength, callout, defaultText, tableinfo, uitype,isPrivate } = this.state;
    const { field, tableid, header, selectedUIType, columnConfigData } = this.props;
    const originalUIType = columnConfigData.uitype;

    try {
      // Update physical field in DB with default text.
      if (defaultText !== undefined) {
        const payload = { field, tableid, defaultText, uitype: selectedUIType };
        await cloudrun.post('/updateFieldDefaultText', { payload });
      }

      // 5-9-2022 I changed logic to just start with current ColumnConfigData. The reason was 
      // I was losing concact attribute. Below, I have line that was deleting it, but it should only
      // delete it IF the field type is changing. 
  //     const defaultNode = `{
  //     "data": "${field}",
  //     "width": 125,
  //     "header": "${header}",
  //     "uitype": ${uitype},
  //     "renderer": "TextRender",
  //     "displayfield": "${field}",
  //     "detailviewsort": ${columnConfigData.detailviewsort !==undefined ? columnConfigData.detailviewsort : 100}
  // }`;

      // const updatedNode = JSON.parse(defaultNode);
      const updatedNode = structuredClone(columnConfigData);
      updatedNode.header= header;
      updatedNode.uitype=uitype;
      updatedNode.displayfield=field;
      if(uitype===2 || uitype===3) {
        updatedNode.renderer='TextRender';
      } else if (uitype===13) {
        updatedNode.renderer='RFTEditor';
      }
  
      // Update Tableinfo with new settings.
      const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
      if (rowindex !== -1) {
        if (maxlength !== '' && uitype===2 ) {
          updatedNode.maxlength = maxlength >255 ? 255 : maxlength;
        } else if(uitype===2){
          updatedNode.maxlength=255;
        } else {
          delete updatedNode.maxlength
        }

        if(isPrivate) {
          updatedNode.isPrivate =true;
        } else {
          delete updatedNode.isPrivate;
        }

        updatedNode.callout = callout;
         // if changed uitype remove concat attribute.
         // main index calls the api to recalcualte view.
        if(originalUIType !==uitype){
          delete updatedNode.concat
        }
        
        updatedNode.defaultText = defaultText;
        if (uitype === 3) {
          updatedNode.editor = 'TextEditor';
        } else {
          delete  updatedNode.editor;
        }

        tableinfo.columns[rowindex] = updatedNode;

        // 1-9-23 adding logic, such that if previous field type was not RTF and new type is RTF
        // we need to delete all prior data as RTF content is very specific.
        // if(originalUIType !==13 && uitype===13 ) {
        //   const payload = {
        //     tableid,
        //     field
        //   };
        //   await cloudrun.post('/deleteFieldContent', { payload });
        // }

        // if we are coming from numeric field OR an ID field, convert numeric back to text.
        if (originalUIType === 23) {
          const payload = {
            tableid,
            tableinfo,
            field,
            newfieldtype: 'citext',
            castFunction: 'cast_numeric_to_text',
          };
          await cloudrun.post('/convertField', { payload });
        } else if (originalUIType === 22) {
          const payload = {
            tableid,
            tableinfo,
            field,
            newfieldtype: 'citext',
            castFunction: 'cast_date_to_text',
          };
          await cloudrun.post('/convertField', { payload });
        } else if(originalUIType ===17) {
          const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
          await cloudrun.post('/convertField', { payload })
        } else if(originalUIType===3 || maxlength < columnConfigData.maxlength) {
          const max = maxlength !== undefined ? maxlength : 255
          const payload = {tableid,field,maxlength:max,dbfunction:'truncateFieldText'};
          await cloudrun.post('/executeQueryAPI', { payload })
        }

        // if we're coming from select field, we need to map optios to text values
        // and then remove associated/related tables. This is doen via removefield API passing removeStructureOnly=true
        else if (originalUIType === 6 || originalUIType === 7) {
          delete updatedNode.lookup;
          delete updatedNode.enableColors;

          const table = `tbl_${tableid}`;
          await MapOptionValuesToText(table, columnConfigData.data,maxlength);

          const payload = {
            tableid,
            fieldname: columnConfigData.data,
            removeRelatedStructureOnly: true,
          };
          await cloudrun.post('/removeField', { payload });
        } else if(originalUIType===10 || originalUIType===11){
          delete updatedNode.validator;
        }

        // we update the meta data last, otherwise functions above would be retreiving wrong starting metadata.
        await updateTableInfoAPI(tableid, tableinfo,columnConfigData);

        return 'success';
      }
    } catch (error) {
      console.log(error);
      return `An Error Occurred: ${error.message}`;
    }
    return 'Error, no matching column foiund';
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  render() {
    const { showAdvanced, callout, defaultText, uitype,isPrivate } = this.state;
    const { color } = this.props;
    return (
      <div>
        <div style={{marginTop: '5px' }}>
          {uitype === 2 ? (
            <div>
              Users will be able to enter no more than 255 characters in this field possibly less
              based on the field attributes. While long text supports more text in the field, this
              field type is recommended if export and/or reporting on this data might be necessary.
            </div>
          ) : null}
          {uitype ===3 ? (
            <div>
              Users will be able to enter up to 10,000 characters in this field. This field type is
              good for long descriptive text, but if export and/or reporting on this data might be
              necessary to consider using the short text field type instead.
            </div>
          ):null}
          {uitype ===13 ? (
            <div>
              Users will be able to use a rich text editor to format their text. This field type is
              good for marking up text in html format and can also be used to include mentions of other
              users who will get notified of any changes.
            </div>
          ):null}
          
        </div>
        
        <div style={{height:'15px'}}></div>
        <GBSwitch
          color={color}
          text="More field attributes"
          isChecked={showAdvanced}
          Action={this.showAdvancedHandler}
        />

        {showAdvanced ? (
          <div>
            <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            {uitype === 2 ? (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                <div style={{ marginBottom: '3px' }}>
                  <strong>Maximum field length</strong>
                </div>
                <div
                  style={{
                    width: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    size="small"
                    type="number"
                    max="255"
                    value={this.state.maxlength || ''}
                    onChange={(e, data) => this.setState({ maxlength: data.value })}
                    style={{ width: '100px' }}
                    placeholder="max characters"
                  />
                  <span style={{ marginLeft: '3px' }}> Characters </span>
                </div>
              </div>
            ) : (
              ''
            )}

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <div style={{ marginBottom: '3px' }}>
                <strong>Default text</strong>
              </div>
              <Input
                size="small"
                onChange={(e, data) => this.setState({ defaultText: data.value })}
                value={defaultText || ''}
                placeholder="Enter default text (optional)"
              />
            </div>
            


            <Callout callout={callout} updateCallout={this.CalloutHandler} />
          </div>
        ) : null}
      </div>
    );
  }
}

ShortText.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default ShortText;
