/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiClose, mdiChevronDown } from '@mdi/js';
import FilterOperators from './FilterOperators';

const MessageFilter = ({
  filter,
  field,
  UpdateFilter,
  displayfield,
  header,
  uitype,
  remove,
  placeholder,
  color,
}) => {
  const [operator, setOperator] = useState('incoming-outgoing');
  const [fieldvalue, setFieldvalue] = useState('');
  const [timeframe, setTimeFrame] = useState(24);
  const [direction, setDirection] = useState('incoming');
  const [status, setStatus] =useState(['undelivered']);
  const [error, setError]=useState(['30007']);

  useEffect(() => {
    if (filter.value != null) {
      if(filter.operator ==='status') {
        setFieldvalue([filter.value])
        setStatus(filter.value)
      } else if(filter.operator==='error') {
        setFieldvalue([filter.value])
        setError(filter.value)
      }
      else {
        setFieldvalue(filter.value);
      }
      
      setOperator(filter.operator);
      setTimeFrame(filter.timeframe);
    } else {
      updatefilter('incoming','incoming-outgoing')
    }

  }, []);

  const statusOptions =[
    {key:'delivery_unknown', value:'delivery_unknown',  text:'⚠ Delivery_unknown'},
    {key:'failed', value:'failed',  text:'⚠ Failed'},
    {key:'undelivered', value:'undelivered',  text:'⚠ Undelivered'},
    {key:'accepted', value:'accepted',  text:'Accepted'},
    {key:'delivered', value:'delivered',  text:'Delivered'},
    {key:'queued', value:'queued',  text:'Queued'},
    {key:'received', value:'received',  text:'received'},
    {key:'sending', value:'sending',  text:'Sending'},
    {key:'sent', value:'sent',  text:'Sent'},
]

const errorCodes = [
  {key:'30001', value:'30001',  text:'30001: Queue overflow'},
  {key:'30002', value:'30002',  text:'30002: Account suspended'},
  {key:'30003', value:'30003',  text:'30003: Unreachable destination handset'},
  {key:'30004', value:'30004',  text:'30004: Message Blocked'},
  {key:'30005', value:'30005',  text:'30005: Unknown destination handset'},
  {key:'30006', value:'30006',  text:'30006: Landline or unreachable carrier'},
  {key:'30007', value:'30007',  text:'30007: Message filtered'},
  {key:'30008', value:'30008',  text:'30008: Unknown error'},
  {key:'30009', value:'30009',  text:'30009: Missing inbound segment'},
  {key:'30010', value:'30010',  text:'30010: Message price exceeds max price'},
  {key:'30013', value:'30013',  text:'30013: TTL is too big'},
  {key:'30017', value:'30017',  text:'30017: Carrier network congestion'},
  {key:'30018', value:'30018',  text:'30018: Requires sender ID pre-registration'},
  {key:'30019', value:'30019',  text:'30019: Content size exceeds carrier limit'},

]

  const updatefilter = (data, tmpoperator,newtimeframe=timeframe) => {
    setFieldvalue(data);

    if (tmpoperator !== undefined) {
      const filterObject = {
        field,
        header,
        value: data,
        timeframe:newtimeframe,
        operator: tmpoperator,
        displayfield,
        uitype,
      };
      UpdateFilter(filterObject);
    } else {
      const filterObject = { field, header, value: data, timeframe, operator, displayfield, uitype };
      UpdateFilter(filterObject);
    }
  };

  const changeDirection =(dir) => {
    setDirection(dir);
    updatefilter(dir,'incoming-outgoing')
  }

  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    if(newoperator==='status') {
      updatefilter(['undelivered'],newoperator)
    } else if(newoperator==='error') {
      updatefilter(['30007'],newoperator)
    }
    else {
      updatefilter(fieldvalue, newoperator);
    }
  };

  const changeTimeframe = (tf) => {
      setTimeFrame(tf);
      updatefilter(fieldvalue,'timeframe',tf)
  }

  const changeStatus =(tmpstatus) =>{
    setStatus(tmpstatus)
    updatefilter(tmpstatus,'status')
  }

  const changeError =(tmperror) =>{
    setError(tmperror)
    updatefilter(tmperror,'error')
  }

  const removeFilter = (newfield) => {
    remove(newfield);
  };

  const filterOption = [
    { key: 'incoming-outgoing', text:'Incoming/Outgoing',value:'incoming-outgoing'},
    { key: 'contains', text: 'Search string', value: 'contains' },
    { key: 'status', text: 'Last message delivery status', value: 'status' },
    { key: 'error', text: 'Last message error code', value: 'error' },
    { key: 'timeframe', text: 'Time frame', value: 'timeframe' },
    { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
    { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
  ];
  const timeframes = [
    { key: '24', text: 'Last 24 hours', value: 24 },
    { key: '48', text: 'Last 48 hours', value: 48 },
    { key: '72', text: 'Last 72 hours', value: 72 },
    { key: '168', text: 'Last 7 days', value: 168 },
    { key: '336', text: 'Last 14 days', value: 336 },
    { key: '720', text: 'Last 30 days', value: 720 },
    { key: '2160', text: 'Last 90 days', value: 2160 },
    { key: '4320', text: 'Last 180 days', value: 4320 },
    { key: '8640', text: 'Last 360 days', value: 8640 },
    { key: 'thisquarter', text: 'This quarter', value: 'thisquarter' },
    { key: 'lastquarter', text: 'Last quarter', value: 'lastquarter' },
    { key: 'thisandlast', text: 'This and Last quarter', value: 'thisandlastquarter' },
    { key: 'ytd', text: 'Year to date', value: 'ytd' },
    { key: 'lastyear', text: 'Last Year', value: 'lastyear' },
  ];

  const flowdirections = [
    { key: 'incoming', text: 'Last message was incoming', value: 'incoming' },
    { key: 'outgoing', text: 'Last message was outgoing', value: 'outgoing' },
  ]

  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header={header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input style={{ width: '100%' }} value={displayvalue} />
      ) : null}
      {operator === 'contains' ? (
        <Input
          value={fieldvalue}
          onChange={(e, data) => updatefilter(data.value)}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      ) : null}
      {operator === 'timeframe' ? (
        <Dropdown
          fluid
          selection
          value={timeframe}
          icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
          // style={{ fontSize: '14px', color: '#486FB3' }}
          options={timeframes}
          onChange={(e, data) => changeTimeframe(data.value)}
        />
      ) : null}
      {operator==='incoming-outgoing' ? (
        <Dropdown
        placeholder='Select a message direction'
        fluid
        selection
        value={direction}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={flowdirections}
        onChange={(e, data) => changeDirection(data.value)}
        />
      ): null}
      {operator==='status' ? (
        <Dropdown
        placeholder='Select a status'
        fluid
        multiple
        selection
        value={status}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={statusOptions}
        onChange={(e, data) => changeStatus(data.value)}
        />
      ): null}
       {operator==='error' ? (
        <Dropdown
        placeholder='Select a error code'
        fluid
        multiple
        selection
        value={error}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={errorCodes}
        onChange={(e, data) => changeError(data.value)}
        />
      ): null}
    </div>
  );
};

MessageFilter.propTypes = {
  field: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  uitype: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  displayfield: PropTypes.string.isRequired,

  filter: PropTypes.object.isRequired,
  UpdateFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default MessageFilter;
