/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource @emotion/react */
import React from 'react'
import Icon from '@mdi/react'
import { css, jsx } from '@emotion/core'
import {Popup} from 'semantic-ui-react';
import { mdiPlus,mdiCheckboxBlankOutline} from '@mdi/js';

const AddTabButton = ({isExpanded,color,Action}) => {

  
    return (
        <div
          title={!isExpanded ? "Add tab" : ''}
            css={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
              margin: '5px',
              color:'#757575',
              svg : {
                  color: '#746666',
              },
            //   svg : {
            //       color: {color},
            //   },
              cursor: 'pointer',
              '&:hover': {
                backgroundColor:'#F1EFEF',
                border: `1px solid ${color}`,
                color,
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                svg : {
                    borderRadius:'20px',
                    transition: 'all .3s ease',
                    color: 'white',
                    backgroundColor: `${color}80`,
                  },
            },
            }}
            onClick={() => Action('tab1',mdiCheckboxBlankOutline)}
          >
              <Icon style={{ marginRight: '10px' }} path={mdiPlus} size="15px"  />
              {isExpanded ? <div>Add tab</div> : null}
          </div>
    )
}

export default AddTabButton