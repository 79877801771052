import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
// import BootstrapForm from 'react-bootstrap/Form';
// import classNames from 'classnames';
import { navigate } from '@reach/router';
import FinalForm, { Form, FormField, FormCard } from '../../../components/Form';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner/Spinner';
import ToggleButtonGroup from '../../../components/ToggleButtonGroup';
import { ArrowLeftIcon } from '../../../components/Icon';
import { ROUTES } from '../../../constants';
import ToggleButton from '../../../components/ToggleButton';
import { useAlert } from '../../../components/Alert';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';

import industries from './industries';

const formValidators = {
  // plans: validators.composeValidators(validators.required),
  // industry: validators.composeValidators(validators.required),
};

const SegmentInfo = ({ user, onSubmit, onCancel, loading, initialValues }) => {
  const initialFormValues = useMemo(() => initialValues || {}, [initialValues]);
  const [alertComponent, alert] = useAlert({ small: true });
  const [selected, setSelected] = useState(initialFormValues.usecase || []);

  const handleChange = useCallback((val) => setSelected(val), []);

  const handleSubmit = useCallback(
    (values) => {
      if (selected.length) {
        onSubmit({ ...values, usecase: selected });
      } else {
        // 9-14-2020 we won't require them to select anyting . For now I will just pass "persnosl use" as option
        // alert.show({ message: 'Please choose a plan.' });
        onSubmit({ ...values, usecase: 'PERSONAL USE' });
      }
    },
    [onSubmit, alert, selected],
  );

  const isWorkUse = selected.includes('WORK USE');

  return (
    <>
    <Button variant="clear" className="back-button" onClick={() =>navigate(ROUTES.REDIRECTION.SIGN_UP)}>
          <ArrowLeftIcon />
        </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div style={{marginBottom:'20px'}}>
      <img src={LogoIcon} alt="Logo" />
      </div>
      <FormCard title="WELCOME!">
        <FormCard.Description className="mb-2 mb-5 text-left">
          {`Hi ${user.displayName}, welcome to GraceBlocks. Let’s get you set up and ready to create your first Zone for managing the Blocks you build.`}
        </FormCard.Description>

        <FormCard.Description className="mb-4 text-left">
          Will you be using GraceBlocks for work or personal use?
        </FormCard.Description>

        <ToggleButtonGroup
          type="checkbox"
          value={selected}
          onChange={handleChange}
          separated
          className="mb-4"
        >
          <ToggleButton variant="outline-primary" value="WORK USE">
            WORK USE
          </ToggleButton>
          <ToggleButton variant="outline-primary" value="PERSONAL USE">
            PERSONAL USE
          </ToggleButton>
        </ToggleButtonGroup>

        <FinalForm onSubmit={handleSubmit} initialValues={initialFormValues}>
          {({ invalid }) => {
            return (
              <>
                <FormField
                  name="plans"
                  label="Plans"
                  placeholder="Tell us what you plan to do with GraceBlocks."
                  validate={formValidators.plans}
                  controlId="id_plans"
                />
                {isWorkUse && (
                  <FormField
                    name="industry"
                    label="Industry"
                    placeholder="Select you work industry"
                    validate={formValidators.industry}
                    controlId="id_industry"
                    control={Form.SelectControl}
                    options={industries}
                    hasEmpty
                  />
                )}
                <div className="my-5">&nbsp;</div>
                <Button long type="submit" disabled={invalid} className="mb-3">
                  Save and continue
                </Button>
                <Button onClick={onCancel} long type="button" variant="secondary">
                  Skip and continue
                </Button>
              </>
            );
          }}
        </FinalForm>
      </FormCard>
      {loading && <Spinner isLoading isDone={false} color="#AE007B" />}
      {alertComponent}
    </>
  );
};

SegmentInfo.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},

  loading: false,
  initialValues: {},
};

SegmentInfo.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  user: PropTypes.shape({ displayName: PropTypes.string }).isRequired,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default SegmentInfo;
