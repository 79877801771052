/* eslint-disable */
import React from 'react';
import GBLogo from '../Block/components/TabLists/GBViewAll.svg';
import TextButton from '../../components/TextButton/TextButton';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiClose,mdiChevronRight } from '@mdi/js';

const TopBar = ({
  zoneName,
  toggleZoneSettings,
  toggleShowInfo,
  toggleShowInvoiceHistory,
  showInfo,
  showInvoiceHistory,
  showPayment,
  showInvoice,
  loadBreadCrumb
}) => {


 const getBreadCrumb = () => {
  if(showInvoice) {
    return (
      <div style={{fontSize:'20px', display:'flex', flexDirection:'row',alignItems:'center'}}>
        <Icon path={mdiChevronRight} size="20px" />
        <div  onClick={()=>loadBreadCrumb('showInfo')}>Plans and pricing information</div>
        <Icon path={mdiChevronRight} size="20px" />
        <div onClick={()=>loadBreadCrumb('showPayment')}>Update plan</div>
        <Icon path={mdiChevronRight} size="20px" />
        <div style={{color:'white'}}>Update plan confirmation</div>
      </div>
    )
  } else if (showPayment) {
    return (
      <div style={{fontSize:'20px', display:'flex', flexDirection:'row',alignItems:'center'}}>
        <Icon path={mdiChevronRight} size="20px" />
        <div  onClick={()=>loadBreadCrumb('showInfo')}>Plans and pricing information</div>
        <Icon path={mdiChevronRight} size="20px" />
        <div style={{color:'white'}}>Update plan</div>
      </div>
    )
  }
  else if (showInfo) {
    return (
      <div style={{fontSize:'20px', display:'flex', flexDirection:'row',alignItems:'center'}}>
        <Icon path={mdiChevronRight} size="20px" />
        <div style={{color:'white'}} >Plans and pricing information</div>
      </div>
    )
  }
  else if (showInvoiceHistory) {
    return (
      <div style={{fontSize:'20px', display:'flex', flexDirection:'row',alignItems:'center'}}>
        <Icon path={mdiChevronRight} size="20px" />
        <div style={{color:'white'}} >Invoice history</div>
      </div>
    )
  }
 }

  return (
    <div
      style={{
        height: '50px',
        backgroundColor: '#ae007b',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        paddingLeft: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'row',
            color:'white',
            alignItems: 'center',
            marginRight: '10px',
          }}
        >
            <TextButton Action={!showInfo && !showInvoiceHistory && !showPayment && !showInvoice ? toggleZoneSettings : loadBreadCrumb} fontSize="20px" text={zoneName + ' Zone settings'} ActionValue="home" boldStyle="bold" icon={mdiArrowLeft} iconSize="25px"  hoverColor="#FFFFFF80" iconPosition="left"/>
          {/* <div> */}
          {getBreadCrumb()}
          {/* </div> */}
        </div>
        {!showInfo && !showInvoiceHistory ? (
          <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
            <div
              onClick={toggleZoneSettings}
              style={{
                backgroundColor: '#ffbe24',
                padding: '6px',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
            >
              <img src={GBLogo} height="30px" />
            </div>
          </div>
        ) : null}

        {showInfo ? (
          <div style={{color:'white', marginRight: '20px', display: 'flex', alignItems: 'center' }}>
            <TextButton
              icon={mdiClose}
              text="Hide plans panel"
              hoverColor="#FFFFFF80"
              Action={toggleShowInfo}
            />
          </div>
        ) : null}

        {showInvoiceHistory ? (
          <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center',color:'white' }}>
            <TextButton
              icon={mdiClose}
              text="Hide invoice history panel"
              hoverColor="#FFFFFF80"
              Action={toggleShowInvoiceHistory}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TopBar;
