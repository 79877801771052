/* eslint-disable */
import React from 'react';


const ProgressBar = ({currentPage,pages,color}) => {

    const percent=currentPage/pages*100
    return (
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <div>
               {`Step ${currentPage} of ${pages}`}
            </div>
       <div style={{width:'10px'}} />
        <div style={{width:'100px', backgroundColor:'20201F', borderRadius:'10px',backgroundColor:'#eee'}}>
            <div style={{backgroundColor:color, width:`${percent}%`, height:'20px', borderRadius:'10px'}} />
        </div>
        </div>
    )
    
}

export default ProgressBar