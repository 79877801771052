/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { AccountContext } from '../../../../providers/AccountProvider';
import {getBlocksAPI} from '../../../../api/blocks'
import FilterOperators from './FilterOperators';
import Global from '../../../../clients/global'


const SystemListFilter = (props) => {

    const [optionData, setOptionData] = useState([]);
    const [operator, setOperator] = useState('searchvalues');
    const [selectedOptions, setSelectedOptions] = useState([]);

    // const {id,specialfunction,displayfield,filter,header,field,uitype,UpdateFilter,color} = props;
    const {id,type,displayfield,filter,header,field,uitype,UpdateFilter,color,blockid} = props;

    useEffect(() => {
       let listdata=[]
        const fetchData = async () => {
           if(type==="blocks") 
           {
                listdata = await getBlocksAPI(parseInt(Global.zoneid))
           }  
            setOptionData(listdata)

           if(filter.value !== undefined)
           {
            setSelectedOptions(filter.value)
            setOperator(filter.operator)
           }
        }
        fetchData();
    }, []);

    const updateFilter = (items, tmpoperator) => {
           
            setSelectedOptions(items);

            const textvalues = []
            items.forEach(itm => {
                const rowindex = optionData.findIndex(el => el.value === itm)
                if (rowindex !== -1) { textvalues.push(optionData[rowindex].text) }
            })

            const filterObject = {
                 field,
                 header,
                 value: items,
                 uitype,
                 textvalues,
                 displayfield,
                 operator: tmpoperator===undefined ? operator : tmpoperator }
                 UpdateFilter(filterObject);

    }

    const filterOption = [
        { key: 'searchvalues', text: 'Search values', value: 'searchvalues' },
        { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
        { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
    ]

    const changeOperator = (newoperator) => {
        setOperator(newoperator);
        updateFilter(selectedOptions, operator);
    }

    const removeFilter =(newfield) => {
        // updateFilter([]);
        props.remove(newfield);
    }
    const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

    // Just setting this to default grey color, otherwise it will use block color.
    // keeping logic just in case we want to swap back to using colors. 

    return (
        <div style={{ marginBottom: '23px', width: '100%' }}>

    <FilterOperators
        header={header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

            {operator==='isnull' || operator==='isnotnull' ? 
            (
               <Input style={{ width: '330px' }}  value={displayvalue} />
            )
            :
            (
                <Dropdown
                selectOnBlur={false}
                multiple search fluid selection
                value={selectedOptions}
                // renderLabel={({text}) => <Label>{text}</Label>}
                options={optionData}
                onChange={(e, data) => updateFilter(data.value)}
            />
            )}
        </div>
    )
}

SystemListFilter.propTypes = {
    // eslint-disable-next-line react/require-default-props
    specialfunction: PropTypes.string,
    displayfield: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    uitype: PropTypes.number.isRequired,
    UpdateFilter: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    listsource: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
}

export default SystemListFilter