/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiKey, mdiFormatPageBreak, mdiSourceCommitStart,mdiBrightness1 } from '@mdi/js';

const GettingStarted=({color,name,apiKey}) => {

    return (
        <div style={{scrollMargin: '20px'}} id="start">
            <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}} >
                <div style={{width:'50%'}}>
                    <div style={{marginLeft:'10px',display:'flex', flexDirection:'row',alignItems:'center'}}>
                       <Icon path={mdiBrightness1} size="30px" color={color} />
                        <span style={{marginLeft:'5px',fontSize:'25px', color:color}}> Getting started</span>
                    </div>
                    <div style={{margin:'10px'}}>
                    The <b>{name}</b> API is organized around REST. It has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.
                    <p/>
                    This API is built on GraceBlocks, and while it is unique to this Block, it follows the same framework all Blocks built on GraceBlocks Also, as we release new versions and tailor functionality, the functionality may change. 
                    <p/>
                    These docs are customized and display your API key is only visible to you, and only Builders in this Block only see the Block specific API documentation, starting with the first tab listed on the left. 
                    <p/>
                    By default, we have demonstrated samples using cURL to interact with the API over HTTP.
                    <p/>
                    The samples documented will be generated using the first records in each tab. Make sure you have a few records populated in the tabs you plan to work with for the documentation to be helpful.           
                    </div>
                </div>
                <div style={{marginTop:'10px', padding:'20px',border:`2px solid ${color}`, backgroundColor:`${color}1A`, height:'200px', borderRadius:'20px',width:'50%'}}>
                   <span style={{fontSize:'14px',fontWeight:'bold'}}>JUST GETTING STARTED?</span>
                   <p/><p/>
                   Check out our <a href="https://www.graceblocks.com/support-article/developer-resources" target="_newWindow" >developer resources</a> in the GraceBlocks support portal.
                   <p/><p/>
                   <span style={{fontSize:'14px',fontWeight:'bold'}}>NOT A DEVELOPER?</span>
                   <p/><p/>
                   <a href="https://my.graceblocks.com/form?x=MTAxfGdyYWNlYmxvY2tzXzAxfDExMQ==" target="_newWindow">Contact GraceRock</a>, the services arm of GraceBlocks to enlist expert resources who can help you connect and extend what you do inside of GraceBlocks.  
                </div>
            </div>

        </div>
    )
}

export default GettingStarted