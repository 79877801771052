/* eslint-disable */
const GridSearch =(array, searchText,tableinfo) =>{

    const isObject=(item) =>{
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    const isArray=(item)=>{
        return Array.isArray(item);
    }

    const searchInValue=(value, searchText)=>{
        if (isArray(value)) {
            // If the value is an array, search each element of the array
            return value.some(element => searchInValue(element, searchText));
        } else if (isObject(value)) {
            // If the value is an object, recurse into it
            return searchInObject(value, searchText);
        } else if (value !== null && value !== undefined) {
            // Otherwise, if the value is not null or undefined, perform a search in the value
            return value.toString().toLowerCase().includes(searchText.toLowerCase());
        }
        return false;
    }


    const searchInObject = (obj, searchText, fields) => {
        return Object.entries(obj).some(([key, value]) => {
            // Skip the search for hidden fields

            if (fields && fields.includes(key)) {
                return false;
            }
            return searchInValue(value, searchText);
        });
    }

    //12-13-2023 we need to remove any columns that are hidden
    const hiddenFields = tableinfo.columns.filter(itm=>itm.hide===true).map(itm=>itm.data)

    const filteredGrid =array.filter(obj => searchInObject(obj, searchText,hiddenFields));
    return filteredGrid
    
}

export default GridSearch