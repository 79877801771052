/* eslint-disable */
import React, { useEffect, useState } from 'react';
import prettyJson from './prettyJSON';

const DeleteRecords = ({ tableData, block, blockKey, records }) => {

  const { REACT_APP_API_URL } = process.env;
  const stringColor='#fde1ab';

  const getExampleJson = () => {
    if(records.length>0) {
    const exResponse ={
        "error": false,
        "message": "success"
    }
    const  final = '<pre style="color:white">'+prettyJson(JSON.stringify(exResponse,null, 2))+'</pre>';
    return  <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>
    } else {
        return <span style={{color:stringColor}}>Need sample data</span>
    }
  }

  return (
    <div style={{ margin: '10px',scrollMargin: '20px' }} id="deleteRecords">
      <div style={{ color: block.color, fontSize: '24px' }}>
        Delete {tableData.name} records
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '30px',alignItems:'flex-start' }}
      >
        <div style={{ width: '60%' }}>
          To delete {' '}
          <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '}
          records, issue a <b>DELETE</b> request to the <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span> endpoint.
          <p/><p/>
          Specify a comma delimited list of  <span
            style={{ backgroundColor: `${block.color}1A`, padding: '4px', borderRadius: '10px' }}
          >
            {tableData.name}
          </span>{' '} id's you wish to delete. 
          <p/><p/>
          <div>Returns a simple json response with two attributes: error, message</div>
        </div>
        <div
          style={{
            backgroundColor: '#303336',
            padding: '10px',
            color: 'white',
            width: '40%',
            alignSelf: 'stretch',
          }}
        >
          <div style={{color:'#98999B'}}>EXAMPLE REQUEST</div>
          <p/><p/>
          <div>
            curl -v -X DELETE <span style={{color:stringColor}}>{REACT_APP_API_URL}/v1/{blockKey}/{encodeURI(tableData.name)}/{records.map(el=>el.id).join()}</span> \
            <p/>-H  <span style={{color:stringColor}}>"Authorization: Bearer YOUR_API_KEY"</span> 
          </div>
          <p/><p/>
          <div>
          <div style={{color:'#98999B'}}>EXAMPLE RESPONSE</div>
          <p/><p/>
          <div>{getExampleJson()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteRecords;
