const { REACT_APP_STRIPE_ENV} = process.env;

const phoneRates= [
    {country:'US', name: 'United States', rate:2.00, planId:REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC94aERqpLfk4rFkRiT3BMG' : 'price_1IG8QnERqpLfk4rF5UU7FCHs' },
    {country:'AT', name: 'Austria', rate:12.00,planId:REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC94aERqpLfk4rFNorvnrE7' : 'price_1IG8QbERqpLfk4rFqtTMXjkp'},
    {country:'AU', name: 'Australia', rate:12.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC95AERqpLfk4rFt8qMvpWl' : 'price_1IG8QNERqpLfk4rFHUrk82wR'},
    {country:'BE', name: 'Belgium', rate:2.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC95ZERqpLfk4rFfOWwdtrZ' : 'price_1IG8QAERqpLfk4rFKcQAX1Iv' },
    {country:'CA', name: 'Canada', rate:2.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC95wERqpLfk4rFOqOyRmiX' : 'price_1IG8PxERqpLfk4rF2GHIGKOV'},
    {country:'CH', name: 'Switzerland', rate:16.00,planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC96vERqpLfk4rFv7Ah3AfL' : 'price_1IG8PlERqpLfk4rFaK1sBCw3' },
    {country:'CL', name: 'Chile', rate:20.00, planId:REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC97RERqpLfk4rFoW2DoM1c' : 'price_1IG8PIERqpLfk4rFuP70UW8m'},
    {country:'CZ', name: 'Czech Republic', rate:24.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC97qERqpLfk4rFjkAyJuD4' : 'price_1IG8P3ERqpLfk4rFz6RmEoNS' },
    {country:'DE', name: 'Germany',rate:10.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC984ERqpLfk4rFjHrnvaTR' : 'price_1IG8OgERqpLfk4rFHNWJ3NpL' },
    {country:'DK', name: 'Denmark',rate:30.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC98OERqpLfk4rFckovHesH' : 'price_1IG8OUERqpLfk4rFGZaTAE4l'},
    {country:'EE', name: 'Estonia', rate:6.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC98pERqpLfk4rF7F8jwfHZ' : 'price_1IG8OHERqpLfk4rFopqJ0TwG'},
    {country:'ES', name: 'Spain', rate:2.00},
    {country:'FR', name: 'France', rate:4.00,planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9A8ERqpLfk4rFERHj2jZP' : 'price_1IG8NKERqpLfk4rFLVxpJg9K' },
    {country:'GB', name: 'United Kingdom', rate:2.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9AjERqpLfk4rFUqVNDLxp' : 'price_1IG8N8ERqpLfk4rFACOVEdtq' },
    {country:'HK', name: 'Hong Kong', rate:30.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9BMERqpLfk4rFT2hvmgq2' : 'price_1IG8MtERqpLfk4rF36m1DAZu'},
    {country:'HU', name: 'Hungary', rate:70.00, planId:  REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9BdERqpLfk4rFA2zVwUix' : 'price_1IG8MfERqpLfk4rF1wZv7JAh'},
    {country:'IL', name: 'Israel', rate:28.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9BtERqpLfk4rF2v570VGk' : 'price_1IG8MSERqpLfk4rFCHykhWet'},
    {country:'LT', name: 'Lithuania', rate:8.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9CLERqpLfk4rFQ2l5MqAx' : 'price_1IG8M4ERqpLfk4rFp6q3NBBR' },
    {country:'MX', name: 'Mexico', rate:2.00},
    {country:'MY', name: 'Malaysia', rate:10.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9EUERqpLfk4rFDp2DtqOQ' : 'price_1IG8LmERqpLfk4rFXrGVHLpb' },
    {country:'NL', name: 'Netherlands', rate:10.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9EoERqpLfk4rFROt3ByWg' : 'price_1IG8LVERqpLfk4rFxQbZqXFf' },
    {country:'PH', name: 'Philippines', rate:240.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9F6ERqpLfk4rFcSGrxEWH' : 'price_1IG8LGERqpLfk4rFVXP7n1n9'},
    {country:'PL', name: 'Poland', rate:6.00, planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9FJERqpLfk4rFXjS1ZVzE' : 'price_1IG8KcERqpLfk4rFfB7jTk6V'},
    {country:'PT', name: 'Portugal', rate:30.00, planId: REACT_APP_STRIPE_ENV==='TEST' ?  'price_1IC9FyERqpLfk4rFYIvngIqE' : 'price_1IG8JlERqpLfk4rFM7LcUdHX'},
    {country:'SG', name: 'Singapore', rate:150.00,planId: REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9GHERqpLfk4rF1YSPWw0k' : 'price_1IG8JUERqpLfk4rFwZD7Mq0p'},
    {country:'ZA', name: 'South Africa', rate:8.00, planId:  REACT_APP_STRIPE_ENV==='TEST' ? 'price_1IC9GVERqpLfk4rFo5ueTvXg' : 'price_1IG8J6ERqpLfk4rF7BRwK3KU'},
]

export default phoneRates
