/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';
import { css, jsx } from '@emotion/core'

const ExpandCollapseButton =({toggleExpand,rotate}) => {

    const expand =
    'M11.51,20.52a1.07,1.07,0,0,0,.91-.51l5.75-8.76a1.33,1.33,0,0,0,0-1.46L12.42,1A1.07,1.07,0,0,0,11,.67l-.12.09a1.33,1.33,0,0,0-.25,1.74l5.27,8-5.27,8a1.31,1.31,0,0,0,.25,1.73,1,1,0,0,0,.66.24Zm-9.9,0a1.06,1.06,0,0,0,.91-.5l5.76-8.76a1.37,1.37,0,0,0,0-1.46L2.52,1A1,1,0,0,0,1.16.62L1.08.67,1,.76A1.33,1.33,0,0,0,.71,2.5l5.28,8-5.27,8A1.32,1.32,0,0,0,1,20.28a1.07,1.07,0,0,0,.66.24Z';
     const expandhover =
    'M13.51,20.52a1.07,1.07,0,0,0,.91-.51l5.75-8.76a1.33,1.33,0,0,0,0-1.46L14.42,1A1.07,1.07,0,0,0,13,.67l-.12.09a1.33,1.33,0,0,0-.25,1.74l5.27,8-5.27,8a1.31,1.31,0,0,0,.25,1.73,1,1,0,0,0,.66.24Zm-11.9,0a1.06,1.06,0,0,0,.91-.5l5.76-8.76a1.37,1.37,0,0,0,0-1.46L2.52,1A1,1,0,0,0,1.16.62L1.08.67,1,.76A1.33,1.33,0,0,0,.71,2.5l5.28,8-5.27,8A1.32,1.32,0,0,0,1,20.28a1.07,1.07,0,0,0,.66.24Z';
    return (
        <div
          onClick={toggleExpand}
          css={{      
            cursor: 'pointer',
            '&:hover': {
              svg: {
                path: {
                  transition: 'all .5s ease',
                  color: '#949185',
                  d: `path("${expandhover}")`,
                },
                transition: 'all 1s ease',
              },
            },
          }}
          onKeyPress={toggleExpand}
        >
          <Icon path={expand} rotate={rotate} size="25px" />
        </div>
    )
}

export default ExpandCollapseButton