  /* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React, { useEffect,useState } from 'react';

import { Form, Checkbox, Radio } from 'semantic-ui-react';

const ListDisplay = ({id, value, options, updateFieldValue, lookup, color }) => {
  const [selectedOptions, setSelectedOptions] = useState(value!==undefined ? value : null);


  useEffect(() => {
   if(options.length>0 && value !==undefined){
    setSelectedOptions(value)
   }
  },[options])

  const updateSelection = (optionid,option) => {
   
    const exists = selectedOptions && selectedOptions.id[0] ===optionid

    if (lookup === 'single') {
      if(exists) {
        setSelectedOptions(null);
        updateFieldValue(id,null)
      } else {
        setSelectedOptions({id:[parseInt(optionid)],label:[option]});
        updateFieldValue(id,{id:[parseInt(optionid)],label:[option]})
      }
    } else {
      if(selectedOptions===null) {
         setSelectedOptions({id:[parseInt(optionid)],label:[option]})
         updateFieldValue(id,{id:[parseInt(optionid)],label:[option]})
      } 
      else {
           const idx =  selectedOptions.id.findIndex((el) => parseInt(el) === parseInt(optionid));
          if (idx === -1) {
            const tempOptions = selectedOptions;
            tempOptions.id.push(parseInt(optionid));
            tempOptions.label.push(option);
            setSelectedOptions(tempOptions);
            updateFieldValue(id,tempOptions)
          } else {
            const tempOptions =selectedOptions;
            tempOptions.id = tempOptions.id.filter((el) => parseInt(el) !== parseInt(optionid));
            tempOptions.label = tempOptions.label.filter(el=>el !==option)
            setSelectedOptions(tempOptions);
            if(tempOptions.id.length===0)
            {
              updateFieldValue(id,[])
            } else {
              updateFieldValue(id,tempOptions)
            }
          }
      }
    }
  };

  const displayOptions = () =>
    options.map((el) =>
      lookup === 'multiple' ? (
        <div key={el.value}>
          <Checkbox
          
            onChange={() => updateSelection(el.value,el.text)}
            checked={selectedOptions !==null  && selectedOptions.id !==undefined && selectedOptions.id.findIndex((itm) => parseInt(itm)=== parseInt(el.value)) !== -1}
            label={el.text}
          />
        </div>
      ) : (
        <div key={el.value}>
          <Radio
            onClick={() => updateSelection(el.value,el.text)}
            checked={selectedOptions !==null  && selectedOptions.id !==undefined && selectedOptions.id.findIndex((itm) => itm === parseInt(el.value)) !== -1}
            label={el.text}
          />
        </div>
      ),
    );

  return (
    <Form>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        {displayOptions()}
      </div>
    </Form>
  );
};

export default ListDisplay;
