import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { mdiCheck } from '@mdi/js';
import Icon from '../../../Icon';
import './styles.scss';

const CheckIcon = () => <Icon color="#fff" path={mdiCheck} />;

const ColorPicker = ({ value, colors, onChange, customOnChange }) => {
  return (
    <div className="color-picker-control">
      {colors.map((color) => (
        <div
          className={color === value ? 'selected' : ''}
          key={color}
          style={{ backgroundColor: color }}
          onClick={() => {
            onChange(color);
            customOnChange(color);
          }}
          onKeyPress={() => {
            onChange(color);
            customOnChange(color);
          }}
          role="button"
          tabIndex="-1"
        >
          {color === value && <CheckIcon />}
        </div>
      ))}
      <div>
              <Input
                style={{ width: '85px' }}
                label="#"
                value={value}
                onChange={(e, data) => onChange(data.value)}
                placeholder="enter hex code"
              />
            </div>
    </div>
  );
};

ColorPicker.defaultProps = {
  onChange: () => {},
  customOnChange: () => {},
  value: undefined,
  colors: [],
};

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  customOnChange: PropTypes.func,
  value: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ColorPicker;
