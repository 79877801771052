/* eslint-disable react/prop-types, no-console */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Redirect } from '@reach/router';
import firebase from '../clients/firebase';
import Spinner from '../components/Spinner/Spinner';
import { ROUTES } from '../constants';
import { getUserInfoAPI } from '../api/auth';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const [loadingInfo, setLoadingInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const handleUpdateUserInfo = useCallback(
    ({ zoneid }) => {
      setUserInfo({ zoneid });
    },
    [setUserInfo],
  );

  const listener = useCallback(
    (u) => {
      if (u) {
        setUser(u);
        /*
          Potential code to logout a user after certain amoutn of time has passed.
          const lastSignInTime = new Date(user.metadata.lastSignInTime);
        const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);
        const yesterdayTimeStamp = Math.round(new Date().getTime() / 1000) - (24 * 3600);
        if(lastSignInTimeTimeStamp < yesterdayTimeStamp){
          await firebase.auth().signOut()
          this.setState({
            loggedIn: false
          });
          return false;
        }
        */
        getUserInfoAPI()
          .then((values) => {
            setUserInfo({ ...values });
            setLoadingInfo(false);
          })
          .catch(() => {
            setLoadingInfo(false);
          });
      } else {
        setUser(null);
        setUserInfo(null);
        setLoadingInfo(false);
      }
      setLoading(false);
    },
    [setLoading, setLoadingInfo, setUser, setUserInfo],
  );

  useEffect(() => {
    const unsubribe = firebase.auth().onAuthStateChanged(listener);
    return () => {
      unsubribe();
    };
  }, [listener]);

  const value = useMemo(
    () => ({
      user,
      userInfo,
      onUpdateUserInfo: handleUpdateUserInfo,
    }),
    [user, userInfo, handleUpdateUserInfo],
  );

  if (loading || loadingInfo) {
    return <Spinner isLoading isDone={false} color="#AE007B" />;
  }

  if (!user || !userInfo) {
    return <Redirect to={ROUTES.REDIRECTION.SIGN_IN} noThrow />;
  }

  if (userInfo.phoneNumber) {
    return <Redirect to={ROUTES.REDIRECTION.DASHBOARD} noThrow />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
export { UserContext };
