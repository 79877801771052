/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';
import { Popup } from 'semantic-ui-react';


const ColumnsButton = ({
  label,
  hiddenColumnCount,
  color,
  height = null,
  Action,
  ActionValue,
  actionIcon,
  actionIcon2,
  isSelected,
  showBottom = false,
  invertColors = false,
  BottomText = '',
  marginBottom = '5px',
  marginRight = '10px',
  marginLeft = '10px',
  fontSize = '12px',
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        color: hiddenColumnCount>0 ? color : '#1A1A1A',
        fontSize: fontSize,
        height: height,
        marginBottom: marginBottom,
        justifyContent: 'center',
        marginLeft: marginLeft,
        marginRight: marginRight,
        alignItems: 'center',
        borderBottom: showBottom && isSelected ? `4px solid ${color}` : '4px solid transparent',
        outline: '0px',
        // border:'1px solid blue',
        '&:hover': {
          transition: 'all .3s ease',
          color: `${color}80`,
          svg: {
            color: invertColors ? `white` : `${color}80`,
            backgroundColor: invertColors ? `${color}` : 'white',
            borderRadius: '100%',
            transition: 'all .3s ease',
          }
        },
        '&:active': {
          color: `${color}`,
          svg: {
            color: `${color}`,
          },
        },
      }}
      onClick={Action !== null ? () => Action(ActionValue) : null}
      onKeyDown={Action != null ? () => Action(ActionValue) : null}
      role="button"
      tabIndex="0"
    >
      <div>{label}</div>
        <div css={{cursor:'pointer'}}>
        <Icon path={hiddenColumnCount===0 ? actionIcon : actionIcon2} size='25px' />
        {hiddenColumnCount>0 ? <span style={{fontSize:'12px'}}>({hiddenColumnCount})</span> : null}
        </div>
      {BottomText !== ''} {<span style={{ fontSize: '20px' }}>{BottomText}</span>}
    </div>
  );
};

export default ColumnsButton;
