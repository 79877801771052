/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import Icon from '@mdi/react';
import { mdiDragVertical, mdiPencil, mdiClose } from '@mdi/js';
import { Input } from 'semantic-ui-react';


const TextElement = ({
  id,
  type,  
  color,
  name,
  isEditMode,
  setIsEditMode,
  removeItemFromSelectedArray,
  updateFieldLabel,
  fontSize,
  fontFamily,
  textColor
}) => {


const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
        setIsEditMode(false);
    }
};

  return (
    <div
      css={{
        padding: '10px',
        width:'100%',
        '&:hover': {
          backgroundColor: `${color}1A`,

          '& .editItem': {
            display: 'flex',
          },
        },
      }}
    >
      {!isEditMode ? (
        <div
        onClick={() => setIsEditMode(true)}
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontFamily
          }}
        >
          {type === 'h1' ? (
              <h1 style={{fontFamily,color:textColor}}>{name}</h1>
          ) : null}
          {type === 'h2' ? (
              <h2 style={{fontFamily,color:textColor}}>{name}</h2>
          ) : null}
          {type === 'h3' ? (
              <h3 style={{fontFamily,color:textColor}}>{name}</h3>
          ) : null}

          <div
            className="editItem"
            css={{
              display: 'none',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            <Icon style={{ marginLeft: '10px' }} path={mdiDragVertical} size="20px" />
            <div
              css={{
                color: '#E06055',
                '&:hover': {
                  svg: { color: 'white' },
                  backgroundColor:'#E06055',
                  borderRadius:'100%',
                },
              }}
            >
              <Icon onClick={() => removeItemFromSelectedArray(id)} path={mdiClose} size="20px" />
            </div>
          </div>


        </div>
      ) : (
        <div
          css={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}
          className="h1edit"
        >
          <Input
            style={{ width: '100%' }}
            fluid
            onKeyPress={onKeyPressHandler}
            onChange={(e, data) => updateFieldLabel(id, data.value)}
            value={name}
          />
          <div style={{ width: '1px' }} />
          <Icon path={mdiDragVertical} size="20px" />
        </div>
      )}
    </div>
  );
};

export default TextElement;
