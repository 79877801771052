/* eslint-disable */
import React, { useEffect, useState} from 'react';
import { Input, Dropdown } from 'semantic-ui-react';

const ActionDeactivateUser = ({ localData, updateData, editMode, sourceNodes }) => {
  const [emailFields, setEmailFields] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? '');
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '')


  useEffect(() => {
    getEmailFields();
  }, []);


  const updateEmailValue = (val) => {
    setEmailValue(val);

    localData.emailValue =val;
    updateData(localData);
  };


  const getEmailFields = () => {
    const emailOptions = [{ key: '0', value: '', text: 'Choose record' }];

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype === 8 ||
            (el.source?.lookupuitype === 8 && el.source.lookup==='single'),
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    setEmailFields(emailOptions);

  };

  const handleEmailSelection = (val) => {
    setSelectedEmail(val);
    localData.selectedEmail = val;
    updateData(localData);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        User to deactivate
      </div>
      <div>
      <Dropdown
          selectOnBlur={false}
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        />
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}

      </div>
    </div>
  );
};

export default ActionDeactivateUser;
