import MESSAGES from './messages';

const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$+%^&*])');

export default function password(value) {
  if (value && !re.test(value)) {
    return MESSAGES.PASSWORD;
  }
  return undefined;
}
