/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Input, Radio, Form } from 'semantic-ui-react';
import CheckoutForm from './CheckoutForm';
import { stripeGetUpcomingInvoice } from '../../api/zone';
import moment from 'moment';
import { toast } from 'react-toastify';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import Spinner from '../../components/Spinner/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';
import debounce from 'lodash.debounce';
import { stripeGetPromotionCode } from '../../api/zone';
import { rules } from 'eslint-config-prettier';

const { REACT_APP_STRIPE } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE);

const PlanSelection = ({ zoneInfo, cancelPayment, plan, completePayment,color }) => {
  // https://stripe.com/docs/billing/subscriptions/per-seat

  const [paymentInterval, setPaymentInterval] = useState('monthly');
  const [userLicenses, setUserLicenses] = useState(1);
  const [volumeDiscount, setVolumeDiscount] = useState(0);
  const [credits, setCredits] = useState(0);
  const [newCredits, setNewCredits] = useState(0);
  const [planCost, setPlanCost] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeID, setPromoCodeID] = useState('');
  const [promotionDiscount, setPromotionDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [currentPlan, setCurrentPlan] = useState('');
  const [existingPlan, setExistingPlan] = useState('');
  const [usedDays, setUsedDays] = useState('');
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [planDates, setPlanDates] = useState('');
  const [actualCost, setActualCost] = useState(0);
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [isChanged,setisChanged] =useState(false)

  const { REACT_APP_STRIPE_ENV } = process.env;

  let plans = [
    { planId: 'plan_HBhXGxjV2AW2rb', name: 'Free', interval: 'monthly', unitPrice: 0.0 },
    { planId: 'plan_FreeAnnual', name: 'Free', interval: 'annual', unitPrice: 0.0 },
    { planId: 'price_1HowgiERqpLfk4rFR2EHqlKH', name: 'Starter', interval: 'monthly', unitPrice: 14.99},
    { planId: 'plan_HBhV1eAXLDop7I', name: 'Starter', interval: 'annual', unitPrice: 143.88 },
    { planId: 'price_1HowwGERqpLfk4rFtdRUDOKV', name: 'Pro', interval: 'monthly', unitPrice: 24.99 },
    { planId: 'plan_HBgyGtfCemI04T', name: 'Pro', interval: 'annual', unitPrice: 251.88 },
  ];

  if (REACT_APP_STRIPE_ENV !== 'TEST') {
    plans = [
      { planId: 'price_1IC86NERqpLfk4rFZVn2LhSR', name: 'Free', interval: 'monthly', unitPrice: 0.0 },
      { planId: 'plan_FreeAnnual', name: 'Free', interval: 'annual', unitPrice: 0.0 },
      { planId: 'price_1IC8O4ERqpLfk4rFWeGZFugS', name: 'Starter', interval: 'monthly', unitPrice: 14.99 },
      { planId: 'price_1IC8O4ERqpLfk4rFyAEVCvK2', name: 'Starter', interval: 'annual', unitPrice: 143.88 },
      { planId: 'price_1IC8bqERqpLfk4rFWyNS5BaW', name: 'Pro', interval: 'monthly', unitPrice: 24.99 },
      { planId: 'price_1IC8bqERqpLfk4rFytoeAXHC', name: 'Pro', interval: 'annual', unitPrice: 251.88 },
    ];
  }

  useEffect(() => {
    updatepaymentInterval();
    if (zoneInfo.stripeInfo.subscriptionId !== undefined) {
      setExistingPlan(plans.filter((itm) => itm.planId === zoneInfo.stripeInfo.planId)[0]);
    }
  }, []);

  const userLimitWarning = () => {

    const message=<div>
    There are currently {zoneInfo.userCount} pending or active users assigned to this
    Zone. The user license count can not be fewer than this number. Enter the number{' '}
    {zoneInfo.userCount} or higher to proceed, or first deactivate users so as to align
    the plan user license number with pending/active users in the Zone.</div>

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Licensing mismatch"
            action={onClose}
            showCancelButton={false}
            buttonLabel="OK"
            message={message}
            showInput={false}
            height="300px"
          />
        );
      },
    });

  };

  const debouncedInvoice = useCallback(
    debounce(
      (nextValue, plan, userLicenses, promoCode) =>
        getUpcomingInvoice(nextValue, plan, userLicenses, promoCode),
      2000,
    ),
    [], // will be created only once initially
  );

  const updatepaymentInterval = async () => {
    let currentPlan = '';
    if (zoneInfo.stripeInfo.planId !== undefined && zoneInfo.plan === plan) {
      currentPlan = plans.filter((itm) => itm.planId === zoneInfo.stripeInfo.planId)[0];
    }  else if(zoneInfo.stripeInfo.planId !== undefined && zoneInfo.plan !== plan) {
      currentPlan = plans.filter((itm) => itm.name === plan && itm.interval === zoneInfo.stripeInfo.interval)[0];
    }
    else {
      currentPlan = plans.filter((itm) => itm.name === plan && itm.interval === 'monthly')[0];
      setisChanged(true)
    }

    setCurrentPlan(currentPlan);
  
    if (zoneInfo.stripeInfo.interval !== undefined) {
      setPaymentInterval(zoneInfo.stripeInfo.interval);
    }
  

    if (zoneInfo.plan !== 'Free') {
      setUserLicenses(zoneInfo.userlicenses);
    } else {
      setUserLicenses(zoneInfo.userCount);
    }

    // if (
    //   zoneInfo.stripeInfo.subscriptionId === undefined ||
    //   zoneInfo.stripeInfo.planId !== currentPlan.planId
    // ) {

    //   await getUpcomingInvoice(
    //     zoneInfo.stripeInfo.subscriptionId === undefined
    //       ? zoneInfo.userCount
    //       : zoneInfo.userlicenses,
    //     currentPlan,
    //   );
    // }
  };

  const handleLicenseChange = (value) => {
    // setIsPriceLoading(true);
    setUserLicenses(value);
    setPlanCost(0);
    setAmountDue(0);
    setLineItems([]);
    setVolumeDiscount(0);
    if (zoneInfo.stripeInfo.planId === currentPlan.planId && zoneInfo.userlicenses == value) {
      setisChanged(false)
    } else {
      setisChanged(true)
    }
  };

  const handleChange = (value) => {
    setPaymentInterval(value);
    const currentPlan = plans.filter((itm) => itm.name === plan && itm.interval === value)[0];
    setCurrentPlan(currentPlan);
    setPlanCost(0);
    setAmountDue(0);
    setLineItems([]);
    setVolumeDiscount(0);
    if (zoneInfo.stripeInfo.planId === currentPlan.planId && zoneInfo.userlicenses == userLicenses) {
      setisChanged(false)
    } else {
      setisChanged(true)
    }
  };

  const getUpcomingInvoice = async () => {
    // 1-22-2021 Check if a promotion code is provided, if yet, we need to retrieve the underying
    // promtion code ID to use with the process. IF null is returned, show toast that we didn't
    // find matching code.
    let promoID='';
    if (promoCode !== '') {
      const result = await stripeGetPromotionCode(promoCode);
      if (result.promotion_code.coupon.id === null) {
        toast.info(
          <div style={{ margin: '5px' }}>
            The Promo code provided does not exist.
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
            closeButton: false,
          },
        );
        return false;
      } else {
        setPromoCodeID(result.promotion_code.coupon.id);
        promoID=result.promotion_code.coupon.id;
      }
    }

    setIsPriceLoading(true);
    if (zoneInfo.userCount > userLicenses) {
      setUserLicenses(zoneInfo.userCount);
      setPlanCost(0);
      setAmountDue(0);
      setNewCredits(0);
      setCredits(0);
      setActualCost(0);
      setNextBillingDate(null);
      setLineItems([]);
      userLimitWarning();
      setIsPriceLoading(false);
      return;
    }
    // setUserLicenses(licenseAmount);

    // if user toggles back to starting conditions, reset values to 0.
    // 2nd condition is intential == as types are different
    if (
      zoneInfo.stripeInfo.planId === currentPlan.planId &&
      zoneInfo.userlicenses == userLicenses
    ) {
      setPlanCost(0);
      setAmountDue(0);
      setLineItems([]);
      setisChanged(false);
      setIsPriceLoading(false);
      return;
    }

    // reset credits
    setNewCredits(0);
    setCredits(0);

    const result = await stripeGetUpcomingInvoice(
      zoneInfo.stripeInfo,
      currentPlan.planId,
      userLicenses,
      promoID,
    );

    if(result.error) {
      setIsPriceLoading(false);
      toast.info(
        <div style={{ margin: '5px' }}>
          {result.message}
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        },
      );
      return;
    }

    const lineItems = [];
    let credits = 0;
    let prorated = 0;
    let unused = 0;
    let due = 0;
    let actualCost = 0;

    if (zoneInfo.stripeInfo.subscriptionId !== undefined && zoneInfo.plan !== 'Free') {
      // 12-14-2020 We need to change where we look for subscription dates if the interval changes
      // between current subscription interval and newly selected interval.
      let startDate = '';
      let endDate = '';
      if (zoneInfo.stripeInfo.interval === currentPlan.interval) {
        startDate = moment(result.invoice.period_start * 1000);
        endDate = moment(result.invoice.period_end * 1000);
      } else {
        startDate = moment(result.invoice.lines.data[1].period.start * 1000);
        endDate = moment(result.invoice.lines.data[1].period.end * 1000);
      }

      const Dates = `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;

      // calculate how many days into subscription they have used (used when they choose free plan)
      const ud = moment().diff(startDate, 'days');
      setUsedDays(ud);
      setPlanDates(Dates);
      setNextBillingDate(endDate.format('MMM DD, YYYY'));

      prorated = result.invoice.lines.data[1].amount * 0.01;
      unused = result.invoice.lines.data[0].amount * 0.01;

      // if they have starting balance, apply this to total credits.
      if (result.invoice.starting_balance < 0) {
        if (result.invoice.starting_balance * -0.01 > prorated - unused * -1) {
          credits = (prorated - unused * -1) * -1;
          setCredits(credits.toFixed(2));
        } else {
          credits = result.invoice.starting_balance * 0.01;
          setCredits(credits.toFixed(2));
        }
      }

      result.invoice.lines.data.map((itm, index) => {
        const item = {
          color: itm.amount < 0 ? '#AE007B' : '#757575',
          description: itm.description,
          amount: (itm.amount * 0.01).toFixed(2),
          quantity: itm.quantity,
        };
        lineItems.push(item);
      });
      setLineItems(lineItems);
      due = result.invoice.total * 0.01;


      if (zoneInfo.stripeInfo.interval === currentPlan.interval) {
        due = (prorated + credits + unused).toFixed(2);
        if(unused*-1 > prorated || due < 0) 
        {
          setNewCredits((unused+prorated).toFixed(2))
          due=0;
        }
        setSubtotal((prorated + unused).toFixed(2));
      } else {
        const diff = result.invoice.lines.data[1].amount * 0.01 + credits + unused;
        setSubtotal((prorated + unused).toFixed(2));
        if (diff > 0) {
          due = (result.invoice.lines.data[1].amount * 0.01 + credits + unused).toFixed(2);
        } else {
          setNewCredits(diff.toFixed(2));
          due = 0;
        }
      }

      if (zoneInfo.stripeInfo.interval === currentPlan.interval && currentPlan.name !== 'Free') {
        actualCost = result.invoice.lines.data[2].amount * 0.01;
      } else {
        actualCost = result.invoice.lines.data[1].amount * 0.01;
      }
      setActualCost(actualCost);
    } else {
      // First time subscriber OR they downgraded to Free plan and are re-upgrading.
      due = (result.invoice.total * 0.01).toFixed(2);
      actualCost = result.invoice.lines.data[0].amount * 0.01;
      const lineItem = {
        color: '#757575',
        backgroundColor: '#F5F8FB',
        description: result.invoice.lines.data[0].description,
        amount: (result.invoice.lines.data[0].amount * 0.01).toFixed(2),
      };
      lineItems.push(lineItem);
      setLineItems(lineItems);
      setActualCost(actualCost.toFixed(2));

      const nextbilling = moment(result.invoice.lines.data[0].period.end * 1000);
      const startDate =  moment(result.invoice.lines.data[0].period.start * 1000);
      const Dates = `${startDate.format('MMM DD, YYYY')} - ${nextbilling.format('MMM DD, YYYY')}`;
      setNextBillingDate(nextbilling.format('MMM DD, YYYY'));
      setPlanDates(Dates)

      // if they have starting balance, apply this to total credits.
      if (result.invoice.starting_balance < 0) {
        if (result.invoice.starting_balance * -0.01 > actualCost) {
          credits = actualCost * -1;
          setCredits(credits.toFixed(2));
          due = 0;
        } else {
          credits = result.invoice.starting_balance * 0.01;
          setCredits(credits.toFixed(2));
          due = (actualCost + credits).toFixed(2);
        }
      }
       setSubtotal(actualCost.toFixed(2));
    

      // check if there are any promotion discounts and update accordingly.
      if (result.invoice.total_discount_amounts.length>0){
        setPromotionDiscount(result.invoice.total_discount_amounts[0].amount*.01.toFixed(2))
      }
      
    }

    const standardPrice =  currentPlan.unitPrice * userLicenses

    // Volume discount
    if (userLicenses > 9) {
      const volprice = standardPrice - actualCost;
      console.log(volprice);
      setVolumeDiscount(volprice.toFixed(2));
    } else {
      setVolumeDiscount(0);
    }

    setPlanCost(standardPrice.toFixed(2));
    setAmountDue(due);
    setIsPriceLoading(false);
  };

  const showVolumeDiscountSummary = () => {
    let lineIndex = 0;
    if (zoneInfo.stripeInfo.subscriptionId === undefined || zoneInfo.plan === 'Free') {
      lineIndex = 0;
    } else if (
      zoneInfo.stripeInfo.subscriptionId !== undefined &&
      zoneInfo.stripeInfo.interval === currentPlan.interval
    ) {
      lineIndex = 2;
    } else if (
      zoneInfo.stripeInfo.subscriptionId !== undefined &&
      zoneInfo.stripeInfo.interval !== currentPlan.interval
    ) {
      lineIndex = 1;
    }

    return (
      <div
        style={{
          marginLeft: '50px',
          marginRight: '40px',
          justifyContent: 'space-between',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #FED95A',
        }}
      >
        <div style={{ width: '125px', margin: '10px', fontWeight: 'bold' }}>
          Volume discount summary
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '10px',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {userLicenses} Users @ ${currentPlan.unitPrice}/user/{currentPlan.interval}
            </div>
            <div>${parseFloat(planCost).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: '#AE007B',
              marginBottom: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>Volume discount for {userLicenses} users</div>
            <div>${parseFloat(volumeDiscount).toLocaleString('en',{ minimumFractionDigits: 2 })}</div> 
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ marginRight: '10px' }}>{lineItems[lineIndex].description}</div>
            <div>${parseFloat(lineItems[lineIndex].amount).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
          </div>
        </div>
      </div>
    );
  };

  const showLineItems = () =>
    lineItems
      .filter((itm, index) => index < 2)
      .map((el, idx) => (
        <div
          key={idx}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: el.backgroundColor !== undefined ? el.backgroundColor : 'white',
            border: el.type === 'plan' ? '1px solid #FFBE24' : null,
            padding: el.backgroundColor !== undefined ? '5px' : '0px',
            borderRadius: '10px',
            justifyContent: 'space-between',
            marginTop: '10px',
            marginLeft: el.type === 'plan' ? '40px' : '50px',
            marginRight: el.type === 'plan' ? '40px' : '50px',
            color: el.color,
          }}
        >
          <div
            style={{
              fontWeight: el.type === 'plan' ? 'bold' : null,
              paddingLeft: el.type === 'plan' ? '5px' : '0px',
            }}
          >
            {el.description}
          </div>
          <div style={{ paddingRight: el.type === 'plan' ? '5px' : '0px' }}>${parseFloat(el.amount).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
        </div>
      ));

  return (
    <Form>
      <div
        style={{
          marginTop: '20px',
          marginLeft: '20px',
          width: '700px',
          height: 'calc(100vh - 200px)',
          overflowX: 'hidden',
          // scrollbarWidth:'none',
          overflowY:'auto',
          border: '1px solid #FFBE24',
          borderRadius: '8px',
        }}
      >
        <div
          style={{ fontSize: '20px', marginTop: '10px', marginLeft: '10px',marginRight:'20px', marginBottom: '10px' }}
        >
          You have selected the GraceBlocks{' '}
          <span style={{ color: '#AE007B', fontWeight: 'bold' }}>{plan}</span> plan for the{' '}
          <span style={{ color: '#AE007B', fontWeight: 'bold' }}>{zoneInfo.name}</span> Zone.
        </div>

        {currentPlan !== '' ? (
          <>
            {plan !== 'Free' ? (
              <>
                <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
                  This Zone currently has {zoneInfo.userCount} active users. Indicate the number of
                  licenses you would like to include in your purchase. Volume discounts apply for
                  any number over 9 users.
                </div>
                <div
                  style={{
                    display: 'flex',
                    border: '1px solid #FFBE24',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                    marginBottom: '10px',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    marginLeft: '50px',
                    marginRight: '40px',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        // border: '1px solid #FFBE24',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        // backgroundColor: '#F1EFEF',
                        width: '280px',
                        height: '50px',
                      }}
                    >
                      <Radio
                        style={{
                          fontSize: '20px',
                          marginLeft: '10px',
                          paddingTop: '10px',
                          fontWeight: 'bold',
                        }}
                        label="Pay annually (save 20%)"
                        name="radioGroup1"
                        value="annual"
                        checked={paymentInterval === 'annual'}
                        onChange={(e, data) => handleChange(data.value)}
                      />
                      {/* </Form.Field> */}
                      <div style={{ marginLeft: '48px' }}>
                        List Price{' $'}
                        {`${
                          plans.filter((itm) => itm.name === plan && itm.interval === 'annual')[0]
                            .unitPrice
                        }/User`}
                      </div>
                    </div>
                    <div style={{ width: '20px' }}></div>
                    <div
                      style={{
                        // border: '1px solid #FFBE24',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        // backgroundColor: '#F1EFEF',
                        width: '280px',
                        height: '50px',
                      }}
                    >
                      <Radio
                        style={{
                          fontSize: '20px',
                          marginLeft: '10px',
                          paddingTop: '10px',
                          fontWeight: 'bold',
                        }}
                        label="Pay monthly"
                        name="radioGroup1"
                        value="monthly"
                        checked={paymentInterval === 'monthly'}
                        onChange={(e, data) => handleChange(data.value)}
                      />
                      <div style={{ marginLeft: '48px' }}>
                        List Price{' $'}
                        {`${
                          plans.filter((itm) => itm.name === plan && itm.interval === 'monthly')[0]
                            .unitPrice
                        }/User`}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginRight: '20px',
                      marginBottom: '10px',
                      alignItems: 'center',
                      justifyContent: zoneInfo.stripeInfo.subscriptionId !== undefined ? 'center' : 'space-between',
                      width: '500px',
                    }}
                  >
                    <div className="row">
                      <div style={{ marginRight: '10px' }}># of user licenses:</div>
                      <Input
                        type="number"
                        value={userLicenses}
                        onChange={(e, data) => handleLicenseChange(data.value)}
                        style={{ borderColor: 'black', borderRadius: '10px', width: '75px' }}
                      />
                    </div>
                    {zoneInfo.stripeInfo.subscriptionId === undefined ? (
                      <div className="row" style={{ marginRight: '5px' }}>
                        <div style={{ marginRight: '10px' }}>Promo code:</div>
                        <Input
                          value={promoCode}
                          onChange={(e, data) => setPromoCode(data.value)}
                          style={{ borderColor: 'black', borderRadius: '10px', width: '125px' }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <GBButton Action={getUpcomingInvoice} text="Calculate" color={isChanged ? "#AE007B" : "#AE007B80"} borderRadius="10px" />
                  </div>
                </div>
              </>
            ) : zoneInfo.plan !=='Free' ? <>
            <div style={{ marginLeft: '10px' }}>
              You are currently using the {existingPlan.name} Plan with a{' '}
              {existingPlan.interval} subscription for {userLicenses} users with {usedDays} days
              used in this month's subscription. The outstanding balance paid will be applied as
              a credit for future use on this Zone.
            </div>
          </> : <div style={{marginLeft:'20px'}}>
             You are currently using the Free plan. No changes requried.
          </div>}

            {isPriceLoading ? <Spinner color={color} /> : null}

            {!isPriceLoading && volumeDiscount > 0 ? showVolumeDiscountSummary() : null}
            {!isPriceLoading ? (
              <>
              {plan !=='Free' ? (<>
                <div
                  style={{
                    marginLeft: '50px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    fontSize: '30px',
                    fontWeight: 'bold',
                  }}
                >
                  ${parseFloat(amountDue).toLocaleString('en',{ minimumFractionDigits: 2 })} due {moment().format('MMM DD, YYYY')}
                </div>
                <div
                  style={{
                    marginLeft: '50px',
                    borderBottom: '1px solid #757575',
                    marginRight: '50px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>Description</div>
                  <div>Amount</div>
                </div>
                  </>): null}
                {planDates !== '' ? (
                  <div style={{ marginLeft: '50px', marginTop: '10px', fontWeight: 'bold' }}>
                    Time period covered: {planDates}
                  </div>
                ) : null}
              </>
            ) : null}
            {!isPriceLoading && lineItems.length > 0 ? (
              <div>
                {showLineItems()}
                <div style={{ marginLeft: '50px', marginRight: '50px' }}>
                  <hr style={{ border: '1px solid black' }} />
                </div>
                <div
                  style={{
                    marginLeft: '50px',
                    marginRight: '50px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  <div>
                    {newCredits < 0 ? (
                      <div style={{ color: '#930878', width: '200px', marginTop: '30px' }}>
                        Credit notice: ${newCredits * -1} remains and will be applied as available
                        balance on future invoices.{' '}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ width: '160px', textAlign:'right',marginRight:'20px' }}>Subtotal</div>
                      <div>${parseFloat(subtotal).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ width: '160px', textAlign:'right',marginRight:'20px' }}>Total</div>
                      <div>${parseFloat(subtotal).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
                    </div>
                    {credits < 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          color: '#930878',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <div style={{ width: '160px', textAlign:'right',marginRight:'20px' }}>Applied balance</div>
                        <div>${parseFloat(credits).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
                      </div>
                    ) : null}
                    {promotionDiscount !== 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          color: '#930878',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <div style={{ width: '160px', textAlign:'right',marginRight:'20px'}}>Promo code discount</div>
                        <div>-${promotionDiscount.toFixed(2)}</div>
                      </div>
                    ) : null}

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ width: '160px', textAlign:'right',marginRight:'20px' }}>Amount due</div>
                      <div>${parseFloat(amountDue).toLocaleString('en',{ minimumFractionDigits: 2 })}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          {!(zoneInfo.plan==='Free' && currentPlan.name==='Free') ? 
            <div style={{ marginLeft: '30px', marginTop: '20px' }}>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  cancelPayment={cancelPayment}
                  completePayment={completePayment}
                  zoneInfo={zoneInfo}
                  currentPlan={currentPlan}
                  amount={userLicenses}
                  actualCost={actualCost}
                  nextBillingDate={nextBillingDate}
                  lineItems={lineItems}
                  promoCodeID={promoCodeID} 
                  color={color}
                />
              </Elements>
            </div>
            : null}

          </>
        ) : null}
      </div>
    </Form>
  );
};

export default PlanSelection;
