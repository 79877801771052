/* eslint-disable  */
/* eslint-disable no-param-reassign */
import PNL from 'google-libphonenumber';

export const mergeSort = (arr, compare) => {
  const { length } = arr;
  const middle = Math.floor(length / 2);

  // define default comparison function if none is defined
  if (!compare) {
    // eslint-disable-next-line func-names
    compare = function(left, right) {
      if (left < right) {
        return -1;
      }
      if (left === right) {
        return 0;
      }
      return 1;
    };
  }

  if (length < 2) {
    return arr;
  }

  // eslint-disable-next-line no-shadow
  function merge(left, right, compare) {
    const result = [];

    while (left.length > 0 || right.length > 0) {
      if (left.length > 0 && right.length > 0) {
        if (compare(left[0], right[0]) <= 0) {
          result.push(left[0]);
          left = left.slice(1);
        } else {
          result.push(right[0]);
          right = right.slice(1);
        }
      } else if (left.length > 0) {
        result.push(left[0]);
        left = left.slice(1);
      } else if (right.length > 0) {
        result.push(right[0]);
        right = right.slice(1);
      }
    }
    return result;
  }

  const arrLeft = arr.slice(0, middle);
  const arrRigth = arr.slice(middle, length);
  return merge(mergeSort(arrLeft, compare), mergeSort(arrRigth, compare), compare);
};

export const getRegions = ({ onlyCountryCodes, favoriteCountryCodes }) => {
  const phoneUtil = PNL.PhoneNumberUtil.getInstance();
  let filteredCodes = phoneUtil.getSupportedRegions().filter(function(region) {
    return onlyCountryCodes && onlyCountryCodes.length
      ? onlyCountryCodes.indexOf(region) !== -1
      : true;
  });
  if (favoriteCountryCodes && favoriteCountryCodes.length) {
    filteredCodes = mergeSort(filteredCodes, function(a, b) {
      const aIndex = favoriteCountryCodes.indexOf(a);
      const bIndex = favoriteCountryCodes.indexOf(b);
      if (aIndex !== -1 && bIndex !== -1) {
        if (aIndex > bIndex) {
          return 1;
        }
        return -1;
      }
      if (aIndex !== -1) {
        return -1;
      }
      if (bIndex !== -1) {
        return 1;
      }
      return 0;
    });
  }
  return filteredCodes.map(function(region) {
    const iso2 = phoneUtil.getCountryCodeForRegion(region);
    const phoneExample = phoneUtil.getExampleNumber(region);
    const internationalExample = phoneUtil.format(
      phoneExample,
      PNL.PhoneNumberFormat.INTERNATIONAL,
    );
    const localNumberExample = internationalExample
      .replace(`+${iso2}`, '')
      .replace(/^\s+|\s+$/g, '');
    return {
      id: region + iso2,
      region,
      iso2,
      internationalExample,
      localNumberExample,
      numberMask: localNumberExample.replace(/\d/g, '9'),
    };
  });
};
