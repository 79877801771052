import React from 'react';
import PropTypes from 'prop-types';
import { FormCard } from '../../../components/Form';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner/Spinner';
import { ArrowLeftIcon } from '../../../components/Icon';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';
import PhoneValidatorSendForm from './PhoneValidatorSendForm';
import PhoneValidatorAcceptForm from './PhoneValidatorAcceptForm';
import PhoneValidatorSuccess from './PhoneValidatorSuccess';

const PhoneValidator = ({
  onValidate,
  onUpdate,
  onRedirect,
  onCancel,
  onBack,
  verificationId,
  loading,
  success,
  initialValues,
  independentStep,
}) => {
  return (
    <>
      {!independentStep && (
        <Button variant="clear" className="back-button" onClick={onBack}>
          <ArrowLeftIcon />
        </Button>
      )}
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div style={{marginBottom:'20px'}}>
      <img src={LogoIcon} alt="Logo" />
      </div>
      <FormCard title="MOBILE PHONE VALIDATION" style={{ minHeight: '580px' }}>
        {!success && !verificationId && (
          <PhoneValidatorSendForm onSubmit={onValidate} initialValues={initialValues} />
        )}
        {!success && !!verificationId && (
          <PhoneValidatorAcceptForm onSubmit={onUpdate} onCancel={onCancel} />
        )}
        {success && <PhoneValidatorSuccess />}
      </FormCard>
      {success && (
        <div className="text-center" style={{marginTop:'20px'}}>
          <Button long onClick={onRedirect}>
            Continue
          </Button>
        </div>
      )}
      {loading && <Spinner isLoading isDone={false} color="#AE007B" />}
    </>
  );
};

PhoneValidator.defaultProps = {
  onValidate: () => {},
  onUpdate: () => {},
  onRedirect: () => {},
  onCancel: () => {},
  onBack: () => {},

  loading: false,
  success: false,
  independentStep: false,
  verificationId: undefined,
  initialValues: {},
};

PhoneValidator.propTypes = {
  onValidate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRedirect: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,

  loading: PropTypes.bool,
  success: PropTypes.bool,
  independentStep: PropTypes.bool,
  verificationId: PropTypes.string,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default PhoneValidator;
