/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiDragVertical, mdiCheck,mdiClose,mdiInformationOutline,mdiAccountOutline } from '@mdi/js';
import { Checkbox,Popup,Input } from 'semantic-ui-react';
import TextareaAutosize from 'react-autosize-textarea';

import onClickOutside from 'react-onclickoutside';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';
import moment from 'moment';
import numbro from 'numbro';
import Modal from 'react-modal';

import DisplayMultiple from '../../containers/Block/components/Renderers/DisplayMultiple';
import DataList from '../..//containers/Block/components/DataList/DataList';
import UserRender from '../../containers/Block/components/Renderers/UserRender';
import DateTimeRenderer from '../../containers/Block/components/Renderers/DateTimeRenderer';
import DisplayLookupValues from '../../containers/Block/components/Renderers/DisplayLookupValues';
import GBDropdownComponent from '../GBDropdown/GBDropDownasComponent';
import SelectedUserItem from '../UserDropdown/SelectedUserItem';
import UserDropdown from '../UserDropdown/UserDropdown';
import GBPhone from '../GBPhone/GBPhone';
import {getTableAPI} from '../../api/tables';



class InputControl extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  state = {
    edit: false,
    valid: true,
    startDate: this.props.value !== undefined && this.props.value !==null ? moment(this.props.value).toDate() : null,
    tableData: null, //thse 3 fields are just used for single relational to pass to Datalist.
    filters: [],
    showDatalist: false, //10-5-22 adding to support using datalist while staying in edit mode
    updatedValue:null //10-5-22 adding to support showing updated value while still in edit mode.
  };


  handleChange = (e) => {
    const { valid,isChecked } = this.state;
      this.props.updateValue(e.target.id, e.target.value);
  };

  handleTextArea =(id,value) =>{
    this.props.updateValue(id,value);
  }

  handleCheckBox =(id,value) => {
    const {isChecked} = this.state;
    this.setState({isChecked: !isChecked})
    this.props.updateValue(id,value.toString())
  }

  handlePhoneChange = (id, value) => {
    this.props.updateValue(id, value);
  };

  handleOptionChange = async (newvalues) => {
    const { colInfo, updateValue } = this.props;
    await updateValue(colInfo.data, newvalues);
  };

  handleCollaboratorChange = async(newvalues)=>{
    const { colInfo, updateValue } = this.props;   
    await updateValue(colInfo.data, newvalues);
  }

  handleDateChange = async (newdate) => {
    const { colInfo, updateValue } = this.props;
    this.setState({ startDate: newdate });
    await updateValue(colInfo.data, newdate.toISOString());
  };


  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.submitChanges();
      this.setState({ edit: false });
    }
  };

  handleDataListClose =() =>{
    this.setState({ edit: false,showDatalist:false });
  }

  toggleEditMode = async () =>{
    const {edit,showDatalist} = this.state;
    const {colInfo} = this.props;
   
    if(colInfo.uitype===1) {
     await this.handelGetRelationalTable();
    }
    this.setState({edit: !edit,showDatalist: !showDatalist})
  }

  handelGetRelationalTable = async () =>{

    const { colInfo,rowId,tableid,value} = this.props;
    const {relValue} = this.state;
    const tableData = await getTableAPI(colInfo.source.lookuptableid);

    let filters = [
      {
        field: 'id',
        header: 'id',
        type: 'relation', 
        displayfield: 'id',
        sourcefield: colInfo.data,
        selectmode: "single",
        renderlookup: colInfo.source.lookup,
        operator: value===null || value.length===0 ? 'not' : 'and',
        value: rowId,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${colInfo.source.returnfield}_join`,
        originalTableId: tableid,
      },
    ];

    this.setState({tableData:tableData,filters})
  }

  AddItem = async (id, field) =>{
    const { colInfo, updateValue } = this.props;
    const newValue=[{id:id,data:field,type:2}]
    await updateValue(colInfo.data, newValue,colInfo.uitype);
    // await this.ApplyChanges()
    this.setState({ edit: false,showDatalist:false,updatedValue:newValue });
  }

  RemoveItem = async (id,mode,field) => {
    const { colInfo, updateValue,value,rowId,tableid } = this.props;
    const newValue=[{id:id,data:field,type:-1}]

    await updateValue(colInfo.data, newValue,colInfo.uitype);

    let filters = [
      {
        field: 'id',
        header: 'id',
        type: 'relation', 
        displayfield: 'id',
        sourcefield: colInfo.data,
        selectmode: "single",
        renderlookup: colInfo.source.lookup,
        operator: 'not',
        value: rowId,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${colInfo.source.returnfield}_join`,
        originalTableId: tableid,
      },
    ];

    //10-5-22, when they remove a single select item and click done, I need a way to have the detail page
    //show no value, before the changes are saved. it needs to be different than null, because there is logic
    //that is based upon the user udpating value and using that value. Here we set it to -1, so the render
    //will cisplay "Choose one".

    this.setState({filters,updatedValue:-1})
    // this.props.submitChanges();
  }

  ApplyChanges = async () =>{
    
    // this.props.submitChanges();
    this.setState({ edit: false,showDatalist:false });
  }

  handleClickOutside = (evt) => {
    // console.log('clicked outside');
    const { colInfo, value } = this.props;
    let isValid = true;

    if (this.state.edit && colInfo.uitype!==1) {
      if (colInfo.uitype === 10 || colInfo.uitype === 11) {
        const EmailCheck = RegExp(colInfo.validator, 'i');
        isValid = EmailCheck.test(this.input.current.value);
        this.setState({ valid: isValid });
      } else if (colInfo.uitype===23 && (colInfo.minValue !==undefined || colInfo.maxValue !==undefined)) {
        let minValid=true;
        let maxValid=true;
        if(colInfo.minValue !==undefined) {
         minValid = this.input.current.value >= parseFloat(colInfo.minValue)
        }
        if(colInfo.maxValue !==undefined) {
          maxValid = this.input.current.value <= parseFloat(colInfo.maxValue)
        }
        //  console.log(isValid)

         isValid = minValid && maxValid;
         this.setState({ valid: isValid });
      } 
      if (isValid || this.input.current.value === '') {
        this.props.submitChanges();
        this.setState({ edit: false });
      } 
    }
  };

  Gettype = (uitype) => {
    switch (uitype) {
      case 2:
        return 'text';
        break;
      case 23:
        return 'number';
        break;
      case 10:
        return 'text';
        break;
      case 11:
        return 'url';
        break;
      default:
        break;
    }
  };

  formatNumber = (number, numericFormat) => {
    numbro.setLanguage(numericFormat.culture);
    return number ? numbro(number).format(numericFormat.pattern) : null;
  };

  createRelationaltags = (value,colInfo) => {
    const {loadRelatedRecord,blockid,canEdit} = this.props

    if (value !== null && value.length>0) {
     
      return (
        value.length>0 && 
        value.map((el) =>
          el.data ? (<>
            <div
              key={el.id !==null ? el.id : el.optionid }
              css={{
                display: 'inline-flex',
                width: 'max-content',
                alignItems: 'flex-start',
                margin: '3px',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderRadius: '7px',
                backgroundColor: `${this.props.color}B3`,
                color: 'white',
                '&:hover' : {
                  backgroundColor:this.props.color,
                  transition: 'all .3s ease',
                }
              }}
            >
              <div onClick={()=>loadRelatedRecord(el.id !==undefined ? el.id : el.optionid,colInfo.source.lookuptableid,blockid,'add')}>{el.data} </div>
              <div onClick={canEdit ? this.toggleEditMode : null} css={{marginLeft:'10px', svg: {color:'white'}, '&:hover' : {
                svg: {
                  backgroundColor:'white',
                  borderRadius:'100%',
                  color: this.props.color,
                  transition: 'all .3s ease'
                }
              }}}>
                <Icon path={mdiClose} size="15px"  />
              </div>
            </div>
          </>) : null,
        )
      );
    }
    return null;
  };

  DisplayInputControl = () => {
    const { colInfo, value, color, updateValue, tableid,blockid,rowId,role,canEdit} = this.props;
    const { valid,isChecked,tableData,filters,showDatalist,updatedValue } = this.state;

    // console.log(value);
    if (
      colInfo.uitype === 2 ||
      colInfo.uitype === 23 ||
      colInfo.uitype === 10 ||
      colInfo.uitype === 11
    ) {
      return (
        <input
          ref={this.input}
          style={{
            border: valid ? '1px solid #757575' : '1px solid red',
            borderRadius:'7px',
            height:'25px',
            minWidth: '100%',
            outline: 'none',
          }}
          onKeyPress={this.handleKeyPress}
          id={colInfo.data}
          type={this.Gettype(colInfo.uitype)}
          // autoFocus
          defaultValue={value}
          onChange={this.handleChange}
        />
      );
    }
    if (colInfo.uitype === 3) {
      return (
        <TextareaAutosize  
        cols={100}
        rows={5}
        defaultValue={value}
        id={colInfo.data}
        onChange={(e) => this.handleTextArea(colInfo.data,e.target.value)} 
        />
      );
    }
    if (colInfo.uitype === 5) {
      return (
       <Checkbox onChange={(e,data)=>this.handleCheckBox(colInfo.data,data.checked)} />
      );
    }
    if (colInfo.uitype === 9) {
      return <GBPhone id={colInfo.data} value={value} handleChange={this.handlePhoneChange} />;
    }
    if (colInfo.uitype === 6 || colInfo.uitype === 7 || colInfo.uitype === 4) {
      return (
        <GBDropdownComponent
          id={colInfo.data}
          field={colInfo.data}
          tableid={tableid}
          enableColors={colInfo.enableColors}
          gridExpandState={false}
          source={colInfo.source}
          color={color}
          width="275"
          optionValues={value}
          uitype={colInfo.uitype}
          lookup={colInfo.lookup}
          updateValue={this.handleOptionChange}
          callingComponent="Details"
         
        />

      );
    }
    if(colInfo.uitype===8) {
      return <UserDropdown
      uitype={8}
      blockid={blockid}
      updateFilter={this.handleCollaboratorChange}
      selectedUsers={value}
      color={color}
      lookup={colInfo.lookup}
      width={275}
      type={"filter"} 
    />
    }
    if (colInfo.uitype === 22) {
      const s1 = moment(value).toDate();
      return (
        <DatePicker
        inline
        selected={this.state.startDate}
        onChange={(date) => this.handleDateChange(date)}
        timeInputLabel="Time:"
        scrollableYearDropdown
        minDate={moment().subtract(80, 'years')._d}
        dropdownMode="select"
        showYearDropdown
        showTimeInput={colInfo.includeTime}
        />
      );
    }
    if (colInfo.uitype === 1 && tableData !==null && showDatalist)  {
  
      return (
        <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={true}
        // onAfterOpen={this.afterOpenModal}
        // onRequestClose={this.closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            // position: 'fixed',
            width: '800px',
            // height: this.state.modalHeight,
            height: 'calc(100vh -100px)',
            // maxHeight:'500px',
            top: 10,
            left: window.innerWidth/4,
            // right: '140px',
            // bottom: '140px',
            border: `.3px solid ${color}`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 5010,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
            // boxShadow: '0px 3px 6px #707070',
          },
        }}
        contentLabel="Example Modal"
      >
        <div>
        <div
          style={{
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
            backgroundColor: color,
          }}
        >
          <Icon path={mdiAccountOutline} size="20px" color="white" />
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            Relate new record(s) to {tableData.name}: {tableData.recordname}
          </div>
        </div>
      
        <DataList
          displayMode={value===null ? 'addition' : 'review'}
          tableid={colInfo.source.lookuptableid}
          sourcetableid={tableid}
          renderfield={colInfo.source.lookupfield}
          nestedFields={colInfo.source.nestedFields}
          blockid={colInfo.source.blockid}
          field={colInfo.data}
          filters={filters}
          tableinfo={tableData.tableinfo}
          color={color}
          AddItem={this.AddItem}
          RemoveItem={this.RemoveItem}
          Apply={this.ApplyChanges}
          role={role}
          Close={this.handleDataListClose}
          selectMode={colInfo.lookup}
          callingComponent='grid'
          height='500px'
          canEdit={canEdit}
          relationalSecurity={colInfo.useSecurity} 
                />
        </div>
        </Modal>
      );
    }
    if(colInfo.uitype===1 && !showDatalist && (value !==null || updatedValue !==null) && updatedValue!==-1) {
      console.log(updatedValue)
      return this.createRelationaltags(updatedValue !==null ? updatedValue : value,colInfo);
    } else if(colInfo.uitype===1 && !showDatalist &&  (value ===null || updatedValue===null || updatedValue===-1)) {
      console.log(updatedValue)
      return <div onClick={canEdit ? this.toggleEditMode : null}>Choose one</div>
    }
  };

  DisplayValue = () => {
    const { colInfo, value,blockid } = this.props;
   
    if (colInfo.uitype === 2 || colInfo.uitype === 9 || colInfo.uitype === 17) return value;

    if (colInfo.uitype === 10) {
      return (
        <a href={'mailto:' + value} target="_newtab">
          {value}
        </a>
      );
    }
    if (colInfo.uitype === 5) {
      if (value === 'true') {
        return <div style={{color:'green', fontSize:'14px'}}>✔</div>;
      }else {
        return ''
      }
    }

    if (colInfo.uitype === 11) {
      if (value !== null) {
        const url = value.toString().startsWith('http') ? value : `http://${value}`;
        return (
          <a href={url} target="_newtab">
            {value}
          </a>
        );
      }
    }
    if (colInfo.uitype === 23 ) return this.formatNumber(value, colInfo.numericFormat);

    if (colInfo.uitype === 3) {
      if (value !== null && value !==undefined) {
        return value.split('\n').map((item, index) => (
          <span  key={index}>
            {item}
            <br />
          </span>
        ));
      }
    }

    if (colInfo.uitype === 24 || colInfo.uitype === 25) {
      return (
        <UserRender Action={null} uitype={colInfo.uitype} field={colInfo.data} value={value} />
      );
    } 
    if (([19,20,22].includes(colInfo.uitype))) {
      if(value !==null){
      return (
        <Moment utc={colInfo.UTC} format={colInfo.dateFormat}>
          {value}
        </Moment>
      );
      } else {
        return (<div></div>)
      }
    }
    if (colInfo.uitype === 18 && colInfo.renderandedit === 'LookupValues') {
      return (
        <DisplayLookupValues
          value={value}
          isEditor={false}
          isRenderer={true}
          uitype={colInfo.source.lookupuitype}
          lookuptable={`${colInfo.data}_lookup`}
          numericFormat={colInfo.numericFormat}
          dateFormat={colInfo.dateFormat}
          UTC={colInfo.UTC}
          lookupfield={colInfo.source.lookupfield}
          finallookupfield={colInfo.source.finallookupfield}
          specialfunction={colInfo.specialfunction}
        />
      );
    }
    if (colInfo.uitype === 6 || colInfo.uitype===7) {
      return <DisplayMultiple value={value} enableColors={colInfo.enableColors} uitype={colInfo.uitype} field={colInfo.displayfield} />;
    }
    if(colInfo.uitype===8 || (colInfo.source && colInfo.source.lookupuitype===8)){
      return value !==null && value.map(usr=>(
        <SelectedUserItem item={usr} backgroundColor={null} color={usr.attributes.text} Action={null} />
      ))
    }
    if (colInfo.uitype === 1 && colInfo.lookup === 'single') {
      return this.createRelationaltags(value,colInfo);
    } 

    return ''
  };

  render() {
    const {
      value,
      colInfo,
      updateValue,
      role,
      submitChanges,
      alignControl = false, // 9-16-2020  - AlignControl =true is used from userProfile page and other plaecs that need to display simple fields without the formatting. Trying to strip out formatting so appears like normal field
      fontWeight='bold',
      canEdit,
    } = this.props;
    const { edit } = this.state;
    return (
      <div
        css={{
          // border: '1px solid blue',
          display: 'flex',
          color: '#312602',
          flexDirection: 'row',
          alignItems:colInfo.uitype === 3 || colInfo.uitype===8 || (colInfo.source && colInfo.source.lookupuitype===8) ? 'flex-start' : 'center',
          justifyContent:'center',
          // alignItems: 'flex-start',
          marginBottom: '5px',
          marginLeft: !alignControl ? '20px' : '0px',
          marginRight: !alignControl ? '35px' : '0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            // alignItems: colInfo.uitype === 3 ? 'flex-start' : 'center',
            justifyContent: !alignControl ? 'flex-end' : null,
            // minWidth: !alignControl ? '200px' : null,
            width: !alignControl ? '200px' : null, // 5-24-2022 changed to width, instead of minWidth to ensure formatting stays consistence.
            whiteSpace: "nowrap",
            fontWeight:fontWeight,
            textAlign: 'right',
            marginLeft: !alignControl ? '10px' : '0px',
            // border: '1px solid transparent',
            marginRight: '0px',
            // backgroundColor:'#aaa',
          }}
        >
          {colInfo.callout !==undefined && colInfo.callout !=='' ? (<>
            <Popup content={colInfo.callout} trigger={
            <Icon  path={mdiInformationOutline} size="20px" />
            } />
            <span style={{width:'3px'}}></span>
          </>): null}
          
          {colInfo.header}
        </div>
        <div
          onClick={
            !edit && canEdit && colInfo.readOnly === undefined && colInfo.uitype !== 18  && (colInfo.uitype !== 1 || colInfo.uitype===1 && (value===null || value===undefined || value[0]===undefined || value[0].id===null) )
              ? this.toggleEditMode
              : null
          }
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            // backgroundColor:'#eee',
            alignItems: 'center',
            overflow: 'hidden',
            minWidth: !alignControl ? null : '200px',
            justifyContent: !edit ? 'space-between' : 'flex-start',
            border: '1px solid transparent',
            backgroundColor: colInfo.uitype===18 ? '#F1EFEF' : null,
            // border:'1px solid yellow',
            minHeight:'20px',
            paddingLeft: '5px',
            svg: {
              opacity: 0,
              transition: 'all .3s ease',
            },
            '&:hover': edit ? {
              border: !edit ? '1px solid #D5D1D1' : null,
              borderRadius: '5px',
              svg: {
                opacity: 1,
                transition: 'all .3s ease',
              },
            } : null,
          }}
        >
         {!edit ? (
            <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              {this.DisplayValue()}
            </div>
          ) : (
            <>{this.DisplayInputControl()}</>
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(InputControl);
