/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { mdiClose, mdiBell, mdiArrowLeft } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import Notifications from './notfications';
import NotificationDetails from './notificationDetails';
import Settings from './settings';

const ShowNotifications = ({ color, blockid, toggleNotifications }) => {

  const [screen, setScreen] = useState('notifications');
  const [details,setDetails]=useState('');

  const showNotificationDetails=(details)=>{
    setDetails(details)
    setScreen('details');
  }

  return (
    <div
      style={{
        zIndex: 10,
        position: 'absolute',
        border: `.3px solid ${color}`,
        borderRadius: '10px',
        // overflow: 'hidden',
        marginBottom: '30px',
        // boxShadow: '0px 1px 2px #757575',
        backgroundColor: 'white',
        height: 'calc(100vh - 150px)',
        width: '425px',
        right: '175px',
        top: '60px',
      }}
    >
      <div
        style={{
          display: 'flex',
          color: 'white',
          // border: `10px solid ${color}`,
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
          height: '40px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          outline: 'none',
          backgroundColor: color,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingLeft: '10px',
          paddingRight: '13px',
        }}
      >
        <TextButton
          text={screen==='notifications' ? "Notifications" : screen==='details' ? 'Notification details' : "Notification settings" } 
          iconPosition="left"
          fontSize="14px"
          icon={screen==='notifications' ? mdiBell : mdiArrowLeft}
          hoverColor="#FFFFFF80"
          Action={screen==='notifications' ? toggleNotifications : setScreen}
          ActionValue={screen==='settings' || screen==='details' ? 'notifications' : null}
          iconSize="25px"
        />
        <TextButton
          text="Hide panel when done"
          fontSize="14px"
          Action={toggleNotifications}
          textColor="white"
          icon={mdiClose}
          hoverColor="#FFFFFF80"
        />
      </div>
     
      {screen==='notifications' ? <Notifications color={color} blockid={blockid} setScreen={setScreen} showNotificationDetails={showNotificationDetails} /> : null}
      {screen==='settings' ? <Settings color={color} setScreen={setScreen} blockid={blockid}  /> : null}
      {screen==='details' ? <NotificationDetails color={color} details={details} setScreen={setScreen} blockid={blockid}  /> : null}
      </div>
  
  );
};

export default ShowNotifications;
