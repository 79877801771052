/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useContext, useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core'
import { AccountContext } from '../../providers/AccountProvider';
import { mdiPlus, mdiPencil, mdiSend,mdiEmail } from '@mdi/js';
import GBActions from '../../components/GBActions/GBActions';
import ChangeEmail from './ChangeEmail';
import SendEmail from './sendEmail';
import AddMobileNumber from './AddMobileNumber';
import { checkEmailVerified } from '../../api/auth';


const ContactInformation = ({color}) => {
  const { userTokenResult, userInfo } = useContext(AccountContext);
  const [regions, setRegions] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(userInfo.phoneNumber==='000-000-0000' ? <span>Choose <b>Action</b>: <b>Change</b> & enter your number.</span> : userInfo.phoneNumber);
  const [email, setEmail] = useState(userTokenResult.claims.email);
  const [mask, setMask] = useState('+1 999-999-9999');
  const [showEmailUpdate, setShowEmailUpdate] = useState(false);
  const [emailActionOpen,setEmailActionOpen] = useState(false);
  const [mobileActionOpen,setMobileActionOpen] = useState(false);
  const [selectedRow,setSelectedRow] = useState(0);
  const [selectedAction,setSelectedAction] = useState('');

  let emailStatus = checkEmailVerified() ? 'Verified' : 'Not verified';
  const mobileStatus = userInfo.mobileverifydate !== null ? 'Verified' : 'Not verified';

  const showAction = (value) => {
      setSelectedRow(value)
      setSelectedAction(value)
  }

  const hideActions = () =>{
    setSelectedAction('')
  }

  const hideEmailActions = () => {
    setEmailActionOpen(false)
    
  }

  const hideMobileActions = () => {
    setMobileActionOpen(false)
  }

  // This is called from ChangeEmail component to refresh email on contact information page.
  const updateEmailParent =(email) =>{
    userTokenResult.claims.email =email
    setEmail(email)
  }

  const updateMobile = (mobileNumber) =>{
    const d = new Date();
    userInfo.mobileverifydate = d.toISOString();
    userInfo.phoneNumber = mobileNumber;
  }

  const selectRow = (type) => {
     
      if(type==='email'){
          setEmailActionOpen(!emailActionOpen)
      } else if(type==='mobile') {
         setMobileActionOpen(true)
      }
      setSelectedRow(type)
  }

  const EmailActions = [
      {name:'Change', icon: mdiPencil, Action:showAction,ActionValue:'ChangeEmail'},
      {name:'Send verification message', icon: mdiPencil, Action:showAction,ActionValue:'VerifyEmail'},
  ]

  const getMobileOptions = () => {
      if(mobileNumber===null){
          return ([{name:'Add', icon: mdiPlus, Action:showAction,ActionValue:"AddMobile"}])
      } else {
          return (
              [
                {name:'Change', icon: mdiPencil, Action:showAction,ActionValue:"ChangeMobile"},
                {name:'Send verification message', icon: mdiSend, Action:showAction,ActionValue:'VerifyMobile'}
              ]
              )
      }
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginLeft: '10px',
          height: '40px',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#F1EFEF',
          // border: '1px solid #757575',
          borderRadius: '40px',
        }}
      >
        <div style={{ fontWeight: 'bold', marginLeft: '20px' }}>Account contact information</div>
        {/* <div style={{marginRight:'10px'}}><TextButton hoverColor="#75757580" icon={mdiPlus} text='Add' iconSize="15px" iconPosition="left" /></div> */}
      </div>
        {/* <button onClick={()=>UnlinkMobileNumber()}>Unlink</button> */}
      <div>
        <div
          style={{
            marginLeft: '20px',
            marginTop: '10px',
            marginRight: '10px',
            // marginBottom: '20px',
            display: 'flex',
            flexDirection: 'row',
            padding:'5px',
          
            // justifyContent: 'space-between',
          }}
        >
          <div style={{ minWidth:'140px',display:'flex',justifyContent:'flex-end',marginRight:'10px' }}>Type</div>
          <div style={{ minWidth:'300px',marginLeft:'10px' }}>Value</div>
          <div style={{ minWidth:'150px' }}>Status</div>
          <div style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>Action</div>
        </div>

        <div
          onClick={() =>selectRow('mobile')}
          css={{
            marginLeft: '20px',
            marginRight: '10px',
            // marginBottom: '20px',
            border: selectedRow==='mobile' ? '1px solid  #FFBE24' : `1px solid transparent`,
            display: 'flex',
            flexDirection: 'row',
            minWidth:'200px',
            padding:'5px',
            '&:hover': {
                transition: 'all .3s ease',
                border: `1px solid #FFBE24`,
                borderRadius:'10px',
              },
          }}
        >
          <div style={{fontWeight: 'bold',  minWidth:'140px',display:'flex',justifyContent:'flex-end',marginRight:'10px',whiteSpace:'nowrap' }}>Account mobile phone</div>
          <div style={{display:'flex',alignItems:'flex-start',  minWidth:'300px',marginLeft:'10px'}}>{mobileNumber}</div>
          <div style={{  minWidth:'150px', color: mobileStatus==='Verified' ? 'green'  :'red'}}>{mobileStatus}</div>
          <div style={{ position:'relative',fontWeight: 'bold', whiteSpace: 'nowrap', minWidth: '100px' }}>
            {mobileStatus==='Verified' ? '' : (
                <GBActions Actions={getMobileOptions()} borderColor="#FFBE24" chevronColor={color} iconColor="#312602" isExpanded={mobileActionOpen} close={hideMobileActions} />
            )}
          </div>
        </div>
        <div
           onClick={() =>selectRow('email')}
          css={{
            zIndex:'0',
            marginLeft: '20px',
            marginRight: '10px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'row',
            minWidth:'200px',
            border: selectedRow==='email' ? '1px solid  #FFBE24' : `1px solid transparent`,
            padding:'5px',
            borderRadius:'10px',
            '&:hover': {
                transition: 'all .3s ease',
                border: `1px solid #FFBE24`,
              },
          }}
        >
          <div style={{ fontWeight: 'bold',  minWidth:'140px',display:'flex',justifyContent:'flex-end',marginRight:'10px' }}>Account email</div>
          <div style={{textAlign:'left',  minWidth:'300px',marginLeft:'10px'}}>{email.includes('invalidemail.com') ? 'Enter valid email' : email}</div>
          <div style={{  minWidth:'150px', color: emailStatus==='Verified' ? 'green'  :'red'}}>{emailStatus}</div>
          <div style={{ position:'relative',fontWeight: 'bold', whiteSpace: 'nowrap', minWidth: '100px' }}>
          
          {/* 12-6-2021 We decided to alawyas allow user to edit their email address as we've run into the issue with
          almost any new user. Once they change it, they will need to reverify (upon changing it, the systems sends them a verification email)  */}
          {/* 12-7-22 We only show this option if they sign in using password provider */}
          {userTokenResult.signInProvider==='password' ? (
            <GBActions Actions={EmailActions} borderColor="#FFBE24" chevronColor={color} iconColor="#312602" isExpanded={emailActionOpen} close={hideEmailActions} />
          ): null}
          </div>
          
          
        </div>

      </div>
      {selectedAction !=='' ? (
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              border: `.1px solid ${color}`,
              borderRadius:'20px',
              top: '100px',
              left: '150px',
              width: '400px',
              height: '450px',
            }}
          >
            {selectedAction==='ChangeEmail' ? (
              <ChangeEmail color={color} close={hideActions} userid={userInfo.id} updateEmailParent={updateEmailParent} />
            ): null}
            {selectedAction==='VerifyEmail' ? (
              <SendEmail color={color} close={hideActions} />
            ): null}
             {selectedAction==='AddMobile' || selectedAction==='ChangeMobile' || selectedAction==='VerifyMobile' ? (
              <AddMobileNumber color={color} close={hideActions} userid={userInfo.id} updateMobile={updateMobile} />
            ): null}
          </div>
      
      ) : null}
    </div>
  );
};

export default ContactInformation;
