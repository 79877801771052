/* eslint-disable */
import React, { useCallback,useEffect,useContext,useState, } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import classNames from 'classnames';
import { AccountContext } from '../../../providers/AccountProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { mdiDeleteForever,mdiAlert } from '@mdi/js';
import AddBlock from './AddBlock';
import DuplicateBlock from './DuplicateBlock';
import Global from '../../../clients/global'

import Icon, {
  SettingsIcon,
  ArrowLeftBoldIcon,
  ArrowRightBoldIcon,
  CopyIcon
} from '../../../components/Icon';
import Button from '../../../components/Button';
import { DASHBOARD } from '../../../constants/routes';

const DeleteIcon = (props) => <Icon {...props} path={mdiDeleteForever} />;

const BlockItem = ({ item, onDelete, onChange, onCopy, onRightMove, onLeftMove, firstItem, lastItem,count }) => {
  const {userInfo } = useContext(AccountContext);

  const currentZone = userInfo.zones.find((z) => z.id === parseInt(Global.zoneid));
  const [modalIsOpen,setModalIsOpen]= useState(false);
  const [isLeftAligned,setIsLeftAligned]=useState(true);
  const [isTopAligned, setIsTopAligned] =useState(true);
  const { REACT_APP_PUBLIC_ZONE_ID } = process.env;

  // useEffect(()=>{
  //   console.log(currentZone)
  // },[])

  const handleDelete = useCallback(() => {
    if(!item.issystemblock) {
      onDelete(item);
    } else {
        toast.info(`This Block cannot be deleted. It is required to support user management for this Zone.`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
    }
  }, [onDelete, item]);

  const handleCancel = useCallback(() => {
    onChange({ ...item, isNew: false })
  }, [item, onChange]);

  const showCopySettings=(e)=>{
    if(!item.isMaxRowsExceeded){
    const LeftAligned = e.pageX < (window.innerWidth/2 + 100)
    const TopAligned = e.pageY < window.innerHeight /2
    setIsLeftAligned(LeftAligned);
    setIsTopAligned(TopAligned);
    setModalIsOpen(true);
    } else {
      toast.info(<div>The copy Block function is disabled because this Zone is over the plan limits. <a style={{color:'white',textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/graceblocks-plans" target="_newWindow">Learn more</a>.
      </div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
    
  }

  const handleRightMove = useCallback(() => {
    onRightMove(item);
  }, [item, onRightMove]);

  const handleEdit = useCallback(() => {
    onChange({ ...item, isNew: true });
  }, [item, onChange]);

  const handleCopy = async (dupParams) => {
    // dupParams.item=item; // make a copy of block
    await onCopy(dupParams)
  }

  const handleLeftMove = useCallback(() => {
    onLeftMove(item);
  }, [item, onLeftMove]);

  const fakeBlock = !item.id;
  const showModal = item.isNew;
  const className = classNames('zone-block-item', fakeBlock && 'no-interactive');
  const classNameActions = classNames(
    'actions',
    firstItem && 'only-right',
    lastItem && 'only-left',
  );

  return (
      <div style={{position:'relative'}} className={className}>
          {item.isMaxRowsExceeded ? (
          <div style={{position:'absolute', top:'-10px', left:'70px'}}>
            <Icon path={mdiAlert} color="#E06055" />
          </div>
          ): null}
       
        <div className="zone-block-item-placeholder" style={{ background: item.color }}>
        {/* <div style={{display:'flex',flexDirection:'row', justifyContent:'end', alignItems:'center',color:'white'}}>{count}</div> */}
          <div className="icon">
            <svg viewBox="0 0 24 24">
              <path d={item.icon} fill="#fff" />
            </svg>
          </div>
          <div className="title">{item.name}</div>
        </div>
        <div className="zone-block-item-content">
          <Link to={`${DASHBOARD.BLOCK_PREFIX}${item.id}/${Global.zoneid}`}>
            <div className="background" style={{ background: item.color }} />
            <div className="icon">
              <svg viewBox="0 0 24 24">
                <path d={item.icon} fill="#fff" />
              </svg>
            </div>
          </Link>
          <div className="title">{item.name}</div>
          <div className={classNameActions}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip className="tooltip-block-action">Move Right</Tooltip>}
            >
              <Button variant="clear arrow-right" onClick={handleRightMove}>
                <ArrowRightBoldIcon size={0.9} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip className="tooltip-block-action">Move Left</Tooltip>}
            >
              <Button variant="clear arrow-left" onClick={handleLeftMove}>
                <ArrowLeftBoldIcon size={0.9} />
              </Button>
            </OverlayTrigger>
 
            {currentZone.role ==='Zone owner' || currentZone.role==='Zone builder' ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip className="tooltip-block-action">Settings</Tooltip>}
            >
              <Button variant="clear settings" onClick={handleEdit}>
                <SettingsIcon size={0.7} />
              </Button>
            </OverlayTrigger>
            ): null}

            {!item.issystemblock && (currentZone.role ==='Zone owner' || currentZone.role==='Zone builder') || currentZone.id===parseInt(REACT_APP_PUBLIC_ZONE_ID) ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip className="tooltip-block-action">Duplicate</Tooltip>}
            >
              <Button variant="clear settings" onClick={showCopySettings}>
                <CopyIcon size={0.7} />
              </Button>
            </OverlayTrigger>
            ): null}
          
          {!item.issystemblock && (currentZone.role ==='Zone owner' || currentZone.role==='Zone builder') ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip className="tooltip-block-action">Delete</Tooltip>}
            >
              <Button variant="clear delete" onClick={handleDelete}>
                <DeleteIcon size={0.8} />
              </Button>
            </OverlayTrigger>
          ) : null}
          </div>
        </div>
    
      <AddBlock show={showModal} block={item} onChange={onChange} onCancel={handleCancel} />
      {modalIsOpen ? (
        <DuplicateBlock onCopy={handleCopy} isTopAligned={isTopAligned} isLeftAligned={isLeftAligned} field='field_1' item={item} close={()=>setModalIsOpen(false)} />
      ):null}
      </div>

    
  );
};

BlockItem.defaultProps = {
  onDelete: () => {},
  onChange: () => {},
  onRightMove: () => {},
  onLeftMove: () => {},

  firstItem: false,
  lastItem: false,
};

BlockItem.propTypes = {
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onRightMove: PropTypes.func,
  onLeftMove: PropTypes.func,

  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  firstItem: PropTypes.bool,
  lastItem: PropTypes.bool,
};

export default BlockItem;
