/* eslint-disable */
import React, {useState,useEffect,useContext} from 'react';
import Icon from '@mdi/react';
import { Buffer } from 'buffer';
import PulseLoader from "react-spinners/PulseLoader";
import { mdiChevronDown, mdiPlus,mdiClose} from '@mdi/js'
import FormView from './FormView';
import { AccountContext } from '../../../../providers/AccountProvider';
import TextButton from '../../../../components/TextButton/TextButton';
import { getFormByToken } from '../../../../api/forms';
import Global from '../../../../clients/global'


const FormWrapper = ({token,close,executeAndClose,color,addedby,role,hiddenFields,callingComponent,searchParams}) => {

    const [formInfo,setFormInfo] = useState('');
    const [form,setForm]=useState(null);
    const [isLoading,setIsLoading] =useState(false);
    const {userInfo} = useContext(AccountContext);

    useEffect(() =>{
        // console.log(searchParams)
        loadformData();
    },[])

    const loadformData = async () =>{
        setIsLoading(true);

        const buff = Buffer.from(token, 'base64');
        const fInfo = buff.toString('ascii');
        let id=null;
        if(searchParams !==undefined && searchParams !==null) {
            id = searchParams['id'];
        }

        const formInstance = await getFormByToken(fInfo,userInfo.id,parseInt(Global.zoneid),id);

        setForm(formInstance.form);
        setFormInfo(fInfo);
        setIsLoading(false);
    }

    return (

        <div style={{display:'flex',flexDirection:'column',width:'100%',overflow:'hidden',height:'100%'}}>
           {/* 8-31-23 added hiddenfields for use of adding relational new records useing webforms. */}
           {hiddenFields===undefined ? (
            <div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between',backgroundColor:color,}}>
                <div style={{display:'flex',paddingLeft:'10px',flexDirection:'row',height:'40px',alignItems:'center',width:'100%',color:'white'}}>
                <Icon path={mdiPlus} size="20px"/>
                <div style={{width:'10px'}}></div>
                 {form?.name}
                </div>
                <div style={{ marginRight: '10px',color:'white' }}>
                    <TextButton hoverColor="#FFFFFF80" Action={close} text="Close" icon={mdiClose} iconPosition="right" />
               </div>
            </div>
            ): null}
            
            {!isLoading && form !==null ? (
            <FormView currentForm={form} fInfo={formInfo}  executeAndClose={executeAndClose} addedby={addedby} role={role} hiddenFields={hiddenFields} callingComponent={callingComponent} searchParams={searchParams ?? undefined} />
            ): (
                <center>
                    <PulseLoader />
                </center>
            )}
        </div>
    )
}

export default FormWrapper