/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import { GetRelationalIdentifier } from '../../../../api/GetData';
import Messages from './Messages';
import Global from '../../../../clients/global'

const MessagesWindow = ({ color, close, tableid, dataID, tableinfo,display,number,role }) => {
  const [RID, setRID] = useState('');

  useEffect(() => {
    if (tableid !== undefined) {
      const schema = `schema_${Global.zoneid}`;
      const fetchData = async () => {
        const RI = await GetRelationalIdentifier(schema, tableid, dataID);
        setRID(RI);
      };
      fetchData();
    }
  },[dataID]);

  // useEffect(()=>{
  //   console.log(new Date().toLocaleString())
  // })

  // const closeWindow =() => {
  //   setRID('')
  //   close()
  // }

  return (
    <div
    tabIndex={0}
      style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%', 
          outline:'none',
          // border:'1px solid red'
        }}
    >
      <div
        style={{
          display: 'flex',
          color: 'white',
          alignItems: 'center',
        //   height:'100%',
          paddingTop:'10px',
          paddingBottom:'10px',
          minHeight: '42px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: color,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingLeft: '10px',         
        }}
      >
        <div>Message stream: {RID} </div>
        <div style={{ marginRight: '10px' }}>
          <TextButton
            Action={close}
            hoverColor="#FFFFFF80"
            text="Hide panel when done"
            icon={mdiClose}
          />
        </div>
      </div>
      <Messages color={color} display={display} role={role} tableid={tableid} dataID={dataID} RID={RID} tableinfo={tableinfo} number={number} callingComponent="grid" />
    </div>
  );
};

export default MessagesWindow;
