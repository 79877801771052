/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, {useState, useEffect,useRef } from 'react';
import { Input } from 'semantic-ui-react';

import { webImageSearch } from '../../../../api/images';

const WebSearch = ({ addUrl, category }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [images, setImages] = useState([]);
  const [offset, setOffSet] =useState(0);
  const listInnerRef = useRef();

  const handleClick = (url,name) => {
    addUrl(url, category,name);
  };

  const fetchImages = async (newoffset) => {
    const result = await webImageSearch(searchTerm,newoffset);
    const tempimages = [...images];
    tempimages.push.apply(tempimages, result);
    // console.log(tempimages)
    setImages(tempimages);
  };

  // const newSearch = async () =>{
  //   const result = await webImageSearch(searchTerm,0);
  //   setImages(result);
  // }

  const startSearch = async () => {
    // setImages([])
    // await fetchImages(0)
    const result = await webImageSearch(searchTerm,0);
    setImages(result);
  }

  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
       const newoffset = offset+50
        setOffSet(newoffset)
       await fetchImages(newoffset)
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '300px',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <div className="row" style={{ marginLeft: '20px', marginTop: '20px' }}>
        <div style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '5px' }}>
          Search the web for images:
        </div>
        <div style={{ width: '400px' }}>
          <Input
            style={{ width: '100%' }}
            action={{
              content: 'Search',
              onClick: () => startSearch(),
            }}
            onChange={(e, data) => setSearchTerm(data.value)}
            placeholder="enter terms to search"
          />
        </div>
      </div>
      <div
        onScroll={() => onScroll()}
        ref={listInnerRef}
        style={{
          display: 'inline-flex',
          marginTop: '20px',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          overflow: 'auto',
          height: '350px',
        }}
      >
        {images.length > 0 &&
          images.map((img,index) => (
            <img
              onClick={() => handleClick(img.contentUrl,img.name)}
              key={index}
              src={img.thumbnailUrl}
              css={{ maxHeight: '75px', margin: '10px', cursor:'pointer', '&:hover': {
                  opacity:.5
              } }}
            />
          ))}
      </div>
    </div>
  );
};

export default WebSearch;
