/* eslint-disable */
import React from 'react';
import {Input} from 'semantic-ui-react';
import GBButton from '../../../../../components/GBButton/GBButton';

const Thankyou = ({fieldData,color,updateFieldLabel,isEditMode,textColor,btnTextColor}) =>{

    return (
        <div style={{width:'100%'}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {isEditMode ? (
              <Input value={fieldData.button} onChange={(e,data)=>updateFieldLabel(fieldData.id,data.value)} />
          ) :  <GBButton color={color} width="400px" height="35px"  fontSize="17px" text={fieldData.button} Action={null} textColor={btnTextColor}  textHoverColor={btnTextColor==='white' ? '#FFFFFF80' : '#00000080'} />}
        
        </div>
        <div
          style={{
            display: 'flex',
            // backgroundColor: '#eee',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <div style={{ width: '45%' }}>
            <hr style={{ border: '1px dashed #757575', width: '100%' }} />
          </div>
          <div
            style={{
              width: '250px',
              height:'40px',
              border: '1px solid #757575',
              borderRadius:'5px',
              display: 'flex',
              margin: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              color:textColor
             
            }}
          >
            <div>THANK YOU</div>
          </div>
          <div style={{ width: '45%' }}>
            <hr style={{ border: '1px dashed #757575', width: '100%' }} />
          </div>
        </div>
      </div>
    )
}

export default Thankyou;