import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import MicrosoftIcon from './Microsoft_logo.svg';
import MicrosoftIcon from './mslogo.png';
import './styles.scss';

const MicrosoftButton = ({ children, className, ...props }) => {
  const classes = classNames('microsoft-button', className);
  return (
    <button className={classes} type="button" {...props}>
      <span className="icon">
        <img src={MicrosoftIcon} alt="Microsoft" />
      </span>
      {children}
    </button>
  );
};

MicrosoftButton.defaultProps = {
  className: null,
};

MicrosoftButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MicrosoftButton;
