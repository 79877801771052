/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ZoneSetup from './ZoneSetup';
import { FORM_ERROR } from '../../../components/Form';
import { updateZoneAPI } from '../../../api/zone';
import { updateUserAPI } from '../../../api/auth';
import { useAlert } from '../../../components/Alert';

const withAPI = () => {
  return ({ userInfo, onChange, onBack, initialValues, userInfoValues }) => {
    const [alertComponent, alert] = useAlert({
      title: 'Zone setup error',
    });

    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
      (values) => {
        setLoading(true);
        return updateZoneAPI({ ...values, token: uuidv4(), id: userInfo.zones[0].id })
          .then(() => {
            return updateUserAPI({ ...userInfo, ...userInfoValues });
          })
          .then(() => {
            onChange('zone', values);
          })
          .catch((error) => {
            setLoading(false);
            alert.show({
              message: error.message,
            });
            return { [FORM_ERROR]: error.message };
          });
      },
      [userInfo, userInfoValues, alert, onChange],
    );

    const handleBack = useCallback(() => {
      onBack('zone');
    }, [onBack]);

    return (
      <>
        {alertComponent}
        <ZoneSetup
          onSubmit={handleSubmit}
          onBack={handleBack}
          loading={loading}
          initialValues={initialValues}
        />
      </>
    );
  };
};

export default withAPI;
