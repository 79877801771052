/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import FormButton from './Buttons/FormButton';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiMonitor } from '@mdi/js';
import { Popup } from 'semantic-ui-react';

import { Input } from 'semantic-ui-react';

const FormDesigner_TopBar = ({
  color,
  close,
  save,
  addFormElement,
  formOpacity,
  formHeight,
  logoHeight,
  logoPlacement,
  updateForm,
  form,
  togglePreview,
  loadingElement,
}) => {
  const [fHeight, setFHeight] = useState(formHeight);
  const [fOpacity, setFOpacity] = useState(formOpacity);
  const [fLogoHeight, setFLogoHeight] = useState(logoHeight);
  const [fLogoPlacement, setFLogoPlacement] = useState(logoPlacement);

  const logoOptions = [
    { key: 1, value: 1, text: 'Absolute position' },
    { key: 2, value: 2, text: 'Lower left' },
    { key: 3, value: 3, text: 'Lower middle' },
    { key: 4, value: 4, text: 'Lower right' },
    { key: 5, value: 5, text: 'Middle left' },
    { key: 6, value: 6, text: 'Middle middle' },
    { key: 7, value: 7, text: 'Middle right' },
    { key: 8, value: 8, text: 'Top left' },
    { key: 9, value: 9, text: 'Top middle' },
    { key: 10, value: 10, text: 'Top right' },
  ];

  const addTextoptions = [
    { key: '1', text: 'Heading 1', value: 'h1' },
    { key: '2', text: 'Heading 2', value: 'h2' },
    { key: '3', text: 'Heading 3', value: 'h3' },
    { key: '4', text: 'RTF(Rich text paragraph)', value: 'rtf' },
    { key: '5', text: 'Page break', value: 'pb' },
  ];


  const updateFormLocal = () => {
    updateForm(fHeight, fOpacity, fLogoHeight, fLogoPlacement);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        // overflow: 'hidden',
        justifyContent: 'space-between',
        height: '130px',
        borderBottom: `1px solid ${color}`,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          alignItems: 'flex-start',
        }}
      >
        <FormButton
          text="Add form element"
          color={color}
          loadingElement={loadingElement}
          Action={addFormElement}
          options={addTextoptions}
        />

        <div
          style={{
            display: 'flex',
            marginLeft: '10px',
            borderRadius: '10px',
            border: '1px solid #757575',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '5px',
              paddingLeft: '5px',
              flexDirection: 'column',
              marginLeft: '1px',
              borderRight: '1px solid #757575',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Popup
                content={
                  <div>
                    This option only applies if an image has been uploaded. Set this to 100% to show
                    the image in full color. By default, this is set to 70% to align the image with
                    the form accent color. Setting to 0% will cause the image to disappear. Instead
                    the form accent color will shine through as the background. In other words,
                    setting to 0 has the same result as uploading no image at all.{' '}
                  </div>
                }
                trigger={
                  <div
                    style={{
                      width: '270px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '11px',
                    }}
                  >
                    BACKGROUND IMAGE OPACITY %
                    <Icon
                      style={{ marginLeft: '5px' }}
                      path={mdiInformationOutline}
                      size="15px"
                      color="black"
                    />
                  </div>
                }
              />

              <div style={{ width: '10px' }} />
              <Input
                value={fOpacity}
                onChange={(e, data) => setFOpacity(data.value)}
                style={{ width: '50px', height: '20px', borderRadius: '10px', marginRight: '5px' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Popup
                content={
                  <div>
                    This option impacts how far down the initial page load the background area will
                    appear. The rest of the layout surrounding the form will be white. The higher
                    this number, the more of the page will be covered by the color/image specified.
                    The best way to see this in action is to change the number and click apply to
                    see how it impacts the page design.{' '}
                  </div>
                }
                trigger={
                  <div
                    style={{
                      width: '270px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '11px',
                    }}
                  >
                    BACKGROUND PAGE CONSUMPTION %
                    <Icon
                      style={{ marginLeft: '5px' }}
                      path={mdiInformationOutline}
                      size="15px"
                      color="black"
                    />
                  </div>
                }
              />
              <div style={{ width: '10px' }} />
              <Input
                value={fHeight}
                onChange={(e, data) => setFHeight(data.value)}
                style={{
                  width: '50px',
                  height: '20px',
                  borderRadius: '5px',
                  outline: 'none',
                  marginRight: '5px',
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Popup
                content={
                  <div>
                    This option impacts how many pixels tall your logo will be. This setting will
                    apply the same consistent size on all devices where the logo is rendered. This
                    option is only needed if the logo is larger than ideal for the page. This is an
                    optional setting. Do not use this setting to increase the size of the logo
                    provided. Doing this will degrade the quality of design.
                  </div>
                }
                trigger={
                  <div
                    style={{
                      width: '270px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '11px',
                    }}
                  >
                    LOGO HEIGHT CONSTRANT PX
                    <Icon
                      style={{ marginLeft: '5px' }}
                      path={mdiInformationOutline}
                      size="15px"
                      color="black"
                    />
                  </div>
                }
              />

              <div style={{ width: '10px' }} />
              <Input
                value={fLogoHeight}
                onChange={(e, data) => setFLogoHeight(data.value)}
                style={{
                  width: '50px',
                  height: '20px',
                  borderRadius: '5px',
                  outline: 'none',
                  marginRight: '5px',
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Popup
                content={
                  <div>
                    This option impacts the location of your logo when rendered on a screen over
                    1000 pixels. The default option, absolute position, lets you drag the image to a
                    location on the page; however, this location will appear slightly differently
                    from device to device. All other options will pick a consistent location for the
                    image when rendered on a screen over 1000 pixels. For smaller screens this
                    setting does not apply. Instead the logo will always be in the center/center
                    position.{' '}
                  </div>
                }
                trigger={
                  <div
                    style={{
                      width: '270px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: '11px',
                    }}
                  >
                    LOGO PLACEMENT
                    <Icon
                      style={{ marginLeft: '5px' }}
                      path={mdiInformationOutline}
                      size="15px"
                      color="black"
                    />
                  </div>
                }
              />
              <div style={{ width: '10px' }} />
              <select
                onChange={(e) => setFLogoPlacement(e.target.value)}
                style={{ border: '1px solid #DEDEDF' }}
              >
                {logoOptions.map((itm) => (
                  <option key={itm.value} selected={itm.value === logoPlacement} value={itm.value}>
                    {itm.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <GBButton text="Apply" color={color} width="50px" Action={updateFormLocal} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          // height: '100%',
          justifyContent: 'flex-start',
          marginRight: '30px',
          alignItems: 'flex-end',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '10px',
            marginTop:'25px'
          }}
        >
          <TextButton text="Cancel" hoverColor="#75757580" Action={close} textColor="black" />

          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <button
              css={{
                width: '50px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize:'13px',
                fontWeight:'bold',
                border:'0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline:'0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus' : {
                  border:'0px',
                  outline:'0px'
                }
              }}
              onClick={() => save({ isClosed: false, control: 'manager' })}
            >
              Save
            </button>
          </div>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
          <button
              css={{
                width: '150px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize:'13px',
                fontWeight:'bold',
                border:'0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline:'0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus' : {
                  border:'0px',
                  outline:'0px'
                }
              }}
              onClick={() => save({ isClosed: true, control: 'settings' })}
            >
              Save & go to settings
            </button>
          </div>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
          <button
              css={{
                width: '100px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize:'13px',
                fontWeight:'bold',
                border:'0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline:'0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus' : {
                  border:'0px',
                  outline:'0px'
                }
              }}
              onClick={() => save({ isClosed: true, control: 'manager' })}
            >
              Save & close
            </button>
          </div>
        </div>
        <div style={{ margin: '10px' }}>
          <GBButton
            text="Preview form"
            Action={togglePreview}
            ActionValue={form}
            iconPosition="left"
            textColor={color}
            iconSize="20px"
            textHoverColor={`${color}80`}
            hoverBorder={`1px solid ${color}`}
            iconHoverColor={`${color}80`}
            icon={mdiMonitor}
            iconColor={color}
          />
        </div>
      </div>
    </div>
  );
};

export default FormDesigner_TopBar;
