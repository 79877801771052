/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import NativeListener from 'react-native-listener';
import { mdiArrowExpandAll, mdiPlus, mdiAccountOutline, mdiClose } from '@mdi/js';
import { BaseEditorComponent } from '@handsontable/react';
import { AccountContext } from '../../../../providers/AccountProvider';
import Icon from '@mdi/react';
import { getUserTable } from '../../../../api/tables';
import DataList from '../DataList/DataList';
import TextButton from 'src/components/TextButton/TextButton';


class TableLink extends BaseEditorComponent {
  static contextType = AccountContext;
  state = {
    renderResult: null,
    value: null,
    results: [],
    fetchedData: null,
    selectedItems: [],
    workingItems: [],
    renderfield: '',
    jointable: '',
    returnfield: '',
    rendertable: '',
    rendertableid: '',
    rendertableinfo: '',
    selectmode: '',
    header: '',
    allfields: [],
    width: this.props.cellWidth,
    height: this.props.cellHeight,
    isExpanded: false,
    display: 'none',
    left: 20,
    top: 30,
    dataID: 0,
    tableid: 0,
    isChanged: false,
    displayMode: 'review',
    hasLookups: false,
    renderlookup: '',
    field: '',
    relationalSecurity: false,
    nestedFields:null,
    useBookmark:false,
    bookmarkid:0
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();

    //2-5-24 added this logic so we can use the semantic dropdowns from datalist newrecord. This prevents
    //default when selecting an item from the dropdown.
    if(e.target.classList.toString().includes('item') || e.target.classList.toString().includes('text')) {
      e.preventDefault();
    }
  }

  stopKeydownPropagation(e) {
    e.stopImmediatePropagation();
    // e.preventDefault();
  }

  onKeyDown=(e)=>{
    if(this.props.isEditor){
      if(e.code==='Enter') {
        if(this.state.selectedItems.length === 0)
        {
          this.ShowAddItems('addition')
        } else {
          this.ShowAddItems('review')
        }
      } else if(e.code==='Escape') {
        this.close()
      }
    }
  }


  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  fetchTableData = async () => {
    const { userInfo } = this.context;
    if (this.state.rendertableid !== '') {
      // const { tableinfo } = await getTableAPI(this.state.rendertableid);
      //9-13-23 added false as updateUserSetting so that this does not reset a users personal table settings if a bookmark is specirfied
      const { tableinfo } = await getUserTable(this.state.rendertableid, this.state.bookmarkid, 0, userInfo.blocks,false); // 1-11-2021 : Changed to getusertable so we get either user defined column order OR a defualt, if one has been defined.

      // // We get the tableinfo for the relation field and store in state here so we don't keep hitting db to look it up.
      // // it's passed to the DataList component in order to render the render table fields.
      const rendertableInfo = this.state.rendertable;
      const uniqueSource = this.state.rendertable + this.state.renderfield;
      // this.setState({ [uniqueSource]: result.tabledata, [rendertableInfo]: tableinfo });
      this.setState({ [uniqueSource]: 'data', [rendertableInfo]: tableinfo });
    }
  };

  fetchSelectedItemRelatedInfo = () => {
    let filters = null;

    filters = [
      {
        field: 'id',
        header: 'id',
        type: 'relation', // indicates if id is relational lookup filter or "table" to search by id on table.
        displayfield: 'id',
        sourcefield: this.state.field,
        selectmode: this.state.selectmode,
        renderlookup: this.state.renderlookup,
        operator: this.state.displayMode === 'addition' ? 'not' : 'and',
        value: this.state.dataID !== null ? this.state.dataID : 0,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${this.state.returnfield}_join`,
        originalTableId: this.state.tableid,
      },
    ];
    // console.log(filters)

    return (
      <DataList
        displayMode={this.state.displayMode}
        tableid={this.state.rendertableid}
        sourcetableid={this.state.tableid}
        field={this.state.field}
        renderfield={this.state.renderfield}
        filters={filters}
        tableinfo={this.state[this.state.rendertable]}
        color={this.props.color}
        AddItem={this.saveSingleItem}
        RemoveItem={this.removeItem}
        Apply={this.updateGrid}
        id={this.state.dataID}
        Close={this.close}
        selectMode={this.state.selectmode}
        callingComponent="Grid"
        height="430px"
        blockid={this.props.blockid}
        role={this.props.role}
        relationalSecurity={this.state.relationalSecurity}
        nestedFields={this.state.nestedFields}
        newRecordOnly={this.props.isSubTable}
        // newRecordOnly={false}
      />
    );
  };

  getValue() {
    return this.state.value !=='' ? this.state.value : null;
  }

  // eslint-disable-next-line class-methods-use-this
  open() {
    document.addEventListener('keydown', this.onKeyDown);
    this.setState({ display: 'block' });
  }

  close =() => {
    document.removeEventListener('keydown', this.onKeyDown);

    this.setState({
      dataID: null,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: [],
      workingItems: [],
      value: null,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
    });
   
  };

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    if (row !== -1) {
      const item = cellProperties.source.lookuptable + cellProperties.source.lookupfield;

      const tdPosition = td.getBoundingClientRect();
      const datarow = this.props.GetRowData(row);


      let dataID = null;
      let tablename = '';
      let recordname = '';
      let hasLookups = false;

      if (datarow !== null) {
        dataID = datarow.dataID;
        tablename = datarow.tablename;
        recordname = datarow.recordname;
        hasLookups = datarow.hasLookups;
      }
      // console.log(cellProperties.source)

      // 3/26/20 comment
      this.setState({
        dataID,
        tablename,
        recordname,
        hasLookups,
        tableid: this.props.tableid,
        field: cellProperties.data,
        relationalSecurity:
          cellProperties.useSecurity !== undefined ? cellProperties.useSecurity : true,
        renderfield: cellProperties.source.lookupfield,
        returnfield: cellProperties.source.returnfield,
        rendertable: cellProperties.source.lookuptable,
        nestedFields: cellProperties.source.nestedFields,
        header: cellProperties.header,
        jointable: cellProperties.source.jointable,
        rendertableid: cellProperties.source.lookuptableid,
        renderlookup: cellProperties.source.lookup, // added 7-3-2020. We need to know both lookup of source/relational table so that query for single/multi select can be enforced.
        selectmode: cellProperties.selectmode,
        left: `${tdPosition.left + 1}px`,
        top: `${tdPosition.top + 2}px`,
        width: cellProperties.width - 4,
        height: this.props.cellHeight - 5,
        workingItems: [],
        selectedItems:[],
        useBookmark:this.props.useBookmark,
        bookmarkid: this.props.bookmarkid
      });

      // 3-26-2020
      if (this.state[item] == null) {
        this.fetchTableData(cellProperties.source);
      }
      // --3-26-2020

      //  12/23
      //  5-3-2020 For the empty row at bottom of grid, has null array with id null.
      // we clean out that value so it displays properly.
      if (originalValue !== undefined && originalValue !== '' && originalValue !== null) {
        const cleanItems = originalValue.filter((itm) => itm.id !== null);
        this.setState({ selectedItems: cleanItems });
      }
    }
  }

  propExists = (obj, path) => {
    // eslint-disable-next-line no-shadow
    return !!path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : undefined;
    }, obj);
  };

  ShowAddItems = (displayMode) => {
    const { top, left } = this.state;
    this.setState({
      width: '820px',
      height: '650px',
      left: window.innerWidth * 0.3,
      top: window.innerHeight * 0.1,
      isExpanded: true,
      displayMode,
    });
  };

  createtags = () => {
    if (this.props.value != null) {
      return (
        this.props.value.map &&
        this.props.value.map((el) =>
          el.data ? (
            <div
              style={{
                display: 'inline-flex',
                margin: '3px',
                paddingLeft: '5px',
                paddingRight: '5px',
                alignSelf: 'center',
                borderRadius: '7px',
                backgroundColor: `${this.props.color}0D`,  //backgroundColor: `${this.props.color}B3`,
                // color: 'white',
              }}
              key={`${el.data}-${el.id}`}
            >
              {el.data}
            </div>
          ) : null,
        )
      );
    }

    return null;
  };

  updateGrid = () => {
    const { selectedItems, workingItems } = this.state;
 
    this.setState(
      () => {
        // return { value: selectedItems, isChanged: true };
        return { value: workingItems, isChanged: true };
      },
      () => {
        this.finishEditing();
      },
    );
  };

  saveSingleItem = (id, field) => {
    let { selectedItems, workingItems,selectmode } = this.state;

    // first check to see if Item is already in list, if it is, just return
 

    if (this.state.selectedItems && this.state.selectedItems.length > 0) {
      const itemalreadyexists = this.state.selectedItems.findIndex((ele) => ele.id === id);
      if (itemalreadyexists !== -1) {
        return;
      }
    }

    const item = { id, data: `${field}`,type:0 };

    const items = selectedItems.concat(item);
    workingItems.push(item);

    // 5-4-2020 isChanged indicates the user changed the value. This is used
    // in logic to force a refesh of the row in the grid, as we want to pull
    // any additional lookup fields, etc.
    this.setState({ selectedItems: items, isChanged: true });

    if (this.state.selectmode === 'single') {
  
      this.setState(
        () => {
          // return { value: items, workingItems };
          return {value: workingItems}
        },
        () => {
          this.finishEditing();
          this.close();
        },
      );
    }
  };

  showSelectedItems = () => {
    if (this.state.selectedItems && this.props.isEditor) {
      return this.state.selectedItems.map((el) => (
        <div
          css={{
            display: 'inline-flex',
            width: 'max-content',
            flexDirection: 'row',
            // margin: '3px',
            // marginTop:'5px',
            heigt: '20px',
            alignSelf: 'center',
            marginLeft: '3px',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '3px',
            marginBottom: '3px',
            paddingTop: '1px',
            paddingBottom: '1px',
            borderRadius: '7px',
            backgroundColor: `${this.props.color}0D`,  //`${this.props.color}B3`,
            // color: 'white',
          }}
          key={el.id}
        >
          {el.data} <div style={{ width: '3px' }}></div>
          <div
            css={{
              // color: 'white',
              '&:hover': {
                svg: {
                  borderRadius: '100%',
                  backgroundColor: 'white',
                  color: this.props.color,
                },
              },
            }}
          >
            <Icon onClick={() => this.removeItem(el.id, el.data)} path={mdiClose} size="15px" />
          </div>
        </div>
      ));
    }
  };

  removeItem = (id, field) => {
    const { selectedItems, workingItems, isExpanded, selectmode } = this.state;
    const tmpselectedItem = [...selectedItems].filter((el) => el.id !== id);
    const idx = [...selectedItems].findIndex((el) => el.id === id);

    if(idx !==-1) {
        const item = [...selectedItems][idx];
        item.type=-1;
        if(selectmode==='single'){
          item.lookup=selectmode;
        }
        workingItems.push(item);

        this.setState(
          {
            selectedItems: tmpselectedItem,
            workingItems,
            // value: tmpselectedItem,
            value:workingItems,
            isChanged: true,
          },
          () => {
            if (!isExpanded && selectmode === 'single') {
              // this.finishEditing();
              // this.close();
            }
          },
        );
    }

  };

  render() {
    let renderResult = null;

    if (this.props.isEditor && this.state.display==='block') {
      renderResult = (
        <NativeListener
          onMouseDown={this.stopMousedownPropagation}
          onKeyDown={this.stopKeydownPropagation}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              // border:
              //   this.state.isExpanded
              //     ? `1px solid #707070`
              //     : '0px',
              backgroundColor: 'white',
              // border:'1px solid blue',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              left: this.state.left,
              top: this.state.top,
              width: this.state.width,
              // height: this.state.height,
              height: this.state.isExpanded ? 'calc(100vh - 125px)' : this.state.height,
              display: this.state.display,
              overflow: this.state.isExpanded ? 'hidden' : 'hidden',
              borderRadius: this.state.isExpanded ? '10px' : '0px',
              boxShadow: this.state.isExpanded ? '0px 3px 6px #707070' : null,
            }}
          >
            {this.state.isExpanded ? (
              <div
                style={{
                  height: '42px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '20px',
                  backgroundColor: this.props.color,
                  justifyContent:'space-between',
                  paddingRight:'20px',
                }}
              >
              <div>
                <TextButton iconPosition='left' iconSize='20px'  text={`Relate ${this.state[this.state.rendertable].recordname}(s) to ${this.state.tablename}: ${this.state.recordname}`} icon={mdiAccountOutline} color="white" Action={()=>{}} />
                {/* <Icon path={mdiAccountOutline} size="20px" color="white" />
                <div style={{ color: 'white', paddingLeft: '10px' }}>
                  Relate {this.state[this.state.rendertable].recordname}(s) to {this.state.tablename}: {this.state.recordname}
                </div> */}
              </div>
              <div><TextButton textColor='white' hoverColor="#FFFFFF80" text="Cancel" icon={mdiClose} Action={this.close} /></div>
              </div>
            ) : (
              <>
                {this.state.selectmode === 'multiple' ||
                (this.state.selectmode === 'single' && this.state.selectedItems.length === 0) ? (
                  // && !this.state.isChanged
                  <div
                    onClick={() => this.ShowAddItems('addition')}
                    css={{
                      float: 'left',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: 'all .3s ease',
                        fontWeight: 'bold',
                        svg: {
                          color: 'white',
                          backgroundColor: `${this.props.color}80`,
                          borderRadius: '30px',
                          transition: 'all .3s ease',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiPlus} size="16px" />
                  </div>
                ) : null}
                {this.state.selectedItems.length > 0 ? (
                  <div
                    onClick={() => this.ShowAddItems('review')}
                    css={{
                      float: 'right',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: 'all .3s ease',
                        svg: {
                          color: `${this.props.color}80`,
                          transition: 'all .3s ease',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiArrowExpandAll} size="16px" />
                  </div>
                ) : null}
              </>
            )}

            {!this.state.isExpanded ? (
              <div style={{ paddingTop: '3px', marginRight: '5px' }}>
                {this.showSelectedItems()}
              </div>
            ) : null}
            {this.state.isExpanded ? <>{this.fetchSelectedItemRelatedInfo()}</> : null}
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        width: this.props.cellWidth,
        height: this.props.cellHeight - 7,
        // display:'flex',
        // flexDirection:'row',
        // justifyContent:'center',
        float: 'left',
        zIndex: 0,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingTop:'5px',
        marginRight: '5px',
      };

      renderResult = <div style={colorboxStyle}>{this.createtags()}</div>;
    }

    return <>{renderResult}</>;
  }
}

export default TableLink;
