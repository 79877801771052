/* eslint-disable */
import React from 'react';
import { mdiChatPlusOutline,mdiClose} from '@mdi/js';
import TextButton from '../../../../../components/TextButton/TextButton';


const NewMessageTopBar = ({color,close}) => {

    return (
    <div
        style={{
          display: 'flex',
          color: 'white',
          alignItems: 'center',
          paddingTop:'10px',
          paddingBottom:'10px',
          minHeight: '42px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: color,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingLeft: '10px',
          paddingRight:'10px',
        }}
      >
        <TextButton text="Compose text" iconPosition="left" Action={close} icon={mdiChatPlusOutline} hoverColor="#FFFFFF80" iconSize='20px' />
  
        <TextButton text="Cancel" textColor='white' hoverColor="#FFFFFF80"  iconPosition="right" Action={close} icon={mdiClose} />

      </div>
    )
}

export default NewMessageTopBar