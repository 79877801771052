
/* eslint-disable */
import React, { useState,useEffect } from 'react';
import { mdiArrowLeft } from '@mdi/js';
import { toast } from 'react-toastify';
import styles from './NewTab/index.module.css';
import { Input } from 'semantic-ui-react';
import TextButton from '../../../../components/TextButton/TextButton';
import {checkTableNameUnique} from '../../../../api/tables';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import GBButton from '../../../../components/GBButton/GBButton';

const DuplicateTabSetup = ({blockid,tableid,close,color,duplicate,currentTabName}) => {

    const [includeData,setIncludeData] = useState(false);
    const [includeBookmarks, setIncludeBookmarks] = useState(false);
    const [includeForms, setIncludeForms] = useState(false);
    const [tabName, setTabName] = useState(`${currentTabName} copy`);
    
    const checkName =  async () =>{
        console.log(`${tabName}name`)
        const result = await checkTableNameUnique(blockid,tableid,tabName)
        const isValid =result.length===0;
        if(!isValid){
            toast.info('The tab name must be unique.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:7000,
              })
        } else {
            await duplicate(tableid,tabName,includeData,includeBookmarks,includeForms)
        }
    }

    return(
        <div className={styles.container}>
           <div className={styles.wrapper1} style={{ backgroundColor: color, paddingLeft: '10px' }}>
        <TextButton
          text='Duplicate tab'
          hoverColor="#FFFFFF80"
          iconSize="20px"
          icon={mdiArrowLeft}
          iconPosition="left"
          Action={close}
        />
      </div>
      <div style={{margin:'10px',marginLeft:'35px',marginRight:'35px'}}>
         <p/><p/>
        <Input fluid label="Tab name" placeholder='Enter tab name'  defaultValue={tabName} onChange={(e,data)=>setTabName(data.value.trim())} />
        <p/><p/>
        <GBSwitch color={color} text="Include data" isChecked={includeData} Action={()=>setIncludeData(!includeData)} /> 
        <div>This will copy existing data from the target tab into the newly created tab.</div>
        <p/><p/>
        <GBSwitch color={color} text="Include boomarks" isChecked={includeBookmarks} Action={()=>setIncludeBookmarks(!includeBookmarks)} /> 
        <div>This will copy existing boomarks into the duplicated tab.</div>
        <p/><p/>
        <GBSwitch color={color} text="Include web forms" isChecked={includeForms} Action={()=>setIncludeForms(!includeForms)} /> 
        <div>This will copy existing web forms into the duplicated tab.</div>

        <div  style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent: 'center',marginTop:'30px' }}>
        <TextButton textColor="black" Action={close} ActionValue={false} text="Cancel" hoverColor="#75757580" />
        <div style={{ width: '20px' }} />
        <GBButton Action={checkName} text="Duplicate tab" width="150px" color={color} />
      </div>
      </div>
  

        </div>
    )
}

export default DuplicateTabSetup