/* eslint-disable no-unused-vars */
const GBColors = [
    { color: '#FFF9C4', text: '#000000' },
    { color: '#FFF3E0', text: '#000000' },
    { color: '#FFEBEE', text: '#000000' },
    { color: '#FCE4EC', text: '#000000' },
    { color: '#E3F2FD', text: '#000000' },
    { color: '#D1ECE8', text: '#000000' },
    { color: '#F5F5F5', text: '#000000' },
    { color: '#FFEB3B', text: '#000000' },
    { color: '#FF9800', text: '#000000' },
    { color: '#FF8A80', text: '#000000' },
    { color: '#FF80AB', text: '#000000' },
    { color: '#03A9F4', text: '#000000' },
    { color: '#00BFA5', text: '#000000' },
    { color: '#9E9E9E', text: '#000000' },
    { color: '#E5AE02', text: '#FFFFFF' },
    { color: '#FFA000', text: '#FFFFFF' },
    { color: '#F44336', text: '#FFFFFF' },
    { color: '#E91E63', text: '#FFFFFF' },
    { color: '#448AFF', text: '#FFFFFF' },
    { color: '#009688', text: '#FFFFFF' },
    { color: '#757575', text: '#FFFFFF' },
    { color: '#C79702', text: '#FFFFFF' },
    { color: '#DD2C00', text: '#FFFFFF' },
    { color: '#B71C1C', text: '#FFFFFF' },
    { color: '#880E4F', text: '#FFFFFF' },
    { color: '#0D47A1', text: '#FFFFFF' },
    { color: '#004D40', text: '#FFFFFF' },
    { color: '#212121', text: '#FFFFFF' },
  ];

  export default GBColors