/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import {Dropdown} from 'semantic-ui-react'
import { css, jsx } from '@emotion/core'
import Icon from '@mdi/react';
import {mdiPlus,  } from '@mdi/js';

const FormButton = ({options,text,color,Action,ActionValue,DropDownAction,loadingElement}) => {

    return (
        <div
        css={{
          width: 'max-content',
          marginLeft: '20px',
          '&:hover': {
            '& .icon': {
              boxShadow: '0px 3px 6px #757575',
            },
            '& .plusicon': {
              transition: 'all .3s ease',
              borderRadius: '20px',
              color,
              opacity: 0.5,
              backgroundColor: 'white',
            },
          },
        }}
      >
        <Dropdown
          text={<span style={{fontSize:'13px'}}>{text}</span>}
          closeOnChange={false}
          scrolling
          disabled={loadingElement}
          simple
          button
          selectOnBlur={false}
          icon={
            <Icon
              className="plusicon"
              style={{ marginLeft: '5px' }}
              path={mdiPlus}
              size="15px"
            />
          }
          // icon="plus"
          className="icon"
          style={{
            borderRadius: '5px',
            fontSize: '12px',
            fontFamily: 'Nunito Sans',
            height: '30px',
            width: '161px',
            backgroundColor: color,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          options={options}
          value=""
          onChange={(e, data) => Action(data.value)}
          onClick={DropDownAction !==undefined ? ()=>DropDownAction() : null}
        />
        </div>
    )
}

export default FormButton