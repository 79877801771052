

/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import _, { isNumber } from 'lodash';
import 'react-base-table/styles.css';

import Icon from '@mdi/react';
import { mdiChatProcessing, mdiChatProcessingOutline, mdiCheck,mdiClose } from '@mdi/js';
import { Dropdown,Checkbox } from 'semantic-ui-react';
import { AccountContext } from '../../../../../providers/AccountProvider';
import RelationalTag from './relationalTag';
import SelectTag from './selectTag';
import SelectedUserItem from '../../../../../components/UserDropdown/SelectedUserItem';
import ImageThumbnails from '../../../../../components/ImageThumbnails/ImageThumbnails';
import DisplayLookupValues from '../../Renderers/DisplayLookupValues';
import PageControls from './pageControls';
import Moment from 'react-moment';
import moment from 'moment';
import numbro from 'numbro';
import UserTag from './UserTag';
import IconButton from '../../FilterData/IconButton';


const QuickViewVirtual = ({
  tableid,
  tableinfo,
  data,
  rowHeight,
  color,
  showDetailView,
  activeRow,
  setSortField,
  sortField,
  sortOrder,
  UpdateTableColumnWidth,
  heightOffset, // used from share screen.Allows grid to resize to meet dynamic elements.
  viewedRecords,
  showBookmarkBar,
  isExpanded,
  loadData,
  recordCount,
  groupTableHeight,
  groupTableWidth,
  mode=null, //specified from datalist on either "addition" or "review" of list
  selectedKeys, //specified from datalist on which keys have been selected
  selectMode=null, // specified from datalist to determine if multi/single selected
  RemoveItem,
  canEdit, 
  showAllColumns=true, //specific to detalist, if false, show only 3 columns
  showForm //12-1-23 added so when using formula field, we pass to <displaylookupvalues> so it uses internal formwrapper.
}) => {
  const [cols, setCols] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(activeRow);
  const [sortBy, setSortBy] = useState({
    key: sortField === 'custom.fullname' ? 'id' : sortField,
    order: sortOrder,
  });
  const { userInfo } = useContext(AccountContext);
  const [viewed, setViewed] = useState(viewedRecords !== undefined ? viewedRecords : []);
  const [page, setPage] = useState(0);
  const [selectedRows,setSelectedRows] =useState(selectedKeys);
  const resultSize=100;

  const totalPages = Math.ceil(data.length / resultSize);

  useEffect(() => {
    // console.log(tableinfo)
    // setIsLoading(true);
    // setCols([]);
    // loadColumns();
    // setIsLoading(false);
  }, []); //data, rowHeight,tableid

  const measuredRef = useCallback((node) => {
    
    if (node !== null) {
      const qv = `quick_${tableid}_view`;
      if (userInfo[qv] !== undefined && mode==null) {
        node.scrollToPosition(userInfo[qv]);
      }
    }
  }, []);

  const updatePage = (direction) => {
    let tempPage = 0;
    if (direction === 'next' && page + 10 < data.length) {
      setPage(page + resultSize);
    } else if (direction === 'prev' && page - resultSize >= 0) {
      setPage(page - resultSize);
    } else if(direction==='first') {
      setPage(0)
    } else if(direction==='last') {
      setPage(data.length-resultSize)
    } else if (Number.isInteger(direction)) {
      setPage(direction * resultSize)
    }
  };

  useEffect(() => {
    setSelectedRow(activeRow);
    // console.log(activeRow)
  }, [activeRow]);

  useEffect(() => {
    setSelectedRows(selectedKeys);
    // console.log(activeRow)
  }, [selectedKeys]);

  const getFormattedNumber = (number, el) => {
    if (el.numericFormat === undefined) {
      return number;
    } else if (el.uitype === 18) {
      return number[0].data;
    }
    numbro.setLanguage(el.numericFormat.culture);
    return numbro(number).format(el.numericFormat.pattern);
  };

  const getDuration = (value) => {
    const nowdate = new Date();
    const ms = moment().diff(moment(value[0].addedon), 'minutes');
    if (value[0].schedule !== null) {
      return 'Scheduled';
    } else if (ms === 0) {
      return 'Now';
    } else if (ms > 0 && ms < 60) {
      return `${ms}M ago`;
    } else if (ms > 60 && ms < 1440) {
      return `${moment().diff(moment(value[0].addedon), 'hours')}H ago`;
    } else if (ms > 1440) {
      return `${moment().diff(moment(value[0].addedon), 'days')}D ago`;
    }
    return ms;
  };

   //  height:'100%', wordWrap:'break-word' ,display:'flex', flexDirection:'column',alignItems:'flex-start' 
  const showHtml = (data) => {   
    return (
        <div style={{verticalAlign:'top',height:'100%'  }} dangerouslySetInnerHTML={{ __html: data}} />
    )
  };

  const showMaskedField =(value,maskType,uniformLength,maskCharacters) =>{
    let mask='*'
      if(maskType==='full' && uniformLength) {
        return  mask.repeat(uniformLength);
      } else if (maskType==='full') {
        return value.replace(/./g, '*');
      } else if (maskType==='partial') {
        let newval=mask.repeat(value.length);
        if(value.length > maskCharacters) {
           newval = mask.repeat(value.length-maskCharacters) + value.slice(value.length-maskCharacters,value.length);
        } 
        return  newval;
      }
      return null;
  }

  const getMessageFormat = (value) => {
    if (value !== null && value[0] !== undefined && value[0].messageid !== null) {
      return (
        <>
          <div
            style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '3px' }}
          >
            <b>{getDuration(value)}:</b> {value[0].message}
          </div>
          <div>
            <Icon
              path={value[0].tablerowid === null ? mdiChatProcessingOutline : mdiChatProcessing}
              horizontal={value[0].tablerowid === null}
              size="20px"
              color={value[0].schedule === null ? color : '#B4B3B3'}
            />
          </div>
        </>
      );
    } else {
      return '';
    }
  };

  const showAttachment = (item) => {
    window.open(item.gcsurl);
  };

  const showDetail = (id) => {
    setSelectedRow(id);
   

    //12-9-22 if this is for Groupview, we need to send array of id's to block component
    //so that the next/prev can work correctly in groupview, otherwise don't pass anything.

    if (groupTableWidth !== undefined) {
      const groupids = data.map((el) => el['id']);
      showDetailView(false, null, id, groupids);
    } else {
      const ids = data.map((el) => el['id']);
      showDetailView(false, null, id);
    }
  };

  const rowProps = ({ rowIndex }) => {
    const tData = groupTableHeight !== undefined ? data.slice(page, page + resultSize) : data;
    const currentRowId = tData[rowIndex].id;

    if (currentRowId === selectedRow) return 'quickview';
  };

  const onColumnResize = async ({ column, width }) => {
    if (UpdateTableColumnWidth !== null) {
      UpdateTableColumnWidth(column.key, width);
    }
  };

  const sortResults = ({ column, key, order }) => {
    // no offset= main grid.
    if (heightOffset === undefined) {
      setSortField(null, order, key, key === 'id' ? tableinfo.recordname : column.title);
    } else {
      setSortField(key, order, key === 'id' ? tableinfo.recordname : column.title);
    }
    setSortBy({ key: key, order: order });


  };

  const handleKeyChange =({rowData}) =>{
    const selectedRowKeys = [...selectedRows];
    const rowIndex = selectedRowKeys.indexOf(rowData.id);

    if (rowIndex !== -1) {
      selectedRowKeys.splice(rowIndex, 1);
    } else {
      selectedRowKeys.push(rowData.id);
    }
    setSelectedRows(selectedRowKeys);
  }


  useEffect(()=> {
    let columns = [];
    let displayCols = tableinfo.columns.filter((el) => el.hide === undefined || el.hide === false);
    
    if(!showAllColumns){
     displayCols = displayCols.slice(0,3)
    }
     
   displayCols
      .map((col, i) => {
        if (col.data === 'id' && mode===null) {
          columns.push({
            key: col.data,
            resizable: true,
            sortable: true,
            width: col.width != 25 ? col.width : window.innerWidth <= 500 ? 150 : 250,
            title: col.header,
            dataKey: 'CustomID',
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer: ({ rowData }) => (
              <div
                style={{
                  height: '100%',
                  cursor: showDetailView !==null ? 'pointer' : null,
                  paddingTop: '2px',
                  paddingTop: '2px',
                  verflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: `${color}`,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
                onClick={() =>  showDetailView !==null ? showDetail(rowData[col.data]) : null}
              >
                🔎 {rowData['CustomID']}
              </div>
            ),
            headerRenderer: () => `${tableinfo.recordname}`,
          });
        } 
        else if (col.data === 'id' && mode !==null) {
          if(mode==='review' && canEdit) {
            columns.push({
            key:'delete',
            title: 'Remove',
            width: 65,
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer:({ rowData }) => (
              <div
              style={{
                height: '100%',
                cursor: 'pointer',
                paddingTop: '2px',
                paddingTop: '2px',
                verflow: 'hidden',
                textOverflow: 'ellipsis',
                color: `${color}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
              onClick={() => RemoveItem(rowData.id, rowData['CustomID'])}
            >     
              <div style={{width:'5px'}}></div>
              <IconButton
                color="red"
                hoverColor="white"
                hoverBackgroundColor="#F6685E"
                icon={mdiClose}
              />
              </div>
              ),
              headerRenderer: () => <div style={{fontWeight:'normal',fontSize:'11px'}}>REMOVE</div>
          })
        }
          columns.push({
            key:col.data,
            title: col.header,
            dataKey: 'CustomID',
            width: col.width != 25 ? col.width : window.innerWidth <= 500 ? 150 : 250,
            selectedRowKeys:{selectedRows},
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer:({ rowData }) => (
              <div
              style={{
                height: '100%',
                cursor: 'pointer',
                paddingTop: '2px',
                paddingTop: '2px',
                verflow: 'hidden',
                textOverflow: 'ellipsis',
                color: `${color}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
              onClick={() => showDetailView(rowData.id, rowData['CustomID'])}
            >     
                  {mode==='addition' && selectMode==='multiple'? (
                  <Checkbox
                    checked={selectedRows.includes(rowData.id)}
                    />
                    ): null}

                    <div style={{width:'5px'}}></div>
                    {rowData['CustomID']}
              </div>
              ),
            headerRenderer: () => <div style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{tableinfo.recordname}</div>
          });
        } 
        else if (col.uitype === 2 || col.uitype === 3 || col.uitype === 9) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable:  mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => (
              <div
                style={{
                  height: '100%',
                  paddingTop: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                {rowData[col.data]}
              </div>
            ),
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } 
        else if (col.uitype === 14) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => 
            rowData[col.data] !== null ? showMaskedField(rowData[col.data],col.maskType,col.uniformLength,col.maskCharacters) : '',
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } 
        else if (col.uitype === 13) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: false,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] !== null ? showHtml(rowData[col.data]) : '',
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (col.uitype === 10) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => (
              <div
                style={{
                  height: '100%',
                  paddingTop: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <a
                  style={{ color: '#669CF6' }}
                  href={'mailto:' + rowData[col.data]}
                  target="_newtab"
                >
                  {rowData[col.data]}
                </a>
              </div>
            ),
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (col.uitype === 11) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => (
              <div
                style={{
                  height: '100%',
                  paddingTop: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                {col.useButtonDisplay !==undefined && rowData[col.data] !==null ? (
                <button  style={{border:`1px solid ${color}`, outline:'none', paddingTop:'5px',paddingBottom:'4px', backgroundColor:color,color:'white',borderRadius:'5px'}} onClick={()=>window.open(rowData[col.data])}>{col.buttonLabel}</button>
                ) : <a style={{ color: '#669CF6' }} href={rowData[col.data]} target="_newtab">
                {rowData[col.data]}
              </a> }
              
              </div>
            ),
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (col.uitype === 1) {
          columns.push({
            key: col.data,
            sortable: col.lookup === 'single' && mode===null,
            style: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
            resizable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => (
              <div
                style={{
                  whiteSpace: 'wrap',
                  paddingTop: '2px',
                  verticalAlign: 'top',
                  height: '100%',
                }}
              >
                <RelationalTag color={color} value={rowData[col.data]} />
              </div>
            ),
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if ([24, 25].includes(col.uitype)) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <SelectedUserItem
                    key={rowData[col.data].userid}
                    item={rowData[col.data]}
                    color={rowData[col.data].attributes.text}
                    Action={null}
                  />
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if ([18,16].includes(col.uitype) && col.renderandedit === 'LookupValues') {
          columns.push({
            key: col.data,
            style: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
            resizable: mode===null,
            sortable: mode===null && (col.uitype===16 || (col.uitype===18 && !['all','unique'].includes(col.specialfunction))),
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <DisplayLookupValues
                    value={rowData[col.data]}
                    isEditor={false}
                    isRenderer={true}
                    uitype={col.uitype===16 ? col.uitype : col.source.lookupuitype}
                    numericFormat={col.numericFormat}
                    dateFormat={col.dateFormat}
                    UTC={col.UTC}
                    color={color}
                    fieldType={col.fieldType}
                    specialfunction={col.specialfunction}
                    useButtonDisplay={col.useButtonDisplay}
                    buttonLabel={col.buttonLabel}
                    callingComponent="quickview"
                    showForm={showForm}
                  />
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (
          col.uitype === 8 ||
          (col.source !== undefined && col.source.lookupuitype === 8)
        ) {
          columns.push({
            key: col.data,
            sortable: col.lookup === 'single' && mode===null,
            style: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
            resizable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
                <div style={{ height: '100%', whiteSpace: 'wrap', paddingTop: '2px' }}>
                  <UserTag item={rowData[col.data]} />
                </div>,
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          })
        } else if (
          [6, 7].includes(col.uitype) ||
          (col.source !== undefined && [6, 7].includes(col.source.lookupuitype))
        ) {
          columns.push({
            key: col.data,
            sortable: col.uitype === 7 && mode===null,
            style: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
            resizable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) => (
              <div style={{ height: '100%', whiteSpace: 'wrap', paddingTop: '2px' }}>
                <SelectTag
                  key={col.data}
                  enableColors={col.enableColors}
                  value={rowData[col.data]}
                />
              </div>
            ),
            headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if ([19, 20, 22].includes(col.uitype)) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <Moment utc={col.UTC===undefined ? true :  col.UTC} format={col.dateFormat}>
                    {rowData[col.data]}
                  </Moment>
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (col.data !== 'id' && [17,23].includes(col.uitype)) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  {getFormattedNumber(rowData[col.data], col)}
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (
          col.uitype === 4 ||
          (col.source !== undefined && col.source.lookupuitype === 4)
        ) {
          columns.push({
            key: col.data,
            height: rowHeight,
            resizable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    // paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    paddingBottom:'2px',
                    justifyContent:'center'
                  }}
                >
                  <ImageThumbnails
                    showDeleteIcon={false}
                    Action={showAttachment}
                    showHover={false}
                    items={rowData[col.data]}
                    height={rowHeight-5}
                    marginTop="2px"
                  />
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (
          col.uitype === 12 ||
          (col.source !== undefined && col.source.lookupuitype === 12)
        ) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] && (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  {getMessageFormat(rowData[col.data])}
                </div>
              ),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        } else if (col.uitype === 5) {
          columns.push({
            key: col.data,
            resizable: mode===null,
            sortable: mode===null,
            width: parseInt(col.width),
            title: col.header,
            dataKey: col.data,
            cellRenderer: ({ rowData }) =>
              rowData[col.data] &&
              (rowData[col.data] && rowData[col.data].toLowerCase() === 'true' ? (
                <div
                  style={{
                    height: '100%',
                    paddingTop: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <Icon size="20" path={mdiCheck} color="green" />
                </div>
              ) : null),
              headerRenderer: () => <div style={{minWidth:col.width,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} title={col.callout}>{col.header}</div>,
          });
        }
      });
      setCols(columns)
    }, [selectedRows,showAllColumns])

  const scrollUpdate = ({
    scrollLeft,
    scrollTop,
    horizontalScrollDirection,
    verticalScrollDirection,
    scrollUpdateWasRequested,
  }) => {
    
    if (scrollTop > 0) {
      const row = Math.round(scrollTop / rowHeight);
      const value = `tbl_${tableid}_currentRow`;
      const qv = `quick_${tableid}_view`;
      userInfo[qv] = { scrollLeft, scrollTop };
      userInfo[value] = row;
    } 
   
  };

  const loadMoreData = async () => {
    console.log(recordCount)
    if (data.length < recordCount) {
      await loadData(data.length);
    }
  };

  const getWidth = () => {
    if (groupTableWidth !== undefined) {
      return groupTableWidth;
    } else {
      return heightOffset === undefined
        ? !isExpanded
          ? window.innerWidth - 100
          : window.innerWidth - 270
        : '100vw';
    }
  };
  const getHeight = () => {
    return groupTableHeight !== undefined
      ? groupTableHeight
      : heightOffset === undefined
      ? window.innerHeight - (!showBookmarkBar ? 160 : 185)
      : `calc(100vh - ${heightOffset}px)`;
  };

  const rowEventHandlers = {
    onDoubleClick: ({ rowData, rowIndex, rowKey, event }) => {
      if(mode===undefined || mode===null && showDetailView !==null) {
        showDetail(rowData['id']);
      } else if(showDetailView !==null){
        showDetailView(rowData['id'],rowData['CustomID'])
      } else {
        return false;
      }
    },
  };

  return (
    <div
      style={{
        width: getWidth(),
        height: getHeight(),
        // width: heightOffset===undefined ? !isExpanded ? window.innerWidth - 100 : window.innerWidth -270 :  '100vw',
        // height: groupTableHeight !== undefined ?  groupTableHeight : heightOffset===undefined ? window.innerHeight - (!showBookmarkBar ? 160 :185) : `calc(100vh - ${heightOffset}px)`,
      }}
    >
      {/* <button onClick={()=>loadData(data.length)}>Loda more...</button> */}

      {groupTableHeight !== undefined && data.length > resultSize ? (
        <div style={{ marginLeft: '37px', marginBottom: '5px' }}>
          <PageControls
            currentPage={Number.isInteger((page + resultSize) / resultSize) ? (page + resultSize) / 1000 : Math.ceil( (page + resultSize) / resultSize)}
            navigate={updatePage}
            totalPages={totalPages}
          />
        </div>
      ) : null}
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            style={{borderTopLeftRadius:'10px',borderTopRightRadius:'10px',border:'0px solid black'}}
            ref={measuredRef}
            onColumnResizeEnd={onColumnResize}
            onScroll={scrollUpdate}
            rowEventHandlers={rowEventHandlers}
            onEndReachedThreshold={300}
            onEndReached={loadData !== null ? loadMoreData : null}
            rowKey="id"
            rowClassName={rowProps}
            sortBy={sortBy}
            onColumnSort={sortResults}
            fixed
            rowHeight={rowHeight}
            headerHeight={25}
            // columns={mode==='addition' && !isLoading ? loadColumns() : mode==='review' && !isLoading ? cols : []}
            columns ={isLoading ? [] : cols}
            data={groupTableHeight !== undefined ? data.slice(page, page + resultSize) : data}
            width={width}
            height={groupTableHeight !== undefined && data.length > resultSize ? height - 30 : height}
            footerHeight={groupTableHeight !== undefined && data.length > resultSize ? 30 : null}
            footerRenderer={
              groupTableHeight !== undefined && data.length > resultSize ? (
                <div style={{ marginLeft: '15px', marginTop: '5px', marginBottom: '5px' }}>
                  <PageControls
                    currentPage={Number.isInteger((page + resultSize) / resultSize) ? (page + resultSize) / resultSize : Math.ceil( (page + resultSize) / resultSize)}
                    totalPages={totalPages}
                    navigate={updatePage}
                  />
                </div>
              ) : null
            }
          />
        )}
      </AutoResizer>
    </div>
  );
};

export default QuickViewVirtual;
