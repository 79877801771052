/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef } from 'react';

import moment from 'moment';
import _ from 'lodash';
import { Popup } from 'semantic-ui-react';
import { mdiPencil, mdiDelete, mdiContentSave, mdiCancel } from '@mdi/js';
import { AccountContext } from '../../../../providers/AccountProvider';
import { getTableMessages, updateTableMessages } from '../../../../api/tables';
import TextButton from '../../../../components/TextButton/TextButton';
import Avatar from '../../../../components/Avatar/Avatar';
import SearchBar from './SearchBar';
import Spinner from '../../../../components/Spinner/Spinner';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import { getUsersAPI, getBlockUsersAPI } from '../../../../api/users';
import { saveNotification } from '../../../../api/notifications';
import UserCard from '../../../../components/UserDropdown/UserCard';
import Global from '../../../../clients/global'

const CollaboratorMessages = ({ color, tableid, dataID, blockid, RID, recordName,canCollaborate=true,canViewComments=true,canEdit }) => {
  const { userInfo } = useContext(AccountContext);
  const [messages, SetMessages] = useState([]);
  // const [rawMessages, setRawMessages] = useState([]);
  const [messageUsers, setMessageUsers] = useState([]);
  const [blockUsers, setBlockUsers] = useState([]);
  const [filteredBlockUsers, setFilteredBlockUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [msgId, setMsgId] = useState(null);
  const [hoverMessageId, setHoverMessageId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState(null);
  const [messageSetting, setMessageSetting] = useState({ interval: 'none', number: 1 });
  const [activeMessage, setActiveMessage] = useState('');
  const [showUserList, setShowUserList] = useState(false);
  const [userKey, setUserKey] = useState(null);
  const [term, setTerm] = useState('');
  const [updatedUsrMsg, setUpdatedUsrMSg] = useState('');
  const [cursor, setCursor] = useState(0);


  const divRef = useRef(null);
  const myUsersRef = React.useRef(blockUsers);

  useEffect(() => {
    if (tableid !== undefined) {
      setMessage('');
      setFileUrls(null);
    }
    fetchData();
    // console.log(userInfo);
  }, [dataID]);

  const scrollHandler = () => {
    if (divRef.current !== null) {
      divRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  const fetchData = async (showSpinner = true) => {
    // 12-4-2020 added showSpinner param so that when we are doing interval checks for new messages
    // it doesn't show spinner, but when a user sends a message from UI, it shows spinner.

    let AllUsers = [];
    if (messageUsers.length === 0) {
      AllUsers = await getUsersAPI([],true);
      const tempblockusers = await getBlockUsersAPI(blockid,true,true);
      setMessageUsers(AllUsers);
      setBlockUsers(tempblockusers);
      myUsersRef.current = tempblockusers;
      setFilteredBlockUsers(tempblockusers);
    }

    setIsLoading(showSpinner ? true : false);

    let newMessages = await getTableMessages(tableid, dataID);

    // setRawMessages(newMessages);

    if (newMessages.length > 0 && AllUsers.length > 0) {
      newMessages.sort((a, b) => (a.addedon > b.addedon ? 1 : -1));

      newMessages.forEach((itm) => {
        const today = moment().startOf('day');
        const msgDate = moment(itm.addedon).startOf('day')
        ;
        const days = today.diff(msgDate, 'days',false);
        // console.log(`days is ${days}`);
        if (days === 0) {
          itm.day = 'Today';
        } else if (days === 1) {
          itm.day = 'Yesterday';


        } else {
          itm.day = moment(itm.addedon).format('DD-MMM-YYYY');
        }
        const addedbyUser = AllUsers.filter((el) => el.userid === itm.addedby)[0];
        itm.User = addedbyUser;
      });
      SetMessages(newMessages);

      scrollHandler();
    } else if (newMessages.length === 0) {
      SetMessages([]);
    }
    setIsLoading(false);
  };

  const messageChangeHandler = (msg) => {
    setMessage(msg);
  };

  function pasteHtmlAtCaret(html) {
    var sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // only relatively recently standardized and is not supported in
        // some browsers (IE9, for one)
        var el = document.createElement('div');
        el.innerHTML = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if (document.selection && document.selection.type != 'Control') {
      // IE < 9
      document.selection.createRange().pasteHTML(html);
    }
  }

  const handleKeyForUserList = () => {
    setShowUserList(!showUserList);
    document.getElementById('userList').focus();
  };

  const DeleteMessage = async (addedon) => {
    const newmessages = messages.filter((el) => el.addedon !== addedon);
    await updateTableMessages(dataID, tableid, newmessages);
    SetMessages(newmessages);
  };

  const saveUpdatedMessage = async (addedon) => {
    const idx = messages.findIndex((el) => el.addedon === addedon);
    const msg = document.getElementById('editmessage').innerHTML;
    messages[idx].message = msg;

    //Get any users mentioned in edited message
    var myarray = [];
    const x = document.getElementById('editmessage').querySelectorAll('a');
    for (var i = 0; i < x.length; i++) {
      var cleanLink = x[i].name;
      myarray.push(cleanLink);
    }
    console.log(myarray);

    await updateTableMessages(dataID, tableid, messages);
    SetMessages(messages);
    setMsgId(null);
    setActiveMessage(null);

    if (myarray.length > 0) {
      await sendNotifications(myarray, msg);
    }
  };

  const SendMessage = async (userids) => {
    const now = new Date().toISOString();
    //Add new message and save to DB.

    const testingMessage = {
      addedby: userInfo.id,
      addedon: now,
      message: message,
      files: fileUrls,
      day: 'Today',
    };
    // const copyMsgs = [...rawMessages];
    const copyMsgs = [...messages];
    copyMsgs.push(testingMessage);
    await updateTableMessages(dataID, tableid, copyMsgs);

    const usr = messageUsers.filter((itm) => itm.userid === userInfo.id)[0];
    const currentMessages = [...messages];

    testingMessage.User = usr;
    currentMessages.push(testingMessage);

    let notifyMessage = message;
    if (fileUrls !== null) {

      fileUrls.map((file) => {
        // console.log(file);
        notifyMessage += ` <a href="${file.gcsurl !== undefined ? file.gcsurl : file.url}" target="_newwindow">${file.uploadtype==='desktop' ? file.filename : 'attachment'} </a>`;
      });
    }

    SetMessages(currentMessages);
    setFileUrls([]);
    setMessage('');
    setUpdatedUsrMSg('');

    if (userids.length > 0) {
      await sendNotifications(userids, notifyMessage);
    }
  };

  const sendNotifications = async (userids, message) => {

    const blockIdx = userInfo.blocks.findIndex(el=>el.blockid===blockid);
    let blockName='Block';
    if(blockIdx !==-1) {
      blockName = userInfo.blocks[blockIdx].name;
    }
    // const currentBlock = userInfo.blocks.filter((el) => el.blockid === blockid)[0];
    const currentZone = userInfo.zones.filter((el) => el.id == parseInt(Global.zoneid))[0].name;
    
    const messageData = {
      RID,
      recordName,
      blockColor: color,
      blockName: blockName,
      currentZone,
      userInfo: userInfo,
    };
    await saveNotification(userids, blockid, tableid,dataID, 2, message, messageData);
  };

  const ShowImageInWindows = (item) => {
    window.open(item.url);
  };

  const editMessage = (msg) => {
    setMsgId(msg.addedon);
    setActiveMessage(msg.message);

    // console.log(elemRef.current)
  };

  const addUser = (user, eventLocation) => {
    // 1-28-2022. Currently there is a bug when a user uses the mouse click to add user to the comment versus
    // the enter key. For whatever reason, the mouse click does not remember the carot position, so it ends up
    // adding user to beginning of comment, where the "enter" key correctly adds user into carot location.
    // for now (we can revisit later), I will change logic so if coming from "click", it will simply
    // append user to end of message.

    const addMessage = `<a href="#/${user.userid}" name="${user.userid}" style="background-color:#E8F5FA;">@${user.firstname} ${user.lastname}</a>&nbsp;`;
    const isEditMsg = document.getElementById('editmessage');

    if (isEditMsg === null) {
      if (eventLocation === 'keyboard') {
        setUpdatedUsrMSg(addMessage);
      } else {
        setUpdatedUsrMSg('||' + addMessage);
      }
    } else {
      if (eventLocation === 'keyboard') {
        document.getElementById('editmessage').focus();
        pasteHtmlAtCaret(addMessage);
      } else {
        isEditMsg.innerHTML = isEditMsg.innerHTML + addMessage;
      }
    }

    setShowUserList(false);
    setTerm('');
  };

  function handleKeyDown(e) {

    // arrow up/down button should select next/previous list element
    const isEditMsg = document.getElementById('editmessage');

    if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1);
    } else if (e.keyCode === 40 && cursor < myUsersRef.current.length - 1) {
      setCursor(cursor + 1);
    } else if (e.key === ' ') {
      setShowUserList(false);
      if (isEditMsg !== null) {
        document.getElementById('editmessage').focus();
      }
    } else if (e.keyCode === 8) {
      if (term.length > 0) {
        setTerm(term.slice(0, -1));
      } else {
        setShowUserList(false);
        if (isEditMsg != null) {
          document.getElementById('editmessage').focus();
        }
      }
    } else if (e.keyCode === 13) {
      const currentList = myUsersRef.current.filter((el) =>
        el.firstname.toLowerCase().startsWith(term),
      );
      const user = currentList[cursor];
      addUser(user, 'keyboard');
      e.preventDefault();

      //   setShowUserList(false);
    } else if (e.key.toLowerCase().length === 1) {
      setTerm(term + e.key);
      setCursor(0);
      if (
        myUsersRef.current.filter((el) => el.firstname.toLowerCase().startsWith(term)).length === 0
      ) {
        setShowUserList(false);
        setTerm('');
        myUsersRef.current = blockUsers;
      }
    }
  }

  const DisplayMessages = () => {
    // The UI groups messages by day. We create a unique set of days by which
    // we loop over to display messages chronologically and by day.
    const days = messages.map((el) => el.day);
    let uniquedays = [...new Set(days)];

    return uniquedays.map((itm) => (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }} key={itm}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
          <div
            style={{
              width: '100px',
              marginLeft: '10px',
              marginRight: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            {itm}
          </div>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
        </div>
        {messages
          .filter((el) => el.day === itm)
          .map((msg) => (
            <div
              style={{ backgroundColor: hoverMessageId === msg.addedon ? '#F8F8F8' : 'white',position:'relative' }}
              key={msg.addedon}
            >
              <div
                onMouseOver={() => setHoverMessageId(msg.addedon)}
                onMouseOut={() => setHoverMessageId(null)}
                style={{
                  // display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  // flowDirection: 'row',
                  alignItems: 'center',
                  minWidth: '0px',
                  //   width:'100%',
                  // justifyContent: 'flex-start',
                }}
              >
                <div>
                  <Popup
                    onClick
                    position="top center"
                    content={'hello'}
                    trigger={
                    <Avatar item={msg.User} width="35px" height="35px" fontSize="13px" />}
                  />
           
                </div>
                {msg.addedon === msgId ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: msg.message }}
                      onKeyDown={(e) => {
                        if (e.key === '@') {
                          handleKeyForUserList();
                        }
                      }}
                      //   onInput={(e) => onChange(e)}
                      style={{
                        border: `1px solid ${color}`,
                        borderRadius: '5px',
                        minWidth: '350px',
                        backgroundColor: 'white',
                        minHeight: '35px',
                        padding: '3px',
                        marginBottom: '5px',
                      }}
                      id="editmessage"
                      contenteditable="true"
                    ></div>

                    <div style={{ width: '10px' }} />
                    <TextButton
                      Action={saveUpdatedMessage}
                      ActionValue={msg.addedon}
                      text="Save"
                      textColor="black"
                      hoverColor="#757575"
                      icon={mdiContentSave}
                      iconPosition="left"
                    />
                    <TextButton
                      Action={setMsgId}
                      ActionValue={null}
                      text="Cancel"
                      textColor="black"
                      hoverColor="#757575"
                      icon={mdiCancel}
                      iconPosition="left"
                    />
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: msg.message }} />
                )}

                <div
                  style={{
                    //   visibility: msg.addedon=== hoverMessageId && msg.addedby===userInfo.id ? 'visible' : 'hidden',
                    display:
                      msg.addedon === hoverMessageId &&
                      msg.addedby === userInfo.id &&
                      msg.addedon !== msgId
                        ? 'flex'
                        : 'none',
                    position:'absolute',
                    top:20,
                    right:100,
                    flexDirection: 'row',
                    marginLeft: '20px',
                    marginRight: '20px',
                    fontSize: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <TextButton
                    text="edit"
                    textColor="black"
                    hoverColor="red"
                    iconPosition="left"
                    icon={mdiPencil}
                    Action={editMessage}
                    ActionValue={msg}
                  />
                  <TextButton
                    text="delete"
                    textColor="black"
                    hoverColor="red"
                    iconPosition="left"
                    icon={mdiDelete}
                    Action={DeleteMessage}
                    ActionValue={msg.addedon}
                  />
                </div>
              </div>
              {msg.files !== null ? (
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: '40px',
                    width: '50%',
                  }}
                >
                  <ImageThumbnails
                    items={msg.files}
                    maxWidth="100%"
                    Action={ShowImageInWindows}
                    showDeleteIcon={false}
                  />
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  marginLeft: '50px',
                  marginRight: '60px',
                  marginBottom: '10px',
                  fontSize: '10px',
                  justifyContent: 'flex-start',
                }}
              > <Popup
              on="click"
              position="top center"
              content={<UserCard user={msg.User} />}
              trigger={
                <span>{moment(msg.addedon).format('hh:mm:ss a ')} EST by {msg.User.firstname} {msg.User.lastname}</span>
              }/>
              </div>
            </div>
          ))}
      </div>
    ));
  };

  return (
    <div
      tabIndex={0}
      style={{
        marginTop:'10px',
        display: 'flex',
        height: window.innerWidth < 500 ? '100%' : '100%',  //calc(100vh - 170px)
        width: '100%',
        outline: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      {/* 1-29-2022 This input is needed to prperly get focus when the detail page is viewed. We use
        this dummy input to autofocus but keep it off the screen hidden. In the useEffect method of searchbar, it then
        resets the focus to the editable div. Same input is used in attachmentviewer. Without it
        when you hit enter/backspace, focus is still on grid and doesn't work. */}
      <input style={{ position: 'absolute', top: -1000, height: '0px' }} autoFocus={canEdit} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          overflow: 'auto',
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        ></div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto',wordWrap:'break-word',overflowWrap:'break-word',minWidth:'0' }}>
          {messages.length > 0 && canViewComments ? DisplayMessages() : null}
          <div style={{ width: '1px', height: '0px' }} ref={divRef} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '10px',
          position: 'relative',
          zIndex: 10,
        }}
      >
        {isLoading ? (
          <div style={{ position: 'absolute', bottom: '250px', left: '50%', zIndex: 1000 }}>
            <Spinner color={color} showMessages={false} />
          </div>
        ) : null}

        {/* {showUserList ? ( */}
       
        <div
          tabIndex="0"
          id="userList"
          value=""
          onKeyDown={handleKeyDown}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 20,
            top: -160,
            left: 20,
            borderRadius: '5px',
            border: !showUserList ? '0px' : '1px solid #757575',
            width: '300px',
            height: showUserList ? '150px' : '0px',
            overflow: 'auto',
          }}
        >
           {term.length>0 ? <div style={{marginLeft:'5px'}}>Filtered by: <b>{term}</b></div>: null}
          {myUsersRef.current
            .filter((el) => el.firstname.toLowerCase().startsWith(term))
            .map((user, i) => (
              <div
                key={user.userid}
                onClick={(e) => addUser(user)}
                onMouseOver={() => setUserKey(user.userid)}
                onMouseOut={() => setUserKey(null)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px',
                  backgroundColor: i === cursor ? '#F8F8F8' : 'white',
                }}
              >
                <Avatar item={user} width="25px" height="25px" fontSize="13px" />
                <div>
                  {user.firstname} {user.lastname}
                </div>
              </div>
            ))}
        </div>
        {/* ): null} */}
        {canCollaborate ? (
        <div style={{ marginBottom: '10px', backgroundColor: 'white' }}>
          <SearchBar
            color={color}
            messageChangeHandler={messageChangeHandler}
            updateFileUrls={setFileUrls}
            fileUrls={fileUrls}
            message={message}
            zoneid={Global.zoneid}
            isEnabled={true}
            Action={SendMessage}
            messageSetting={messageSetting}
            updateMessageSetting={null}
            setShowTemplates={null}
            updateSetting={null}
            dataID={dataID}
            callingComponent={'collaborators'}
            updatedUsrMsg={updatedUsrMsg}
            userListActive={showUserList}
            handleKeyForUserList={handleKeyForUserList}
          />
        </div>
        ): null}
      </div>
    </div>
  );
};

export default CollaboratorMessages;
