import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import BootstrapToggleButton from 'react-bootstrap/ToggleButton';

const ToggleButton = (props) => {
  const className = classNames();
  return <BootstrapToggleButton {...props} className={className} />;
};

// added comment on 12-7-2021 at 11:35 AM to collab fixes. test

// ToggleButtonGroup.defaultProps = {
//   long: false,
// };

// ToggleButtonGroup.propTypes = {
//   long: PropTypes.bool,
// };

export default ToggleButton;
