import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mdiPlusCircle, mdiPlus } from '@mdi/js';
import Icon from '../../../components/Icon';

const EmptyItem = ({ onClick }) => {
  const className = classNames('zone-block-item', 'empty');

  return (
    <div className={className}>
      <div
        className="zone-block-item-placeholder"
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex="-1"
      >
        <div>
          <div className="icons">
            <Icon path={mdiPlus} color="#757575" size={0.7} />
            <Icon path={mdiPlusCircle} color="#757575" size={0.7} />
          </div>
          <div>Add Block</div>
        </div>
      </div>
    </div>
  );
};

EmptyItem.defaultProps = {
  onClick: () => {},
};

EmptyItem.propTypes = {
  onClick: PropTypes.func,
};

export default EmptyItem;
