/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown, mdiPencil } from '@mdi/js';


const GBAddNewRecordButton = ({options, color, Action, ActionValue, ActionTwo,isMaxRowsExceeded=false,role }) => {
  //   const options = [
  //     { key: 'edit', icon: 'pencil alternate icon', text: 'Manage web forms', value: 'edit' },
  //     { key: 'delete', icon: 'external alternate', text: 'New contact form 1 that is reall long', value: 'delete' },
  //     { key: 'hide', icon: 'external alternate', text: 'New contact form 2', value: 'hide' },
  //   ];

  return (
    <div
      css={{
        userSelect: 'none', 
        width: '150px',
        '&:hover': {
          '& .gbbutton': {
            // border: `1px solid ${color}`,
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            svg: {
              color: '#FFFFFF80',
              // backgroundColor: 'white',
              // borderRadius: '100%',
              // '&:hover': {
              //   backgroundColor: 'white',
              //   borderRadius: '100%',
              //   // borderRadius: '100%',
              //   color: color,
              //   transition: '.5s all ease',
              // },
            },
          },
          '& .gbdropdown': {
            svg: {
              color: `white`,
              opacity: 1,
              '&:hover': {
                color:color,
                backgroundColor: 'white',
                borderRadius:'100%'
              },
            },
          },
          '& .gbtext' : {
            color: `#FFFFFF80`,
          }
        },
        '& .gbdropdown': {
          svg: {
            opacity: 1,
          },
        },
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px' }}
      >
        <div
          className="gbbutton"
          css={{
            height: '35px',
            color:'white',
            backgroundColor: color,
            outline: 'none',
            display: 'flex',
            flexDirection: 'row',
            fontSize:'13px',
            alignItems: 'center',
            borderBottomLeftRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: role !==3 && options.length==0 ? '4px' :null,
            borderBottomRightRadius: role !==3 && options.length==0 ? '4px' :null,
            // border: '1px solid transparent',
          }}
        >
          <Icon style={{marginLeft:'3px'}} onClick={() => Action(ActionValue)} path={mdiPlus} size="20px" />
          <div
           className="gbtext"
            onClick={!isMaxRowsExceeded ? () => Action(ActionValue) : ()=> toast.info('Block over plan limits. A Zone plan upgrade is needed to add more  records.', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 7000,
            })}
            css={{
              backgroundColor: color,
              color:'white',
              whiteSpace:'nowrap',
              marginLeft:'2px',
              marginRight: '10px',
              border: '0px',
              fontWeight:'bold',
              height:'18px',
              borderBottomLeftRadius: '4px',
              borderTopLeftRadius: '4px',
            }}
          >
            New record
          </div>
        </div>
        {role===3  || options.length>0? (
        <div className="gbdropdown" css={{ opacity: 1 }}>
          <Dropdown
            selectOnBlur={false}
            text=""
            icon={
              <Icon
                style={{ marginLeft: '3px', marginTop: '5px' }}
                path={mdiChevronDown}
                size="25px"
              />
            }
            style={{
              backgroundColor: color,
              color:'white',
              width: '35px',
              height: '35px',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            direction="left"
          >
            <Dropdown.Menu>
              {options.map((el) => (
                <Dropdown.Item
                  key={el.key}
                  text={el.text}
                  icon={el.icon}
                  onClick={!isMaxRowsExceeded ? () => ActionTwo(el.value) : ()=> toast.info('Block over plan limits. A Zone plan upgrade is needed to add more  records.', {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 7000,
                  })}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        ):null}
      </div>
    </div>
  );
};

export default GBAddNewRecordButton;
