/* eslint-disable  */

import { Buffer } from 'buffer';

const customEncode=(zoneid, blockid, tableid, id, showMessage) => {
    // Concatenate the integers with a pipe '|' separator

    const {REACT_APP_SITE_URL} = process.env;

    let concatenatedText = `${zoneid}|${blockid}|${tableid}|${id}`;

    // Reverse the string
    let reversedText = concatenatedText.split('').reverse().join('');

    // Custom encoding: offset the digits and swap the delimiter
    let customEncoded = reversedText.split('').map(char => {
        if (!isNaN(parseInt(char)) && isFinite(char)) {
            // Offset digit by 2, wrapping around if necessary
            return (parseInt(char) + 2) % 10;
        } else if (char === '|') {
            // Replace '|' with '-'
            return '-';
        } else {
            return char;
        }
    }).join('');

    // Base64 encode the result
    let finalToken = Buffer.from(customEncoded).toString('base64') + '_' + zoneid;

    const finalUrl=`${REACT_APP_SITE_URL}/record/${finalToken}`
    
    if (navigator.clipboard && window.isSecureContext && showMessage !==null) {
        // Navigator.clipboard is available
        navigator.clipboard.writeText(finalUrl).then(function() {
            console.log("Successfully copied to clipboard");
            showMessage('Successfully copied to clipboard')
        }).catch(function(err) {
            console.error("Could not copy text: ", err);
        });
    }

    return finalUrl;
}

export default customEncode
