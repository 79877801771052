/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import { mdiTransitConnectionVariant,mdiChatProcessing,mdiAccountMultiple,mdiFilm,mdiDetails,mdiHistory } from '@mdi/js';
import ActionButton from '../../ActionBar/ActionButton';

const TopNavBar = ({color,setControl,control,hasRelatedRecords}) => {

    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', borderBottom:'1px solid #eee', width:'100%'}}>
           <div style={{display:'flex', height:'50px', marginTop:'10px', marginLeft:'10px', flexDirection:'row', alignItems:'center'}}>
                   <ActionButton label="Details" tooltip="View record details" actionIcon={mdiDetails} Action={setControl} ActionValue='details' showBottom={true} color={color} isSelected={control==='details'} />
                   <ActionButton label="Messages" actionIcon={mdiChatProcessing} Action={setControl} ActionValue='messages' showBottom={true} color={color} isSelected={control==='messages'} />
                   {hasRelatedRecords ? ( 
                   <ActionButton label="Related Records" actionIcon={mdiTransitConnectionVariant} Action={setControl} ActionValue='datalist' showBottom={true} color={color} isSelected={control==='datalist'} />
                   ): null}
                   
                   <ActionButton label="Collaboration" actionIcon={mdiAccountMultiple} Action={setControl} ActionValue='collaborators' color={color}  showBottom={true} isSelected={control==='collaborators'} />
                   <ActionButton label="History" actionIcon={mdiHistory} Action={setControl} ActionValue="history" color={color}  showBottom={true} isSelected={control==='history'} />
            </div>
        </div>
    )
}

export default TopNavBar