/* eslint-disable  */
import React,{Component}from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { AccountContext } from '../../../../providers/AccountProvider';
import {updateTableInfoAPI} from '../../../../api/tables';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import cloudrun from '../../../../clients/httpcloudrun';
import Callout from './Callout';

class CheckBoxField extends Component{
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo, selectedUIType } = this.props;

    this.state = {
      showAdvanced: false,
      callout: columnConfigData.callout,
      uitype: selectedUIType,
      tableinfo,
      isPrivate: columnConfigData.isPrivate ?? false
      //   defaultText: '',
    };
  } 

  static contextType = AccountContext;

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
    const {tableinfo,field,tableid,header,columnConfigData} = this.props;
    const {callout,isPrivate} = this.state;
    const originalUIType = columnConfigData.uitype;

    if(originalUIType ===22)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
      }  
      if (originalUIType === 23) {
        const payload = {
          tableid,
          tableinfo,
          field,
          newfieldtype: 'citext',
          castFunction: 'cast_numeric_to_text',
        };
        await cloudrun.post('/convertField', { payload });
      }
      else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } 
      else if(originalUIType === 9 || originalUIType ===10 || originalUIType ===11 ) {
        const payload = { tableid, tableinfo, field };
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } 


    const rowindex = tableinfo.columns.findIndex(el => el.data === field)
    if(rowindex !== -1)
    {
        tableinfo.columns[rowindex].header = header;
        tableinfo.columns[rowindex].type = 'checkbox';
        delete tableinfo.columns[rowindex].readOnly;
        tableinfo.columns[rowindex].uitype = 5;
        if(callout==='')
        {
          delete  tableinfo.columns[rowindex].callout
        } else {
          tableinfo.columns[rowindex].callout=callout;
        }

        if(isPrivate) {
          tableinfo.columns[rowindex].isPrivate =true;
        } else {
          delete tableinfo.columns[rowindex].isPrivate;
        }

        tableinfo.columns[rowindex].className = "htCenter htMiddle";
        tableinfo.columns[rowindex].renderer = null;
    }

      // delete any existing data that is not true/false
      const payload = {tableid,field,dbfunction:'deleteNonBooleanData'}
      await cloudrun.post('/executeQueryAPI',{payload})

      await updateTableInfoAPI(tableid,tableinfo,columnConfigData)
      return 'success';
    }

    render() {
    const {callout,isPrivate} = this.state;
    const {color,uitype}=this.props
    return (
        <div style={{width:'100%'}}>
            A single checkbox that can be checked or unchecked.
            <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            <Callout callout={callout} updateCallout={this.CalloutHandler} />
        </div>
    )
    }
}
CheckBoxField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableinfo: PropTypes.object.isRequired,
}
export default CheckBoxField