/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import { mdiMail, mdiCellphone, mdiMapMarker } from '@mdi/js';
import Avatar from '../../components/Avatar/Avatar';

const UserCard = ({ initials, user }) => {
  const { name, email, mobile, color, attributes, address } = user;

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
       <Avatar item={user} />
        <div>{user.firstname} {user.lastname}</div>
      </div>
      <div style={{ marginLeft:'25px',display: 'flex', flexDirection: 'column',marginTop:'20px' }}>
        {email ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginBottom:'5px' }}>
            <Icon style={{ marginRight: '5px' }} path={mdiMail} size="15px" /> {email}
          </div>
        ) : null}
        
        {mobile && mobile !=='000-000-0000' ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginBottom:'5px' }}>
          <Icon style={{ marginRight: '5px' }} path={mdiCellphone} size="15px" />
          {mobile}
        </div>
        ): null}
        {address ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Icon style={{ marginRight: '5px' }} path={mdiMapMarker} size="15px" />
          {address}
        </div>
        ): null}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginBottom:'5px' }}>
           <b style={{marginRight:'5px'}}>ID </b> {user.userid}
          </div>
      </div>
    </div>
  );
};

export default UserCard;
