/* eslint-disable  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input, Radio, Form, TextArea, Dropdown } from 'semantic-ui-react';
import {updateTableInfoAPI} from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import {replaceFieldsInTableUserColumnSettings} from '../../../../api/tables';
import cloudrun from '../../../../clients/httpcloudrun';
import Global from '../../../../clients/global'

class DateField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData} = this.props;
    this.state = {
      dateFormat: 'DD-MMM-YYYY',
      showAdvanced: false,
      includeTime: false,
      clockFormat: '12',
      timeFormat: 'minutes',
      UTC: false,
      defaultValue: '',
      callout: '',
      isFutureDays: true,
      isPrivate:columnConfigData.isPrivate ?? false
    };
  }

  static contextType = AccountContext;
  
  componentDidMount() {
    const { columnConfigData,uitype } = this.props;
    
    let clockFormat='12';
    let timeFormat="minutes";
     clockFormat = columnConfigData.dateFormat && columnConfigData.dateFormat.includes('A') ? '12' : '24';
     timeFormat = columnConfigData.dateFormat && columnConfigData.dateFormat.includes('ss') ? 'seconds' : 'minutes';

    this.setState({
      UTC: false,
      clockFormat,
      timeFormat,
      callout: columnConfigData.callout ? columnConfigData.callout : '',
      dateFormat: columnConfigData.dateFormatOnly ? columnConfigData.dateFormatOnly : 'DD-MMM-YYYY' ,
      defaultValue: columnConfigData.defaultValue ? columnConfigData.defaultValue : '',
      isFutureDays: columnConfigData.isFutureDays ? columnConfigData.isFutureDays : true,
      includeTime: columnConfigData.includeTime ? columnConfigData.includeTime : false,
    });
  }

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  IncludeTimeHandler =() => {
    const {includeTime,UTC} = this.state;
    // if Time is turned off, we want to set UTC=true, so that the correct date is displayed in UI For date only.
    //This should solve issue with date appearing 1 day older than specifiedc. 
    let tempUTC = !includeTime ? UTC : true;
    this.setState({includeTime:!includeTime, UTC:tempUTC})
  }

  DateStringFormat = () => {
    const { clockFormat, timeFormat, dateFormat, includeTime } = this.state;

    const hourformat = clockFormat === '24' ? 'HH' : 'hh';
    const minuteformat =
      timeFormat === 'minutes'
        ? `${hourformat}:mm ${clockFormat === '12' ? 'A' : ''}`
        : `${hourformat}:mm:ss ${clockFormat === '12' ? 'A' : ''}`;
    let finaldateFormat = dateFormat;
    if (includeTime) {
      finaldateFormat = `${dateFormat} ${minuteformat.trim()}`;
    }

    return finaldateFormat;
  };

  defaultValueHandler = (value) => {
    const regex = '^[0-9]*$';
    if (value.toString().match(regex)) {
      this.setState({ defaultValue: value });
    } else {
      this.setState({ defaultValue: '' });
    }
  };

  HeaderText = () => {
    let headerText='';
    const {uitype}=this.props;
    switch(uitype) {
      case 22:
        headerText= "Store a date value in the system. You can customize the formatting and default options.";
        break
      case 19:
        headerText="This field displays the date the record was added to the system. This field is system generated. It is not editable.";
        break
      case 20:
        headerText="This field displays the date the record was last updated in the system. This field is system generated. It is not editable.";
        break
       default: 
       headerText="Store a date value"
    }

    return headerText
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
    const { tableinfo, field, tableid, columnConfigData,header,uitype } = this.props;
    const originaluitype = columnConfigData.uitype;

    const { UTC, defaultValue, callout, isFutureDays, dateFormat,includeTime,isPrivate } = this.state;
    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);

    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "displayfield": "${field}",
      "detailviewsort":${columnConfigData.detailviewsort !==undefined ? columnConfigData.detailviewsort : 100}
  }`

    const updatedNode = JSON.parse(defaultNode)

    try {
    if (rowindex !== -1) {
      updatedNode.header = header;
      updatedNode.uitype = uitype;
      updatedNode.callout = callout;
      updatedNode.isFutureDays=isFutureDays;
      updatedNode.dateFormat = this.DateStringFormat();
      updatedNode.dateFormatOnly = dateFormat;
      
      updatedNode.renderer = 'DateTimeRender';
      updatedNode.UTC = UTC;
      updatedNode.defaultFutureDays = isFutureDays;
      updatedNode.includeTime = includeTime;

      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }

      if(uitype===19 || uitype===20) {
        updatedNode.readOnly=true;
        updatedNode.displayfield = uitype===19 ? 'addedon' : 'updatedon'
        updatedNode.data = uitype===19 ? 'addedon' : 'updatedon'

        // 2-4-2021 When changing to a system field (addedon, updatedon) we need to 
        // manually update the TableUsers columnsettings fields to this value.
        // 10-21-2021 added update logic in API to update bookmarks.
        await replaceFieldsInTableUserColumnSettings(tableid,columnConfigData.data,uitype===19 ? 'addedon' : 'updatedon')

      }

      if (defaultValue !== '') {
        updatedNode.defaultValue = defaultValue;
      } else {
        delete updatedNode.defaultValue;
      }
      if (callout !== '') 
        updatedNode.callout = callout;
      } else {
        delete updatedNode.callout
      }

      tableinfo.columns[rowindex] = updatedNode;

      // 10/6/2020 if this is Addedon or UpdatedOn, we don't do anything to the field
      // as we simply point the metadata field to the actual "Addedon" column.
      // 8-20-23 changed to only run if standard date field (don't run for addedon , updatedon)
      if(uitype===22)
      {

       // If we are coming from numeric field, must set data to null, then convert field.
      if (originaluitype === 23 ) {
        // This changes the underlyig field to date type.
        // we only do this for custom date field . Addedon/updatedon(19,20) we skip this step.
      
        const payload = { tableid, tableinfo, field, newfieldtype: 'timestamptz',castFunction:'cast_numeric_to_date' };
        await cloudrun.post('/convertField', { payload })

        // await updateFieldAdmin(schema,tableid,tableinfo,field,'timestamptz')
      }  
      else if(originaluitype ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'timestamptz',castFunction:'cast_integer_to_date' };
        await cloudrun.post('/convertField', { payload })
      }
        // otherwise convert to date field from text if this is not a date field or system date field already. 
      else if (originaluitype !==22 && originaluitype !==19 && originaluitype !==20) {
        const d = new Date();
        const timezone = d.getTimezoneOffset()/60 +1;
        const payload = { tableid, tableinfo, field, timezone, newfieldtype: 'timestamptz',castFunction:'cast_text_to_date' };
        await cloudrun.post('/convertField', { payload })
      }
      // if coming from single select, delete/remove related structures of that field.
      if(originaluitype===6 || originaluitype===7)
      {
        const payload={tableid,fieldname:columnConfigData.data,removeRelatedStructureOnly:true}
        await cloudrun.post('/removeField', { payload })
      } 

      // Calculate default date field, if specified.
      let tempvalue = '';
      // Update default value
      if (defaultValue === '0') {
        tempvalue = 'NOW()';
      } else if (defaultValue !== '0' && defaultValue !== '') {
        tempvalue = `(NOW() ${isFutureDays ? '+' : '-'} '${defaultValue} day'::interval)`;
      }
       
        // 7-4-2020 Removed check on change. Since they can simply remove default
        // value, we need to update default setting on every save. 
        const payload ={field,tableid,defaultText:tempvalue,uitype:22};
        await cloudrun.post('/updateFieldDefaultText', { payload })
    }
      // Update the tableinfo metadata

      await updateTableInfoAPI(tableid,tableinfo,columnConfigData);
      return "success"
    } catch (error) {
    return `An error occurred: ${error.message}`
    }
  };

  render() {
    const {
      dateFormat,
      showAdvanced,
      includeTime,
      clockFormat,
      timeFormat,
      UTC,
      defaultValue,
      callout,
      isFutureDays,
      isPrivate
    } = this.state;
    const {color,uitype}=this.props
    return (
      <div>
        <div>{this.HeaderText()}</div>
        <div style={{height:'15px'}}/>
        <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} />     
        {showAdvanced ? (
          <Form>
             <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            
            <div className="subtext" style={{ marginBottom: '3px', marginTop: '10px',fontSize:'13px' }}>
              <strong>Date format</strong>
            </div>
            <Dropdown
              style={{ width: 'max-content', fontSize: '13px' }}
              placeholder="None"
              value={dateFormat}
              fluid
              onChange={(e, data) => this.setState({ dateFormat: data.value })}
              floating
              options={[
                { key: '1', value: 'DD-MMM-YYYY', text: 'DD-MMM-YYYY: 8-MAR-2020' },
                { key: '2', value: 'DD/MM/YYYY', text: 'DD/MM/YYYY: 8/3/2020' },
                { key: '3', value: 'MM/DD/YYYY', text: 'MM/DD/YYYY: 3/8/2020' },
                { key: '4', value: 'YYYY-MM-DD', text: 'YYYY-MM-DD: 2020-03-08' },
                { key: '5', value: 'dddd, MMMM D, YYYY', text: 'Sunday, March 8, 2020' },
              ]}
            />
            <div>
            <GBSwitch color={color} text="Include time" isChecked={includeTime} Action={this.IncludeTimeHandler} />   

              {includeTime ? (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    {/* using extra div to align radio buttions */}
                    <div style={{ width: '170px' }}>
                      <Radio
                        checked={clockFormat === '12'}
                        name="clock"
                        value="12"
                        onChange={(e, data) => this.setState({ clockFormat: data.value })}
                        label="12 hour clock (am/pm)"
                        style={{
                          zIndex: '1',
                          position: 'relative',
                          marginTop: '10px',
                          fontSize: '13px',
                        }}
                      />
                    </div>
                    <Radio
                      checked={clockFormat === '24'}
                      name="clock"
                      value="24"
                      onChange={(e, data) => this.setState({ clockFormat: data.value })}
                      label="24 hour clock"
                      style={{
                        zIndex: '1',
                        position: 'relative',
                        marginTop: '10px',
                        fontSize: '13px',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <div style={{ width: '170px' }}>
                      <Radio
                        checked={timeFormat === 'minutes'}
                        name="time"
                        value="minutes"
                        onChange={(e, data) => this.setState({ timeFormat: data.value })}
                        label="hh:mm"
                        style={{
                          zIndex: '1',
                          position: 'relative',
                          marginTop: '10px',
                          fontSize: '13px',
                        }}
                      />
                    </div>
                    <Radio
                      checked={timeFormat === 'seconds'}
                      name="time"
                      value="seconds"
                      onChange={(e, data) => this.setState({ timeFormat: data.value })}
                      label="hh:mm:ss"
                      style={{
                        zIndex: '1',
                        position: 'relative',
                        marginTop: '10px',
                        fontSize: '13px',
                      }}
                    />
                  </div>
                  {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <div style={{ width: '170px' }}>
                      <Radio
                        checked={!UTC}
                        name="utc"
                        onChange={() => this.setState({ UTC: false })}
                        label="Show local time zone"
                        style={{
                          zIndex: '1',
                          position: 'relative',
                          marginTop: '10px',
                          fontSize: '13px',
                        }}
                      />
                    </div>
                    <Radio
                      checked={UTC}
                      name="utc"
                      onChange={() => this.setState({ UTC: true })}
                      label="Show GMT time zone"
                      style={{
                        zIndex: '1',
                        position: 'relative',
                        marginTop: '10px',
                        fontSize: '13px',
                      }}
                    />
                  </div> */}
                </>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                {uitype===22 ? (<>
                <div className="subtext" style={{ marginBottom: '3px',fontSize:'13px' }}>
                  <strong> Default value: a specific number of days (use 0 for today)</strong>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Input
                    style={{ fontSize: '12', width: '74px', height: '30px', marginRight: '5px' }}
                    type="number"
                    onChange={(e, data) => this.defaultValueHandler(data.value)}
                    value={defaultValue}
                    size="small"
                  />
                  <Radio
                    checked={isFutureDays}
                    name="isFutureDays"
                    onChange={() => this.setState({ isFutureDays: true })}
                    label="days in future"
                    style={{
                      zIndex: '1',
                      position: 'relative',
                      fontSize: '13px',
                      marginRight: '5px',
                    }}
                  />
                  <Radio
                    checked={!isFutureDays}
                    name="isFutureDays"
                    onChange={() => this.setState({ isFutureDays: false })}
                    label="days in past"
                    style={{
                      zIndex: '1',
                      position: 'relative',
                      fontSize: '13px',
                    }}
                  />
                </div>
                </>): null}
                <Callout callout={callout} updateCallout={this.CalloutHandler} /> 
              </div>
            </div>
          </Form>
        ) : null}
      </div>
    );
  }
}

DateField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableinfo: PropTypes.object.isRequired,
};

export default DateField;
