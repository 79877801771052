/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import SignUp from './SignUp';
import { FORM_ERROR } from '../../../components/Form';
import { signUpAPI, signInGoogleAPI, signInMicrosoftAPI } from '../../../api/auth';
import useAlert from './useAlert';
import { ROUTES } from '../../../constants';


const withAPI = () => {
 
  return ({ navigate }) => {
    const [alertComponent, alert] = useAlert({
      title: 'Sign Up error',
    });

    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
      (values) => {
        setLoading(true);
        return signUpAPI(values)
          .then(() => {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          })
          .catch((error) => {
            setLoading(false);
            alert.show({ message: error.message });
            return { [FORM_ERROR]: error.message };
          });
      },
      [navigate, alert],
    );

    const handleGoogleSignIn = useCallback((code) => {
      setLoading(true);
      signInGoogleAPI(code)
        .then(({ isNewUser }) => {
          if (isNewUser) {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          } else {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
          }
        })
        .catch((error) => {
          setLoading(false);
          alert.show({ message: error.message });
          return { [FORM_ERROR]: error.message };
        });
    }, [alert, navigate]);


    const handleMicrosoftSignIn = useCallback((code) => {
      setLoading(true);
      signInMicrosoftAPI(code)
        .then(({ isNewUser }) => {
          if (isNewUser) {
            navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
          } else {
            navigate(ROUTES.REDIRECTION.DASHBOARD);
          }
        })
        .catch((error) => {
          setLoading(false);
          alert.show({ message: error.message });
          return { [FORM_ERROR]: error.message };
        });
    }, [alert, navigate]);

    return (
      <>
        {alertComponent}
        <SignUp onSubmit={handleSubmit} onGoogleSignIn={handleGoogleSignIn} onMicrosoftSignIn={handleMicrosoftSignIn} loading={loading}  />
      </>
    );
  };
};

export default withAPI;
