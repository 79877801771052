/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import Icon from '@mdi/react';
import { Helmet } from 'react-helmet';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
const moment = require('moment');
import { Input, Dropdown, Checkbox, Popup } from 'semantic-ui-react';
import {
  mdiFilter,
  mdiArrowDecisionOutline,
  mdiPencil,
  mdiContentDuplicate,
  mdiDeleteForever,
  mdiChevronDown,
  mdiHistory,
  mdiPencilRuler,
} from '@mdi/js';
import Global from '../../clients/global';
import { getBlocksAPI } from '../../api/blocks';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import GBInput from '../../components/GBInput/GBInput';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import {
  getWorkFlows,
  addWorkFlow,
  updateWorkFlowName,
  updateWorkFlowStatus,
  deleteWorkFlow,
  duplicateWorkFlow,
} from '../../api/workflows';
import Workflow from './Workflow';
import WorkflowHistory from './WorkflowHistory';
import { AccountContext } from '../../providers/AccountProvider';

const WorkFlowManager = ({ blockID }) => {
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showWorkFlow, setShowWorkFlow] = useState(false);
  const [showWorkflowHistory, setShowWorkflowHistory] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [showActive, setShowActive] = useState(false);
  const [name, setName] = useState('');
  const [workFlowEditId, setWorkFlowEditId] = useState(null);
  const [tempName, setTempName] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [actions, setActions] = useState([
    {
      key: 'filter',
      text: 'Find workflow',
      value: 'filter',
      icon: 'search',
      placeholder: 'Find workflows',
      clear: false,
      toolTip: 'Filter workflows by words found in the workflow name. (case sensitive)',
    },
  ]);
  const { userInfo } = useContext(AccountContext);
  const divRef = useRef();

  useEffect(() => {
    loadData();

    const faviconLink = document.querySelector("link[rel~='icon']");
    if (faviconLink) {
      faviconLink.href = '/Workflow_Favicon.svg'; // Replace with your SVG path
    } else {
      const newFaviconLink = document.createElement('link');
      newFaviconLink.rel = 'icon';
      newFaviconLink.href = '/Workflow_Favicon.svg';
      document.head.appendChild(newFaviconLink);
    }

    const zIdx = userInfo.zones.findIndex((z) => z.id === Global.zoneid);
    if (zIdx !== -1) {
      setZoneName(userInfo.zones[zIdx].name);
    }
  }, []);

  const options = [
    {
      key: 'user',
      text: (
        <div>
          <strong>Actions</strong>
        </div>
      ),
      disabled: true,
    },
    {
      key: 'history',
      value: 'history',
      text: 'View history',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiHistory} size="20px" />
      ),
    },
    {
      key: 'designer',
      value: 'designer',
      text: 'Edit',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiPencilRuler}
          size="20px"
        />
      ),
    },
    {
      key: 'rename',
      value: 'rename',
      text: 'Rename',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiPencil} size="20px" />
      ),
    },
    {
      key: 'duplicate',
      value: 'duplicate',
      text: 'Duplicate',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiContentDuplicate}
          size="20px"
        />
      ),
    },
    {
      key: 'delete',
      value: 'delete',
      text: 'Delete',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiDeleteForever}
          size="20px"
        />
      ),
    },
  ];

  const closeWorkFlow = async (isRefresh) => {
    setShowWorkFlow(false);
    setShowWorkflowHistory(false);
    setSelectedWorkFlow(null);
    if(isRefresh) {
      await loadData();
    }

  };

  const workFlowAction = async (workflowid, action) => {
    if (action === 'rename') {
      setWorkFlowEditId(workflowid);
      const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
      setTempName(workflows[idx].workflowname);
    } else if (action === 'delete') {
      deleteWorkflowConfirm(workflowid);
    } else if (action === 'designer') {
      const wf = workflows.filter((fl) => fl.workflowid === workflowid)[0];
      setSelectedWorkFlow(wf);
      setShowWorkFlow(true);
    } else if (action === 'duplicate') {
      await duplicateWorkflowLocal(workflowid);
    } else if (action === 'history') {
      const idx = workflows.findIndex((wf) => wf.workflowid === workflowid);
      if (idx !== -1) setSelectedWorkFlow(workflows[idx]);
      setShowWorkflowHistory(true);
    }
  };

  const duplicateWorkflowLocal = async (workflowid) => {
    await duplicateWorkFlow(workflowid);
    await loadData();
  };

  const renameWorkFlow = async (workflowid) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    if (idx !== -1) {
      const copyWorkflows = structuredClone(workflows);
      copyWorkflows[idx].workflowname = tempName;
      setWorkflows(copyWorkflows);

      await updateWorkFlowName(tempName, workflowid);
    }
    setTempName('');
    setWorkFlowEditId(null);
  };

  const addWorkFlowLocal = async () => {
    const wid = await addWorkFlow(name);
    const tempWorkFlow = { workflowid: wid, workflowname: name };
    setSelectedWorkFlow(tempWorkFlow);
    setName('');
    setShowWorkFlow(true);
  };

  const deleteWorkflowConfirm = (workflowid) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    let wfname = 'workflow';
    if (idx !== -1) {
      wfname = workflows[idx].workflowname;
    }

    const message = (
      <div>
        You have selected to delete the workflow: {wfname}. <br />
        <br />
        Are you sure you want to delete this workflow?
      </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Delete workflow forever'}
            action={deleteWorkflowLocal}
            width="350px"
            actionValue={workflowid}
            buttonLabel="OK"
            message={message}
            divRef={divRef}
            showInput={true}
            confirmAction="delete"
            confirmWord="delete"
            height="450px"
            onClose={onClose}
          />
        );
      },
    });
  };

  const deleteWorkflowLocal = async (workflowid) => {
    if (divRef.current.value === 'delete') {
      await deleteWorkFlow(workflowid);
      await loadData();
    }
  };

  const refresh = (wf) => {
    const copyWorkFlows = structuredClone(workflows);
    const idx = workflows.findIndex((el) => el.workflowid === wf.workflowid);
    if (idx !== -1) {
      copyWorkFlows[idx] = wf;
      setWorkflows(copyWorkFlows);
      setSelectedWorkFlow(copyWorkFlows[idx])
    } else {
      // new workflow, to refresh list.
      loadData();
    }
  };

  const updateWorkFlowStatusLocal = async (workflowid, isactive) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    if (idx !== -1) {
      const copyWorkflows = structuredClone(workflows);
      copyWorkflows[idx].isactive = isactive;
      setWorkflows(copyWorkflows);

      await updateWorkFlowStatus(workflowid, isactive);
    }
  };

  const loadData = async () => {
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));
    blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    const blockswithIcons = [{ key: 'all', value: '', text: 'ALL' }];
    blocks.forEach((itm) => {
      blockswithIcons.push({
        key: itm.key,
        value: itm.value,
        text: itm.text,
        icon: (
          <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
        ),
      });
    });

    // if (blockID !== undefined) {
    //   setSelectedBlock(parseInt(blockID));
    // }

    setBlocks(blockswithIcons);

    const wflows = await getWorkFlows();
    setWorkflows(wflows);
  };

  const sortWorkFlows = (col) => {
    const dir = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(dir);

    // workflows.sort((a, b) => (a[col] > b[col] ? 1 : -1));
    workflows.sort((a, b) => {
      if (dir === 'asc') {
        return a[col] > b[col] ? 1 : -1; // Ascending order
      } else {
        return a[col] < b[col] ? 1 : -1; // Descending order (assuming dir is 'desc')
      }
    });

    setWorkflows(workflows)
  };

  const handleBlockSelection = (val) => {
    setSelectedBlock(val);
  };

  const updateSearchTerm = (val) => {
    setSearchTerm(val);
  };

  const filterWorkFlows = (action, value) => {
    setSearchTerm(value);
  };

  const clearInput = () => {
    setSearchTerm('');
  };

  const selectWorkFlow = (val) => {
    setSelectedWorkFlow(val);
    setShowWorkFlow(true);
  };

  return (
    <div style={{ display: 'relative' }}>
      <Helmet>
        <title>{`GraceBlocks workflow manager`}</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          backgroundColor: '#0D99FF',
        }}
      >
        <div
          style={{
            marginLeft: '20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiArrowDecisionOutline} size="35px" color="white" />
          <div style={{ width: '10x', marginLeft: '10px' }} />
          <div style={{ fontSize: '20px' }}>GraceBlocks workflow manager</div>
        </div>
        <div style={{ color: 'white', marginRight: '20px' }}>GraceBlocks Zone: {zoneName}</div>
      </div>
      <div>
        <div
          style={{
            marginLeft: '40px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiFilter} size="30px" color="black" />
          <div style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '10px' }}>
            Filter workflows
          </div>
        </div>

        <div
          style={{
            margin: '10px',
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '300px', marginRight: '30px' }}>
            <Dropdown
              search
              selectOnBlur={false}
              clearable
              options={blocks}
              value={selectedBlock}
              onChange={(e, data) => handleBlockSelection(data.value)}
              fluid
              selection
              placeholder="Filter by blocks"
            />
          </div>
          <div style={{ width: '300px' }}>
            <GBInput
              actions={actions}
              currentValue={searchTerm}
              executeAction={filterWorkFlows}
              clearInput={clearInput}
              recordSet="blockList" //here set to constant value so it doesn't reset currentvalue. used in blocks to reset term when table and/or filter is changed
            />
          </div>
          <div style={{ marginLeft: '200px' }}>
            <Input
              onChange={(e, data) => setName(data.value)}
              value={name}
              labelPosition="right"
              onKeyDown={(e) => (e.keyCode == 13 ? addWorkFlowLocal() : null)}
              // action="Create new workflow"
              action={{
                // color: 'teal',
                className: 'custom-workflow-label',
                // labelPosition: 'left',
                // icon: 'plus',
                content: 'Create new workflow',
                // label:"test",
                onClick: addWorkFlowLocal,
              }}
            />
          </div>
        </div>

        <div
          style={{
            marginLeft: '50px',
            marginRight: '50px',
            fontSize: '20px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #E5E5E5',

          }}
        >
          <div style={{ width: '150px' }}></div>
          <div onClick={() => sortWorkFlows('workflowid')}
            css={{
              width: '55px',
              cursor: 'pointer',
              ':hover': {
                // Add the hover effect here
                color: '#00000080',
              },
            }}>ID</div>
          <div onClick={() => sortWorkFlows('workflowname')}
            css={{
              width: '400px',
              cursor: 'pointer',
              ':hover': {
                // Add the hover effect here
                color: '#00000080',
              },
            }}>Name</div>
          <div style={{ width: '200px' }}>
            <Checkbox
              fitted
              // style={{ fontSize: '20px' }}
              checked={showActive}
              label="Active"
              onChange={(e, data) => setShowActive(data.checked)}
              className="my-checkbox"
            />
          </div>
          <div style={{ width: '200px' }}>Actions</div>
          <div
            onClick={() => sortWorkFlows('updatedon')}
            css={{
              width: '350px',
              cursor: 'pointer',
              ':hover': {
                // Add the hover effect here
                color: '#00000080',
              },
            }}
          >
            Last edit
          </div>
          <div
            onClick={() => sortWorkFlows('updatedByName')}
            css={{
              width: '300px',
              cursor: 'pointer',
              ':hover': {
                // Add the hover effect here
                color: '#00000080',
              },
            }}
          >
            Last edited by
          </div>
        </div>
          <div style={{overflow:'auto',height:'calc(100vh - 280px)'}}>
        {workflows
          .filter(
            (wf) =>
              wf.workflowname.includes(searchTerm) &&
              (selectedBlock === '' || wf.blockid === selectedBlock) &&
              (showActive ? wf.isactive : true),
          )
          .map((w) => (
            <div
              style={{
                // border:'1px solid red',
                marginLeft: '50px',
                marginRight: '50px',
                fontSize: '20px',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {/* <div style={{ width: '150px' }}>Show icons</div> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '150px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: w.color,
                      height: '45px',
                      width: '45px',
                      borderRadius: '5px',
                      fontSize: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Popup
                      content={`Triggers from ${w.blockname}: ${w.tabname}`}
                      trigger={<Icon path={w.icon} size="20px" color="white" />}
                    />
                  </div>
                  <div style={{ width: '10px' }}></div>
                  <Popup
                    content={`${w.actioncount} Action${w.actioncount > 1 ? 's' : ''}`}
                    trigger={
                      <div
                        style={{
                          backgroundColor: '#D9D9D9',
                          height: '45px',
                          width: '45px',
                          borderRadius: '5px',
                          fontSize: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {w.actioncount}
                      </div>
                    }
                  />
                </div>

                <div style={{ width: '55px' }}>{w.workflowid}</div>
                <div style={{ width: '400px' }}>
                  {w.workflowid === workFlowEditId ? (
                    <Input
                      fluid
                      onChange={(e, data) => setTempName(data.value)}
                      defaultValue={w.workflowname}
                      // labelPosition="right"
                      action={{
                        color: 'teal',
                        className: 'custom-workflow-label',
                        // labelPosition: 'right',
                        content: 'Rename',
                        onClick: () => renameWorkFlow(w.workflowid),
                      }}
                    />
                  ) : (
                    <TextButton
                      fontSize="20px"
                      textColor="#2BA6FF"
                      hoverColor="#2BA6FF80"
                      text={w.workflowname}
                      Action={selectWorkFlow}
                      ActionValue={w}
                    />
                  )}
                </div>

                <div style={{ width: '200px' }}>
                  <div style={{ marginLeft: '20px' }}>
                    <GBSwitch
                      color={'#0D99FF'}
                      isChecked={w.isactive}
                      Action={() => updateWorkFlowStatusLocal(w.workflowid, !w.isactive)}
                    />
                  </div>
                </div>
                <div style={{ width: '200px' }}>
                  <Dropdown
                    selectOnBlur={false}
                    text=""
                    icon={null}
                    trigger={
                      <GBButton
                        text="Select an action"
                        icon={mdiChevronDown}
                        iconSize="20px"
                        iconColor="white"
                        iconHoverColor="white"
                        iconPosition="right"
                        borderRadius="10px"
                        Action={null}
                        textColor="white"
                        width="150px"
                        height={'30px'}
                        color="#0D99FF"
                        textHoverColor="white"
                        hoverBackgroundColor={'#0D99FF'}
                      />
                    }
                    direction="left"
                  >
                    <Dropdown.Menu>
                      {options.map((el) => (
                        <Dropdown.Item
                          key={el.key}
                          text={el.text}
                          icon={el.icon}
                          onClick={() => workFlowAction(w.workflowid, el.value)}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{ width: '350px' }}>
                  {moment(w.updatedon)
                    .format('DD-MMM-YYYY hh:mm:ss A')
                    .toUpperCase()}
                </div>
                <div style={{ width: '300px' }}>{w.updatedByName}</div>
              </div>
            </div>
          ))}
         </div>
        <div style={{ height: '50px' }}></div>
      </div>
      {showWorkFlow ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            zIndex: 1,
            left: 0,
            right:0,
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Workflow workFlowObj={selectedWorkFlow} close={closeWorkFlow} refresh={refresh} />
        </div>
      ) : null}

      {showWorkflowHistory ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            zIndex: 1,
            left: 0,
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <WorkflowHistory workFlowObj={selectedWorkFlow} close={closeWorkFlow} />
        </div>
      ) : null}
    </div>
  );
};

export default WorkFlowManager;
