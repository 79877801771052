/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import FinalForm, { Form, FormField, FormCard, validators } from '../../../components/Form';
import TextButton from '../../../components/TextButton/TextButton';
import GBButton from '../../../components/GBButton/GBButton';
import GBSubmit from '../../../components/GBSubmit/GBSubmit';
import firebase, { phoneProvider } from '../../../clients/firebase';
import {disableMultiFactor} from '../../../api/users'

const Multifactor = ({ resolver, color, close, onSuccess }) => {
  const formValidators = {
    phoneNumber: validators.composeValidators(validators.required, validators.phoneNumber),
    password: validators.composeValidators(validators.required),
  };

  const [verificationId, setVerificationId] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [smsCodeError, setSmsCodeError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-validator', {
      size: 'invisible',
    });
    setIsLoading(true);
    sendCodeRequest();
  
  }, []);

  const sendCodeRequest = async () =>{
    var phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      };
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const id = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
    
    setVerificationId(id);
    setIsLoading(false);
   
  }


  const verifySMSCode = async (values) => {
    setSmsCodeError('');
    const user = firebase.auth().currentUser;
    try {
      const cred = await firebase.auth.PhoneAuthProvider.credential(verificationId, values.code);

      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

      // 12-6-22 if the user has more than one login provider(not including phone provider),
      // this means they have both a username/password provider and a Google/Microsoft provider.
      // In that case, we need to unlink the password provider so they are forced to use the google/microsoft login.
      // and we disable multi-factor for this account.
      const nonPhoneProviders = userCredential.user.providerData.filter(el=>el.providerId !=='phone').length;
     
        if(nonPhoneProviders>1) {
            //remove password sign-in, user must user microsoft/google to sign in going forward.
            await userCredential.user.unlink('password');
            await disableMultiFactor();
        }
      
      onSuccess();
       // userCredential will also contain the user, additionalUserInfo, optional
            // credential (null for email/password) associated with the first factor sign-in.
            // For example, if the user signed in with Google as a first factor,
            // userCredential.additionalUserInfo will contain data related to Google provider that
            // the user signed in with.
            // user.credential contains the Google OAuth credential.
            // user.credential.accessToken contains the Google OAuth access token.
            // user.credential.idToken contains the Google OAuth ID token.
    } catch (error) {
      setSmsCode('');
      if (error.message.includes('invalid')) {
        setSmsCodeError('Incorrect or expired code, please try again.');
      } else {
        setSmsCodeError(error.message);
      }
    }
  };

  return (
    <div>
      <div id="phone-validator" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: color,
          textAlign: 'center',
          paddingLeft: '20px',
          color: 'white',
          verticalAlign: 'center',
          height: '40px',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
        }}
      >
        Multi-factor authentication
      </div>

      {!isLoading && smsCode === '' && submitError === '' ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '10px', display: 'inline' }}>
            Validation code sent, please enter it below. The validation code is only authorized for a limited time.
When this time expires, you will be required to request a new validation code.
            <FinalForm onSubmit={verifySMSCode}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div style={{ margin: '20px' }}>
                      <FormField
                        autoFocus
                        name="code"
                        color={color}
                        label="Validation Code"
                        placeholder="Enter the validation code."
                        validate={formValidators.code}
                        controlId="id_code"
                      />
                      {smsCodeError !== '' ? (
                        <div style={{ color: 'red' }}>{smsCodeError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <TextButton
                          text="Cancel"
                          hoverColor="#75757580"
                          Action={() => close()}
                          ActinValue={false}
                          textColor="black"
                        />
                      </div>
                      <GBSubmit color={color} text="Submit" width="100px" />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Multifactor;
