/* eslint-disable */
import React,{useEffect} from 'react';

const Avatar = ({item,width="24px",height="24px",fontSize="10px"}) => {

    let initial1=''
    let initial2=''
    if(item.userid !== null)
    {
      initial1=item.firstname.substring(0,1)
      initial2=item.lastname.substring(0,1)
    } 
    // useEffect(() => {
    //     console.log(item)
    // })


    return (
        <>
        {item.attributes.avatar !==undefined  && item.attributes.avatar !==null? (
            <div style={{minWidth:width}}><img style={{ borderRadius: '100%',marginRight: '3px'}} src={item.attributes.avatar} height={height} width={width}  /></div>
        ) : (
      <div
        style={{height, width, minWidth:width, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:item.attributes.color, color:item.attributes.text, fontSize, marginRight: '3px', borderRadius: '100%' }}
      >
        {initial1}
        {initial2}
      </div>
      )}
      </>
    );
  };

  export default Avatar