/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { mdiContentCopy,mdiInformationOutline } from '@mdi/js';
import { AccountContext } from '../../../../providers/AccountProvider';
import { updateTableInfoAPI } from '../../../../api/tables';
import { Input, Dropdown,Radio,Form } from 'semantic-ui-react';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import IconButton from '../FilterData/IconButton';
import Callout from './Callout';
import TextButton from '../../../../components/TextButton/TextButton';
import cloudrun from '../../../../clients/httpcloudrun';
import {MapOptionValuesToText,getTableRelationalIdentifiers} from '../../../../api/GetData';
import {getTablesWithEmailFields} from '../../../../api/tables'
import Global from '../../../../clients/global';


class EmailField extends Component {
  // This is used to configure both Email and URL fields.
  constructor(props) {
    super(props);
    const { columnConfigData} = this.props;

    this.state = {
      showAdvanced: false,
      callout: columnConfigData.callout,
      defaultText: columnConfigData.defaultText,
      isPrivate:columnConfigData.isPrivate ?? false,
      enableEmailMessages: columnConfigData.enableEmailMessages ?? false,
      fromAddresses:[{key:'1',value:'clientemaildelivery@graceblocks.com',text:'clientemaildelivery@graceblocks.com'}],
      fromAddress: columnConfigData.fromAddress ?? 'clientemaildelivery@graceblocks.com',
      blocks: [],
      tableData:[],
      tablesWithEmail:[],
      selectedBlock: columnConfigData.selectedBlock ?? null,
      selectedTable: columnConfigData.selectedTable ?? null,
      selectedTemplate: columnConfigData.selectedTemplate ?? null,
      replyTo: '',
      alternateEmailFields: [],
      alternateEmailField: columnConfigData.alternateEmailField ?? null,
      useButtonDisplay: columnConfigData.useButtonDisplay ?? false,
      buttonLabel: columnConfigData.buttonLabel ?? '',
      enableDeliveryOption: true,  //by default it's true, but we only allow one email field to have thie enabled. Once enabled, other email fields will turn this option off.
      replyConfiguration: columnConfigData.replyConfiguration ?? 'general',
      templateRows: []
    };
  }
  static contextType = AccountContext;

  toggleEmailMessages= async () =>{
    const {enableEmailMessages} = this.state;
    const {userInfo} = this.context;

    await this.loadEmailTables()

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         You must upgrade to the pro plan to enable this feature. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({enableEmailMessages: !enableEmailMessages})
    }
  }

  async componentDidMount() {
    const {userInfo} = this.context;
    const {columnConfigData,tableinfo} = this.props;
   
    const {tableid} = this.props;
    const {fromAddresses} =this.state;
    await this.loadEmailTables();

    if(columnConfigData.selectedBlock !==undefined) {
      this.handleBlockSelection(columnConfigData.selectedBlock);
    }

    let availableTemplates =[];
    if(columnConfigData.selectedTable !==undefined) {
      const result = await getTableRelationalIdentifiers(columnConfigData.selectedTable);
      result.length>0 && result.map(r =>{
        availableTemplates.push({key:r.id.toString(),value:r.id,text:r.fullname})
      })
    }

    const { REACT_APP_SITE_URL} = process.env;
    let domain='@parse.graceblocks.com'
    if(REACT_APP_SITE_URL==='https://dev.graceblocks.com') {
      domain='@parsedev.graceblocks.com'
    } else if( REACT_APP_SITE_URL==='http://localhost:3000') {
      domain='@parsetest.graceblocks.com'
    }

    const replyToString=`${Global.zoneid}||${tableid}`;
    const buff = Buffer.from(replyToString, 'utf8');
    const replyToInfo = buff.toString('base64') +domain ;
    

    const currentZone = userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0];
    if(currentZone.attributes.fromAddresses) {
      currentZone.attributes.fromAddresses.map(el=>{
        if(el.isActive) {
          fromAddresses.push({key:el,value:el.email,text:el.email})
        }
      })
    }

    //7-12-2003. Get all other email fields in the tab and provide dropdown allowing them
    //to select a secondary email address field. If primary is null, we will use secondary if it exists.

    const AltEmailFieldsTemp=[];
    const altEmails= tableinfo.columns.filter(itm=>itm.data !==columnConfigData.data && (itm.uitype===10 || itm.source?.lookupuitype===10))
      altEmails.map(itm=>{
        AltEmailFieldsTemp.push({key:itm.data,value:itm.data,text:itm.header})
      })

    //Check to see if any other email fields with enableEmailDelivery is on. If yes, turn it off here.
    const enableDelivery = tableinfo.columns.findIndex(itm=>itm.uitype===10 && itm.enableEmailMessages && itm.data !==columnConfigData.data) ==-1 ;

    this.setState({replyTo:replyToInfo,fromAddresses, alternateEmailFields:AltEmailFieldsTemp,enableDeliveryOption:enableDelivery,templateRows:availableTemplates});

  }

  loadEmailTables = async () =>{
    const data = await getTablesWithEmailFields();

    const blockOptions=[];
    
    data.map(el=>{
      const idx = blockOptions.findIndex(itm=>itm.value===el.blockid);
      if(idx===-1) {
        blockOptions.push({key:el.blockid, value:el.blockid,text:el.blockname})
      }
    })


    this.setState({blocks:blockOptions,tableData:data})
  }

  handleBlockSelection = (blockid) => {
    const {tableData} = this.state;
    const tdata = tableData.filter(el=>el.blockid===blockid);
    const tables =[];
    tdata.map(el=>{
      tables.push({key:el.id,value:el.id,text:el.name})
    })
    this.setState({tablesWithEmail: tables,selectedBlock:blockid})
  }

  handleTableSelection = async (tableid) => {

  }

  Save = async () => {
    const { callout,defaultText,isPrivate,selectedBlock,selectedTable,selectedTemplate, enableEmailMessages,fromAddress,replyTo,alternateEmailField,useButtonDisplay,buttonLabel,replyConfiguration } = this.state;
    const { field, tableid, header, selectedUIType,tableinfo,columnConfigData  } = this.props;
    const originalUIType = columnConfigData.uitype;


    if (defaultText !== undefined) {
      const payload = { field, tableid, defaultText, uitype: selectedUIType };
      await cloudrun.post('/updateFieldDefaultText', { payload });
    }

    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "displayfield": "${field}",
      "detailviewsort": ${columnConfigData.detailviewsort ?? 1}
  }`

  const updatedNode = JSON.parse(defaultNode)

    try {
    // Update Tableinfo with new settings.
    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
    if (rowindex !== -1) {
      updatedNode.callout=callout;
      updatedNode.uitype=selectedUIType;
      updatedNode.defaultText = defaultText;
      updatedNode.header=header;
      updatedNode.renderer='TextRender';
      updatedNode.enableEmailMessages = enableEmailMessages;
      updatedNode.fromAddress = fromAddress;
      updatedNode.replyTo = replyTo;
      updatedNode.replyConfiguration = replyConfiguration;

      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }
      
      if(alternateEmailField !==null && alternateEmailField !=='') {
        updatedNode.alternateEmailField = alternateEmailField;
      } 
      
      if(selectedTemplate !==null && selectedTemplate !=='') {
        updatedNode.selectedTemplate = selectedTemplate;
      }

      if(selectedBlock !==null && enableEmailMessages) {
        updatedNode.selectedBlock=selectedBlock
      }
      if(selectedTable !==null && enableEmailMessages) {
        updatedNode.selectedTable=selectedTable;
      }

      if(!enableEmailMessages) {
        delete updatedNode.selectedBlock;
        delete updatedNode.selectedTable;
        delete updatedNode.fromAddress;
      }

      if(useButtonDisplay) {
        updatedNode.useButtonDisplay=true;
        updatedNode.buttonLabel=buttonLabel;
      } else {
        delete updatedNode.useButtonDisplay;
        delete updatedNode.buttonLabel;
      }
     

      if(selectedUIType===10)
      {
        updatedNode.validator ="[^\\s@]+@[^\\s@]+\\.[^\\s@]+" //updated regex on 8-13-2020 to allow international character and in general be more lax.
      } else
      {
        updatedNode.validator="(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"
      }

      if (defaultText !== undefined) {
        updatedNode.defaultText = defaultText;
        // call server code to update default text.
       //missing call..
      }
      tableinfo.columns[rowindex]=updatedNode;

      if(originalUIType===23)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_numeric_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } else if (originalUIType===22)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } 
      // if we're coming from select field, we need to map optios to text values
      // and then remove associated/related tables. This is doen via removefield API passing removeStructureOnly=true
      else if (originalUIType===6 || originalUIType===7){
        const table=`tbl_${tableid}`;
        await MapOptionValuesToText(table,columnConfigData.data,255)

        const payload={tableid,fieldname:columnConfigData.data,removeRelatedStructureOnly:true}
        await cloudrun.post('/removeField', { payload })
      } else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } else if (originalUIType===9) {
        const payload = { tableid, tableinfo, field };
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      }

      await updateTableInfoAPI(tableid, tableinfo, columnConfigData);
    }
    return "success"
  } catch (error) {
    console.log(error)
    return `An error occurred: ${error.message}`
  }
  };

  showAdvancedHandler = () => {
    const { showAdvanced,bcc } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  copyLink =() => {
    const {replyTo} = this.state;
    var input = document.createElement('input');
    input.setAttribute('value', replyTo);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    
    toast.info(
      <div style={{ margin: '5px' }}>
        Bcc email address was successfully copied!
      </div>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
        // closeButton: false,
      },
    );
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  toggleUseButtonDisplay =() =>{
    const {useButtonDisplay} = this.state;
    this.setState({useButtonDisplay: !useButtonDisplay})
  }

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  updateReplyConfiguration =(val) => {
    this.setState({replyConfiguration:val})
  }

  Description = () => {
    const { selectedUIType } = this.props;
    if(selectedUIType===10){
    return(
    <div style={{marginTop: '5px' }}>
         Users will be able to enter a valid email address into this field. The value will become a clickable value that will launch to support sending emails easily.If you choose to
         enable email messages, this option will be added to the messaging tab and view. Email templates are supported. <a href="https://www.graceblocks.com/support-article/sending-email-using-graceblocks" target="_newwindow">Learn more about emailing and templates here.</a>
         <div style={{display:'flex', marginTop:'10px', flexDirection:'row', width:'200px', alignItems:'center',justifyContent:'space-between'}}>
         <div>
             <div style={{display:'flex', flexDirection:'column', width:'170px', alignItems:'flex-start'}}>
                <div style={{color:'green'}}>Valid example</div>
                <div>support@graceblocks.com</div>
             </div>
          </div>
          <div>
             <div style={{display:'flex', flexDirection:'column', width:'170px'}}>
                <div style={{color:'red'}}>Invalid example</div>
                <div>graceclocks</div>
             </div>
          </div>
        </div>
        </div>
    )
    } else {
      return(
        <div style={{ marginTop: '5px' }}>
             Users will be able to enter a valid URL into this field. The value entered will be a clickable hyperlink.
             <div style={{display:'flex', marginTop:'10px', flexDirection:'row', width:'200px', alignItems:'flex-start',justifyContent:'space-between'}}>
             <div>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start',width:'170px'}}>
                    <div style={{color:'green'}}>Valid example</div>
                    <div>graceblocks.com</div>
                    <div>www.graceblocks.com</div>
                    <div>http://graceblocks.com</div>
                 </div>
              </div>
              <div>
                 <div style={{display:'flex', flexDirection:'column',alignItems:'flex-start',width:'170px'}}>
                    <div style={{color:'red'}}>Invalid example</div>
                    <div>graceclocks</div>
                 </div>
              </div>
            </div>
            </div>
        )
    }
    
  }

  render() {
    const { showAdvanced, callout,defaultText,isPrivate,enableEmailMessages,replyTo,alternateEmailFields,enableDeliveryOption,useButtonDisplay,buttonLabel,replyConfiguration } = this.state;
    const { color,selectedUIType,columnConfigData } = this.props;
    return (
      <div>
        {this.Description()}
        <div style={{height:'15px'}}></div>
       <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} />

        {showAdvanced ? (
            <>
             <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
             <div>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            {selectedUIType===10 && enableDeliveryOption ? (
            <div style={{marginTop:'5px',marginBottom:'5px', display:'flex',flexDirection:'row', alignItems:'center'}}>
            <div style={{width:'180px'}}>
            <GBSwitch
              color={color}
              text="Enable email messages"
              isChecked={enableEmailMessages}
              Action={this.toggleEmailMessages}
            />
            </div>
            <div style={{width:'10px'}} />
             <IconButton size='20px' icon={mdiInformationOutline} tooltip="Rate is $.001 per message sent/received. Learn more here." Action={()=>window.open('https://www.graceblocks.com/messaging-pricing')} color={color} />
            </div>
            ) :null}
            {enableEmailMessages ? (<>
             <div style={{marginTop:'5px',marginBottom:'5px'}}>
              From address: <Dropdown selectOnBlur={false} fluid value={this.state.fromAddress} selection options={this.state.fromAddresses} onChange={(e,data)=>this.setState({fromAddress:data.value})} />
            </div>
            <div style={{marginTop:'5px',marginBottom:'10px'}}>Reply email routing Configuration</div>
            <Form>
                  <Form.Field style={{ marginBottom: '5px' }}>
                    <Radio
                      label="Generate record specific reply emails"
                      name="radioGroup1"
                      value="specific"
                      checked={replyConfiguration === 'specific'}
                      onChange={(e, data) => this.updateReplyConfiguration(data.value)}
                    />
                  </Form.Field>
                  <Form.Field style={{ marginBottom: '10px' }}>
                    <Radio
                      label="Use a generic reply email"
                      name="radioGroup1"
                      value="general"
                      checked={replyConfiguration === 'general'}
                      onChange={(e, data) => this.updateReplyConfiguration(data.value)}
                    />
                    </Form.Field>
                    </Form>

            {replyConfiguration==='general' ? (
                <div>
                Reply to email address: <TextButton text={replyTo} textColor='#0D47A1' hoverColor='#0D47A180' Action={this.copyLink} icon={mdiContentCopy} color={color} />
               </div>
            ): null}
          

            <div style={{marginBottom:'5px',marginTop:'5px'}}>
              Specify message template:
            </div>
            <div style={{marginBottom:'10px'}}>
               Block: <Dropdown selectOnBlur={false} fluid value={this.state.selectedBlock} selection options={this.state.blocks} onChange={(e,data)=>this.handleBlockSelection(data.value)} />
            </div>
            <div style={{marginBottom:'10px'}}>
               Tab: <Dropdown selectOnBlur={false} fluid value={this.state.selectedTable} selection options={this.state.tablesWithEmail} onChange={(e,data)=>this.setState({selectedTable:data.value})} />
            </div>
            <div style={{marginBottom:'10px'}}>
               Default template: <Dropdown selectOnBlur={false} fluid clearable value={this.state.selectedTemplate} selection options={this.state.templateRows} onChange={(e,data)=>this.setState({selectedTemplate:data.value})} />
            </div>

             {alternateEmailFields.length>0 ? (
              <div style={{marginBottom:'5px'}}>
               Alternate Email: <Dropdown selectOnBlur={false} clearable fluid value={this.state.alternateEmailField} selection options={this.state.alternateEmailFields} onChange={(e,data)=>this.setState({alternateEmailField:data.value})} />
            </div>
             ): null}
            

            </>):null}

             {selectedUIType===11 ? (
                 <div style={{marginTop:'5px',marginBottom:'5px'}}>
                 <GBSwitch
                   color={color}
                   text="Convert URL to button"
                   isChecked={useButtonDisplay}
                   Action={this.toggleUseButtonDisplay}
                 />
                 </div>
             ):null}
             {useButtonDisplay ? (<>
                <div style={{ marginBottom: '3px',marginTop:'5px' }}>
                <strong>Button label</strong>
              </div>
               <Input
               size="small"
               onChange={(e, data) => this.setState({ buttonLabel: data.value })}
               value={buttonLabel}
               placeholder="Enter label for button"
             />
             </>):null}

             <div style={{ marginBottom: '3px',marginTop:'5px' }}>
               <strong>Default text</strong>
             </div>
             <Input
               size="small"
               onChange={(e, data) => this.setState({ defaultText: data.value })}
               value={defaultText || ''}
               placeholder="Enter default text (optional)"
             />
           </div>
           <Callout callout={callout} updateCallout={this.CalloutHandler} />       
           </>
        ) : null}
      </div>
    );
  }
}

EmailField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default EmailField
