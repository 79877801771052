/* eslint-disable */
import "firebase/auth"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import "firebase/compat/storage";
import 'firebase/compat/messaging';

// import * as firebase from 'firebase/app';
// import 'firebase/auth';
// import "firebase/storage";
// import 'firebase/messaging';
import Cookies from 'js-cookie';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

// const provider = new firebase.auth.GoogleAuthProvider();
var provider = new firebase.auth.OAuthProvider('google.com');
provider.addScope('profile');
provider.addScope('email');
provider.setCustomParameters({
  prompt: 'select_account'
});

const msprovider = new firebase.auth.OAuthProvider('microsoft.com');
msprovider.setCustomParameters({
  prompt: "consent",
  // login_hint:"Select account you'd like to use",
})


const phoneProvider = new firebase.auth.PhoneAuthProvider();

firebase.auth().useDeviceLanguage();

const getIdToken = () => firebase.auth().currentUser.getIdToken();

const getIdTokenResult = () =>
  firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then((response) => {
      const { graphserver, schema } = response.claims;
      const zoneId = schema.split('_')[1];
      Cookies.set('graphserver', graphserver);
      return { ...response, zoneId: parseInt(zoneId, 10), graphserver };
    });

export default firebase;

export { provider, msprovider, phoneProvider, getIdToken, getIdTokenResult };
