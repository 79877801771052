/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';

import { mdiCloseCircle, mdiArrowLeft } from '@mdi/js';

const DeleteImageIcon = ({deleteImage,type}) => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: '#E06055',
        borderRadius: '100%',
        color: 'white',
        '&:hover': {
          svg: {
            backgroundColor: 'white',
            color: '#E06055',
            borderRadius: '100%',
            transition: '.5s ease all',
          },
        },
      }}
    >
      <Icon onClick={() => deleteImage(type)} path={mdiCloseCircle} size="20px" />
    </div>
  );
};

export default DeleteImageIcon;
