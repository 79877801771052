/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';
import { css, jsx } from '@emotion/core'

const SendButton = ({ color,Action,ActionValue,text,width,icon,iconPosition}) => {
  return (
    <div
     onClick={Action !== null ? () => Action(ActionValue) : null}
      css={{
        position: 'relative',
        cursor:'pointer',
        display: 'flex',
        // border: '1px solid blue',
        '&:hover': {
          '& .delay': {
            transition: 'all .3s ease',
            color: `${color}80`,
          },
          '& .btnSend' : {
            transition: 'all .3s ease',
              backgroundColor: `${color}80`,
          }
        },
      }}
    >

      <div
        className="delay"
        css={{
            // position: 'absolute',
            zIndex:200,
          display: 'flex',
          paddingLeft: '10px',
          paddingRight: '10px',
          zIndex: '2px',
          // width: messageSetting.interval==='none' ? '130px' : '170px',
          width:width,
          alignItems: 'center',
          justifyContent:iconPosition==='right' ? 'flex-start' : 'flex-end',
          right: '10px',
          bottom: '3px',
          color,
          height: '35px',
          borderRadius: '20px',
          backgroundColor: `${color}1A`,
        }}
      >
        {/* {messageSetting.interval ==='none' ? 'Send now' : `Send in ${messageSetting.number} ${messageSetting.interval}`} */}
        {text}
      </div>

      <div
      className="btnSend"
        css={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: color,
          borderRadius: '20px',
          right: iconPosition==='right' ? '0px' : '100px',
          zIndex: 3,
          //   bottom: '3px',
          alignItems: 'center',
          alignSelf: 'center',
          height: '35px',
          width: '35px',
          color: 'white',
        }}
      >
        <Icon path={icon} size="25px" />
      </div>
    </div>
  );
};

export default SendButton;
