/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { verifyShare } from '../../../../api/shares';
import { useNavigate } from '@reach/router';
import firebase from '../../../../clients/firebase';

const ShareLogin = ({ token }) => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    verify();
  }, []);

  const verify = async () => {
    
    const result = await verifyShare(token);

    firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult(true);
         
          if(parseInt(idTokenResult.claims.zoneid) ===result.shareZoneId) {
            navigate(`/share/${token}`);
          } else {
            // setMessage('This share is from a different zone than you are logged into. In order to view this share, log out of GraceBlocks and return to this page.')
            await firebase.auth().signOut();
          }
          
        } else {
            if (result.error) {
                 setMessage(result.message);
            } else {
                await firebase.auth().signInWithCustomToken(result.usertoken);
                setMessage('success!');
                navigate(`/share/${token}`);
            }
        }
      });

  };

  return <div>{message === '' ? <div>Setting up the share....</div> : <div>{message}</div>}</div>;
};

export default ShareLogin;
