/* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import Icon from '@mdi/react';
import { mdiTrashCan,mdiClose,mdiPlus } from '@mdi/js';
import TextInput from '../../../../../components/TextInput/TextInput';
import RangeSlider from './RangeSlider';
import GBPhone from '../../../../../components/GBPhone/GBPhone';
import GBButton from '../../../../../components/GBButton/GBButton';
import TextButton from '../../../../../components/TextButton/TextButton';
import DesktopFiles from '../../Attachments/DesktopFiles';
import DatePicker from 'react-datepicker';
import ButtonDisplay from './ButtonDisplay.jsx';
import ListDisplay from './ListDisplay.jsx';
import NestedDropdowns from '../../DataList/nestedDropdowns';
import FormAttachment from './FormAttachment';
import { getOptionsAPI, getRelationRecords } from '../../../../../api/options';
import {getRelationalTableData} from '../../../../../api/tables'
import { getFormFieldOptions,getFormByToken} from '../../../../../api/forms';
import { getUsersAPI,getBlockUsersAPI } from '../../../../../api/users';
import CustomEditor from '../../../../../components/CustomEditor/CustomEditor';
import FormView from '../FormView';


const RenderField = ({
  id,
  tableid,
  blockid,
  fieldData,
  updateFieldValue,
  color,
  isEditMode,
  fontFamily,
  fontSize,
  labelsInCaps,
  formInfo,
  value,
  error,
  uploadDataFiles,
  deleteFile,
  role,
  addedby,
  updatedNestedLevels,
  index,
  htmlMode=false,
  addSubFormData,
  removeSubFormData,
  completedSubForms,
  textColor="#1A1A1A"
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [filteredOptions,setFilteredOptions] =useState([]);
  const [filter, setFilter] = useState('');
  const [useDropDown,setUseDropDown]=useState(false);
  const [currentCategory,setCurrentCategory] = useState();
  const [isNestedField,setIsNestedField] =useState(false);
  const [rtfLoaded, setRTFLoaded]=useState(false);
  const [initEditorValue, setInitEditorValue] =useState(null)
  const [showSubForm, setShowSubForm] =useState(false);
  const [currentSubFormInfo, setCurrentSubFormInfo] =useState(null);
  const [currentSubForm, setCurrentSubform]=useState(null);
  const [currentValues, setCurrentValues] =useState(null);
  const [currentGuid, setCurrentGuid] =useState(null);


  //#1A1A1A

  //11-11-23 this hf is used to pass an empty relational field hiddenfield value to the subform
  //so that if the subform references this relational field, it will not be shown as it should
  //be automatically linked to the parent forms relational field. 
  //we pass null, because it needs a value , but this is ignored in processing.

  const hf ={}
  hf[fieldData.fieldInfo.source?.returnfield] = null;
  


  // 11-13-23 added this function to support auto-filling in date/time values
  // into mobile date field, which is different than datePicker.
const convertISOToCustomFormat = (isoString,includeTime) => {

  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
  const day = date.getDate().toString().padStart(2, '0');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  if(includeTime) {
  return `${year}-${month}-${day}T${hours}:${minutes}`; 
  } else {
    return `${year}-${month}-${day}`
  }
}

const convertMobileDateTime = (field, value) => {
  const utcDate = new Date(value);
  updateFieldValue(field, utcDate.toISOString());
};



//11-13-23 added this method for filling in selectedvalue on mobile dropdown (select control).
//this is different method than used fro <dropdown> control. 
const isSelected = (optionValue) => {
    if(value !==undefined && fieldData.fieldInfo.lookup==='single') {
      return value.id[0] ===optionValue
    } else if (value !==undefined && fieldData.fieldInfo.lookup==='multiple') {
      return value.id.findIndex(el=>el===optionValue) !==-1
    } else {
      return false
    }
}

  const handleDateChange =(field,newDate,includeTime) =>{
    
    if(newDate.getHours()===0 && newDate.getMinutes()===0 && includeTime) {
      const d = new Date();
      newDate.setHours(d.getHours())
      newDate.setMinutes(d.getMinutes())
    } else if(!includeTime){
      newDate.setHours(0)
      newDate.setMinutes(0)
    }
    
    setStartDate(newDate)
    updateFieldValue(field,newDate)
  }

  const loadSubForm = async (formid) =>{
     const formElements = formInfo.split('|');
    const subFormInfo = `${formElements[0]}|${formElements[1]}|${formid}`;

    const subForm = await getFormByToken(subFormInfo,addedby);

    if(subForm.form !==undefined) {
      subForm.form.settings.accentColors.attributes.color=color
      subForm.form.settings.font =fontFamily;
      subForm.form.settings.fontSize=fontSize;
      setCurrentSubFormInfo(subFormInfo);
      setCurrentSubform(subForm.form);
      setShowSubForm(true);
    }
  }

  const ReloadSubForm =(guid) =>{

    const idx = completedSubForms.findIndex(el=>el.guid===guid);
  
    if(idx !==-1) {
      const formElements = formInfo.split('|');
      const subFormInfoTemp = `${formElements[0]}|${formElements[1]}|${completedSubForms[idx].subFormData.form.formid}`;
      console.log(subFormInfoTemp);
      setCurrentSubFormInfo(subFormInfoTemp);
      setCurrentSubform(completedSubForms[idx].subFormData.form)
      setCurrentValues(completedSubForms[idx].subFormData.fieldValues)
      setCurrentGuid(guid);
      setShowSubForm(true);
    }
  }

  const saveSubFormData = (data,sForms) =>{

    //if currentGuid is not null, this indcates we are updating an existing form, update fieldValues for that form
    if(currentGuid !==null) {
       const idx = completedSubForms.findIndex(el=>el.guid===currentGuid)
       if(idx !==-1) {
        completedSubForms[idx].subFormData.fieldValues = data;
        completedSubForms[idx].otherForms =sForms;
        addSubFormData(fieldData.fieldInfo.data,completedSubForms[idx].subFormData,currentGuid,false)
       }
       setShowSubForm(false);
       setCurrentGuid(null);
       setCurrentValues(null);

    } else {
      const guid = uuidv4();
      const subFormData = {form:currentSubForm,col:fieldData.fieldInfo,fieldValues:data,guid:guid,otherForms:sForms}
      addSubFormData(fieldData.fieldInfo.data,subFormData,guid)
      setShowSubForm(false);
    }

    // adding this call forces UI to update to newly added form entry appears.
     updateFieldValue('xxx',null)
  }


  //8-5-2022 Adding logic to better set default value based on single/multi-select. 
  const getDropDownValue= () =>{
    // if(value !==undefined && fieldData.fieldInfo.lookup==='multiple' ? value.id : value !==undefined && value.id !==undefined && fieldData.fieldInfo.lookup==='single' ? value.id[0] : [])
    if(value !==undefined && fieldData.fieldInfo.lookup==='multiple'){
      return value.id
    } else if(value !==undefined && value.id !==undefined && fieldData.fieldInfo.lookup==='single') {
      return value.id[0]
    } else if (value===undefined && fieldData.fieldInfo.lookup==='multiple'){
      return []
    } else if(value===undefined && fieldData.fieldInfo.lookup==='single'){
      return null
    }

  }

  useEffect(() => {
    let data = [];
 
    const getData = async () => {
     
      if (fieldData.fieldInfo.uitype === 6 || fieldData.fieldInfo.uitype === 7 || fieldData.fieldInfo.uitype===4) {
        // 4-2-2021 for real form, we need to use API calls that don't require loggedin user.
        // if "formInfo" is passed in, this means it's external Form render. the "formInfo"
        // is pipe delimited string containting zoneid|DBname|formid.
        if (formInfo === null || formInfo === undefined) { //determines if we are comgin from external form or form designer
          data = await getOptionsAPI(fieldData.fieldInfo.data, tableid,"dropdown");
        } else {
          data = await getFormFieldOptions(
            fieldData.fieldInfo.data,
            fieldData.fieldInfo.source,
            formInfo,
            '',
            'getOptionsApi',
            null,
            tableid,
            fieldData.fieldInfo,
            "dropdown"
          );
        }
      } else if(fieldData.fieldInfo.uitype===8) {
        if (formInfo === null || formInfo === undefined) { //determines if we are comgin from external form or form designer
          if(fieldData.fieldInfo.userType==='zone' || fieldData.fieldInfo.userType==='specific')
          {
            const filters=[{field:"status",value:['Active']}]
            data = await getUsersAPI(filters,false,"dropdown",fieldData.fieldInfo.userType==='specific' ? fieldData.fieldInfo.definedUsers : null)
          } else if(fieldData.fieldInfo.userType==='block') {
            data = await getBlockUsersAPI(blockid,true,false,'dropdown')
          } else {
            data = await getUsersAPI([],false,"dropdown");
          }

        } else {
          data = await getFormFieldOptions(
            fieldData.fieldInfo.data,
            fieldData.fieldInfo.source,
            formInfo,
            '',
            fieldData.fieldInfo.userType==='block' ? 'getBlockUsers' : 'getZoneUsers',
            null,
            tableid,
            fieldData.fieldInfo,
            "dropdown",
             100,
             role,
             addedby !==undefined ? addedby : 0,
             blockid
          );
        }
      } 
      else if(fieldData.fieldInfo.uitype===1 && fieldData.fieldInfo.source.nestedFields!==undefined) {
        if(formInfo === undefined || formInfo === null) {
          data = await getRelationalTableData(tableid,fieldData.fieldInfo,role,'',5000,null,'raw',true,false);
        } else {
          data = await getFormFieldOptions(
            fieldData.fieldInfo.data,
            fieldData.fieldInfo.source,
            formInfo,
            '',
            'getRelationalTableData',
            null,
            tableid,
            fieldData.fieldInfo,
            "list",
            5000,
            role,
            addedby !==undefined ? addedby : 0,
            blockid
          );
        }
      }
      else {
        if (formInfo === undefined || formInfo === null) {
          data = await getRelationalTableData(tableid,fieldData.fieldInfo,role,'',100,null,"dropdown",true,false);
        } else {        
          data = await getFormFieldOptions(
            fieldData.fieldInfo.data,
            fieldData.fieldInfo.source,
            formInfo,
            '',
            'getRelationalTableData',
            null,
            tableid,
            fieldData.fieldInfo,
            "dropdown",
            100,
            role,
            addedby !==undefined ? addedby : 0,
            blockid
          );
        }
        // If it's a subTable, requires new entries, so set data to empty. They will use add subform to add an item.
        if(fieldData.fieldInfo.isSubTable) {
          data=[];
        }
      }

      // 8-5-22 adding logic to flag whether relation field is nested or not.
      //logic below will use this param to show regular relational options or the nestedDropdown control.
      if(fieldData.fieldInfo.uitype===1 && fieldData.fieldInfo.source.nestedFields !==undefined){
        setIsNestedField(true);
      }

      // 6-16-2021 Changed to 30 to deal with St Elizabath use-case. Will be changing logic to
      // allow users to decide which type of control to use in future. 
      if(fieldData.fieldInfo.uitype===8 || (data.length>30 && (fieldData.fieldInfo.source===undefined || fieldData.fieldInfo.source.nestedFields===undefined)) ) {
        // 5-13-2021 Check to see if passed in value (url param) is in existing list. For larger relational fields
        // the UI brings back 50 by default, a urlparam passed in might not be in this list,therefore
        // we need add/append the passed in value to eixsting data.

    
        if(value !==undefined && value.id !==undefined){
        const idx=data.findIndex(itm=>parseInt(itm.value)===value.id[0])
        if(idx===-1){
          data.push({key:value.id[0],value:value.id[0], text:value.label[0]})
         
        }
        }
          // 11-13-23 remove check on htmlMode, as we no longer have issue
          // with relational forms in grid, 
          
           // 11-6-23 reminder that htmlMode param is used to support creating relational forms from data list, which 
          //is done from Grid and we can't currently support semantic dropdown from a editable grid column, so we are
          //forced to use html controls instead. 
        if((htmlMode || screen.width<500) && fieldData.fieldInfo.lookup==='single') {
          data.unshift({key:0,value:'',text:''}) 
        }

          setUseDropDown(true)
      }
      
      
      setOptions(data);
    };

    //if field is relational, single/multiple select, load option data.
    if ([6, 7, 1, 4, 8].includes(fieldData.fieldInfo.uitype)) {
      getData();
    }

  },[id]);

  useEffect(() => {
    if(fieldData.fieldInfo.uitype===13 && value !==undefined && value && value !=='' && !rtfLoaded ) {
      setRTFLoaded(true);
      setInitEditorValue(value);
    }
  },[value])

  // useEffect(() =>{
  //   console.log(value)
  // },[value])


  const handleDropDownSelection =(field,data)=>{


    const {value} = data;
   

    let labels=''
    let newValue=[];

    if(fieldData.fieldInfo.lookup==='multiple'){
      newValue=value;
    } else {
      newValue=[value];
    }
      labels=[];

      // 8-16-2022 adding logic If value is from nestedfield, data format is different for looking up label value.
      if(fieldData.fieldInfo.uitype===1 && fieldData.fieldInfo.source.nestedFields!==undefined) {
        value !=='' && newValue.map(el=>{
          const index = options.findIndex(o => o.id === parseInt(el))
          if(index !==-1){
            labels.push(options[index].CustomID)
          }
        })
      } else {
        value !=='' && newValue.map(el=>{
        const index = data.options.findIndex(o => parseInt(o.value) === parseInt(el))
        if(index !==-1){
          labels.push(data.options[index].text)
        }
      
        // Check to see if passed in value is in existing list. It might not be if they are filtering big list of options.
        // in order for it to display, we need to add it to list if it doesnt' exist.
        const idx=options.findIndex(itm=>itm.value===el)
        if(idx===-1){
          const tempOptions=[...options]
          tempOptions.push(data.options[index])
          setOptions(tempOptions)
        }

      })
    }
  
    setFilter('')
    setFilteredOptions([])

    if(value==='' || value===null || (Array.isArray(value) && value.length===0)){
      updateFieldValue(field,'')
    } else {
      updateFieldValue(field,{id:newValue,label:labels})
    }
 
  }

  const handleUploadFiles = async (files,category,showProgress=null) => {

     const idx = options.findIndex(el=>el.value===parseInt(category))
     if(idx !==-1){
      await uploadDataFiles(fieldData,files,options[idx],showProgress)
     }

  }

  const handleDropdownSearch = async (value) => {
  

    if(fieldData.fieldInfo.uitype !==1){
     setFilter(value)
     setFilteredOptions(options.filter(el=>el.text.includes(value)))
     return false;
    }

    setFilter(value)
    let data=[]
    if (formInfo === undefined || formInfo === null) {
      // data = await getRelationRecords(fieldData.fieldInfo.source, value);
      data = await getRelationalTableData(tableid,fieldData.fieldInfo,3,value,100,null,"dropdown",true,false);
    } else {
      data = await getFormFieldOptions(
        fieldData.fieldInfo.data,
        fieldData.fieldInfo.source,
        formInfo,
        value,
        'getRelationalTableData',
        null,
        tableid,
        fieldData.fieldInfo,
        "dropdown",
        100
      )
    }
      
      setFilteredOptions(data)
  }

  const DateInput = React.forwardRef(({ value, onClick }, ref) => (
    <TextInput
      ref={ref}
      value={value}
      labelsInCaps={labelsInCaps}
      isRequired={fieldData.required}
      readOnly={true}
      width="100%"
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight="bold"
      Action={onClick}
      name={!isEditMode ? fieldData.label : ''}
      marginLeft="0px"
      field={fieldData.fieldInfo.data}
      color={color}
      textColor={textColor}
      error={error !==''}
    />
  ));

  // const upload = async (acceptedFiles, category) => {
  //   setIsLoading(true);
  //   await uploadFiles(acceptedFiles, category);
  //   setIsLoading(false);
  //   console.log(acceptedFiles)
  //   console.log(category)
  // };


  const handleSelectChange = (field,e) => {
    
    let value = Array.from(e.target.selectedOptions, option => option.value);
    let label =  Array.from(e.target.selectedOptions, option => option.text);
     updateFieldValue(field,{id:value,label:label})
  }


  const getLabelFormat =(label) => {
    if(labelsInCaps) return label.toUpperCase()
    return label
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isEditMode ? 'row' : 'column',
        width: '100%',
        marginBottom: isEditMode ? '0px' : '30px',
        //  border: '1px solid blue',
      }}
    >
      {fieldData.fieldInfo.uitype === 2 || fieldData.fieldInfo.uitype === 3 || fieldData.fieldInfo.uitype === 14 ? (
        <div style={{ display: 'flex',position:'relative',zIndex:0, flexDirection: 'column', width: '100%' }}>
          <TextInput
           autoFocus={index===0}
            value={value}
            labelsInCaps={labelsInCaps}
            name={!isEditMode ? fieldData.label : ''}
            isRequired={fieldData.required}
            isTextArea={fieldData.fieldInfo.uitype === 3}
            marginLeft="0px"
            updateValue={updateFieldValue}
            width="100%"
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight="bold"
            field={fieldData.fieldInfo.data}
            color={color}
            textColor={textColor}
            error={error !==''}
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575' }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055' }}>{error}</div>
          ) : null}
        </div>
      ) : null}

      {fieldData.fieldInfo.uitype === 13 ? (
        <div style={{ display: 'flex',position:'relative', flexDirection: 'column', width: '100%',}}>
          <div
            style={{ color: textColor, marginBottom: '5px', fontSize, fontFamily, fontWeight: 'bold' }}
          >
            {!isEditMode ? getLabelFormat(fieldData.label) : ''}
            {fieldData.required ? (
              <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
            ) : (
              ''
            )}
          </div>
      <div style={{position:'relative',zIndex:0, border: '1px solid #E5E5E6', borderRadius: '7px',position:'relative'}}>
            <CustomEditor  
             initValue={initEditorValue}
             field={fieldData.fieldInfo.data} 
             updateContent={updateFieldValue}
             height={300} 
             callingComponent="form" 
             showMenu={!htmlMode} 
             />
         </div>
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575' }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055' }}>{error}</div>
          ) : null}
        </div>
      ) : null}


      {fieldData.fieldInfo.uitype === 10 || fieldData.fieldInfo.uitype === 11 ? (
        <div style={{ display: 'flex', position:'relative', flexDirection: 'column', width: '100%' }}>
          <TextInput
            value={value}
            marginLeft="0px"
            isRequired={fieldData.required}
            name={!isEditMode ? fieldData.label : ''}
            width="100%"
            labelsInCaps={labelsInCaps}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight="bold"
            updateValue={updateFieldValue}
            field={fieldData.fieldInfo.data}
            color={color}
            textColor={textColor}
            error={error !==''}
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055', fontFamily}}>{error}</div>
          ) : null}
        </div>
      ) : null}
      {fieldData.fieldInfo.uitype === 5 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div
            style={{ color: textColor, marginBottom: '5px', fontSize, fontFamily, fontWeight: 'bold' }}
          >
            {!isEditMode ? getLabelFormat(fieldData.label) : ''}
            {fieldData.required ? (
              <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
            ) : (
              ''
            )}
          </div>
          <Checkbox
            label=""
            checked={value=='false' || value===false  || value===undefined ? false :true}
            onChange={(e, data) =>
              updateFieldValue(
                fieldData.fieldInfo.data,
                data.checked
              )
            }
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
        </div>
      ) : null}

      {fieldData.fieldInfo.uitype === 9 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ color: textColor, fontSize, fontFamily, fontWeight: 'bold' }}>
            {!isEditMode ? getLabelFormat(fieldData.label) : ''}
            {fieldData.required ? (
              <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
            ) : (
              ''
            )}{' '}
          </div>
          <div
            css={{
              position:'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // border: requiredError ? `1px solid #E06055` : `1px solid #eee` ,
              borderTop: error !=='' ? `1px solid #E06055` : `1px solid #DEDEDF` ,
              borderBottom: error !=='' ? `1px solid #E06055` : `1px solid #DEDEDF` ,
              borderRight: error !=='' ? `1px solid #E06055` : `1px solid #DEDEDF` ,
              outline: '0px',
              // borderLeft: `7px solid ${color}`,
              height: '35px',
              borderRadius: '3px',
              paddingLeft:'15px',
              '&:focus': {
                outline: 'none !important',
                // borderLeft: `7px solid ${color}`,
                borderTop: `1px solid ${color}`,
                borderRight: `1px solid ${color}`,
                borderBottom: `1px solid ${color}`,
              },
              // borderLeft: `7px solid ${color}`,
            }}
          >
            <GBPhone handleChange={updateFieldValue} id={fieldData.fieldInfo.data} value={value} focus={false} />
            <div style={{position:'absolute',borderTopLeftRadius:'3px', borderBottomLeftRadius:'3px', width:'7px',height:'35px',top:-1,left:0,backgroundColor:color}} >&nbsp;</div>
          </div>
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}


     {/* relational , single, multiselect with more than 30 items */} 
      {((fieldData.fieldInfo.uitype === 6 ||
        fieldData.fieldInfo.uitype === 7 ||
        fieldData.fieldInfo.uitype === 8 ||
        fieldData.fieldInfo.uitype === 1
        ) &&
        useDropDown && !isNestedField
        ) ? (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%' }}>
          {!isEditMode ? (
            <div
              style={{
                color: textColor,
                marginBottom: '5px',
                fontSize,
                fontFamily,
                fontWeight: 'bold',
              }}
            >
              {getLabelFormat(fieldData.label)}{' '}
              {fieldData.required ? (
                <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
              ) : (
                ''
              )}{' '}
            </div>
          ) : null}
          {(screen.width<500  && options.length <100) ? (
            <select name="option1" 
             multiple={fieldData.fieldInfo.lookup==='multiple'}
             onChange={(e) =>handleSelectChange(id,e)}
             css={{
              borderTopLeftRadius: '3px',
              height: fieldData.fieldInfo.lookup==='multiple' ? '100px' : '35px',
              borderBottomLeftRadius: '3px',
              backgroundColor:'white',
              border:'0px',
              borderLeft: `7px solid ${color}`,
              outline:'none',
              borderTop: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
              borderBottom: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
              borderRight: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
              '&:focus': {
                outline: 'none !important',
                borderLeft: `7px solid ${color}`,
                borderTop: `1px solid ${color}`,
                borderRight: `1px solid ${color}`,
                borderBottom: `1px solid ${color}`,
              }
            }}>
            {options.map(el=> (
              <option key={el.value} selected={isSelected(el.value)} value={el.value}>{el.text}</option>
            ))}
            </select>
          ) : (
              <Dropdown
              selectOnBlur={false}
              id="formDropDown"
              options={filteredOptions.length>0 ? filteredOptions : options}
              fluid
              value={getDropDownValue()}
              selection
              scrolling
              clearable
              searchQuery={filter}
              search
              onSearchChange={(e,data) =>  handleDropdownSearch(data.searchQuery)}
              onChange={(e, data) => handleDropDownSelection(fieldData.id, data)}
              multiple={fieldData.fieldInfo.lookup==='multiple'}
              style={{
                borderLeft: `7px solid ${color}`,
                borderTop: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
                borderBottom: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
                borderRight: error !=='' ? `1px solid #E06055` : `1px solid #eee` ,
                borderTopLeftRadius: '3px',
                borderBottomLeftRadius: '3px',
                outline:'none'
              }}
            /> 
          )}
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}

      {/* relational , single, multiselect with fewer than 30 items */}
      {(fieldData.fieldInfo.uitype === 6 ||
        fieldData.fieldInfo.uitype === 7 ||
        (fieldData.fieldInfo.uitype === 1 && !fieldData.fieldInfo.isSubTable) ||
        (fieldData.fieldInfo.uitype === 1 && fieldData.fieldInfo.isSubTable && (fieldData.subForm !=='' && fieldData.subForm !==undefined) )
        || (fieldData.fieldInfo.uitype === 1 && value==='' )
        ) &&
        !useDropDown && !isNestedField ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {!isEditMode ? (
            <div
              style={{
                color: textColor,
                marginBottom: '10px',
                fontSize,
                fontFamily,
                fontWeight: 'bold',
              }}
            >
              {getLabelFormat(fieldData.label)}{' '}
              {fieldData.required ? (
                <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
              ) : (
                ''
              )}
            </div>
          ) : null}
          {fieldData.listDisplay === 'button' ? (
            <ButtonDisplay id={id} value={value} updateFieldValue={updateFieldValue}  options={options} color={color} lookup={fieldData.fieldInfo.lookup} />
          ) : (
            <ListDisplay id={id} value={value}  updateFieldValue={updateFieldValue}  options={options} color={color} lookup={fieldData.fieldInfo.lookup} />
          )}

          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}

      {/* 8-5-22 Adding nested web control */}
      {isNestedField && options.length>0 ? (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%' }}>
              {!isEditMode ? (
            <div
              style={{
                color: textColor,
                marginBottom: fieldData.tooltip === '' ? '5px' : null,
                fontSize,
                fontFamily,
                fontWeight: 'bold',
              }}
            >
              {getLabelFormat(fieldData.label)}{' '}
              {fieldData.required ? (
                <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
              ) : (
                ''
              )}{' '}
            </div>
          ) : null}
           {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily,fontSize,marginBottom:'5px' }}>{fieldData.tooltip}</div>
          ) : null}

          
        <NestedDropdowns 
          data={options}
          value={value}
          colInfo={fieldData.fieldInfo} 
          nestedFields={fieldData.fieldInfo.source.nestedFields} 
          role={3} 
          updateValue={handleDropDownSelection} 
          color={color}
          fontFamily={fontFamily}
          fontSize={fontSize}
          labelsInCaps={labelsInCaps}
          updateSelectedLevel={updatedNestedLevels}
          callingComponent="webform"
          htmlMode={window.innerWidth <500}
          />

          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
          </div>
      ): null}
      

      {fieldData.fieldInfo.uitype === 22 && screen.width>500 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <DatePicker
            selected={value ?? null}
            shouldCloseOnSelect={!fieldData.fieldInfo.includeTime || htmlMode}
            timeInputLabel="Time:"
            onChange={(date) => handleDateChange(id,date,fieldData.fieldInfo.includeTime)}
            minDate={moment().subtract(80, 'years')._d}
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            showTimeInput={fieldData.fieldInfo.includeTime}
            dateFormat={fieldData.fieldInfo.dateFormat.replaceAll('D','d').replaceAll('Y','y').replaceAll('A','a')}
            customInput={<DateInput />}
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}

      {fieldData.fieldInfo.uitype === 22 && screen.width<500 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextInput 
           value={convertISOToCustomFormat(value,fieldData.fieldInfo.includeTime)}
           type={fieldData.fieldInfo.includeTime ? "datetime-local" : 'date'}
           marginLeft="0px"
           labelsInCaps={labelsInCaps}
           isRequired={fieldData.required}
           name={!isEditMode ? fieldData.label : ''}
           width="100%"
           fontSize={fontSize}
           fontFamily={fontFamily}
           fontWeight="bold"
           updateValue={convertMobileDateTime}
           field={fieldData.fieldInfo.data}
           color={color}
           textColor={textColor}
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
        </div>
      ) : null}


      {fieldData.fieldInfo.uitype === 4 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {!isEditMode ? (
            <div style={{ color: textColor, fontSize, fontFamily, fontWeight: 'bold' }}>
              {getLabelFormat(fieldData.label)}
              {fieldData.required ? (
                <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
              ) : null}
            </div>
          ) : null}
          {value==='' || value===undefined || fieldData.fieldInfo.lookup==='multiple' ? (
          <DesktopFiles
            callingComponent="Form"
            color={color}
            uploadFiles={handleUploadFiles}
            height="100px"
            width="100%"
            textColor={textColor}
            category={fieldData.categoryid}
            selectmode={fieldData.fieldInfo.lookup}
          />
          ): null}
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
          {value && value.length>0 ? value.map(file=>(
             <div key={file.guidname} style={{display:'flex', marginTop:'10px', flexDirection:'row', justifyContent:'center', width:'100%'}}>
             <FormAttachment field={fieldData.id} deleteFile={deleteFile} filename={file.filename} guidname={file.guidname} />
             </div>
          )
          ): null}

        </div>
      ) : null}

      {fieldData.fieldInfo.uitype === 23 &&
      (fieldData.fieldInfo.minValue === undefined || fieldData.fieldInfo.maxValue === undefined 
        // || parseInt(fieldData.fieldInfo.numericDecimals) >0
        ) ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextInput
            value={value}
            marginLeft="0px"
            min={fieldData.fieldInfo.minValue!==undefined ? fieldData.fieldInfo.minValue : null}
            max={fieldData.fieldInfo.maxValue!==undefined ? fieldData.fieldInfo.maxValue : null}
            type="number"
            labelsInCaps={labelsInCaps}
            isRequired={fieldData.required}
            name={!isEditMode ? fieldData.label : ''}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fontWeight="bold"
            width="100%"
            updateValue={updateFieldValue}
            field={fieldData.fieldInfo.data}
            color={color}
            textColor={textColor}
            error={error !==''}
          />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}

  

      {fieldData.fieldInfo.uitype === 23 &&
      fieldData.fieldInfo.minValue !== undefined &&
      fieldData.fieldInfo.maxValue !== undefined
      //  && parseInt(fieldData.fieldInfo.numericDecimals) ===0
      ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {!isEditMode ? (
            <div
              style={{
                color: textColor,
                marginBottom: '5px',
                fontSize,
                fontFamily,
                fontWeight: 'bold',
              }}
            >
              {getLabelFormat(fieldData.label)}
              {fieldData.required ? (
                <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span>
              ) : null}
            </div>
          ) : null}
          <RangeSlider color={color} field={id} currentValue={value} updateFieldValue={updateFieldValue} min={fieldData.fieldInfo.minValue} max={fieldData.fieldInfo.maxValue} />
          {fieldData.tooltip !== '' && !isEditMode ? (
            <div style={{ color: '#757575',fontFamily }}>{fieldData.tooltip}</div>
          ) : null}
          {error !=='' ? (
            <div style={{ color: '#E06055',fontFamily }}>{error}</div>
          ) : null}
        </div>
      ) : null}
     
      {fieldData.fieldInfo.uitype===1 && fieldData.subForm !==undefined && fieldData.subForm !=='' ? (
           <div style={{margin:'10px'}}>
            {fieldData.maxRecords ===undefined || (completedSubForms && completedSubForms.length < fieldData.maxRecords) ? (
               <GBButton  width="75px" height={"30px"} padding='10px' iconSize='18px' text="Add" icon={mdiPlus} iconPosition='left' color="#EEECEC" textColor='#31260280' iconColor='#31260280' textHoverColor="#312602" Action={loadSubForm} ActionValue={fieldData.subForm} />
            ): null}
           
            
            {completedSubForms && completedSubForms.length>0 && completedSubForms.map((f,i)=> (
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div css={{backgroundColor:'#EEECEC', border:`2px solid ${color}`, padding:'5px',borderRadius:'7px', cursor:'pointer','&:hover': {color:'#00000080', border:`2px solid ${color}80`,  boxShadow: '0px 3px 6px #757575'}}} onClick={()=>ReloadSubForm(f.guid)}>{fieldData.fieldInfo.header} ({f.guid.slice(0,5)})</div>
                <div style={{width:'20px'}}/>
                <div style={{margin:'10px'}}>
                <GBButton height={"30px"} width="75px" iconSize='20px' text="Delete" icon={mdiTrashCan} iconPosition='left' color="#EEECEC" textColor={'black'} iconColor={'black'} iconHoverColor='#E06055'  textHoverColor="#E06055" Action={removeSubFormData} ActionValue={f.guid} />
                </div>
              </div>
            ))}
            {showSubForm && currentSubForm ? (
          <div
            style={{
              position: 'fixed',
              top: screen.width <500 ? 0 : '10%',
              left: screen.width <500 ? 0 : '50%',
              // transform: 'translate(-50%, -50%)',
              // marginTop: screen.width < 501 ? '0' : '-100px', // Half of the viewport height
              marginLeft: screen.width < 501 ? '0' : '-400px', // Half of the width (600px / 2)
              zIndex: 5000,
              backgroundColor: 'white',
              width: screen.width<501 ? '100%' : '800px',
              height: screen.width<501 ? '100%' : 'calc(100vh - 200px)',
              border: '1px solid #aaa',
              boxShadow: '0px 3px 6px #707070',
              borderRadius: '10px',
              overflow:'auto'
            }}
          >
            <div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center', justifyContent:'space-between',backgroundColor:color}}>
                <div style={{display:'flex',paddingLeft:'10px',flexDirection:'row',height:'40px',alignItems:'center',width:'100%',color:'white'}}>
                <div style={{width:'10px',color:'white'}}></div>
                <Icon path={mdiPlus} size="20px" color="white" />
                <div style={{marginTop:'3px'}}> {currentSubForm.name}</div>
                </div>
                <div style={{ marginRight: '10px',color:'black' }}>
                    <TextButton hoverColor="#FFFFFF80" textColor='white' Action={()=>setShowSubForm(false)} text="Close" icon={mdiClose} iconPosition="right" />
               </div>
            </div>
             <FormView currentForm={currentSubForm} hiddenFields={hf} currentValues={currentValues} fInfo={currentSubFormInfo} executeAndClose={saveSubFormData} addedby={addedby} role={fieldData.fieldInfo.role ?? 3}  callingComponent="form" />
          </div>
          ) : null}
        </div>
      ) : null}
   

    </div>
  );
};

export default RenderField;
