/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import FinalForm, { Form, FormField, FormCard, validators } from '../../components/Form';
import { EmailIcon } from '../../components/Icon';
import TextButton from '../../components/TextButton/TextButton';
import Spinner from '../../components/Spinner/Spinner';
import GBButton from '../../components/GBButton/GBButton';
import { getUserByEmail } from '../../api/users';
import { updateEmail } from '../../api/auth';
import GBSubmit from '../../components/GBSubmit/GBSubmit';

const ChangeEmail = ({ color, close, userid, updateEmailParent }) => {
  const formValidators = {
    password1: validators.composeValidators(
      validators.required,
    ),
    email: validators.composeValidators(validators.required, validators.email),
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true)
    setIsSubmitted(true);
    setSubmitError('');
    const checkuser = await getUserByEmail(values.email);

    if (checkuser.length > 0) {
      setSubmitError(`${values.email} is already in use`);
      setIsSubmitted(false)
      setIsLoading(false)
      return;
    }
    try {
      await updateEmail(values.password1, userid, values.email);
      updateEmailParent(values.email);
      setIsLoading(false)
    } catch (error) {
      setSubmitError(error.message);
      setIsSubmitted(false);
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: color,
          textAlign: 'center',
          paddingLeft: '20px',
          color: 'white',
          verticalAlign: 'center',
          height: '40px',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
        }}
      >
        Change email address
      </div>

      {!isSubmitted && !isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginLeft: '40px',marginTop:'10px', marginRight:'20px', display: 'inline' }}>
            Changing your email will modify the email you use at Sign in. It will also need to be
            verified. Enter your new email and confirm you wish to proceed by also providing your
            password.{' '}
            <div style={{ textDecoration: 'underline',marginTop:'10px' }}>
              {' '}
              Verify your email soon, so that you continue to use the full features of GraceBlocks.
            </div>
            <FinalForm onSubmit={onSubmit}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div>
                      <FormField
                        name="email"
                        label="Email"
                        color={color}
                        placeholder="Enter your new email address."
                        validate={formValidators.email}
                        leftIcon={<EmailIcon />}
                        controlId="id_email"
                      />

                      <FormField
                        name="password1"
                        label="Password"
                        placeholder="Enter your password."
                        validate={formValidators.password1}
                        control={Form.PasswordControl}
                        color={color}
                        controlId="id_password1"
                      />
                      {submitError !== '' ? (
                        <div style={{ color: 'red' }}>{submitError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <TextButton
                          text="Cancel"
                          hoverColor="#75757580"
                          Action={() => close()}
                          ActinValue={false}
                          textColor="black"
                        />
                      </div>
                     <GBSubmit color={color} text='Continue' />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
      {isSubmitted && !isLoading && submitError==='' ? (
        <div style={{ margin: '10px' }}>
          Check your email. Click the link provided in this email to validate the email address.
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GBButton text="OK" color={color} Action={close} borderRadius="10px" />
          </div>
        </div>
      ) : null}
      {isSubmitted && isLoading ? (
        <div style={{ margin: '10px' }}>
          <Spinner />
        </div>
      ) : null}
    </div>
  );
};

export default ChangeEmail;
