import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './styles.scss';
import Header from './Header';
import Body from './Body';
import Button from '../Button';
import LogoIcon from '../../assets/icons/GraceBlocksNewLogo.svg';
import ArrowLeftIcon from './arrow-left.png';

const titleForMenu = (visible) => (visible ? 'Collapse' : 'Expand');

const SideBar = ({ visible, toggleDrawer, children }) => {
  const [chevronTitle, setChevronTitle] = useState(titleForMenu(visible));
  const header = [];
  const body = [];
  React.Children.forEach(children, (c) => {
    if (c && c.type === Header) {
      header.push(c);
    } else if (c && c.type === Body) {
      body.push(c);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setChevronTitle(titleForMenu(visible));
    }, 200);
  }, [visible]);

  return (
    <nav id="sidebar" className={visible ? 'active' : 'short'}>
      <div className="header">
        {!!header && header}
        <OverlayTrigger placement="right" overlay={<Tooltip>{chevronTitle}</Tooltip>}>
          <Button variant="clear burger with-icon float-right" onClick={toggleDrawer}>
            <img src={ArrowLeftIcon} alt="expand/collapse" className="move" />
            <img src={ArrowLeftIcon} alt="expand/collapse" />
          </Button>
        </OverlayTrigger>
      </div>

      <div className="content">
        <div>{!!body && body}</div>
      </div>
      <div className="bottom-block">
        <div className="logo">
          <Link to="/">
            <img src={LogoIcon} alt="GraceBlock" />
          </Link>
        </div>
        <OverlayTrigger placement="right" overlay={<Tooltip>{chevronTitle}</Tooltip>}>
          <Button variant="clear burger with-icon float-right" onClick={toggleDrawer}>
            <img src={ArrowLeftIcon} alt="expand/collapse" className="move" />
            <img src={ArrowLeftIcon} alt="expand/collapse" />
          </Button>
        </OverlayTrigger>
      </div>
    </nav>
  );
};

SideBar.defaultProps = {
  toggleDrawer: () => {},

  visible: true,
  children: null,
};

SideBar.propTypes = {
  toggleDrawer: PropTypes.func,

  visible: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

SideBar.Header = Header;
SideBar.Body = Body;

export default SideBar;
