/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation, navigate } from '@reach/router';
import { confirmAlert } from 'react-confirm-alert';
import { parse } from 'query-string';
import { ROUTES } from '../../../constants';
import FinalForm, { Form, FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import Spinner from '../../../components/Spinner/Spinner';
import { EmailIcon } from '../../../components/Icon';
import ToggleButtonGroup from '../../../components/ToggleButtonGroup';
import GBConfirm from '../../../components/GBConfirm/GBConfirm';
import ToggleButton from '../../../components/ToggleButton';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';
import { ReactComponent as Logo } from '../PhoneValidator/Success.svg';
import { SendEmailPasswordReset, ConfirmPasswordReset, VerifyPhoneNumber,verifyEmailConfirmation } from '../../../api/auth';
import GBButton from '../../../components/GBButton/GBButton';
import { ArrowLeftIcon } from '../../../components/Icon';
import firebase, { phoneProvider } from '../../../clients/firebase';

const formValidators = {
  email: validators.composeValidators(validators.required, validators.email),
  password: undefined,
  phoneNumber: validators.composeValidators(validators.required, validators.phoneNumber),
  password1: validators.composeValidators(
    validators.required,
    validators.minLength(8),
    validators.password,
  ),
  code: validators.composeValidators(validators.required),
};

const handleClose = (action) => {
  if (action === 'redirect') {
    navigate('/auth/forgot-password');
  }
};

const GotoSignIn =() =>{
  navigate('/auth/sign-in')
}

const equalToPassword = (value, values) => {
  if (value !== values.password1) {
    return 'Passwords are not equal.';
  }
  return undefined;
};

const showError = (message) => {

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <GBConfirm
          title="An error occurred"
          action={onClose}
          showCancelButton={false}
          buttonLabel="OK"
          message={message}
          showInput={false}
          height="300px"
        />
      );
    },
  });

};

const ForgotPassword = ({ code }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('EMAIL');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [email, setEmail] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [mode, setMode] = useState('userEntry');

  const handleChange = () => {
    setSelected(selected === 'MOBILE PHONE' ? 'EMAIL' : 'MOBILE PHONE');
  };
  const location = useLocation();

  useEffect(() => {
    if (location.search !== '') {
      const searchParams = parse(location.search);
      setResetCode(searchParams.oobCode);
      setMode(searchParams.mode);

      // 9-18-2020 if it's email verification
      const verifyEmail = async ()=> {
          const result = await verifyEmailConfirmation(searchParams.oobCode)
      }
      if(searchParams.mode==='verifyEmail')
      {
        verifyEmail()
      }
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-validator', {
      size: 'invisible',
    });
  }, []);

  const confirmAcccount = async (values) => {
    if (selected === 'EMAIL') {
      await SendEmailPasswordReset(values.email);
      setIsSubmitted(true);
    } else {
      setIsSubmitted(true);
      try {
        const result = await VerifyPhoneNumber(values.phoneNumber);
        if (result.data === 'success') {
          const id = await phoneProvider.verifyPhoneNumber(
            values.phoneNumber,
            window.recaptchaVerifier,
          );
          setVerificationId(id);
        }
      } catch (error) {
        console.log(error.message);
        showError(error.message);
      }
    }
  };

  const verifySMSCode = async (values) => {
    try {
      const AuthCredential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        values.code,
      );
      await firebase.auth().signInWithCredential(AuthCredential);
      navigate(ROUTES.REDIRECTION.DASHBOARD);
    } catch (error) {
      showError(error.message);
    }
  };

  const handleCancel = () => {
    setIsSubmitted(false);
  };

  const resetPassword = async (values) => {
    setLoading(true);

    try {
      const result = await ConfirmPasswordReset(resetCode, values.password1);
      navigate(ROUTES.REDIRECTION.SIGN_IN);
    } catch (error) {
      setLoading(false);
      showError(error.message);
    }
  };

  return (
    <>
    <Button variant="clear" className="back-button" onClick={() =>navigate(ROUTES.REDIRECTION.AUTH)}>
          <ArrowLeftIcon />
        </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img src={LogoIcon} alt="Logo" />
      <div id="phone-validator" />
      <FormCard
        title={
          mode === 'userEntry' || mode === 'forgotPassword'
            ? 'RESET YOUR PASSWORD'
            : 'Email verification successful'
        }
      >
        {!isSubmitted && mode === 'userEntry' && resetCode === '' ? (
          <>
            <div style={{ textAlign: 'left', marginBottom: '20px' }}>
              Choose how you wish to locate your account, by mobile phone or email address. Then
              enter the information field and click Find my account.
            </div>

            <ToggleButtonGroup
              type="checkbox"
              value={selected}
              onChange={handleChange}
              separated
              className="mb-4"
            >
              <ToggleButton variant="outline-primary" value="MOBILE PHONE">
                MOBILE PHONE
              </ToggleButton>
              <ToggleButton variant="outline-primary" value="EMAIL">
                EMAIL
              </ToggleButton>
            </ToggleButtonGroup>

            <FinalForm
              onSubmit={confirmAcccount}
              // initialValues={{ email: 'flampschelsea@gmail.com', password: '11111111aA!' }}
              id="id-sign-in-form"
            >
              {() => {
                // console.log(args);
                return (
                  <>
                    {selected === 'EMAIL' ? (
                      <FormField
                        name="email"
                        label="Email"
                        placeholder="Enter your email address."
                        validate={formValidators.email}
                        leftIcon={<EmailIcon />}
                        //   controlId="id_email"
                      />
                    ) : (
                      <>
                        <FormField
                          name="phoneNumber"
                          label="Mobile Phone"
                          placeholder="Enter your mobile phone number."
                          validate={formValidators.phoneNumber}
                          control={Form.PhoneNumberControl}
                          //   controlId="id_phone"
                        />
                      </>
                    )}
                    <div className="py-5" />
                    <Button long type="submit">
                      Find my account
                    </Button>

                    {/* <div className="small pt-4">
                  New to GraceBlocks? <Link to="/auth/sign-up">Sign up for free</Link>
                </div> */}
                  </>
                );
              }}
            </FinalForm>
          </>
        ) : null}
        {resetCode !== '' && mode === 'resetPassword' ? (
          <FinalForm onSubmit={resetPassword} id="id-sign-in-form">
            {() => {
              // console.log(args);
              return (
                <>
                  <FormField
                    name="password1"
                    label="Password"
                    placeholder="Enter a new password"
                    control={Form.PasswordControl}
                    validate={formValidators.password}
                    validateFields={['password2']}
                    controlId="id_password1"
                    helpText="Use 8 or more characters using letters, numbers and symbols."
                    showLevelTooltip
                  />
                  <FormField
                    name="password2"
                    label="Confirm Password"
                    placeholder="Re-enter your password."
                    control={Form.PasswordControl}
                    validate={equalToPassword}
                    controlId="id_password2"
                  />
                  <div className="py-5" />
                  <Button long type="submit">
                    Reset password
                  </Button>
                </>
              );
            }}
          </FinalForm>
        ) : null}
        {isSubmitted && selected === 'EMAIL' && mode === 'userEntry' ? (
          <div>
            If an account was identified, an email was sent with a forgot password link for you to
            click. Use this email to continue and reset your password.
          </div>
        ) : null}
        {isSubmitted && selected === 'MOBILE PHONE' && mode === 'userEntry' ? (
          <FinalForm onSubmit={verifySMSCode}>
            {({ invalid }) => {
              return (
                <>
                  <FormField
                    name="code"
                    label="Validation Code"
                    placeholder="Enter the validation code."
                    validate={formValidators.code}
                    controlId="id_code"
                  />
                  <div className="mb-4">&nbsp;</div>
                  <Button long type="submit" disabled={invalid} className="mb-3">
                    Submit
                  </Button>
                  <Button onClick={handleCancel} long variant="secondary">
                    Try again
                  </Button>
                </>
              );
            }}
          </FinalForm>
        ) : null}
        {mode === 'verifyEmail' ? (
          <div>
            <div className="my-3">
              <Logo />
            </div>
            <h1 style={{ fontSize: '1.8rem' }}>
              <strong>Your email is verified!</strong>
            </h1>
            <Button onClick={()=>GotoSignIn()} long type="submit">
              Sign in
            </Button>
          </div>
        ) : null}
      </FormCard>
      {loading && <Spinner isLoading isDone={false} color="#AE007B" />}
    </>
  );
};

export default ForgotPassword;
