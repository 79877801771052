/* eslint-disable */
import React, { useCallback, useEffect, useState,useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { Input, Dropdown } from 'semantic-ui-react';
import { mdiGateNor, mdiTrashCan, mdiPencil,mdiCheckCircleOutline } from '@mdi/js';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import EventSettings from './EventSettings';
import ActionNewRecord from './Action_NewRecord';
import FilterConditions from './FilterConditions';
import ActionSchedule from './ActionSchedule';
import ActionDeleteRecord from './Action_DeleteRecord';
import ActionFormTask from './Action_FormTask';
import ActionSendMessage from './Action_SendMessage';
import ActionAddorUpdateUser from './Action_AddorUpdateUser';
import ActionDeactivateUser from './Action_DeactivateUser';
import ActionConfetti  from './Action_Confetti';


const ActionPanel = ({close, addNode, nodeData, updateNode, removeNode,sourceNodes}) =>{

    const [editMode, setEditMode] =useState(true);
    const [name, setName] = useState('');
    const [actionEvent, setActionEvent] =useState('');
    const [nodeId, setNodeId] = useState(null);
    const [localData,setLocalData]=useState(null);
    const [allNodes, setAllNodes]=useState([]);

    const divRef = useRef(); 

    useEffect(()=>{
      setName(nodeData.label);
      setNodeId(nodeData.id);
      
      if(nodeData.actionEvent !==undefined) {
        setActionEvent(nodeData.actionEvent);
      }
      setLocalData(nodeData);

      const allSourceNodes = get_all_source_nodes(nodeData);
      setAllNodes(allSourceNodes);
      const date = new Date();
    
    },[])


    const get_all_source_nodes = (nodeData,currentDepth=0) => {
      const allNodeData = [];
      const seenIds = new Set(); // Use a Set to track unique IDs
    
      const extractSourceNodes = (data,depth) => {
      if (depth > 10) { // Check if we've reached the max depth
          return; // Stop recursion if we have
      }

        if (data.sourceNodes) {
          data.sourceNodes.forEach((sourceNode) => {
            if (sourceNode.id && sourceNode.data && !seenIds.has(sourceNode.id) ) { // Check for 'id' and uniqueness
              allNodeData.push(sourceNode);
              seenIds.add(sourceNode.id); 
            }
    
            if (sourceNode.data) {
              extractSourceNodes(sourceNode.data, depth + 1);
            }
          });
        }
      };
    
      extractSourceNodes(nodeData,currentDepth);
      return allNodeData;
    };

    const removeNodeLocal = (id) =>{
      if (divRef.current.value === 'delete') {
        removeNode(id);
      }
    }
    
   
    const saveNode = () => {
         
        let message=[];
        let isValid=true;
        if(name ==='') {
          message.push('An action name is required')
          isValid=false;
        }
        if(actionEvent ==='') {
          message.push('You must select an action Event')
          isValid=false;
        }
        if(actionEvent==='newrecord' && localData.blockid===undefined) {
          message.push('The Block is a required field')
          isValid=false;
        }
        if(actionEvent==='newrecord' && (localData.tableData===undefined || localData.tableData ===null)){
          message.push('The tab is a required field')
          isValid=false;
        }
        if(actionEvent==='deleterecord' && localData.deletionLevel==='soft' &&  (localData.insertFields===undefined || localData.insertFields.length===0)){
          message.push('You must select which fields to be set to null')
          isValid=false;
        }
        if(actionEvent==='sendmessage' && localData.emailIsActive===true && localData.selectedEmail.some(itm=>!itm.field.includes('|') && itm.value ==='')){
          message.push('Email address is required')
          isValid=false;
        }
        if(actionEvent==='sendmessage' && localData.emailIsActive===true && (localData.emailMessage===undefined) || localData.emailMessage?.subject==='' || localData.emailMessage?.message==='' || localData.emailMessage?.fromAddress==='' ){
          message.push('You must configure the email to be sent.')
          isValid=false;
        }
        if(actionEvent==='sendmessage' && localData.textIsActive===true && localData.selectedText.some(itm=>!itm.field.includes('|') && itm.value ==='')){
          message.push('The recipient phone number is required')
          isValid=false;
        }
        if(actionEvent==='sendmessage' && localData.textIsActive===true && (localData.textMessage==='' || localData.textMessage===undefined)){
          message.push('You must configure the text message to be sent')
          isValid=false;
        }
        
        if(actionEvent==='newrecord' && (localData.insertFields==undefined || localData.insertFields.length===0)){
          message.push('You must specify fields to be inserted into new table.')
          isValid=false;
        }

        if(actionEvent==='updaterecord' && (localData.insertFields==undefined || localData.insertFields.length===0)){
          message.push('You must specify the fields to be updated.')
          isValid=false;
        }

        if((actionEvent==='newrecord' || actionEvent==='updaterecord') && localData.insertFields !==undefined && localData.insertFields.length>0) {
          localData.insertFields.map(m=>{
            if(['manual','function'].includes(m.mappedValue) && (m.dataValue===undefined || m.dataValue==='')){
              message.push(`The field ${m.text} is set to Manual/Function but has no value entered.`)
              isValid=false;
            }
          })
        }
        
        if(actionEvent==='confetti' && (localData.confettiMessage===undefined || localData.confettiMessage==='')) {
          message.push('You must specify the confetti message')
          isValid=false;
        }

        if(actionEvent==='confetti') {
          localData.selectedEmail.map(m=>{
            if(m.field==='manual' && m.value==='') {
              message.push('You must select a user to receive the confetti message')
              isValid=false;
            }
          })
         
        }


        if (!isValid) {
          toast.info(<div style={{ marginRight: '10px' }}>
            {message.map((m, index) => (
            <React.Fragment key={index}>
              {m}
              {index < message.length - 1 && <><br /><br /></>} {/* Add line break if not the last element */}
            </React.Fragment>
          ))}
          </div>, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: true,
          });
          return false;
        } 

        localData.label=name;
        updateNode(localData.id, localData)
    
      };

    const deleteTriggerConfirm = () => {
        const message = (
          <div>
            You have selected to delete the action: {name}. <br/><br/>Are you sure you want to delete this
            action? History of this action will be retained in the workflow but it will no longer be a
            part of the workflow moving forward once saved. 
          </div>
        );
    
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <GBConfirm
                title={'Delete action forever'}
                action={removeNodeLocal}
                width="350px"
                actionValue={nodeData.id}
                buttonLabel="OK"
                message={message}
                divRef={divRef}
                showInput={true}
                confirmAction="delete"
                confirmWord="delete"
                height="450px"
                onClose={onClose}
              />
            );
          },
        });
      };

    return <div style={{margin:'10px',padding:'20px'}}>
            <div
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{fontSize:'30px',fontWeight:'bold'}}>
              {' '}
              {nodeData !== null && !editMode
                ? 'Action details'
                : nodeData !== null && editMode
                ? 'Edit action'
                : 'Add new action'}
            </div>
            <div>
              {nodeData !== null  ? (
                <div style={{color:'grey',fontSize:'14px'}}>Internal ID: {nodeData.id}</div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TextButton textColor="black" text="Cancel" Action={close} />
            <div style={{ width: '20px' }}></div>
     
              <GBButton
                text="Save"
                borderRadius="10px"
                Action={saveNode}
                ActionValue={'trigger'}
                textColor="white"
                fontWeight='normal'
                width="75px"
                fontSize='15px'
                padding='10px'
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
           
          </div>
          <div style={{ height: '20px' }}></div>
          <div style={{ fontWeight: 'bold',fontSize:'20px',marginBottom:'5px' }}>
            Action name <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
          </div>
          <div>
            {!editMode ? (
              <div>{name}</div>
            ) : (
              <Input value={name} fluid onChange={(e, data) => setName(data.value)} />
            )}
          </div>
       
          <div style={{ height: '20px' }}></div>
          {allNodes.length>0 ? (<>
          <EventSettings localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} updateEvent={setActionEvent} actionEvent={nodeData.actionEvent} sourceNodes={sourceNodes}  />
          <div style={{ height: '20px' }}></div>
          {actionEvent==='newrecord' || actionEvent==='updaterecord' ? (
            <div>
            <ActionNewRecord localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null}
          
          {actionEvent==='deleterecord' ? (
            <div>
            <ActionDeleteRecord localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null}

          {actionEvent==='formtask' ? (
            <div>
            <ActionFormTask localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null} 

          {actionEvent==='sendmessage' ? (
           <div>
           <ActionSendMessage localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          {actionEvent==='adduser' ? (
           <div>
           <ActionAddorUpdateUser localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          {actionEvent==='confetti' ? (
           <div>
           <ActionConfetti localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          {actionEvent==='deactivateuser' ? (
           <div>
           <ActionDeactivateUser localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          <div style={{marginTop:'10px'}}>
            <FilterConditions localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
          </div>

          <div style={{marginTop:'10px'}}>
            <ActionSchedule localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
          </div>

          </>):null}

          {/* {testRows?.length > 0 ? (
          <div style={{marginTop:'20px'}}>
            <div style={{fontSize:'20px', fontWeight:'bold',marginBottom:'5px'}}>Test Action</div>
            <div>Test action using the following Trigger test record:</div>
            <Dropdown selection fluid value={testRow} options={testRows} onChange={(e,data)=>updateTestRow(data.value)} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'10px'}}>
            <GBButton
                text="Test Action"
                borderRadius="10px"
                Action={executeTest}
                ActionValue={'trigger'}
                textColor="white"
                width="100px"
                height={'30px'}
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
              {testResult !=null ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'10px'}}>
                  <div style={{marginRight:'5px'}}><Icon path={mdiCheckCircleOutline} size="25px" /></div>
                  <div>Success! If you are ready<br/>to proceed click save.</div>
                </div>
              ):null}
              </div>
          </div>
          ):null} */}
        
        <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TextButton textColor="black" text="Cancel" Action={close} />
            <div style={{ width: '20px' }}></div>
     
              <GBButton
                text="Save"
                borderRadius="10px"
                Action={saveNode}
                ActionValue={'trigger'}
                textColor="white"
                fontWeight='normal'
                width="75px"
                fontSize='15px'
                padding='10px'
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
           
          </div>

          <GBButton
                icon={mdiTrashCan}
                iconPosition="left"
                iconSize="30px"
                text="Delete forever"
                borderRadius="10px"
                Action={deleteTriggerConfirm}
                // ActionValue={nodeData.id}
                textColor="black"
                width="150px"
                height={'35px'}
                color="#eee"
                textHoverColor="#00000080"
                hoverBackgroundColor={'#eee'}
              />
    </div>
}

export default ActionPanel