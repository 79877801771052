/* eslint-disable */
import moment from 'moment';

export const TO_NUMBER =(val) =>{
    const match = val.match(/(\d+(?:\.\d+)?)/);
    if (match) {
      return parseFloat(match[0]);
    } else {
        // If no match is found, return null or handle the error as needed
      return null; 
    }
  }

export const NOW = () =>{
    const now = moment();
    return now;
   }

export const TO_DATE=(val) =>{
    const d = moment(val)
    return d;
  }


 export const DATEADD = (date1, num, interval) => {
    const newData = new Date(date1);

    switch (interval.toLowerCase()) {
        case 'seconds':
            newData.setSeconds(newData.getSeconds() + num);
            break;
        case 'minutes':
            newData.setMinutes(newData.getMinutes() + num);
            break;
        case 'hours':
            newData.setHours(newData.getHours() + num);
            break;
        case 'days':
            newData.setDate(newData.getDate() + num);
            break;
        case 'weeks':
            newData.setDate(newData.getDate() + num * 7);
            break;
        case 'months':
            newData.setMonth(newData.getMonth() + num);
            break;
        case 'years':
            newData.setFullYear(newData.getFullYear() + num);
            break;
        default:
            throw new Error('Invalid interval specified');
    }

    return newData.toISOString(); 
};

export const CONCAT =(...strings) =>{
    return strings.join(''); 
  }



