/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from 'react';
import onClickOutside from 'react-onclickoutside';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Icon from '@mdi/react';
import {Form } from 'semantic-ui-react';
import { AccountContext } from '../../../providers/AccountProvider';
import { mdiArrowLeft,mdiContentCopy } from '@mdi/js';
import TextButton from '../../../components/TextButton/TextButton';
import {checkBlockCanBeCopied, checkBlockHasMessageFields} from '../../../api/blocks'
import { getZoneAPI } from '../../../api/zone';
import GBSwitch from '../../../components/GBSwitch/GBSwitch';
import GBButton from '../../../components/GBButton/GBButton';
import GBConfirm from '../../../components/GBConfirm/GBConfirm';
import Spinner from '../../../components/Spinner/Spinner'
import invalidUSRegions from '../../../utils/invalidUSRegions';
import Global from '../../../clients/global';;

import styles from '../../Block/components/TabLists/NewTab/index.module.css';

const clickOutsideConfig = {
    handleClickOutside: ({props}) => DuplicateBlock['handleClickOutside_' + props.field]
  }
  

function DuplicateBlock ({field,color,item,close,isLeftAligned,isTopAligned,onCopy,callingComponent='homepage'}) {

    DuplicateBlock['handleClickOutside_'+field] =() =>close();

    const [isLoading, setIsLoading]= useState(false);
    const [includeData,setIncludeData] = useState(false);
    const [includeBookmarks, setIncludeBookmarks] = useState(false);
    const [includeForms, setIncludeForms] = useState(false);
    const [tabName, setTabName] = useState(`${item.name} copy`);
    const { userInfo } = useContext(AccountContext);
    const [zoneid, setZoneId] = useState(null);
    const { REACT_APP_PUBLIC_ZONE_ID } = process.env;
    const divRef = useRef();

    const isPublicZone = Global.zoneid===parseInt(REACT_APP_PUBLIC_ZONE_ID);

    const checkValidTwilioNumber = async () =>{
      let phoneNumber='';
      let hasPreviousNumbers=false;
      let isNumberConfigured=false;
      const zoneInfo = await getZoneAPI(zoneid);
     
      if (zoneInfo.attributes !== null && zoneInfo.attributes.PhoneNumbers !== undefined) {
        hasPreviousNumbers = true;
        const idx = zoneInfo.attributes.PhoneNumbers.findIndex(el=>el.isActive===true);
        if(idx !==-1) {
          phoneNumber= zoneInfo.attributes.PhoneNumbers[idx].number;
          isNumberConfigured=true;
        }
      } 
      return {phoneNumber, hasPreviousNumbers,isNumberConfigured};
    }

    const MobileRequiredMessage = () => {

      const message =<div>In order to add text messaging, we need a validated phone number on file associated
                with your record. Please go to your Profile page
                <Icon path={mdiAccountCircle} size="25px" />
                in the top right area of this page, add a mobile phone number, and verify it. Then you
                will be able to proceed with this action.</div>
  
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title="Phone validation"
              action={onClose}
              showCancelButton={false}
              buttonLabel="OK"
              message={message}
              showInput={false}
              height="430px"
            />
          );
        },
      });
    };
  


    const verifyLogic = async () => {
     
      if(zoneid===null){
        toast.info('You must select a destination zone.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        })
        return false;
      }

      //First check if blocck has references to other blocks. If yes, they can't copy this block
      const blocks = await checkBlockCanBeCopied(item.blockid);
      if(blocks.length>0) {
     
     const r= blocks.map(itm=>(
        <tr style={{color:'grey',border:'1px solid black'}}><td>{itm.name}</td><td>  </td><td>{itm.header}</td></tr>
        ));

        const fieldInfo =<table style={{border:'1px solid black',width:'100%'}}><th>Tab</th><th></th><th>Field</th>
        {r}
        </table>

        // const finalMessage=<div>Blocks that reference other Blocks through related records can not be duplicated. <p/><p/> {tempfields}</div>;
        const finalMessage=<div>
          <div>Blocks that reference other Blocks through related records can not be duplicated.</div>
          <p/>
          <div style={{color:'#FECB4A'}}>Fields causing the issue:</div><p/>
          {fieldInfo}
          </div>

        confirmAction('References another Block',finalMessage,false);
        return;
      }

      //Second check if block has message fields. If yes, we have a number of additional steps we need to check
      const hasMessageFields = await checkBlockHasMessageFields(item.blockid);

      if(hasMessageFields){
        // First, does user have a validated mobile phone number. If not, they must configure it.
        if (userInfo.mobileverifydate===null) {
          MobileRequiredMessage();
          return;
        } 

        //Check to see if there are any valid twilio numbers available to use in the selected zone.
        const {phoneNumber, hasPreviousNumbers,isNumberConfigured} = await checkValidTwilioNumber();

        if(!isNumberConfigured) { //If no valid Twilio number, check following use-cases to determine action.
          const currentRole=userInfo.zones.filter(itm=>itm.id===zoneid)[0].role;
          const areaCode = parseInt(userInfo.phoneNumber.slice(2,5))
          let message='';

          if(phoneNumber==='' && hasPreviousNumbers){
            message= <div>The selected Zone currently has no active phone numbers. In order to use messaging, please purchase a phone number from the selected Zone settings page.</div>
            confirmAction("No active phone numbers",message,false);
          }
          else if(userInfo.phoneNumber.slice(0,2)==='+1' && !invalidUSRegions.includes(areaCode)){
            message= <div>Messaging has not yet been activated in the selected Zone. By copying this Block, you will activate the free 30-day messaging trial which allows you to send/receive your first few messages using some initial funds we have provided. To continue using messages beyond this time-period or amount, a Zone Owner will need to upgrade the selected Zone to a paid plan and also purchase message funds.</div>
            confirmAction("Text messaging activation warning",message,true);
          } 
          else if ((userInfo.phoneNumber.slice(0,2) !=='+1' || invalidUSRegions.includes(areaCode)) && currentRole==='Zone owner'){
            message=<div>
            The selected Zone is currently on the free plan. Text messaging can not be activated until you upgrade to a paid plan. Click Upgrade Now in the top left corner of from the View all my GraceBlocks Homepage to get started. You will also need to purchase a phone number in your desired country and message funds once you are on a paid plan. These steps can be completed from the page you will land on once you complete the upgrade process. (Zone settings page - also accessed via the gear icon next to the Zone name in the left side navigation panel of the View all my GraceBlocks Homepage.)
          </div>
            confirmAction("Upgrade to activate text messaging",message,false);
          } else if ((userInfo.phoneNumber.slice(0,2) !=='+1' || invalidUSRegions.includes(areaCode)) && currentRole !=='Zone owner') {
            message=<div>
               The selected Zone is currently on the free plan and text messaging can not be activated until you upgrade to a paid plan. Contact the selected Zone owner for more details.
              </div>
            confirmAction("Upgrade to activate text messaging",message,false);
          }
        } else {
          await duplicate(hasMessageFields,phoneNumber);
        }
      } else {
         await duplicate(hasMessageFields,'');
      }

    }

    const duplicate = async (hasMessageFields,phoneNumber) => {
      // if hasMessagefields, what number to use OR to provision new number
      setIsLoading(true);

      //11-7-22 don't allow them to copy a block from a zone with higher plan to zone with lower plan
      //ie, they can't copy a block from starter plan to a zone with free plan.
      // If currentZone is "Public", we use special logic to determine valid destinatino zone based
      //on the zonename containing 1 asterisk (*) = Starter plan, or 2 astericks (**) = pro plan.

      const { REACT_APP_PUBLIC_ZONE_ID } = process.env;
      let sourcePlan='Free'

      const currentZone =  userInfo.zones.filter(z =>z.id===Global.zoneid)[0];
      const destinationZone =  userInfo.zones.filter(z =>z.id===zoneid)[0];
     
      // Determine source Zone plan, either coming from Public Zone or their own zone.
      if(currentZone.id===parseInt(REACT_APP_PUBLIC_ZONE_ID) && item.name.includes('**')) {
        sourcePlan='Pro'
      } else if (currentZone.id===parseInt(REACT_APP_PUBLIC_ZONE_ID) && item.name.includes('*')) {
        sourcePlan='Starter'
      }  else if (currentZone.id===parseInt(REACT_APP_PUBLIC_ZONE_ID)){
        sourcePlan='Free'
      } else {
        sourcePlan=currentZone.plan;
      }

      if(
          ((sourcePlan==='Starter' || sourcePlan==='Pro' || sourcePlan==='Enterprise') && destinationZone.plan==='Free')
          ||
          ((sourcePlan==='Pro' || sourcePlan==='Enterprise') && destinationZone.plan==='Starter')
          ||
          ((sourcePlan==='Enterprise') && destinationZone.plan==='Pro')
        )        
        {
          toast.info('The destination Zone must be enrolled in an equal or higher plan as the Zone from which you are copying this Block.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 7000,
          })
          setIsLoading(false);
          return false;
        } 
        
      if((hasMessageFields && phoneNumber==='' && divRef.current && divRef.current.value === 'activate') || (hasMessageFields && phoneNumber !=='') || (!hasMessageFields)) {
            const data={item: item,blockid:item.blockid, destinationZoneId: zoneid, name:tabName,includeData,includeBookmarks,includeForms,phoneNumber,hasMessageFields,userPhone: userInfo.phoneNumber}
            
            const result = await onCopy(data);            
            // 4-20-24 Note the success/failure toast is comjing from useCacheBlocks handleCopyBlock method

            if(callingComponent==='tab') {
              if(result.error !==undefined) {
                toast.info('Failed to copy Block. The support team has been notified.', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 7000,
                })
              } else {
                toast.success('Block copied successfully!', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 7000,
                })
              }
            }

            close();
        } 
    }

    // 5-4-2022 I added this second method specifically for the action from the pop-up activation box
    // because currently the "verifylogic" method will invoke the duplicate method by determining 
    // whether message fields are required, as well as what the phonenumber is. These needed to be passed to
    //dupocliate method, because state update was not recogized for those variables when calling it, so I pass them along.
    // in use-case where ueer must type "activate" to provision a new number for their zone, instead of trying to 
    // pass variables to confirmation logic and then to dup logic, I instead opted to create a dedicated method
    // where I know messageifelds exist and I can set the phoneNumber='' which is only reason they would be coming
    // from confirmation alert tbox 
    const duplicateFromConfirmationBox = async () => {

      if(divRef.current.value === 'activate') {
        const data={item: item,blockid:item.blockid, destinationZoneId: zoneid, name:tabName,includeData,includeBookmarks,includeForms,phoneNumber:'',hasMessageFields:true,userPhone: userInfo.phoneNumber}
        const result = await onCopy(data);
  
        if(result.error ===undefined){
        toast.success('Block successfully copied!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        })} else {
          toast.success(result.error, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          })
        }
        close();
    } 
    }

    const confirmAction = (title,message,showInput=true) => {
    
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title={title}
              action={showInput ? duplicateFromConfirmationBox : onClose}
              buttonLabel="OK"
              message={message}
              width="400px"
              divRef={divRef}
              showInput={showInput}
              showCancelButton={showInput}
              confirmAction=""
              confirmWord="activate"
              height="430px"
              onClose={onClose}
            />
          );
        },
      });
  
    };

    const getOptions =() =>{
        const options=[];
        userInfo.zones.filter(z=>['zone owner','zone builder'].includes(z.role.toLowerCase()) && z.token !==null).map(el=>{
          options.push({key:el.id,value:el.id, text:el.name})
        })
        options.sort((a, b) => (a.text > b.text ? 1 : -1));
        return options;
    }
    const getStyle=() =>{
      if(callingComponent==='homepage') {
      return {position:'absolute', top:isTopAligned ? 0 : null,bottom:isTopAligned ? null : 0, left:isLeftAligned ? 0: null, right:isLeftAligned ? null : 0, zIndex:10,backgroundColor:'white',width:'450px',height:'450px',border:`1px solid ${item.color}`, borderRadius:'20px'}
      // return {position:'absolute', top:10 , left: window.width/2,zIndex:10,backgroundColor:'white',width:'450px',height:'450px',border:`1px solid ${item.color}`, borderRadius:'20px'}
      } else {
        return {position:'absolute', top:60 ,left: 250, zIndex:10,backgroundColor:'white',width:'550px',height:'550px',border:`1px solid ${item.color}`, borderRadius:'20px'}
      }
    }

    return (
        <div style={getStyle()}>
               <div className={styles.wrapper1} style={{ backgroundColor: item.color, paddingLeft: '10px',borderTopLeftRadius:'20px', borderTopRightRadius:'20px' }}>
        <Icon path={mdiArrowLeft} size="20px" onClick={close} style={{cursor:'pointer'}} />
        &nbsp; &nbsp;
        <TextButton
          text='Duplicate block'
          hoverColor="#FFFFFF80"
          iconSize="20px"
          icon={mdiContentCopy}
          iconPosition="left"
          Action={close}
        />
      </div>
      {isLoading ? <Spinner color={color} /> : (
      <div style={{margin:'10px',marginLeft:'35px',marginRight:'35px'}}>
        {callingComponent==='tab' && !isPublicZone ? (
          <div>
            This action will duplicate the <b>Entire Block</b>. If you want to copy just
            one tab, use the tab's action dropdown and choose <b>Duplicate tab</b>.
            <p/><p/>
          </div>
        ):<div>This action will duplicate the <b>Entire Block</b>.</div>}
         <p/><p/>
        <p/><p/>
                  <Form>
            <Form.Input label="DUPLICATED BLOCK NAME"  defaultValue={tabName} onChange={(e,data)=>setTabName(data.value.trim())}  />
            <Form.Dropdown
              search
              label='DESTINATION ZONE'
              options={getOptions()}
              selection
              onChange={(e,data)=>setZoneId(data.value)}
              
              />
          </Form>
          <p/><p/>

        <GBSwitch color={item.color} text="Include data" isChecked={includeData} Action={()=>setIncludeData(!includeData)} /> 
        <div>This will copy existing data for all tabs in the Block.</div>
        <p/><p/>
        <GBSwitch color={item.color} text="Include bookmarks" isChecked={includeBookmarks} Action={()=>setIncludeBookmarks(!includeBookmarks)} /> 
        <div>This will copy existing bookmarks for all tabs in the Block.</div>
        <p/><p/>
        <GBSwitch color={item.color} text="Include web forms" isChecked={includeForms} Action={()=>setIncludeForms(!includeForms)} /> 
        <div>This will copy existing web forms for all tabs in the Block.</div>

        <div  style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent: 'center',marginTop:'30px' }}>
        <TextButton textColor="black" Action={close} ActionValue={false} text="Cancel" hoverColor="#75757580" />
        <div style={{ width: '20px' }} />
        <GBButton Action={verifyLogic} text="Duplicate Block" width="150px" color={item.color} />
      </div>
      </div>
      )}
        </div>
    )

}

// export default onClickOutside(DuplicateBlock,clickOutsideConfig);
// 5-3-2022 Removed from outside click, as we now have pop-up modal for error message and don't want to close this modal too.
// they will need to click the cancel button, or back arrow at top to close.
export default DuplicateBlock;