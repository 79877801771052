import React from 'react';
import { Redirect } from '@reach/router';
import Router from '../../components/Router';
import Page from '../../components/Page';
import SignInPage from './SignIn';
import SignUpPage from './SignUp';
import SignUpInvitePage from './SignUp/SignUpInvite';
import ConfirmInvitePage from './SignUp/ConfirmInvite';
import ForgotPasswordPage from './SignUp/ForgotPassword';
import ShareLoginPage from '../Block/components/Shares/ShareLogin';
import Home from './Home';
import UserProvider from '../../providers/UserProvider';
import { ROUTES } from '../../constants';
import AuthSteps from './AuthSteps';

const Stepper = (props) => (
  <UserProvider>
    <AuthSteps {...props} />
  </UserProvider>
);

const Auth = () => {
  return (
    <Page twin>
      <Router>
        <Home path={ROUTES.AUTH.HOME} />
        <SignInPage path={ROUTES.AUTH.SIGN_IN} />
        <SignUpPage path={ROUTES.AUTH.SIGN_UP} />
        <SignUpInvitePage path={ROUTES.AUTH.SIGN_UP_INVITE} />
        <ConfirmInvitePage path={ROUTES.AUTH.CONFIRM_INVITE} />
        <ForgotPasswordPage path={ROUTES.AUTH.FORGOT_PASSWORD} />
        <ForgotPasswordPage path={ROUTES.AUTH.FORGOT_PASSWORD_CONFIRM} />
        <ShareLoginPage path={ROUTES.AUTH.SHARELOGIN} />
        <Stepper path={ROUTES.AUTH.SIGN_UP_STEPS} />
        <Redirect noThrow from="*" to={ROUTES.REDIRECTION.AUTH} />
      </Router>
    </Page>
  );
};

export default Auth;
