/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiChatProcessing,mdiChatProcessingOutline } from '@mdi/js';

const MessageRender = ({ value, cellWidth, cellHeight, color, rule, field, TD }) => {
  const TagStyle = {
    width: cellWidth,
    height: "30px",
    float: 'left',
    display:'flex',
    flexDirection:'row',
    lineHeight: '1.25',
    marginRight: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  const getDuration = () => {
    // const ms = moment(value[0].addedon)
    const nowdate = new Date();
    // const ms = moment(nowdate.toISOString(),"DD/MM/YYYY HH:mm:ss").diff(moment(value[0].addedon,"DD/MM/YYYY HH:mm:ss"));
    // const d = moment.duration(ms);
    const ms = moment().diff(moment(value[0].addedon), 'minutes');
    if(value[0].schedule !== null){
        return "Scheduled"
    } else if (ms === 0) {
      return 'Now';
    } else if (ms > 0 && ms < 60) {
      return `${ms}M ago`;
    } else if (ms > 60 && ms < 1440) {
      return `${moment().diff(moment(value[0].addedon), 'hours')}H ago`;
    } else if (ms > 1440 && ms <= 10800) {
      return `${moment().diff(moment(value[0].addedon), 'days')}D ago`;
    } else if( ms>10800) {
      return  moment(value[0].addedon).format('DD-MMM-YYYY')
    }
    return ms;
  };

  const CreateText = () => {
   
    if (value !==null && value[0] !== undefined && value[0].messageid !== null) {
      //   return `${getDuration()}: ${value[0].message}`
      return (
     <>
        <div style={{width:'80%',overflow:'hidden',textOverflow: 'ellipsis',marginTop:'3px' }}>
          <b>{getDuration()}:</b> {value[0].message}
        </div>
        <div><Icon path={value[0].tablerowid===null ? mdiChatProcessingOutline : mdiChatProcessing} horizontal={value[0].tablerowid===null} size="20px" color={value[0].schedule ===null ? color : '#B4B3B3'} /></div>
     </>
      );
    } else {
      return '';
    }
    // return value[0] !== undefined ? value[0].message :  '';
  };

  return <div style={TagStyle}>{CreateText()}</div>;
  // return <div style={TagStyle}>hello</div>;
};

MessageRender.propTypes = {
  cellWidth: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
};
export default MessageRender;
