/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import { toast } from 'react-toastify';
import { getZoneAPI } from '../../api/zone';
import TopBar from './TopBar';
import PlanMetrics from './PlanMetrics';
import PlanInformation from './PlanInformation';
import PaymentMethods from './PaymentMethods';
import BlockInformation from './BlockInformation';
import ZoneProperties from './ZoneProperties';
import ZoneMessaging from './ZoneMessaging';
import ZoneUsers from './ZoneUsers';
import PlanSelection from './PlanSelection';
import PaymentSuccess from './PaymentSuccess';
import Invoices from './Invoices';
import InvoiceHistory from './invoiceHistory';
import { stripeGetNextInvoiceDate } from '../../api/zone';
import { AccountContext } from '../../providers/AccountProvider';
import Global from '../../clients/global'

const ZoneSettings = ({ toggleZoneSettings, zoneid, showPlans = false,color }) => {
  const [zoneInfo, setZoneInfo] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [showInfo, setShowInfo] = useState(showPlans);
  const [showPayment, setShowPayment] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showInvoiceHistory, setShowInvoiceHistory] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [billingDate, setBillingDate] = useState('');
  const [startingBalance, setStartingBalance] = useState('');
  const [plan, setPlan] = useState('');
  const {userInfo } = useContext(AccountContext);


  useEffect(() => {
    const getZone = async () => {
      const zoneInfo = await getZoneAPI(parseInt(Global.zoneid));
      setZoneInfo(zoneInfo);
      setZoneName(zoneInfo.name);
      setPlan(zoneInfo.plan);
      // console.log(zoneInfo)
      if (zoneInfo.stripeInfo.subscriptionId !== undefined) {
        const result = await stripeGetNextInvoiceDate(zoneInfo.stripeInfo);
       
        if(typeof result === 'string' && !result.includes('Error occurred')){
        const nextBillingDate = new Date(result.invoice.period_end * 1000);
        setBillingDate(nextBillingDate.toLocaleDateString('en-us'));
        setStartingBalance(result.invoice.starting_balance);
        }
      }
    };
    getZone();

  }, [zoneid]);

  const updateZone= (zInfo) => {
    setZoneName(zInfo.name);
    // zoneInfo.name = name;
    setZoneInfo(zInfo);
  };

  const updateZonePlan = (plan) => {
    
    setPlan(plan);
    setShowInfo(false);
    setShowPayment(true);
  };

  const toggleSettingsLocal = () => {
    userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0].plan=zoneInfo.plan
    toggleZoneSettings()
  }

  const completePayment =  (paymentDetails) => {
    
    // If plan successfully changed, update Zoneplan.plan to reflect updated plan in UI.
    setPaymentDetails(paymentDetails);
    setShowPayment(false);
   
    // 7-19-23 Updating to deal with use-case where trial pro-user downgraded to "Free" plan
    //in this situation, there is no payment/stripe info and we just updated the plan to "free"
    if(paymentDetails.status==='succeeded' && paymentDetails.paymentInfo !==undefined ) {
        let zInfo ={...zoneInfo} // need to create full /deep copy as we are setting planId in sub-object.
        zInfo.plan=paymentDetails.currentPlan.name
        zInfo.stripeInfo.planId=paymentDetails.currentPlan.planId;
        zInfo.stripeInfo.paymentInfo=[paymentDetails.paymentInfo];
        zInfo.userlicenses =paymentDetails.userlicenses;
        setShowInvoice(true);
        setZoneInfo(zInfo)
       } else if(paymentDetails.status==='succeeded' && paymentDetails.userLicenses===-1) {
        let zInfo ={...zoneInfo}
        zInfo.plan = paymentDetails.currentPlan.name;
        setZoneInfo(zInfo)

        location.reload();
        // toast.info(
        //   <div style={{ margin: '10px' }}>
        //    You have successfully changed to the Free plan. In order to see these changes,
        //    click the "reload" button on your browser.
        //   </div>,
        //   {
        //     position: toast.POSITION.BOTTOM_CENTER,
        //     autoClose: false,
        //   },
        // );

       }
  };

  const cancelPayment = () => {
    setShowInfo(true);
    setShowPayment(false);
  };

  const loadBreadCrumb =(page) => {
   
    if(page==='showPayment') {
      setShowPayment(true)
      setShowInvoice(false)
      setShowInfo(false)
      setShowInvoiceHistory(false)
    } else if (page==='showInfo') {
      setShowPayment(false)
      setShowInvoice(false)
      setShowInfo(true)
      setShowInvoiceHistory(false)
    } else if (page==='home') {
      setShowPayment(false)
      setShowInvoice(false)
      setShowInfo(false)
      setShowInvoiceHistory(false)
    }
  }

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  const toggleShowInvoiceHistory = () => {
    setShowInvoiceHistory(!showInvoiceHistory);
  };

  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: 'white',
        left: '100px',
        top: '20px',
        zIndex: 10,
        height: 'calc(100vh - 100px)',
        width: 'calc(100vw - 150px)',
        // border: '1px solid black',
        borderRadius: '10px',
      }}
    >
      <TopBar
        zoneName={zoneName}
        toggleShowInfo={toggleShowInfo}
        toggleShowInvoiceHistory={toggleShowInvoiceHistory}
        toggleZoneSettings={toggleSettingsLocal}
        showInfo={showInfo}
        showPayment={showPayment}
        showInvoice={showInvoice}
        showInvoiceHistory={showInvoiceHistory}
        loadBreadCrumb={loadBreadCrumb}
      />
      {zoneInfo != '' && !showInfo && !showPayment && !showInvoice && !showInvoiceHistory ? (
        <div style={{ display: 'flex', flexDirection: 'row', border:'1px solid #ae007b' }}>
          <div style={{ width: '70%', overflow: 'auto',height: 'calc(100vh - 170px)' }}>
            <ZoneProperties zoneInfo={zoneInfo} updateZone={updateZone} />
            <PlanMetrics zoneInfo={zoneInfo} toggleShowInfo={toggleShowInfo} />
            <div style={{ height: '20px' }}> </div>
            <ZoneMessaging zoneInfo={zoneInfo} color={color} />
            <PaymentMethods zoneInfo={zoneInfo} toggleShowInfo={toggleShowInfo} color={color} />
            <ZoneUsers zoneInfo={zoneInfo} />
            {zoneInfo.stripeInfo.paymentInfo !== undefined ? (
              <Invoices
                zoneInfo={zoneInfo}
                startingBalance={startingBalance}
                toggleShowInvoiceHistory={toggleShowInvoiceHistory}
                color={color}
              />
            ) : null}
          </div>
          <div style={{ width: '25%' }}>
            <BlockInformation zoneInfo={zoneInfo} toggleZoneSettings={toggleSettingsLocal} />
          </div>
        </div>
      ) : null}
      <div style={{border:'1px solid #ae007b'}}>
      {zoneInfo !== '' && showInfo ? (
        <PlanInformation
          zoneInfo={zoneInfo}
          updateZonePlan={updateZonePlan}
          toggleShowInfo={toggleShowInfo}
          billingDate={billingDate}
        />
      ) : null}
      {zoneInfo !== '' && showPayment ? (
        <PlanSelection
          zoneInfo={zoneInfo}
          plan={plan}
          color={color}
          cancelPayment={cancelPayment}
          completePayment={completePayment}
        />
      ) : null}
      {zoneInfo !== '' && showInvoice ? (
        <PaymentSuccess
          paymentDetails={paymentDetails}
          zoneName={zoneInfo.name}
          toggleZoneSettings={toggleSettingsLocal}
        />
      ) : null}
      {zoneInfo !== '' && showInvoiceHistory ? (
        <InvoiceHistory zoneInfo={zoneInfo} toggleShowInvoiceHistory={toggleShowInvoiceHistory} />
      ) : null}
      </div>
    </div>
  );
};

export default ZoneSettings;
