/*
SUMMARY: mph 4-11-2020 
This method is used to export the data from the current grid/table into a standard CSV format. Because of the 
highly dynamic nature of the data, it takes in the tableinfo, filters, and runs query against graphql
and then loops to gerneate output. It uses simliar logic of datashaper in retrieving just the string values
of complex fields lke select fields, relatoinship fields,etc

PARAMS
[TableDetails:json] This is the Tableinfo json from customtables.
[filters: array of filter objects]: If the user has any filters on the grid, these are passed as part of query
[offset: integer]: The offset to use. default is 0 (all records.)

*/
/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';
import moment from 'moment';

const ExportData = async (tableinfo,tableid,filters,offset,blockid,existingData,canUnmask=false,role=0) => {

    //6-8-2022 added the "existingData" param to work for Share downloads. In thise use-case, there is 
    //no need to re-run query..I will just pass in the existing array of data.

    let payload={
        tableinfo,
        tableid,
        blockid,
        limit:10000,
        offset,
        filters,
        sortfield:'id',
        sortorder:'asc',
        callingComponent:'export',
        role:role
      }

        // let tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });       
        // let shapedData = tabledata[0].array;
        // let recordCount = tabledata[0].count;
    let recordCount =0;
    let shapedData='';
    let tabledata='';

    if(existingData !==undefined && existingData !==null) {
      shapedData = existingData
    } else {
        if(tableinfo.tablename==='Users')
          {
            payload.dbfunction='getZoneUsers'
            payload.useCentral=true
            tabledata = await cloudrun.post('/getUsers', { payload });
            recordCount = tabledata[0].count;
            shapedData =tabledata[0].array;
          } else if (tableinfo.tablename==='BlockUsers')
          {
            payload.blockid=blockid;
            tabledata = await cloudrun.post('/getBlockUsersTable', { payload }); 
            recordCount = tabledata.length;
            shapedData =tabledata
          }
          else {
            tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });       
            shapedData = tabledata.rows;
            recordCount = parseInt(tabledata.count);
          }
    }

        let newtable=[];
        shapedData.forEach(dataitem => {
        const shapedItemData = {};
        tableinfo.columns.filter(col=>col.hide===undefined || col.hide===false).forEach(el => {

            if(el.uitype==8 || (el.source !==undefined && el.source.lookupuitype==8)){
              const items = [];
              dataitem && dataitem[el.data] !=null && dataitem[el.data].forEach(itm => {
                if(el.specialfunction!=='unique') {
                   items.push(`${itm.userid}|${itm.firstname} ${itm.lastname}`)
                } else {
                  const idx = items.findIndex(e=>e===`${itm.userid}|${itm.firstname} ${itm.lastname}`);
                  if(idx === -1){
                    items.push(`${itm.userid}|${itm.firstname} ${itm.lastname}`)
                  }
                }
                shapedItemData[[`${el.data}`]] = `"${items.join('|').replace(/"/g, '""')}"`
              })
            }
            else if (el.uitype===7 || el.uitype===6 || el.uitype===1 || (el.uitype===18 && (![4,19,20,22,14].includes(el.source.lookupuitype))) || (el.uitype===18 && el.source.lookupuitype===14 && canUnmask) || el.uitype===16 ) {

              const items = [];
               dataitem && dataitem[el.data] !==null && dataitem[el.data] !==undefined && dataitem[el.data].forEach(itm => {
                  if(itm.data !=='' && itm.data !==null && itm.data !==undefined)  {
                  if(el.specialfunction !=='unique'){
                      items.push(el.fieldType==='date' ? `${moment(itm.data).format(el.dateFormat)}` : itm.data)
                    } else {
                      const idx = items.findIndex(e=>e===itm.data);
                      if(idx === -1  ){
                        items.push(el.fieldType==='date' ? `${moment(itm.data).format(el.dateFormat)}` : itm.data)
                      }
                    }
                  }

                    shapedItemData[[`${el.data}`]] = `"${items.join('|').replace(/"/g, '""')}"`
                })
            } 
            else if (el.uitype===4 || (el.source && el.source.lookupuitype===4)) {
              const items = [];
                  dataitem  && dataitem[el.data] !==null &&  dataitem[el.data].forEach(itm => {
                  items.push(itm.gcsurl)
                  shapedItemData[[`${el.data}`]] = `"${items.join('|').replace(/"/g, '""')}"`
              })
          } 
            else if (el.uitype===24 || el.uitype===25 || el.uitype===26){
                // const textitem = { [`${el.data}`]: `${dataitem[el.data].firstname}  ${dataitem[el.data].lastname}` }
                shapedItemData[[`${el.data}`]] =  `"${dataitem[el.data].userid}|${dataitem[el.data].firstname} ${dataitem[el.data].lastname}"`
            } else if(el.uitype===30) {
              const items = [];
              dataitem  && dataitem[el.data] !==null && dataitem[el.data].forEach(itm => {
                   items.push(`${itm.name}: ${itm.role}`)
                   shapedItemData[[`${el.data}`]] = `"${items.join('|').replace(/"/g, '""')}"`
               })
            } else if(el.uitype===22 || el.uitype===19 || el.uitype===20 || (el.uitype===18 && [19,20,22].includes(el.source.lookupuitype)) ) {
              if(el.uitype !==18) {
                shapedItemData[[`${el.data}`]] =  dataitem[el.data] !==null ? `"${moment(dataitem[el.data]).format(el.dateFormat)}"` :''
              } else {
                const items=[];
                dataitem  && dataitem[el.data] !==null && dataitem[el.data].forEach(itm => {
                  items.push(`${moment(itm.data).format(el.dateFormat)}`)
                  shapedItemData[[`${el.data}`]] = `"${items.join('|').replace(/"/g, '""')}"`
              })
              }

            }
            else if (el.uitype===12) {
                // console.log(dataitem[el.data])
                shapedItemData[[`${el.data}`]] = dataitem[el.data] !==null && dataitem[el.data][0]!==null ? `"${dataitem[el.data][0].message.replace(/"/g, '""')}"` : ''
            } 
            
            //10-2-23 using tinyMCE, html will be output. 
            // else if(el.uitype===13) {
            //   if(dataitem[el.data] !=null) {
            //   const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(dataitem[el.data])));
            //   const txt = editorState.getCurrentContent().getPlainText('\r\n');
            //   const textitem = { [`${el.data}`]: txt.toString().replace(/"/g, '""') }
            //   shapedItemData[[`${el.data}`]] = `"${textitem[el.data]}"`
            //   } else {
            //     const textitem = { [`${el.data}`]: '' }
            //     shapedItemData[[`${el.data}`]] = `"${textitem[el.data]}"`
            //   }
            // } 
  
             else if ((el.uitype===14 || el.source?.lookupuitype===14) && !canUnmask) {
              shapedItemData[[`${el.data}`]] = dataitem[el.data] !==null ? '********' : ''
            }
            
            else {
                const finalitem = dataitem[el.data] !==null ? dataitem[el.data] : ''
                const textitem = { [`${el.data}`]: finalitem.toString().replace(/"/g, '""') }
                shapedItemData[[`${el.data}`]] = `"${textitem[el.data]}"`
            }
        })
        newtable.push(shapedItemData);
    })

    const fieldnames=[];
    tableinfo.columns.filter(el=>el.hide===undefined || el.hide===false).forEach(col => {
      fieldnames.push({id: col.data, displayName: col.header.replaceAll(',','')})
    })

    const exportResult ={columns:fieldnames, datas: newtable, count: existingData !==undefined ? existingData.length : recordCount}
    return exportResult;
}

export default ExportData