/* eslint-disable */
import React, { useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AccountContext } from '../../providers/AccountProvider';
import { addZoneAPI, updateZoneAPI } from '../../api/zone';

const calculatePosForZones = (zones) => {
  const zonesList = [...zones];
  zonesList.sort((a, b) => a.pos - b.pos);
  let pos = zonesList[zonesList.length - 1].pos * 2;
  if (zonesList.length > 1) {
    pos = zonesList[zonesList.length - 1].pos + zonesList[zonesList.length - 2].pos;
  }
  if (pos < 30000) {
    pos = 30000;
  }
  return pos;
};

const withAPI = (Component) => {
  return () => {
    const { signOut, userInfo, updateUserZones } = useContext(AccountContext);
    const { zones } = userInfo;

    const handleChangeZone = useCallback(
      (current) => {
        const list = [...zones];
        const currentIndex = list.findIndex((z) => z.token === current.token);
        if (currentIndex >= 0) {
          list.splice(currentIndex, 1, { ...list[currentIndex], pos: current.pos });
        }
        updateUserZones([...list]);
        updateZoneAPI(current);
      
      },
      [updateUserZones, zones],
    );

    const handleAddZone = useCallback(
      (name) => {
        const token = uuidv4();
        const pos = calculatePosForZones(zones);
        const newZones = [
          ...zones,
          {
            name,
            users: 0,
            blocks: 0,
            pos,
            token,
            role:'Zone owner',
            plan:'Free',
          },
        ];
        updateUserZones(newZones);
        addZoneAPI({ name, pos, token,email:userInfo.email }).then(({ id }) => {
          const list = [...newZones];
          const currentIndex = list.findIndex((z) => z.token === token);
          if (currentIndex >= 0) {
            list.splice(currentIndex, 1, { ...list[currentIndex], id });
          }
          updateUserZones([...list]);
        });
      },
      [zones, updateUserZones],
    );

    return (
      <Component
        zones={zones}
        signOut={signOut}
        onChangeZone={handleChangeZone}
        onAddZone={handleAddZone}
      />
    );
  };
};

export default withAPI;
