/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Content from '../../../components/Content';
import Spinner from '../../../components/Spinner/Spinner';

const {
  Header: { Title },
  Container,
} = Content;

const BlocksLoader = memo(({ zone }) => {
  return (
    <>
      <Title>{zone ? zone.name : ''}</Title>
      <Spinner isLoading color="#AE007B" />
    </>
  );
});

BlocksLoader.defaultProps = {
  zone: null,
};

BlocksLoader.propTypes = {
  zone: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default BlocksLoader;
