/* eslint-disable */
import React, { useEffect, useState } from 'react';
import prettyJson from './prettyJSON';

const Fields = ({ tableData, block, records, tableOptions, relationTables }) => {
  // const stringColor ='#FFBE24'
  const stringColor = '#fde1ab';
  const numberColor = '#ff447e';
  useEffect(() => {}, []);

  const getTableName = (tableid) => {
    const tblname = relationTables.filter((el) => el.id === parseInt(tableid))[0].name;
    return tblname;
  };

  const lookupFieldName = (tableid, lookupfield, type) => {
    const relTable = relationTables.filter((el) => el.id === parseInt(tableid))[0];
    const textfield = relTable.tableinfo.columns.filter(
      (el) =>
        el.displayfield == lookupfield.replace('_options', '') ||
        el.data == lookupfield.replace('_options', ''),
    )[0].header;

    const finalString = (
      <div>
        {type} value of
        <span style={{ backgroundColor: `${block.color}1A`, padding: '3px', borderRadius: '10px' }}>
          {textfield}
        </span>{' '}
        fields in related{' '}
        <span style={{ backgroundColor: `${block.color}1A`, padding: '3px', borderRadius: '10px' }}>
          {relTable.name}
        </span>{' '}
        records
      </div>
    );

    return finalString;
  };

  const renderExampleData = (col, dataArray) => {
    const field = col.data;
    const uitype = col.uitype;
    const lookup = col.lookup;

    if ([2, 3, 13, 9, 10, 11, 14, 19, 20, 22].includes(uitype) && dataArray.length > 0) {
      return (
        <>
          {dataArray.map((el) =>
            el !== null && el !== undefined ? (
              <div style={{ color: stringColor }}>"{el.toString()}"</div>
            ) : (
              <div style={{ color: stringColor }}>"Sample data"</div>
            ),
          )}
        </>
      );
    } 
    // else if (uitype === 13 && dataArray.length > 0) {
    //   return (
    //     <>
    //       {dataArray.map((el) => {
    //         const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(el)));
    //         const txt = editorState.getCurrentContent().getPlainText('\u0001');
    //         return el !== null && el !== undefined ? (
    //           <div style={{ color: stringColor }}>"{txt}"</div>
    //         ) : (
    //           <div style={{ color: stringColor }}>"Sample data"</div>
    //         );
    //       })}
    //     </>
    //   );
    // } 
    else if (
      [12, 24, 25, 8, 1, 4].includes(uitype) ||
      (uitype === 18 && ['all', 'unique', 'lookup'].includes(col.specialfunction))
    ) {
      const tempArray = dataArray.filter((el) => el !== null && el !== undefined);
      if (tempArray.length > 0) {
        const final =
          '<pre style="color:white">' + prettyJson(JSON.stringify(tempArray, null, 2)) + '</pre>';
        return <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>;
      } else {
        return <div style={{ color: stringColor }}>"Need sample data"</div>;
      }
    } else if ((uitype === 18 && !['all', 'unique', 'lookup'].includes(col.specialfunction) || col.uitype===16)) {
      const isNumber = ['count', 'countdistinct', 'avg', 'sum', 'nummin', 'nummax','median'].includes(
        col.specialfunction,
      ) || col.fieldType==='numeric';

      return (
        <>
          {dataArray.map((el) =>
            el !== null && el !== undefined ? (
              <div style={{ color: isNumber ? numberColor : stringColor }}>
                {isNumber ? el.toString() : `"${el}"`}
              </div>
            ) : (
              <div style={{ color: numberColor }}>Sample number</div>
            ),
          )}
        </>
      );
    } else if ([12, 24, 25, 8, 1, 4, 18].includes(uitype)) {
      const tempArray = dataArray.filter((el) => el !== null && el !== undefined);
      if (tempArray.length > 0) {
        const final =
          '<pre style="color:white">' + prettyJson(JSON.stringify(tempArray, null, 2)) + '</pre>';
        return <div spellcheck="false" dangerouslySetInnerHTML={{ __html: final }}></div>;
      } else {
        return <div style={{ color: stringColor }}>"Need sample data"</div>;
      }
    } else if ((uitype === 23 && col.data !== 'id') || uitype === 17 || uitype === 5) {
      return (
        <>
          {dataArray.map((el) =>
            el !== null && el !== undefined ? (
              <div style={{ color: numberColor }}>{el.toString()}</div>
            ) : (
              <div style={{ color: numberColor }}>Sample number</div>
            ),
          )}
        </>
      );
    } else if (uitype === 7 || (uitype === 6 && tableOptions.length > 0)) {
      const options = tableOptions.filter((el) => el.field === field)[0].data;
      if (options.length === 0) {
        return '[]';
      }
      return (
        <>
          <div>Possible values</div>[
          {options.map((el) => (
            <div style={{ color: stringColor }}>"{el.option}"</div>
          ))}
          ]
        </>
      );
    } else {
      return 'Example data';
    }
  };

  return (
    <div style={{ width: '100%', scrollMargin: '20px' }} id="fields">
      <div style={{ color: block.color, fontSize: '24px' }}>Fields in the {tableData.name} tab</div>
      <div
        style={{
          paddingLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <div style={{ width: '10%', fontWeight: 'bold', padding: '10px' }}>Field Name</div>
        <div style={{ width: '10%', fontWeight: 'bold', padding: '10px' }}>Field ID</div>
        <div style={{ width: '10%', fontWeight: 'bold', padding: '10px' }}>Type</div>
        <div style={{ width: '30%', fontWeight: 'bold', padding: '10px' }}>Description</div>
        <div style={{ width: '40%', fontWeight: 'bold', padding: '10px' }}>Example data</div>
      </div>
      {records.length > 0 &&
        tableData.tableinfo.columns
          .filter((el) => el.data !== 'id')
          .map((col) => (
            <div
              key={records[col.id]}
              style={{
                paddingLeft: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                borderBottom: '1px solid #eee',
              }}
            >
              <div style={{ width: '10%', padding: '10px' }}>
                <span
                  style={{
                    backgroundColor: `${block.color}1A`,
                    padding: '5px',
                    borderRadius: '10px',
                    lineHeight: 2.3,
                  }}
                >
                  {col.data !== 'id' ? col.header : 'CustomID'}
                </span>
              </div>
              <div style={{ width: '10%', color: block.color, padding: '10px' }}>
                {' '}
                {col.data !== 'id' ? col.data : 'CustomID'}
              </div>
              <div style={{ width: '10%', padding: '10px' }}>
                {col.uitype === 2 ? 'Text' : ''}
                {col.uitype === 13 ? 'Text' : ''}
                {col.uitype === 14 ? 'Text' : ''}
                {col.uitype === 3 ? 'Long Text' : ''}
                {col.uitype === 24 ? 'Added by' : ''}
                {col.uitype === 25 ? 'Updated by' : ''}
                {col.uitype === 19 ? 'Added On' : ''}
                {col.uitype === 20 ? 'Updated On' : ''}
                {col.uitype === 4 && col.lookup === 'multiple' ? 'Multiple attachment' : ''}
                {col.uitype === 4 && col.lookup === 'single' ? 'Single attachment' : ''}
                {col.uitype === 5 ? 'Checkbox' : ''}
                {col.uitype === 6 ? 'Multi select' : ''}
                {col.uitype === 7 ? 'Single select' : ''}
                {col.uitype === 8 && col.lookup === 'multiple' ? 'Multiple Collaborator' : ''}
                {col.uitype === 8 && col.lookup === 'single' ? 'Single Collaborator' : ''}
                {col.uitype === 9 ? 'Phone' : ''}
                {col.uitype === 10 ? 'Email' : ''}
                {col.uitype === 11 ? 'Url' : ''}
                {col.uitype === 12 ? 'Message' : ''}
                {col.uitype === 18 ? 'Lookup' : ''}
                {col.uitype === 16 ? 'Formula' : ''}
                {col.uitype === 22 ? 'Date' : ''}
                {col.uitype === 23 && col.data !== 'id' ? 'Number' : ''}
                {col.uitype === 17 ? 'Counter' : ''}
                {col.uitype === 1 && col.lookup === 'single' ? 'Single relational' : ''}
                {col.uitype === 1 && col.lookup === 'multiple' ? 'Multiple relational' : ''}
                {/* {col.data=='id' ? 'Relational Identifider' : ''} */}
              </div>
              <div style={{ width: '30%', height: '100%', padding: '10px' }}>
                {col.uitype === 2 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>Single text string</div>
                  </div>
                ) : (
                  ''
                )}
                {col.uitype === 13 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>HTML that is converted to plain text string</div>
                  </div>
                ) : (
                  ''
                )}
                {col.uitype === 3 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>Multiple lines of text</div>
                  </div>
                ) : (
                  ''
                )}
                {col.uitype === 24 ? (
                  <div>
                    <div style={{ color: 'green' }}>User object</div>
                    <div>User who created this record</div>
                    <div>
                      <b>userid</b> : <span style={{ color: 'green' }}>integer</span> : unique user
                      id
                    </div>
                    <div>
                      <b>firstname</b> : <span style={{ color: 'green' }}>string</span> : users
                      first name
                    </div>
                    <div>
                      <b>lastname</b> : <span style={{ color: 'green' }}>string</span> : users last
                      name
                    </div>
                    <div>
                      <b>email</b> : <span style={{ color: 'green' }}>string</span> : users email
                    </div>
                    <div>
                      <b>mobile</b> : <span style={{ color: 'green' }}>string</span> : users mobile
                      phone
                    </div>
                    <div>
                      <b>addedon</b> : <span style={{ color: 'green' }}>string</span>: Date/time
                      user was added to system
                    </div>
                  </div>
                ) : null}
                {col.uitype === 25 ? (
                  <div>
                    <div style={{ color: 'green' }}>User object</div>
                    <div>User who updated this record</div>
                    <div>
                      <b>userid</b> : <span style={{ color: 'green' }}>integer</span> : unique user
                      id
                    </div>
                    <div>
                      <b>firstname</b> : <span style={{ color: 'green' }}>string</span> : users
                      first name
                    </div>
                    <div>
                      <b>lastname</b> : <span style={{ color: 'green' }}>string</span> : users last
                      name
                    </div>
                    <div>
                      <b>email</b> : <span style={{ color: 'green' }}>string</span> : users email
                    </div>
                    <div>
                      <b>mobile</b> : <span style={{ color: 'green' }}>string</span> : users mobile
                      phone
                    </div>
                    <div>
                      <b>addedon</b> : <span style={{ color: 'green' }}>string</span>: Date/time
                      user was added to system
                    </div>
                  </div>
                ) : null}
                {col.uitype === 8 && col.lookup === 'single' ? (
                  <div>
                    <div style={{ color: 'green' }}>User object</div>
                    <div>A single User object</div>
                    <p />
                    <p />
                    <div>
                      <b>userid</b> : <span style={{ color: 'green' }}>integer</span> : unique user
                      id
                    </div>
                    <div>
                      <b>firstname</b> : <span style={{ color: 'green' }}>string</span> : users
                      first name
                    </div>
                    <div>
                      <b>lastname</b> : <span style={{ color: 'green' }}>string</span> : users last
                      name
                    </div>
                    <div>
                      <b>email</b> : <span style={{ color: 'green' }}>string</span> : users email
                    </div>
                    <div>
                      <b>mobile</b> : <span style={{ color: 'green' }}>string</span> : users mobile
                      phone
                    </div>
                    <div>
                      <b>addedon</b> : <span style={{ color: 'green' }}>string</span>: Date/time
                      user was added to system
                    </div>
                  </div>
                ) : null}
                {col.uitype === 8 && col.lookup === 'multiple' ? (
                  <div>
                    <div style={{ color: 'green' }}>array of user objects</div>
                    <div>Multiple users assigned as collaborators on this tab</div>
                    <p />
                    <p />
                    <div>
                      <b>userid</b> : <span style={{ color: 'green' }}>integer</span> : unique user
                      id
                    </div>
                    <div>
                      <b>firstname</b> : <span style={{ color: 'green' }}>string</span> : users
                      first name
                    </div>
                    <div>
                      <b>lastname</b> : <span style={{ color: 'green' }}>string</span> : users last
                      name
                    </div>
                    <div>
                      <b>email</b> : <span style={{ color: 'green' }}>string</span> : users email
                    </div>
                    <div>
                      <b>mobile</b> : <span style={{ color: 'green' }}>string</span> : users mobile
                      phone
                    </div>
                    <div>
                      <b>addedon</b> : <span style={{ color: 'green' }}>string</span>: Date/time
                      user was added to system
                    </div>
                  </div>
                ) : null}
                {col.uitype === 4 && col.lookup === 'single' ? (
                  <div>
                    <div style={{ color: 'green' }}>attachment object</div>
                    <p />
                    <p />
                    <div>Each attachment object may contain the following properties: </div>
                    <div>
                      <b>url</b> : <span style={{ color: 'green' }}>string</span> : Unique Url to
                      the attachment
                    </div>
                    <div>
                      <b>filename</b> : <span style={{ color: 'green' }}>string</span> : The file
                      name
                    </div>
                    <div>
                      <b>size</b> : <span style={{ color: 'green' }}>number</span> : The size of the
                      file in Gigabytes
                    </div>
                    <div>
                      <b>uploadtype</b> : <span style={{ color: 'green' }}>string</span> : Method of
                      how attachment was added
                    </div>
                    <div>
                      <b>category</b> : <span style={{ color: 'green' }}>string</span> : (optional:
                      category associated with attachment)
                    </div>
                  </div>
                ) : null}
                {col.uitype === 4 && col.lookup === 'multiple' ? (
                  <div>
                    <div style={{ color: 'green' }}>array of attachment objects</div>
                    <p />
                    <p />
                    <div>Each attachment object may contain the following properties: </div>
                    <div>
                      <b>url</b> : <span style={{ color: 'green' }}>string</span> : Unique Url to
                      the attachment
                    </div>
                    <div>
                      <b>filename</b> : <span style={{ color: 'green' }}>string</span> : The file
                      name
                    </div>
                    <div>
                      <b>size</b> : <span style={{ color: 'green' }}>number</span> : The size of the
                      file in Gigabytes
                    </div>
                    <div>
                      <b>uploadtype</b> : <span style={{ color: 'green' }}>string</span> : Method of
                      how attachment was added
                    </div>
                    <div>
                      <b>category</b> : <span style={{ color: 'green' }}>string</span> : (optional:
                      category associated with attachment)
                    </div>
                  </div>
                ) : null}
                {col.uitype === 1 && col.lookup === 'single' ? (
                  <div>
                    <div style={{ color: 'green' }}>relational object</div>
                    <div>
                      Relational object from the{' '}
                      <span
                        style={{
                          backgroundColor: `${block.color}1A`,
                          padding: '3px',
                          borderRadius: '10px',
                        }}
                      >
                        {getTableName(col.source.lookuptableid)}
                      </span>{' '}
                      table
                    </div>
                    <p />
                    <p />
                    <div>Each relational object will contain the following properties:</div>
                    <div>
                      <b>id</b> : <span style={{ color: 'green' }}>integer</span> : Unique id of the
                      related record
                    </div>
                    <div>
                      <b>data</b> : <span style={{ color: 'green' }}>string</span> : The relational
                      identifier of the related record
                    </div>
                    <p />
                    <p />
                    When adding/updating records, you can use either value interchangeably; however,
                    we strongly recommend you use the "id" value as it will always be unique and
                    will not change over time, where as the "data" (relational identifier) can
                    change at any time.
                  </div>
                ) : null}
                {col.uitype === 1 && col.lookup === 'multiple' ? (
                  <div>
                    <div style={{ color: 'green' }}>array of relational objects</div>
                    <div>
                      Array of linked records IDs from the{' '}
                      <span
                        style={{
                          backgroundColor: `${block.color}1A`,
                          padding: '3px',
                          borderRadius: '10px',
                        }}
                      >
                        {getTableName(col.source.lookuptableid)}
                      </span>{' '}
                      table
                    </div>
                    <p />
                    <p />
                    <div>Each relational object will contain the following properties:</div>
                    <div>
                      <b>id</b> : <span style={{ color: 'green' }}>integer</span> : Unique id of the
                      related record
                    </div>
                    <div>
                      <b>data</b> : <span style={{ color: 'green' }}>string</span> : The relational
                      identifier of the related record
                    </div>
                    <p />
                    <p />
                    When adding/updating records, you can use either value interchangeably; however,
                    we strongly recommend you use the "id" value as it will always be unique and
                    will not change over time, where as the "data" (relational identifier) can
                    change at any time.
                  </div>
                ) : null}
                {col.uitype === 12 ? (
                  <div>
                    <div style={{ color: 'green' }}>message object</div>
                    <p />
                    <p />
                    <div>Each message object will contain the following properties:</div>
                    <div>
                      <b>message</b> : <span style={{ color: 'green' }}>string</span> : text message
                      sent or received
                    </div>
                    <div>
                      <b>addedon</b> : <span style={{ color: 'green' }}>string</span> : ISO date
                      string of when message sent/recieved
                    </div>
                    <div>
                      <b>direction</b> : <span style={{ color: 'green' }}>string</span> : Either
                      "incoming" or "outgoing".{' '}
                    </div>
                    <p />
                    <p />
                    This is the LAST text message either sent or received from this record.
                  </div>
                ) : null}
                {col.uitype === 19 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>The time the record was created in UTC</div>
                  </div>
                ) : null}
                {col.uitype === 7 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>Selected option name</div>
                    <p />
                    <div>
                      When creating or updating records, if the choice string does not exactly match
                      an existing option (case sensative), the data will be ignored.
                    </div>
                  </div>
                ) : null}
                {col.uitype === 6 ? (
                  <div>
                    <div style={{ color: 'green' }}>array of strings</div>
                    <div>Array of selected option names</div>
                    <p />
                    <div>
                      When creating or updating records, if the choice string does not exactly match
                      an existing option (case sensative), the data will be ignored.
                    </div>
                  </div>
                ) : null}
                {col.uitype === 10 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>A valid email address</div>
                  </div>
                ) : null}
                {col.uitype === 11 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>A valid URL (e.g. graceblocks.com or https://my.graceblocks.com)</div>
                  </div>
                ) : null}
                {col.uitype ===14 ? (
                  <div>
                  <div style={{ color: 'green' }}>string</div>
                  <div>A masked string value. If you have permission, you will see full value otherwise will appear with mask.</div>
                </div>
                ): null}
                {col.uitype === 9 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>A valid phone number in E.164 format</div>
                  </div>
                ) : null}
                {col.uitype === 22 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>A valid date in ISO 8601 format</div>
                  </div>
                ) : null}
                {col.uitype === 5 ? (
                  <div>
                    <div style={{ color: 'green' }}>boolean</div>
                    <div>This field is "true" when checked or "false"</div>
                    <div>Empty value indicates "false" </div>
                  </div>
                ) : null}

                {col.uitype === 20 ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>The time the record was last updated in UTC</div>
                  </div>
                ) : null}
                {col.fieldType==='numeric' ? (
                   <div>
                   <div style={{ color: 'green' }}>number</div>
                   <div>A formula that calculates a numeric value</div>
                   <div>{col.formula}</div>
                 </div>
                ): null}
                {col.fieldType==='date' ? (
                   <div>
                   <div style={{ color: 'green' }}>date</div>
                   <div>A formula that calculates a date value</div>
                   <div>{col.formula}</div>
                 </div>
                ): null}
                {col.fieldType==='text' ? (
                   <div>
                   <div style={{ color: 'green' }}>string</div>
                   <div>A formula that calculates a string value</div>
                   <div>{col.formula}</div>
                 </div>
                ): null}
                {col.uitype === 23 && col.data !== 'id' ? (
                  <div>
                    <div style={{ color: 'green' }}>
                      {col.numericFormat.pattern.includes('$')
                        ? 'Currency number'
                        : col.numericFormat.pattern.includes('%')
                        ? 'Percentage number'
                        : 'General number'}{' '}
                    </div>
                    {col.minValue !== undefined && col.maxValue !== undefined ? (
                      <div>
                        <div>Min value: {col.minValue}</div>
                        <div>Max value: {col.maxValue}</div>
                      </div>
                    ) : null}
                    <div>A number formatted per above</div>
                  </div>
                ) : null}
                {col.uitype === 18 && ['all', 'unique', 'lookup'].includes(col.specialfunction) ? (
                  <div>
                    <div style={{ color: 'green' }}>
                      array of numbers, strings, booleans, or objects
                    </div>
                    <div>
                      {lookupFieldName(col.source.lookuptableid, col.source.lookupfield, 'array')}
                    </div>
                  </div>
                ) : null}
                {col.uitype === 18 &&
                ['count', 'countdistinct', 'sum', 'avg', 'nummin', 'nummax'].includes(
                  col.specialfunction,
                ) ? (
                  <div>
                    <div style={{ color: 'green' }}>number</div>
                    <div>
                      {lookupFieldName(col.source.lookuptableid, col.source.lookupfield, 'number')}
                    </div>
                  </div>
                ) : null}
                {col.uitype === 18 &&
                ['min', 'max', 'newest', 'oldest', 'mostrecent'].includes(col.specialfunction) ? (
                  <div>
                    <div style={{ color: 'green' }}>string</div>
                    <div>
                      {lookupFieldName(col.source.lookuptableid, col.source.lookupfield, 'string')}
                    </div>
                  </div>
                ) : null}

                {col.uitype === 17 ? (
                  <div>
                    <div style={{ color: 'green' }}>integer</div>
                    Sequential counter field.
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  backgroundColor: '#303336',
                  padding: '10px',
                  color: 'white',
                  width: '40%',
                  alignSelf: 'stretch',
                }}
              >
                {renderExampleData(
                  col,
                  records.map((el) => el[col.header]),
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Fields;
