/* eslint-disable  */

/** @jsxImportSource @emotion/react */
import React from 'react'
import { css, jsx } from '@emotion/core'
import Icon from '@mdi/react';
import { Popup} from 'semantic-ui-react';


const IconButton = ({color,hoverColor,hoverBackgroundColor,icon,Action,ActionValue,tooltip,size="15px",border=null}) => {
    return (
        <div
              css={{
                border:border,
                width: 'max-content',
                display: 'inline',
                cursor:'pointer',
                color,
                paddingRight: '3px',
                '&:hover': {
                 svg: {
                    color: hoverColor,
                    backgroundColor: hoverBackgroundColor,
                    borderRadius: '15px',
                    transition: 'all .3s ease',
                  },
                },
              }}
            >
               <Popup
           disabled={tooltip===undefined}
           content={tooltip}
           trigger={
              <Icon path={icon} size={size} onClick={Action!== undefined ? ()=>Action(ActionValue) : null} />
           }/>
            </div>
    )
}

export default IconButton