/* eslint-disable */
import firebase from '../clients/firebase';
import { v4 as uuidv4 } from 'uuid';
import cloudrun from '../clients/httpcloudrun';
import axios from 'axios';
import {updateUserAttributes} from './users';


export const uploadAvatar = async (image,attributes) => {

    const ext = image.name.split('.').pop();
    const avatarFileName =`${uuidv4()}.${ext}`
    let progress=0;
    let url=''

    try {
        await firebase.storage().ref(`users/${avatarFileName}`).put(image);
        url = await firebase.storage().ref("users").child(avatarFileName).getDownloadURL();
    } catch(error) {
        return error.message
    }
    
    // delete the old image
    if(attributes.avatarFileName !== undefined) {
        var desertRef = firebase.storage().ref(`users/${attributes.avatarFileName}`);
        desertRef.delete()
    }

    attributes.avatar = url;
    attributes.avatarFileName=avatarFileName;
    await updateUserAttributes(attributes)
    return url;

    }

    export const uploadAnonymousFiles = async (schema,files,onUploadProgress=null) =>{

        /* 2-21-23
          Updated to deal with CloudRun limitation of 32MB request limit. Here we call api to get a 
          Signed URL for a file, which we can then directly Post to Google Storage, instead of going
          through Cloudrun...and effectively remove any limit on file size. It also appears to be much
          faster in posting direct. Special header is used to designate the file as public read.
        */
        const { REACT_APP_API_CLOUDRUN_URL, REACT_APP_API_KEY } = process.env;
        let fileInfo=[];
        var formData = new FormData();
        files.forEach(itm=>{
            fileInfo.push({name:itm.name,size:itm.size})
        })

        formData.append('ReactAPIKey',REACT_APP_API_KEY)
        formData.append('schema',schema)
        formData.append('fileInfo',JSON.stringify(fileInfo));

        const config1 = {
            headers: {
                'content-type': 'application/json'
            },
        };

        // const fileUrls = await axios.post(`${REACT_APP_API_CLOUDRUN_URL}/uploadAnonymousFiles`,formData,config)

        // We call API with file info to get returned set of signedurls
        const fileUrls = await axios.post(`${REACT_APP_API_CLOUDRUN_URL}/getSignedUrl`,formData,config1)

        const loadFiles = async () => {
        return Promise.all(
                fileUrls.data && files.map(async file =>{
                const idx = fileUrls.data.findIndex(f=>f.filename===file.name)
            
                if(idx !==-1) {
                    const config = {
                        headers: {
                            'content-type': file.type,
                            'x-goog-acl': 'public-read'
                        },
                        onUploadProgress
                    };
                    try{
                    await axios.put(fileUrls.data[idx].signedUrl, file, config)
                    } catch(error) {
                        console.log(error)
                    }
                }
            })
        )
    }
        await loadFiles()
        return fileUrls.data
    }

    export const deleteAnonymousFile = async (schema,guidname) =>{
        const { REACT_APP_API_CLOUDRUN_URL,REACT_APP_API_KEY } = process.env;
        
        const payload={schema,guidname,ReactAPIKey:REACT_APP_API_KEY}
        const result = await axios({
            url: `${REACT_APP_API_CLOUDRUN_URL}/deleteFile`,
            headers: { 'Content-Type': 'application/json' },
            method: 'post',
            data: {
                payload,
            },
        });

        return result.data;
    }

    export const uploadFiles = async (schema,files,type) => {

        // https://googleapis.dev/nodejs/storage/latest/Bucket.html#upload

        // example of GC Storage URL
        // https://storage.googleapis.com/graceblocks-4efeb.appspot.com/customers/schema_1/attachments/234fac33-18a4-48a7-a769-661fdf4de463.png

        const { REACT_APP_FIREBASE_STORAGE_BUCKET,REACT_APP_GRACEBLOCKS_API_KEY } = process.env;

        let fileUrls=[];
        let filePaths=[];

        const upload = async () => {
            return Promise.all(
                files.map(async file =>{
                    const ext = file.name.split('.').pop().toLowerCase();
                    const mediaFileName =`${uuidv4()}.${ext}`
                    const GCSURL =`https://storage.googleapis.com/${REACT_APP_FIREBASE_STORAGE_BUCKET}/customers/${schema}/${type}/${mediaFileName}`
                    let fileRef = firebase.storage().ref(`customers/${schema}/${type}/${mediaFileName}`);
                    await fileRef.put(file);

                   const fileURL = await fileRef.getDownloadURL();

                   fileUrls.push({filename:file.name,url:fileURL,gcsurl:GCSURL,guidname:mediaFileName,size:file.size*.000000001,uploadtype:'desktop'});
                   filePaths.push(`customers/${schema}/${type}/${mediaFileName}`);
               })
            )
        }

    await upload()

         // make files publically avaible via GCF Bucket URL format.
        let payload={filePaths};
        const dataresponse = await cloudrun.post('/makeFilesPublic', {payload})
        return fileUrls
    }

    export const deleteFile = async (schema,tableid,field,filename,type,caller='app') => {
        
        //Determine if file is referenced anywhere else in tab/field. If yes, don't delete the file, as we need to keep it for other references
        // 4-8-2021 added the "caller" attribute as this action could be happening within the app
        // or from a form.

        // 1-26-24 Commenting out  deleting the file, we want to provide a solution which would
        //let clients restore files they delelte, so for now, we'll keep them on Google Storage
        // and then set a task to delete them in 60 days. need to add the logic to trigger
        // task for deletion in 60 days.

        // if(type !=='messages' && caller==='app') {
        //     const payload={tableid,field,filename,dbfunction:'checkAttachmentFileExists'};
        //     const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

        //     if(dataresponse.length===1)
        //     {
        //         var desertRef = firebase.storage().ref(`customers/${schema}/${type}/${filename}`);
        //         const result = await desertRef.delete()
        //     } 
        // } else {
        //     var desertRef = firebase.storage().ref(`customers/${schema}/${type}/${filename}`);
        //     const result = await desertRef.delete()
        // }        
        return "success"
    }

    export const webImageSearch = async (searchTerm,offset) => {
        const payload={searchTerm,offset};
        const dataresponse = await cloudrun.post('/webImageSearch', {payload})
        return dataresponse
    }
    

    export const saveAttachments = async (id,table,field,value,userid,tableinfo) => {
        
        let header='header';
        const idx = tableinfo.columns.findIndex(el=>el.data===field)
        if(idx !==-1) {
            header = tableinfo.columns[idx].header;
        }
        const payload={id,table,field,header,value,userid,tableinfo}
        // console.log(tableinfo)
        const result = await cloudrun.post('/addAttachments', {payload})
        return "success";
    }

    // 7-21-22 added this method so that individual changes to an attachment's name or category
    // will be updated separately and we won't record this in history.
    export const updateAttachment = async (id,tableid,field,url,optionid,name) =>{
        const payload={id,tableid,field,url,optionid,name,dbfunction:'updateAttachment'}
        const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
        return "success";

    }