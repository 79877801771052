/* eslint-disable */
import React from 'react';
import GBLogo from './Powered.svg';
import firebase from '../../../../clients/firebase';
import { navigate } from '@reach/router';

const Footer = ({ footerColors, footerText, includeLogo,width,login }) => {

  //1-3-24
  //added logic so that a logged in form user can click the GB logo and have them logged out.
  //We added this to support testing of having logged in form users and being able to test differetnt ones from mobile.

  const checkUser=()=>{
    const user = firebase.auth().currentUser;
    if(user!=null && login==='mandatory') {
      firebase.auth().signOut();
    }
    window.location.href='https://www.graceblocks.com';
  }

  return (
    <div
      style={{
        // marginTop: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        zIndex:1,
        backgroundColor: footerColors !== undefined ? footerColors.attributes.color : '#F9F9F9',
        // height: includeLogo && footerText !==undefined ? '450px' : includeLogo && footerText===undefined ? '400px' : '500px',
        // height:'450px'
        paddingTop: footerText !==undefined ? '0px' : '30px'
        // paddingTop: footerText !==undefined && includeLogo ? '50px' : footerText===undefined & includeLogo ? '200px' : null,
        // paddingBottom:'200px'
      }}
    >
      {footerText !== undefined ? (
        <div
          style={{
            paddingTop: includeLogo ? '20px' : '30px',
            display: 'flex',
            height:'100%',
            textAlign:'center',
            flexDirection: 'column',
            alignItems: 'center',
            color: footerColors !== undefined ? footerColors.attributes.text : 'black',
            // paddingBottom:  includeLogo ? '200px' : null,
            justifyContent:includeLogo ? 'flex-start' : 'center',
            paddingBottom:'30px',
            // maxWidth:`${width}px`,
            // width:'1100px',
            width:screen.width < 500 ? screen.width-10 : `${width}px`,
            whiteSpace:'pre-wrap'
          }}
        >
          {footerText}
        </div>
      ) : null}

        {includeLogo ? (<>
        <div  style={{ backgroundColor: 'black', width: '180px', padding: '10px', borderRadius: '10px',cursor:'pointer' }}>
          {/* <a href="https://www.graceblocks.com" target="_newwindow"><img src={GBLogo} height="30px" /></a> */}
          <img onClick={checkUser} src={GBLogo} height="30px" />         
        </div>
        <div style={{paddingBottom:'30px'}}>
        </div>
      </>): null}

    </div>
  );
};

export default Footer;
