/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { mdiFileDocument, mdiTrashCan } from '@mdi/js';

import Icon from '@mdi/react';

const FormAttachment = ({ field, filename, guidname, deleteFile }) => {
  return (
    <div
      style={{
        color: '#1A1A1A',
        display: 'flex',
        border: '1px dashed #757575',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Icon path={mdiFileDocument} size="30px" />
        <div style={{ width: '10px' }}></div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div>{filename.slice(0,25)}</div>
          <div>successfully uploaded</div>
          <div>To remove, click the trash can icon</div>
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginRight: '10px',
          marginBottom: '5px',
          justifyContent: 'space-between',
          
        }}
      ><div></div>
       <div css={{color:'#1A1A1A',
          '&:hover' : {
              svg: {
                  color:'#E06055'
              }
          }}}><Icon css={{cursor:'pointer'}} onClick={() => deleteFile(field, guidname)} path={mdiTrashCan} size="30px" /></div>
      </div>
    </div>
  );
};

export default FormAttachment;
