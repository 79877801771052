/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import NativeListener from 'react-native-listener';
import {Dimmer,Loader} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { mdiArrowExpandAll, mdiPaperclip, mdiClose } from '@mdi/js';
import { BaseEditorComponent } from '@handsontable/react';
import Icon from '@mdi/react';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import { uploadFiles, deleteFile,updateAttachment } from '../../../../api/images';
import AttachmentViewer from '../Attachments/AttachmentViewer';
import TextButton from '../../../../components/TextButton/TextButton';
import AddAttachmentsContainer from '../Attachments/AddAttachmentsContainer';


class AttachmentsRenderer extends BaseEditorComponent {
  state = {
    value: '',
    field: '',
    results: [],
    selectedItems: [],
    workingItems: [],
    selectmode: '',
    isLoading:false,
    tablename:'',
    width: this.props.cellWidth,
    height: this.props.cellHeight,
    lookuptable: '',
    lookupfield: '',
    isExpanded: false,
    isReadOnly:false,
    lastUpdatedItem: '',
    display: 'none',
    left: 20,
    top: 30,
    dataID: 0,
    tableid: 0,
    isChanged: false,
    displayMode: 'review',
    hasLookups: false,
    lastUpdate: '',
    source: {}
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value !=='' ? this.state.value : null;
  }

  // eslint-disable-next-line class-methods-use-this
  open() {
    this.setState({ display: 'block' });
  }

  close = async () => {
    this.setState({
      dataID: null,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: [],
      workingItems: [],
      value: null,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
      allowCategories: false
    });
  };

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    if (row !== -1) {
      const tdPosition = td.getBoundingClientRect();
      const datarow = this.props.GetRowData(row);
      // console.log(datarow)

      let dataID = null;
      let tablename = '';
      let recordname = '';
      let hasLookups = false;

      if (datarow !== null) {
        dataID = datarow.dataID;
        tablename = datarow.tablename;
        recordname = datarow.recordname;
        hasLookups = datarow.hasLookups;
      }

      // 3/26/20 comment
      this.setState({
        dataID,
        field: cellProperties.data,
        allowCategories: this.props.allowCategories,
        tablename,
        isReadOnly: cellProperties.source !==undefined,
        source: cellProperties.source,
        displayMode:'review',
        recordname,
        hasLookups,
        isExpanded: cellProperties.source !== undefined &&  originalValue && originalValue.length > 0,
        tableid: this.props.tableid,
        selectmode: cellProperties.selectmode,
        left:
        cellProperties.source !== undefined && originalValue && originalValue.length > 0
            ? 200
            : `${tdPosition.left + 1}px`,
        top:
        cellProperties.source !== undefined && originalValue && originalValue.length > 0
            ? window.innerHeight * 0.1
            : `${tdPosition.top + 2}px`,
        width:
        cellProperties.source !== undefined && originalValue && originalValue.length > 0
            ? 'calc(100vw - 300px)'
            : cellProperties.width - 4,
        height:
        cellProperties.source !== undefined && originalValue && originalValue.length > 0
            ? 'calc(100vh - 100px)'
            : this.props.cellHeight - 5,
      });


      if (originalValue !== undefined && originalValue !== '' && originalValue !== null) {
        const attachs = originalValue.filter((itm) => itm.optionid !== null);
        this.setState({ selectedItems: attachs });
      }
    }
  }

  propExists = (obj, path) => {
    // eslint-disable-next-line no-shadow
    return !!path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : undefined;
    }, obj);
  };

  handleFileUpload = async (files) => {
    const schema = `schema_${this.props.zoneid}`;
    let newItems = [];
    this.setState({isLoading:true})
    const fileUrls = await uploadFiles(schema, files, 'attachments');

    // 12-16-2020 Adding files directly to grid requires we put them in the "default" uncategorized category.
    // This category is created when the table is created and will always have the id = 1.
    // When users add files via pop-up into specific categoies, this value (optionid/data) will change accordingly.

    fileUrls.forEach((itm) => {
      // newItems.push({optionid:1,data:'uncategorized',attributes:{text:'000000',color:'#757575'},filename:itm.filename, url:itm.url,guidname:itm.guidname,uploadtype:'desktop',size:itm.size});
      newItems.push({
        url: itm.url,
        data: 'Uncategorized',
        size: itm.size,
        gcsurl: itm.gcsurl,
        filename: itm.filename,
        guidname: itm.guidname,
        optionid: 1,
        attributes: { text: 'FFFFFF', color: '#757575' },
        uploadtype: 'desktop',
      });
    });

    this.saveSingleItem(newItems);
  };

  ShowAddItems = (displayMode) => {
    const { top, left } = this.state;
    this.setState({
      width: 'calc(100vw - 300px)',
      height: displayMode === 'addition' ? '600px' : 'calc(100vh - 100px)',
      // left: window.innerWidth * 0.4,
      left: 200,
      top: window.innerHeight * 0.1,
      isExpanded: true,
      displayMode,
    });
  };

  updateGrid = () => {
    const { selectedItems } = this.state;

    this.setState(
      () => {
        return { value: selectedItems, isChanged: true };
      },
      () => {
        this.finishEditing();
      },
    );
  };

  saveSingleItem = (newItems) => {
    let { selectedItems, isExpanded } = this.state;
    const items = [...selectedItems];
    items.push.apply(items, newItems);


    this.setState({ selectedItems: items });

    // if (!isExpanded) {
    this.setState(
      () => {
        return { value: items,isLoading:false };
      },
      () => {
        this.finishEditing();
        this.close();
      },
    );
    // }
  };

  updateItem = async (url, category, name) => {
    const { selectedItems,dataID,tableid,field } = this.state;
    const idx = selectedItems.findIndex((itm) => itm.url === url);

    let currentCategory={
      optionid: '1',
      option: 'Uncategorized',
      attributes: { color: '#757575', text: '#FFFFFF' },
    }

    if(category !==undefined) {
      currentCategory=category;
    }

    if (idx !== -1) {
      selectedItems[idx].filename = name;
      
        selectedItems[idx].optionid = currentCategory.optionid;
        selectedItems[idx].rank = currentCategory.rank;
        selectedItems[idx].data = currentCategory.option;
        selectedItems[idx].attributes = currentCategory.attributes;

      this.setState({
        selectedItems: selectedItems,
        value: selectedItems,
        lastUpdatedItem: url,
        lastUpdate: Date.now(),
      },() => {
        updateAttachment(dataID,tableid,field,url,currentCategory.optionid,name);
      });

    }
  };

  removeItem = (url) => {
    const { selectedItems, isExpanded, selectmode } = this.state;
    const tmpselectedItem = [...selectedItems].filter((el) => el.url !== url);

      if(selectmode==='single') {
    this.setState(
      () => {
        return { value:tmpselectedItem };
        // return {value:[{optionid:null}]}
      },
      () => {
        this.finishEditing();
        this.close();
      },
    );
    } else {
      this.setState({
        selectedItems: tmpselectedItem,
        value: tmpselectedItem,
      });
    }


  };

  render() {
    let renderResult = null;
    const { displayMode, tableid, source, selectedItems, field, lastUpdatedItem, lastUpdate,selectmode,isLoading,isReadOnly,allowCategories } = this.state;
    const { zoneid, color, } = this.props;


    if (this.props.isEditor) {
      renderResult = (
        <NativeListener
          onMouseDown={this.stopMousedownPropagation}
          // onKeyDown={this.stopMousedownPropagation}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              // border:
              //   this.state.isExpanded
              //     ? `1px solid #707070`
              //     : '0px',
              backgroundColor: 'white',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              left: this.state.left,
              top: this.state.top,
              width: this.state.width,
              height: this.state.height,
              display: this.state.display,
              overflow: this.state.isExpanded ? 'hidden' : 'hidden',
              borderRadius: this.state.isExpanded ? '10px' : '0px',
              boxShadow: this.state.isExpanded ? '0px 3px 6px #707070' : null,
            }}
          >
            {this.state.isExpanded ? (
              <div
                style={{
                  height: '42px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                  paddingLeft: '20px',
                  backgroundColor: this.props.color,
                }}
              >
                
                <div style={{display:'flex',flexDirection:'row', alignItems:'center', color: 'white', paddingLeft: '10px' }}>
                <Icon path={mdiPaperclip} size="20px" color="white" />
                  Attach file(s) for {this.state.tablename}: {this.state.recordname}
                </div>
                <div style={{ marginRight: '10px', color: 'white' }}>
                  <TextButton
                    Action={this.updateGrid}
                    fontSize="14px"
                    hoverColor="#FFFFFF80"
                    text="Hide panel when done"
                    icon={mdiClose}
                  />
                </div>
              </div>
            ) : (
              <div>
                {(this.state.selectmode === 'multiple' ||
                  (this.state.selectmode === 'single' && selectedItems.length === 0)) &&
               !isReadOnly ? (
                  <div
                    onClick={() => this.ShowAddItems('addition')}
                    css={{
                      float: 'left',
                      marginRight: '10px',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: 'all .3s ease',
                        fontWeight: 'bold',
                        svg: {
                          color: 'white',
                          backgroundColor: `${this.props.color}80`,
                          borderRadius: '30px',
                          transition: 'all .3s ease',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiPaperclip} size="16px" />
                  </div>
                ) : null}

                {selectedItems.length > 0 ? (
                  <div
                    onClick={() => this.ShowAddItems('review')}
                    css={{
                      float: 'right',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: 'all .3s ease',
                        svg: {
                          color: `${this.props.color}80`,
                          transition: 'all .3s ease',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiArrowExpandAll} size="16px" />
                  </div>
                ) : null}

                {(this.state.selectmode === 'multiple' ||
                  (this.state.selectmode === 'single' && selectedItems.length === 0)) &&
               !isReadOnly  && !isLoading ? (
                  <Dropzone onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div
                          style={{
                            border: '1px dashed #757575',
                            height: this.state.height - 7,
                            marginBottom: '1px',
                            borderRadius: '5px',
                            marginTop: '3px',
                            marginLeft: '20px',
                            marginRight: '20px',
                          }}
                          {...getRootProps()}
                          className={classNames('dropzone', {
                            'dropzone--isActive': isDragActive,
                          })}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? <p>Drop files here...</p> : <p>Drag, drop, attach</p>}
                        </div>
                      );
                    }}
                  </Dropzone>
                ) : null}
                {isLoading ? (
                  (<Dimmer active inverted>
                    <Loader size='mini' inverted/>
                  </Dimmer>)
                ): null}
              </div>
            )}

            {this.state.isExpanded ? (
              <>
                {displayMode === 'addition' ? (
                  <div>
                    <AddAttachmentsContainer
                      zoneid={zoneid}
                      color={color}
                      AddItems={this.saveSingleItem}
                      Apply={this.updateGrid}
                      tableid={tableid}
                      field={field}
                      close={this.close}
                      selectmode={selectmode}
                      switchMode={this.ShowAddItems}
                      allowCategories={allowCategories}
                    />
                  </div>
                ) : null}
                {displayMode === 'review' && selectedItems !== undefined ? (
                  <AttachmentViewer
                    selectmode={selectmode}
                    tablename={this.state.tablename}
                    isReadOnly={isReadOnly}
                    zoneid={zoneid}
                    lastUpdate={lastUpdate}
                    lastUpdatedItem={lastUpdatedItem}
                    updateItem={this.updateItem}
                    switchMode={this.ShowAddItems}
                    recordname={this.state.recordname}
                    color={color}
                    removeItem={this.removeItem}
                    items={selectedItems}
                    tableid={source !== undefined ? source.lookuptableid : tableid}
                    field={source !== undefined && source.lookupfield !== undefined ? source.lookupfield.replace('_options', '') : field}
                    allowCategories={allowCategories}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        width: this.props.cellWidth,
        height: this.props.cellHeight - 7,
        // display:'inline-flex',
        float: 'left',
        zIndex: 0,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingTop:'5px',
        marginRight: '5px',
      };
      const cleanItems = this.props.value
        ? this.props.value.filter((itm) => itm.optionid !== null)
        : [];
      // this.props.value !==null && this.props.value[0] !==undefined && this.props.value[0].url !== null
      renderResult =
        cleanItems.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '3px',
              height:this.props.cellHeight - 14
            }}
          >
            <ImageThumbnails
              showDeleteIcon={false}
              Action={null}
              showHover={false}
              items={cleanItems}
              height={this.props.cellHeight - 14}
            />
          </div>
        ) : null;
    }

    return <>{renderResult}</>;
  }
}

export default AttachmentsRenderer;
