/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React ,{useEffect,useRef, useState,useContext} from 'react';
import { debounce } from "debounce";
import { css, jsx } from '@emotion/core'

import { Input, Icon as SIcon } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiPlus,mdiSortAscending,mdiSortDescending,mdiSort } from '@mdi/js';
import RowHeightButton from '../ActionBar/RowHeightButton';
import SortManager from './sortManager';
import TextButton from 'src/components/TextButton/TextButton';

const TopBar = ({SearchData,rowHeight, updateRowHeight,mode,setIsNewEntry,SwitchMode,color,hasItems,selectMode,sortMultiple,toggleSort,hasBlockAccess,showCreateRecord,canEdit,recordName,tableinfo,tableid,field,role,callingComponent}) => {

  const delaySearch = debounce(SearchData, 500)
  const inputRef = useRef(null);
  const [modalIsOpen,setModalIsOpen] = useState(false);

  useEffect(()=>{
    inputRef.current.focus();
  },[mode,recordName])

  const closeModal =() =>{
    setModalIsOpen(false);
  }
  const openModal=() =>{
    setModalIsOpen(true);
  }

  const updateSort =(tableid,field,sortItems) =>{
    setModalIsOpen(false);
    toggleSort(tableid,field,sortItems);   
  }

    return (
        <div style={{display: 'flex', margin: '20px', justifyContent: 'space-between' }}>
        <div css={{ 
          width: '100%', 
          input: {
            outlineColor:'black',
            border:'5px red solid',
        } }} >
        <Input
            ref={inputRef}
            // autoFocus={canEdit} //7-22-22 debating if i should comment out. In detail view, the backspace does work until you click into the input.
            // value={searchTerm}
            onChange={(e, data) =>delaySearch(data.value)}
            iconPosition="left"
            fluid
            placeholder="Find record(s)"
            style={{outlineColor:'red', width:'100%',minWidth:'150px'}}
          >
           <SIcon name="search" /> 
            <input style={{ borderRadius: '10px'}}/>
          </Input>
        </div>
        <div style={{fontSize:'13px',marginLeft:'10px',whiteSpace:'nowrap'}}> 
          <TextButton text={sortMultiple ? "Sort (on)" : "Sort"} iconSize='20px' Action={openModal} icon={mdiSort} textColor={sortMultiple ? color : "black"} iconPosition='left' />
        </div>
       
        <div style={{ 
          // width: '150px',
          marginLeft:'10px',
          marginRight:'20px'
          }}>
            <RowHeightButton color={color} label="Height" labelPosition='right' showBar3={true} selectedHeightIcon={rowHeight===33 ? 1 : rowHeight===60 ? 2 : 3} Action={updateRowHeight} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems:'flex-start',marginLeft:'5px' }}>
          {mode === 'addition' ? (
            <div
            style={{ display: 'flex', flexDirection: 'row', width: '100px', marginRight: '20px',alignItems:'center',justifyContent:'center' }}
          >
            <div onClick={() => setIsNewEntry(true)}
            css={{
              cursor: 'pointer',
              marginLeft:'20px',
              '&:hover': {
                transition: 'all .3s ease',
                fontWeight: 'bold',
                svg: {
                  color: 'white',
                  backgroundColor: `${color}80`,
                  transition: 'all .3s ease',
                },
              },
            }}>
              {hasBlockAccess  && showCreateRecord ? (
              <div css={{ backgroundColor: 'white', borderRadius: '30px',marginLeft:'3px',width: 'max-content',display:'flex',flexDirection:'row',alignItems:'center'  }}> 
              <Icon style={{ borderRadius: '30px', marginRight: '3px' }} path={mdiPlus} size="15px" />{'  '}
              <span>Create {recordName.length>12 ? `${recordName.slice(0,12)}...`: recordName}</span>
              </div>
              ): null}

            </div>
            </div>
          ) : null }
          {(mode==="review" && selectMode==="multiple") || (mode==="review" && selectMode==="single" && !hasItems) ?
          (
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100px', marginRight: '20px',alignItems:'center',justifyContent:'center' }}
            >
              <div
                onClick={() => SwitchMode('addition')}
                css={{
                  cursor: 'pointer',
                  '&:hover': {
                    transition: 'all .3s ease',
                    fontWeight: 'bold',
                    svg: {
                      color: 'white',
                      backgroundColor: `${color}80`,
                      transition: 'all .3s ease',
                    },
                  },
                }}
              > {canEdit ? (
                <div css={{ backgroundColor: 'white', borderRadius: '30px', width: 'max-content',display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <Icon
                    style={{ borderRadius: '30px', marginRight:'3px' }}
                    path={mdiPlus}
                    size="15px"
                  />
                  <span>Add {recordName.length>12 ? `${recordName.slice(0,12)}...`: recordName}</span>
                </div>
                ):null}
              </div>
            </div>
          ) : null}
        </div>
        {modalIsOpen ? (
      <div style={{position:'absolute', zIndex:1200, top:callingComponent==='detailview' ? 180 :100,left:callingComponent==='detailview' ? 20 : 150,backgroundColor:'white',borderRadius:'10px', boxShadow: '0px 3px 6px #707070'}}>
            <SortManager tableid={tableid} tableinfo={tableinfo} field={field} closeModal={closeModal} role={role} updateSort={updateSort} color={color} sortFields={sortMultiple} />
     </div> ) : null}
      </div>
    )
}

export default TopBar