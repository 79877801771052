/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';

const DoughnutControl = ({ label, totalMetric,fontSize="13px", currentValue,labelWidth='75px',measure='' }) => {
  
  let overLimit = currentValue > totalMetric;
  let primaryColor= overLimit ? '#E06055' : '#AE007B';
  let secondaryColor= overLimit ? '#E06055' : '#FFBE24';

  const metricData = {
    datasets: [
      {
        data: [currentValue, totalMetric - currentValue],
        backgroundColor: [
          secondaryColor,
          primaryColor
          // '#FFCE56'
        ],
      },
    ],
    labels: [
      '',
      ''
      // `${currentValue} ${label}`,
      // `${totalMetric} ${label}`,
      // 'Blue'
    ],
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          marginLeft:'5px',
          marginRight:'5px',
          display: 'flex',
          width: labelWidth,
          overflowWrap:'normal',
          height: '35px',
          justifyContent: 'center',
          fontSize:fontSize,
          textAlign: 'center',
        }}
      >
        {currentValue} of {totalMetric} {measure} {label}
      </div>
      <div style={{position:'relative', width: '75px', height: '75px' }}>
        {overLimit ? (
        <div style={{position:'absolute', top:'8px', left:'30px', color:"#E06055"}}>
          <Icon path={mdiAlert} size="15px" />
        </div>
        ): null}
        <Doughnut data={metricData} options={{ legend: false, cutoutPercentage: 70 }} />
      </div>
    </div>
  );
};

export default DoughnutControl;
