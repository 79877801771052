/* eslint-disable */
import React, {useState} from 'react';
import { Link } from '@reach/router';
import { toast } from 'react-toastify';
import Page from '../../../../components/Page';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../../../components/Button';
import GoogleButton from '../../../../components/GoogleButton';
import MicrosoftButton from '../../../../components/MicrosoftButton';
import { signInAPI, signInGoogleAPI,signInMicrosoftAPI } from '../../../../api/auth';
import { EmailIcon } from '../../../../components/Icon';
import FinalForm, { Form, FormField, FormCard, validators,FORM_ERROR } from '../../../../components/Form';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import LogoIcon from '../../../../assets/icons/GraceBlocksNewLogo.svg';
import Multifactor from '../../../../containers/Auth/SignIn/Multifactor';
import GBLogo from './Powered.svg';


const formValidators = {
    email: validators.composeValidators(validators.required, validators.email),
    password: validators.composeValidators(validators.required),
  };

const FormLogin = ({ form, noLoginUsed,isLoggedIn,message }) => {

  const [isLoading,setLoading]=useState(false)
  const [isMultiFactor,setIsMultiFactor] =useState(false);
  const [resolver, setResolver] =useState(null);

  const handleMultiFactor =() =>{
    // navigate(ROUTES.REDIRECTION.DASHBOARD);
  }

  const close =() =>{
    setIsMultiFactor(false);
  }

  const showError =(message) =>{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Login error occurred'}
            action={onClose}
            buttonLabel="OK"
            message={message}
            width="500px"
            showInput={false}
            showCancelButton={false}
            confirmAction=""
            confirmWord="activate"
            height="430px"
            onClose={onClose}
          />
        );
      },
    });
  }

  const handleSubmit = (values) => {
      setLoading(true);
      return signInAPI(values)
      .then((result) => {
        if(result.user !==undefined) {
        //  navigate(ROUTES.REDIRECTION.DASHBOARD);
        } else {
         setIsMultiFactor(true);
         setLoading(false);
         setResolver(result.resolver)
        }
       })
        .catch((error) => {
          if(screen.width>500) {
          showError(error.message)
        } else {
          toast.info(
            error.message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 20000,
            },
          );
        }
        });
    }


  const handleGoogleSignIn =() => {
    setLoading(true);
    signInGoogleAPI()
      .then(({ isNewUser }) => {
        if (isNewUser) {
          // navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
        } else {
          // navigate(ROUTES.REDIRECTION.DASHBOARD);
        }
      })
      .catch((error) => {
        showError(error.message)
      });
  }

  const handleMicrosoftSignIn =() => {
    setLoading(true);
    signInMicrosoftAPI()
      .then(({ isNewUser }) => {
        if (isNewUser) {
          // navigate(ROUTES.REDIRECTION.AUTH_PROCESS);
        } else {
          // navigate(ROUTES.REDIRECTION.DASHBOARD);
        }
      })
      .catch((error) => {
        showError(error.message)
      });
  }

    const getFormDetails = () =>{
        return (
          <>
           {form.settings.logo !==undefined && screen.width>500 ? (
              <img
              height={`${form.settings.logo.height}px`}
              src={form.settings.logo.url}
            />
          ): <div style={{marginTop:'10px',marginBottom:'20px'}}><img src={LogoIcon} alt="Logo" /></div>}
    
      <FormCard title={'SIGN IN'}>
      {!isLoggedIn ? (<>

      <div style={{ textAlign: 'left', marginBottom: '20px', width: screen.width>500 ? "400px" :'100%' }}></div>
        <GoogleButton className="mb-4" onClick={handleGoogleSignIn}>
          Sign in with Google
        </GoogleButton>
        <MicrosoftButton className="mb-4" onClick={handleMicrosoftSignIn}>
          Sign in with Microsoft
        </MicrosoftButton>

        <FormCard.Subtitle className="mb-2 mb-4">OR SIGN IN MANUALLY</FormCard.Subtitle>

        <FinalForm onSubmit={handleSubmit} id="id-sign-in-form">
          {() => {
            return (
              <>
         
                <FormField
                  name="email"
                  label="Email"
                  color={form.settings.accentColors.attributes.color}
                  placeholder="Enter your email address."
                  validate={formValidators.email}
                  leftIcon={<EmailIcon />}
                  controlId="id_email"
                />
                <FormField
                  name="password"
                  label="Password"
                  validate={formValidators.password}
                  color={form.settings.accentColors.attributes.color}
                  placeholder="Enter the password to view the share record(s)"
                  control={Form.PasswordControl}
                  helpText={
                    <div className="float-right" style={{ fontSize: '85%' }}>
                      <Link to="/auth/forgot-password">Forgot Password</Link>
                    </div>
                  }
                  helpTextAlways
                  // leftIcon={<EmailIcon />}
                />
            

                <div className="py-3" />
                <Button  type="submit" variant='form'>
                 Sign in
                </Button>


                {form.settings.login==='optional' ? (
                <div style={{marginTop:'30px'}}>
                    <FormCard.Subtitle className="mb-2 mb-4">OR SKIP SIGN IN</FormCard.Subtitle>
                    <Button  onClick={noLoginUsed} variant='form'>
                    Continue without signing in
                   </Button>
                </div>
                ): null}
                
              </>
            );
          }}
        </FinalForm>
        </>): <div>{message}</div>}
      </FormCard>

      {form.settings.includeGraceBlocksLogo ? (<center>
        <div style={{ backgroundColor: 'black', width: '180px', padding: '10px', borderRadius: '10px',marginTop:'20px' }}>
          <a href="https://www.graceblocks.com" target="_newwindow"><img src={GBLogo} height="30px" /></a>
        </div>
        <div style={{paddingBottom:'30px'}}>
        </div>
      </center>): null}
          </>
        )
    }


  return (<>
    {screen.width<501 ? (
      <div style={{margin:'10px',marginTop:'20px'}}>
      {getFormDetails()}
    </div>
    ): (
      <Page twin color={form.settings.accentColors.attributes.color}>
         {getFormDetails()}
        </Page>
    )}

     {isMultiFactor && resolver !==null ? (
          <div
          style={{
            zIndex: 100,
            // position: 'absolute',
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            border: `1px solid black`,
            borderRadius: '20px',
            // marginLeft:screen.width
            top: '50%',
            left: '50%',
            // margin: screen.width<500 ? '20px' : null,
            width: screen.width>500 ? '500px' :'80%',
            height: screen.width>500 ? '522px' : '350px',
          }}
          >
            <Multifactor resolver={resolver} color="#120233" onSuccess={handleMultiFactor} close={close}  />
          </div>
        ): null}
    
    </>
  );
};

export default FormLogin;
