/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import FinalForm, { Form, FormField, FormCard, validators } from '../../components/Form';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import GBSubmit from '../../components/GBSubmit/GBSubmit';
import firebase, { phoneProvider } from '../../clients/firebase';
import { VerifyPhoneNumber, updateMobileNumber } from '../../api/auth';
import { ReactComponent as Logo } from '../../containers/Auth/PhoneValidator/Success.svg';

const AddMobileNumber = ({ userid, color, close, updateMobile }) => {
  const formValidators = {
    phoneNumber: validators.composeValidators(validators.required, validators.phoneNumber),
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [smsCodeError, setSmsCodeError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [mobile, setMobile] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-validator', {
      size: 'invisible',
    });
  }, []);

  const sendCode = async (values) => {
    setIsSubmitted(true);
    setSubmitError('');
    setMobile(values.phoneNumber);
    try {
      const result = await VerifyPhoneNumber(values.phoneNumber);
      // Check phone number is not already used. If returns false, indicates no matching records, so it can be used.
      if (result.data !== 'success') {
        const id = await phoneProvider.verifyPhoneNumber(
          values.phoneNumber,
          window.recaptchaVerifier,
        );
        setVerificationId(id);
      } else {
        setSubmitError('An account already exists matching this phone number. ');
        setIsSubmitted(false);
      }
    } catch (error) {
      setSubmitError(error.message);
      setIsSubmitted(false);
    }
  };

  const verifySMSCode = async (values) => {
    
    setSmsCodeError('');
    try {
      const credential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        values.code,
      );
      const user = firebase.auth().currentUser;
      await user.updatePhoneNumber(credential);

      //update system mobile number and mobiledeliverydate in users table.
      await updateMobileNumber(userid, mobile);
      //update mobile in parent ConactInformation page so new number appears and shows as verified.
      updateMobile(mobile);
      setSmsCode(values.code);
    } catch (error) {
        setSmsCode('')
        if(error.message.includes('is invalid'))
        {
            setSmsCodeError('Incorrect or expired code, please try again.');
        } else {
            setSmsCodeError(error.message)
        }
      
    }
  };

  const SectionHeader = () => {
    let header = '';
    if (!isSubmitted) {
      header = 'Add phone';
    } else if(isSubmitted && smsCode==='')
    {
        header= 'Mobile phone validation'
    } else if (isSubmitted && smsCode !=='' && smsCodeError==='')
    {
        header='Phone number validated'
    }
    return header;
  };

  return (
    <div>
      <div id="phone-validator" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: color,
          textAlign: 'center',
          paddingLeft: '20px',
          color: 'white',
          verticalAlign: 'center',
          height: '40px',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
        }}
      >
        {SectionHeader()}
      </div>

      {!isSubmitted && !isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '5px',  display: 'inline' }}>
            <div style={{marginLeft:'23px'}}>
            Your number will be used to deliver important notifications, help you sign in, and is required to be a
            Zone owner. Validate it below to proceed.
            </div>

            <FinalForm onSubmit={sendCode}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div style={{ margin: '20px' }}>
                      <FormField
                        name="phoneNumber"
                        label="Mobile Phone"
                        color={color}
                        placeholder="Enter your mobile phone number."
                        validate={formValidators.phoneNumber}
                        control={Form.PhoneNumberControl}
                      />
                      {submitError !== '' ? (
                        <div style={{ color: 'red' }}>{submitError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <TextButton
                          text="Cancel"
                          hoverColor="#75757580"
                          Action={() => close()}
                          ActinValue={false}
                          textColor="black"
                        />
                      </div>
                      <GBSubmit color={color} text="Send validation code" width="200px" />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
      {isSubmitted && !isLoading && smsCode === '' && submitError === '' ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '5px', display: 'inline' }}>
            Validation code sent. Please enter it below.
            <FinalForm onSubmit={verifySMSCode}>
              {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
                return (
                  <>
                    <div style={{ margin: '20px' }}>
                      <FormField
                        name="code"
                        color={color}
                        label="Validation Code"
                        placeholder="Enter the validation code."
                        validate={formValidators.code}
                        controlId="id_code"
                      />
                      {smsCodeError !== '' ? (
                        <div style={{ color: 'red' }}>{smsCodeError}</div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'rows',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>
                        <TextButton
                          text="cancel"
                          hoverColor="#75757580"
                          Action={() => close()}
                          ActinValue={false}
                          textColor="black"
                        />
                      </div>
                      <GBSubmit color={color} text="Submit" width="100px" />
                    </div>
                  </>
                );
              }}
            </FinalForm>
          </div>
        </div>
      ) : null}
      {isSubmitted && smsCode !== '' && smsCodeError === '' ? (
        <div style={{ margin: '10px',display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center' }}>
          <div><Logo style={{width:'175px'}} /></div>
          <div style={{marginTop:'20px',alignSelf:'center', marginBottom:'10px', fontWeight:'bold'}}>Success!</div>

         <div style={{alignSelf:'center',marginLeft:'-10px'}}><GBButton color={color} text="Ok" Action={close} width="100px" />  </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddMobileNumber;
