/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component, useEffect } from 'react';
import Icon from '@mdi/react';

import { toast } from 'react-toastify';
import { Dropdown,Input } from 'semantic-ui-react';
import { mdiDragVertical, mdiLockOutline, mdiStar, mdiEarth, mdiPencil,mdiDeleteForever,mdiChevronDown } from '@mdi/js';
import Avatar from '../../../../components/Avatar/Avatar';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import GBButton from '../../../../components/GBButton/GBButton';
import TextButton from '../../../../components/TextButton/TextButton';
import { getBookmarkByName } from '../../../../api/bookmarks';

class Bookmark extends Component {

    constructor(props) {
        super(props);
        this.input = React.createRef();
      }
    
      state = {
        editMode: false,
        name: '',
        oldname: '',
        ispublic: false,
        isdefault: false,
        updateContent: false
      };

      componentDidMount() {
          const {item} = this.props
          this.setState({name: item.name,oldname: item.name,ispublic:item.ispublic,isdefault:item.isdefault})
      }

      toggleUpdateContent =() =>{
        const {updateContent} = this.state;
        this.setState({updateContent: !updateContent})
      }

      toggleEditMode =() =>{
        const {editMode} = this.state;
        this.setState({editMode: !editMode})
      }

      saveBookMark= async () =>{
        const {name} = this.state;
        const checkName = await getBookmarkByName(tableid,name)
        if(checkName.length>0) {
            toast.info('This name is already in use on this tab. Name must be unique.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:7000,
              })
        } else {
           this.setState({editMode:false}, () =>this.updateBookmarkHandler())
        }
       
      }

    //   handleClickOutside = async (evt) => {
    //    const {editMode,name} = this.state;
    //    const {tableid} = this.props;

    //    if(editMode) {
    //     const checkName = await getBookmarkByName(tableid,name)
    //     if(checkName.length>0) {
    //         toast.info('This name is already in use on this tab. Name must be unique.', {
    //             position: toast.POSITION.BOTTOM_CENTER,
    //             autoClose:7000,
    //           })
    //     } else {
    //        this.setState({editMode:false}, () =>this.updateBookmarkHandler())
    //     }
    //    }
    //   };

      handleAction = async (value) => {
         const {deleteMark,item} = this.props;
    
          if(value==='rename') {
              this.setState({editMode: true})
          } else if (value==='makedefault') {
              this.setState({isdefault:true,ispublic:true}, () =>this.updateBookmarkHandler())
          } else if(value==='makepublic') {
              this.setState({ispublic:true,isdefault:false}, () =>this.updateBookmarkHandler())
          }
            else if(value==='makeprivate') {
            this.setState({ispublic:false,isdefault:false}, () =>this.updateBookmarkHandler())
        }
            else if(value==='delete') {
            await deleteMark(item.bookmarkid)
        }

      }

      updateBookmarkHandler = async () => {
        const {name,ispublic,isdefault} = this.state;
        const {tableid,update,item} = this.props;
        await update(name,item.bookmarkid,ispublic,isdefault,tableid)
      }

      updateBookmarkContentLocal = async () =>{
        const {name,oldname,updateContent}  = this.state;
        const {updateBookmarkContent,item,tableid} = this.props;

        if(updateContent){
            this.setState({editMode:false},async ()=> await updateBookmarkContent(name,oldname,item.bookmarkid,tableid));
        } else {
            this.setState({editMode:false},async ()=> await this.updateBookmarkHandler());
        }
      }

render() {
    const {item, loadBookmark,role,color} = this.props;
    const {name,editMode,ispublic,isdefault,updateContent}  = this.state;
    return (
        <div
        style={{
            // border: '1px solid blue',
            marginRight: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}
        >
            {editMode ? (<>
                <Input value={name} onChange={(e,data)=>this.setState({name:data.value})} />
                &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; 
                <GBSwitch width="150px" color={color} isChecked={updateContent} Action={this.toggleUpdateContent} text="Reset bookmark with current filters/columns" />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <TextButton text='Cancel' textColor='black' hoverColor='#00000080' Action={this.toggleEditMode} />
                &nbsp; &nbsp; &nbsp;
                <GBButton text='Save' width="75px" Action={this.updateBookmarkContentLocal} color={color} />
            </>): (
        <div
            css={{ width: '210px', fontWeight:'bold', color:color, '&:hover': {
                color:`${color}80`
            } }}
            onClick={() => loadBookmark(item.bookmarkid,item.tabsettings)}
        >
            {name}
        </div>
        )}
        {!editMode ? (<>
        <div style={{ width: '130px' }}>
            {ispublic && (isdefault === false || isdefault===null) ? (
            <div>
                <Icon path={mdiEarth} size="20px" /> Public
            </div>
            ) : null} 
            {isdefault ? (
                <div>
                <Icon path={mdiStar} size="20px" /> Public default
                </div>
            ): null}
            {!ispublic ? (
                <div>
                <Icon path={mdiLockOutline} size="20px" /> Private
                </div>
            ): null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '200px' }}>
            <Avatar
            item={item.User}
            width="35px"
            height="35px"
            fontSize="13px"
            />
            <div style={{marginRight:'5px'}}>{item.User.firstname}</div>
            
            <div>{item.User.lastname}</div>
        </div>
        <div css={{ width: '200px', '&:hover': {
            svg: {
                color:'white',
                backgroundColor:color,
                borderRadius:'100%',
            }
        } }}>
            {role===3 || !ispublic ? (
            <Dropdown 
            icon={null}
            trigger={<GBButton
              text="Select an action"
              icon={mdiChevronDown}
              iconSize="20px"
              iconColor="white"
              iconHoverColor="white"
              iconPosition="right"
              borderRadius="10px"
              Action={null}
              textColor="white"
              width="150px"
              height={'30px'}
              color={color}
              textHoverColor="white"
              hoverBackgroundColor={`${color}80`}
    />}
            // icon={<Icon path={mdiChevronDown} size="20px" />}
            >
                  <Dropdown.Menu>
                      {role===3 || !ispublic ? (
                    <Dropdown.Item
                      onClick={() => this.handleAction('rename')}
                      icon={<Icon style={{marginRight:'10px'}}  path={mdiPencil} size="20px" />}
                      text="Edit"
                    />
                       ) : null}
                      {role===3 ? (<>
                    <Dropdown.Item
                    onClick={() => this.handleAction('makedefault')}
                      icon={ <Icon style={{marginRight:'10px'}}  path={mdiStar} size="20px" />}
                      text="Make public default"
                    />
                    <Dropdown.Item
                      onClick={() => this.handleAction('makepublic')}
                      icon={ <Icon style={{marginRight:'10px'}}  path={mdiEarth} size="20px" />}
                      text="Make public"
                    />
                    <Dropdown.Item
                      onClick={() => this.handleAction('makeprivate')}
                      icon={ <Icon style={{marginRight:'10px'}}  path={mdiLockOutline} size="20px" />}
                      text="Make private"
                    />
                    </>) : null}
                    {role===3 || !ispublic ? (
                    <Dropdown.Item
                      onClick={() => this.handleAction('delete')}
                      icon={ <Icon style={{marginRight:'10px'}}  path={mdiDeleteForever} size="20px" />}
                      text="Delete"
                    />
                    ): null}

                  </Dropdown.Menu>
                </Dropdown>
                ): null}
        </div>
        <div style={{ width: '30px' }}>
            <Icon path={mdiDragVertical} size="20px" />
        </div>
        </>) : null }
        </div>
    );
    }
};

export default Bookmark;
