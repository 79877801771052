/* eslint-disable */
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Checkbox } from 'semantic-ui-react';
import GBSubmit from '../../components/GBSubmit/GBSubmit';
import TextButton from '../../components/TextButton/TextButton';


class CheckoutForm extends React.Component {
  state = {
    isDefault: true,
  };

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const { stripe, elements, stripeInfo,cancel,addPayment } = this.props;
    const {isDefault} = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const result = await addPayment(stripeInfo, paymentMethod,isDefault);
    
    if (error) {
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  render() {
    const { stripe,cancel } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
          <div style={{width:'400px',marginLeft:'20px',marginRight:'20px',marginTop:'20px'}}>
              <div>Credit card Information </div>
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        </div>
          <div style={{display:'flex', flexDirection:'column', alignItems:'center',marginTop:'20px'}}>
        
        <Checkbox
          label="Use this payment method on future payments"
          checked={this.state.isDefault}
          onChange={(e, data) => this.setState({ isDefault: !this.state.isDefault })}
        /> 
        <div style={{height:'10px'}}></div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <TextButton Action={cancel} text="Cancel" hoverColor="#75757580" textColor="black" />
            <div style={{width:'10px'}}></div>
            <GBSubmit color="#AE007B" text="Add card" />
        </div>
        </div>
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} stripeInfo={props.stripeInfo} cancel={props.cancel} addPayment={props.addPayment} />
      )}
    </ElementsConsumer>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const { REACT_APP_STRIPE } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE);

const AddPayment = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm stripeInfo={props.stripeInfo} cancel={props.cancel} addPayment={props.addPayment} />
    </Elements>
  );
};

export default AddPayment;
