/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Form from '../Form';
import showErrors from './showErrors';

const errorMessage = (error) => {
  if (!error) return '';
  if (typeof error === 'string' || error instanceof String) {
    return error;
  }
  return error[0];
};

const FormField = (props) => {
  const {
    name,
    label,
    helpText,
    helpTextAlways,
    validate,
    validateFields,
    control: Control,
    controlId,
    hideErrors,
    size,
    onChange,
    ...other
  } = props;

  const controlProps = { ...other };
  if (onChange) {
    controlProps.customOnChange = onChange;
  }

  return (
    <Field name={name} validate={validate} validateFields={validateFields} onChange={onChange} onFocus={()=>console.log('focus')}>
      {({ input, meta }) => {
        const { error, submitError, dirtySinceLastSubmit } = meta;
        const isSubmitErrors = !!(!hideErrors && !dirtySinceLastSubmit && submitError);
        const isErrors = !!(!hideErrors && !isSubmitErrors && showErrors(meta) && error);
        return (
          <Form.Group size={size} invalid={isErrors || isSubmitErrors} controlId={controlId}>
            {!!label && <Form.Label size={size}>{label}</Form.Label>}
            <Control size={size} {...controlProps} {...input} meta={meta} />
            {!!helpText && (meta.active || helpTextAlways) && <Form.Text>{helpText}</Form.Text>}
            {!!isSubmitErrors && <Form.Feedback type="invalid">{submitError}</Form.Feedback>}
            {!!isErrors && <Form.Feedback type="invalid">{errorMessage(error)}</Form.Feedback>}
          </Form.Group>
        );
      }}
    </Field>
  );
};

FormField.defaultProps = {
  onChange: undefined,
  control: Form.Control,
  validate: () => {},

  label: null,
  helpText: null,
  controlId: null,
  validateFields: [],
  hideErrors: false,
  helpTextAlways: false,
  size: undefined,
};

FormField.propTypes = {
  onChange: PropTypes.func,
  validate: PropTypes.func,
  control: PropTypes.func,

  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controlId: PropTypes.string,
  validateFields: PropTypes.arrayOf(PropTypes.string),
  hideErrors: PropTypes.bool,
  helpTextAlways: PropTypes.bool,
  size: PropTypes.string,
};

export default FormField;
