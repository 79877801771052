/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState,useEffect } from 'react';

import { mdiCloudUpload } from '@mdi/js';
import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import {plans} from '../../../../Zone/plans';

const CsvLoader = ({color,loadCSVFile,plan}) => {

  useEffect(()=>{
    const currentPlan=plans.filter(el=>el.type===plan)[0];
  },[])


    const GREY = '#CCC';
    const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
    const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
    const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor( DEFAULT_REMOVE_HOVER_COLOR, 40);
    const GREY_DIM = '#686868';

    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
    const currentPlan=plans.filter(el=>el.type===plan)[0];

    const recordsExceededMessage = () =>{

      toast.info('The number of records exceeds your current plan\'s record limit. You may get this error if you have not saved the csv file in utf-8 format.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      })
    }


const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${color}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width:'250px',
    height: '50px',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    // borderColor: `black`,
    border: `2px dashed ${color}1A`,
    backgroundColor:'#eee'
  },
  default: {
    borderColor: color,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

  return (
  <div>
    <CSVReader
      onUploadAccepted={(results) => results.data.length < currentPlan.records ? loadCSVFile(results.data) : recordsExceededMessage()} 
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }) => (
        <>
          <div
            {...getRootProps()}
            css={{
              alignItems: 'center',
              border: `2px dashed ${color}`,
              backgroundColor: zoneHover ? '#eee' : 'white',
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              width:'250px',
              height: '50px',
              justifyContent: 'center',
              padding: 20,
              color: color,
              '&:hover': {
                border: `2px dashed ${color}80`,
                svg: {
                  color: `${color}80`
                }
              }
            }}
          >
            {acceptedFile ? (
              <>
              </>
            ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                cursor:'pointer',
                color: {color}
                // border:'1px solid red'
              }}
            >
                  <Icon path={mdiCloudUpload} size="30px"  />
                  <span style={{marginLeft:'5px'}}>Drop CSV UTF-8 file here or click to upload.</span>
            </div>
            )}
          </div>
        </>
      )}
    </CSVReader>
  </div>
  )
};

export default CsvLoader;
