/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@mdi/react';
import { Popup } from 'semantic-ui-react';


const ActionButton = ({
  label,
  tooltip,
  color,
  baseColor ="#312602",
  height = null,
  Action,
  ActionValue,
  actionIcon,
  isSelected,
  showBottom = false,
  invertColors = false,
  BottomText = '',
  marginBottom = '5px',
  marginRight = '10px',
  marginLeft = '10px',
  fontSize = '12px',
  textHoverColor=`${color}80`,
  isMobile=false
}) => {

  const hover={
    transition: 'all .3s ease',
    color: `${textHoverColor}`,
    svg: {
      color: invertColors ? `white` : `${color}80`,
      backgroundColor: invertColors ? `${color}` : 'white',
      borderRadius: '100%',
      transition: 'all .3s ease',
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        cursor:'pointer',
        flexDirection: 'column',
        color: isSelected ? color : baseColor,
        fontSize: fontSize,
        height: height,
        marginBottom: marginBottom,
        justifyContent: 'center',
        marginLeft: marginLeft,
        marginRight: marginRight,
        alignItems: 'center',
        borderBottom: showBottom && isSelected ? `4px solid ${color}` : '4px solid transparent',
        outline: '0px',
        // border:'1px solid blue',
        '&:hover': !isMobile ?  hover : null,
        '&:active': {
          color: `${color}`,
          svg: {
            color: `${color}`,
          },
        },
      }}
      onClick={Action !== null ? () => Action(ActionValue) : null}
      onKeyDown={Action != null ? () => Action(ActionValue) : null}
      role="button"
      tabIndex="0"
    >
      <div style={{whiteSpace:'nowrap'}}>{label}</div>
      {actionIcon !== undefined ? (
        <div className='icon1'>
        <Popup
          disabled={tooltip === undefined}
          content={tooltip}
          trigger={<Icon path={actionIcon} size={isMobile ? '20px' : "25px"} />}
        /></div>
      ) : null}
      {BottomText !== ''} {<span style={{ fontSize: '20px' }}>{BottomText}</span>}
    </div>
  );
};

export default ActionButton;
