/* eslint-disable no-unused-expressions */
import React from "react";
import NativeListener from "react-native-listener";
import { BaseEditorComponent } from "@handsontable/react";
import { Label } from 'semantic-ui-react';

class LookupLookupValues extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();

    this.editorContainerStyle = {
      display: "none",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 999,
      background: "#fff",
      padding: "15px",
      border: "1px solid #cecece",
    };

    this.state = {
      renderResult: null,
      value: [],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.editorRef.current.style.display = "block";
  }

  close() {
    this.editorRef.current.style.display = "none";

    this.setState({
      pickedColor: null,
    });
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const tdPosition = td.getBoundingClientRect();

    this.editorRef.current.style.left = `${tdPosition.left  }px`;
    this.editorRef.current.style.top = `${tdPosition.top  }px`;
  }

  onPickedColor(color) {
    this.setValue(color.hex);
  }

  applyColor() {
    this.finishEditing();
  }

  itemsarray = [];
  

  scan = (obj) => {
    let k;
    if (obj instanceof Object) {
      // eslint-disable-next-line no-restricted-syntax
      for (k in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(k)) {
          this.body += `scanning property ${  k  }<br/>`;
          if (k === this.props.lookuptable && obj[k][this.props.lookupfield] != null) {

            this.itemsarray.push(obj[k])
            // console.log(k);
            // console.log(obj[k])
          }
          this.scan(obj[k]);
        }
      }
    } else {
      this.body += `found value : ${  obj  }<br/>`;
    };

    return this.itemsarray;
  };


  relationitemarray =[];

  scanrelationarray = (obj,element) =>
  {
      let k;
      if (obj instanceof Object) {
          // eslint-disable-next-line no-restricted-syntax
          for (k in obj){
              // eslint-disable-next-line no-prototype-builtins
              if (obj.hasOwnProperty(k)){
                  if(k ===element)
                  {
                    this.relationitemarray.push(obj[k])
                    // console.log(k);
                    // console.log(obj[k])
                  }
                  this.scanrelationarray( obj[k],element );  
              }                
          }
      } else {
         this.body += `found value : ${  obj  }<br/>`;
      };
  
      return this.relationitemarray;
  };

  createtags = () => {
    let items = this.scan(this.props.value);
    items.sort((a, b) => (a.addedon > b.addedon) ? 1 : -1)

    if (this.props.value != null) {
      if (this.props.specialfunction === 'all') {
        return (
          items.length > 0 && items.map((el) => (
            <Label  style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }} key={el}>{el[this.props.lookupfield]}</Label>
          ))
        )
      }  if (this.props.specialfunction === 'unique') {
        items = []
        // eslint-disable-next-line no-unused-expressions
        this.props.value.length > 0 && this.props.value.forEach((el) => {
          if (el[this.props.lookuptable][this.props.lookupfield] != null) {
            if (!items.includes(el[this.props.lookuptable][this.props.lookupfield])) {
              items.push(el[this.props.lookuptable][this.props.lookupfield])

            }
          }
        });

        return (
          items.map((el) => (
            // <Label style={{marginTop:'5px', marginBottom:'5px'}} key={el.tbl_1.id}>{el.tbl_1[this.props.lookupfield]}</Label>
            <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }} key={el}>{el}</Label>
          ))
        )

      }  if (this.props.specialfunction === 'count') {
        let counter = 0;
        this.props.value.forEach((el) => {
          if (el[this.props.lookuptable][this.props.lookupfield] != null) {
            counter += 1;
          }
        });
        return (
          <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{counter}</Label>
        )
      }
     if (this.props.specialfunction === 'countdistinct') {
        items = []
         this.props.value.forEach((el) => {
          if (el[this.props.lookuptable][this.props.lookupfield] != null) {
            if (!items.includes(el[this.props.lookuptable][this.props.lookupfield])) {
              items.push(el[this.props.lookuptable][this.props.lookupfield])
            }
          }
        });

        return (
          <Label style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items.length}</Label>
        )
      }
      if (this.props.specialfunction === 'oldest') {
        items.sort((a, b) => (a.addedon > b.addedon) ? 1 : -1)
        return (
          <Label  style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield]}</Label>
        )
      }
       if (this.props.specialfunction === 'newest') {
        items.sort((a, b) => (a.addedon < b.addedon) ? 1 : -1)
        return (
          <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield]}</Label>
        )
      }
      if (this.props.specialfunction === 'mostrecent') {
        items.sort((a, b) => (a.updatedon < b.updatedon) ? 1 : -1)
        return (
          items[0][this.props.lookupfield] && <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield]}</Label>
        )
      }
    }

    return null;
  }

  createMultipleTags = () => {
    const items = this.scan(this.props.value);

    if (this.props.value != null) {
      if (this.props.specialfunction === "all") {

        return (
          items.length > 0 && items.map((el) => (
            el[this.props.lookupfield].map((opt, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Label  style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center', color: 'white', backgroundColor: opt.data.attributes != null ? opt.data.attributes.color : 'teal' }} key={i}>{opt.data.option}</Label>
              // <Label>{i}</Label>
            ))
          ))
        )
      }  if (this.props.specialfunction === 'unique') {

        // eslint-disable-next-line prefer-const
        let tempitems = [];
         items.forEach((el) => {
          // eslint-disable-next-line no-unused-expressions
          [this.props.lookupfield] && el[this.props.lookupfield].forEach((opt) => {
            const findindex = tempitems.findIndex(el2 => el2.id === opt.data.optionid)
            if (findindex === -1) {
              tempitems.push({ id: opt.data.optionid, option: opt.data.option, attributes: opt.data.attributes })
            }
          })
        })
        return (
          tempitems.map((itm) => (
            <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center', color: 'white', backgroundColor: itm.attributes != null ? itm.attributes.color : 'teal' }} key={itm.id}>{itm.option}</Label>
          ))
        )
      } 
      if (this.props.specialfunction === 'count') {
        let counter = 0;
        items.length > 0 && items.forEach((el) => {
          // eslint-disable-next-line no-unused-expressions
          el[this.props.lookupfield] && el[this.props.lookupfield].forEach(() => {
            counter += 1
          })
        })
        return (
          <Label  style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}> {counter}</Label>
        )

      } 
      if (this.props.specialfunction === 'countdistinct') {
        const tempitems = [];
        items.length > 0 && items.forEach((el) => {
          el[this.props.lookupfield] && el[this.props.lookupfield].forEach((opt) => {
            const findindex = tempitems.findIndex(el2 => el2 === opt.data.optionid)
            if (findindex === -1) {
              tempitems.push(opt.data.optionid)
            }
          })
        })
        return (
          items.length > 0 && <Label  style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{tempitems.length}</Label>
        )
      }
       if (this.props.specialfunction === 'oldest') {
       
        if (items.length > 1) {
          items.sort((a, b) => (a.addedon > b.addedon) ? 1 : -1)
          // console.log(items)
          return (
            items.length > 0 && <Label  style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield][0].data.option}</Label>
          )
        }
      }
      else if (this.props.specialfunction === 'newest') {
        items.length > 0 && items.sort((a, b) => (a.addedon < b.addedon) ? 1 : -1)
        return (
          items.length > 0 && <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield][0].data.option}</Label>
        )
      } 
      else if (this.props.specialfunction === 'mostrecent') {
        items.length > 0 && items.sort((a, b) => (a.updatedon < b.updatedon) ? 1 : -1)
        return (
          items.length > 0 && <Label style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield][0].data.option}</Label>
        )
      }
      else if (this.props.specialfunction === 'max') {
        //  console.log(items)
        items.length > 0 && items.sort((a, b) => (a[this.props.lookupfield][0].data.rank < b[this.props.lookupfield][0].data.rank) ? 1 : -1)
        // console.log(items[0][this.props.lookupfield][0].data.option)
        return (
          items.length > 0 && <Label style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center'}}>{items[0][this.props.lookupfield][0].data.option}</Label>
        )
      }
      else if (this.props.specialfunction === 'min') {
        items.length > 0 && items.sort((a, b) => (a[this.props.lookupfield][0].data.rank < b[this.props.lookupfield][0].data.rank) ? 1 : -1).reverse()
        return (
          items.length > 0 && <Label  style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{items[0][this.props.lookupfield][0].data.option}</Label>
        )
      }
    }
    return null;
  }

  createMultipleRelationTags = () => {
    //  This logic assumes that the data will be in first key, first field to display.
    // console.log(this.props.lookupfield);

    const items = this.scan(this.props.value);

    if (this.props.value != null) {
      if (this.props.specialfunction === "all") {
        return (
          items.length > 0 && items.map((el) => (
            el[this.props.lookupfield] && el[this.props.lookupfield].map((opt, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Label style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }} key={i}>{opt[Object.keys(opt)[0]][Object.keys(opt[Object.keys(opt)[0]])[0]]}</Label>
            ))
          ))
        )
      } 
      if (this.props.specialfunction === 'unique') {
        const tempitems = [];
        items.length > 0 && items.forEach((el) => {
          el[this.props.lookupfield] && el[this.props.lookupfield].forEach((opt) => {

            const findindex = tempitems.findIndex(el2 => el2 === opt[Object.keys(opt)[0]][Object.keys(opt[Object.keys(opt)[0]])[0]])
            if (findindex === -1) {
              tempitems.push(opt[Object.keys(opt)[0]][Object.keys(opt[Object.keys(opt)[0]])[0]])
            }
          })
        })
        return (
          tempitems.map((el, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Label key={i}  style={{paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{el}</Label>
          ))
        )
      } 
      if (this.props.specialfunction === "count") {
        let counter = 0;
        items.length > 0 && items.forEach((el) => {
          el[this.props.lookupfield] && el[this.props.lookupfield].forEach(() => {
            counter += 1;
          })
        })
        return (
          <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{counter}</Label>
        )
      } 
      if (this.props.specialfunction === "countdistinct") {
        const tempitems = [];
        items.length > 0 && items.forEach((el) => {
          el[this.props.lookupfield] && el[this.props.lookupfield].forEach((opt) => {
            const findindex = tempitems.findIndex(el2 => el2 === opt[Object.keys(opt)[0]][Object.keys(opt[Object.keys(opt)[0]])[0]])
            if (findindex === -1) {
              tempitems.push(opt[Object.keys(opt)[0]][Object.keys(opt[Object.keys(opt)[0]])[0]])
            }
          })
        })
        return (
          items.length > 0 && <Label  style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{tempitems.length}</Label>
        )
      }

       if (this.props.specialfunction === 'oldest') {
        if (items.length > 1) {
          items.sort((a, b) => (a.addedon > b.addedon) ? 1 : -1)
          const finalanwer = this.scanrelationarray(items[0],'field_1');
          return (
            items.length > 0 && <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{finalanwer}</Label>
          )
        }
      }
      
      if (this.props.specialfunction === 'newest') {
        items.length > 0 && items.sort((a, b) => (a.addedon < b.addedon) ? 1 : -1)
        const finalanwer = this.scanrelationarray(items[0],'field_1');
        return (
          items.length > 0 && <Label style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{finalanwer}</Label>
        )
      } 
      
      if (this.props.specialfunction === 'mostrecent') {
        items.length > 0 && items.sort((a, b) => (a.updatedon < b.updatedon) ? 1 : -1)
        const finalanwer = this.scanrelationarray(items[0],'field_1');
        return (
          items.length > 0 && <Label  style={{ paddingTop:'2px',paddingBottom:'2px', fontSize:'12px',alignSelf:'center' }}>{finalanwer}</Label>
        )
      }
    }
    return null;
  }

  DisplayResults = () => {

    if (this.props.lookupfield.includes('options')) {
      return this.createMultipleTags();
    } 
    
    if (this.props.lookupfield.includes('join')) {
      return this.createMultipleRelationTags()
    }
      return this.createtags();
  }


  render() {
    let renderResult = null;
    // DisplayLookupValues.defaultProps = {
    //     value: []
    //   };

    if (this.props.isEditor) {
      renderResult = (
        <NativeListener onMouseDown={this.stopMousedownPropagation}>
          <div style={this.editorContainerStyle} ref={this.editorRef}>
            <Label>Display Lookukp Values</Label>
            <button
            type="button"
              style={{ width: "100%", height: "33px", marginTop: "10px" }}
              onClick={this.applyColor.bind(this)}
            >
              Close
            </button>
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        // background: this.props.value,
        width: "450px",
        float: "left",
        marginRight: "15px",
      };

      renderResult = (
        <>
          <div style={colorboxStyle}>
            {/* {this.DisplayResults()} */}
            <Label>hello</Label>
          </div>
        </>
      );
    }

    return <>{renderResult}</>;
  }
}

export default LookupLookupValues;
