/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';
import BlockUserTable from './graphql/BlockUserTable.json';


export const blocksAPI = (zoneid) => {
  return cloudrun
    .get('/getUserBlocks', { params: { zoneid, isActive: 'true' } })
    .then((blocks) => {
      return blocks
        .filter((block) => block.isActive)
        .map((block) => ({
          ...block,
          id: block.blockid,
          key: block.blockid.toString(),
          sort: block.BlockUsers !==null ? block.BlockUsers[0]?.pos : 0,
          draft: block.information,
          information:block.information,
          iscompliant: block.isMaxRowsExceeded
        }));
    });
};

// 4-10-23 Adding this stub for implementing the idea where users can create a code
// associated with their block(s) that they can then send to other GB users. At some point
// we could add a UI element where users can glick to add a product/block, put in code and it will
// copy the designated set of block(s) into their current active zones. 
export const importBlock =(productCode) =>{
  return cloudrun
  .post('/addBlock', { params: { code:productCode } })
}

const AddBlockUserTable = async (blockId, zoneId, userid, name) => {

  // const node1 = BlockUserTable.columns.filter(itm => itm.data==='status')[0]
    BlockUserTable.filters[0].filter=`{blockid: {_eq: ${blockId}}},`
 
    const payload={blockid:blockId,name,ownerid:userid,zoneid:zoneId,tableinfo:BlockUserTable,recordname:'Record',icon:'M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z', dbfunction:'addBlockUserTable'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const addBlockAPI = async (userId, zoneId, { name, icon, color, sort }) => {

  let payload={addedby:userId,updatedby:userId,name,color,zoneid:zoneId,icon,dbfunction:'addBlock'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const blockId = dataresponse[0].blockid

   await AddBlockUserTable(blockId,zoneId,userId,name)

   payload={blockid:blockId,pos:sort,role:'Block builder',userid:userId,dbfunction:'addBlockUser'}
   await cloudrun.post('/executeQueryAPI', {payload})
   return {id:blockId}

};

export const copyBlock = async (data) => {
  let payload={data}
  const dataresponse = await cloudrun.post('/copyBlockUniversal', {payload})
  return dataresponse;
}

export const checkBlockCanBeCopied = async (blockid) =>{
  let payload={blockid,dbfunction:'checkBlockCanBeCopied'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse;
}

export const checkBlockHasMessageFields = async (blockid) =>{
  let payload={blockid,dbfunction:'checkBlockHasMessageFields'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const hasMessageFields = dataresponse.length>0;
  return hasMessageFields;
}


export const updateBlockAPI = async (block) => {

  const { id, name, icon, color,statement} = block;
  let information = block.draft;

  const payload={name,icon,color,statement,blockid:id,information,dbfunction:'updateBlock'}
  await cloudrun.post('/executeQueryAPI', {payload})
  return {id}
};


export const deleteBlockAPI = async (block) => {

  // 4-8-2019 changing to old logic for deployment to prod, has not been tested yet.
  const { id } = block;
  // const payload={blockid:id,dbfunction:'deleteBlock'}
  // await cloudrun.post('/executeQueryAPI', {payload})

  const payload={blockid:id}
  await cloudrun.post('/removeBlock', {payload})

  return "success"
};

export const sortAPI = async (userId, block) => {
  const { id, sort } = block;

  const payload={blockid:id,userid:userId,pos:sort,dbfunction:'sortAPI'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dbresponse

};

export const getBlockAPI = async (blockid,userid) => {

  const payload={blockid,userid,dbfunction:'getBlock'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const response = dbresponse[0].root;
  return response;
}

export const getUserBlocksSimplified = async () => {

  const payload={dbfunction:'getUserBlocksSimplified'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return  dbresponse[0].json_agg
}

export const updateBlockUserSettings = async (blockid,blocksettings) => {
  const payload={blockid,blocksettings,dbfunction:'updateBlockUserSettings'}
  await cloudrun.post('/executeQueryAPI', {payload})
}

export const updateDefaultTab = async (blockid,tab) => {
  const payload={blockid,tab,dbfunction:'updateDefaultTab'}
  await cloudrun.post('/executeQueryAPI', {payload})
}

export const getBlockUserSettings = async (blockid) => {
  const payload={blockid,dbfunction:'getBlockUserSettings'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})

  return dbresponse[0].settings
}

export const getBlocksAPI = async (zoneid,issystemblock=false) => {

  let payload={zoneid,issystemblock,dbfunction:'getBlocks'}
  const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const response = dbresponse[0].root;
  if(issystemblock==='all') //if coming from Users table, just return block object "as is"
  {
    return response
  } else {
  const blockitems=[];
  response.map(block => {
    const item = {key: block.blockid,value:block.blockid,text:block.name,icon:block.icon}
    blockitems.push(item);
  })
    return blockitems;
}
}


// 6-1-2020. This is query to retrieve the specific "Users" CustomTable that is associated with this block.
// Every Block will have a correspnding "Users" table defined which allows users to manage which users have access
// to the given block, add them, remove them and change their role. We are always going to call this table "Users".

export const GetUsersTableForBlock  = async (blockid) => {

  let payload={blockid,dbfunction:'getUsersTableforBlock'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

  const id = dataresponse[0].id
  return id;

}

export const getFirstTableInBlock = async(blockid) =>{
  let payload={blockid,dbfunction:'getFirstTableInBlock'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

  const name= dataresponse[0].name
  return name;

}
