/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import NativeListener from 'react-native-listener';
import { mdiArrowExpandAll, mdiPlus, mdiAccountGroup, mdiClose } from '@mdi/js';
import { Button, Dropdown, Icon as SIcon } from 'semantic-ui-react';
import { BaseEditorComponent } from '@handsontable/react';
import Icon from '@mdi/react';
import BlockUserControl from './BlockUserControl';

class BlockUsersRenderer extends BaseEditorComponent {
  state = {
    renderResult: null,
    value: '',
    userid: 0,
    results: [],
    selectedItems: [],
    workingItems: [],
    width: this.props.cellWidth,
    height: this.props.cellHeight,
    isExpanded: false,
    display: 'none',
    left: 20,
    top: 30,
    dataID: 0,
    isChanged: false,
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  showBlockUsersControl= () => {
    const { selectedItems,userid } = this.state;
    let filters = null;

    return (
      <BlockUserControl
        Close={this.close}
        items={selectedItems}
        Apply={this.updateGrid}
        userid={userid}
        color={this.props.color}
      />
    );
  };

  getValue() {
    return this.state.value;
  }

  // eslint-disable-next-line class-methods-use-this
  open() {
    this.setState({ display: 'block' });
  }

  close = async () => {
    this.setState({
      dataID: null,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: [],
      workingItems: [],
      value: null,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
    });
  };

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    if (row !== -1) {
      const tdPosition = td.getBoundingClientRect();
      const datarow = this.props.GetRowData(row);
      let userid=null;
        if(datarow !==null)
        {
          userid = datarow.dataID;
        }

      // 3/26/20 comment
      this.setState({
        userid,
        left: `${tdPosition.left + 1}px`,
        top: `${tdPosition.top + 2}px`,
        width: cellProperties.width - 4,
        height: this.props.cellHeight - 5,
      });

      if (originalValue !== undefined && originalValue !== '' && originalValue !== null) {
        const cleanItems = originalValue.filter((itm) => itm.id !== null);
        this.setState({ selectedItems: cleanItems });
      }
    }
  }

  // onPickedColor(color) {
  //   this.setValue(color.hex);
  // }

  propExists = (obj, path) => {
    // eslint-disable-next-line no-shadow
    return !!path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : undefined;
    }, obj);
  };

  ShowAddItems = (displayMode) => {
    const { top, left } = this.state;
    this.setState({
      width: '820px',
      height: '650px',
      left: window.innerWidth * 0.3,
      top: window.innerHeight * 0.1,
      isExpanded: true,
      displayMode,
    });
  };

  createtags = () => {
    if (this.props.value != null) {
      return (
        this.props.value.map &&
        this.props.value.map((el) =>
          // eslint-disable-next-line react/no-array-index-key
          el.blockid ? (
            <div
              style={{
                display: 'inline-flex',
                width: 'max-content',
                // flexDirection:'row',
                height: '20px',
                whiteSpace: 'nowrap',
                margin: '3px',
                paddingLeft: '5px',
                paddingRight: '5px',
                alignSelf: 'center',
                borderRadius: '7px',
                backgroundColor: `${this.props.color}B3`,
                color: 'white',
              }}
              key={el.blockid}
            >
              {el.name} - {el.role}
            </div>
          ) : null,
        )
      );
    }

    return null;
  };

  updateGrid = (blocks) => {
    // const { selectedItems } = this.state;

    this.setState(
      () => {
        return { value: blocks, isChanged: true };
      },
      () => {
        this.finishEditing();
      },
    );
  };

  addItem = (block) => {
    let { selectedItems } = this.state;

    // first check to see if Item is already in list, if it is, just return
    if (this.state.selectedItems && this.state.selectedItems.length > 0) {
      const itemalreadyexists = this.state.selectedItems.findIndex((ele) => ele.blockid === block.blockid);
      if (itemalreadyexists !== -1) {
        return;
      }
    }
  
    const items = selectedItems.concat(block);

    // 5-4-2020 isChanged indicates the user changed the value. This is used
    // in logic to force a refesh of the row in the grid, as we want to pull
    // any additional lookup fields, etc.
    this.setState({ selectedItems: items, isChanged: true });

  };

  showSelectedItems = () => {
    if (this.state.selectedItems && this.props.isEditor) {
      return this.state.selectedItems.map((el) => (
        <div
          onClick={() => this.removeItem(el.blockid, el.data)}
          style={{
            display: 'inline-flex',
            width: 'max-content',
            flexDirection: 'row',
            // margin: '3px',
            // marginTop:'5px',
            heigt: '20px',
            alignSelf: 'center',
            marginLeft: '3px',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '3px',
            marginBottom: '3px',
            paddingTop: '1px',
            paddingBottom: '1px',

            borderRadius: '7px',
            backgroundColor: `${this.props.color}B3`,
            color: 'white',
          }}
          key={el.blockid}
        >
          {el.name}-{el.role} <Icon path={mdiClose} size="15px" color="white" />
        </div>
      ));
    }
  };

  removeItem = (blockid, field) => {
    const { selectedItems, workingItems, isExpanded, selectmode } = this.state;
    const tmpselectedItem = [...selectedItems].filter((el) => el.blockid !== blockid);

    this.setState({
      selectedItems: tmpselectedItem,
      value: tmpselectedItem,
      isChanged: true,
    });
  };

  render() {
    let renderResult = null;

    if (this.props.isEditor) {
      renderResult = (
        <NativeListener
          onMouseDown={this.stopMousedownPropagation}
          onKeyDown={this.stopMousedownPropagation}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 1001,
              backgroundColor: 'white',
              // border:'1px solid blue',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              left: this.state.left,
              top: this.state.top,
              width: this.state.isExpanded ? '600px' :  this.state.width,
              height: this.state.height,
              display: this.state.display,
              overflow: this.state.isExpanded ? 'hidden' : 'hidden',
              borderRadius: this.state.isExpanded ? '10px' : '0px',
              boxShadow: this.state.isExpanded ? '0px 3px 6px #707070' : null,
            }}
          >
            {this.state.isExpanded ? (
              <div
                style={{
                  height: '42px',
                  display: 'flex',
                  alignItems: 'center',
                  // paddingLeft: '5px',
                  padding:'10px',
                  backgroundColor: this.props.color,
                }}
              >
                <Icon path={mdiAccountGroup} size="20px" color="white" />
                <div style={{ color: 'white', paddingLeft: '10px' }}>Current Blocks: blockname</div>
              </div>
            ) : (
              <>
                <div
                  onClick={() => this.ShowAddItems('addition')}
                  css={{
                    float: 'left',
                    cursor: 'pointer',
                    '&:hover': {
                      transition: 'all .3s ease',
                      fontWeight: 'bold',
                      svg: {
                        color: 'white',
                        backgroundColor: `${this.props.color}80`,
                        borderRadius: '30px',
                        transition: 'all .3s ease',
                      },
                    },
                  }}
                >
                  <Icon path={mdiPlus} size="16px" />
                </div>

                {this.state.selectedItems.length > 0 ? (
                  <div
                    onClick={() => this.ShowAddItems('review')}
                    css={{
                      float: 'right',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: 'all .3s ease',
                        svg: {
                          color: `${this.props.color}80`,
                          transition: 'all .3s ease',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiArrowExpandAll} size="16px" />
                  </div>
                ) : null}
              </>
            )}

            {!this.state.isExpanded ? (
              <div style={{ paddingTop: '3px', marginRight: '5px' }}>
                {this.showSelectedItems()}
              </div>
            ) : null}
            {this.state.isExpanded ? <>{this.showBlockUsersControl()}</> : null}
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        width: this.props.cellWidth,
        height: this.props.cellHeight - 7,
        // display:'inline-flex',
        float: 'left',
        zIndex: 0,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingTop:'5px',
        marginRight: '5px',
      };

      renderResult = <div style={colorboxStyle}>{this.createtags()}</div>;
    }

    return <>{renderResult}</>;
  }
}

export default BlockUsersRenderer;
