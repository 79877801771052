/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext } from 'react';

import SearchBar from '../../MessageView/SearchBar';
import TemplateViewer from '../../MessageView/TemplateViewer';
import {Popup} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getMessageTemplatesAPI, updateMessageSetting } from '../../../../../api/messages';
import { AccountContext } from '../../../../../providers/AccountProvider';
import cloudrun from '../../../../../clients/httpcloudrun';
import Spinner from '../../../../../components/Spinner/Spinner';
import Global from '../../../../../clients/global'

const SendTextMessages = ({
  selectedRecords,
  color,
  tableid,
  messageNode,
  close,
  role
}) => {
  const { userInfo } = useContext(AccountContext);
  const schema = `schema_${Global.zoneid}`;
  const [message, setMessage] = useState('');
  const [messageSetting, setMessageSetting] = useState({ interval: 'none', number: 1 });
  const [showTemplates, setShowTemplates] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState(null);
  const [templateFields, setTemplateFields]=useState(null);

  useEffect(() => {
    if (messageNode.selectedField !== undefined) {
      const GetMessageTemplates = async () => {
        const response = await getMessageTemplatesAPI(
          messageNode.selectedTable,
          messageNode.selectedField,
        );
        setMessageTemplates(response);
      };
      GetMessageTemplates();
    }
  }, [tableid]);

  const updateMsgSetting = async (msgSetting) => {
    const newsetting = {
      schema: schema,
      table: `tbl_${tableid}`,
      interval: msgSetting.interval,
      number: msgSetting.number,
    };
    const newAttributes = await updateMessageSetting(schema, msgSetting, tableid, userInfo);
    userInfo.systemattributes = newAttributes;
    setMessageSetting(newsetting);
  };

  const messageChangeHandler = (msg) => {
    setMessage(msg);
  };

  const appendTemplateMessage = async (id) => {

    let tempMsg = messageTemplates.filter(el=>el.id===id)[0].message;
    const tFields = [{field:"message",fieldValue:tempMsg}]

 
    if (message.slice(message.length - 1) === '#') {
      setMessage(message.slice(0, -1) + tempMsg);
    } else {
      setMessage(message + tempMsg);
    }
    setTemplateFields(tFields);
    setShowTemplates(false);
  };

  const ConvertDelayIntoSeconds = () => {
    let seconds = 0;
    if (messageSetting.interval === 'minutes') {
      return messageSetting.number * 60;
    }
    if (messageSetting.interval === 'hours') {
      return messageSetting.number * 3600;
    }
    if (messageSetting.interval === 'days') {
      return messageSetting.number * 86400;
    }
  };

  const SendMessage = async () => {
    const multipleRecipients = [];
    const zoneRole= userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].role
    selectedRecords.forEach((itm) => {
      multipleRecipients.push({ number: itm.number, id: itm.id });
    });
    // const multiplenumbers =selectedRecords.map(el=>{number:el[messageNode.phonefield]).join();

    let result = '';
    setIsLoading(true);
    const payload = {
      tableid,
      tablerowid: null,
      from: messageNode.phonenumber,
      to: multipleRecipients,
      templateFields,
      blockRoles:userInfo.blocks,
      role,
      message,
      fileUrls,
      zoneRole,
      schedule: messageSetting.interval === 'none' ? null : ConvertDelayIntoSeconds(),
    };
    if (messageSetting.interval === 'none') {
      result =await cloudrun.post('/sendSms', { payload });
    } else {
      result = await cloudrun.post('/scheduleDelivery', { payload });
    }
    setMessage('');
    setFileUrls(null)

    // Put error message in Toast.
      if(result !== 'success') {

        const errormessage = (
          <div>Oops, something went wrong. Issue(s) identified:
            {result.map(el=> (
              <ul><li>
              {el.error==='invalidFunds' ? <div>This Zone has depleted all available messaging funds. To continue sending messages, a Zone owner will need to purchase more funds under Zone settings (gear icon in left panel of the GraceBlocks Homepage).</div>: null}
              {el.error==='invalidInternational' ? <div>International message sending is currently disabled. Unless the Zone owner activates this Zone setting, the to and from country must match when sending messages.</div>: null}
              {el.error==='invalidRegion' ? <div>You tried to send one or more messages to a North American location we do not support. Visit our messaging page for the <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/countries/us" target="_blank">US</a> or <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/countries/ca" target="_blank">Canada</a> to learn more.</div>: null}
              {el.error==='invalidCountry' ? <div>You tried to send one or more messages to a country we don't support. Visit the <a style={{color:'white', textDecoration:'underline'}} href="http://www.graceblocks.com/messaging-pricing" target="_blank">GraceBlocks messaging pricing</a> to see all supported countries</div>: null}
              {el.error==='nofunds' ? <div>{el.message}</div>: null}
              </li></ul>
            ))}
          </div>
        )

      toast.info(errormessage, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setIsLoading(false);
    close();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '5px',
          marginBottom: '5px',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>Type a message to: 
        {selectedRecords.length>0 ? (
               <Popup
               hoverable
               content={
                <div style={{height:'400px',width:'350px',overflow:'auto'}}>
                {selectedRecords.map(itm=>`${itm.CustomID} (${itm.number})`).join('\n')}
                </div>
              }
               trigger={<span css={{color:color,  '&:hover': {color:`${color}80`}}}><b>{selectedRecords.length} records</b></span>}
               style={{ whiteSpace: 'pre-line' }}
             />
            ):null}
        </div>
      </div>
      {showTemplates ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '100',
            top: '50px',
            left: '170px',
            border: '1px solid #aaa',
            backgroundColor: 'white',
            // width: '610px',
            // height: '631px',
          }}
        >
          <TemplateViewer
            appendTemplateMessage={appendTemplateMessage}
            showTemplates={setShowTemplates}
            messageTemplates={messageTemplates}
            color={color}
          />
        </div>
      ) : null}
      {isLoading ? <Spinner /> : null}
      <SearchBar
        color={color}
        emojiPosition="0px"
        messageChangeHandler={messageChangeHandler}
        fileUrls={fileUrls}
        updateFileUrls={setFileUrls}
        message={message}
        isEnabled={true}
        Action={SendMessage}
        messageSetting={messageSetting}
        updateMessageSetting={updateMsgSetting}
        setShowTemplates={setShowTemplates}
        dataID={1} // 8-9-2020 this is used to close the emoji popup when in grid and navigatint records. Here we can just set to 1.
      />
    </div>
  );
};

export default SendTextMessages;
