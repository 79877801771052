/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

export const getNotificationCount= async (blockid) => {

    try{
        const payload={dbfunction:'getNotificationCount',blockid}
        const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
        return dbresponse;
    } catch(error) {
        console.log(error);
        return 0;
    }
}

export const getNotifications = async (blockid,includeConfetti=false) => {
    const payload={dbfunction:'getNotifications',blockid,includeConfetti}
    const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
    // console.log(dbresponse[0].json_agg);
     return dbresponse[0].json_agg;
}

export const deleteNotification= async (notificationid,blockid,removeAll=false) => {
    const payload={dbfunction:'deleteNotification',notificationid,blockid,removeAll:removeAll}
    const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
}

//The users param should be array of userid's that should receive the notification.
export const saveNotification= async (users,blockid,tableid,rowid,type,message,messageData) => {
    const payload={users,blockid,tableid,rowid,type,message,messageData}
    const dbresponse = await cloudrun.post('/saveNotificationAPI', {payload})
}

export const configureRecordNotifications = async(attributes) => {
    const payload={attributes}
    await cloudrun.post('/configureRecordNotifications', {payload})
}

export const clearNotificationCache = async(tableid) => {
    const payload={tableid}
    await cloudrun.post('/clearNotificationCache', {payload})
}