/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Dropdown,Radio,Form } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiMonitor } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBButton from '../../../../components/GBButton/GBButton';
import TextInput from '../../../../components/TextInput/TextInput';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import OptionColorPicker from '../ColumnConfig/OptionColorPicker';
import DuplicateControl from './components/DuplicateControl';
import TrackingPixelControl from './components/FormDesigner/TrackingPixelControl';
import RangeSlider from './components/RangeSlider';
import { saveForm } from '../../../../api/forms';
import Global from '../../../../clients/global'

const FormSettings = ({ form, close, tableinfo, color, togglePreview,plan }) => {
  const [currentForm, setCurrentForm] = useState(form);
  const [currentZone, setCurrentZone] = useState();
  const { userInfo } = useContext(AccountContext);


  useEffect(() => {
    const zoneList = [...userInfo.zones];
    const tempZone = zoneList.filter((itm) => itm.id === parseInt(Global.zoneid))[0];
    setCurrentZone(tempZone);
  }, []);

  const save = async (actions) => {

    await saveForm(currentForm);
    if (!actions.isClosed) {
      ShowToast('The Form has been saved!');
    }

    if (actions.isClosed) {
      close(actions.control, currentForm);
    }
  };

  const ShowToast = (message) => {
    toast.info(<div style={{margin:'10px'}}>{message}</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
    });
  };

  const fontSizes = [
    { key: 1, text: 'Small', value: '11px' },
    { key: 2, text: 'Medium', value: '14px' },
    { key: 3, text: 'Large', value: '17px' },
  ];

  const fontOptions = [
    { key: 1, text: 'Arial', value: 'Arial' },
    { key: 2, text: 'Georgia', value: 'Georgia' },
    { key: 3, text: 'Source Sans Pro', value: 'Source Sans Pro' },
    { key: 4, text: 'Tahoma', value: 'Tahoma' },
    { key: 5, text: 'Times New Roman', value: 'Times New Roman' },
  ];

  const updateAccentColors = (optionid, color, text) => {
    const tempForm = { ...currentForm };
    tempForm.settings.accentColors = { optionid: 0, option: 'TEXT', attributes: { color, text } };
    setCurrentForm(tempForm);
  };

  const updateFooterColors = (optionid, color, text) => {
    const tempForm = { ...currentForm };
    tempForm.settings.footerColors = { optionid: 0, option: 'TEXT', attributes: { color, text } };
    setCurrentForm(tempForm);
  };

  const updateFormBackgroundColor = (optionid, color, text) => {
    const tempForm = { ...currentForm };
    tempForm.settings.formBackgroundColor ={ optionid: 0, option: 'TEXT', attributes: { color, text } };
    setCurrentForm(tempForm);
  };

  const updateTextColor = (optionid, color, text) => {
    const tempForm = { ...currentForm };
    tempForm.settings.textColor ={ optionid: 0, option: 'TEXT', attributes: { color, text } };
    setCurrentForm(tempForm);
  };

  const updateFormAttribute = (attr, value) => {
    const tempForm = { ...currentForm };

    tempForm.settings[attr] = value;
    setCurrentForm(tempForm);
  };

  const updateLoginAttribute =(attr,value) => {
      if(plan==='Pro' || plan==='Enterprise' || plan==='Public') {
        updateFormAttribute(attr,value);
      } else {
        ShowToast('You need to upgrade to the Pro plan in order to use this feature.')
      }
  }


  const updateTrackingPixel = (attr,value,mode) => {
    const tempForm = { ...currentForm };

    if(mode==='add'){
      if (tempForm.settings[attr] !== undefined) {
        tempForm.settings[attr] = tempForm.settings[attr].concat(value);
      } else {
        tempForm.settings[attr] = [value];
      }
    } else {
      if( tempForm.settings[attr].length===1){
        delete  tempForm.settings[attr]
      }else {
        tempForm.settings[attr] =tempForm.settings[attr].filter(el=>el !==value)
      }
    }

    setCurrentForm(tempForm);
  };


  const updateFormName = (field, name) => {
    const tempForm = { ...currentForm };
    tempForm.name = name;
    setCurrentForm(tempForm);
  };

  const width = '454px';
  const defaultColors = {
    optionid: 0,
    option: 'TEXT',
    attributes: { color: '#E91E63', textColor: 'white' },
  };

  const defaultFormBackgroundColors = {
    optionid: 0,
    option: 'TEXT',
    attributes: { color: '#FFFFFF', textColor: 'black' },
  };

  const defaultTextColors = {
    optionid: 0,
    option: 'TEXT',
    attributes: { color: '#1A1A1A', textColor: 'white' },
  };

  return (
    <div style={{ width: '100%' }}>
      {/* -----------------TOP BAR ------------------- */}
      <div
        style={{
          display: 'flex',
          // border: '1px solid red',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '10px',
            marginRight: '45px',
            marginTop: '25px',
          }}
        >
          <TextButton text="Cancel" hoverColor="#75757580" Action={close} textColor="black" />
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <button
              css={{
                width: '50px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize: '13px',
                fontWeight: 'bold',
                border: '0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline: '0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus': {
                  border: '0px',
                  outline: '0px',
                },
              }}
              onClick={() => save({ isClosed: false, control: 'manager' })}
            >
              Save
            </button>
          </div>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <button
              css={{
                width: '150px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize: '13px',
                fontWeight: 'bold',
                border: '0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline: '0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus': {
                  border: '0px',
                  outline: '0px',
                },
              }}
              onClick={() => save({ isClosed: true, control: 'designer' })}
            >
              Save & go to designer
            </button>
          </div>
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <button
              css={{
                width: '100px',
                backgroundColor: color,
                color: 'white',
                height: '30px',
                borderRadius: '5px',
                fontSize: '13px',
                fontWeight: 'bold',
                border: '0px',
                paddingTop: '5px',
                paddingBottom: '5px',
                outline: '0px',
                '&:hover': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '&:focus': {
                  border: '0px',
                  outline: '0px',
                },
              }}
              onClick={() => save({ isClosed: true, control: 'manager' })}
            >
              Save & close
            </button>
          </div>
        </div>
        <div style={{ marginRight: '20px',height:'50px'}}>
          <GBButton
            text="Preview form"
            Action={togglePreview}
            ActionValue={form}
            iconPosition="left"
            textColor={color}
            iconSize="20px"
            textHoverColor={`${color}80`}
            hoverBorder={`1px solid ${color}`}
            iconHoverColor={`${color}80`}
            icon={mdiMonitor}
            iconColor={color}
          />
        </div>
      </div>
      {/* -----------------TOP BAR ------------------- */}
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
          height: 'calc(100vh - 250px)',
          flexDirection: 'column',
          // border: '1px solid blue',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ width }}>
          <TextInput
            value={currentForm.name}
            updateValue={updateFormName}
            fontWeight="bold"
            width="100%"
            name="FORM NAME"
            color={color}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width }}>
          <TextInput
            value={
              currentForm.settings.formTitle !== undefined
                ? currentForm.settings.formTitle
                : currentForm.name
            }
            field="formTitle"
            updateValue={updateFormAttribute}
            fontWeight="bold"
            width="100%"
            name="FORM PAGE TITLE"
            color={color}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div>
        <div
            style={{
              width,
              color: color,
              marginLeft: '20px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            FORM SIGN IN
          </div>
          
            <div style={{display:'flex',flexDirection:'row', alignItems:'center', marginLeft: '20px',width:'100%'}}>
            {/* <Form> */}
            <Form.Field>
                <Radio
                  label="Disabled"
                  name="login"
                  value="disabled"
                  checked={currentForm.settings.login === 'disabled' || currentForm.settings.login ===undefined}
                  onChange={(e, data) => updateLoginAttribute('login',data.value)}
                />
              </Form.Field>
              <div style={{width:"20px"}}> </div>
              <Form.Field>
              <Radio
                label="Optional"
                name="login"
                value="optional"
                checked={currentForm.settings.login === 'optional'}
                onChange={(e, data) =>updateLoginAttribute('login',data.value)}
              />
            </Form.Field>
            <div style={{width:"20px"}}> </div>
            <Form.Field>
              <Radio
                label="Mandatory"
                name="login"
                value="mandatory"
                checked={currentForm.settings.login === 'mandatory'}
                onChange={(e, data) => updateLoginAttribute('login',data.value)}
              />
            </Form.Field>
             {/* </Form> */}
            </div>

         

        </div>

        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div>
          <div
            style={{
              width,
              color: color,
              marginLeft: '20px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            OUTER FORM WIDTH (PX)
          </div>
          <div style={{ marginLeft: '20px',marginBottom:'20px' }}>
            <RangeSlider
              color={color}
              field="formWidth"
              min="600"
              max="2000"
              currentValue={currentForm.settings.formWidth}
              updateFieldValue={updateFormAttribute}
            />
          </div>
        </div>

        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div>
          <div
            style={{
              width,
              color: color,
              marginLeft: '20px',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            INNER FORM WIDTH (PX)
          </div>
          <div style={{ marginLeft: '20px',marginBottom:'20px' }}>
            <RangeSlider
              color={color}
              field="innerFormWidth"
              min="600"
              max="1000"
              currentValue={currentForm.settings.innerFormWidth !==undefined ? currentForm.settings.innerFormWidth : 600}
              updateFieldValue={updateFormAttribute}
            />
          </div>
        </div>


        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>FONT</div>
          <Dropdown
            selectOnBlur={false}
            fluid
            style={{
              borderLeft: `7px solid ${color}`,
              bordeTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
            }}
            selection
            value={currentForm.settings.font}
            onChange={(e, data) => updateFormAttribute('font', data.value)}
            options={fontOptions}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            isChecked={currentForm.settings.labelsInCaps}
            Action={updateFormAttribute}
            ActionValue={'labelsInCaps'}
            ActionValue2={!currentForm.settings.labelsInCaps}
            fontWeight="bold"
            color={color}
            text="Show labels in all CAPS"
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>FONT SIZE</div>
          <Dropdown
            selectOnBlur={false}
            fluid
            style={{
              borderLeft: `7px solid ${color}`,
              bordeTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
            }}
            selection
            value={currentForm.settings.fontSize}
            onChange={(e, data) => updateFormAttribute('fontSize', data.value)}
            options={fontSizes}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            color={color}
            isChecked={currentForm.settings.updateRecord}
            Action={updateFormAttribute}
            ActionValue={'updateRecord'}
            ActionValue2={!currentForm.settings.updateRecord}
            fontWeight="bold"
            text="Update vs. create new record when duplicates are identified"
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>

        {currentForm.settings.updateRecord ? (
          <>
            <div>
              <DuplicateControl
                type="duplicateFields"
                text="DUPLICATE CRITERIA"
                tooltip={<div>You can specify one or many fields, or combination of fields that when matching an existing record in the tab will cause a duplicate to be identified.  The record will then stack onto the existing record using the logic configured on the subsequent two fields.
                  <p/> To learn more, see <a href="https://www.graceblocks.com/support-article/web-form-duplicate-logic" target="_newwindow">web form duplicate logic.</a> </div> }
                selectedFields={currentForm.settings.duplicateFields}
                tableinfo={tableinfo}
                placeholder="Click + to add criterial fields set"
                color={color}
                form={currentForm}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>&nbsp;</div>
            <div>
              <DuplicateControl
                type="blockedFields"
                text="BLOCK UPDATES OF THESE FIELDS WHEN DUPLICATE"
                tooltip={<div>Specify any fields you do not want overwritten when a duplicate is identified.  The data entered on the form matching the fields specified here will be ignored when the update occurs.
                  <p/> To learn more, see <a href="https://www.graceblocks.com/support-article/web-form-duplicate-logic" target="_newwindow">web form duplicate logic.</a> </div> }
                selectedFields={currentForm.settings.blockedFields}
                tableinfo={tableinfo}
                placeholder="Click to select fields"
                color={color}
                form={currentForm}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>&nbsp;</div>
            <div>
              <DuplicateControl
                type="replaceMultipleFields"
                text="REPLACE VS. APPEND VALUES FOR MULTI-SELECT FIELDS"
                tooltip={<div>When duplicates are found, data from the 
                  incoming form appends to the existing record by
                  default. If you wish to override this default 
                  behavior and instead have data replaced with 
                  what is provided on the form, use this setting.
                  Specify the fields here where you want to override
                  the default behavior.
                  
                  <p/> To learn more, see <a href="https://www.graceblocks.com/support-article/web-form-duplicate-logic" target="_newwindow">web form duplicate logic.</a> </div> }
                selectedFields={currentForm.settings.replaceMultipleFields}
                tableinfo={tableinfo}
                placeholder="Click to select fields"
                color={color}
                form={currentForm}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
          </>
        ) : null}
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <TrackingPixelControl
          width={width}
          color={color}
          tooltip={<div>If you are using a 3rd party analytics tools, they may have 
            tracking pixels that need to be stored on the start
            and or finish pages of the web form for their tools to work. 
            Enter the URL for each tracking pixel you need to track.
            <p/> To learn more, see <a href="https://www.graceblocks.com/support-article/web-form-duplicate-logic" target="_newwindow">web form tracking pixels.</a> </div> }
          attribute="firstTrackingPixel"
          updateTrackingPixel={updateTrackingPixel}
          links ={currentForm.settings.firstTrackingPixel}
          label={
            <div>
              TRACKING PIXEL URL(S) FOR <u>FIRST</u> PAGE OF FORM
            </div>
          }
        />
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
      
        <TrackingPixelControl
          width={width}
          color={color}
          tooltip={<div>If you are using a 3rd party analytics tools, they may have 
            tracking pixels that need to be stored on the start
            and or finish pages of the web form for their tools to work. 
            Enter the URL for each tracking pixel you need to track.
            <p/> To learn more, see <a href="https://www.graceblocks.com/support-article/web-form-duplicate-logic" target="_newwindow">web form tracking pixels.</a> </div> }
          attribute="lastTrackingPixel"
          updateTrackingPixel={updateTrackingPixel}
          links ={currentForm.settings.lastTrackingPixel}
          label={
            <div>
              TRACKING PIXEL URL(S) FOR <u>LAST</u> PAGE OF FORM
            </div>
          }
        />
   

        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            color={color}
            fontWeight="bold"
            text="Include progress bar"
            isChecked={currentForm.settings.includeProgressBar}
            Action={updateFormAttribute}
            ActionValue={'includeProgressBar'}
            ActionValue2={!currentForm.settings.includeProgressBar}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            color={color}
            fontWeight="bold"
            text="Include print option"
            isChecked={currentForm.settings.includePrintButton}
            Action={updateFormAttribute}
            ActionValue={'includePrintButton'}
            ActionValue2={!currentForm.settings.includePrintButton}
          />
        </div>

        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            color={color}
            fontWeight="bold"
            text="Auto-reload form after 5 seconds upon successful form completion"
            isChecked={currentForm.settings.autoReload}
            Action={updateFormAttribute}
            ActionValue={'autoReload'}
            ActionValue2={!currentForm.settings.autoReload}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width }}>
          <TextInput
            fontWeight="bold"
            width="75px"
            name="MAX SIZE IN MB FOR ATTACHMENTS"
            color={color}
            value={currentForm.settings.maxFileSize}
            updateValue={updateFormAttribute}
            field="maxFileSize"
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width }}>
          <TextInput
            fontWeight="bold"
            width="100%"
            name="SOCIAL SHARE FORM TITLE"
            color={color}
            value={
              currentForm.settings.socialShareTitle !== undefined
                ? currentForm.settings.socialShareTitle
                : currentForm.name
            }
            updateValue={updateFormAttribute}
            field="socialShareTitle"
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div
          style={{
            marginLeft: '20px',
            width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <GBSwitch
            color={color}
            fontWeight="bold"
            text="Include Powered by GraceBlocks logo in footer"
            disabled={currentZone && currentZone.plan === 'Free'}
            isChecked={currentForm.settings.includeGraceBlocksLogo}
            Action={updateFormAttribute}
            ActionValue={'includeGraceBlocksLogo'}
            ActionValue2={!currentForm.settings.includeGraceBlocksLogo}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>
            ACCENT AND BUTTON COLOR
          </div>
          <OptionColorPicker
            autoSave={true}
            activeZone={currentZone}
            OptionData={
              currentForm.settings.accentColors !== undefined
                ? currentForm.settings.accentColors
                : defaultColors
            }
            color={color}
            ShowError={ShowToast}
            UpdateColor={updateAccentColors}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>FOOTER COLOR</div>
          <OptionColorPicker
            autoSave={true}
            activeZone={currentZone}
            OptionData={
              currentForm.settings.footerColors !== undefined
                ? currentForm.settings.footerColors
                : defaultColors
            }
            color={color}
            ShowError={ShowToast}
            UpdateColor={updateFooterColors}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>FORM BACKGROUND COLOR</div>
          <OptionColorPicker
            autoSave={true}
            toggleTextColor={false}
            activeZone={currentZone}
            OptionData={
              currentForm.settings.formBackgroundColor !== undefined
                ? currentForm.settings.formBackgroundColor
                :  defaultFormBackgroundColors
            }
            color={color}
            ShowError={ShowToast}
            UpdateColor={updateFormBackgroundColor}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width: '430px' }}>
          <div style={{ color: color, marginBottom: '5px', fontWeight: 'bold' }}>TEXT COLOR</div>
          <OptionColorPicker
            autoSave={true}
            toggleTextColor={false}
            isTextColor={true}
            activeZone={currentZone}
            OptionData={
              currentForm.settings.textColor !== undefined
                ? currentForm.settings.textColor
                :  defaultTextColors 
            }
            color={color}
            ShowError={ShowToast}
            UpdateColor={updateTextColor}
          />
        </div>

        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
        <div style={{ width }}>
          <TextInput
            isTextArea={true}
            fontWeight="bold"
            value={currentForm.settings.footerText}
            updateValue={updateFormAttribute}
            field="footerText"
            width="100%"
            name="FOOTER TEXT"
            color={color}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>&nbsp;</div>
      </div>
    </div>
  );
};

export default FormSettings;
