/* eslint-disable */
import React, { Component } from 'react';
import { mdiInformationOutline,mdiClose } from '@mdi/js';
import { Dropdown, Grid, GridColumn, GridRow, Input, Checkbox, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';
import { AccountContext } from '../../../../providers/AccountProvider';
import {
  getTableAPI,
  getTablesAPI,
  updateTableInfoAPI,
  getFieldsAPI,
  deleteTableData,
  checkIfNestedField,
  removeNestedConditions
} from '../../../../api/tables';
import { getBookmarks } from '../../../../api/bookmarks';
import { getBlockAPI, getBlocksAPI } from '../../../../api/blocks';
import Icon from '@mdi/react';
import cloudrun from '../../../../clients/httpcloudrun';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import IconButton  from '../FilterData/IconButton';
import { MapOptionValuesToText } from '../../../../api/GetData';
import Callout from './Callout';
import Global from '../../../../clients/global'


class RelationFieldSelector extends Component {
  // Receives tableid, fieldname as prop
  constructor(props) {
    super(props);
    this.dropdownRef1 = React.createRef(); 
    this.dropdownRef2 = React.createRef(); 

    const { columnConfigData } = this.props;
    this.state = {
      blocks: [],
      tables: [],
      bookmarkList1: [],
      bookmarkList2: [],
      selectedField: '',
      selectedBlock: 0,
      selectedTableid: 1000,
      blockname: '',
      isCustomID: false,
      relationtablename: '',
      isMultiple: columnConfigData.lookup !== 'single',
      isTabMultiple: true,
      showAdvanced: false,
      callout: columnConfigData.callout,
      defaultText: columnConfigData.defaultText,
      zindex: 3,
      deleteData: false,
      isLoading: false,
      useSecurity1: false, // 11-22-2021 adding 6 new security attributes to support security checkboxes.
      useSecurity2: false,
      useBookmark1: false,
      useBookmark2: false,
      bookmarkName1: '',
      bookmarkName2: '',
      showNesting: false,
      relationalFields: [],
      nestedFields:[],
      isNestedFieldsChanged: false,
      isPrivate: columnConfigData.isPrivate ?? false,
      isSubTable: columnConfigData.isSubTable ?? false
    };
  }

  static contextType = AccountContext;

  componentDidMount() {
    const { columnConfigData } = this.props;
    this.FetchBlocks();
    if (columnConfigData.source && columnConfigData.source.lookup === 'single')
      this.setState({ isTabMultiple: false });
  }

  FetchBlocks = async () => {
    this.setState({ isLoading: true });
    try {
      const { blockid, columnConfigData, tableid, plan } = this.props;
      const { userInfo } = this.context;
      const blocks = await getBlocksAPI(parseInt(Global.zoneid));

      blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)

      const blockswithIcons = [];
      blocks.forEach((itm) => {
        blockswithIcons.push({
          key: itm.key,
          value: itm.value,
          text: itm.text,
          icon: (
            <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
          ),
        });
      });

      let relationtable = '';
      let blockname = '';
      let tables = [];
      let sourceBookmarks = [];
      let destinationBookmarks = [];

      // 11-29-2021 new security & bookmark filter logic.
      const bookMarksDropdown1 = [];
      const bookMarksDropdown2 = [];
      let tmpBookmarkName1 = '';
      let tmpBookmarkName2 = '';
      let tmpUseSecurity1 = false;
      let tmpUseSecurity2 = false;
      let tmpUseBookmark1 = false;
      let tmpUseBookmark2 = false;
      let nestedFields=[];
      let relationFields=[];

      // if editing an already defind single/multi-select.
      if (columnConfigData.source && columnConfigData.source.lookuptableid !== undefined) {
        const tbl = await getTableAPI(columnConfigData.source.lookuptableid);
        relationtable = tbl.name;

        tables = await getTablesAPI(tbl.blockid);
        const blockresult = await getBlockAPI(tbl.blockid, userInfo.id);
        blockname = blockresult[0].name;

        // load bookmarks for first relationship
        if (plan !== 'Free') {
          sourceBookmarks = await getBookmarks(columnConfigData.source.lookuptableid,[],true);

          if (sourceBookmarks.length > 0 || columnConfigData.bookmarkName !== '') {
            sourceBookmarks.forEach((el) => {
              bookMarksDropdown1.push({ key: el.bookmarkid, value: el.bookmarkid, text: el.name });
            });

            if (columnConfigData.bookmarkName !== undefined) {
              const idx = sourceBookmarks.findIndex(
                (el) => parseInt(el.bookmarkid) === columnConfigData.bookmarkName,
              );
              if (idx === -1) {
                bookMarksDropdown1.push({
                  key: 0,
                  value: `${columnConfigData.bookmarkName}`,
                  text: `${columnConfigData.bookmarkName}-missing`,
                });
                tmpBookmarkName1 = `${columnConfigData.bookmarkName}`;
              } else {
                tmpBookmarkName1 = columnConfigData.bookmarkName;
              }
            }
          }
        }
      }

      tables = await getTablesAPI(parseInt(blockid), 3);
      tables.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)

      //11-22-2021 if non-Free plan, we load bookmarks, as it's a new option
      // to let users limit which records they see when viewing relational table.

      if (plan !== 'Free') {
        destinationBookmarks = await getBookmarks(tableid,[],true);

        //Load bookmarks for 2nd relationship.
        if (
          destinationBookmarks.length > 0 ||
          (columnConfigData.source && columnConfigData.source.bookmarkName !== '')
        ) {
          destinationBookmarks.forEach((el) => {
            bookMarksDropdown2.push({ key: el.bookmarkid, value: el.bookmarkid, text: el.name });
          });
          // check if they have selected bookmark. If yes, verify if it's still a valid bookmark in
          // list. If not, insert new option with format of <bookmarkName>-missing.
          if (columnConfigData.source && columnConfigData.source.bookmarkName !== undefined) {
            const idx = destinationBookmarks.findIndex(
              (el) => el.bookmarkid === columnConfigData.source.bookmarkName,
            );
            if (idx === -1) {
              bookMarksDropdown2.push({
                key: 0,
                value: `${columnConfigData.source.bookmarkName}`,
                text: `${columnConfigData.source.bookmarkName}-missing`,
              });
              tmpBookmarkName2 = `${columnConfigData.source.bookmarkName}`;
            } else {
              tmpBookmarkName2 = columnConfigData.source.bookmarkName;
            }
          }
        }

        if (columnConfigData.useSecurity !== undefined)
          tmpUseSecurity1 = columnConfigData.useSecurity;
        if (columnConfigData.source && columnConfigData.source.useSecurity !== undefined)
          tmpUseSecurity2 = columnConfigData.source.useSecurity;
        if (columnConfigData.useBookmark !== undefined)
          tmpUseBookmark1 = columnConfigData.useBookmark;
        if (columnConfigData.source && columnConfigData.source.useBookmark !== undefined)
          tmpUseBookmark2 = columnConfigData.source.useBookmark;
      }

      // If pro plan and editing existing relational field, load the possible nested fields
      if(['Pro','Enterprise','Public'].includes(plan) && columnConfigData.source && columnConfigData.source.lookuptableid !== undefined)
      {
        nestedFields =  columnConfigData.source && columnConfigData.source.nestedFields !==undefined ?  columnConfigData.source.nestedFields : []
        const {fields} = await getFieldsAPI(columnConfigData.source.lookuptableid);
        

        const singlefields=[2,5,9,10,11,16,22,24,25]
        const singleComplex=[1,7,8]
        // 8-29-22 maybe will implement support for lookup fields later, but decided today to just keep with
         //single value field.
         //11-10-23 added support for formula fields and lookups of single relational fields

        const validFields = fields.filter(fld=>fld.key !=='id' && (singlefields.includes(fld.uitype) || (singleComplex.includes(fld.uitype) && fld.lookup==='single')));
        
        //11-10-23 need to find lookup fields of single relational. Only way currently is to 
        //first identify the single relational field, then loop over these and get all lookups
        //of this single relational.
       
         fields.filter(itm=>itm.uitype===1 && itm.lookup==='single').map(el=>{
          const lookupfields = fields.filter(itm=>itm.displayfield===el.displayfield && itm.uitype===18 && ['relational-lookup','relational-special'].includes(itm.jointype) && [1,2,3,5,7,8,9,10,11,16].includes(itm.source.lookupuitype));
            lookupfields.map(col =>{
              validFields.push(col)
            })
         })

        validFields.map(el=>{
          const idx = nestedFields.findIndex(itm=>itm.field===el.value);
          if(idx ===-1) {
            relationFields.push(el);
          }
        })
         relationFields.unshift({key:0,value:0,text:'Choose a field'})
      }

      bookMarksDropdown1.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
      bookMarksDropdown2.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
      relationFields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)

      this.setState({
        blocks: blockswithIcons,
        selectedBlock: parseInt(blockid),
        tables,
        selectedTableid: tables[0].value,
        bookmarkList1: bookMarksDropdown1,
        bookmarkList2: bookMarksDropdown2,
        bookmarkName1: tmpBookmarkName1,
        bookmarkName2: tmpBookmarkName2,
        useSecurity1: tmpUseSecurity1,
        useSecurity2: tmpUseSecurity2,
        useBookmark1: tmpUseBookmark1,
        useBookmark2: tmpUseBookmark2,
        relationtablename: relationtable,
        blockname,
        nestedFields: nestedFields,
        showNesting: nestedFields.length>0,
        relationalFields:relationFields,
      });
    } catch (error) {
      console.log(error.message);
    }
    this.setState({ isLoading: false });
  };

  SelectedBlockHandler = async (blockid) => {
    //12-13-2021 added the role=3 for this API, which now requires a role to be passed.
    // since confuguring columns is only allowed for builders, we can just set the role-3 (builder)

    let tables = await getTablesAPI(blockid, 3);
    // 7-2-2020 react complains about including the "IsSystemtalb" in dropdown element...so I just remove it here.
    tables.forEach((itm) => {
      delete itm.isSystemTable;
    });

    tables.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
    this.setState({ tables: tables, selectedBlock: blockid,selectedTableid:tables[0].value });
    this.dropdownRef1.current.close(); 
  };

  // 6-30-2020, Updating this logic to always use customID. We have now moved the CustomID View outside
  // of the fields on the grid and always create the CustomID view when a table is created. They will
  // use the table Tab edit page to modify what is concatenedated to build this string, but we will
  // set this value of isCustomID = true , as this should always be true, unless we change logic in the future.


  SelectedTableHandler = async (tableid) => {

    const {fields} = await getFieldsAPI(tableid);
    const tbl = await getTableAPI(tableid);

    // 11-29-2021 first time configuring field, when they select tab, we need to lookup bookmarks.
    const tmpbookMarks = [];
    const sourceBookmarks = await getBookmarks(tableid,[],true);

    if (sourceBookmarks.length > 0) {
      sourceBookmarks.forEach((el) => {
        tmpbookMarks.push({ key: el.bookmarkid, value: el.bookmarkid, text: el.name });
      });
    }

    // if field_1 display=CustomID, we insert "isCustom" to properly display ID column data.
    const isCustomID = fields[1].displayfield === 'CustomID';
    const reltable = this.state.tables.filter((itm) => itm.value === tableid)[0].text;

    //7-19-22 Adding nested field functionality. For the selected relational table, filter on
    //just single value fields to save, which can be used for nesting.
    const singlefields=[2,5,9,10,11,16]
    const singleComplex=[1,7,8]

    //8-29-22 we may support lookup for nested fields, but for now, just single value standard fields.
    // const combo =[2,5,9,11,22,24,25,1,6,7,8]
    const validFields = fields.filter(fld=>fld.key !=='id' && (singlefields.includes(fld.uitype) || (singleComplex.includes(fld.uitype) && fld.lookup==='single')));

    //11-10-23 need to find lookup fields of single relational. Only way currently is to 
    //first identify the single relational field, then loop over these and get all lookups
    //of this single relational.
       
    fields.filter(itm=>itm.uitype===1 && itm.lookup==='single').map(el=>{
      const lookupfields = fields.filter(itm=>itm.displayfield===el.displayfield && itm.uitype===18 && ['relational-lookup','relational-special'].includes(itm.jointype) && [1,2,3,5,7,8,9,10,11,16].includes(itm.source.lookupuitype));
      lookupfields.map(col =>{
        validFields.push(col)
      })
    })
    
    validFields.unshift({key:0,value:'',text:'Choose one'})

    this.setState({
      selectedTableid: tableid,
      selectedField: 'field_1',
      isCustomID,
      relationtablename: reltable,
      bookmarkList1: tmpbookMarks,
      relationalFields:validFields,
      nestedFields:[],
      isSubTable:tbl.isSubTable
    });
    this.dropdownRef2.current.close();
  };

  SelectedFieldHandler = async (fieldname) => {
    this.setState({ selectedField: fieldname });
  };

  getRelationalFields = () =>{
    const {relationalFields} = this.state;
    let options =[];
    relationalFields.map(el=>{
      options.push({ key: el.data, value: el.data, text: el.header });
    })
    return options;
  }

  Save = async () => {
    const {
      tableid,
      tablename,
      isSourceCustomID,
      tableinfo,
      blockid,
      columnConfigData,
      header,
    } = this.props;
    const {
      isMultiple,
      isTabMultiple,
      selectedTableid,
      selectedField,
      callout,
      isCustomID,
      selectedBlock,
      defaultText,
      showNesting,
      isPrivate,
      isSubTable
    } = this.state;

    try {
      // If the field is currently not relational, we will allow update to relational field.

      const lookuptype = isMultiple ? 'multiple' : 'single';
      const tablookuptype = isTabMultiple ? 'multiple' : 'single';

      // save default textg.
      if (defaultText !== undefined) {
        const payload = {
          field: columnConfigData.data,
          tableid,
          defaultText,
          uitype: columnConfigData.uitype,
        };
        await cloudrun.post('/updateFieldDefaultText', { payload });
      }

      if (columnConfigData.uitype !== 1) {
        if (columnConfigData.uitype === 23) {
          const payload = {
            tableid,
            tableinfo,
            field: columnConfigData.data,
            newfieldtype: 'citext',
            castFunction: 'cast_numeric_to_text',
          };
          await cloudrun.post('/convertField', { payload });
          payload.dbfunction = 'deleteFieldValues';
          // await cloudrun.post('/executeQueryAPI', {payload})
        } else if (columnConfigData.uitype === 22) {
          const payload = {
            tableid,
            tableinfo,
            field: columnConfigData.data,
            newfieldtype: 'citext',
            castFunction: 'cast_date_to_text',
          };
          await cloudrun.post('/convertField', { payload });
          payload.dbfunction = 'deleteFieldValues';
        } else if (columnConfigData.uitype === 17) {
          const payload = {
            tableid,
            tableinfo,
            field: columnConfigData.data,
            newfieldtype: 'citext',
            castFunction: 'cast_integer_to_text',
          };
          await cloudrun.post('/convertField', { payload });
        } else if (columnConfigData.uitype === 6 || columnConfigData.uitype === 7) {
          const table = `tbl_${tableid}`;
          await MapOptionValuesToText(table, columnConfigData.data, 255);

          const payload = {
            tableid,
            fieldname: columnConfigData.data,
            removeRelatedStructureOnly: true,
          };
          await cloudrun.post('/removeField', { payload });
        }

        let payload = {
          tableid,
          header,
          fieldname: columnConfigData.data,
          lookuptype,
          tablookuptype,
          relationtableid: selectedTableid,
          relationfield: selectedField,
          isCustomID,
          isSourceCustomID,
          sourcetablename: tablename,
          selectedBlock,
          blockid,
          isSubTable
        };
        await cloudrun.post('/addRelationalField', { payload });

        await this.UpdateSchemaNodes(
          tableid,
          callout,
          lookuptype,
          tablookuptype,
          columnConfigData.source
        );
      } else {
        await this.UpdateSchemaNodes(
          tableid,
          callout,
          lookuptype,
          tablookuptype,
          columnConfigData.source
        );
      }

      return 'success';
    } catch (error) {
      return `An error occurred: ${error}`;
    }
  };

  // I am separating out updating Tableinfo from server side call so that we allow
  // users to update the selection type(single/multiple) and change/remove the Callout Text.
  // We don't allow changing of block/tables.

  // 7-2-202. We added a new toggle to relationship field allowing both source & destination table specifications
  // for single/multiple. As such, on each save, we need update both tables metadata.

  UpdateSchemaNodes = async (tableid, callout, lookuptype, tablookuptype, source) => {
    const { header, columnConfigData } = this.props;
    const {
      deleteData,
      isMultiple,
      selectedTableid,
      defaultText,
      useSecurity1,
      useSecurity2,
      bookmarkName1,
      bookmarkName2,
      useBookmark1,
      useBookmark2,
      nestedFields,
      showNesting,
      isNestedFieldsChanged,
      isPrivate,
      isSubTable
    } = this.state;


    const { tableinfo } = await getTableAPI(tableid);
    const schema = `schema_${Global.zoneid}`;
    let jointable = ''; // 7-3-2020, if they swap from multiple to single, we need to delete all data in jointable.

    const rowindex = tableinfo.columns.findIndex((el) => el.data === columnConfigData.data);

    if (rowindex !== -1) {
      tableinfo.columns[rowindex].header = header;
      tableinfo.columns[rowindex].uitype = 1;

      if (bookmarkName1 !== '') {
        tableinfo.columns[rowindex].bookmarkName = bookmarkName1;
        tableinfo.columns[rowindex].useBookmark = useBookmark1;
      } else {
        delete tableinfo.columns[rowindex].bookmarkName;
        tableinfo.columns[rowindex].useBookmark =false;
      }

      tableinfo.columns[rowindex].useSecurity = useSecurity1;
      
      
      //7-19-22 added nestedFields to the config data. 
      if(nestedFields.length>0 && showNesting){
        tableinfo.columns[rowindex].source.nestedFields=nestedFields;
      } else {
        delete tableinfo.columns[rowindex].source.nestedFields;
      }

      if (callout === '') {
        delete tableinfo.columns[rowindex].callout;
      } else {
        tableinfo.columns[rowindex].callout = callout;
      }
      if (defaultText === '') {
        delete tableinfo.columns[rowindex].defaultText;
      } else {
        tableinfo.columns[rowindex].defaultText = defaultText;
      }

      if(isPrivate) {
        tableinfo.columns[rowindex].isPrivate =true;
      } else {
        delete tableinfo.columns[rowindex].isPrivate;
      }

      delete tableinfo.columns[rowindex].readOnly;
      tableinfo.columns[rowindex].lookup = lookuptype;
      tableinfo.columns[rowindex].source.lookup = tablookuptype;
      tableinfo.columns[rowindex].source.useSecurity = useSecurity2;
      tableinfo.columns[rowindex].source.useBookmark = useBookmark2;

      if (bookmarkName2 !== '') {
        tableinfo.columns[rowindex].source.bookmarkName = bookmarkName2;
      } else {
        delete tableinfo.columns[rowindex].source.bookmarkName;
      }

      // if this field is currently used in relationl identifier & they are changing
      // selection == multiple, remove the field from relational identifier.
      if (tableinfo.columns[rowindex].concat !== undefined && isMultiple) {
        delete tableinfo.columns[rowindex].concat;
        const payload = { tableid, tableinfo, field: columnConfigData.data };
        await cloudrun.post('/updateCustomIdView', { payload });
      }

      delete tableinfo.columns[rowindex].maxlength;
      delete tableinfo.columns[rowindex].renderer;
    }
    await updateTableInfoAPI(tableid, tableinfo,columnConfigData);

    /* *********Update Destination table with new settings ************
      11-29-2021 old logic was to only updated destination table when relational field already defined.
     * I updated logic to always updated destination table. so securit/bookmark logic could be implemeted
     * here versus needing to update the AddRelational api logic.
     */

    //8-13-23 We don't update destination field if this is isSubTable. 
    if(!isSubTable) {
    const newsource = tableinfo.columns[rowindex].source;

    const reltable = await getTableAPI(newsource.lookuptableid);
    const relTableInfo = reltable.tableinfo;
    const relIndex = relTableInfo.columns.findIndex((itm) => itm.data === newsource.returnfield);
    if (relIndex !== -1) {
      relTableInfo.columns[relIndex].lookup = tablookuptype;
      relTableInfo.columns[relIndex].useSecurity = useSecurity2;
      relTableInfo.columns[relIndex].useBookmark = useBookmark2;

      if (bookmarkName2 !== '') {
        relTableInfo.columns[relIndex].bookmarkName = bookmarkName2;
      } else {
        delete relTableInfo.columns[relIndex].bookmarkName;
        relTableInfo.columns[relIndex].useBookmark=false;
      }

      relTableInfo.columns[relIndex].source.lookup = lookuptype;
      relTableInfo.columns[relIndex].source.useSecurity = useSecurity1;

      if (bookmarkName1 !== '') {
        relTableInfo.columns[relIndex].source.bookmarkName = bookmarkName1;
        relTableInfo.columns[relIndex].source.useBookmark = useBookmark1;
      } else {
        delete relTableInfo.columns[relIndex].source.bookmarkName;
        relTableInfo.columns[relIndex].source.useBookmark=false;
      }

      await updateTableInfoAPI(newsource.lookuptableid, relTableInfo,columnConfigData);
    }
  } //end !isSubTable
   /*******End update destination table */

    // if comeing from other field type, we need to try to match existing records with relationsl
    // this can only be done after the field is converted, hence why this is not in "Save" method
    // but called after so we get the updated tableinfo with new node.
    if (columnConfigData.uitype !== 1) {
      const relationalFields = [columnConfigData.data];

      // 8-17-2020 Convertig from a different field type, we try to map existing values to
      // existing relationsl records.
      const payload = { tableid, tableinfo, relationalFields, processAllRecords: true };
      await cloudrun.post('/mapRelationalValues', { payload });
    }

    if (deleteData) {
      await deleteTableData(schema, jointable);
    }

    if(isNestedFieldsChanged){
      await removeNestedConditions(tableid,columnConfigData.data);
    }

  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(<div style={{marginRight:'10px'}}>This feature requires the Pro plan. Upgrade today to start using this feature!</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  isMultipleHandler = async () => {
    const { isMultiple } = this.state;
    const { source,tableid,columnConfigData } = this.props;

    //8-4-22 Now need to check if field is being used in nested relational field definition.
    //if yes and they are trying to switch to multiple, don't allow.
    const nestedFields = await checkIfNestedField(columnConfigData.data,tableid);
    if(nestedFields.length>0){
      let fields=
      nestedFields.map(el=>(
        <ul>{el.name}: {el.header}</ul>
      ))

      toast.info(<div style={{marginRight:'10px'}}>This field is used in a relational nested field(s). It can not be changed to multi-select until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
      return false;
    }

    // this.setState({isMultiple: !isMultiple}, source !== undefined ? () =>this.SetWarningMessage() : null)
    this.setState({ isMultiple: !isMultiple });
  };

  toggleShowNesting = () =>{
    const { plan} = this.props;
    const {showNesting}=this.state;
    if(plan !=='Pro'){
      toast.info(
        <div style={{ margin: '10px' }}>To used nested fields, please upgrade to the Pro plan.</div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
        },
      );
    } else {
      this.setState({showNesting: !showNesting});
    }
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  updateNestedFields = (field) =>{
    let {nestedFields,relationalFields,isNestedFieldsChanged} = this.state
    const {columnConfigData} =this.props;

   if(field.field==='') {
    return false;
   }

   const idx = nestedFields.findIndex(el=>el.field===field.field);
   const idx2 = relationalFields.findIndex(el=>el.value===field.field);

   //11-10-23 added support for single relatinal lookup fields. Here we need to store the lookup uitype
   //so that in the nested control, we can pass the appropriate filter data (id, optionid, text val) to search.
   if(idx2 !==-1) {
    field.label = relationalFields[idx2].text;
    field.header= relationalFields[idx2].text;
    field.uitype= relationalFields[idx2].uitype;
    if(relationalFields[idx2].uitype===18) {
      field.lookupuitype=relationalFields[idx2].source.lookupuitype;
    }
   }

   if(idx ===-1){
    nestedFields.push(field)
   }
     relationalFields.splice(idx2,1);
     if(columnConfigData.uitype===1) {
      isNestedFieldsChanged=true;
     }

    this.setState({nestedFields,relationalFields,isNestedFieldsChanged});
  }


  removeNestedField =(field) =>{
    let {nestedFields,relationalFields,isNestedFieldsChanged} = this.state;
    const {columnConfigData} = this.props;
    const item={key:field.field,value:field.field,text:field.header,uitype:field.uitype}
    relationalFields.push(item);
    nestedFields = nestedFields.filter(el=>el.field !==field.field);
   
    if(columnConfigData.uitype===1) {
      isNestedFieldsChanged=true;
     }

    this.setState({nestedFields,relationalFields,isNestedFieldsChanged});
  }

  updateNestedFieldLabel=(field,value) =>{
    let {nestedFields} = this.state;
    const idx = nestedFields.findIndex(el=>el.field===field.field);
    nestedFields[idx].label=value;
    this.setState({nestedFields})
  }


  isTabMultipleHandler = async () => {
    const { isTabMultiple } = this.state;
    const { source,columnConfigData } = this.props;

     //8-4-22 Now need to check if field is being used in nested relational field definition.
    //if yes and they are trying to switch to multiple, don't allow. 
    //  9-22-22 we only check if this is existing relational field . If it's new, we can't /don't need to run this check as it's just being created.
    if(columnConfigData.uitype===1){
    const nestedFields = await checkIfNestedField(columnConfigData.source.returnfield,columnConfigData.source.lookuptableid);
    if(nestedFields.length>0){
      let fields=
      nestedFields.map(el=>(
        <ul>{el.name}: {el.header}</ul>
      ))

      toast.info(<div style={{marginRight:'10px'}}>This field is used in a relational nested field(s). It can not be changed to multi-select until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
      return false;
    }
  }


    this.setState({ isTabMultiple: !isTabMultiple });
  };

  updateSecurityOrBBookmarkField = (field) => {
    const { useSecurity1, useSecurity2, useBookmark1, useBookmark2 } = this.state;
    const { plan } = this.props;

    if (plan === 'Free' || plan==='Starter') {
      toast.info(
        <div style={{ margin: '10px' }}>To apply security, please upgrade to the pro plan. <a style={{color:'white',textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newWindow">Learn more here.</a> </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
        },
      );
      return false;
    }

    if (field === 'useSecurity1') this.setState({ useSecurity1: !useSecurity1 });
    if (field === 'useSecurity2') this.setState({ useSecurity2: !useSecurity2 });
    if (field === 'useBookmark1') this.setState({ useBookmark1: !useBookmark1 });
    if (field === 'useBookmark2') this.setState({ useBookmark2: !useBookmark2 });
  };

  // 7-3-2020 If they are changing a relation field from multiple to single,
  // we need to show warning message that all data will be deleted.

  // 1-7-2021 we decided to allow change from single/multiple and vice-versa and we will
  //keep all data. so if going from multiple to single, it allows this, but means there
  // could be records with multiple values still. They will need to clean this up via ui.

  // SetWarningMessage =() => {
  //   const {lookup, source,updateWarningMessage} = this.props;
  //   const {isMultiple, isTabMultiple,deleteData} = this.state;
  //   if((lookup==='multiple' && !isMultiple) || (source.lookup==='multiple' && !isTabMultiple)) {
  //     updateWarningMessage(2);
  //     this.setState({deleteData:true})
  //   } else {
  //     updateWarningMessage(0);
  //     this.setState({deleteData:false})
  //   }
  // }

  render() {
    const {
      isMultiple,
      isLoading,
      isTabMultiple,
      blocks,
      tables,
      showAdvanced,
      callout,
      selectedBlock,
      selectedTableid,
      relationtablename,
      blockname,
      zindex,
      defaultText,
      useSecurity1,
      useSecurity2,
      useBookmark1,
      useBookmark2,
      bookmarkName1,
      bookmarkName2,
      bookmarkList1,
      bookmarkList2,
      showNesting,
      relationalFields,
      nestedFields,
      isPrivate,
      isSubTable
    } = this.state;
    const { color, plan, tableid, columnConfigData } = this.props;
    return (
      <div style={{ width: '100%', marginRight: '10px' }}>
        <div style={{ marginTop: '5px' }}>
          Find a relational tab in this Block or other Blocks where data exists. Users will select one
          or more values from the selected Tab's dataset. Only users who can add data to the
          selected tab can add data to that tab while interacting with this field. If this field
          should only support single value selection, disable allowing multiple values under More
          field attributes.
        </div>
        {!isLoading ? (
          <Grid style={{ marginLeft: '10px' }}>
            <GridColumn>
              <GridRow>
                <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                  <div
                    style={{
                      fontSize: '12px',
                      marginBottom: '3px',
                      marginTop: '5px',
                      fontWeight: 'bold',
                    }}
                  >
                    Relational Block
                  </div>
                  {columnConfigData.uitype !== 1 ? (
                    <Dropdown
                      ref={this.dropdownRef1}
                      disabled={plan === 'Free'}
                      fluid
                      search
                      // style={{
                      //   zIndex: '4',
                      //   position: 'relative',
                      //   marginTop: '5px',
                      //   backgroundColor: '#eee',
                      // }}
                      placeholder="Blocks"
                      value={selectedBlock}
                      selection
                      options={blocks}
                      onChange={(e, data) => {
                        this.SelectedBlockHandler(data.value);
                      }}
                    />
                  ) : (
                    <div>{blockname}</div>
                  )}
                </div>
              </GridRow>
              <GridRow>
                <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                  <div
                    style={{
                      fontSize: '12px',
                      marginBottom: '3px',
                      marginTop: '5px',
                      fontWeight: 'bold',
                    }}
                  >
                    Relational tab
                  </div>
                  {columnConfigData.uitype !== 1 ? (
                    <Dropdown
                      ref={this.dropdownRef2}
                      fluid
                      search
                      placeholder="Tables"
                      upward
                      value={selectedTableid}
                      selection
                      options={tables}
                      onChange={(e, data) => {
                        this.SelectedTableHandler(data.value);
                      }}
                    />
                  ) : (
                    <span>{relationtablename}</span>
                  )}
                </div>
              </GridRow>
              <GridRow>
                <div style={{ marginTop: '15px' }}>
                  <GBSwitch
                    color={color}
                    text="More field attributes"
                    isChecked={showAdvanced}
                    Action={this.showAdvancedHandler}
                  />
                </div>
              </GridRow>

              {showAdvanced && isSubTable ? (<>
           
                    
                    <div style={{marginTop:'10px',marginBottom:'10px'}}>
                     <GBSwitch
                       color={color}
                       text="Private field"
                       isChecked={isPrivate}
                       Action={this.togglePrivate}
                     />
                     </div>

                     <div style={{ marginTop: '10px' }}>
                        <div>Apply control/security: selection and viewing access. <Popup
                           hoverable={true}
                            content={
                              <div>
                                To learn more about this capability,{' '}
                                <a
                                  href="https://www.graceblocks.com/support-article/faq-lock-icon"
                                  target="_newindow"
                                >
                                  click here.
                                </a>
                              </div>
                            }
                            mouseEnterDelay={200}
                            trigger={<Icon path={mdiInformationOutline} size="17px" />}
                          /></div> 
                        <div style={{ marginTop: '5px' }}>
                          <Checkbox
                            label={`By user access to records on the tab: ${relationtablename}.`}
                            checked={useSecurity1}
                            onChange={() => this.updateSecurityOrBBookmarkField('useSecurity1')}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >

                        </div>
                      </div>
                      <div
                          style={{
                            marginTop: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            label="By bookmark"
                            checked={useBookmark1}
                            onChange={() => this.updateSecurityOrBBookmarkField('useBookmark1')}
                          />
                          <div style={{ width: '10px' }} />
                          <Dropdown
                            value={bookmarkName1}
                            clearable
                            placeholder="Bookmarks"
                            selection
                            options={bookmarkList1}
                            onChange={(e, data) => this.setState({ bookmarkName1: data.value })}
                          />
                        </div>
                 </>
              ):null}

              {showAdvanced && !isSubTable ? (
                <GridRow>
                   <div style={{marginTop:'10px',marginBottom:'10px'}}>
                    <GBSwitch
                      color={color}
                      text="Private field"
                      isChecked={isPrivate}
                      Action={this.togglePrivate}
                    />
                    </div>
                  <div style={{ width: '440px', marginRight: '20px' }}>
                    <div style={{ marginLeft: '35px', marginTop: '15px' }}>
                      <div style={{ marginBottom: '5px' }}>
                        <b>Settings for here:</b>{' '}
                        <u>
                          <b>{columnConfigData.header}</b>
                        </u>
                      </div>
                      <GBSwitch
                        color={color}
                        text={
                          <div>
                            <b>Enable multi-select here</b>: This allows selecting multiple records
                            of <u>{relationtablename}</u> for a single record on{' '}
                            <u>{this.props.tablename}</u>.{' '}
                          </div>
                        }
                        isChecked={isMultiple}
                        Action={this.isMultipleHandler}
                      />

                      <div style={{ marginTop: '10px' }}>
                        <div>Apply control/security: selection and viewing access. <Popup
                           hoverable={true}
                            content={
                              <div>
                                To learn more about this capability,{' '}
                                <a
                                  href="https://www.graceblocks.com/support-article/faq-lock-icon"
                                  target="_newindow"
                                >
                                  click here.
                                </a>
                              </div>
                            }
                            mouseEnterDelay={200}
                            trigger={<Icon path={mdiInformationOutline} size="17px" />}
                          /></div> 
                        <div style={{ marginTop: '5px' }}>
                          <Checkbox
                            label={`By user access to records on the tab: ${relationtablename}.`}
                            checked={useSecurity1}
                            onChange={() => this.updateSecurityOrBBookmarkField('useSecurity1')}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            label="By bookmark"
                            checked={useBookmark1}
                            onChange={() => this.updateSecurityOrBBookmarkField('useBookmark1')}
                          />
                          <div style={{ width: '10px' }} />
                          <Dropdown
                            value={bookmarkName1}
                            clearable
                            placeholder="Bookmarks"
                            selection
                            options={bookmarkList1}
                            onChange={(e, data) => this.setState({ bookmarkName1: data.value })}
                          />
                        </div>
                      </div>

                      <div style={{marginTop:'10px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <GBSwitch
                        color={color}
                        text={
                          <div>
                            Enable nested fields
                          </div>
                        }
                        isChecked={showNesting}
                        Action={this.toggleShowNesting}
                      />
                      <div style={{width:'5px'}}> {` `} </div>
                       <Popup
                           hoverable={true}
                            content={
                              <div>
                                To learn more about this capability,{' '}
                                <a
                                  href="https://www.graceblocks.com/support-article/adding-nesting-capabilities-to-relational-fields"
                                  target="_newindow"
                                >
                                  click here.
                                </a>
                              </div>
                            }
                            mouseEnterDelay={200}
                            trigger={<Icon path={mdiInformationOutline} size="17px" />}
                          />
                    </div>
                    
                    {showNesting ? (<div style={{marginTop:'10px'}}>
                      <div>
                        <Dropdown
                            value={0}
                            fluid
                            placeholder="Nest fields"
                            selection
                            options={relationalFields}
                            onChange={(e, data) => this.updateNestedFields({field:data.value})}
                          />
                      </div>
                      <div style={{display:'flex',flexDirection:'column', marginTop:'10px',marginBottom:'10px'}}>
                      {nestedFields.length>0 ? nestedFields.map(fld => (
                      <div style={{marginTop:'10px',width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <Input onChange={(e,data)=>this.updateNestedFieldLabel(fld,data.value)}  style={{width:'100%',marginRight:'10px'}} defaultValue={fld.label} label={fld.header} labelPosition='left' />
                        <IconButton color="red" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={this.removeNestedField} ActionValue={fld}  />
                      </div>
                      )) : null}
                      </div>

                   </div> ): null}
                    </div>

   

                    <div style={{ marginLeft: '35px', marginTop: '15px' }}>
                      <div style={{ marginBottom: '5px' }}>
                        <b>Settings for there:</b>{' '}
                        <u>
                          <b>{this.props.tablename}</b>
                        </u>
                      </div>
                      <GBSwitch
                        color={color}
                        text={
                          <div>
                            <b>Enable multi-select there</b>: This allows selecting multiple records
                            of <u>{this.props.tablename}</u> for a single record on{' '}
                            <u>{relationtablename}</u>.{' '}
                          </div>
                        }
                        isChecked={isTabMultiple}
                        Action={this.isTabMultipleHandler}
                      />

                      <div style={{ marginTop: '10px' }}>
                        <div>
                          Apply control/security: selection and viewing access.{' '}
                          <Popup
                           hoverable={true}
                            content={
                              <div>
                                To learn more about this capability,{' '}
                                <a
                                  href="https://www.graceblocks.com/support-article/faq-lock-icon"
                                  target="_newindow"
                                >
                                  click here.
                                </a>
                              </div>
                            }
                            mouseEnterDelay={200}
                            trigger={<Icon path={mdiInformationOutline} size="17px" />}
                          />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                          <Checkbox
                            label={`By user access to records on the tab: ${this.props.tablename}.`}
                            checked={useSecurity2}
                            onChange={() => this.updateSecurityOrBBookmarkField('useSecurity2')}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '5px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            label="By bookmark"
                            checked={useBookmark2}
                            onChange={() => this.updateSecurityOrBBookmarkField('useBookmark2')}
                          />
                          <div style={{ width: '10px' }} />
                          <Dropdown
                            value={bookmarkName2}
                            clearable
                            placeholder="Bookmarks"
                            selection
                            options={bookmarkList2}
                            onChange={(e, data) => this.setState({ bookmarkName2: data.value })}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                      <div style={{ marginBottom: '3px' }}>
                        <strong>Default value</strong>
                      </div>
                      <Input
                        size="small"
                        onChange={(e, data) => this.setState({ defaultText: data.value })}
                        value={defaultText || ''}
                        placeholder="Enter default value (optional)"
                      />
                    </div>

                    <Callout callout={callout} updateCallout={this.CalloutHandler} />
                  </div>
                </GridRow>
              ) : null}
            </GridColumn>
          </Grid>
        ) : null}
      </div>
    );
  }
}

RelationFieldSelector.propTypes = {
  tableid: PropTypes.number.isRequired,
  uitype: PropTypes.number.isRequired,
  fieldname: PropTypes.string.isRequired,
};

export default RelationFieldSelector;
