/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { toast } from 'react-toastify';
import { mdiCloseCircle, mdiArrowLeft } from '@mdi/js';
import FormObject from './FormObject';
import FormEditObject from './FormEditObject';
import ImageUploader from './components/ImageUploader';
import FormDesigner_TopBar from './FormDesigner_TopBar';
import GBButton from '../../../../components/GBButton/GBButton';
import { AlphaCodes } from '../../../../utils/alphaCodes';
import DraggableDiv from './DraggableDiv';
import BannerPlaceholder from './components/FormDesigner/BannerPlaceholder';
import { saveForm } from '../../../../api/forms';
import { getOptionsAPI } from '../../../../api/options';
import DeleteImageIcon from './components/FormDesigner/deleteImageIcon';
import { getLogoPlacement } from './components/getLogoPlacement';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
});

class FormDesigner extends Component {
  state = {
    loadingElement:false,
    isLoading: false,
    selected: [],
    overlayOpacity: 70,
    imageHeight: 30,
    logoHeight: 100,
    logoPlacement: 1,
    alphaCode: '80',
    form: null,
    x: 0,
    y: 0,
    logoX: 0,
    logoY: 0,
    startX: 0,
    startY: 0,
    logoStartX: 0,
    logoStartY: 0,
    logoCoords: {},
  };

  resetDesigner = () => {
   
  };

  async componentDidMount() {
    const { tableinfo, form, tableid } = this.props;
    this.setState({ isLoading: true });
    const tempItems = [];

    const loadData = async () => {
      return Promise.all(
        tableinfo.columns
          .filter((field) => [1, 2, 3, 4, 5, 6, 7,8, 9, 10, 11, 22, 23, 13, 14].includes(field.uitype))
          .filter((field2) => field2.data !== 'id')
          .map(async (el) => {
            if (el.uitype === 4) {
              const options = await getOptionsAPI(el.data, tableid);

              options.map((itm) => {
                let id = `${el.data}-${itm.optionid}`;
                let name = `${el.header} -  ${itm.option}`;
                let idx = -1;

                if (form.columnsettings !== undefined && form.columnsettings !== null) {
                  idx = form.columnsettings.findIndex((ele) => ele.id === id);
                  if (idx !== -1) {
                    form.columnsettings[idx].header = name;
                    // if(form.columnsettings[idx].header=form.columnsettings[idx].label)
                    // {
                    //   form.columnsettings[idx].label=name;
                    // }
                  }
                }

                if (idx === -1) {
                  tempItems.push({
                    id: id,
                    data: el.data,
                    label: name,
                    header: name,
                    fieldInfo: el,
                    categoryid: itm.optionid,
                    categoryLabel: itm.option,
                    type: 'field',
                    required: false,
                    tooltip: '',
                    listDisplay: 'button',
                    maxAttachments: 3,
                  });
                }
              });
            } else {
              //Not attachment field, so process with stq
              let idx = -1;
              if (form.columnsettings !== undefined && form.columnsettings !== null) {
                idx = form.columnsettings.findIndex((itm) => itm.id === el.data);
              }

              if (idx === -1) {
                tempItems.push({
                  id: el.data,
                  data: el.data,
                  label: el.header,
                  header: el.header,
                  fieldInfo: el,
                  type: 'field',
                  required: false,
                  tooltip: '',
                  listDisplay: 'button',
                  maxAttachments: 3,
                });
              }
            }
          }),
      );
    };

    await loadData();

    let x = 0;
    let y = 0;
    let logoX = 0;
    let logoY = 0;
    let logoHeight = 100;
    let logoPlacement = 1;
    let logoCoordsTemp = { top: 0, left: 0, right: 0, bottom: 0 };

    if (form.settings.banner !== undefined) {
      x = form.settings.banner.left;
      y = form.settings.banner.top;
    }

    if (form.settings.logo !== undefined) {
      logoX = form.settings.logo.left;
      logoY = form.settings.logo.top;
      logoHeight = form.settings.logo.height;
      logoPlacement = form.settings.logo.placement;
      
      if(form.settings.logo.placement !==1) {
      logoCoordsTemp = getLogoPlacement(
        form.settings.logo.placement,
        form.settings.logo.height,
        form.settings.formWidth,
      );
      }


    }

    this.setState({
      items: tempItems,
      form,
      selected: form.columnsettings !== null ? form.columnsettings : [],
      x,
      y,
      logoX,
      logoY,
      logoCoords: logoCoordsTemp,
      logoHeight,
      logoPlacement,
      isLoading: false,
      overlayOpacity: form.settings.overlayOpacity,
      imageHeight: form.settings.imageHeight,
    });
  }

  sleep=(ms)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  addFormElement = async (type) => {

    this.setState({loadingElement:true})
    // await this.sleep(500)

    const { items } = this.state;
    const tempItems = [...items];
    let typename='';
    if(type==='pb') typename='Page Break'
    if(type==='rtf') typename='Rich Text Editor'
    if(type==='h1') typename='Header 1'
    if(type==='h2') typename='Header 2'
    if(type==='h3') typename='Header 3'

    const d = new Date();
    const newid = `${type}:${d.toLocaleTimeString('en')}`;

    const name = `${typename.toUpperCase()}: ${d.toLocaleTimeString('en')}`;
    const newElement = { id: newid, label: name, header: name, type: type };
    if(type==='pb')
    {
      newElement.header='Continue'
      newElement.button='Continue'
    } 
    
    // 5-10-2021 To solve issue with clicking add button multiple times and generating same id, 
    // i will remove the "sleep" function and instead only insert the item if the id isn't already in the array
    const idx = tempItems.findIndex(el=>el.id===newid)
    if(idx ===-1){
      tempItems.unshift(newElement);
    }
    
    this.setState({ items: tempItems,loadingElement:false});
   
  };

  removeFormElement = (id) => {
    const { items } = this.state;
    const tempItems = [...items].filter((el) => el.id !== id);
    this.setState({ items: tempItems });
  };

  removeItemFromSelectedArray = (id) => {
    const { items, selected } = this.state;
    let tempSelected = [...selected];
    //Get Item from selected array
    const tempItem = tempSelected.filter((itm) => itm.id === id)[0];
    //Add to list of available fields.

    const tempItems = [...items];
    tempItems.push(tempItem);

    //remove from selected items
    tempSelected = tempSelected.filter((el) => el.id !== id);
    this.setState({ items: tempItems, selected: tempSelected });
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: 'items',
    droppable2: 'selected',
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { form, selected } = this.state;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // Don't allow them to move the Thankyou.
    if (result.draggableId.startsWith('thankyou')) {
      return;
    }

    // Get index of "Thank you Component"
    const thankYouIndex = selected.findIndex(el=>el.id==='thankyou')
    // Don't allow them to move fields /page breaks below the Thank you section from left Panel
    if ((result.draggableId.startsWith('field_') || result.draggableId.startsWith('pb'))  && result.destination.index > thankYouIndex) {
      return;
    }


    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.getList(source.droppableId), source.index, destination.index);

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination,
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  save = () => {
  };

  updateForm = (height, opacity, logoHeight, placement) => {
    let acode='FF';
    const idx =AlphaCodes.findIndex(el=>el.setting===parseInt(opacity))
    if(idx !==-1) {
      acode = AlphaCodes[idx].code;
    }
    
    const { form } = this.state;
    let logoCoordsTemp={top:0,left:0}
    if(placement !==1) {
        logoCoordsTemp = getLogoPlacement(placement, logoHeight, form.settings.formWidth);
    }

    this.setState({
      imageHeight: height,
      overlayOpacity: opacity,
      alphaCode: acode,
      logoHeight: logoHeight,
      logoPlacement: parseInt(placement),
      logoCoords: logoCoordsTemp,
    });
  };


  updateFieldLabel = (field, value) => {
    const { selected } = this.state;
    const tempFields = [...selected];
    const idx = tempFields.findIndex((itm) => itm.id === field);

    // 4-13-2021 adding support for storing RTF content. If the field starts with 'rtf', we know it's a RTF editor
    // in this use-case, save the content to the content attribute (label stays the same.)
    if (field.startsWith('rtf')) {
      tempFields[idx].content = value;
    } else if (field.startsWith('pb') || field.startsWith('thankyou')) // if page break or thank you, change header instead of label to show continue button value
    {
      tempFields[idx].button=value;
    }
    else {
      tempFields[idx].label = value;
    }

    this.setState({ selected: tempFields });
  };

  updateFieldAttribute = (field, attribute, value) => {
    const { selected } = this.state;
    const tempFields = [...selected];
    const idx = tempFields.findIndex((itm) => itm.id === field);

    tempFields[idx][attribute] = value;
    this.setState({ selected: tempFields });
  };

  updateFieldValue =(field,value) => {
  }

  // updateFieldToolTip = (field, tooltip) => {
  //   const { selected } = this.state;
  //   const tempFields = [...selected];
  //   const idx = tempFields.findIndex((itm) => itm.id === field);
  //   tempFields[idx].tooltip = tooltip;
  //   this.setState({ selected: tempFields });
  // };

  // updateFieldListDisplay = (field, listDisplay) => {
  //   const { selected } = this.state;
  //   const tempFields = [...selected];
  //   const idx = tempFields.findIndex((itm) => itm.id === field);
  //   tempFields[idx].listDisplay = listDisplay;
  //   this.setState({ selected: tempFields });
  // };

  _moveBanner = (x, y) => {
    const { startX, startY, form } = this.state;
    this.setState({
      x: x - startX + form.settings.banner.left,
      y: y - startY + form.settings.banner.top,
    });
    // console.log(`${x - startX} -- ${y - startY}`)
  };

  _moveLogo = (x, y) => {
    const { logoStartX, logoStartY, form } = this.state;
    this.setState({
      logoX: x - logoStartX + form.settings.logo.left,
      logoY: y - logoStartY + form.settings.logo.top,
    });
    // console.log(`${x - startX} -- ${y - startY}`)
  };

  _onStartBanner = (x, y) => {
    const { startX } = this.state;
    if (startX === 0) {
      this.setState({ startX: x, startY: y });
    }
  };

  _onStartLogo = (x, y) => {
    const { logoStartX } = this.state;
    if (logoStartX === 0) {
      this.setState({ logoStartX: x, logoStartY: y });
    }
  };

  getMeta = (url) => {
    var img = new Image();
    let isValidLength = true;
    img.addEventListener('load', function() {
      isValidLength = this.naturalWidth >= 1200;
      alert(this.naturalWidth + ' ' + this.naturalHeight);
    });
    img.src = url;
    return isValidLength;
  };

  updateHeaderImages = (type, file) => {
    const loadData = (width, height) => {
      const { form } = this.state;
      const tempForm = { ...form };
      file.top = 0;
      file.left = 0;
      file.naturalHeight = height;
      file.naturalWidth = width;
      if (type === 'banner') {
        tempForm.settings.banner = file;
      } else {
        tempForm.settings.logo = file;
      }

      this.setState({ form: tempForm });
    };
    //if loading a banner image, we must ensure it's at least 1200 pixels wide.
    var img = new Image();
    let isValidLength = true;
    img.addEventListener('load', function() {
      if (this.naturalWidth >= 1200 || type === 'logo') {
        loadData(this.naturalWidth, this.naturalHeight);
      } else {
        toast.info(
          <div style={{ marginRight: '5px' }}>
           The image must be at least 1200 pixels in width.
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
          },
        );
      }
    });
    img.src = file.url;
  };

  saveFormLocal = async (actions) => {
    const {
      form,
      x,
      y,
      logoX,
      logoY,
      logoHeight,
      logoPlacement,
      overlayOpacity,
      imageHeight,
      selected,
      logoCoords,
    } = this.state;
    form.settings.overlayOpacity = overlayOpacity;
    form.settings.imageHeight = imageHeight;
    form.columnsettings = selected;

    if (form.settings.banner !== undefined) {
      form.settings.banner.top = y;
      form.settings.banner.left = x;
    }

    if (form.settings.logo !== undefined) {
      form.settings.logo.top = logoPlacement === 1 ? logoY : logoCoords.top;
      form.settings.logo.left = logoPlacement === 1 ? logoX : logoCoords.left;
      form.settings.logo.bottom = logoPlacement === 1 ? null : logoCoords.bottom;
      form.settings.logo.right = logoPlacement === 1 ? null : logoCoords.right;
      form.settings.logo.height = parseInt(logoHeight);
      form.settings.logo.placement = parseInt(logoPlacement);
    }

    await saveForm(form);
    if (!actions.isClosed) {
      toast.info(`The form has been saved!`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    if (actions.isClosed) {
      this.props.close(actions.control,form);
    }
  };

  deleteImage = (type) => {
    const { form } = this.state;
    const tempForm = { ...form };
    if (type === 'banner') {
      delete tempForm.settings.banner;
    } else {
      delete tempForm.settings.logo;
    }
    this.setState({ form: tempForm });
  };

  render() {
    const { close, tableid, blockid, togglePreview,tableinfo } = this.props;
    const {
      imageHeight,
      overlayOpacity,
      logoHeight,
      logoPlacement,
      items,
      alphaCode,
      x,
      y,
      logoX,
      logoY,
      logoCoords,
      loadingElement,
      form,
      isLoading,
      selected
    } = this.state;

    const color =
      form && form.settings.accentColors !== undefined
        ? form.settings.accentColors.attributes.color
        : this.props.color;

    return (
      <div>
        {form !== null ? (
          <>
            <FormDesigner_TopBar
              loadingElement={loadingElement}
              color={this.props.color}
              form={form}
              close={close}
              save={this.saveFormLocal}
              addFormElement={this.addFormElement}
              formHeight={imageHeight}
              formOpacity={overlayOpacity}
              logoHeight={logoHeight}
              logoPlacement={logoPlacement}
              updateForm={this.updateForm}
              togglePreview={togglePreview}
            />

            <DragDropContext onDragEnd={this.onDragEnd}>
              <div
                style={{
                  display: 'flex',
                  height: 'calc(100vh - 270px)',
                  //   border: `1px solid ${color}`,
                  margin: '20px',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <div style={{ width: '25%' }}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          // border:'1px solid red',
                          marginRight: '10px',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          height: 'calc(100vh - 270px)',
                        }}
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <div style={{ fontWeight: 'bold', marginLeft: '20px', fontSize: '16px' }}>
                          Available fields and elements
                        </div>
                        {items &&
                          items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FormObject
                                    id={item.id}
                                    name={item.type === 'field' ? item.header : item.label}
                                    type={item.type}
                                    removeFormElement={this.removeFormElement}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div style={{ width: '50px' }}></div>
                {/*----------------------------------- RIGHT HAND SIDE OF DESIGNER --------------------------*/}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    alignItems: 'center',
                    width: '65%',
                    maxWidth: `${form.settings.formWidth}px`,
                    minWidth: '300px',
                    border: `1px solid ${color}`,
                    height: 'calc(100vh - 250px)',
                    // height: '100%',
                    overflow: 'auto',
                  }}
                >
                  {/* Need to put the banner delete icon in this specific DIV so that, even if the user sets screen
              width to 0, the delete icon will remain. This DIV should only appear in the form.settings.banner !==undefined */}

                  <BannerPlaceholder
                    banner={form.settings.banner}
                    deleteImage={this.deleteImage}
                    updateHeaderImages={this.updateHeaderImages}
                    color={color}
                  />

                  {/*   {/* WE HAVE LOGO WITH SET PLACEMENT AT TOP/MIDDLE */}
                  {form &&
                  form.settings.logo !== undefined &&
                  [5, 6, 7, 8, 9, 10].includes(logoPlacement) ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: window.innerHeight * 0.25,
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          zIndex: 40,
                          position: 'absolute',
                          top: logoCoords.top,
                          transform: logoCoords.transform,
                          left: logoCoords.left,
                          right: logoCoords.right,
                        }}
                      >
                        <img height={`${logoHeight}px`} src={form.settings.logo.url} />
                        <DeleteImageIcon deleteImage={this.deleteImage} type="logo" />
                      </div>
                    </div>
                  ) : null}
                  {/* ******************* */}

                
                      {/* WE HAVE LOGO WITH ABSOLUTE PLACEMENT */}
                     {form && form.settings.logo !== undefined && logoPlacement === 1 ? (
                      <div
                        style={{
                          zIndex: 40,
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      >
                        <DraggableDiv
                          zIndex={0}
                          x={logoX}
                          y={logoY}
                          onMove={this._moveLogo}
                          onStart={this._onStartLogo}
                        >
                          <img height={`${logoHeight}px`} src={form.settings.logo.url} />
                          <DeleteImageIcon deleteImage={this.deleteImage} type="logo" />
                        </DraggableDiv>
                       </div>
                    ) : null}
               

                  <div
                    style={{
                      zIndex: 0,
                      backgroundColor: parseInt(imageHeight) !== 0 ? color : 'white',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height:
                        parseInt(imageHeight) !== 0
                          ? (window.innerHeight * imageHeight) / 100
                          : window.innerHeight * 0.25,
                      // height:'75px',
                      overflow: 'hidden',
                    }}
                  >
                
                    {form && form.settings.banner !== undefined && imageHeight > 0 ? (
                      <DraggableDiv
                        zIndex={10}
                        x={x}
                        y={y}
                        onMove={this._moveBanner}
                        onStart={this._onStartBanner}
                      >
                        <div>
                          <img
                            style={{ opacity: overlayOpacity / 100 }}
                            src={form.settings.banner.url}
                          />
                        </div>
                      </DraggableDiv>
                    ) : null}

                    {/* -----------------------------LOGO START IMAGE------------------------ */}

                    {/* ALWAUS SHOW BACK BUTTTON ON DESIGN */}
                    <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 45 }}>
                      <GBButton
                        width="85px"
                        showDropShadow={false}
                        hoverBackgroundColor={`#00000080`}
                        Action={null}
                        iconHoverBackgroundColor={`#00000080`}
                        icon={mdiArrowLeft}
                        iconColor="white"
                        iconPosition="left"
                        text="Back"
                        color="#1A1A1A"
                      />
                    </div>

                    {/* WE HAVE NO LOGO */}
                    {form && form.settings.logo === undefined ? (
                      <div
                        style={{
                          zIndex: 40,
                          backgroundColor: 'white',
                          position: 'absolute',
                          top: 50,
                          left: 10,
                        }}
                      >
                        <ImageUploader
                          type="logo"
                          saveImage={this.updateHeaderImages}
                          addText="Add logo"
                          existingFile={form && form.settings.logo}
                          color={color}
                        />
                      </div>
                    ) : null}

                    {/* -----------------------------LOGO END IMAGE------------------------ */}
                  </div>

                  <div
                    style={{
                      position: 'relative',
                      border: '1px solid red',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: window.innerHeight * 0.25,
                    }}
                  ></div>

                  <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                      <div
                        style={{
                          position: 'relative',
                          zIndex: 10,
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: form.settings.formBackgroundColor !==undefined ? form.settings.formBackgroundColor.attributes.color : 'white',
                          width: '80%',
                          marginRight: '10px',
                          maxWidth: '600px',
                          minWidth: '300px',
                          // border: '1px solid red',
                          // height: 'calc(100vh - 300px)',
                          paddingTop: form.settings.logo !==undefined ? `${form.settings.logo.height/2}px` : '30px',
                          paddingLeft: window.innerWidth > 500 ? '80px' : '10px',
                          paddingRight: window.innerWidth > 500 ? '80px' : '10px',
                        }}
                        ref={provided.innerRef}
                      >
                        {/* LOGO AT BOTTOM PLACEMENT LOCATION */}
                        {form &&
                        form.settings.logo !== undefined &&
                        [2, 3, 4].includes(logoPlacement) ? (
                          <div
                            style={{
                              zIndex: 40,
                              position: 'absolute',
                              top: logoCoords.top,
                              left: logoCoords.left,
                              transform: logoPlacement === 3 ? 'translate(-50%)' : null, //middle
                              right: logoCoords.right,
                            }}
                          >
                            <img height={`${logoHeight}px`} src={form.settings.logo.url} />
                            <DeleteImageIcon deleteImage={this.deleteImage} type="logo" />
                          </div>
                        ) : null}
                        {/* LOGO BOTTOM PLACEMENT LOCATION */}

                        {/* <div style={{height:'10px'}} /> */}
                        {this.state.selected.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // style={getItemStyle(
                                //     snapshot.isDragging,
                                //     provided.draggableProps.style
                                // )}
                              >
                                <FormEditObject
                                  id={item.id}
                                  tableid={tableid}
                                  blockid={blockid}
                                  fieldData={item}
                                  fontFamily={form.settings.font}
                                  fontSize={form.settings.fontSize}
                                  labelsInCaps={form.settings.labelsInCaps}
                                  color={form.settings.accentColors.attributes.color}
                                  btnTextColor={form.settings.accentColors.attributes.text}
                                  textColor={form.settings.textColor !==undefined ? form.settings.textColor.attributes.color : '#1A1A1A'}
                                  updateFieldLabel={this.updateFieldLabel}
                                  updateFieldValue={this.updateFieldValue}
                                  updateFieldAttribute={this.updateFieldAttribute}
                                  removeItemFromSelectedArray={this.removeItemFromSelectedArray}
                                  tableinfo={tableinfo}
                                  fields={selected}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {/* <Footer /> */}
                </div>
              </div>
            </DragDropContext>
          </>
        ) : null}
      </div>
    );
  }
}

export default FormDesigner;
