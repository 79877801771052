/* eslint-disable */

export const plans = [
    {
        type: "Free",
        data: 2,
        blocks: 10,
        messages: 100,
        messageInterval: "all",
        records: 1000,
        tabs: 10,
        phonenumbers: 1
    },{
        type: "Starter",
        data: 5, 
        blocks: 20,
        messages: 500,
        messageInterval: "month",
        records: 10000,
        tabs: 20,
        phonenumbers: 3
    },{
        type: "Pro",
        data: 20,
        blocks: 100,
        messages: 1500,
        messageInterval: "month",
        records: 100000,
        tabs: 30,
        phonenumbers: 10
    }, {
        type: "Enterprise",
        data: 1000,
        blocks: 500,
        messages: 1500,
        messageInterval: "month",
        records: 10000000,
        tabs: 1000,
        phonenumbers: 100
    }
    , {
        type: "Public",
        data: 20,
        blocks: 1000,
        messages: 1000,
        messageInterval: "month",
        records: 10000000,
        tabs: 10000,
        phonenumbers: 10000
    }
]