/* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React, {useEffect, useState } from 'react';


const ButtonDisplay = ({ id, options, updateFieldValue, lookup, color,value }) => {
  
 const [selectedOptions, setSelectedOptions] = useState(value!==undefined ? value : null);

 useEffect(() => {
  if(options.length>0 && value !==undefined){
   setSelectedOptions(value)
  }
 },[options,value])


 const updateSelection =(optionid,option)=>{
 
  if(selectedOptions===null) {
    setSelectedOptions({id:[parseInt(optionid)],label:[option]})
    updateFieldValue(id,{id:[parseInt(optionid)],label:[option]})
  } else {
    if(lookup==='single') {
      setSelectedOptions({id:[parseInt(optionid)],label:[option]});
      updateFieldValue(id,{id:[parseInt(optionid)],label:[option]})
    } 
    else {
           const idx =  selectedOptions.id.findIndex((el) => parseInt(el) === parseInt(optionid));
          if (idx === -1) {
            const tempOptions = selectedOptions;
            tempOptions.id.push(parseInt(optionid));
            tempOptions.label.push(option);
            setSelectedOptions(tempOptions);
            updateFieldValue(id,tempOptions)
          } else {
            const tempOptions =selectedOptions;
            tempOptions.id = tempOptions.id.filter((el) => parseInt(el) !== parseInt(optionid));
            tempOptions.label = tempOptions.label.filter(el=>el !==option)
            setSelectedOptions(tempOptions);
            if(tempOptions.id.length===0)
            {
              updateFieldValue(id,[])
            } else {
              updateFieldValue(id,tempOptions)
            }
          }
      }
    }

 }

 function hexToRgb(hex,opacity) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let rgb=`rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`
  return rgb
}

  const displayOptions = () =>
    options.map((el) => (
      <div
       key={el.value}
        onClick={()=>updateSelection(el.value,el.text)}
        css={{
          cursor:'pointer',
          backgroundColor: selectedOptions!==null && selectedOptions.id !==undefined && selectedOptions.id.findIndex(itm=>itm===parseInt(el.value)) !== -1 ? hexToRgb(color,.5) : 'white',
          border:  selectedOptions!==null  && selectedOptions.id !==undefined && selectedOptions.id.findIndex(itm=>itm===parseInt(el.value)) !== -1 ?  null : `1px solid ${color}`,
          margin: '10px',
          width: '230px',
          height: '40px',
          // color: selectedOptions.findIndex(itm=>itm===parseInt(el.optionid)) !== -1 ? 'white' : color,
          color: '#1A1A1A',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center',
          borderRadius:'10px',
          fontWeight:'bold',
          '&:hover' : {
              backgroundColor: `${color}1A`
          }
        }}
      >
        {el.text}
      </div>
    ));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {displayOptions()}
    </div>
  );
};

export default ButtonDisplay;
