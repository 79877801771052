/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import PhoneValidator from './PhoneValidator';
import { confirmAlert } from 'react-confirm-alert';
import { navigate } from '@reach/router';
import { mdiAlert} from '@mdi/js';
import Icon from '@mdi/react';
import firebase, { phoneProvider } from '../../../clients/firebase';
import GBButton from '../../../components/GBButton/GBButton';

const withAPI = () => {
  return ({ user, onChange, onBack, initialValues }) => {
    // const [alertComponent, alert] = useAlert();

    const [loading, setLoading] = useState(false);
    const [verificationId, setVerification] = useState('');
    const [success, setSuccess] = useState(false);

    const handleValidate = useCallback(
      ({ phoneNumber }) => {
        // onChange('phone', { phoneNumber });
        // return;
        setLoading(true);
        return phoneProvider
          .verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
          .then((id) => {
            setVerification(id);
            setLoading(false);
          })
          .catch((error) => {
            // alert.show({ message: error.message });
            confirmAction(error.message)
            setLoading(false);
          });
      },
      [setVerification, alert],
    );

    const handleSignIn =() => {
      navigate('/auth/sign-in')
    }
    const handleUpdate = useCallback(
      ({ code }) => {
        setLoading(true);
        const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
        user
          .updatePhoneNumber(credential)
          .then(() => {
            setSuccess(true);
            setLoading(false);
          })
          .catch((error) => {
            if(error.message.includes('This credential is already associated'))
            {
              confirmAction('An account already exists matching the phone number entered.')
            } else {
              confirmAction(error.message)
            }
            setLoading(false);
          });
      },
      [verificationId, user, alert, setSuccess],
    );

    const handleRedirect = useCallback(() => {
      onChange('phone', { phoneNumber: user.phoneNumber });
    }, [onChange, user]);

    const handleCancel = useCallback(() => {
      setVerification('');
    }, [setVerification]);

    const handleBack = useCallback(() => {
      onBack('phone');
    }, [onBack]);

    useEffect(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-validator', {
        size: 'invisible',
      });
    }, []);

    const confirmAction = (error) => {
      confirmAlert({
        customUI: ({onClose}) => {
          return (
            <div style={{width:'300px', height:'400px',border:'1px solid #757575', backgroundColor:`white`,borderRadius:'20px'}}>
              <div style={{display:'flex',alignItems:'center', justifyContent:'center', backgroundColor:`#E06055`, textAlign:'center', color:'white',verticalAlign:'center', height:'40px',borderTopRightRadius:'20px', borderTopLeftRadius:'20px'}}>
                <Icon path={mdiAlert} size="20px" color="white" style={{marginRight:'3px'}}/>
              Account already exists.
              </div>
              <div style={{display:'flex', alignItems:'center',flexDirection:'column',marginRight:'20px', marginLeft:'20px',marginTop:'10px'}}>
             {error}
            <div style={{marginRight:'10px', marginBottom:'10px'}}>
            <GBButton text="Sign in" color='#E06055' Action={handleSignIn} ActionTwo={onClose} width="100px" />
            </div>
            <div style={{marginBottom:'10px',marginRight:'10px'}}>
            <GBButton text="Start over" textColor="black" color="#D5D1D1" Action={handleBack}  ActionTwo={onClose} width="100px" />
            </div>
            </div>
            </div>
          )
        },
      });
    
    }

    return (
      <>
        <div id="phone-validator" />
        {/* {alertComponent} */}
        <PhoneValidator
          user={user}
          onValidate={handleValidate}
          onUpdate={handleUpdate}
          onRedirect={handleRedirect}
          onCancel={handleCancel}
          onBack={handleBack}
          loading={loading}
          verificationId={verificationId}
          success={success}
          initialValues={initialValues}
        />
      </>
    );
  };
};

export default withAPI;
