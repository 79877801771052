/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import TextInput from '../../../../../components/TextInput/TextInput'

const RangeSlider = ({field,min,max,currentValue,updateFieldValue,color}) => {
  const [value, onChange] = useState(currentValue);

  const updValue=(field,value) =>{
    updateFieldValue(field,value);
    onChange(value);
  }

  useEffect(()=>{
    onChange(currentValue)
  },[currentValue])

  return (
    <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', position: 'relative',width:'100%' }}>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center',width:'100%',position:'relative'}}>
         <div style={{width:'80px'}}>  
            <TextInput
            value={value}
            type="number"
            min={min}
            max={max}
            marginLeft="0px"
            isRequired={false}
            name={''}
            width="80px"
            labelsInCaps={false}
            fontWeight="bold"
            updateValue={updValue}
            field={field}
            color={color}
            textColor={'black'}
            error={false}
          />
          </div>
      <div className="slider">
      <input
        style={{color:color,marginLeft:'20px',width:'100%'}}
        type="range"
        min={min}
        max={max}
        // step={max !==undefined && max <100 ? '1' : '10'}
        value={value!==undefined && value !=='' ? value : 0}
        onChange={({ target: { value: radius } }) => {
          updateFieldValue(field,radius);
          onChange(radius);
        }}
      />
      </div>
      </div>
    </div>
  );
};

export default RangeSlider;
