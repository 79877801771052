/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import { mdiAccountGroup,mdiChevronDown,mdiChevronUp } from '@mdi/js';
import { getZoneUserAdminBlock } from '../../api/zone';
import { getActiveZoneUsers } from '../../api/users';
import Avatar from '../../components/Avatar/Avatar';


const ZoneUsers = () => {
  const [adminBlockId, setAdminBlockId] = useState('');  
  const [isExpanded, setIsExpanded] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const query = async () => {
      const result = await getZoneUserAdminBlock();
      setAdminBlockId(result[0].blockid);
      const users = await getActiveZoneUsers()
      setUsers(users);
    };
    query();
  }, []);

  const NavigateToAdmin = () => {
    navigate(`/block/${adminBlockId}`);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
}

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
        }}
      >
       <div style={{ marginLeft: '20px' }}>
        <TextButton Action={toggleExpand} textColor="black" hoverColor="#75757580" fontSize="13px" icon={isExpanded ? mdiChevronUp : mdiChevronDown} text="Zone users (owners and builders)" iconPosition="left" />
        </div>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ marginLeft: '10px' }}>
            <GBButton
              Action={NavigateToAdmin}
              icon={mdiAccountGroup}
              iconHoverColor="White"
              width="200px"
              borderRadius="10px"
              iconHoverBackgroundColor="#757575"
              textColor="#757575"
              textHoverColor="#757575"
              hoverBorder="2px solid #757575"
              hoverColor="#75757580"
              iconPosition="left"
              text="Manage all Zone users"
            />
          </div>
        </div>
      </div>
      {isExpanded ? (<>
      <div style={{display:'flex', marginLeft:'60px',marginBottom:'10px', flexDirection:'row', width:'400px', alignItems:'center'}}>
          <div style={{width:'200px'}}>Administrative users</div>
          <div>Zone Role</div>
      </div>
    
      {users.map(el => (
        <div key={el.userid} style={{display:'flex', marginBottom:'10px', marginLeft:'60px', flexDirection:'row', alignItems:'center', width:'400px'}}>
            <div style={{display:'flex', width:'200px', flexDirection:'row', alignItems:'center'}}>
                <Avatar item={el} />
                <div>{el.firstname} {el.lastname}</div>
            </div>
            <div style={{width:'100px'}}>{el.role}</div>
        </div>
      ))}
      </>) : null}

    </div>
  );
};

export default ZoneUsers;
