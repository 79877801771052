/* eslint-disable */
import React,{useEffect} from 'react';
import Moment from 'react-moment';
import { mdiClose} from '@mdi/js';
import IconButton from '../FilterData/IconButton'
import LetterColors from '../../../../utils/LetterColors';
import GBButton from '../../../../components/GBButton/GBButton';

const Notification = ({notificationData,deleteNotification,color,showNotificationDetails}) => {

    // useEffect(() => {
    //     console.log(notificationData)
    // },[])
    let leadingColor=color;
    
    if(LetterColors.filter(e=>e.letter===notificationData.leadingtext.slice(0,1).toLowerCase()).length===1){
        leadingColor= LetterColors.filter(e=>e.letter===notificationData.leadingtext.slice(0,1).toLowerCase())[0].color
    };

    return (
        <div>
        <div style={{
            display:'flex',
            margin:'10px',
            flexDirection:'row',
            justifyContent:'space-evenly'
        }}>
            <div style={{marginRight:'20px'}}>
            {notificationData.leadingtext.startsWith('http') ? (
                <div style={{minWidth:'30px'}}><img style={{ borderRadius: '100%',marginRight: '3px'}} src={notificationData.leadingtext} height='30px' width='30px' /></div>
            ) : (
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'30px',
                    height:'30px',
                    backgroundColor:leadingColor,
                    color:'white',
                    borderRadius:'100%'
                }}>
                    {notificationData.leadingtext}
              </div>
            )}
            

          </div>
          <div dangerouslySetInnerHTML={{ __html: notificationData.htmlmessage }} />
     
         {deleteNotification !==undefined ? (<div>
            <IconButton tooltip="Remove" color="#F6685E" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={deleteNotification} ActionValue={notificationData.notificationid}  />
            </div>) : null }
        </div>
    
        <div style={{marginRight:'10px', display:'flex',flexDirection:'row',justifyContent:notificationData.details !==null ? 'space-between' : 'end',color:'#757575'}}>
            {notificationData.details !==null ? (
                <div style={{marginLeft:'58px'}}>
                    <GBButton width="100px" text='View details' color={color} Action={showNotificationDetails} ActionValue={notificationData.details} />
                </div>
            ): null}
            <div style={{marginRight:'5px'}}>
            <Moment format="hh:mma DD-MMM-YYYY">{notificationData.addedon}</Moment>
            </div>
            
        </div>
        </div>
    )
}

export default Notification;