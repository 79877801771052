/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef } from 'react';

import moment from 'moment';
import { Label } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiCloseCircleOutline, mdiRefresh } from '@mdi/js';
import cloudrun from '../../../../clients/httpcloudrun';
import PNL from 'google-libphonenumber';
import { toast } from 'react-toastify';
import { AccountContext } from '../../../../providers/AccountProvider';
import {
  getMessagesAPI,
  getMessageUsersAPI,
  DeleteScheduledMessage,
  getMessageTemplatesAPI,
  getAllNumbersPerRow,
  getAltPhoneMessages,
  constructEmail
} from '../../../../api/messages';
import { updateUserTabSettings,getUserTabSettings } from '../../../../api/tables';
import { getFieldFromTable } from '../../../../api/GetData';
import Avatar from '../../../../components/Avatar/Avatar';
import SearchBar from './SearchBar';
import TemplateViewer from './TemplateViewer';
import Spinner from '../../../../components/Spinner/Spinner';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import { useInterval } from '../../../../utils/useInterval';
import LetterColors from '../../../../utils/LetterColors';
import { getUsersAPI } from '../../../../api/users';
import Global from '../../../../clients/global'


const Messages = ({
  color,
  tableid,
  dataID,
  tableinfo,
  RID,
  display,
  number,
  callingComponent,
  canMessage=true,
  isShare=false,
  role
}) => {
  const { userInfo } = useContext(AccountContext);

  const phoneUtil = PNL.PhoneNumberUtil.getInstance();
  const schema = `schema_${Global.zoneid}`;
  const [messages, SetMessages] = useState([]);
  const [messageUsers, setMessageUsers] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [allNumbers, setAllNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [phoneField, setPhoneField] = useState('');
  const [graceblocksms, setgraceblockssms] = useState('');
  const [message, setMessage] = useState('');
  const [maxId, setMaxId] = useState(0);
  const [hoverMessageId, setHoverMessageId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrls, setFileUrls] = useState(null);
  const [messageSetting, setMessageSetting] = useState({ interval: 'none', number: 1 });
  const divRef = useRef(null);


  useEffect(() => {
    if (tableid !== undefined) {
      setMessage('');
      setFileUrls(null);
    }
    fetchData();
   
  }, [dataID, number]);

  // 8-4-2020 Load any other numbers that have been sent messages from this table/row. This
  // happens when a user changes the recepient phone number or in the future, has other numbers
  // or services they use to message to the person.
  const getNumbers = async () => {
    const uniqueNumbers = await getAllNumbersPerRow(schema, tableid, dataID);
    const numbers = [];
    if (uniqueNumbers.length > 1) {
      uniqueNumbers.map((el) => {
        numbers.push(el.recipientsms);
      });
    }
    return numbers;
  };

  const getHeight = () =>{
    let height='100%';
    if(callingComponent==='chatview') {
      height='calc(100vh - 330px)'
    }
    // if(callingComponent==='grid') {
    //   height = 'calc(100vh - 235px)'
    // } else if(callingComponent==='detailview' && !isShare) {
    //   height='calc(100vh - 200px)'
    // } else {
    //   height='calc(100vh - 300px)'
    // } 

    return height;
  }

  useEffect(() => {
    // See if message field has message template field defined. If yes, fetch/store message templates for use in UI.
    const messagefield = tableinfo.columns.filter((itm) => itm.uitype === 12)[0];
    const GetMessageOptions = async () => {
      const settingsResult = await getUserTabSettings(tableid);

      const userTabSettings = settingsResult[0];
      if (
        userTabSettings &&  userTabSettings.tabsettings !== null &&
        userTabSettings.tabsettings.MessageDelivery !== undefined
      ) {
        setMessageSetting(userTabSettings.tabsettings.MessageDelivery);
      }
    };

    if (messagefield.selectedField !== undefined) {
      const GetMessageTemplates = async () => {
        const response = await getMessageTemplatesAPI(
          messagefield.selectedTable,
          messagefield.selectedField,
        );
        setMessageTemplates(response);
      };

      GetMessageTemplates();
    }

    GetMessageOptions();
  }, [tableid]);


  const scrollHandler = () => {
    if (divRef.current !== null) {
      divRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  // 12-4-2020 using useInterval hook to poll for new messages. This is a replacement
  // for using Websockets which CloudRun does not currently support. We'll start by using
  // delay of 3 seconds. if display of window='none', pause the interval.

  useInterval(
    async () => {
      // console.log('running...' + dataID);
      await fetchMoreData();
    },
    display !== 'none' ? 5000 : null,
  );

  // turning off for now as cloud run does not support websockets.
  // useEffect(() => {
  //   if (data){
  //     // 7-21-2020, For now I am going to keep this simple. I will use the subscription
  //     // notification to simply trigger the refetch of data. Assumption is that message threads
  //     // will generally be small (20-50 messages) and refetching will not be a performance hit.
  //     // in the future we could optimize this by using date/time field and "latestrow" to only
  //     // fetch new records and then append them to table when data changes...but can implement that down the road.
  //     fetchData();
  //   }
  // }, [data]);

  const changeNumber = async (number) => {
    if (number === phoneNumber) {
      fetchData();
    } else {
      const altMessages = await getAltPhoneMessages(schema, graceblocksms, number);
      altMessages.sort((a, b) => (a.addedon > b.addedon ? 1 : -1));
      altMessages.forEach((itm) => {
        itm.day = moment(itm.addedon).format('DD-MMM-YYYY');
        const addedbyUser = messageUsers.filter((el) => el.userid === itm.addedby)[0];
        itm.User = addedbyUser;
      });
      SetMessages(altMessages);
    }
    setSelectedNumber(number);
  };

  const updateMsgSetting = async (msgSetting) => {
    const newsetting = {
      interval: msgSetting.interval,
      number: msgSetting.number,
    };

    const settingsResult = await getUserTabSettings(tableid);
    const userTabSettings = settingsResult[0];
    
    if (userTabSettings.tabsettings !== undefined) {
      userTabSettings.tabsettings.MessageDelivery = newsetting;
    } else {
      userTabSettings.tabsettings = { MessageDelivery: newsetting };
    }
    const method = userTabSettings.hasSettings ? 'update' : 'insert';
    await updateUserTabSettings(tableid, userTabSettings.tabsettings, method);
    setMessageSetting(newsetting);
  };

  const updateSetting = () => {
    setShowSettings(!showSettings);
  };

  const fetchData = async (showSpinner = true) => {
    // 12-4-2020 added showSpinner param so that when we are doing interval checks for new messages
    // it doesn't show spinner, but when a user sends a message from UI, it shows spinner.
    let AllUsers = [];
    if (messageUsers.length === 0) {
      AllUsers = await getUsersAPI([],true);
      setMessageUsers(AllUsers);
    } else {
      AllUsers = messageUsers;
    }

    setIsLoading(showSpinner ? true : false);

    const phonefield = tableinfo.columns.filter((itm) => itm.uitype === 12)[0].phonefield;
    const graceblocksSMS = tableinfo.columns.filter((itm) => itm.uitype === 12)[0].phonenumber;

    let newMessages = await getMessagesAPI(schema, tableid, dataID, phonefield,number,null,graceblocksSMS);
    const max = Math.max(...newMessages.map((itm) => itm.messageid));
    setgraceblockssms(graceblocksSMS);
    setPhoneField(phonefield);
    setMaxId(max);

    // 2-15-2021 am now passing in "number" from message editor and dynamically
    //lookup up this number from grid, so that the messages can properly refresh when
    // a user changes the number in the grid. The detail view currently does not pass
    // in ths number so use normal lookup options.

    if (number !== undefined) {
      setPhoneNumber(number);
      setSelectedNumber(number);
    } else {
      const phone = await getFieldFromTable(tableid, phonefield, dataID);
      setPhoneNumber(phone);
      setSelectedNumber(phone);
    }

    if (newMessages.length > 0 && AllUsers.length > 0) {
      const oldNumbers = await getNumbers();
      newMessages.sort((a, b) => (a.addedon > b.addedon ? 1 : -1));

      newMessages.forEach((itm) => {
        const today = moment().startOf('day');
        const msgDate = moment(itm.addedon).startOf('day');
        const days = today.diff(msgDate,'days')     
        if(days===0)
        {
          itm.day='Today'
        } else if (days===1)
        {
          itm.day='Yesterday'
        } else{
          itm.day = moment(itm.addedon).format('DD-MMM-YYYY');
        }
        const addedbyUser = AllUsers.filter((el) => el.userid === itm.addedby)[0];
        itm.User = addedbyUser;
      });
      SetMessages(newMessages);

      // const  currentNumber = newMessages[0][phonefield];

      // setPhoneNumber(currentNumber);
      // setSelectedNumber(currentNumber);

      setAllNumbers(oldNumbers);
    } else if (newMessages.length === 0) {
      SetMessages([]);
    }
    setIsLoading(false);
    // scrollHandler();
    setTimeout(() => {
      scrollHandler();
    }, 300); // 1
    
  };

  const fetchMoreData = async () => {
    if(phoneNumber !==null){
    let newMessages = await getMessagesAPI(schema, tableid, dataID, phoneField,number, maxId,graceblocksms);
    if (newMessages.length > 0) {
      const max = Math.max(...newMessages.map((itm) => itm.messageid));
      setMaxId(max);
      newMessages.forEach((itm) => {
        const today = moment().startOf('day');
        const msgDate = moment(itm.addedon,"YYYY-MM-DD");
        const days = today.diff(msgDate,'days')     

        if(days===0)
        {
          itm.day='Today'
        } else if (days===1)
        {
          itm.day='Yesterday'
        } else{
          itm.day = moment(itm.addedon).format('DD-MMM-YYYY');
        }

        const addedbyUser = messageUsers.filter((el) => el.userid === itm.addedby)[0];
        itm.User = addedbyUser;
      });

      const updatedMessages = [...messages];
      updatedMessages.push.apply(updatedMessages, newMessages);
      SetMessages(updatedMessages);
      scrollHandler();
    }
  }

  };

  const GetFormattedNumber = (number) => {

    try{
    const phonenumber = phoneUtil.parse(number, 'US');
    const natnumber = phoneUtil.format(phonenumber, PNL.PhoneNumberFormat.NATIONAL);
    return natnumber;
    } catch(error) {
      return ''
    }
 
  };

  const appendTemplateMessage = async(id) => {

    const messagefield = tableinfo.columns.filter((itm) => itm.uitype === 12)[0];
    const templateInfo ={blockid: messagefield.selectedBlock,tableid:messagefield.selectedTable, field:messagefield.selectedField, rowid:id}


    let tempMsg = messageTemplates.filter(el=>el.id===id)[0].message;
    const result = await constructEmail(tableid,[dataID],templateInfo,userInfo.blocks,role)
    
    //9-13-23 txt messages only have one field "message", so we get first emailfield/fieldValue.
    if(result.length>0) {
      tempMsg=result[0].emailFields[0].fieldValue
    }
    // console.log(result);

    if (message.slice(message.length - 1) === '#') {
      setMessage(message.slice(0, -1) + tempMsg);
    } else {
      setMessage(message + tempMsg);
    }
    setShowTemplates(false);
  };

  const messageChangeHandler = (msg) => {
    setMessage(msg);
  };

  // test method to simulate getting text response from candidate.
  const SendResponse = async () => {
    const tempnumber = phoneUtil.parse(phoneNumber, 'US');
    const e164number = phoneUtil.format(tempnumber, PNL.PhoneNumberFormat.E164);
    await cloudrun.post('/receiveSms?zoneid=1', {
      Body: 'This is a new posting that is long and will take more space. Lets see what happens and I will hope it will warp nicely.more data is better than no data. Its saturday and Im testing without apoostrpohe.',
      To: graceblocksms,
      From: e164number,
    });
    setMessage('');
  };

  const ConvertDelayIntoSeconds = () => {
    let seconds = 0;
    if (messageSetting.interval === 'minutes') {
      return messageSetting.number * 60;
    }
    if (messageSetting.interval === 'hours') {
      return messageSetting.number * 3600;
    }
    if (messageSetting.interval === 'days') {
      return messageSetting.number * 86400;
    }
  };

  const DeleteMessage = async (schema, messageid) => {
    await DeleteScheduledMessage(schema, messageid,'messages');
    await fetchData();
  };

  const getMessageBorder = (msg) =>{
    if(['delivery_unknown','failed','undelivered'].includes(msg.status)) {
      return '2px solid red'
    } else if(msg.status==='queued') {
      return `1px solid ${color}1A`
    }
    else if (msg.schedule !==null) {
      return `2px dashed ${color}`
    } else {
      return `1px solid ${color}`
    }
   
  }

  const SendMessage = async () => {
    setIsLoading(true);

    // 1-28-2021 adding zoneRole for business logic in send message (if out of funds, different message based on role/owner or user)
    const zoneRole = userInfo.zones.filter((itm) => itm.id === parseInt(Global.zoneid))[0].role;

    let result = '';
    const payload = {
      tableid,
      tablerowid: dataID,
      from: graceblocksms,
      to: phoneNumber,
      message,
      role,
      blockRoles: userInfo.blocks,
      fileUrls,
      zoneRole,
      schedule: messageSetting.interval === 'none' ? null : ConvertDelayIntoSeconds(),
    };
    if (messageSetting.interval === 'none') {
      result = await cloudrun.post('/sendSms', { payload });
    } else {
      result = await cloudrun.post('/scheduleDelivery', { payload });
    }
    setMessage('');
    setFileUrls(null);

    // Put error message in Toast.
    if (result !== 'success') {
      const errormessage = (
        <div>
          Oops, something went wrong. Issue(s) identified:
          {result.map((el) => (
            <ul>
              <li>
                {el.error === 'invalidFunds' ? (
                  <div>
                    This Zone has depleted all available messaging funds. To continue sending
                    messages, a Zone owner will need to purchase more funds under Zone settings
                    (gear icon in left panel of the GraceBlocks Homepage).
                  </div>
                ) : null}
                {el.error === 'invalidInternational' ? (
                  <div>
                    International message sending is currently disabled. Unless the Zone owner
                    activates this Zone setting, the to and from country must match when sending
                    messages.
                  </div>
                ) : null}
                {el.error==='invalidPhoneNumber' ? (
                      <div>
                      The phone number is not a valid phone number
                    </div>
                ) :  null};
                {el.error === 'invalidRegion' ? (
                  <div>
                    You tried to send one or more messages to a North American location we do not
                    support. Visit our messaging page for the{' '}
                    <a
                      style={{ color: 'white', textDecoration: 'underline' }}
                      href="https://www.graceblocks.com/countries/us"
                      target="_blank"
                    >
                      US
                    </a>{' '}
                    or{' '}
                    <a
                      style={{ color: 'white', textDecoration: 'underline' }}
                      href="https://www.graceblocks.com/countries/ca"
                      target="_blank"
                    >
                      Canada
                    </a>{' '}
                    to learn more.
                  </div>
                ) : null}
                {el.error === 'invalidCountry' ? (
                  <div>
                    You tried to send one or more messages to a country we don't support. Visit the{' '}
                    <a
                      style={{ color: 'white', textDecoration: 'underline' }}
                      href="http://www.graceblocks.com/messaging-pricing"
                      target="_blank"
                    >
                      GraceBlocks messaging pricing
                    </a>{' '}
                    to see all supported countries
                  </div>
                ) : null}
                {el.error === 'nofunds' ? <div>{el.message}</div> : null}
              </li>
            </ul>
          ))}
        </div>
      );

      toast.info(errormessage, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    if (messages.length === 0) {
      await fetchData();
    } else {
      await fetchMoreData();
    }
    setIsLoading(false);
  };

  const DisplayNumbers = () => {
    if (allNumbers.length > 0) {
      return (
        <select value={selectedNumber} onChange={(e) => changeNumber(e.target.value)}>
          {allNumbers.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      );
    } else {
      return phoneNumber;
    }
  };

  const ShowImageInWindows = (item) => {
    window.open(item.url);
  };

  const getLetterColor =(letter) => {
    const idx = LetterColors.findIndex(el=>el.letter===letter.toLowerCase());
    if(idx !==-1) {
      return LetterColors[idx].color
    } else {
      return '#1B8892'
    }
  }

  const DisplayMessages = () => {
    // The UI groups messages by day. We create a unique set of days by which
    // we loop over to display messages chronologically and by day.
    const days = messages.map((el) => el.day);
    let uniquedays = [...new Set(days)];

    return uniquedays.map((itm) => (
      <div
        style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        key={itm}
        onClick={() => setShowTemplates(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
          <div
            style={{
              width: '100px',
              marginLeft: '10px',
              marginRight: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            {itm}
          </div>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
        </div>
        {messages
          .filter((el) => el.day === itm)
          .map((msg) => (
            <div key={msg.messageid}>
              <div
                onMouseOver={() => setHoverMessageId(msg.messageid)}
                onMouseOut={() => setHoverMessageId(null)}
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flowDirection: 'row',
                  alignItems: 'center',
                  minWidth: '0px',
                  justifyContent: msg.tablerowid !== null ? 'flex-end' : 'flex-start',
                }}
              >
                {msg.tablerowid === null ? (
                  <div
                    style={{
                      backgroundColor: getLetterColor(RID.slice(0,1)),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '30px',
                      color: 'white',
                    }}
                  >
                    {RID.slice(0, 1)}
                  </div>
                ) : null}
                {msg.schedule !== null ? (
                  <div
                    onClick={() => DeleteMessage(schema, msg.messageid)}
                    css={{
                      color: '#E89089',
                      cursor: 'pointer',
                      '&:hover': {
                        svg: {
                          color: '#E8908980',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiCloseCircleOutline} size="25px"></Icon>
                  </div>
                ) : null}
                <Label
                  pointing={msg.tablerowid === null ? 'left' : 'right'}
                  style={{
                    zIndex: '0',
                    maxWidth: '50%',
                    backgroundColor: msg.tablerowid === null ? `white` : '#F0F0F0',
                    border: getMessageBorder(msg),
                  }}
                >
                  {msg.tablerowid === null ? msg.message : null}
                
                  {msg.files !== null ? (<div style={{marginTop:'10px', marginBottom:'10px'}}>
                       <ImageThumbnails
                       items={msg.files}
                       maxWidth="100%"
                       Action={ShowImageInWindows}
                       showDeleteIcon={false}/>
                 </div> ) : null}
                 
                  {msg.tablerowid !== null ? msg.message : null}
                
                </Label>
                {msg.tablerowid !== null ? (
                  <div>
                    <Avatar item={msg.User} width="35px" height="35px" fontSize="13px" />
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  visibility: msg.messageid === hoverMessageId ? 'visible' : 'hidden',
                  display: 'flex',
                  marginLeft: '50px',
                  marginRight: '60px',
                  fontSize: '10px',
                  justifyContent: msg.tablerowid !== null ? 'flex-end' : 'flex-start',
                }}
              >
                {msg.tablerowid !== null ? ( <div style={{display:'flex',flexDirection:'column'}}>
                 <div>
                    <span>
                      {msg.schedule !== null ? 'Scheduled by' : 'Sent by'} {msg.User.firstname}{' '}
                      {msg.User.lastname} from {GetFormattedNumber(msg.graceblockssms)} @{' '}
                      {moment(msg.addedon).format('hh:mm:ss a ')} EST{' '}
                    </span>
                  </div>
                  {['delivery_unknown','failed','undelivered'].includes(msg.status) ? (
                    <div style={{color:'red'}}>
                      {msg.status} : {msg.errorcode} - {msg.errormessage}
                    </div>
                  ) :null}
                 
                </div>) : (
                  <span>Received @ {moment(msg.addedon).format('hh:mm:ss a ')} EST </span>
                )}
              </div>
            </div>
          ))}
      </div>
    ));
  };

  return (
    <div
      tabIndex={0}
      style={{
        display: 'flex',
        height: getHeight(),
        width: '100%',
        outline: 'none',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        overflow:'auto',
        // position:'relative'
      }}
    >
         
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          // maxHeight:'600px',
          width: '100%',
          // border:'1px solid green',
          overflow: 'auto',
          // position:'relative',
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ display: 'flex', margin: '10px', width: '100%' }}
          >
            Type a message to {RID} @{' '}
            {phoneNumber !== null ? (
              DisplayNumbers()
            ) : (
              <span style={{ color: 'red' }}>'NO VALID NUMBER'</span>
            )}
          </div>
          {/* 8-9-2021: Removed the refresh icon, as we auto-poll for new messages now  */}
          {/* <div css={{ marginRight: '20px', '&:hover': { svg: { color: color } } }}>
            <Icon onClick={() => fetchData()} path={mdiRefresh} size="20px" />
          </div> */}
        </div>

 
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto' }}>
          {messages.length > 0 ? DisplayMessages() : null}
          <div style={{ width: '1px', height: '0px' }} ref={divRef} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '10px',
          position: 'relative',
          zIndex: 10,
        }}
      >
        {showTemplates ? (
          <div
            style={{
              position: 'absolute',
              zIndex: callingComponent === 'grid' ? 100 :500,
              top: '-450px',
              left: callingComponent === 'grid' ? '170px' : '50px',
              border: '1px solid #aaa',
              backgroundColor: 'white',
              width: '510px',
              height: '401px',
            }}
          >
            <TemplateViewer
              appendTemplateMessage={appendTemplateMessage}
              showTemplates={setShowTemplates}
              messageTemplates={messageTemplates}
              color={color}
            />
           
          </div>
        ) : null}
        
        {isLoading ? (
            <div style={{ position: 'absolute', bottom: '250px', left: '50%',zIndex:1000 }}>
              <Spinner color={color} showMessages={false} />
            </div>
          ) : null}
  
        {phoneNumber === selectedNumber && canMessage ? (
          <div style={{ marginBottom: '10px', backgroundColor: 'white' }}>
          
            <SearchBar
              color={color}
              messageChangeHandler={messageChangeHandler}
              updateFileUrls={setFileUrls}
              fileUrls={fileUrls}
              message={message}
              zoneid={parseInt(Global.zoneid)}
              isEnabled={phoneNumber !== null}
              Action={SendMessage}
              messageSetting={messageSetting}
              updateMessageSetting={updateMsgSetting}
              setShowTemplates={setShowTemplates}
              updateSetting={updateSetting}
              dataID={dataID}
              callingComponent={callingComponent}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Messages;
