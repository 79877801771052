/* eslint-disable */
import React,{useEffect,useState} from 'react';
import { ReactComponent as Logo } from '../Auth/PhoneValidator/Success.svg';
import Icon from '@mdi/react';
import moment from 'moment';
import GBLogo from '../Block/components/TabLists/GBViewAll.svg';
import PAID from './PAID.svg';
import { mdiPrinter } from '@mdi/js';
import LogoIcon from '../../assets/icons/GraceBlocksNewLogo.svg';
import ActionButton from '../Block/components/ActionBar/ActionButton';

const PaymentSuccess = ({paymentDetails,toggleZoneSettings,zoneName}) => {

    const newDate = new Date();

    // const paymentDetails={"trxid":"C4B7FFCD-0004","invoicePDF":"http://www.google.com","paymentInfo":{"paymentId":"pm_1Hov38ERqpLfk4rFArNlFa1I","cardBrand":"visa","cardLast4":"0341","exp_month":3,"exp_year":2023,"isDefault":true},"currentPlan":{"planId":"plan_HBhQRKaOmuU7ZM","name":"Starter","interval":"monthly","unitPrice":19.99},"amountDue":5997,"amountPaid":0,"userLicenses":3,"lineItems":[],"status":"succeeded"}
    const [invoiceURL,setInvoiceURL] =useState('')

    useEffect(() => {
    },[])

    const showPDF =() => {
      window.open(paymentDetails.invoicePDF,'_blank')
    }

    const showLineItems = () =>
    paymentDetails.lineItems.map((el) => (
      <div
        key={el.id}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: el.type === 'plan' ? '#F1EFEF' : 'white',
          border: el.type === 'plan' ? '1px solid #FFBE24' : null,
          padding: el.type === 'plan' ? '5px' : '0px',
          borderRadius: '10px',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginLeft: el.type === 'plan' ? '40px' : '50px',
          marginRight: el.type === 'plan' ? '40px' : '50px',
          color: el.color,
        }}
      >
        <div
          style={{
            fontWeight: el.type === 'plan' ? 'bold' : null,
            paddingLeft: el.type === 'plan' ? '5px' : '0px',
          }}
        >
          {el.description}
        </div>
        <div style={{paddingRight: el.type === 'plan' ? '5px' : '0px',}}>${el.amount}</div>
      </div>
    ));


    return (
        <div style={{marginLeft:'40px', overflow:'auto', marginRight:'40px', marginTop:'20px',height:'calc(100vh - 180px)'}}>
     
            {paymentDetails.status==='succeeded' ? (
               <div style={{display:'flex', fontSize:'20px', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <div><Logo width="100px" height="100px" /></div>
                <div style={{width:'20px'}}></div>
                <div>Success!</div>
                </div>) : null}

             <div style={{display:'flex', marginLeft:'40px', marginRight:'40px', marginBottom:'20px', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <img src={LogoIcon} alt="Logo" />
                {/* <div style={{display:'flex', flexDirection:'column'}}><div>Print invoice</div><Icon onClick={() => window.open(paymentDetails.invoicePDF,'_blank')} path={mdiPrinter}  size="30px" /></div> */}
                <ActionButton label="Print invoice" actionIcon={mdiPrinter} hoverColor="#75757580" color="#757575" Action={showPDF} />
            </div>

            <div style={{display:'flex', fontSize:'20px', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                {paymentDetails.status !=='succeeded' ? (
                  <div style={{color:'#AE007B',fontSize:'13px',marginLeft:'40px',marginRight:'40px'}}>
                    <p>We have not been successful in processing this invoice.  We will continue daily attempts to charge the designated primary payment method and have sent notifications to all Zone owners via email regarding this status. This Zone will be deactivated on July 20, 2020 if the invoice remains unpaid.</p>
                    <p>What to do if your Zone is deactivated: Zone owners can re-activate the Zone by going to their User Profile, navigating to Inactive Zones and clicking the Activate Button for this Zone. Once a successful payment is processed, the Zone can be activated.</p>
                  </div>
                ) : null}
            </div> 
            
            <div style={{display:'flex', flexDirection:'column',marginLeft:'40px', marginTop:'30px',marginBottom:'20px',marginRight:'20px'}}>
                <div>Transaction ID: <b>{paymentDetails.trxid}</b></div>
                {/* <div>Date: <b>{newDate.toLocaleDateString('en-us')}</b> </div> */}
                <div>Date: <b>{moment().format('DD-MMM-YYYY HH:mm:ss')} EST</b></div>
            </div>
            <div style={{display:'flex',marginLeft:'40px', marginTop:'10px', flexDirection:'row', alignItems:'flex-start'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{fontWeight:'bold'}}>From</div>
                    <div style={{fontWeight:'bold'}}>GraceBlocks</div>
                    <div>45 Webster circle</div>
                    <div>Sudbury, MA 01776</div>
                    <div>email: support@graceblocks.com</div>
                    <div>website: www.graceblocks.com</div>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{fontWeight:'bold'}}>Receipt For: </div>
                    <div style={{fontWeight:'bold'}}>{zoneName}</div>
                </div>
            </div>

            <div style={{fontSize:'20px', borderBottom:'1px solid black',marginLeft:'40px', marginTop:'30px',marginRight:'20px'}}>Order Details</div>
            {paymentDetails.lineItems.length>0 ? (
              showLineItems()
            ) : (
              <div style={{display:'flex',marginLeft:'50px', marginTop:'10px',marginRight:'50px', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <div>{paymentDetails.currentPlan.name} - {paymentDetails.currentPlan.interval} - {paymentDetails.userLicenses} </div>
                <div>${paymentDetails.amountDue*.01}</div>
              </div>
            )}

            <div style={{display:'flex',marginLeft:'50px', marginTop:'10px',marginRight:'50px', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <div>Total due:</div>
                {paymentDetails.status==='succeeded' ? (
                <div><b>${(paymentDetails.amountPaid*.01).toFixed(2)}</b></div>
                ):  <div><b>${(paymentDetails.amountDue*.01).toFixed(2)}</b></div>}

            </div>
            {paymentDetails.status==='succeeded' ? (
            <div style={{marginRight:'200px',display:'flex', flexDirection:'row',justifyContent:'flex-end'}}>
              <img src={PAID} height="250px" width="250px" />
            </div>
            ): null}

            {/* <div style={{display:'flex',marginTop:'20px',fontSize:'20px',marginLeft:'40px',marginRight:'20px', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <div>Payment Method</div>
                <div>{paymentDetails.paymentInfo.cardBrand} {paymentDetails.paymentInfo.cardLast4}</div>
            </div> */}

            <div style={{ marginLeft:'40px',marginRight: '20px', marginBottom: '10px', display:'flex',marginTop:'20px' }}>
                <div
                    onClick={toggleZoneSettings}
                    style={{ backgroundColor: '#AE007B', width:'250px', borderRadius:'10px', padding: '6px', cursor: 'pointer' }}
                >
                    <img src={GBLogo} height="30px" />
                </div>
        </div>

        </div>
    )
}

export default PaymentSuccess