/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { css, jsx } from '@emotion/core'
import { mdiClose } from '@mdi/js';
import RowHeightButton from '../ActionBar/RowHeightButton';
import TextButton from '../../../../components/TextButton/TextButton';

const TemplateViewer = ({ messageTemplates, showTemplates, color,appendTemplateMessage,callingComponent="messages" }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [templates, setTemplates] = useState(messageTemplates);
  const [searchValue,setSearchValue] = useState('');

  const filterTemplates = (value) => {
      setSearchValue(value)
      const filteredTemplates = messageTemplates.filter(el=>el.fullname.toLowerCase().includes(value))
      setTemplates(filteredTemplates)
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          height: '40px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '10px',
          paddingRight: '10px',
          borderBottom:'1px solid black',
        }}
      >
        <div>Message Templates</div>
        <div>
          <TextButton
            text="Hide panel"
            hoverColor="#75757580"
            Action={showTemplates}
            icon={mdiClose} 
            ActionValue={false}
            textColor="black"
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100%', marginLeft: '10px', marginTop:'10px' }}>
          <Input
            // autoFocus
            onChange={(e, data) => filterTemplates(data.value)}
            value={searchValue}
            // icon='search'
            iconPosition="left"
            fluid
            placeholder="Find message template"
            style={{ outlineColor: 'red', width: '100%' }}
          >
            <Icon name="search" />
            <input defaultValue='' style={{ borderRadius: '10px' }} />
          </Input>
        </div>
        <div style={{ marginRight: '10px' }}>
          <RowHeightButton
            color={color}
            label="Height"
            labelPosition="right"
            showBar3={false}
            selectedHeightIcon={isExpanded ? 2 : 1}
            Action={setIsExpanded}
          />
        </div>
      </div>
      <div
        style={{
          // display: 'flex',
        //   border: '1px solid blue',
          // flexDirection: 'column',
          height: '300px',
          width: '95%',
          marginTop: '10px',
          marginLeft: '10px',
          marginRight: '10px',
          overflow:'auto'
        }}
      >
        {templates.length>0 &&
          templates.map((el) => (
            <div
            onClick={()=>appendTemplateMessage(el.id)}
              key={el.id}
               css={{
                cursor:'pointer',
                display: 'flex',
                overflow:'hidden',
                flexDirection: 'column',
                width: '90%',
                marginLeft: '10px',
                marginRight: '20px',
                alignItems: 'flex-start',
                justifyContent: isExpanded ? 'flex-start' : 'center',
                border: `1px solid #707070`,
                height: isExpanded ? '50px' : '30px',
                marginBottom: '10px',
                borderRadius: '25px',
                '&:hover'  : {
                    backgroundColor:'#F1EFEF',
                    borderColor: color
                }
              }}
            >
              <span style={{ marginLeft: '20px', color:color }}>{el.fullname}</span>
              {isExpanded ? <span style={{ marginLeft: '20px', marginBottom:'3px', lineHeight:'90%' }}>{el.message}</span> : null}
            </div>
          ))}
          {messageTemplates.length===0 ? (
            <div>There are currently no Messaging Templates configured</div>
          ): null}
      </div>
    </div>
  );
};

export default TemplateViewer;
