/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import SegmentInfo from './SegmentInfo';

const withAPI = () => {
  return ({ user, onChange, initialValues }) => {
    const [loading] = useState(false);
    const handleSubmit = useCallback(
      (values) => {
        onChange('segment', values);
      },
      [onChange],
    );

    const handleCancel = useCallback(() => {
      onChange('segment', {});
    }, [onChange]);

    return (
      <>
        {/* {alertComponent} */}
        <SegmentInfo
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          loading={loading}
          initialValues={initialValues}
        />
      </>
    );
  };
};

export default withAPI;
