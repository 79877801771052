import React from 'react';
import PropTypes from 'prop-types';
import FinalForm, { Form, FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';

const formValidators = {
  phoneNumber: validators.composeValidators(validators.required, validators.phoneNumber),
};

const SendForm = ({ onSubmit, initialValues }) => {
  return (
    <>
      <FormCard.Description className="mb-2 mb-4 text-left">
        Your number will be used to deliver important notifications, help you sign in, and more.
        Validate it below to proceed.
      </FormCard.Description>

      <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
        {({ invalid }) => {
          return (
            <>
              <FormField
                name="phoneNumber"
                label="Mobile Phone"
                placeholder="Enter your mobile phone number."
                validate={formValidators.phoneNumber}
                id="id_phone"
                control={Form.PhoneNumberControl}
              />
              <div className="mb-4">&nbsp;</div>
              <Button long type="submit" disabled={invalid}>
                Send GraceBlocks validation code
              </Button>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

SendForm.defaultProps = {
  onSubmit: () => {},
  initialValues: {},
};

SendForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default SendForm;
