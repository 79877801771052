/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { stripeInvoiceHistory } from '../../api/zone';
import Icon from '@mdi/react';
import Moment from 'react-moment';
import { mdiFilePdfBox } from '@mdi/js';
import phoneRates from './phoneRates';

const InvoiceHistory = ({ zoneInfo, toggleShowInvoiceHistory }) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const getinvoices = async () => {
      const invoiceList = await stripeInvoiceHistory(zoneInfo.stripeInfo);
      console.log(invoiceList.invoices.data)
      setInvoices(invoiceList.invoices.data);
    };
    getinvoices();
  }, []);

  const { REACT_APP_STRIPE_ENV } = process.env;

  let plans = [
    { planId: 'plan_HBhXGxjV2AW2rb', name: 'Free', interval: 'monthly', unitPrice: 0.0 },
    { planId: 'plan_FreeAnnual', name: 'Free', interval: 'annual', unitPrice: 0.0 },
    { planId: 'price_1HowgiERqpLfk4rFR2EHqlKH', name: 'Starter', interval: 'monthly', unitPrice: 14.99},
    { planId: 'plan_HBhV1eAXLDop7I', name: 'Starter', interval: 'annual', unitPrice: 143.88 },
    { planId: 'price_1HowwGERqpLfk4rFtdRUDOKV', name: 'Pro', interval: 'monthly', unitPrice: 24.99 },
    { planId: 'plan_HBgyGtfCemI04T', name: 'Pro', interval: 'annual', unitPrice: 251.88 },
    { planId: 'price_1OgTXOERqpLfk4rFf9hiQ9TL', name: 'GraceBlocks Support Services', interval: 'monthly', unitPrice: 100.00 },
    { planId: 'price_1OyOLEERqpLfk4rFPVR6YCAT', name: 'Custom From Address Implementation', interval: '', unitPrice: 200.00 },
  ];

  if (REACT_APP_STRIPE_ENV !== 'TEST') {
    plans = [
      { planId: 'price_1IC86NERqpLfk4rFZVn2LhSR', name: 'Free', interval: 'monthly', unitPrice: 0.0 },
      { planId: 'plan_FreeAnnual', name: 'Free', interval: 'annual', unitPrice: 0.0 },
      { planId: 'price_1IC8O4ERqpLfk4rFWeGZFugS', name: 'Starter', interval: 'monthly', unitPrice: 14.99 },
      { planId: 'price_1IC8O4ERqpLfk4rFyAEVCvK2', name: 'Starter', interval: 'annual', unitPrice: 143.88 },
      { planId: 'price_1IC8bqERqpLfk4rFWyNS5BaW', name: 'Pro', interval: 'monthly', unitPrice: 24.99 },
      { planId: 'price_1IC8bqERqpLfk4rFytoeAXHC', name: 'Pro', interval: 'annual', unitPrice: 251.88 },
      { planId: 'price_1NilAlERqpLfk4rFMo5Sh9zM', name: 'GraceBlocks Support Services ', interval: 'monthly', unitPrice: 100.00 },
      { planId: 'price_1Ns6d1ERqpLfk4rFNaJBTFUJ', name: 'Custom From Address Implementation', interval: '', unitPrice: 200.00 },
    ];
  }

  const getPlanInfo = (planId, amount) => {
    const idx = plans.findIndex(itm=>itm.planId===planId);
    const idx2 = phoneRates.findIndex(itm=>itm.planId===planId)

    let plan=''
    let detail='';
    if(idx !==-1 ){
      plan = plans[idx];
      detail = `${plan.interval.charAt(0).toUpperCase()}${plan.interval.slice(1)}.${plan.name}/${amount}`;
    } else if (idx2 !==-1){
      plan = phoneRates[idx2];
      // detail = `Phone: ${plan.name}/${amount}`;
      detail=`${plan.country}/Messaging phone number`
    } else {
      detail="Message funds"
    }
   
    return detail;
  };

  return (
    <div>
      <div
        style={{
          marginLeft: '30px',
          marginRight: '30px',
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        //   border:'1px solid red',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100px',textAlign:'left' }}>Date</div>
        <div style={{ width: '100px' }}>Description</div>
        <div style={{ width: '130px' }}>Reference ID</div>
        <div style={{ width: '100px' }}>Amount</div>
        <div style={{ width: '100px' }}>Status</div>
        <div style={{ width: '130px' }}>View invoice/receipt</div>
      </div>
        <div style={{overflow:'auto', height: 'calc(100vh - 200px'}}>
      {invoices.length > 0 &&
        invoices.map((el) => (
          <div
            key={el.id}
            style={{
            //   border:'1px solid red',
              marginLeft: '30px',
              marginRight: '30px',
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '100px' }}>
              <Moment format={'DD-MMM-YYYY'}>
                {el.created * 1000}
              </Moment>
              {/* {new Date(el.created * 1000).toLocaleDateString('en-us')} */}
            </div>
            {el.lines.data.length > 1 ? (
              <div style={{ width: '100px' }}>
                {getPlanInfo(el.lines.data[1].plan.id, el.lines.data[1].quantity)}
              </div>
            ) : (
              <div style={{ width: '100px' }}>
                {getPlanInfo(el.lines.data[0]?.plan !==null ? el.lines.data[0].plan.id : el.lines.data[0].price.id, el.lines.data[0].quantity)}
              </div>
            )}
            <div style={{ width: '130px' }}>{el.number}</div>
            <div style={{ width: '100px' }}>${(el.amount_due * 0.01).toLocaleString()}</div>
            <div style={{ width: '100px' }}>
              {el.status.charAt(0).toUpperCase() + el.status.slice(1)}
            </div>
            <div style={{ width: '130px', cursor: 'pointer' }}>
              <Icon
                onClick={() => window.open(el.hosted_invoice_url)}
                path={mdiFilePdfBox}
                size="40px"
                color="#AE007B"
              />
            </div>
          </div>
        ))}
    </div>

    </div>
  );
};

export default InvoiceHistory;
