/* eslint-disable */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import ZoneItem from './ZoneItem';
import { DASHBOARD } from '../../constants/routes';
import './styles.scss';

const ZoneDndList = ({ data, onChange,showSettings }) => {
  const [items, setItems] = useState(data);

  const sortedList = useMemo(() => {
    const list = [...items].filter(({ token }) => !!token);
    list.sort((a, b) => a.pos - b.pos);
    return list;
  }, [items]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const current = { ...sortedList[result.source.index] };
      const next = { ...sortedList[result.destination.index] };
      if (!result.destination.index) {
        current.pos = next.pos / 2;
      } else if (result.destination.index === sortedList.length - 1) {
        current.pos = next.pos + sortedList[result.destination.index - 1].pos;
        if (current.pos < 30000) {
          current.pos = 30000;
        }
      } else if (result.source.index - result.destination.index > 0) {
        current.pos = next.pos - (next.pos - sortedList[result.destination.index - 1].pos) / 2;
      } else if (result.source.index - result.destination.index < 0) {
        current.pos = next.pos + (sortedList[result.destination.index + 1].pos - next.pos) / 2;
      }

      const newList = Array.from(items);
      const index = items.findIndex((i) => i.token === current.token);
      newList[index] = current;
      setItems(newList);
      onChange(current, newList);
    },
    [items, setItems, sortedList, onChange],
  );

  useEffect(() => {
    setItems(data);
  }, [data, setItems]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames('zone-dnd-list', snapshot.isDraggingOver && 'dragging')}
          >
            {sortedList.map((item, index) => (
              <Draggable key={item.token} draggableId={item.token} index={index}>
                {(p) => (
                  <ZoneItem
                    ref={p.innerRef}
                    {...p.draggableProps}
                    to={`${DASHBOARD.ZONE_PREFIX}${item.id}`}
                    dragHandleProps={p.dragHandleProps}
                    item={item}
                    showSettings={showSettings}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ZoneDndList.defaultProps = {
  onChange: () => {},
  data: [],
  selected: null,
};

ZoneDndList.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.string,
};

export default ZoneDndList;
