/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import moment from 'moment';
import { mdiPencil,mdiMonitor,mdiLinkVariantPlus,mdiReload,mdiHistory,mdiEye,mdiPlus,mdiClose,mdiArrowLeft,mdiContentSaveEdit } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import Avatar from '../../../../components/Avatar/Avatar';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import { Input,TextArea,Form } from 'semantic-ui-react';
import {renameShare,updateShareStatus,generateNewLink,updateInstructions} from '../../../../api/shares'

const ShareDetails = ({ color, close, details,changeControl,enableBackArrow=false }) => {
  
   const {REACT_APP_SITE_URL} = process.env;
   const [edit, setEdit] = useState(false);
   const [editInstructions, setEditInstructions] = useState(false);

   const [expanded, setExpanded] = useState(false);  
   const [showPassword, setShowPassword] = useState(false);
   const [shareName, setShareName]=useState(details.name);
   const [instructions,setInstructions] =useState(details.instructions);
   const [isActive,setIsActive] =useState(details.isActive);
   const [link,setLink] = useState(details.shorturl);
   const [trackingcode, setTrackingCode] = useState(details.trackingcode);

   const security = details.settings.security===0 ? 'General' : details.settings.security===1 ? 'Level 1' : 'Level 2'

   useEffect(() =>{
    //  console.log(details)
   })

    const toggleExpand=()=>{
        setExpanded(!expanded)
    }

    const togglePassword=()=>{
        setShowPassword(!showPassword)
    }

    const toggleEdit =() =>{
        setEdit(!edit)
    }

    const toggleEditInstructions =() =>{
      setEditInstructions(!editInstructions);
    }

    const toggleIsActive = async () =>{
        await updateShareStatus(details.id,!isActive)
        setIsActive(!isActive);
        const term = isActive ? 'Deactivated' : 'Re-activated'
        toast.info(<div style={{margin:'10px'}}>The share has been successfully {term}</div>, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 7000,
          })
    }

    const generateNewLinkLocal = async() =>{
        const result = await generateNewLink(details.id);
        setLink(result.shorturl);
        setTrackingCode(result.trackingcode);
    }

    const updateName = async () =>{
        await renameShare(details.id,shareName);
        setEdit(false);
    }

    const saveInstructions = async () =>{
      await updateInstructions(details.id,instructions);
      setEditInstructions(false);
    }

    const copyLink =() => {

        var input = document.createElement('input');
        input.setAttribute('value', link);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        
        toast.info(
          <div style={{ margin: '5px' }}>
            Link was successfully copied!
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            // closeButton: false,
          },
        );
      }

  const previewShare = () =>{

    var x = screen.width/2 - 700/2;
    var y = screen.height/2 - 450/2;

    const url =`${REACT_APP_SITE_URL}/share/${trackingcode}`
    var iframe = `<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="${url}" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></html></body>`;
    var win = window.open("","",`width=1024,height=768,toolbar=no,menubar=no,resizable=yes,left=${x},top=${y}`);
    win.document.write(iframe);
    win.document.close();
  }


  return (
    <div style={{margin:'20px'}}>
       {enableBackArrow ? (
                <TextButton
                Action={changeControl}
                ActionValue={'list'}
                text={'SHARE CREATED'}
                textColor="black"
                hoverColor="#75757580"
                icon={mdiArrowLeft}
                iconPosition="left"
                />
       ): <div>SHARE CREATED</div>}
      
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!edit ? (
        <div style={{fontWeight:'bold'}}>{shareName}</div>
        ): <Input  action={{
            icon: 'save', onClick: (event,data)=>updateName()
        }} label="Name" value={shareName} onChange={(e,data)=>setShareName(data.value)} />}
        
        <div>
          <TextButton
            Action={toggleEdit}
            ActionValue={true}
            text={edit ? 'Cancel' : 'Rename'}
            textColor="black"
            hoverColor="#75757580"
            icon={edit ? mdiClose : mdiPencil}
            iconPosition="left"
          />
        </div>
      </div>
      <div style={{marginTop:'20px', marginBottom:'10px',fontWeight:'bold'}}>SHARE LINK</div>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
          <div style={{marginRight:'10px'}}>
              <TextButton 
                text="Preview" 
                textColor='black' 
                hoverColor="#75757580" 
                icon={mdiMonitor}
                iconPosition="left"
                Action={previewShare}
                />
          </div>
          <div style={{marginRight:'10px'}}>
              <TextButton 
                text="Copy link" 
                textColor='black' 
                hoverColor="#75757580" 
                icon={mdiLinkVariantPlus}
                iconPosition="left"
                Action={copyLink}
                />
          </div>
          <div>
              <TextButton 
                text="Generate new link" 
                textColor='black' 
                hoverColor="#75757580" 
                icon={mdiReload}
                iconPosition="left"
                Action={generateNewLinkLocal}
                />
          </div>
      </div>
      <div style={{backgroundColor:'#F3F3F3', height:'40px', alignContent:'center',overflow:'auto',padding:'5px'}}>
        {link}
      </div>
      <div style={{marginTop:'20px', marginBottom:'10px',fontWeight:'bold'}}>EMBED CODE FOR THIS LINK</div>
      <div style={{backgroundColor:'#F3F3F3', height:'80px', alignContent:'center',overflow:'auto',padding:'5px',}}>
        {
              `<iframe src="${link}" frameborder="0" onmousewheel="" width="100%" height="100%" style="background: transparent; border: 1px solid #0D47A1"></iframe>`
        }  
      </div>
      <div style={{marginTop:'20px'}}>Current link generated</div>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        <div>
          {moment.utc(details.addedon).local().format('DD-MMM-YYYY hh:mm:ss A z')}
        &nbsp; by: </div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginLeft:'10px'}}><Avatar item={details.addedby} />{details.addedby.firstname} {details.addedby.lastname} </div>
      </div>
        <div style={{marginTop:'20px',marginBottom:'20px'}}>
            <GBButton color={color} text="Share link history" icon={mdiHistory} Action={changeControl} ActionValue='history' iconColor="white" iconHoverColor="white" iconPosition="left" />
        </div>
        <div style={{marginBottom:'10px'}}>
           <GBSwitch text="Share settings" isChecked={expanded} Action={toggleExpand} color={color} />
        </div>
        {expanded ? (<>
             <div>
                 Shared content: <span style={{color:color}}>{details.bookmarkname}</span>
             </div>
             <div>
              Share security level:&nbsp; <span style={{color:color}}>{security}</span>
             </div>
             <div>
             Include record details:&nbsp; <span style={{color:color}}>{(details.settings.includeDetails !==undefined && details.settings.includeDetails===true) || details.settings.includeDetails===undefined ? 'Yes': 'No'}</span>
             </div>
             <div>
             Include attachments:&nbsp; <span style={{color:color}}>{details.settings.includeAttachments===true ? 'Yes': 'No'}</span>
             </div>
             <div>
             Include messages: &nbsp;<span style={{color:color}}>{details.settings.includeMessages===true ? 'Yes': 'No'}</span>
             </div>
             {details.settings.includeMessages ? (
                   <div style={{marginLeft:'20px'}}>
                   Include send messages: &nbsp;<span style={{color:color}}>{details.settings.includeSendMessage===true ? 'Yes': 'No'}</span>
                   </div>
             ): null}
             <div>
             Include collaboration notes entered by others: &nbsp;<span style={{color:color}}>{details.settings.includeComments===true ? 'Yes': 'No'}</span>
             </div>
            <div>
                Allow recipient to add notes:&nbsp; <span style={{color:color}}>{details.settings.includeAddComments===true ? 'Yes': 'No'}</span>
            </div>
             <div>
                 Include download record to csv: &nbsp;<span style={{color:color}}>{details.settings.includeDownload===true ? 'Yes': 'No'}</span>
             </div>
             <div>
                 Hide header: &nbsp;<span style={{color:color}}>{details.settings.hideHeader===true ? 'Yes': 'No'}</span>
             </div>
             <div>
                 Hide footer: &nbsp;<span style={{color:color}}>{details.settings.hideFooter===true ? 'Yes': 'No'}</span>
             </div>
             <div>
                 Enbable option to add new record: &nbsp;<span style={{color:color}}>{details.settings.includeWebform===true ? 'Yes': 'No'}</span>
                 {details.settings.includeWebform ? (<span  style={{color:color}}> Selected web form: {details.settings.formName}</span>) : null}
             </div>
             <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
                 Password protect link: &nbsp; <span style={{color:color}}>{details.settings.protectLink===true ? 'Yes': 'No'}</span>
                 {details.settings.protectLink ?  
                    showPassword ? <div> &nbsp; {details.settings.password} &nbsp; <Icon path={mdiEye} color="black" size="20px" onClick={togglePassword} /> </div>  : <span style={{marginLeft:'10px'}}> * * * * * <Icon path={mdiEye} color="black" size="20px" onClick={togglePassword} /></span>
                 : null}
             </div>
             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              Instructions: &nbsp; 
              {!editInstructions ? (
                <span style={{color:color}}>{instructions}</span>
              ) : (
                <Form>
                <TextArea value={instructions} onChange={(e,data)=>setInstructions(data.value)} />
                </Form>
              )}
              <TextButton
                Action={toggleEditInstructions}
                ActionValue={true}
                text={editInstructions ? 'Cancel' : 'Edit'}
                textColor="black"
                hoverColor="#75757580"
                icon={editInstructions ? mdiClose : mdiPencil}
                iconPosition="left"
              />
              {editInstructions ? (
                 <TextButton
                 Action={saveInstructions}
                 ActionValue={true}
                 text='Save'
                 textColor="black"
                 hoverColor="#75757580"
                 icon={mdiContentSaveEdit}
                 iconPosition="left"
               />
              ):null}
             </div>
        </>): null}
        <div style={{marginTop:'10px'}}>
          {details.isvalid ? (
        <TextButton Action={toggleIsActive} iconPosition="left" textColor='#757575' hoverColor= {isActive ? 'red' :'green'} text={isActive ? 'Deactivate' : 'Re-activate' } icon={isActive ? mdiClose : mdiPlus} />
        ): 'No longer valid'}
        </div>
       

    </div>
  );
};

export default ShareDetails;
