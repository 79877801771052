/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import { Input } from 'semantic-ui-react';

const TextInput = ({
  color,
  field,
  name,
  icon,
  updateValue,
  defaultValue = '',
  isRequired = false,
  isTextArea = false,
  marginLeft = '12px',
  placeholder,
  fontWeight = 'normal',
  width = '300px',
  Action,
  value,
  type = 'text',
  fontFamily = 'Nunito Sans',
  fontSize = '13px',
  backgroundColor = 'white',
  textColor,
  readOnly=false,
  error=false,
  labelsInCaps=false,
  min=null,
  max=null,
  autoFocus=false,
}) => {

  const handleFocus = (event) => event.target.select();

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          fontSize,
          fontFamily: fontFamily,
          color: textColor === undefined ? color : textColor,
          marginLeft: marginLeft,
          marginBottom: '5px',
          fontWeight,
        }}
      >
        {labelsInCaps ? name.toUpperCase() : name}
        {isRequired && name.length>0 ? <span style={{ color: '#E06055', marginLeft: '5px' }}>*</span> : ''}
      </div>
      <div style={{ marginLeft: marginLeft, display: 'flex', justifyContent: 'space-between',position:'relative' }}>
        {!isTextArea ? (<>
          <input
            autoFocus={autoFocus}
            type={type}
            min={min}
            max={max}
            onClick={Action}
            value={value}
            readOnly={readOnly}
            icon={icon !== undefined ? icon : null}
            iconposition={icon !== undefined ? 'left' : null}
            onChange={(e, data) => updateValue(field, e.target.value)}
            onFocus={handleFocus} 
            css={{
              height: '35px',
              backgroundColor,
              paddingLeft: '15px',
              width: width,
              outline: '0px',
              border: '0px',
              // borderLeft: `3px solid ${color}`,
              borderRadius: '5px',
              borderTop: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              borderRight: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              borderBottom: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              '&:focus': {
                outline: 'none !important',
                // borderLeft: `7px solid ${color}`,
                borderTop: `1px solid ${color}`,
                borderRight: `1px solid ${color}`,
                borderBottom: `1px solid ${color}`,
              },
            }}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
          <div style={{position:'absolute', borderTopLeftRadius:'3px', borderBottomLeftRadius:'3px', width:'7px',height:'100%',top:0,left:0,backgroundColor:color}} >&nbsp;</div>
        </>) : (<>
          <textarea
            onClick={Action}
            value={value}
            rows={4}
            icon={icon !== undefined ? icon : null}
            iconPosition={icon !== undefined ? 'left' : null}
            onChange={(e, data) => updateValue(field, e.target.value)}
            css={{
              height: '60px',
              paddingLeft: '15px',
              width: width,
              outline: '0px',
              overflow:'hidden',
              border: '0px',
              borderLeft: `7px solid ${color}`,
              borderRadius: '5px',
              borderTop: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              borderRight: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              borderBottom: error ? '1px solid #E06055' : '1px solid #DEDEDF',
              '&:focus': {
                outline: 'none !important',
                borderLeft: `7px solid ${color}`,
                borderTop: `1px solid ${color}`,
                borderRight: `1px solid ${color}`,
                borderBottom: `1px solid ${color}`,
              },
            }}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
          <div style={{position:'absolute', borderTopLeftRadius:'3px', borderBottomLeftRadius:'3px', width:'7px',height:'100%',top:0,left:0,backgroundColor:color}} >&nbsp;</div>
        </>)}
      </div>
    </div>
  );
};

export default TextInput;
