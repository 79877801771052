/*
2-7-24
New method to make exporting data an async function processed on background task
and which creates a CSV file uploaded to Google storage and added as a notifiction where
user can download it.
*/
/* eslint-disable */

import axios from 'axios';
import { getIdToken } from '../clients/firebase';
import Global from '../clients/global'

const ExportRequest = async (tableinfo,tableid,tablename,blockName,filters,offset,blockid,canUnmask=false,role=0) => {

    //We will call the API URL to do this processing.
    const {REACT_APP_API_URL} = process.env;

    let payload={
        tableinfo,
        tablename,
        blockName,
        tableid,
        blockid,
        limit:100000,
        offset,
        filters,
        sortfield:'id',
        sortorder:'asc',
        callingComponent:'export',
        role:role,
        canUnmask
      }

      const token = await getIdToken();

      const result = await axios({
        url: `${REACT_APP_API_URL}/exportdata`,
        headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}`, 'zoneid': Global.zoneid },
        method: 'post',
        data: {
          payload,
        },
    });

    // const result = await cloudrun.post('/exportData', { payload });   
    return [];

}

export default ExportRequest