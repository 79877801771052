/* eslint-disable */
import React, { useContext, useState } from 'react';
import { AccountContext } from '../../../../providers/AccountProvider';
import { SettingsIcon } from '../../../../components/Icon';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';

const MessageSettings = ({ color, close, messageSetting, updateMessageSetting }) => {

  const [isDelayed, setIsDelayed] = useState(messageSetting.interval !== 'none');
  const [delayInterval, setDelayInterval] = useState(messageSetting.interval);
  const [number, setNumber] = useState(messageSetting.number);
  const [isValid, setisValid] = useState(true);

  const updateDelay = () => {
    setIsDelayed(!isDelayed);
    isDelayed ? setDelayInterval('none') : setDelayInterval('minutes');
  };

  const handleChange = () => {
    if (number > 0 && number < 1000) {
      const msgInterval = { interval: !isDelayed ? 'none' : delayInterval, number: number };
      setisValid(true);
      updateMessageSetting(msgInterval);
      close();
    } else {
      setisValid(false);
    }
  };

  return (
    <div style={{border:'.1px solid #757575', borderRadius:'10px',width:'200px',height:'150px'}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTopLeftRadius:'10px',
          borderTopRightRadius:'10px',
          backgroundColor: color,
          height: '30px',
          color: 'white',
        }}
      >
        <SettingsIcon color="white" />
        <span style={{ marginLeft: '5px' }}>Message settings</span>
      </div>
      <div
        style={{
          display: 'flex',
          // backgroundColor:'white',
          borderRadius: '5px',
          flexDirection: 'column',
          marginLeft:'20px',
          // justifyContent: 'flex-end',
          // paddingRight: '30px',
          // alignItems: 'center',
        }}
      >
        {!isValid ? (
          <div style={{ color: 'red', marginRight: '10px' }}>
            Must be greater than 0 and less than 999
          </div>
        ) : null}
        <div style={{ marginRight: '10px', paddingBottom: '10px',marginTop:'10px' }}>
          <GBSwitch color={color} text="Message delay" isChecked={isDelayed} Action={updateDelay} />
        </div>
        {isDelayed ? (
          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start',marginTop:'5px'}}>
            <div style={{ width: '50px', marginRight: '10px' }}>
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                style={{ width: '50px' }}
              />
            </div>
            <div style={{ fontSize: '13px', marginRight: '10px' }}>
              <select style={{height:'20px'}} value={delayInterval} onChange={(e) => setDelayInterval(e.target.value)}>
                <option value="minutes">Minute(s)</option>
                <option value="hours">Hour(s)</option>
                <option value="days">Day(s)</option>
              </select>
            </div>
          </div>
        ) : null}
        <div style={{display:'flex', marginLeft:'0px', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop:'10px',marginBottom:'20px'}}>
        <div style={{marginRight:'5px'}}>
          <TextButton text="Cancel" Action={close} hoverColor="#75757580" textColor="black" />
        </div>
        <GBButton text="Save" color={color} width="50px" Action={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default MessageSettings;
