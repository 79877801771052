/* eslint-disable */
import React, { useContext } from 'react';
import { Redirect } from '@reach/router';
import { useAlert } from '../../components/Alert';
import BlocksLoader from './components/BlocksLoader';
import BlocksError from './components/BlocksError';
import useZone from './hooks/useZone';
import useBlocks from './hooks/useBlocks';
import useCacheBlocks from './hooks/useCacheBlocks';
import { AccountContext } from '../../providers/AccountProvider';
import {checkEmailVerified} from '../../api/auth'
import {plans} from '../Zone/plans';
import Global from '../../clients/global'

const withAPI = (Component) => {
  return ({ zoneID: zoneid }) => {
    
    const zoneID = parseInt(zoneid, 10);
    const { REACT_APP_PUBLIC_ZONE_ID } = process.env;

    const [alertComponent, alert] = useAlert();
    const { userTokenResult,userInfo} = useContext(AccountContext);
    const zoneDetail = useZone(zoneID);

    const { loading, data, error } = useBlocks(zoneDetail);
    const {
      loading: loadingForCache,
      blocks,
      onAddBlock,
      onChangeBlock,
      onCopyBlock,
      onImportBlock,
      onDeleteBlock,
      onRightMoveBlock,
      onLeftMoveBlock,
    } = useCacheBlocks(data, alert);

    if (!zoneDetail) {
      return <BlocksError />;
    }

    if (loading || userTokenResult.zoneSwitching) {
      return <BlocksLoader zone={zoneDetail} />;
    }

    if (parseInt(Global.zoneid) !== zoneDetail.id) {
      return <Redirect to="/" noThrow />;
    }

    if (error) {
      return <BlocksError zone={zoneDetail} error={error} />;
    }

    let emailStatus = checkEmailVerified() ? 'Verified' : 'Not verified';

    // 10-6-2020 the showAddBlock refernces zoneDetail.role ==-undefined. This happens when user
    // clicks to create a new Zone in UI. The system doesn't get all zone details and therefore no role
    // exists. However, once user refreshes or loads page back, it will always be defined so it should
    // be safe to show the addBlock in this inital setup action.
    
    // 5-2-20222 add support for public block/public user to show add block.
    
    const showAddLogic =() =>{
      let show = true;
      show =parseInt(Global.zoneid) === zoneID && zoneDetail.token !==null && (zoneDetail.role ===undefined || zoneDetail.role.toLowerCase()==='zone owner' || zoneDetail.role.toLowerCase()==='zone builder');
      return show;
    }

    return (
      <>
        <Component
          title={zoneDetail.token !==null && zoneDetail ? zoneDetail.name : 'No Zone selected'}
          blocks={blocks}
          loading={loadingForCache}
          showAddBlock={showAddLogic()}
          onAddBlock={onAddBlock}
          onChangeBlock={onChangeBlock}
          onImportBlock={onImportBlock}
          onCopyBlock={onCopyBlock}
          onDeleteBlock={onDeleteBlock}
          onRightMoveBlock={onRightMoveBlock}
          onLeftMoveBlock={onLeftMoveBlock}
          maxBlockCount={plans.filter(itm=>itm.type===zoneDetail.plan)[0].blocks+1}
          zoneDetail={zoneDetail}
          emailStatus={emailStatus}
        />
        {alertComponent}
      </>
    );
  };
};

export default withAPI;
