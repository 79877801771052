
/* eslint-disable */
import React,{useEffect,useState} from 'react';
import GettingStarted  from './gettingStarted';
import Authentication from './authentication';
import Errors from './errors';
import Pagination from './pagination';
import {getFirstTableInBlock} from '../../api/blocks'

const GeneralInfo =({color, name, block, tableId, apiKey,blockKey}) => {

   const [tablename,setTablename] =useState('');

    useEffect(() => {
        if (tableId !== null ) {
          const divElement = document.getElementById(tableId);
          divElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, [tableId]);

    useEffect(() => {
      getTable();
    },[])

    const getTable = async () => {
      const tbl = await getFirstTableInBlock(block.blockid)
      setTablename(tbl)
      console.log(tbl);
    }

    return(
        <div style={{ margin:'20px', overflow:'auto'}} >
              <GettingStarted color={color} name={name} tablename={tablename} />
            
              <Authentication color={color} name={name} apiKey={apiKey} blockKey={blockKey} tablename={tablename} />
           
              <Errors color={color} tablename={tablename} />

              <Pagination color={color}  blockKey={blockKey} tablename={tablename} />
              <div style={{marginBottom:'800px'}}></div>
        </div>
    )
}

export default GeneralInfo;