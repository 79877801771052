/* eslint-disable no-console */
/* eslint-disable */
import React, {useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Icon from '@mdi/react';
import { Popup } from 'semantic-ui-react';
import { mdiInformationOutline} from '@mdi/js';
import FinalForm, { Form, FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';
import GoogleButton from '../../../components/GoogleButton';
import MicrosoftButton from '../../../components/MicrosoftButton';
import Loader from '../../../components/Loader';
import { EmailIcon } from '../../../components/Icon';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';
import './styles.scss';

const formValidators = {
  firstName: validators.composeValidators(validators.required, validators.minLength(1)),
  lastName: validators.composeValidators(validators.required, validators.minLength(2)),
  email: validators.composeValidators(validators.required, validators.email),
  password1: validators.composeValidators(
    validators.required,
    validators.minLength(8),
    validators.password,
  ),
};



const equalToPassword = (value, values) => {
  if (value !== values.password1) {
    return 'Passwords are not equal.';
  }
  return undefined;
};

const SignUp = ({onSubmit, onGoogleSignIn, onMicrosoftSignIn, loading}) => {
  const { REACT_APP_CURRENT_VERSION } = process.env;
  const [code,setCode] = useState(null);

  useEffect(()=>{
    const query = new URLSearchParams(window.location.search);
    const tempcode = query.get('code')
    if(tempcode !==undefined) {
      setCode(tempcode);
    }
  },[])

  return (
    <>
      <div className="help-to-sign-in small" style={{fontSize:'13px'}}>
        Already have an account? <Link to="/auth/sign-in">Sign in</Link>.
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img src={LogoIcon} alt="Logo" />
      
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize:'13px',
        }}
      >
        Open Beta&nbsp; <Popup content={`${REACT_APP_CURRENT_VERSION}`} trigger={<Icon path={mdiInformationOutline} size="15px" color="black" />} />
      </div>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          code,
        }}
      >
        {({ errors, hasSubmitErrors, dirtySinceLastSubmit }) => {
          return (
            <>
              <FormCard title="Sign Up">
                <GoogleButton className="mb-4" onClick={()=>onGoogleSignIn(code)}>
                  Sign up with Google
                </GoogleButton>
                <MicrosoftButton className="mb-4" onClick={()=>onMicrosoftSignIn(code)}>
                  Sign up with Microsoft
                </MicrosoftButton>
                <FormCard.Subtitle className="my-2">or sign up manually</FormCard.Subtitle>
                <FormField
                  name="firstName"
                  label="First"
                  placeholder="Enter your first name."
                  validate={formValidators.firstName}
                  controlId="id_firstName"
                />
                <FormField
                  name="lastName"
                  label="Last"
                  placeholder="Enter your last name."
                  validate={formValidators.lastName}
                  controlId="id_lastName"
                />
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Enter your email address."
                  validate={formValidators.email}
                  leftIcon={<EmailIcon />}
                  controlId="id_email"
                  autocomplete="username"
                />
                <FormField
                  name="password1"
                  label="Password"
                  placeholder="Create your password."
                  validate={formValidators.password1}
                  control={Form.PasswordControl}
                  controlId="id_password1"
                  helpText="Use 8 or more characters using letters, numbers and symbols."
                  validateFields={['password2']}
                  showLevelTooltip
                  autocomplete="current-password"
                />
                <FormField
                  name="password2"
                  label="Password confirmation"
                  placeholder="Re-enter your password."
                  validate={equalToPassword}
                  control={Form.PasswordControl}
                  controlId="id_password2"
                  hideErrors={!!errors.password1}
                />
                {code !==null ? (
                 <FormField
                  name="code"
                  label="Product code"
                  placeholder="Product code"
                  controlId="id_Code"
                />
                ):null}
              </FormCard>
              <div className="text-center" style={{marginTop:'20px'}}>
                <Button long type="submit" disabled={hasSubmitErrors && !dirtySinceLastSubmit}>
                  Sign up for free
                </Button>
                <div className="small pt-1">
                  By creating an account you agree to the{' '}
                  <a
                    href="http://www.graceblocks.com/termsofservice"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                  .
                </div>
              </div>
              {loading && <Loader />}
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

SignUp.defaultProps = {
  onSubmit: () => {},
  onGoogleSignIn: () => {},
  loading: false,
};

SignUp.propTypes = {
  onSubmit: PropTypes.func,
  onGoogleSignIn: PropTypes.func,
  loading: PropTypes.bool,
};

export default SignUp;
