import React from 'react';
import { Input } from 'semantic-ui-react';

const AdvancedField = ({ name, field, value, changeHandler,width, placeholder,marginTop="10px" }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop }}>
      <div style={{ marginBottom: '3px' }}>
        <strong>{name}</strong>
      </div>
      <div
        style={{
          display: 'flex',
          width,
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Input
          size="small"
          value={value || ''}
          onChange={(e, data) => changeHandler(field,data.value)}
          style={{width}}
          placeholder={placeholder}
        />
    
      </div>
    </div>
  );
};

export default AdvancedField;
