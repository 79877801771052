export default [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Computer hardware', label: 'Computer hardware' },
  { value: 'Construction and materials', label: 'Construction and materials' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Financial services', label: 'Financial services' },
  { value: 'Food and beverage', label: 'Food and beverage' },
  { value: 'Goods (household, leisure, personal)', label: 'Goods (household, leisure, personal)' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media and telecommunications', label: 'Media and telecommunications' },
  { value: 'Non-profit', label: 'Non-profit' },
  { value: 'Other', label: 'Other' },
  { value: 'Real estate', label: 'Real estate' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Software and computer services', label: 'Software and computer services' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Utilities', label: 'Utilities' },
];

// export default [
//   { value: 1, label: 'Agriculture' },
//   { value: 2, label: 'Automotive' },
//   { value: 3, label: 'Chemicals' },
//   { value: 4, label: 'Computer hardware' },
//   { value: 5, label: 'Construction and materials' },
//   { value: 6, label: 'Education' },
//   { value: 7, label: 'Energy' },
//   { value: 8, label: 'Financial services' },
//   { value: 9, label: 'Food and beverage' },
//   { value: 10, label: 'Goods (household, leisure, personal)' },
//   { value: 11, label: 'Government' },
//   { value: 12, label: 'Healthcare' },
//   { value: 13, label: 'Hospitality' },
//   { value: 14, label: 'Insurance' },
//   { value: 15, label: 'Manufacturing' },
//   { value: 16, label: 'Media and telecommunications' },
//   { value: 17, label: 'Non-profit' },
//   { value: 18, label: 'Other' },
//   { value: 19, label: 'Real estate' },
//   { value: 20, label: 'Retail' },
//   { value: 21, label: 'Software and computer services' },
//   { value: 22, label: 'Technology' },
//   { value: 23, label: 'Utilities' },
// ];
