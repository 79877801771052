/* eslint-disable */
import React, { useEffect, useState, useContext,useRef } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { AccountContext } from '../../../../../providers/AccountProvider';
import { debounce } from 'debounce';
import NameControl from './NameControl';
import SendButton from '../../MessageView/SendButton';
import GBSwitch from 'src/components/GBSwitch/GBSwitch';
import { mdiPencilOutline, mdiEmailPlus,mdiChatPlusOutline } from '@mdi/js';

const LeftNav = ({
  data,
  messageField,
  color,
  setCurrentRecord,
  tableinfo,
  useSecurity,
  role,
  loadData,
  recordCount,
  composeEmail,
  composeText,
  emailNode,
  messageNode,
  tableid
}) => {
  const [selectedRecord, setSelectedRecord] = useState(data[0]);
  const { userInfo } = useContext(AccountContext);
  const [sortedData, setSortedData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showTextInfo, setShowTextInfo] = useState(true);
  const [selectAll, setSelecteAll] = useState(false);
  const [date, setDate] = useState(null);
  const userid = userInfo.id;


  useEffect(() => {
  
    let finalData = structuredClone(data);
    if (messageField !== null && showTextInfo) {
      sortData();
      setShowTextInfo(true);
    } else {
      setSortedData(finalData);
    }

  }, [messageField, data]);


  const sortData = () => {
    let finalData = structuredClone(data);
    const NonNullData = finalData.filter(
      (e) => e[messageField] && e[messageField].addedon !== null,
    );

    const nullData = structuredClone(data).filter((e) => e[messageField]===undefined);

    NonNullData.sort(
      (a, b) => new Date(b[messageField][0].addedon) - new Date(a[messageField][0].addedon),
    );
    
    finalData = NonNullData.concat(nullData);
    setSortedData(finalData);
  };

  const toggleShowTextInfo = () => {
    setShowTextInfo(!showTextInfo);
    if (showTextInfo) {
      setSortedData(data);
    } else {
      sortData();
    }
  };

  const ShowComposeEmail = () =>{
     const emailRecords =[];
     data.filter(itm=>selectedItems.includes(itm.id) && ((itm[emailNode.data] !==null && itm[emailNode.data] !==undefined) || (itm[emailNode.alternateEmailField] !==null && itm[emailNode.alternateEmailField] !==undefined) )).map(el=>{
    
      let email=''

      if(el[emailNode.data] !==null && !Array.isArray(el[emailNode.data])){
        email = el[emailNode.data];
       } else if(el[emailNode.data] !==null && (Array.isArray(el[emailNode.data]) && el[emailNode.data][0].data !==null )) {
        email = el[emailNode.data][0].data;
       } else if(el[emailNode.alternateEmailField] !==null && (Array.isArray(el[emailNode.alternateEmailField]) && el[emailNode.alternateEmailField][0].data !==null )){
        email = el[emailNode.alternateEmailField][0].data;
       } else if(el[emailNode.alternateEmailField] !==null && !Array.isArray(el[emailNode.alternateEmailField])){
        email = el[emailNode.alternateEmailField];
       }

       if(email !=='') {
          emailRecords.push({id:el.id,email:email,name:el['CustomID']})
       }
       
     })
     
     if(emailRecords.length>0) {
      composeEmail(emailRecords.slice(0,200))
     }
  }

  const ShowComposeText = () =>{
    const txtRecords =[];
    data.filter(itm=>selectedItems.includes(itm.id) && (itm[messageNode.phonefield] !==null || itm[messageNode.altPhoneField] !==null)).map(el=>{
     let number='';

     if(el[messageNode.phonefield] !==null && !Array.isArray(el[messageNode.phonefield])) {
      number = el[messageNode.phonefield];
     }  else if(el[messageNode.phonefield] !==null && (Array.isArray(el[messageNode.phonefield]) && el[messageNode.phonefield][0].data !==null )) {
      number = el[messageNode.phonefield][0].data;
     } else if(el[messageNode.altPhoneField] !==null && (Array.isArray(el[messageNode.altPhoneField]) && el[messageNode.altPhoneField][0].data !==null )){
      number = el[messageNode.altPhoneField][0].data;
     }  else if(el[messageNode.phonefield] !==null && !Array.isArray(el[messageNode.phonefield])){
      number = el[messageNode.altPhoneField];
     }

      if(number !=='') {
        txtRecords.push({id:el.id,number:number,CustomID:el['CustomID']})
      }   
    })
    
    if(txtRecords.length>0) {
     composeText(txtRecords.slice(0,100))  //we only allow 100 records to be sent text messages at once.
    }
 }


  const scrollCheck = async (e) => {

    if (data.length === recordCount) {
      return false;
    }
    if (e.target.scrollHeight - e.target.scrollTop < e.target.scrollHeight * 0.05) {
      await delayWhileLoading();
    }
  };

  const loadDataLocal = async () => {
    await loadData(data.length);
  };

  const handleCheckBox = (isChecked) => {
    if (!isChecked) {
      setSelectedItems([]);
      setSelecteAll(false)
    } else {
    
      setSelectedItems(sortedData.slice(0,200).map(itm=>itm.id));
      setSelecteAll(true);
    }
  };

  const delayWhileLoading = debounce(loadDataLocal, 1000);

  const selectRecord = (record) => {
    // let canEdit = true;
    // if (useSecurity) {
    //   canEdit = checkRowSecurity(record);
    // }
    // record.canEdit = canEdit;
    setSelectedRecord(record);
    setCurrentRecord(record);
  };

  const toggleCheck = (isChecked, id) => {

    if (!isChecked) {
      setSelectedItems(selectedItems.filter((el) => el !== id));
    } else {
      const idx = selectedItems.findIndex(el => el === id);
      if (idx === -1) {
        selectedItems.push(id);
        setSelectedItems(selectedItems);
      }
    }

    //This is needed to have checkmarks properly show/not show.
    setDate(new Date())

  };


  const checkRowSecurity = (record) => {
    let canEdit = false;
    if (tableinfo.security.editOthersRecord <= role) {
      const collabFields = tableinfo.columns.filter(
        (el) => el.uitype === 8 || (el.source && el.source.lookupuitype === 8),
      );
      collabFields.forEach((itm) => {
        if (record[itm.data] !== null) {
          const idx = record[itm.data].findIndex((el) => el.userid === userid);
          if (idx !== -1) canEdit = true;
        }
      });
      if (record.addedby.userid === userid) canEdit = true;
    } else {
      if (record.addedby.userid === userid) canEdit = true;
    }
    return canEdit;
  };

  return (
    <div
      style={{
        display: 'flex',
        // height: 'calc(100vh - 150px)',
        width: '500px',
        flexDirection: 'column',
        borderRight: '1px solid #eee',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: '10px',
          paddingRight: '10px',
          alignItems: 'center',
          borderBottom: '1px solid #eee',
          justifyContent: 'space-between',
          height: '80px',
        }}
      >
        <div>
          <></>
        </div>

        {messageField !== null ? (
          <GBSwitch
            width="150px"
            color={color}
            isChecked={showTextInfo}
            Action={toggleShowTextInfo}
            text="Text message summaries"
          />
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'flex-end',
            marginRight: '10px',
          }}
        >
          <div style={{ marginTop: '10px', fontWeight: 'bold', marginRight: '5px' }}>COMPOSE</div>
          {messageField !== null ? (
            <SendButton
              iconPosition="right"
              color={color}
              icon={mdiChatPlusOutline}
              Action={ShowComposeText}
              ActionValue={true}
              text="Text"
              width="85px"
            />
          ) : null}
            <div style={{ width: '20px' }} />
          {emailNode !== null ? (
            <SendButton
              iconPosition="right"
              color={color}
              icon={mdiEmailPlus}
              Action={selectedItems.length > 0 ? ShowComposeEmail : null}
              ActionValue={null}
              text="Email"
              width="85px"
            />
          ) : null}
        
        </div>
      </div>
      
      <div style={{margin:'5px',marginLeft:'10px'}}>
      <Checkbox checked={selectAll} onChange={(e, data) => handleCheckBox(data.checked)} />
      </div>
      <div
        onScroll={scrollCheck}
        style={{
          //   borderRight: '1px solid #eee',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {sortedData &&
          sortedData
            .filter((el) => el.id && el.id !== null)
            .map((el, index) => (
              <NameControl
                isChecked={selectedItems.includes(el.id)}
                toggleCheck={toggleCheck}
                key={el.id}
                index={index}
                isSelected={selectedRecord.id === el.id}
                currentRecord={el}
                setCurrentRecord={selectRecord}
                messageField={messageField}
                color={color}
                showTextInfo={showTextInfo}
              />
            ))}
      </div>
    </div>
  );
};

export default LeftNav;
