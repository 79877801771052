/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { getOptionsAPI } from '../../../../api/options';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import { Input } from 'semantic-ui-react';

const EditAttachment = ({ allCategories, optionid, file, close, color, updateItem,imageIndex }) => {
 
  const [name, setName] = useState(file.filename.split('.')[0]);
  const [categoryId, setCategoryId] = useState(optionid);
  const [ext, setExt] = useState(file.filename.split('.').pop())

  const setCategory = (el) => {
    setCategoryId(el.optionid);
  };

  // const sortedCategories = allCategories.sort((a, b) => (a.rank < b.rank ? 1 : -1))
  // useEffect(()=>{
  //   console.log(file)
  // })

  // useEffect(() => {
  //   // setName(file.filename.split('.')[0]);
  // }, [file]);

  useEffect(() => {
    setCategoryId(optionid);
  }, [optionid]);

  const updateFile = () => {
    const newCategory = allCategories.filter((itm) => itm.optionid == categoryId)[0];
     
    // 1-5-2021 We pass the current image index to top container so that when we rename file, it maintains it's spot in carousel.
    // however, if we change category, we should set image index=0
    let tempIndex =imageIndex;
    if(categoryId !==optionid) {
      tempIndex=0
    }
    updateItem(file.url, newCategory, `${name}.${ext}`,tempIndex);
    setName('');
    close();
  };

  const createtags = () => {
    // if (optionid === '1') {
    //   categories.push(categories.splice(0, 1)[0]);
    // }
    return allCategories.map((el) =>
      el && el.option ? (
        <div
          onClick={() => setCategory(el)}
          key={el.optionid}
          css={{
            cursor: 'pointer',
            width: 'max-content',
            backgroundColor: el.optionid == categoryId ? el.attributes.color : '#FFFFFF',
            color: el.optionid == categoryId ? el.attributes.text : '#757575',
            border: `2px solid ${el.attributes.color}`,
            paddingLeft: '10px',
            paddingTop: '10px',
            marginBottom: '10px',
            paddingBottom: '10px',
            height: '16.67px',
            whiteSpace: 'nowrap',
            marginRight: '10px',
            display: 'inline-flex',
            verticalAlign: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingRight: '10px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: `${el.attributes.color}80`,
              color: '#ffffff',
            },
          }}
        >
          {el.option}
        </div>
      ) : null,
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          padding: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          backgroundColor: color,
          color: 'white',
          height: '42px',
        }}
      >
        Edit attachment properties
      </div>
      {allCategories.length > 1 ? (
        <>
          <div style={{ margin: '10px', fontWeight: 'bold' }}>ATTACHMENT CATEGORY</div>
          <div style={{ width: '500px', margin: '10px' }}>
            {allCategories.length > 0 ? createtags() : null}
          </div>
        </>
      ) : null}
      <div style={{ margin: '10px' }}>
        <div style={{ fontWeight: 'bold' }}>ATTACHMENT NAME</div>
        <Input
          onChange={(e, data) => setName(data.value)}
          value={name}
          style={{ width: '100%' }}
          fluid
          placeholder="attachment name"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: '10px',
          }}
        >
          <div></div>
          <div>File type extension: .{ext}</div>
        </div>
      </div>
      <div className="row" style={{ justifyContent: 'center' }}>
        <TextButton textColor="black" Action={close} ActionValue={false} text="Cancel" hoverColor="#75757580" />
        <div style={{ width: '20px' }} />
        <GBButton Action={updateFile} text="Save" width="50px" color={color} />
      </div>
    </div>
  );
};

export default EditAttachment;
