/*
SUMMARY: mph 4-11-20
This file contains all the table related API's for retrieving and updating table related actions.

getTablesAPI: [blockid: integer] Retrieves all tables that belong to a given block.

getFieldsAPI: [tableid: integer] Retrieves all the fields from a given table from the tableinfo. This is saved in an array format
which is easy to bind to the sematnic dropdown list format.

getTableApi: [tableid: integer]: Retrieve s full table record including tableinfo, block, name, color, icon, etc

updateTableInfoAPI: [tableid: integer, tableinfo: json] - This is used to update the tableinfo for a given table.
This method is used extensively whenever we need to change column or table info

UpdateTableColumnOrderAPI: [tableid: integer, columnarray: array of strings]. This method is used to sort the column
order which is how columns appear in the grid. Whenver a user moves a column in the grid, it also calls this API to update the DB.

UpdateTableColumnWidthAPI: [tableid: integer, columnName: string ,newWidth: integer]. This method is use to update
the column width setting. Anytime a user resizes a column in the grid, this method is called to update DB

updateColumnHeaderAPI = [tableid: integer, columnName:string, header:string, uitype:integer): This method is used
  to update the column header and uitype. This is called when a user renames a column or when they change the column type.

updateTableOrderAPI = [tbl1: integer,tbl2:integer,to:integer,from:integer]. This method is used when a user changes
the order of the table/tabs down the left side. When they click "move up" or "move down", this method is called
where it passes in the 2 tableid's that are being swapped and moving from one position to the other. 

updateTableAPI = [tableid: integer, name: string,icon: string]: This is used when a user edits an existing tab/table 
and wants to update /change the name or the icon. 

*/
/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

//11-5-2021 Added role as param. This is used to determine if user has access to this table/tab.
//by default, role=0(General user)

export const getTablesAPI = async (blockid, role = 0) => {
  const payload = { blockid, role, dbfunction: 'getTablesAPI' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  const response = dataresponse[0].root;

  const tables = [];
  response.forEach((el) =>
    tables.push({
      key: el.id,
      value: el.id,
      text: el.name,
      gbicon: el.icon,
      recordname: el.recordname,
      isSystemTable: el.isSystemTable,
    }),
  );

  return tables;
};

export const getTableNames = async (tableids) => {
  const payload = { tableids, dbfunction: 'getTableNames' };
  const result = await cloudrun.post('/executeQueryAPI', { payload });
  return result;
};

export const copyTable = async (
  blockid,
  tableid,
  tabName,
  includeData,
  includeBookmarks,
  includeForms,
) => {
  const payload = { tableid, blockid, tabName, includeData, includeBookmarks, includeForms };
  const result = await cloudrun.post('/copyTable', { payload });
  return result;
};

export const csvLoader = async (tableid, tableinfo, data) => {
  const payload = { tableid, tableinfo, data };
  const result = await cloudrun.post('/csvLoaderAPI', { payload });
  return result;
};

export const copyField = async (blockid, tableid, metadata, sourceTableName) => {
  const payload = { blockid, tableid, metadata, sourceTableName };
  const result = await cloudrun.post('/copyField', { payload });
  return result;
};

export const checkTableNameUnique = async (blockid, tableid, name) => {
  const payload = { blockid, tableid, name, dbfunction: 'checkTableNameUnique' };
  const result = await cloudrun.post('/executeQueryAPI', { payload });
  return result;
};

export const deleteTableData = async (schema, tablename) => {
  const payload = { schema, tablename, dbfunction: 'deleteTableData' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
};

export const getFieldsAPI = async (tableid) => {
  const payload = { tableid, dbfunction: 'getTableAPI' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });

  const response = dataresponse[0];

  const fields = [];
  response.tableinfo.columns.forEach((el) => {
    const item = {
      key: el.data,
      text: el.header,
      value: el.data,
      lookup: el.lookup,
      jointype: el.jointype,
      filters: el.filters,
      source: el.source,
      uitype: el.uitype,
      displayfield: el.displayfield,
      dateFormat: el.dateFormat,
      UTC: el.UTC,
      includeTime: el.includeTime,
      numericFormat: el.numericFormat,
      specialfunction: el.specialfunction,
      maskType: el.maskType,
      maskCharacters: el.maskCharacters,
      uniformLength: el.uniformLength,
      fieldType: el.fieldType,
      usedFields: el.usedFields,
      useButtonDisplay: el.useButtonDisplay,
      buttonLabel: el.buttonLabel,
      isMobile: el.isMobile,
    };
    fields.push(item);
  });

  return { fields, tableinfo: response.tableinfo };
};

export const getTableAPI = async (tableid) => {
  const payload = { tableid, dbfunction: 'getTableAPI' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  if (dataresponse.length > 0) {
    return dataresponse[0];
  }
  return null;
};

export const getTableMessages = async (tableid, id) => {
  const payload = { tableid, id, dbfunction: 'getTableMessages' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  const response = dataresponse[0];
  // console.log(response)

  if (response.messages !== null) {
    return response.messages;
  }
  return [];
};

export const updateTableMessages = async (id, tableid, messages) => {
  const payload = { id, tableid, messages, dbfunction: 'updateTableMessages' };
  await cloudrun.post('/executeQueryAPI', { payload });
  return 'success';
};

// 9-28-2020 added method to return user-customized tableinfo with their own
// defined column order and column widths.

// 11-24-2020 adding bookmarkid as optional parameter to load a bookmark versus user data.
// 9-13-23 adding updateUserSettings =false. IF a bookmark is specified, the logic was incorrectly
//updating the users settings to the bookmarksettings , regardsless of where we call this api. So,
//in all cases, except when clicking on bookmark, it should be false, othersie true.
export const getUserTable = async (
  tableid,
  bookmarkid = 0,
  role,
  blockRoles,
  updateUserSettings = false,
) => {
  const payload = { tableid, bookmarkid, role, blockRoles, updateUserSettings };
  const dataresponse = await cloudrun.post('/getUserTable', { payload });
  const response = dataresponse[0];
  if (response !== undefined) {
    return response;
  }
  return null;
};

export const hideColumn = async (tableid, field, hideColumn) => {
  const payload = { tableid, field, hideColumn, dbfunction: 'hideColumn' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return 'success';
};

export const updateUserTableColumnSettings = async (tableid, columnsettings) => {
  const payload = { tableid, columnsettings, dbfunction: 'updateUserTableColumnSettings' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return 'success';
};

export const updateTableInfoAPI = async (tableid, tableinfo, columnConfigData) => {
  /*  10-31-23 I'm adding logic to check if the header is changed on a field. Because formula's reference
    header names, we want to keep them in sync, so that if they change a header that is used in a formula, the
    user could update the formula and see the updated header name and save it without issue. Just as important,
    When copying a block, it uses the header names to create the new formula view. If these are out of sync, it breaks.
    If header name has changed and there are formula fields, we will replace old token header {<old header>} with {<new header>}
    */

  if (columnConfigData !== undefined) {
    //columnConfigData will be undefined when updating relatinoal table, as we also call tou update matching table,
    //here we don't want to make any changes in regards to the headers.
    const idx = tableinfo.columns.findIndex((el) => el.data === columnConfigData.data);
    if (idx !== -1) {
      if (tableinfo.columns[idx].header !== columnConfigData.header) {
        tableinfo.columns
          .filter((col) => col.uitype === 16)
          .map((itm) => {
            itm.formula = itm.formula.replaceAll(
              `{${columnConfigData.header}}`,
              `{${tableinfo.columns[idx].header}}`,
            );
          });
      }
    }
  }

  const payload = { tableid, tableinfo, columnConfigData, dbfunction: 'updateTableInfoAPI' };
  const dataresponse = await cloudrun.post('/updateTableInfo', { payload });
  return dataresponse;
};

export const UpdateTableColumnOrderAPI = async (tableid, columnarray) => {
  // console.log(columnarray)
  const payload = { tableid, columnarray };
  await cloudrun.post('/updateUserTable', { payload });
};

export const removeFieldByFieldChange = async (tableid, field) => {
  const payload = { tableid, field };
  await cloudrun.post('/removeFieldByFieldChange', { payload });
};

export const UpdateTableColumnWidthAPI = async (tableid, columnName, newWidth) => {
  const payload = { tableid, columnName, newWidth };
  await cloudrun.post('/updateUserTable', { payload });
  return null;
};

export const updateColumnHeaderAPI = async (tableid, columnName, header, uitype) => {
  const { tableinfo } = await getTableAPI(tableid);
  const rowindex = tableinfo.columns.findIndex((el) => el.data === columnName);
  if (rowindex !== -1) {
    tableinfo.columns[rowindex].header = header;
    tableinfo.columns[rowindex].uitype = uitype;
    const response = await updateTableInfoAPI(tableid, tableinfo);
    return response;
  }
  return null;
};

export const updateTableOrderAPI = async ({ tbl1, tbl2, to, from }) => {
  const payload = { tbl1, tbl2, to, from, dbfunction: 'updateTableOrderAPI' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });

  return 'success';
};

export const updateUserTabSettings = async (tableid, tabsettings) => {
  const payload = { tableid, tabsettings, dbfunction: 'updateUserTabSettings' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return 'success';
};

export const getUserTabSettings = async (tableid) => {
  const payload = { tableid, dbfunction: 'getUserTabSettings' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const updateTableAPI = async (tableid, name, icon, recordname, viewRole = 0, isSubTable) => {
  const payload = {
    tableid,
    name,
    icon,
    recordname,
    viewRole,
    isSubTable,
    dbfunction: 'updateTableAPI',
  };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });

  return 'success';
};

export const removeColumn = async (tableid, columnName) => {
  const { tableinfo } = await getTableAPI(tableid);
  const newtableinfo = tableinfo;
  newtableinfo.columns = tableinfo.columns.filter((el) => el.data !== columnName);
  const response = await updateTableInfoAPI(tableid, newtableinfo);
  return response;
};

export const replaceFieldsInTableUserColumnSettings = async (tableid, field, newfield) => {
  const payload = {
    tableid,
    field,
    newfield,
    dbfunction: 'replaceFieldsInTableUserColumnSettings',
  };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const checkFieldIsReferenced = async (field, tableid) => {
  const payload = {
    table: `tbl_${tableid}`,
    tableid,
    field,
    includeFormula: true,
    dbfunction: 'checkFieldIsReferenced',
  };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const checkIfNestedField = async (field, tableid) => {
  const payload = { table: `tbl_${tableid}`,tableid, field, dbfunction: 'checkIfNestedField' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  // const dataresponse = await cloudrun.post('/canFieldBeDeleted', { payload });
  return dataresponse;
};

export const canFieldBeDeleted = async (field, tableid,blocks) => {
  const payload = { table: `tbl_${tableid}`,tableid, field, dbfunction: 'checkIfNestedField' };
  // const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  const dataresponse = await cloudrun.post('/canFieldBeDeleted', { payload });  
  if(dataresponse.length>0) {
    dataresponse.map(itm=>{
      const idx = blocks.findIndex(b=>b.blockid===itm.blockid)
      if(idx !==-1) {
        itm.blockName=blocks[idx].name
      } else {
        itm.blockName=='Restricted block'
      }
    })
  }

  console.log(dataresponse)
  return dataresponse;
};


export const checkIfNestedTable = async (tableid) => {
  const payload = { table: `tbl_${tableid}`, dbfunction: 'checkIfNestedTable' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const GetRelationTableCount = async (tableid, jointable, schema, id, field, returnfield) => {
  const payload = {
    tableid,
    jointable,
    schema,
    id,
    field,
    returnfield,
    dbfunction: 'getRelationTableCount',
  };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse[0].count;
};

// 9-29-22 When nested fields are changed on a nested relationa field, we call this method to remove
//any webform field conditios which may reference them.
export const removeNestedConditions = async (tableid, field) => {
  const payload = { tableid, field };
  const dataresponse = await cloudrun.post('/removeNestedConditions', { payload });
  return dataresponse;
};

export const GetRelatedTablesInfo = async (schema, id, dataItem, displayMode, role, blocks) => {
  const tempArray = [];
  // const table = await getUserTable(tableid, 0,role,userInfo.blocks);

  return Promise.all(
    dataItem.tableinfo.columns
      .filter((el) => el.uitype === 1 && el.lookup === 'multiple' && el.removeColumn === undefined) // 8-17-2022 added removeColumn==undefind to remove any relational columns user shouldn't have access to. This is based on their role being less than "myRecords" defined role for tab.
      .map(async (itm) => {
        // const tblinfo = await getTableAPI(itm.source.lookuptableid);
        const tblinfo = await getUserTable(
          itm.source.lookuptableid,
          itm.bookmarkName ?? 0,
          role,
          blocks,
          false,
        );
        const tblCount = await GetRelationTableCount(
          dataItem.tableid,
          itm.source.jointable,
          schema,
          id,
          itm.data,
          itm.source.returnfield,
        );
        const filters = [
          {
            field: 'id',
            header: 'id',
            type: 'relation', // indicates if id is relational lookup filter or "table" to search by id on table.
            displayfield: 'id',
            sourcefield: itm.data,
            operator: displayMode === 'addition' ? 'not' : 'and',
            value: id,
            uitype: 100,
            selectmode: itm.lookup,
            renderlookup: itm.source.lookup,
            lookupfield: `${itm.source.returnfield}_join`,
            originalTableId: dataItem.tableid,
          },
        ];

        const nfields = itm.source.nestedFields;
        const TableIconInfo = {
          name: itm.header,
          field: itm.data,
          returnfield: itm.source.returnfield,
          blockid: itm.source.blockid,
          tableid: itm.source.lookuptableid,
          jointable: itm.source.jointable,
          lookupfield: itm.source.lookupfield,
          lookup: itm.lookup,
          icon: tblinfo.icon,
          count: tblCount,
          tableinfo: tblinfo.tableinfo,
          filters: filters,
          useSecurity: itm.useSecurity,
          nestedFields: nfields,
          tooltip: itm.callout,
          isSubTable: itm.isSubTable,
        };
        return TableIconInfo;
      }),
  );
};

export const getTableHistory = async (tableid, id) => {
  const payload = { tableid, id };
  const dataresponse = await cloudrun.post('/getTableHistory', { payload });
  return dataresponse;
};

//8-17-2022 also will be used by form dropdown and filter dropdown relational fields...as we need to support
// the bookmarks and userSecurity settings here as well.

//10-30-23 We use this method both for newRecord and on Web Forms to retrieve available options, but also
//use it for filtering on existing values. Ive added new param, includeAll which will determine if we
//should restrict the options to available options. Speficially this will affect when the lookup of the relational
//table is set to single, This is defined in the column metadata at source->Lookup. In that case, we need to
//only retreive available items. So includeAll=false means to enforce this restriction. when calling from
//the filter component, I will set this to True, as we need to see all used options.

export const getRelationalTableData = async (
  tableid,
  colInfo,
  role,
  filterTerm,
  limit = 10000,
  ids = null,
  format = 'raw',
  useBookmarks = true,
  includeAll = false,
) => {
  const payload = {
    colInfo: colInfo,
    limit: limit,
    callingComponent: 'getRelational',
    filterTerm: filterTerm,
    role: role,
    sortfield: 'CustomID',
    sortorder: 'asc',
    ids,
    sourceTableId: tableid,
    useBookmarks,
    includeAll,
  };
  const tabledata = await cloudrun.post('/getRelationalTableData', { payload });

  if (format === 'raw') {
    return tabledata;
  } else if (format === 'dropdown') {
    const listItems = [];
    tabledata.map((el) => {
      listItems.push({ key: el.id, value: el.id, text: el.CustomID });
    });
    return listItems;
  } else if (format === 'list') {
    const listItems = [];
    tabledata.map((el) => {
      listItems.push({ optionid: el.id, option: el.CustomID });
    });
    return listItems;
  }
};

export const tableStream = async (tableid) => {
  const payload = { tableid };
  const dataresponse = await cloudrun.post('/tableStream', { payload });
  return dataresponse;
};

export const getTablesWithEmailFields = async () => {
  const payload = { dbfunction: 'getTablesWithEmailFields' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const TestFunctionForCheckin = () => {
  console.log('Adding this method to see if git checkin is working after I forced .');
};

export const getTablesWithEmailSendFields = async () => {
  const payload = { dbfunction: 'getTablesWithEmailSendFields' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const getTablesWithMessaging = async () => {
  const payload = { dbfunction: 'getTablesWithMessaging' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const getTablesWithAttachments = async () => {
  const payload = { dbfunction: 'getTablesWithAttachments' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};

export const getFieldAttachments = async (tableid, field) => {
  const payload = { tableid, field, dbfunction: 'getFieldAttachments' };
  const dataresponse = await cloudrun.post('/executeQueryAPI', { payload });
  return dataresponse;
};
