/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/core'
import {Popup} from 'semantic-ui-react';
import { Link } from '@reach/router';
import ExpandCollapseButton from '../../../../components/ExpandCollapseButtons/ExpandCollapseButton';

import GBLogo from './GBViewAll.svg';
import GBSquare from './GSquare.svg';

const BottomBar = ({ isExpanded, toggleExpand, color }) => {
 
  return (
    <div style={{marginRight:'10px'}}>
      {isExpanded ? (
        <Popup
        flowing
        hoverable
        basic
        on="hover"
        mouseEnterDelay={1000}
        position="right center"
        trigger={
        <div style={{display:'flex', flexDirection:'row',justifyContent:'flex-end',marginRight:'10px'}}>
          <ExpandCollapseButton toggleExpand={toggleExpand} rotate={180} />
          </div>}>
            Collapse
          </Popup>
       
      ) : (
        <Popup
            flowing
            hoverable
            basic
            on="hover"
            mouseEnterDelay={1000}
            position="right center"
            trigger={
        <div style={{display:'flex', flexDirection:'row',justifyContent:'center',marginLeft:'10px'}}> 
          <ExpandCollapseButton toggleExpand={toggleExpand} rotate={0} />
        </div>}>
         Expand
        </Popup>
      )}
 <Popup
            flowing
            hoverable
            basic
            on="hover"
            mouseEnterDelay={1000}
            position="right center"
            trigger={
      <div
        css={{
          margin: '5px',
          backgroundColor: color,
          padding: isExpanded ? '6px' :'6px',
          borderRadius: '10px',
          overflow: 'hidden',
          '&:hover': {
              boxShadow: '0px 3px 6px #757575',
              transition: 'all 1s ease',
            },
          }}
      >
        <Link to="/">
          <img src={isExpanded ? GBLogo : GBSquare}  height='30px' />
        </Link>
      </div>}>
        View all my GraceBlocks
      </Popup>
      
    </div>
  );
};

export default BottomBar;
