/* eslint-disable */
import React, { useEffect } from 'react';

const RelationalTag =({color,value}) => {

  // print(value)
    return (
    
     value !==null && value.map(el=> el.data && (
        <div
        style={{
          display:'inline-flex',
          margin: '3px',
          whiteSpace:'noWrap',
          paddingLeft: '5px',
          paddingRight: '5px',
          // alignSelf: 'center',
          borderRadius: '7px',
          backgroundColor: `${color}0D`, //`${color}B3`
          // color: 'white',
        }}
        key={`${el.id}-${el.data}`}
      >
        {el.data}
      </div>
     ))
    )

}


export default RelationalTag