/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import Icon from '@mdi/react';
import { Input,Popup } from 'semantic-ui-react';
import { mdiPlus, mdiInformationOutline,mdiClose } from '@mdi/js';
import TextButton from '../../../../../../components/TextButton/TextButton';
import GBButton from '../../../../../../components/GBButton/GBButton';

const TrackingPixelControl = ({ links, updateTrackingPixel, width, color, attribute, label,tooltip }) => {
  const [addItem, setAddItem] = useState(false);
  const [trackingLink, setTrackingLink] = useState('');

  //   useEffect(() => {
  //       console.log(links)
  //   })

  const saveTrackingLink = (mode) => {
    updateTrackingPixel(attribute, trackingLink, mode);
    setTrackingLink('');
    setAddItem(false);
  };

  const removeTrackingLink = (value) => {
    updateTrackingPixel(attribute, value, 'remove');
    setTrackingLink('');
  }

  return (
    <div>
      <div
        css={{
          display: 'flex',
          //   marginLeft: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: color,
          marginBottom: '5px',
          fontWeight: 'bold',
          svg: {
            color: color,
          },
        }}
      >
        <div>{label}</div>
        <div>
            <Popup content={tooltip} hoverable trigger={
            <Icon path={mdiInformationOutline} size="15px" />
            } />
        </div>
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          // marginLeft:'5px',
          flexDirection: 'row',
          alignItems: 'center',
          width: '440px',
          height: '35px',
          borderTop: `1px solid #DEDEDF`,
          borderBottom: `1px solid #DEDEDF`,
          borderRight: `1px solid #DEDEDF`,
          borderRadius: '5px',
          paddingLeft: '15px',
          color: color,
        }}
      >
        {' '}
        <div
          css={{
            marginRight: '5px',
            '&:hover': {
              svg: {
                backgroundColor: color,
                color: 'white',
                transition: '.5s ease all',
                borderRadius: '100%',
              },
            },
          }}
        >
          <Icon onClick={() => setAddItem(true)} path={mdiPlus} size="15px" />
        </div>
        <div style={{ color: 'black', display:'inline-flex', marginTop: '4px',width:'100%',overflowX:'auto' }}>
          {links === undefined ? (
            <span>Click + to add tracking pixel URL.</span>
          ) : (
            links.map((el) => (
              <div
                css={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                backgroundColor: color,
                borderRadius: '10px',
                marginRight:'5px',
                color: 'white',
                paddingLeft: '5px',
                paddingRight: '5px',
                paddingTop: '3px',
                paddingBottom: '3px',
                }}
              >
                <div style={{marginRight:'5px', whiteSpace:'nowrap'}}>{el}</div>
                <div css={{
                    marginBottom:'3px',
                    '&:hover': {
                        svg: {
                            backgroundColor:'white',
                            color:color,
                            borderRadius:'100%',
                            transition:'.5s ease all'
                        }
                    }
                }}><Icon onClick={()=>removeTrackingLink(el)} path={mdiClose} size="15" /></div>
              </div>
            ))
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 100,
            display: addItem ? 'flex' : 'none',
            backgroundColor: 'white',
            flexDirection: 'column',
            alignItems: 'center',
            top: 10,
            left: 10,
            width: '300px',
            height: '150px',
            border: '1px solid #DEDEDF',
            borderRadius: '10px',
            boxShadow: '0px 3px 6px #757575',
          }}
        >
          <div style={{ marginTop: '20px', color: 'black' }}>
            URL <Input value={trackingLink} onChange={(e, data) => setTrackingLink(data.value)} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '20px',
              color: 'black',
            }}
          >
            <TextButton text="Cancel" hoverColor="#75757580" Action={setAddItem} value={false} textColor="black" />
            <div style={{ width: '20px' }} />
            <GBButton width="75px" text="Save" color={color} Action={saveTrackingLink} ActionValue='add' />
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px',
            width: '7px',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: color,
          }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default TrackingPixelControl;
