/* eslint-disable */
import React, { useEffect,useState} from 'react';
import { Buffer } from 'buffer';
import { parse } from 'query-string';
import { useLocation, navigate } from '@reach/router';
import { AccountContext } from '../../providers/AccountProvider';
import firebase from '../../clients/firebase';
import PulseLoader from "react-spinners/PulseLoader";
import { getFormByToken,getZoneServer,getFormRole } from '../../api/forms';

import FormView from '../Block/components/Forms/FormView';
import FormLogin from '../Block/components/Forms/FormLogin';
import SocialShare from './SocialShare';


const Form = (props) => {
  const location = useLocation();
  const searchParams = parse(location.search); 

  const [height,setHeight]=useState('100%')
  const [form,setForm] =useState(null);
  const [fInfo,setFinfo] =useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin]=useState(false);
  const [isLoggedIn, setIsLoggedIn] =useState(false);
  const [addedby,setAddedby] =useState(null);
  const [role, setRole] =useState(null);
  const [message,setMessage]=useState('');
  const [errorMessage,setErrorMessage] = useState('This form is no longer valid.')
  const [error, setError] = useState();


  useEffect(() => {
    verify();
  }, []);

  const verify = async () => {
    setIsLoading(true);

    const buff = Buffer.from(searchParams.x, 'base64');
    const fInfo = buff.toString('ascii');
    const elements=fInfo.split('|');
    const formZoneId= elements[0];

    let formInstance=null;

    firebase.auth().onAuthStateChanged(async (user) => {
      if(user) {
        const idTokenResult = await user.getIdTokenResult(true);
  
        formInstance = await getFormByToken(fInfo,idTokenResult.claims.userid,formZoneId,searchParams['id'],searchParams['aid']);  
       
        if(formInstance.form !==null) {
        setForm(formInstance.form)
        setIsLoggedIn(true);

        if(!formInstance.error) {
          setAddedby(idTokenResult.claims.userid)
          setRole(formInstance.role);
          setShowLogin(false)
        } else if(formInstance.error && formInstance.form.settings.login !=='mandatory') {
          //In this use-case, they don't have access to the form, but it's not mandatory, so we
          //automatically send them to the form as anonymous.
          setRole(3)
          setAddedby(formInstance.userId);
          setShowLogin(false);
        } else {
          setMessage(formInstance.errorMessage);
          setAddedby(formInstance.userId)
          setShowLogin(true);
        }
      } else {
        setError(true);
        setErrorMessage(formInstance.errorMessage);
      }
        
      } else {
        formInstance = await getFormByToken(fInfo,undefined,undefined,searchParams['id'],searchParams['aid']);  
        if(formInstance.form !==null) {
        setForm(formInstance.form)
        setAddedby(formInstance.userId)
        setRole(3)
        if(formInstance.form.settings.login==='optional' || formInstance.form.settings.login==='mandatory' ){
          setShowLogin(true);
        }
      } else {
        setError(true)
        setErrorMessage(formInstance.errorMessage);
      }
    }
    })

    setFinfo(fInfo);
    setIsLoading(false);

  };


  const inputFocusHandler =(e) =>{
   e.preventDefault()
  }

  const inputBlurHandler =() => {
    setHeight('100%')
  }

  const noLoginUsed =() =>{
    setShowLogin(false);
  }

  return (
  <>
    {searchParams.social && form !==null && !isLoading ? (
      <SocialShare form={form} fInfo={fInfo} token={searchParams.x} searchParams={searchParams}/>
    ):null}
    {form !=null && !isLoading && !showLogin && addedby!==null && role !==null && searchParams.social ==undefined ? (
        <FormView currentForm={form} addedby={addedby} role={role} fInfo={fInfo}  searchParams={searchParams} url={window.location.href}/>
    ):null}
    {form !=null && !isLoading && showLogin && searchParams.social ==undefined ? (
        <FormLogin form={form} noLoginUsed={noLoginUsed} message={message} isLoggedIn={isLoggedIn} />
    ):null}
    {isLoading ? (
      <div style={{height:window.innerHeight,display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'center'}}><PulseLoader /></div>
    ):null}
    {form===null && error ? (
      <div style={{ 
        backgroundColor: '#f8d7da', // Light red background
        color: '#721c24',             // Dark red text color
        padding: '20px',
        borderRadius: '5px',         // Rounded corners
        border: '1px solid #f5c6cb',  // Light red border
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'           // Center the text
    }}>
      {errorMessage}
    </div>
    ):null}
   </>
  );
};

export default Form;
