/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef,useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { navigate } from '@reach/router';
import { AccountContext } from '../../providers/AccountProvider';
import { mdiClose, mdiPlus } from '@mdi/js';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import Spinner from '../../components/Spinner/Spinner';
import { getOwnerZones, updateZoneStatus, getZoneOwnersCount } from '../../api/zone';
import { updateAccountStatus } from '../../api/auth';
import { confirmAlert } from 'react-confirm-alert';
import { DASHBOARD } from '../../constants/routes';
import Global from '../../clients/global'

const ZoneInformation = ({ color }) => {
  const { userInfo,switchToZone } = useContext(AccountContext);
  const [isActive, setIsActive] = useState(true);
  const [zones, setZones] = useState([]);
  const [isSingleOwner, setIsSingleOwner] = useState(false);
  const [accountStatus, setAccountStatus] = useState(userInfo.status);
  const [isDone,setIsDone] = useState(true)
  const [isLoading,setIsLoading] = useState(false)

  const divRef = useRef();
  const divRef2 = useRef();

  useEffect(() => {
    if (zones.length === 0) {
      const getZones = async () => {
        const zones = await getOwnerZones(userInfo.id);
        setZones(zones);
        // console.log(zones);

        // Determine if this user is the single "owner" of any of his zones. If true,
        // they cannot deactivate their account.
        const currentZonesArray = userInfo.zones.map((itm) => itm.id).join();

        if (currentZonesArray !== '') {
          const zoneCounts = await getZoneOwnersCount(currentZonesArray);

          if (zoneCounts !== null) {
            const findZoneIndex = zoneCounts.findIndex((itm) => itm.count === 1);
            setIsSingleOwner(findZoneIndex !== -1);
          }
        }
      };
      getZones();
    }
  }, []);

  // useEffect(() => {
  //   console.log(userInfo)
  // }),[];


  const SwitchDefaultZone = () =>{
    const testzones =zones.filter(el=>el.token !==null).sort((a, b) => (parseInt(a.pos) > parseInt(b.pos) ? 1 : -1));
  
    if (testzones.length>0) {
      const newZoneid = testzones[0].zoneid
      switchToZone(newZoneid).catch(() => {
        navigate(`${DASHBOARD.ZONE_PREFIX}${Global.zoneid}`, { replace: true });
      });
        navigate(`${DASHBOARD.ZONE_PREFIX}${newZoneid}`, { replace: true });
    }
  }


  const AnimatedLoadingDelay = () => {
   setIsDone(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1300);
  };

  const showZones = () => {
    let filterZones = [];

    if (zones && zones.length > 0) {
      if (isActive) filterZones = zones.filter((el) => el.token !== null);
      if (!isActive) filterZones = zones.filter((el) => el.token === null);

      return filterZones.map((itm) => (
        <div
          key={itm.zoneid}
          style={{
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '650px',
            justifyContent: 'space-between',
          }}
        >
          <div>{itm.name}</div>

          <GBButton
            text={isActive ? 'Deactivate' : 'Activate'}
            Action={isActive ? confirmDeactivate : reactivateConfirmation}
            ActionValue={itm.zoneid}
            textColor="#757575"
            borderRadius="10px"
            hoverBackgroundColor="#F1EFEF"
            textHoverColor="#757575"
            hoverColor="#F1EFEF"
            icon={isActive ? mdiClose : mdiPlus}
            iconColor={isActive ? '#F6685E' : '#9CCC65'}
            iconHoverBackgroundColor={isActive ? '#F6685E' : '#9CCC65'}
            iconHoverColor="white"
            iconPosition="left"
            hoverBorder="1px solid #757575"
          />
        </div>
      ));
    } else {
      return (
        <div style={{ marginBottom: '10px' }}>
          You do not currently act as an owner for any Zones.
        </div>
      );
    }
  };

  const deactivateZone = async (zoneid) => {
    if (divRef.current.value === 'deactivate') {
     
      setIsLoading(true)
      const dbresponse = await updateZoneStatus(zoneid, false, '');
      const tempZones = [...zones];
      const index = zones.findIndex((itm) => itm.zoneid === zoneid);
      if (index !== -1) {
        tempZones[index].token = null;
        setZones(tempZones);
      }
      // remove from current userInfo object so update is reflected in UI.
      let tempLocalZones = [...userInfo.zones];
      const idx = tempLocalZones.findIndex((itm) => itm.id === zoneid);
      if (idx !== -1) {
        tempLocalZones[idx].token = null;
      }
      userInfo.zones = tempLocalZones;
      AnimatedLoadingDelay(false)
      SwitchDefaultZone()
    }
  };

  const activateZone = async (zoneid) => {
    if (divRef.current.value === 'reactivate') {
    setIsLoading(true)
    const token = uuidv4();
    const dbresponse = await updateZoneStatus(zoneid, true, token);
    const tempZones = [...zones];
    const index = zones.findIndex(itm => itm.zoneid === zoneid);
    if (index !== -1) {
      tempZones[index].token = token;
      tempZones[index].plan='Free';
      setZones(tempZones);
    }

    // add zone back into userInfo zones so it's reflected in UI
    const tmpzones = [...userInfo.zones];
    const idx = tmpzones.findIndex((itm) => itm.id === zoneid);
    if (idx !== -1) {
      tmpzones[idx].token = token;
    }
    userInfo.zones = tmpzones;
    AnimatedLoadingDelay(false)
  }
  };

  const updateAccount = async (status) => {
    if ((status === 'Inactive' && divRef2.current.value === 'deactivate') || status==='Active') {
      await updateAccountStatus(userInfo.id, status);
      setAccountStatus(status);
      userInfo.status = status;
    } 
  };

  const confirmDeactivate = (zoneid) => {
    const currentZone = zones.filter(itm=>itm.zoneid===zoneid)[0];
    const zonename=currentZone.name;
    const plan = currentZone.plan;

    const message=<div>
       You have selected to deactivate the Zone: {zonename}.<p/><p/>
       Are you sure you want to deactivate this Zone? GraceBlocks recommends you download any desired records or information
        from the Zone prior to continuing with deactivation. Once you deactivate the Zone, all users, including you, will lose access to the Zone.
        {plan==='Free' ? (
          <><p/><p>Zone Owners will be able to reactivate the Zone from their user profile for up to 3 months, but once the zone remains inactive for 3 consecutive months, it will be fully abolished.</p></>
        ) : (
          <><p/><p>For the next 6 months, Zone owners will be able to reactivate the Zone from their User profile. This Zone is currently on the {plan} plan. Upon deactivation, Zone credits equal to the cost of any unused portion of this plan will be set aside for possible future use with this Zone. The Zone will be available for reactivation for up to 6 months, but once the Zone remains inactive for 6 consecutive months it will be fully abolished including any remaining Zone credits.</p></>
        )}
    </div>

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Deactivate Zone"
            action={deactivateZone}
            actionValue={zoneid}
            buttonLabel="OK"
            message={message}
            width="500px"
            divRef={divRef}
            showInput={true}
            confirmAction="deactivate"
            confirmWord="deactivate"
            height={plan==='Free' ? '450px' : '630px'}
            onClose={onClose}
          />
        );
      },
    });
  };

  const DeactivateAccountConfirm = () => {

    let message=''
    if(!isSingleOwner){
      message=<div>Are you sure you want to deactivate your account? This will inactivate access to all
      Zones and Blocks where you are currently an authorized active user. Your account will
      still exist, and if desired, your access can be reactivated by Zone owners at a future
      time. If you wish to fully abolish any record of your account send an email to
      privacy@graceblocks.com and we will process your request.</div>
    } else {
      message=<div style={{ marginBottom: '20px' }}>
      As a Zone owner, the Zone(s) you own must first have a at least one other Zone Owner
      designated or the Zone(s) must be deactivated before your account can be deactivated.
      Please make the necessary updates to proceed.{' '}
    </div>
    }
    
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title= {isSingleOwner ? 'Update Zones first' : 'Deactivate account'}
            action={isSingleOwner ? onClose : updateAccount}
            actionValue={"Inactive"}
            showCancelButton={!isSingleOwner}
            buttonLabel="OK"
            message={message}
            width="500px"
            divRef={divRef}
            showInput={!isSingleOwner}
            confirmAction="deactivate"
            confirmWord="deactivate"
            height={isSingleOwner ? '300px' : '430px'}
            onClose={onClose}
          />
        );
      },
    });
  };

  const reactivateConfirmation = (zoneid) => {

    const currentZone = zones.filter(itm=>itm.zoneid===zoneid)[0];
    console.log(currentZone)
    const zonename=currentZone.name;
    const plan = currentZone.plan;

    let message=<div>
      You have selected to activate the Zone: {zonename}
      <p/> <p/>
      Are you sure you want to reactivate this Zone? This Zone was previously on the {plan} plan for 7 user license(s).  Any unused time that was remaining on this plan has been converted to credits, available for use with future purchases related to this Zone. These credits will be visible in Zone settings, on the bottom right of the screen. Any available credits will apply automatically to future purchases related to this Zone.
      <p/> <p/>
      Upon reactivation, this Zone will begin on the free plan and you (as well as any other Zone owner) will be able to choose to upgrade the desired plan. Depending on the Blocks in this Zone and how they align to the GraceBlocks plans, users may not be able to interact with the Blocks in the Zone until it is upgraded to a paid plan.
    </div>

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title='Reactivate Zone'
            action={activateZone}
            actionValue={zoneid}
            showCancelButton={true}
            buttonLabel="OK"
            message={message}
            icon="check"
            width="500px"
            color="#9CCC65"
            divRef={divRef}
            showInput={true}
            confirmAction="reactivatation"
            confirmWord="reactivate"
            height='500px'
            onClose={onClose}
          />
        );
      },
    });
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginLeft: '10px',
          height: '40px',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#F1EFEF',
          // border: '1px solid #757575',
          borderRadius: '40px',
        }}
      >
        <div style={{ fontWeight: 'bold', marginLeft: '20px' }}>Zone owner information</div>
      </div>
      {!isLoading ? (<>
      <div style={{ marginLeft: '40px',borderBottom:'1px solid #eee', display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
        <div
          onClick={() => setIsActive(true)}
          css={{
            cursor: 'pointer',
            fontSize: '15px',
            marginRight: '20px',
            color: isActive === true ? color : '#757575',
            borderBottom: isActive === true ? `3px solid ${color}` : null,
            '&:hover' : {
              color: color,
            }
          }}
        >
          Active
        </div>
        <div
          onClick={() => setIsActive(false)}
          css={{
            cursor: 'pointer',
            fontSize: '15px',
            color: isActive === false ? color : '#757575',
            borderBottom: isActive === false ? `3px solid ${color}` : null,
            '&:hover' : {
              color: color,
            }
          }}
        >
          Inactive
        </div>
      </div>
      
      <div style={{ marginLeft: '40px', marginTop: '20px' }}>{showZones()}</div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          marginLeft: '10px',
          height: '40px',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop:'20px',
          backgroundColor: '#F1EFEF',
          // border: '1px solid #757575',
          borderRadius: '40px',
        }}
      >
        <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
          Account status: {accountStatus.toUpperCase()}
        </div>
      
      </div>
      <div style={{ marginBottom: '40px', marginTop: '20px',marginLeft:'20px' }}>
        <GBButton
          text={accountStatus === 'Active' ? 'Deactivate my account' : 'Activate my account'}
          Action={accountStatus === 'Active' ? DeactivateAccountConfirm : updateAccount}
          ActionValue={accountStatus === 'Inactive' ? 'Active' : null}
          width="200px"
          color="white"
          hoverBackgroundColor="#F1EFEF"
          textColor="#757575"
          textHoverColor="#757575"
          borderRadius="10px"
          hoverColor="#757575"
          icon={accountStatus === 'Active' ? mdiClose : mdiPlus}
          iconColor={accountStatus === 'Active' ? '#F6685E' : '#9CCC65'}
          iconHoverBackgroundColor={accountStatus === 'Active' ? '#F6685E' : '#9CCC65'}
          iconHoverColor="white"
          iconPosition="left"
          hoverBorder="1px solid #757575"
        />
      </div>
      </>) : <Spinner isLoading={isLoading} isDone={isDone} color={color} />}

    </div>
  );
};

export default ZoneInformation;
