const MESSAGES = {
  REQUIRED: "This field can't be empty.",
  EMAIL: 'Enter a valid email address.',
  PHONE_NUMBER: 'Enter a valid phone number.',
  MAX_LENGTH: 'Ensure this value has at most {correctLength} character(s) (it has {length}).',
  MIN_LENGTH: 'Ensure this value has at least {correctLength} character(s) (it has {length}).',
  MAX_VALUE: 'Ensure this value is less than or equal to {value}.',
  MIN_VALUE: 'Ensure this value is greater than or equal to {value}.',
  PASSWORD:
    'Password must contain at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character, at least 1 numeric character and at least one special character.',
};

export default MESSAGES;
