import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { mdiPlusCircle, mdiPlus } from '@mdi/js';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

const ZoneAdd = ({ onAdd }) => {
  const ref = useRef();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (value) {
        setEditing(false);
        setValue('');
        onAdd(value);
      }
    },
    [value, setEditing, setValue, onAdd],
  );

  const handleBlur = useCallback(() => {
    setEditing(false);
    if (value) {
      setValue('');
      onAdd(value);
    }
  }, [setEditing, value, setValue, onAdd]);

  const keyDown = useCallback(
    (event) => {
      if (editing && event.keyCode === 27) {
        setEditing(false);
      }
    },
    [editing, setEditing],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  }, [keyDown]);

  useEffect(() => {
    if (editing) {
      ref.current.focus();
    }
  }, [editing]);

  return (
    <div className="zone-add">
      {editing ? (
        <form noValidate onSubmit={handleSubmit}>
          <input
            placeholder="Enter Zone Name"
            value={value}
            onBlur={handleBlur}
            onChange={(e) => setValue(e.target.value)}
            maxLength="20"
            ref={ref}
          />
        </form>
      ) : (
        <Button type="button" size="sm" variant="clear" onClick={() => setEditing(true)}>
          <Icon path={mdiPlusCircle} color="#bdbdbd" size={0.8} className="svg-active" />
          <span />
          <Icon path={mdiPlus} color="#bdbdbd" size={0.6} className="svg-default" />
          Add Zone
        </Button>
      )}
    </div>
  );
};

ZoneAdd.defaultProps = {
  onAdd: () => {},
};

ZoneAdd.propTypes = {
  onAdd: PropTypes.func,
};

export default ZoneAdd;
