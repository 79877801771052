/* eslint-disable */
import React, { useState, useEffect, Suspense } from 'react';
const Confetti = React.lazy(() => import('react-confetti')); // Lazy load Confetti
import {mdiClose} from '@mdi/js';
import IconButton from '../FilterData/IconButton';

const ConfettiMessage = ({ message, close, color }) => {
  //the "message" will be an array ['message 1', 'message 2']
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);


  useEffect(() => {
    setShowConfetti(true); // Show confetti after component mounts
    const timer = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % message.length);
    }, 5000); // Change message every 5 seconds (adjust as needed)


    const audio = new Audio('/yayy.mp3');
    audio.play();

    return () => {
      clearInterval(timer);
    };
   
  }, []);



  return (
    <div style={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Suspense fallback={<div></div>}>
        {' '}
        {/* Fallback while Confetti loads */}
        {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      </Suspense>
      
    <div className="transparent-radial-gradient" style={{position:'relative',maxWidth:'600px', padding:'100px',backgroundColor:`${color}0D`,opacity:0,  animation: 'fadeIn 2s ease-in-out 3s forwards', boxShadow:'0px 3px 6px #757575'}}>
        <div style={{position:'absolute',top:15,right:15,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
              <IconButton size="25px" color="#F6685E" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={close}  />
        </div>
      <div>
        <h1 style={{ textAlign: 'center', color: color, marginBottom: '20px' }}>
          {message[currentMessageIndex]}
        </h1>
      </div>
      </div>
      
    </div>
  );
};

export default ConfettiMessage;
