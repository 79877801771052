/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, Component } from 'react';
import { css, jsx } from '@emotion/core'
import { mdiDragVertical, mdiChevronDown,mdiDeleteOutline} from '@mdi/js';
import Icon from '@mdi/react';
import onClickOutside from 'react-onclickoutside';
import { Input } from 'semantic-ui-react';

class OptionItem extends Component {

  state= {
    mode: this.props.option.option =='' ? 'edit' : 'display',
    key: null,
    optionValue: this.props.option.option,
    showModal: false,
    isFocused: true,
  }

  // const { option,pickColor,updateItem,displaymode,enableColors,deleteOption } = this.props;
  // const [mode, setMode] = useState(option.option==="" ? 'edit' : 'display');
  // const [key, setKey] = useState(null);
  // const [optionValue, setOption] = useState(option.option);
  // const [showModal, setShowModal] = useState(false)
  // const [isFocused, setIsFocused] = useState(true);

  handleClickOutside = () => {
    const {mode,optionValue} = this.state;
    const {option,updateItem}=this.props;
    if (mode==='edit') {
      this.setState({isFocused:false, mode:'display'})
      updateItem(option.optionid,optionValue,false)
    }
  };

   onKeyPress = (e) => {
    const {updateItem,option} = this.props;
    const {optionValue}=this.state;
    if (e.key === 'Enter') {
      this.setState({isFocused:false, mode:'display'})
      // setIsFocused(false)
      updateItem(option.optionid,optionValue,true)
      // setMode('display');
    }
  };

  onBlurHandler =() => {
    const {updateItem,option} = this.props;
    const {optionValue} = this.state;
    updateItem(option.optionid,optionValue)
    // setMode('display');
    this.setState({mode:'display'})
  }

 displayItem = () => {
  const { option,enableColors,isEditable } = this.props;
  const {optionValue} = this.state;
      if(!enableColors)
      {
          return <div style={{fontColor:'black'}}>{optionValue}</div>
      }
    return (
    <div
         onMouseOver={() => this.setState({key:'yes'})}
         onMouseOut={() => this.setState({key:null})}
         style={{
           backgroundColor: option.attributes.color,
           color: option.attributes.text,
           maxWidth:'180px',
           overflowWrap:'break-word',
           paddingLeft: '10px',
           paddingRight: '10px',
           borderRadius: '10px',
         }}
         onClick={isEditable ? () => this.setState({mode: 'edit'}) : null}
       >
         {optionValue}
       </div>
    )
  };

render() {
  const { option,pickColor,updateItem,displaymode,enableColors,deleteOption,isEditable } = this.props;
  const {mode,optionValue,key} = this.state;
  return (
    <div
      style={{
        display: 'flex',
        padding: '3px',
        // color: option.attributes.color,
        flexDirection: 'row',
        verticalAlign: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <div
        onMouseOver={() => this.setState({key:'yes'})}
        onMouseOut={() => this.setState({key:null})}
        onClick={isEditable ? () => this.setState({mode:'edit'}) : null}
        style={{
          width: '300px',
          backgroundColor: key == null || mode!=='display' ? 'white' : option.attributes.color,
          display: 'flex',
          // border: '1px solid blue',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
          marginRight:'15px',
          paddingTop:'3px',
          paddingBottom:'3px',
        }}
      >
        {mode === 'display' ? (

         this.displayItem()
         
        ) : (
          <div>
            <Input
               autoFocus
               maxLength="255"
              transparent
               style={{borderBottom:'1px solid blue'}}
              value={optionValue}
              // onBlur={onBlurHandler}
              onKeyPress={this.onKeyPress}
              onChange={(e, data) => {
                this.setState({optionValue:data.value});
              }}
            />
          </div>
        )}
      </div>
      <div
        style={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {enableColors ? (
        <Icon
          onClick={(e) =>pickColor(e,option)}
          css={{ backgroundColor: option.attributes.color,  borderRadius: '20px', '&:hover': {backgroundColor:`${option.attributes.color}40`} }}
          path={mdiChevronDown}
          color={option.attributes.text}
          size="20px"
        />
        ): null}
        <Icon path={mdiDragVertical} size="20px" />
        {isEditable ? (
        <Icon 
        style={{marginLeft:'20px'}}
        onClick={() => deleteOption(option.optionid)}
        path={mdiDeleteOutline} color="#aaa" size="20px" />
        ) : null}
        
      </div>
    </div>
  );
}
};


export default onClickOutside(OptionItem);
