/* eslint-disable */
const validExtensions =
['jpg',
'jpeg',
'gif',
'png',
'bmp',
'l24',
'mp4',
'mpeg',
'ogg',
'vorbis',
'vcard',
'csv',
'rtf',
'richtext',
'calendar',
'directory',
'pdf']

export default validExtensions;