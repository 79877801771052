/* eslint-disable */
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import PNL from 'google-libphonenumber';
import { getRegions } from '../Form/Controls/PhoneNumber/phoneUtils';
import InputMask from 'react-input-mask';

class GBPhone extends Component {

    constructor(props) {
        super(props);
        this.phoneInput = React.createRef();
    }
    

  state = {
    regions: [],
    mask: '+1 999-999-9999',
  };

  handleClickOutside = () => {
    // console.log('clicked outside...')
  };

  componentDidMount() {
    const {focus=true} = this.props; //add this prop so that if phone is included on form, the form doesn't auto-scroll to this field
    if(focus){
    this.phoneInput.current.getInputDOMNode().focus();
    }

    const onlyCountryCodes = [
      // 'AU',
      // 'AT',
      // 'BD',
      // 'CA',
      // 'CL',
      // 'CZ',
      // 'DK',
      // 'EE',
      // 'FR',
      // 'GT',
      // 'IL',
      // 'HK',
      // 'HU',
      // 'LV',
      // 'LT',
      // 'MY',
      // 'MX',
      // 'NL',
      // 'PH',
      // 'PL',
      // 'PT',
      // 'PR',
      // 'SG',
      // 'SK',
      // 'ZA',
      // 'KR',
      // 'CH',
      // 'ES',
      // 'TW',
      // 'GB',
      // 'US',
    ];
    const regions = getRegions({ onlyCountryCodes, favoriteCountryCodes: ['US'] });
    this.setState({ regions });
  }

  selectRegion = (event) => {
    const id = event.target.value;

    const selectedRegion = this.state.regions.filter((itm) => itm.id === id)[0];
    // console.log(selectedRegion.iso2)
    // console.log(selectedRegion.numberMask)
    const newMask = `+${selectedRegion.iso2} ${selectedRegion.numberMask}`;
    this.setState({ mask: newMask });

    // const phoneUtil = PNL.PhoneNumberUtil.getInstance();
    // const phonenumber = phoneUtil.parse('+1 (978) 793-1633');
    // const intlnumber = phoneUtil.format(phonenumber,PNL.PhoneNumberFormat.E164)
    // console.log(intlnumber)
  };

  onKeyPressHandler = (key) => {
    // if (key === 'Enter') this.updateText();
  };

  GetOptions =() => (<>
    {this.state.regions.map(itm => (
      <option key={itm.id} value={itm.id}>{itm.region} +{itm.iso2}</option>
    ))}
    </>
    )

  render() {
      const {value,updateText,handleChange,id} = this.props;
      const {mask} = this.state;

    return (
      <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <select
          onChange={this.selectRegion}
          style={{
            outline: 'none',
            width:'80px',
            border: '1px solid #DcDCDC',
            backgroundColor:'white',
            margin: '2px',
            borderRadius: '5px',
          }}
        >
          {this.GetOptions()}
        </select>
        <InputMask
          id={id}
          type="tel"
          onKeyPress={(e) => this.onKeyPressHandler(e.key)}
          ref={this.phoneInput}
          style={{ height: '20px', outline: 'none', border: '0px' }}
          alwaysShowMask="true"
          mask={mask}
          defaultValue={value}
        //   onChange={(e) => this.setState({ value: e.target.value })}
          onChange={(e) => handleChange !==null ? handleChange(id, e.target.value) : null}
          maskChar={'_'}
        />
      </div>
    );
  }
}

export default onClickOutside(GBPhone);
