/* eslint-disable no-console */
/* eslint no-restricted-syntax: "off", no-param-reassign: "off" */

import axios from 'axios';
import Qs from 'qs';
// import { getAuthData, removeAuthData } from '@utils/auth';
// import { showApiError } from '@utils/showError';
import ApiError from './ApiError';
import { getIdToken } from './firebase';

const { REACT_APP_API_URL } = process.env;

const client = axios.create({ baseURL: REACT_APP_API_URL });

/**
 * The default url param serializer. For all those GET methods with filters.
 * @param params
 * @returns {*|string}
 */
axios.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' });

client.interceptors.request.use(
  async (config) => {
    const token = await getIdToken();
    // console.log(token)
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { response, message } = error;
    const apiError = new ApiError(response, message);

    // if (apiError.isUnauthorized()) {
    //   removeAuthData();
    //   window.location.href = '/';
    // }
    // if (!apiError.isUserError()) {
    //   showApiError(apiError);
    // }
    return Promise.reject(apiError);
  },
);

export default client;
