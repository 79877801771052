import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GoogleIcon from './google.svg';
import './styles.scss';

const GoogleButton = ({ children, className, ...props }) => {
  const classes = classNames('google-button', className);
  return (
    <button className={classes} type="button" {...props}>
      <span className="icon">
        <img src={GoogleIcon} alt="Google" />
      </span>
      {children}
    </button>
  );
};

GoogleButton.defaultProps = {
  className: null,
};

GoogleButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default GoogleButton;
