/* eslint-disable */
import React, { useEffect, useState,useRef } from 'react';
import { getOptionsAPI } from '../../../../api/options';
import { v4 as uuidv4 } from 'uuid';
import AttachmentCategory from './AttachmentCategory';
import { uploadFiles, deleteFile } from '../../../../api/images';
import { toast } from 'react-toastify';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import GBButton from '../../../../components/GBButton/GBButton';
import TextButton from '../../../../components/TextButton/TextButton';
import AttachmentExtensions from '../../../../utils/attachmentExtensions';


const AddAttachmentsContainer = ({ zoneid, tableid, field, AddItems, Apply, color,close,selectmode,switchMode,caller,allowCategories }) => {
  const [categories, setCategories] = useState([]);
  const [files, _setFiles] = useState([]);


  let filesRef = useRef(files);

  const setFiles =(data) => {
    filesRef.current=data;
    _setFiles(data);
  }

  useEffect(() => {
    // document.addEventListener('paste', function (e) {GetImage(e); }, false);
    FetchOptions();
  }, [field]);

  // useEffect(() => {
  //   document.onpaste=(e)=>{GetImage(e);e.preventDefault()}
  // },[])


  const FetchOptions = async () => {
    const options = await getOptionsAPI(field, tableid);
    if(allowCategories) {
      setCategories(options);
    } else {
      setCategories(options.slice(0,1));
    }
  };

  const GetImage = (e,category) => {
      
   
    // http://jsfiddle.net/viliusl/xq2aLj4b/5/
    // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559

    if (e.clipboardData) {
			var items = e.clipboardData.items;
			if (!items) return;
			
			//access data directly
			var is_image = false;
      const newfiles=[]
			for (var i = 0; i < items.length; i++) {
				if (items[i].type.indexOf("image") !== -1) {
					//image
					var blob = items[i].getAsFile();
          newfiles.push(blob)
				} 
			}
      if(newfiles.length>0) {
       handleFileUpload(newfiles,category)
      }
		}
  }


  const handleFileUpload = async (newFiles, category) => {
    const schema = `schema_${zoneid}`;
    let newItems = [];
    const fileUrls = await uploadFiles(schema, newFiles, 'attachments');

    fileUrls.forEach((itm) => {
      newItems.push({
        url: itm.url,
        data: category.option,
        size: itm.size,
        gcsurl: itm.gcsurl,
        filename: itm.filename,
        guidname: itm.guidname,
        optionid: category.optionid,
        attributes: category.attributes,
        uploadtype: 'desktop',
      });
    });

    const items = [...filesRef.current];
    items.push.apply(items, newItems);
    setFiles(items);
    if(selectmode==='single') {
      AddItems(items)
    }
  };

  const fetchImageUrl = (url, category,name) => {

    // 3-1-2021 If URL is Google Doc (comes from docs.google.com), allow URL, set extension=.gd to have something.
    // 1-2-2023 Changing logic such that if it's not an image or accepted document type, just sae with .lnk extension and show in iframe.
    let ext='';
    let guidname=url;

    ext = url.split('.').pop().toLowerCase();
    const findExt = AttachmentExtensions.findIndex((itm) => itm.ext === ext.toLowerCase());
    //if end of url extension is not one of our accepted formats, than save with 'web' extension
    //and we'll attempt to show in the ifreme.
    if(findExt ===-1) {
      ext='web'
      guidname=`${uuidv4()}.web`;
    }

    name=`${name}.${ext}`
    
    const urlItem = {
      url: url,
      data: category.option,
      rank: category.rank,
      size: 0,
      gcsurl: url,
      filename: name,
      guidname: guidname,
      optionid: category.optionid,
      attributes: category.attributes,
      uploadtype: 'url'
    };

    // if (findExt === -1) {
    //   toast.info(
    //     <div style={{ margin: '5px' }}>
    //       The link was not of an accepted media type.
    //     </div>,
    //     {
    //       position: toast.POSITION.BOTTOM_CENTER,
    //       autoClose: 5000,
    //       closeButton: false,
    //     },
    //   );
    //   return false;
    // }

    const items = [...files];

    if (items && items.length > 0) {
      items.push(urlItem);
      setFiles(items);
      if(selectmode==='single') {
        AddItems(items)
      }
    } else {
      setFiles([urlItem]);
      if(selectmode==='single') {
        AddItems([urlItem])
      }
    }
  };

  const deleteImage = async (file) => {

    let result = '';
    if (file.uploadtype === 'desktop') {
      const schema = `schema_${zoneid}`;
      result = await deleteFile(schema,tableid,field,file.guidname, 'attachments');
    } else {
      result = 'success'; // For URL fetches, we just need to remove them from array.
    }

    if (result === 'success') {
      const tempurls = [...files];
      const idx = tempurls.findIndex((itm) => itm.guidname === file.guidname);
      if(idx !==-1) {
      tempurls.splice(idx, 1);
      setFiles(tempurls);
      }

    }
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '550px',
        // height:'100%',
        flexDirection: 'column',
        marginTop: '10px',
        overflow:'auto'
      }}
    >

      <div>
        {categories.length > 0 ? (
          <AttachmentCategory
            categories={categories}
            uploadFiles={handleFileUpload}
            fetchImageUrl={fetchImageUrl}
            color={color}
            selectmode={selectmode}
            GetImage={GetImage}
            allowCategories={allowCategories}
          />
        ) : null}
      </div>
  


      {files.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'space-between',
            // marginTop: '10px',
            marginBottom: '10px',
            paddingLeft: '30px',
            // border:'1px solid black'
            // borderTop: '1px solid #757575',
          }}
        >
          <div style={{alignItems: 'center',marginTop:'10px',marginRight:'30px',height:'75px',overflowY:'auto',overflowX:'hidden'}}>
            <ImageThumbnails deleteAction={deleteImage} Action={null} height="50px" items={files} />{' '}
          </div>
          <div className="row" style={{ marginRight: '20px',marginTop:'30px',width:'250px' }}>
            <TextButton textColor="black" text="Cancel" hoverColor="#75757580" Action={close} />
            <div style={{width:'15px'}} />
            <GBButton
              Action={AddItems}
              ActionValue={files}
              text="Finish attaching file(s)"
              color={color}
            />
          </div>
        </div>
      ) : null}
      {files.length===0 && caller==='detail' ? (
        <center><GBButton Action={switchMode} ActionValue="review"  text="Cancel" color={color} /></center>
      ): null}
      
    </div>
  );
};

export default AddAttachmentsContainer;
