/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

const PasteData = async (tableid,fields,pastedData,incomingtableinfo, refreshRows, buildload, userid,blockid) => {

  const d = new Date();
  const timezone = d.getTimezoneOffset()/60;

  // console.log(pastedData)

  const payload={tableid,blockid,fields,pastedData,incomingtableinfo,userid,timezone,createNewOptions:false,dbmethod:'pasteData'}

  const dataresponse = await cloudrun.post('/executeMethod', {payload})
  
  // 1-19-2021  When copy/pasting we would like to bring the user to spot in the grid where they pasted the data.
  // if the pastedData col=0 has any null values, this means new records were added and we should move viewport to end of result
  // if no nulls, get the min rowID and use this to scroll to viewport.
  
  const ids = pastedData.filter(el=>el[0] !==null).map(el=>el[0])
  const findEmpty = pastedData.findIndex(itm=>itm[0]===null)

  if(dataresponse.status !=='success') {
      return dataresponse.message
  }

   if(!dataresponse.isNewColumnsAdded) {

    // if we have null values, this means we have new rows. Load all new rows greater than last previous ID.
    if(findEmpty !== -1){
      await refreshRows(ids,true)
    }

    // if we have Id's refresh those rows in the Grid
    if(ids !=='') {
      await refreshRows(ids)
    }

  } else {
    await buildload(tableid)
  }


  return "success" 
};

export default PasteData;
