/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Buffer } from 'buffer';
import FormDesigner from './FormDesigner';
import FormsManager from './FormsManager';
import FormSettings from './FormSettings';
import ImageBuilder from './ImageBuilder';
import { AccountContext } from '../../../../providers/AccountProvider';
import FormView from './FormView';
import TextButton from '../../../../components/TextButton/TextButton';
import { mdiArrowLeft } from '@mdi/js';
import Global from '../../../../clients/global'

const FormContainer = ({ color, tableinfo, tableid, blockid, close, tablename,plan }) => {
  const title = `Web forms for: ${tablename}`;

  const [control, setControl] = useState('manager');
  const [form, setForm] = useState();
  const [previewForm, setPreviewForm] = useState(false);
  const { userInfo } = useContext(AccountContext);

  const closeControl = (control = 'manager',currentForm) => {
    setForm(currentForm)
    setControl(control);
  };

  const togglePreview = (form,mode) => {
    // create encoded code for form.
    setForm(form);
    const { REACT_APP_SITE_URL } = process.env;

    const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;

    const c = Buffer.from(
      `${Global.zoneid}|${dbname}|${form.formid}`,
    );
    let base64data = c.toString('base64');
    if(mode===undefined){
      window.open(`${REACT_APP_SITE_URL}/form?x=${base64data}&mode=preview`);
    } else {
      window.open(`${REACT_APP_SITE_URL}/form?x=${base64data}`);
    }
  };

  const editForm = (form) => {
    setForm(form);
    setControl('designer');
  };

  const editSettings = (form) => {
    setForm(form);
    setControl('settings');
  };


  return (
    <div>
      <div
        style={{
          display: 'flex',
          paddingLeft: '20px',
          color: 'white',
          alignItems: 'center',
          height: '40px',
          backgroundColor: color,
          fontWeight: 'bold',
          fontSize: '20px',
        }}
      >
        {control==='manager' ? (
        <TextButton
          boldStyle="bold"
          text={title}
          Action={close}
          hoverColor="#FFFFFF80"
          icon={mdiArrowLeft}
          iconPosition="left"
          fontSize="20px"
          iconSize="25px"
        />): (
          <div>Web forms for: {tablename} &#62; {control==='designer' ? 'Design' : 'Settings'} for {form.name}</div>
        )}
      </div>

      {control === 'designer' ? (
        <FormDesigner
          togglePreview={togglePreview}
          form={form}
          blockid={blockid}
          color={color}
          tableinfo={tableinfo}
          tableid={tableid}
          close={closeControl}
        />
      ) : null}
      {control === 'manager' ? (
        <FormsManager
          editForm={editForm}
          togglePreview={togglePreview}
          editSettings={editSettings}
          color={color}
          tableinfo={tableinfo}
          blockid={blockid}
          tableid={tableid}
          close={closeControl}
        />
      ) : null}
      {control === 'settings' ? (
        <FormSettings
          togglePreview={togglePreview}
          form={form}
          color={color}
          tableinfo={tableinfo}
          tableid={tableid}
          close={closeControl}
          plan={userInfo.plan}
        />
      ) : null}

      {previewForm ? (
        <div
          style={{
            zIndex: 10,
            backgroundColor: 'white',
            width: '900px',
            height: '700px',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Form formid={form.formid} togglePreview={togglePreview} />
        </div>
      ) : null}
    </div>
  );
};

export default FormContainer;
