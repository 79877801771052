/* eslint-disable */
import React, {useState,useEffect} from 'react';
import Icon from '@mdi/react';
import { mdiChevronRight,mdiChevronLeft,mdiPageLast,mdiPageFirst} from '@mdi/js';
import TextButton from '../../../../../components/TextButton/TextButton';
import IconButton from '../../FilterData/IconButton';

const PageControls = ({totalPages,currentPage,navigate}) =>{

    const [page, setPage] = useState(parseInt(currentPage));

    useEffect(()=>{
        setPage(parseInt(currentPage))
    },[currentPage])

    const updatePage =(val) =>{
        if(Number.isInteger(val)) {
            setPage(val)
        }
    }


    const onKeyDownHandler = (e) =>{
       if(e.keyCode===13 && Number.isInteger(page) && page>=1 && page <=totalPages) {
        navigate(page-1)
       }
    }
  
    return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <IconButton Action={navigate} ActionValue="first" icon={mdiPageFirst} size="25px" color="#000000" hoverColor="#00000080" />
           <div style={{width:'10px'}}></div>
           <TextButton fontSize="13px" text='Prev' Action={navigate} ActionValue="prev" textColor='black' hoverColor="#00000080" iconSize='25px' icon={mdiChevronLeft} iconPosition="left" />
           <input onChange={(e)=>setPage(parseInt(e.target.value))} type="number" onKeyDown={(e)=>onKeyDownHandler(e)}  value={page}  style={{width:"40px"}} />&#160; / {totalPages}
           <div style={{width:'10px'}}></div>
           <TextButton fontSize="13px" text='Next' Action={navigate} ActionValue="next" textColor='black'  hoverColor="#00000080" iconSize='25px'  icon={mdiChevronRight} iconPosition="right" />
           <div style={{width:'10px'}}></div>
           <IconButton Action={navigate} ActionValue="last" icon={mdiPageLast} size="25px" color="#000000" hoverColor="#00000080" />
        </div>
    )
}

export default PageControls