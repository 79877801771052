/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { mdiEyeOff, mdiEye } from '@mdi/js';
import TextButton from '../TextButton/TextButton';

const MaskedText = ({ value, maskType, uniformLength, maskCharacters, canView }) => {
  const [val, setVal] = useState(null);
  const [showValue, setShowValue] = useState(false);
  let mask = '*';

  const toggle = () => {
    setShowValue(!showValue);
  };

  useEffect(() => {
    if (maskType === 'full' && uniformLength && !showValue) {
      setVal(mask.repeat(uniformLength));
    } else if (maskType === 'full' && !showValue && uniformLength==undefined) {
      setVal(value.replace(/./g, '*'));
    } else if(maskType==='partial' && !showValue) {
      let newval=mask.repeat(value.length);
      if(value.length > maskCharacters) {
        newval = mask.repeat(value.length-maskCharacters) + value.slice(value.length-maskCharacters,value.length);
     } 
     setVal(newval);
    }
    else if (showValue) {
      setVal(value);
    } else {
      setVal('*****')
    }
  }, [value]);

  return (
    <div>
      {val !== null ? (
        canView ? (
          <TextButton
            textColor="black"
            text={showValue ? value : val}
            icon={!showValue ? mdiEye : mdiEyeOff}
            iconSize="20px"
            Action={toggle}
            iconPosition="left"
          />
        ) : (
          <div>{val}</div>
        )
      ) : null}
    </div>
  );
};

export default MaskedText;
