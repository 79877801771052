/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

export const generateText = async (query,isCsv=false) =>{
    const payload={query,isCsv};
    const result = await cloudrun.post('/generateText', { payload });
    return result;
  }

  export const test=()=>{
    console.log('done');
  }