/* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState,useContext,useCallback } from 'react';
import { css, jsx } from '@emotion/core'
import moment from 'moment';
import { Input, Form, TextArea, Checkbox, Icon as SIcon,Dropdown } from 'semantic-ui-react';
import { mdiChevronDown, mdiPlus,mdiClose} from '@mdi/js';
import DatePicker from 'react-datepicker';

import { AccountContext } from '../../../../providers/AccountProvider';
import 'react-datepicker/dist/react-datepicker.css';
import GBDropdownComponent from '../../../../components/GBDropdown/GBDropDownasComponent';
import UserDropdown from 'src/components/UserDropdown/UserDropdown';
import Divider from './Divider';
import AddRecord from '../../../../api/newRecord';
import GBButton from '../../../../components/GBButton/GBButton';
import Spinner from '../../../../components/Spinner/Spinner';
import GBPhone from '../../../../components/GBPhone/GBPhone';
import TextButton from '../../../../components/TextButton/TextButton';
import {getDefaultID,getDefaultOption} from '../../../../api/GetData';
import {getUsersAPI} from '../../../../api/users';
import {getOptionsAPI} from '../../../../api/options'
import {getRelationalTableData} from '../../../../api/tables';
import {uploadAnonymousFiles} from '../../../../api/images'
import NestedDropdowns from './nestedDropdowns';
import Global from '../../../../clients/global';
import CustomEditor from '../../../../components/CustomEditor/CustomEditor';
import UserDropdownHtml from '../../../../components/UserDropdown/UserDropdownHtml';

const NewRecord = ({ tableinfo,blockid, color, close,sourcetableid,tableid,executeAndClose,callingComponent,role }) => {
  // Controls. input, textarea, Date-Picker, DropDown,
  const uicontrols = [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 22, 23, 9, 10, 11, 15, 16];
  const singleValueControls=[2,3,5,22,23,9,20,11,13];
  const [columns, setColumns] = useState([]);
  const [isTopExpanded, setisTopExpanded] = useState(true);
  const [isBottomExpanded, setIsBottomExpanded] = useState(false);
  const [savedValues, setSavedValues] = useState([]);
  const [dateSelected, setDateSelected] = useState();
  const [errors, setErrors] = useState([]);
  const {userInfo} = useContext(AccountContext);
  const [isLoading, setIsLoading]=useState(false);
  const [nestedOptions,setNestedOptions] = useState({});
  const [users,setUsers] = useState([]);
  const [daySelected,setDaySelected] =useState(null)
  const [optionData,setOptionData] =useState(null);
  const [filterOptions, setFilterOptions] = useState({})

  useEffect(() => {

    //Remove any columns where useSecurity is true and the myRecords role is greater than users current role
    tableinfo.columns.filter(el=>el.uitype===1 && el.useSecurity===true).map(el=>{
      const userBlockIndex = userInfo.blocks.findIndex(itm=>itm.blockid===el.source.blockid);
      if(userBlockIndex !==-1) {
        if(el.myRecords > userInfo.blocks[userBlockIndex].role){
         tableinfo.columns=tableinfo.columns.filter(col=>col.data !==el.data);
        }
      }
    })

    let reducedFilters= tableinfo.columns.filter((el) => uicontrols.includes(el.uitype) && el.removeColumn===undefined && el.isSubTable ===undefined);
    
    const tblname=`tbl_${sourcetableid}`;
    reducedFilters = reducedFilters.filter(el=>(el.source && el.source.lookuptable) !== tblname && el.data !=='id' && el.uitype !==4)
    setColumns(reducedFilters);

    loadDefaultValues(reducedFilters);

    // 1-9-23, if the table has a RTFEditor, load users to be used in RTf mentionds logic.
    //10-30-23 commented ouit as we don't allow mentions in tinymce
    // if(tableinfo.columns.findIndex(el=>el.uitype===13) !==-1) {
    //   fetchUsers();
    // }

  }, [tableinfo]);

  const searchItems = (query,field) =>{
    const idx = optionData.findIndex(el=>el.field===field);
    console.log(field)
    console.log(optionData)

    if(idx !==-1) {
       let textOptions=[];
        const data = optionData[idx].data;


        data.filter(el=>el.option.toLowerCase().startsWith(query)).map(itm=>{
        const bgcolor = itm.attributes !==undefined ? itm.attributes.color : color
        const textColor = itm.attributes !==undefined ? itm.attributes.text : 'white'
        const control=<div style={{backgroundColor:bgcolor,padding:'5px',color:textColor,borderRadius:'7px',width:'max-Content'}}>{itm.option}</div>
        textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
      })
       return textOptions;
    } 
  }


  const hasRelationalData =(field) =>{
    const idx= optionData && optionData.findIndex(itm=>itm.field===field);
    return idx !==-1 && optionData[idx].data.length>0
  }


  // const fetchUsers = async () =>{
  //   const usr = await getUsersAPI([],false)
  //   let userMentions =[];
  //   usr.map(u =>{
  //       userMentions.push({key:u.userid,value:`${u.email}`, text:`${u.firstname} ${u.lastname}`, url:'#'})
  //   })
  //   setUsers(userMentions);
  // }


  const loadDefaultValues = async (columns) => {
    setIsLoading(true)
    const defaultValues=[];

    const loadData = async (cols) => {

      return Promise.all(
        cols.map(async itm=>{
         
          if(itm.defaultText !==undefined || (itm.uitype===22 && itm.defaultValue !==undefined)){
            if(singleValueControls.includes(itm.uitype)){
              if(itm.uitype===22){
                const d = new Date();
                if(itm.isFutureDays !==undefined){
                  if(itm.isFutureDays) {
                    d.setDate(d.getDate() + parseInt(itm.defaultValue))
                  } else {
                    d.setDate(d.getDate() - parseInt(itm.defaultValue))
                  }
                  defaultValues.push({field:itm.data,value:d,uitype:itm.uitype});
                }
              } 
              else {
                defaultValues.push({field:itm.data,value:itm.defaultText,uitype:itm.uitype});
              }
            } else if(itm.uitype===8){
              defaultValues.push({field:itm.data,value:itm.defaultText.map(i=>Number(i)),uitype:itm.uitype});
            } else if (itm.uitype===1 && itm.source.nestedFields===undefined) {
                const result = await getDefaultID(itm.source.lookuptableid,itm.defaultText);
                if(result !==null && result !==undefined){
                  const defaultOption={id:result.id,data:result.fullname}
                  defaultValues.push({field:itm.data,value:[defaultOption],uitype:itm.uitype})
                }
            } else if(itm.uitype===6 || itm.uitype===7) {
              const result = await getDefaultOption(tableid,itm.data,itm.defaultText);
              
                if(result !==null && result !==undefined){
                  const defaultOption={optionid:result.optionid,data:result.option,attributes:result.attributes}
                  defaultValues.push({field:itm.data,value:[defaultOption],uitype:itm.uitype})
                }
            }
          }

          if(itm.uitype===1 && itm.source.nestedFields !==undefined) {
            const data = await getRelationalTableData(tableid,itm,role,'',5000,null,'raw',true, false);
           nestedOptions[itm.data] = data;
         }
        })
        );
    }
      await loadData(columns);
      
      setNestedOptions(nestedOptions)


      if(defaultValues.length>0){
        setSavedValues(defaultValues)
      }

      await fetchOptionData(columns);
    
      setIsLoading(false)
  }

  const fetchOptionData = async (columns) => {

    const optionColumns =columns.filter(itm=>[6,7,1].includes(itm.uitype));
    const optionData=[];

    for(const col of optionColumns) {
      let result =null
      if(col.uitype===6 || col.uitype===7) {
        result = await getOptionsAPI(col.data,tableid,'list');
      } else if(col.uitype===1 && col.nestedFields===undefined) {
        result = await getRelationalTableData(tableid,col,role,'',1000,null,"list",false);
      }
      optionData.push({field: col.data, data: result});
    }
    setOptionData(optionData);
  }

  const getDefaultOptionValue = (field) =>{
    const idx = savedValues.findIndex(itm=>itm.field===field);
    if(idx !==-1) {
      const defaultValue = savedValues[idx]
      let val =null;
      if(defaultValue.uitype===7 && defaultValue.value !==null) {
         val = defaultValue.value[0].optionid.toString();
      } else if (defaultValue.uitype===6 && defaultValue.value !==null) {
        val = defaultValue.value.map(itm=>itm.optionid.toString())
      }
      return val;
    } else {
      return null
    }

  }

  const handleOptionChange = (colInfo,value) =>{
      const idx = optionData.findIndex(itm=>itm.field===colInfo.data);

      if(value==='' || value?.length===0) {
        updateValue(colInfo.data,[],colInfo.uitype)
        return false
      }
      
      if(idx !==-1) {
          if(colInfo.uitype===7 || colInfo.lookup==='single') {
             const idx2 = optionData[idx].data.findIndex(el=>el.optionid===value);
             if(idx2 !==-1) {
              const item = optionData[idx].data[idx2]
              item.data = item.option
              updateValue(colInfo.data,[item],colInfo.uitype)
             }
          } else if(colInfo.uitype===6 || colInfo.lookup==='multiple') {
            let finalValues=[];
            value.map(el => {
              const idx2 = optionData[idx].data.findIndex(itm=>itm.optionid==el.toString());
             
                if(idx2 !==-1) {
                const item = optionData[idx].data[idx2]
                item.data = item.option
                finalValues.push(item)
              }
              updateValue(colInfo.data,finalValues,colInfo.uitype)
            })
          
          }
      }
  }

  const renderLabel = (label) => {
    const { value, text } = label;

    const customLabel = (
      <div 
      style={{
        // padding: '1px',
        marginLeft:'5px',
        backgroundColor:'#000000',
        color: 'white',
        borderRadius: '7px',
        display: 'inline-flex', // Use 'inline-flex' to make sure the label and the remove icon are in the same line
        alignItems: 'center', // Center items vertically
        marginRight: '8px', // Add some space between labels
      }}>
        {text}
      </div>
    );

    return {
      color: '#eee',
      content: customLabel
    };

  }

  const displayOptions = (field) => {

  const idx= optionData && optionData.findIndex(itm=>itm.field===field);

   if(idx!==-1 && idx !==null && optionData.length>0) {
    let textOptions=[];
    
    optionData && optionData[idx].data.map(itm=>{
      const bgcolor = itm.attributes !==undefined ? itm.attributes.color : color
      const textColor = itm.attributes !==undefined ? itm.attributes.text : 'white'
      const control=<div style={{backgroundColor:bgcolor,padding:'5px',color:textColor,borderRadius:'7px',whiteSpace:'wrap', maxWidth:'max-Content'}}>{itm.option}</div>
      textOptions.push({key:itm.optionid, value:itm.optionid,text:control})
    })
    return textOptions
   } else {
    return []
   }

  }

  

  const saveData = async () => {

    let isValid =true;
  
    savedValues.forEach(el => {
      const field = tableinfo.columns.filter(itm=>itm.data===el.field)[0];
      if((field.uitype===10 || field.uitype===11) && el.value !=='') {
        
        const test = RegExp(field.validator, 'i');
        let newValue=el.value;
        if(field.uitype===11 && !el.value.includes('http')) {
          newValue=`http://${el.value}`
        }
        const checkField = test.test(newValue);
        if(!checkField) {
         setErrors(errors.concat({field:el.field,message:field.uitype===10 ? 'Please enter valid email.' : 'Please enter a valid URL.'}))
          isValid=false
          el.isValid=false
        }
      } else if(field.uitype===23 && (field.minValue !==undefined || field.maxValue !==undefined) && el.value !=='')
      {
        let minValid=true;
        let maxValid=true;
       
        if(field.minValue !==undefined) {
         minValid = el.value >= parseFloat(field.minValue)
        }
        if(field.maxValue !== undefined) {
          maxValid = el.value <= parseFloat(field.maxValue)
        }
         const isNumberValid = minValid && maxValid;
         if(!isNumberValid) {
          setErrors(errors.concat({field:el.field,message:`Please enter a number between ${field.minValue} and ${field.maxValue}`}))
          isValid=false
          el.isValid=false
         }
      }
    })

    if(isValid) {
    const datarecord = await AddRecord(savedValues, tableinfo,userInfo.id,tableid);
    // console.log(datarecord)
    if(executeAndClose !== undefined) {
      executeAndClose(datarecord.id)
    } else {
      await close(datarecord)
    }
    }  else {
      setSavedValues(savedValues)
    }
  }

  const updatePhone = (id,value) => {
    updateValue(id,value,9)
  }

  const updateNestedValue =(field,value) =>{
    updateValue(field,value,1);
  }

  const onDaySelect =(field,value,uitype) =>{
    if(value.toISOString() !== daySelected) {
      setDaySelected(value.toISOString())
    } else {
      updateValue(field,null,uitype)
    }
    // console.log(value.toISOString())
    // const currentvalues = [...savedValues];
    // const rowindex = currentvalues.findIndex((el) => el.field === field);
    
    // if(rowindex !==-1){
    //   if(currentvalues[rowindex].value !==null && currentvalues[rowindex].value.toISOString() == value.toISOString()) {
    //     // updateValue(field,null,uitype)
    //   } else {
    //     // updateValue(field,value,uitype)
    //   }
    // } else {
    //   // updateValue(field,value,uitype)
    // }
  }

  const onDayChange =(field,value,uitype,includeTime) =>{
    // console.log(value.toISOString())
    if(value !==null) {
      if(value.getHours()===0 && value.getMinutes()===0 && includeTime) {
        const d = new Date();
        value.setHours(d.getHours())
        value.setMinutes(d.getMinutes())
      } else if(!includeTime) {
        value.setHours(0)
        value.setMinutes(0)
      }
      updateValue(field,value,uitype)
    }

  }



  const updateValue = (field, value, uitype) => {

    // clear out any existing errors once they change any data on form.
    let tempValue=value;
    if([6,7,1].includes(uitype) && value.length===0) {
      tempValue=null;
    }
    

    setErrors([])
    const currentvalues = [...savedValues];
    const rowindex = currentvalues.findIndex((el) => el.field === field);
   
    if (rowindex !== -1) {
      currentvalues[rowindex].value = tempValue;
      setSavedValues(currentvalues);

    } else {
      const newfield = { field, value:tempValue, uitype };
      currentvalues.push(newfield);
      setSavedValues(currentvalues);
    }
    // console.log(currentvalues)
  };

  const updateCollaborators=(field,value,uitype) => {
    const userids = value.map(el=>el.userid);
    updateValue(field,userids,uitype)
  }

  const getValue =(field)=> {
    const currentvalues = [...savedValues];
    const idx = currentvalues.findIndex(el=>el.field===field)
    if(idx !==-1) {

      // 12-9-2021 special use-case for collaborator field. It expects a "filter" object with a value attribute of userid's
      // to populate selected Users. We store just the array of userid's in defaultText, so needs to be converted.
      // Also note that the userdropdown passes full user object on update, so we also need to reduce to just array of userids to pass to addnewrecord.js method.
      // This should be simplified in future. 
      if(currentvalues[idx].uitype===8) {
        return {value:currentvalues[idx].value}
      }
      return currentvalues[idx].value
    }
  }

  const displayFields = (from, to) =>
    columns.slice(from, to).map((el,i) => (
      <div
        key={el.data}
        style={{
          display: 'flex',
          minHeight:'60px',
          flexDirection: 'row',
          alignItems: el.uitype===1 && el.source.nestedFields !==undefined ? 'flex-start' : 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{overflow:'hidden', width: '150px', textAlign: 'right', marginRight: '10px', marginTop: el.uitype===1 && el.source.nestedFields !==undefined ? '10px' : null }}>{el.header}</div>

        <div style={{ margin: '10px', width: '336px' }}>
          {el.uitype === 2  || el.uitype===14 ? (
            <Input
              autoFocus={i===0?true:false}
              value={getValue(el.data)}
              style={{ height: '30px' }}
              onChange={(e, data) => updateValue(el.data, data.value, el.uitype)}
              fluid
            />
          ) : null}
          {el.uitype === 10 || el.uitype === 11 || el.uitype === 23 ? (<>
            <Input
              type={el.uitype===23 ? 'number' :'text'}
              value={getValue(el.data)}
              style={{ height: '30px' }}
              onChange={(e, data) => updateValue(el.data, data.value, el.uitype)}
              fluid
            /> 
            {errors.length>0 && errors.findIndex(err=>err.field===el.data) !== -1 ? (
              <div style={{color:'red'}}>{errors.filter(err=>err.field===el.data)[0].message}</div>
            ): null}
          </>) : null}
          {el.uitype===3 ? (
            <TextArea
            value={getValue(el.data)}
            style={{ fontSize: '12px' }}
            onChange={(e, data) => updateValue(el.data,data.value,el.uitype)}
          />) : null}

        {el.uitype===13 ? (
            <CustomEditor initValue={''} field={el.data} updateContent={updateValue} uitype={13} callingComponent="newrecord" />
        ): null}

          {el.uitype===5 ? (
            <Checkbox onChange={(e,data) =>updateValue(el.data,data.checked.toString(),el.uitype)} />
          ) : null}

          {el.uitype===8 ? (
          
           <UserDropdownHtml
           key={el.data}  
           field={el.data}
           uitype={el.uitype}
           blockid={blockid}
           updateFilter={updateCollaborators}
           color={color}
           filter={getValue(el.data)}
          //  filter={{value:el.defaultText.split('%|%').map(i=>Number(i))}} 
           selectedUsers={null}
           lookup={el.lookup}
          //  width={this.state.width}
           type={"restricted"}
           userType={el.userType}
           definedUsers={el.definedUsers}
           callingComponent="NewRecord"
           />
          ) : null}

          {el.uitype===9 ? (
           <GBPhone handleChange={updatePhone} id={el.data} value={getValue(el.data)} />
          ) : null}
          
          {el.uitype === 6 || el.uitype === 7 || (el.uitype === 1 && el.source.nestedFields===undefined && el.isSubTable===undefined) ? (
           <>
          
           {/* {hasRelationalData(el.data) ? (
           <div
           css={{
             '&& .ui.scrolling.dropdown .menu, && .ui.dropdown .scrolling.menu': {
               maxHeight: '200px !important', // This increases the specificity
             },
             '&& .ui.selection.dropdown': {
              backgroundColor:`${color}1A !important`
             }
           }}
         >
            <Dropdown
            placeholder="Select item"
            // selectOnBlur={false}
            // text={null}
            key={el.data}
            floating
            scrolling={el.lookup==='multiple'}
            multiple={el.lookup==='multiple'}
            defaultValue={getDefaultOptionValue(el.data)}
            search={(options,query)=> searchItems(query,el.data)} 
            renderLabel={renderLabel}
            fluid 
            clearable={el.lookup==='single'}
            selection 
            options={displayOptions(el.data)} 
            onChange={(e,data)=> handleOptionChange(el, data.value)} 
            />
           
            </div>
            ): <div>No available options</div>} */}
           
           
           {hasRelationalData(el.data) ? (
            <GBDropdownComponent
              field={el.data}
              tableid={tableid}
              source={el.source}
              colInfo={el}
              role={role}
              blockid={blockid}
              color={color}
              width="336px"
              uitype={el.uitype}
              lookup={el.lookup}
              updateValue={updateValue}
              callingComponent='NewRecord'
              optionValues={getValue(el.data)}
            />
           ):<div>No available options</div>}
             </>

          ) : null}
          {el.uitype===1 && el.source.nestedFields !==undefined && nestedOptions[el.data] !==undefined ? (
            <NestedDropdowns 
            data={nestedOptions[el.data]}
            colInfo={el} 
            nestedFields={el.source.nestedFields} 
            role={role} 
            updateValue={updateNestedValue} 
            color={color}
            callingComponent="newRecord"
            htmlMode={true}
            showLeftBar={false}
            />
          ): null}
          {el.uitype === 22 ? (
            <DatePicker
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
              minDate={moment().subtract(80, 'years')._d}
              selected={getValue(el.data) ?? null}
              // onSelect={(date) => onDaySelect(el.data, date,el.uitype)}
              onChange={(date) => onDayChange(el.data, date, el.uitype,el.includeTime)}
              showTimeInput={el.includeTime}
              dateFormat= {el.includeTime ? "MMMM d, yyyy h:mm aa" : 'MMMM d, yyyy'}
            />
          ) : null}
        </div>
      </div>
    ));

  return (
   
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        // marginLeft: '20px',
        // marginRight: '20px',
        // border: '1px solid grey',
        // marginTop: '10px',
        borderRadius: '10px',
        // overflow:'auto',
        // height:'calc(100vh -550px)',
        // height:'100%'
      }}
    >
       {!isLoading ? (
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            paddingLeft: '20px',
            height: '40px',
            fontSize:'20px',
            // borderBottom: '1px solid grey',
            backgroundColor: callingComponent==='grid' ? color  : 'white',
            color: callingComponent==='grid' ? 'white' : 'black',
            alignItems: 'center',
          }}
        >
          <div>Create {tableinfo.recordname}</div>
          <div style={{display:callingComponent==='grid' ? 'block' : 'none',marginRight:'20px'}}>
           <TextButton icon={mdiClose} text="Cancel" textColor="white" hoverColor="#FFFFFF80" Action={close} />
          </div>

        </div>
       <div style={{overflow:'auto',height:callingComponent==='grid' ? 'calc(100vh - 250px)' : '100%'}}>
        <Divider
          setSectionExpanded={setisTopExpanded}
          color={color}
          isSectionExpanded={isTopExpanded}
          fieldCount={3}
          sectionNumber={1}
          sectionName="Initial fields"
        />

        <div style={{ display: isTopExpanded ? 'block' : 'none' }}>{displayFields(0, 3)}</div>

        <Divider
          setSectionExpanded={setIsBottomExpanded}
          color={color}
          isSectionExpanded={isBottomExpanded}
          fieldCount={columns.length - 3}
          sectionNumber={2}
          sectionName="More fields"
        />

        <div style={{ display: isBottomExpanded ? 'block' : 'none'}}>
          {displayFields(3, columns.length)}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          {/* <div onClick={() => close(false)} style={{ marginRight: '20px' }}>
            Cancel
          </div> */}
          <TextButton text="Cancel" hoverColor="#75757580" Action={close} textColor="black" />
          <div style={{width:'20px'}} />
          <GBButton text={`Create ${tableinfo.recordname}`} color={color} iconHoverColor={color} iconHoverBackgroundColor="white" iconColor="white" Action={saveData} icon={mdiPlus} />
        </div>
        </div>
      </Form>
     ) : <Spinner color={color}/>}
       
    </div>
  );
};

export default NewRecord;
