/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import { Link } from '@reach/router';
import { mdiShareVariant } from '@mdi/js';
import Page from '../../../../components/Page';

import Button from '../../../../components/Button';
import LogoIcon from '../../../../assets/icons/GraceBlocksNewLogo.svg';
import FinalForm, { Form, FormField, FormCard, validators } from '../../../../components/Form';

const Password = ({ verifyPassword, passwordAttempts }) => {
  const confirmAcccount = (values) => {
    verifyPassword(values.password);
  };

  return (
    <Page twin>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',background:null }}>
        <img src={LogoIcon} alt="Logo" style={{marginBottom:'20px'}} />
        <div
          style={{
            display: 'flex',
            fontWeight: 'bold',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '10px',
            width: '90px',
            paddingRight: '20px',
            fontSize: '20px',
            padding: '5px',
            height: '30px',
            borderRadius: '25px',
            backgroundColor: '#9C1D71',
            color: 'white',
          }}
        >
          <Icon path={mdiShareVariant} size="25px"></Icon>
          Share
        </div>
      </div>
      <FormCard title={'PASSWORD PROTECTED RECORD(S)'}>
        <div style={{ textAlign: 'left', marginBottom: '20px' }}>
          Enter the password required below to view the record(s) that have been shared from
          GraceBlocks.
        </div>
        <FinalForm onSubmit={confirmAcccount} id="id-sign-in-form">
          {() => {
            return (
              <>
                <FormField
                  disabled={passwordAttempts > 2}
                  name="password"
                  label="Password"
                  placeholder="Enter the password to view the share record(s)"
                  control={Form.PasswordControl}
                  // leftIcon={<EmailIcon />}
                />

                <div className="py-5" />
                <Button disabled={passwordAttempts > 2} long type="submit">
                  Submit
                </Button>
                <div className="pt-2" style={{marginTop:'20px'}}>
                  New to GraceBlocks? <Link to="/auth/sign-up">Sign up for free</Link>
                </div>
              </>
            );
          }}
        </FinalForm>
      </FormCard>
    </Page>
  );
};

export default Password;
