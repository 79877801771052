/* eslint-disable */
import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useNavigate } from '@reach/router';
import Spinner from '../../../../components/Spinner/Spinner';
import { AccountContext } from '../../../../providers/AccountProvider';
import { mdiCloudDownloadOutline, mdiFilter,mdiPlusCircleOutline } from '@mdi/js';
import {
  viewShare,
  filterAndSortRows,
  updateViewedRecords,
  recordShareDownload,
} from '../../../../api/shares';
import ExportData from '../../../../api/export';
import ActionButton from '../ActionBar/ActionButton';
import ExportModal from '../ExportModal/index';
import QuickViewVirtual from '../GridViews/QuickView/QuickViewVirtual';
import GroupView from '../GridViews/GroupView/GroupView';
import DetailView from '../Detailview/Detailview';
import FilterData from '../FilterData/index';
import FilterTags from '../FilterData/FilterTags';
import TopNav from './TopNav';
import Password from './Password';
import GBLogo from '../Forms/Powered.svg';
import Global from '../../../../clients/global'

const Share = ({ token }) => {
  const [data, setData] = useState(null);
  const [color, setColor] = useState('');
  const [isLoading, setIsLoading] =useState(false);
  const { userInfo,signOut } = useContext(AccountContext);
  const [activeRow, setActiveRow] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selected_id,setSelectedId] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [heightOffset, setHeightOffset] = useState(195);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterTableInfo, setFilterTableInfo] = useState(null);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortHeader, setSortHeader] = useState('id');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [viewedRecords, setViewedRecords] = useState(null);
  const [showData, setShowData] = useState(true);
  const [showConntrol, setShowControl] = useState(false);
  const [control, setControl] = useState('details');
  const [showExportModal, setShowExportModal] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [ipaddress, setIpaddress] = useState('unknown');
  const [error,setError] = useState('');
  const [pageName, setPageName] =useState('');
  const [passwordAttempts, setPasswordAttempts]= useState(0);
  const [rowHeight, setRowHeight] = useState(33);
  const [groupids,setGroupids] = useState(null);
  const [nextPrevBool,setNextPrevBool]=useState({}); 
  const [recordCount,setRecordCount] =useState(0);

  const topBar = useRef();
  const bottomBar = useRef();
  const navigate = useNavigate();

  window.addEventListener('message', (event) => {
    if (event.data === 'closeWindow') {

      console.log('called to close window')
      // Close the child window
      loadShareData();
    }
  });

  useEffect(() => {
    //10-25-22 We need to deal with use-case where use copies the expanded (original) share url, which 
    //doesn't go through the sharelogin. In this use-case it's possible they are logged into graceblocks
    //in a different zone than the share. This was throwing error, as it wasn't caught. Here' we 
    //verify the zoneid of share is same as logged in user. 
    const buff = Buffer.from(token, 'base64');
    const shareInfo = buff.toString('ascii');
    const zoneid=shareInfo.split('|')[0];
    if(parseInt(zoneid) !==parseInt(Global.zoneid)){
      setError('This share is from a different zone than you are logged into. In order to view this share, log out of GraceBlocks and return to this page.');
      redirect()
    } else {
      loadShareData();
      window.addEventListener('resize', getHeightOffset);
    }



  }, []);

  useEffect(() => {
    // if (data !== null) {
      getHeightOffset();
    // }
  }, );

  const redirect = async () =>{
    await signOut();
    navigate(`/auth/share/${token}`);
  }

  const getHeightOffset = () => {
    /*6-8-2022, we are dynamically calculating the height of top and bottom bar, as they can change based
      on text that is provided. There is a wierd issue where the sizing is one pixel off depending on if the bottom 
      bar has dynamic text or not, as well as the roudning up/down of the actual height. I use the bounding rectangle
      to get the actual size and then round-down...which works in situations where the bottom bar has text. Otherwise,
      I can use the straight forward calculation of getting the clientHeight of each element. 
      */
 if(topBar.current !==null && bottomBar.current !==undefined) {

    let totalOffset = 0;
    if (bottomBar.current.clientHeight === 70) {
      totalOffset = topBar.current.clientHeight + bottomBar.current.clientHeight + 67;
    } else {
      totalOffset =
        Math.floor(topBar.current.getBoundingClientRect().height) +
        bottomBar.current.clientHeight +
        67;
    }
    // totalOffset = topBar.current.clientHeight + bottomBar.current.clientHeight+67;

    setHeightOffset(totalOffset);
    setIsMobile(window.innerWidth < 800);
  }
  };

  const verifyPassword = (password) => {
    if (password === data.share.settings.password) {
      setShowData(true);
      Cookies.set(token,'true', { expires: 30 });
    } else {
      if(passwordAttempts <2 ) {
      setPasswordAttempts(passwordAttempts+1);
      toast.error(
        <div style={{ margin: '10px' }}>Incorrect password. Please try again.</div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
        },
      );
      } else {
        setPasswordAttempts(passwordAttempts+1);
        toast.error(
          <div style={{ margin: '10px' }}>Incorrect password. This page has been disabled due to repeated failed login attempts.</div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 7000,
          },
        );
      }
    }
  };

  const changeControl = (newControl) => {
    setControl(newControl);
    setShowControl(true);
  };

  const downloadData = async () => {
    console.log('here')
    await recordShareDownload(data.share.id, ipaddress);
    const result = await ExportData(
      data.tableData.tableinfo,
      data.share.tableid,
      null,
      null,
      null,
      rowData,
    );

    setCsvData(result);
    setShowExportModal(true);
  };

  const launchForm =() =>{
    const { REACT_APP_SITE_URL } = process.env;
    var x = screen.width/2 - 700/2;
    var y = screen.height/2 - 450/2;
    
    const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;

    const c = Buffer.from(
      `${Global.zoneid}|${dbname}|${data.share.settings.formid}`,
    );
    let base64data = c.toString('base64');
    const url =`${REACT_APP_SITE_URL}/form?x=${base64data}&close=true`;
    // var iframe = `<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="${url}" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></html></body>`;
    // var childWindow = window.open("","",`width=1024,height=768,toolbar=no,menubar=no,resizable=yes,left=${x},top=${y}`);

    // Define the width and height
    const popupWidth = 1024;
    const popupHeight = 768;

    // Calculate the left and top positions to center the popup
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;

  // 2-6-24 Open the popup window directly, instead of iframe so that we can get callback to both close the window and refresh the list.
  let childWindow = window.open(url, 'PopupWindow', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);

  window.addEventListener('message', (event) => {
    if (event.data === 'closeWindow') {
      // Close the child window
      childWindow.close();
      setShowControl(false);
      loadShareData();
    }
  });

    
    // const checkChildWindowClosed = setInterval(() => {
    //   if (childWindow && childWindow.closed) {
    //     // The child window is closed
    //     clearInterval(checkChildWindowClosed);
    //     WindowClosed();
    //   }
    // }, 1000);
  
    // childWindow.document.write(iframe);
    // childWindow.document.close();

  }

  const loadShareData = async () => {
    setIsLoading(true);
    const data = await viewShare(token);
    if(data.error) {
      setError('The Share is no longer valid.');
      return;
    }

    //we manually set icon on tableinfo, which is used in detailview to show block icon at top.
    data.tableData.tableinfo.icon = data.tableData.icon;
    
    setData(data);
    setColor(data.share.settings.color);
    setRowData(data.rowData.rows);
    setRecordCount(parseInt(data.rowData.count))
    userInfo.blocks = data.blocks;

    //8-4-22 check if cookie is set , if yes, show contents
    const isValidated =Cookies.get(token);
    let showDataTemp=true;
    if(data.share.settings.protectLink && isValidated===undefined){
      showDataTemp=false;
    }

    // We need to keep track of heigh of top/bottom bar as they can dynamically change height
    //based on content and window size. This calc goes to quickview to dynamically size table.
    if (data !== null) {
      setViewedRecords(data.share.viewedrecords !== null ? data.share.viewedrecords : []);
      setShowData(showDataTemp);
      const pName=`${data.tableData.name}: ${data.share.name}`
      setPageName(pName)
      
      //8-12-22 added check for tabsetting and rowheight setting.
      let rh = 33;
      if(data.tableData.tabsettings &&  data.tableData.tabsettings.rowHeight){
       rh = data.tableData.tabsettings.rowHeight===1 ? 33 : data.tableData.tabsettings.rowHeight===2 ? 66 : 120
      }
      setRowHeight(rh);

      // We need to remove filter fields that were defined by share bookmark, so that we don't allow
      // end user to filter on those fields...as they shouldb locked.
      const usedFilters = data.filters.map((el) => el.field);
      const fInfo = { ...data.tableData.tableinfo };
      fInfo.columns = fInfo.columns.filter((col) => !usedFilters.includes(col.data));
      setFilterTableInfo(fInfo);

      //We determine if single record, if so, set selecteItem and just show Detail view
      if (data.rowData.rows && parseInt(data.rowData.count) === 1) {
        const item = data.rowData.rows[0];
        item.canEdit = false;
        setSelectedItem(item);
        setShowControl(true);
      }
    }
    const favico = document.getElementById('favicon');
    const sharecolor = encodeURIComponent(data.share.settings.color);
    const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:${sharecolor}' viewBox='0 0 24 24'%3E%3Cpath d='${data.tableData.icon}'/%3E%3C/svg%3E`;
    favico.href = svgpath;

    getHeightOffset();
    setIsLoading(false);

  };

  const loadMoreData = async (offset) =>{
    await filterData(filters,offset)
  }

  const filterData = async (filters,localOffset=0) => {
    setIsLoading(true);
    const filteredData = await filterAndSortRows(sortField, sortOrder, filters, token,localOffset);
    
    // 1-16-23, added localOffset to allow for infinte scroll. Only reset filters if it's 0

    if(localOffset===0) {
    setFilters(filters);
    togglerFilters();
    }

    if(localOffset===0) {
      setRowData(filteredData.rowData.rows);
    } else {
      setRowData(rowData.concat(filteredData.rowData.rows));
    }

    setRecordCount(parseInt(filteredData.rowData.count))
    setIsLoading(false);
  };


  const sortRows = async (sField, sOrder, sHeader) => {
    setIsLoading(true)
    setSortField(sField);
    setSortOrder(sOrder);
    setSortHeader(sHeader);
    const filteredData = await filterAndSortRows(sField, sOrder, filters, token);
    setRowData(filteredData.rowData.rows);
    setIsLoading(false)
  };

  // using same signature as Block to keep consistent(view is not used/needed here.)
  const showDetail = async (view, currentIndex, id,groupids=null) => {

    //12-11-23 Added check on new setting on whether user can view details page or not.
    if(data.share.settings.includeDetails !==undefined && !data.share.settings.includeDetails) {
        return false;
    }
    

    if (currentIndex === null) {
      currentIndex =rowData.findIndex((el) => el.id === id); // data.
    }

    let nextPrevBoolTemp={prev:currentIndex-1>=0,next:currentIndex+1<parseInt(rowData.length)}
    if(groupids !==null){
      const groupIdx = groupids.findIndex(el=>el===id);
      nextPrevBoolTemp={prev:groupIdx-1>=0,next:groupIdx+1<groupids.length}
    }


    let selectedItem = rowData.filter((itm) => itm.id === id)[0];
    selectedItem.canEdit = false;

    setSelectedId(id);
    setSelectedRowIndex(currentIndex);
    setActiveRow(currentIndex);
    setSelectedItem(selectedItem);
    setShowControl(true);
    setGroupids(groupids)
    setNextPrevBool(nextPrevBoolTemp);
  };

  const NextPrev = async (direction) => {
    let newrowindex = selectedRowIndex;
    let newSelectedItem = selectedItem;
    let next_selected_id = null;
    let nextPrevBoolTemp ={};
   

    if (direction === 'next' && newrowindex + 1 < parseInt(rowData.length)) {
      newrowindex = newrowindex + 1;
      newSelectedItem = rowData[newrowindex];
      newSelectedItem.canEdit = false;
      nextPrevBoolTemp={prev:newrowindex-1>=0,next:newrowindex+1<parseInt(rowData.length)}
    } else if (direction === 'prev' && newrowindex > 0) {
      newrowindex = newrowindex - 1;
      newSelectedItem = rowData[newrowindex];
      nextPrevBoolTemp={prev:newrowindex-1>=0,next:newrowindex+1<parseInt(rowData.length)}
    }
  
    next_selected_id = rowData[newrowindex].id;

    if(groupids !==null) {
      const groupIdx = groupids.findIndex(el=>el===selected_id)
      if(direction==='next') {
        if(groupIdx+1 < groupids.length){
          next_selected_id = groupids[groupIdx+1]
          nextPrevBoolTemp={prev:groupIdx>=0,next:groupIdx+2<groupids.length}
        } else {
          return false
        }
      } else if(direction==='prev') {
        if(groupIdx > 0) {
          next_selected_id = groupids[groupIdx-1]
          nextPrevBoolTemp={prev:groupIdx-2>=0,next:groupIdx<groupids.length}
        } else {
          return false
        }
      }
     
      newrowindex = rowData.findIndex(el=>el.id===next_selected_id)
      newSelectedItem = rowData[newrowindex]
    }

    setSelectedId(next_selected_id);
    setSelectedRowIndex(newrowindex);
    setActiveRow(newrowindex);
    setSelectedItem(newSelectedItem);
    setNextPrevBool(nextPrevBoolTemp)

    // 12-9-22 commented out, removing this functionality
    // const idx = viewedRecords.findIndex((el2) => el2 === newSelectedItem.id);
    // if (idx === -1) {
    //   viewedRecords.push(newSelectedItem.id);
    //   await updateViewedRecords(data.share.id, newSelectedItem.id);
    // }
  };

  const hideDetail = () => {
    setShowControl(false);
  };

  const reRender=()=>{
    setD('more')
    forceUpdate();
  }

  const togglerFilters = () => {
    setShowFilters(!showFilters);
  };

  const toggleExportModal = () => {
    setShowExportModal(!showExportModal);
  };

  const removeFilter = async (newfilter) => {
    setIsLoading(true)
    var tmparray = filters.filter((filter) => filter.field !== newfilter.field);
    setFilters(tmparray);
    const filteredData = await filterAndSortRows(sortField, sortOrder, tmparray, token,0);
    setRowData(filteredData.rowData.rows);
    setRecordCount(parseInt(filteredData.rowData.count))
    setIsLoading(false)
  };

  const removeSort = async () => {
    setIsLoading(true)
    setSortField('id');
    setSortOrder('asc');
    setSortHeader('id');
    const filteredData = await filterAndSortRows('id', 'asc', filters, token,0);
    setRowData(filteredData.rowData.rows);
    setRecordCount(parseInt(filteredData.rowData.count))
    setIsLoading(false)
  };


  const hasMessages = 
    data !== null && data.share.settings.includeMessages && data.tableData.tableinfo.columns.findIndex((el) => el.uitype === 12 || (el.uitype===10 && el.enableEmailMessages===true)) !== -1;

  const hasAttachments =
    data !== null &&
    data.tableData.tableinfo.columns.findIndex(
      (el) => el.uitype === 4 || (el.source && el.source.lookupuitype === 4),
    ) !== -1;
  const hasRelated =
    data !== null &&
    data.tableData.tableinfo.columns.findIndex(
      (el) => el.uitype === 1 && el.lookup === 'multiple',
    ) !== -1;


  const displayBody =() =>{
    return (
      data !== null && showData && !error ? (
        <>
        <Helmet>
        <title>{pageName}</title>
        {/* <link
          rel="icon"
          sizes="any"
          href="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M6 12c0-4.5 1.93-8.74 6-10a10 10 0 010 20c-4.07-1.26-6-5.5-6-10z'/%3E%3C/svg%3E"
        /> */}
        </Helmet>
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              ref={topBar}
              style={{
                display: data.share.settings.hideHeader===true ? 'none' : 'block',
                height: 'maxContent',
                padding: '10px',
                paddingLeft:'15px',
                backgroundColor: data.share.settings.color,
                color: 'white',
                overflow: 'auto',
              }}
            >
              {data.share.instructions !='' ? data.share.instructions : 'GraceBlocks Share'}
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '67px',
                backgroundColor: '#EEEEEE',
              }}
            >
              <div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <div
                      style={{
                        backgroundColor: 'white',
                        color: color,
                        paddingLeft: '7px',
                        paddingRight: '10px',
                        borderRadius: '20px',
                      }}
                    >
                      Total {!isMobile ? 'Records' : ''}: {data.rowData.count}
                    </div>
                    {rowData !==null && filters.length > 0 ? (
                      <div
                      style={{
                        border:'1px solid red',
                        marginLeft:'10px',
                        backgroundColor: 'white',
                        color: color,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        borderRadius: '20px',
                      }}
                    >
                      Filtered {!isMobile ? 'Records' : ''}: {rowData.length}
                    </div>
                    ): null}
                  </div>
                  <div style={{marginLeft:'2px'}}>
                <FilterTags
                  sortHeader={sortHeader}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  filters={filters}
                  removeFilter={removeFilter}
                  removeSort={removeSort}
                /></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {data.share.settings.includeWebform ? (
                  <ActionButton
                    label="Add record"
                    actionIcon={mdiPlusCircleOutline}
                    Action={launchForm}
                    color={color}
                    isSelected={false}
                  />
                ): null}
                {data.share.settings.includeDownload ? (
                  <ActionButton
                    label="Download"
                    actionIcon={mdiCloudDownloadOutline}
                    Action={downloadData}
                    color={color}
                    isSelected={false}
                  />
                ) : null}

                <ActionButton
                  label="Filter"
                  actionIcon={mdiFilter}
                  Action={togglerFilters}
                  color={color}
                  isSelected={filters.length > 0}
                />
              </div>
            </div>

            {rowData !== null && viewedRecords !== null  && (data.tableData.tabsettings===undefined || (data.tableData.tabsettings!==undefined && data.tableData.tabsettings.groups===undefined)) ? (
              <QuickViewVirtual
                color={color}
                rowHeight={rowHeight}
                tableid={data.share.tableid}
                tableinfo={data.tableData.tableinfo}
                data={rowData}
                showDetailView={data.share.settings.includeDetails !==undefined && !data.share.settings.includeDetails ? null : showDetail}
                activeRow={selected_id}
                setSortField={sortRows}
                UpdateTableColumnWidth={null}
                sortField={sortField}
                sortOrder={sortOrder}
                heightOffset={heightOffset}
                viewedRecords={viewedRecords}
                loadData={loadMoreData}
                recordCount={recordCount}
              />
            ) : rowData !== null && viewedRecords !== null && data.tableData.tabsettings.groups !==undefined ? (
              <GroupView 
                  color={color} 
                  rowHeight={rowHeight} 
                  tableid={data.share.tableid} 
                  tableinfo={data.tableData.tableinfo} 
                  data={rowData}
                  showDetailView={data.share.settings.includeDetails !==undefined && !data.share.settings.includeDetails ? null : showDetail}
                  activeRow={selected_id}
                  setSortField={sortRows}
                  UpdateTableColumnWidth={null}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  heightOffset={heightOffset}
                  viewedRecords={viewedRecords}
                  loadData={null}
                  groups ={data.tableData.tabsettings.groups}
                  calcFields={data.tableData.tabsettings.calcFields ?? []}
                  />
            ) : (
              <div style={{ margin: '10px' }}>
                There are currently no matching records for this Share.
              </div>
            )}
          </div>
          <div
            ref={bottomBar}
            style={{
              display: data.share.settings.hideFooter===true ? 'none' : 'block',
              backgroundColor: color,
              height: 'maxContent',
              alignItems: 'center',
              color: 'white',
              paddingTop: '10px',
              paddingBottom: '10px',
              // border:'1px solid red'
            }}
          >
            <div style={{paddingLeft:'17px'}}>{data.tableData.statement}</div>
            <center>
              <div
                style={{
                  backgroundColor: 'black',
                  width: '180px',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <a href="https://www.graceblocks.com" target="_newwindow">
                  <img src={GBLogo} height="30px" />
                </a>
              </div>
            </center>
          </div>
        </div>
        </>
      ) : null)
  }


  return (
      <div style={{ position: 'relative' }}>
        {!isLoading ? displayBody() : <div><Spinner color="#AE007B" /></div>}
        {showConntrol && viewedRecords !== null && showData ? (
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'100%' }}
          >
            {isMobile ? (
              <TopNav
                control={control}
                hasAttachments={hasAttachments}
                hasMessages={hasMessages}
                hasRelated={hasRelated}
                canCollaborate={data.share.settings.includeAddComments}
                canViewComments={data.share.settings.includeComments}
                color={color}
                changeControl={changeControl}
                heightInfo={{ heightOffset, top: topBar.current?.clientHeight }}
                RID={selectedItem.CustomID}
                hide={parseInt(data.rowData.count) === 1 ? null : hideDetail}
                icon={data.tableData.icon}
                recordname={data.tableData.recordname}
                NextPrev={NextPrev}
                activeRow={activeRow}
                filterCount={rowData.length}
              />
            ) : null}
            <DetailView
              role={data.share.settings.security}
              tableid={data.share.tableid}
              blockid={data.tableData.blockid}
              color={color}
              NextPrev={NextPrev}
              hide={parseInt(data.rowData.count) === 1 ? null : hideDetail}
              id={selectedItem.id}
              userTabSettings={() => {
                return false;
              }}
              updateSettingsFromDetailView={() => {
                return false;
              }}
              selectedRow={selectedItem}
              tableinfo={data.tableData.tableinfo}
              updateTableInfo={() => {
                return false;
              }}
              RefreshRow={async() => {
                // 2-10-24 In share, refreshrow is cannot really be used, however, we re-use this for the use-case
                //where the user clicks to update a form from detail page. When they submit form, it calls
                //refreshrow. When invoked from share, we want to reload the share /ermove the detail view
                setShowControl(false)
                loadShareData();
                return false;
              }}
              SelectedRowIndex={selectedRowIndex}
              hasMessages={hasMessages}
              filterCount={rowData.length}
              activeRow={activeRow}
              isShare={true}
              isMobileShare={isMobile}
              canMessage={data.share.settings.includeSendMessage}
              canCollaborate={data.share.settings.includeAddComments}
              canViewComments={data.share.settings.includeComments}
              shareControl={control}
              heightInfo={{
                heightOffset,
                top: topBar.current?.clientHeight,
                bottom: bottomBar.current?.clientHeight,
              }}
              nextPrevBool={nextPrevBool}
            />
          </div>
        ) : null}

        {showFilters ? (
          <div
            style={{
              position: 'absolute',
              border: `1px solid ${color}`,
              zIndex: 20,
              top: !isMobile ? 40 : null,
              bottom: !isMobile ? null : 0,
              right: !isMobile ? 30 : 0,
              left: !isMobile ? null : 0,
              backgroundColor: 'white',
              margin: !isMobile ? '30px' : null,
              paddingBottom: '60px',
            }}
          >
            <FilterData
              tableinfo={filterTableInfo}
              tableid={data.share.tableid}
              saveText="Apply filter(s)"
              close={togglerFilters}
              FilterData={filterData}
              filters={filters}
              color={color}
              blockid={data.tableData.blockid} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
            />
          </div>
        ) : null}

        {showExportModal ? (
          <div
            style={{
              position: 'absolute',
              width: '400px',
              height: '300px',
              border: `1px solid ${color}`,
              zIndex: 20,
              top: 50,
              right: 50,
              backgroundColor: 'white',
            }}
          >
            <ExportModal
              exportdata={csvData}
              close={toggleExportModal}
              color={color}
              name={data.tableData.name}
              blockname="Share"
            />
          </div>
        ) : null}

        {!showData ? <Password verifyPassword={verifyPassword} passwordAttempts={passwordAttempts} /> : null}
        {error !=='' ? <div style={{width:'100vw',height:'100vh',alignSelf:'center'}}>{error}</div> : null}
      </div>
  
  );
};

export default Share;
