/*
SUMMARY: mph 10-6-22
This method is called when updating a record from the detail screen. We changed logic from updating a field at 
a time to putting the detail record in "edit" mode and allowing them to edit all fields...and then updating in one update.

*/

/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

const UpdateRecord = async (changes,tableinfo,tableid,id) => {
  
  const payload={changes,tableinfo,id,tableid}
  const dataresponse = await cloudrun.post('/updateRecord', {payload})
  return dataresponse
};

export default UpdateRecord;
