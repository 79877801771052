/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useContext } from 'react';
import { css, jsx } from '@emotion/core'
import { Dropdown } from 'semantic-ui-react';
import { AccountContext } from '../../providers/AccountProvider';
import SelectedUserItem from './SelectedUserItem';
import { getTableUsersAPI,getBlockUsersAPI,getUsersAPI } from '../../api/users';
import Global from '../../clients/global';

const UserDropdown = ({row,field,uitype, updateFilter, color, lookup, selectedUsers,width="100%",filter,tablename,blockid,type,callingComponent,expand,userType='zone',definedUsers=[]}) => {
  
  const [users, setUsers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const schema = `schema_${Global.zoneid}`;
  const [defaultValue,setDefaultValue] = useState(null);
  const [text, setText] =useState(selectedUsers !==null ? null : 'Select a user')


  // const filterItems = async (value) => {
  //   setSearchTerm(value);
  //   setItems(allItems.filter((el) => el.firstname.toLowerCase().includes(value)));
  // };

  useEffect(() => {
    const fetchData = async () => {
    let result = [];
      if(uitype===26 && type==='grid')
      {
        // result = await getNonBlockUsersAPI(blockid) 
        result = await getBlockUsersAPI(blockid,false)  //second param indicates if you want users assigned to block or not yet assigned.
      } else if (uitype===26 && type==='filter') {  //11-8-2021 added support for collaboration type (8)
        result = await getUsersAPI()
      } else if (uitype===8 && type==='filter') {
        // 1-26-23 if filtering on collab, show all users regardless of active/inactive
        result = await getUsersAPI() 
      } else if (uitype===8 && type==='restricted') {
        // 1-26-23 Collaboration type can either be show all users in zone, regardless of status (type='filter')
        //or restricted based on defined "userType" param. 
        if(userType==='zone' || userType==='specific') {
          const filters=[{field:"status",value:['Active']}]
          result = await getUsersAPI(filters,false,"raw",userType==='specific' ? definedUsers : null) 
        } else if(userType==='block') {
          result = await getBlockUsersAPI(blockid,true)
        }
      }
      else if (uitype===24 || uitype===25 )
      {
        // 12-8-2021 updating logic to include active Block users AND any users who previously added rows
        // to the table, as those users could be in-activated in the future, and you still need to search.
       result = await getTableUsersAPI(schema, tablename, uitype);
       const result2 = await getBlockUsersAPI(blockid,true) 
       result2.map(itm=>{
         const idx =  result.findIndex(el=>el.userid===itm.userid)
         if(idx ===-1){
           result.push(itm)
         }
       })
      }
      const usersTemp=[];
      const selUsers =[];

      result.length > 0 && result.forEach((itm) => {
        
        // 2-11-2021 as we add new values to the user attributes json, it continues to break the block user table ability
        // to select a new user to associate as the value must match exact. Therefore we will specfially create
        // the attributes with the common required to display a user: text, color and avatar.
        const newattributes = {color:itm.attributes.color, text:itm.attributes.text, avatar:itm.attributes.avatar !==undefined ? itm.attributes.avatar : null}
        
        const useritem = {      
          userid:  itm.userid ,
          firstname: itm.firstname,
          lastname: itm.lastname ,
          email:itm.email,
          status:itm.status,   
          mobile: itm.mobile ,
          address: itm.address,        
          attributes: newattributes,
        };
        usersTemp.push(useritem);
        usersTemp.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));

       
        if(filter !==undefined && filter.value) {
          if(filter.value.includes(itm.userid)){
                selUsers.push(useritem)
            }
        }
      })

      let selected = [];

      if(selectedUsers !==null && selectedUsers !==undefined  && selectedUsers.length>0) {
        selected = selectedUsers
      } else if(selUsers.length>0) {
        selected = selUsers
      } 

      //Set selected values.
      setSelectedItems(selected)
  
      //Store the selected values as keys used by dropdown component
      if(lookup==='single' && selected.length===1) {
        setDefaultValue(selected[0].userid)
      } else if(lookup==='multiple' && selected.length>0) {
        setDefaultValue(selected.map(itm=>itm.userid))
      } else {
        setDefaultValue(null)
      }

      setUsers(usersTemp)

    };
    fetchData();
  

    // setIsExpanded(gridExpandState)
    if(selectedItems !== undefined && selectedItems !== null){
      setSelectedItems(selectedItems)
    } else {
      setSelectedItems([])
    }
    
  },[uitype,selectedUsers,row])

  const selectItem = (value) => {
      
    console.log(value)
      let temparray=[];
      if(lookup==='multiple' && value.length>0)
      {
        console.log('here')
        value.map(el=>{
          const idx = users.findIndex(itm=>itm.userid===el);
          if(idx !==-1) {
            temparray.push(users[idx])
          }
        })
      } else if(lookup==='single' && value !==null) {
         const idx = users.findIndex(itm=>itm.userid===value);
         temparray.push(users[idx]);
      } 

      //The text value is used to show a text string if no value is present,
      //otherwise it will display the selected value at top of list. 
     if(value==='' || value.length===0) {
      setText('Select a user')
     } else {
      setText(null)
     }
    
    if(callingComponent==='NewRecord') {
      updateFilter(field,temparray,uitype)
    } else {
      updateFilter(temparray)
    }

  };

  const renderLabel = (label) => {
    const { value, text } = label;

    const customLabel = (
      <div 
      style={{
        // backgroundColor: 'blue',
        // width:'50px',
        // overflow:'hidden',
        padding: '1px',
        color: 'white',
        borderRadius: '7px',
        display: 'inline-flex', // Use 'inline-flex' to make sure the label and the remove icon are in the same line
        alignItems: 'center', // Center items vertically
        marginRight: '8px', // Add some space between labels
      }}>
        {text}
      </div>
    );

    return {
      color: 'white',
      content: customLabel
    };

  }


  const displayOptions = () => {

    let userOptions=[];
    users.map(itm=>{
      // const control=<div style={{backgroundColor:itm.attributes.color,padding:'5px',color:itm.attributes.text,borderRadius:'7px',whiteSpace:'wrap',maxWidth:'max-content'}}>{itm.option}</div>
      const control =<SelectedUserItem
          key={itm.userid}
          item={itm}
          backgroundColor='white'
          color={itm.attributes ? itm.attributes.text : 'white'}
        />
      userOptions.push({key:itm.userid, value:itm.userid,text:control})
    })

    return userOptions
  }

  const  searchItems =(options, query) =>{
    let textOptions=[];

    users.filter(el=>el.firstname.toLowerCase().startsWith(query)).map(itm=>{
      const control=<SelectedUserItem
      key={itm.userid}
      item={itm}
      backgroundColor='white'
      color={itm.attributes ? itm.attributes.text : 'white'}
    />
      textOptions.push({key:itm.userid, value:itm.userid,text:control})
    })
 
    return textOptions
  }



  return (<>
    {users.length>0 ? (
      <div css={{
        // '&& .ui.scrolling.dropdown .menu, && .ui.dropdown .scrolling.menu': {
        //   maxHeight:  callingComponent==='detailview' ? '600px !important' : null, // This increases the specificity
        // },
        // '&& .ui.selection.dropdown': {
        //   backgroundColor:`${color}1A !important`
        //  },
        //  '&& .ui.dropdown .menu': {
        //   maxHeight: '200px !important'
        //  },
      }}>
           <Dropdown 
            scrolling={lookup==='multiple'}
            multiple={lookup==='multiple'}
            // text={selectedItems.length===0 ? 'Select a user' : null}
            text={text}
            key={field} 
            search={searchItems} 
            renderLabel={renderLabel}
            fluid 
            defaultValue={defaultValue}
            clearable 
            {...(callingComponent==='grid' || callingComponent==='blockusers' ? { open: true } : {})}
            selection 
            options={displayOptions()} 
            onChange={(e,data)=>selectItem(data.value)} 
            />
      </div>
    ) : null}
  </>);
};

// export default UserDropdown;
export default UserDropdown;
