/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AccountContext } from '../../providers/AccountProvider';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiInformationOutline, mdiSend } from '@mdi/js';
import TextButton from '../../components/TextButton/TextButton';
import { checkEmailVerified } from '../../api/auth';
import GBButton from '../../components/GBButton/GBButton';
import PersonalInformation from './PersonalInformation';
import ContactInformation from './ContactInformation';
import {sendEmailVerification} from  '../../api/auth'
import ZoneInformation from './ZoneInformation';
import Avatar from '../../components/Avatar/Avatar';

// import Avatar from './Avatar';

const UserProfile = ({ color, toggleUserProfile }) => {
  const { userInfo } = useContext(AccountContext);
  const userItem = {
    userid: userInfo.id,
    firstname: userInfo.firstName,
    lastname: userInfo.lastName,
    attributes: userInfo.attributes,
  };
  const emailStatus = checkEmailVerified();

  // article on how to re-authenticate user, then change email (or password)
  // https://medium.com/@ericmorgan1/change-user-email-password-in-firebase-and-react-native-d0abc8d21618


  const sendEmail =() => {
    sendEmailVerification()
    toast.info(<div style={{marginRight:'10px'}}>Check your email. Click the link provided in this email to validate the email address.</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  return (
    <div
      style={{
        zIndex: 10,
        position: 'absolute',
        // border: `.3px solid ${color}`,
        borderRadius: '10px',
        overflow: 'hidden',
        marginBottom:'30px',
        // boxShadow: '0px 1px 2px #757575',
        backgroundColor: 'white',
        height: 'calc(100vh - 150px)',
        width: 'calc(100vw - 300px)',
        left: '225px',
        top: '60px',
      }}
    >
      <div
        style={{
          display: 'flex',
          color: 'white',
          alignItems: 'center',
          // boxShadow: '0px 3px 6px #757575',
          //   height:'100%',
          paddingTop: '10px',
          paddingBottom: '10px',
          minHeight: '42px',
          flexDirection: 'row',
          //   justifyContent: 'space-between',
          outline: 'none',
          backgroundColor: color,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          paddingLeft: '10px',
        }}
      >
        <TextButton
          text="My profile"
          iconPosition="left"
          boldStyle="bold"
          fontSize="20px"
          icon={mdiArrowLeft}
          hoverColor="#FFFFFF80"
          Action={toggleUserProfile}
          iconSize="25px"
        />
        <div
          style={{
            marginLeft: '10px',
            display: 'flex',
            fontSize: '20px',
            fontWeight: 'bold',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Avatar item={userItem} />
          <div style={{ marginLeft: '5px' }}>
            {userInfo.firstName} {userInfo.lastName}{' '}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          border: `1px solid ${color}`,
          // height: 'calc(100vh - 150px)',
          height:'90%',
          flexDirection: 'row',
          borderBottomLeftRadius:'10px',
          borderBottomLeftRadius:'10px',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <div style={{ width: '70%', marginTop: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <PersonalInformation color={color} />
            <div style={{ marginTop: '30px' }}></div>
            <ContactInformation color={color} />
            <ZoneInformation color={color} />
            <div style={{ height: '30px' }}></div>
          </div>
        </div>
        {!emailStatus ? (
        <div
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            marginTop: '20px',
            borderRadius: '20px',
            border: '1px solid #EEA8A2',
            height: '100%',
          }}
        >
      
            <div
              style={{
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                color: '#E06055',
                fontSize: '12px',
              }}
            >
              <Icon style={{ marginBottom: '10px' }} path={mdiInformationOutline} size="20px" />
              The email tied to this account is not yet verified. Please click the button below to finalize the Verification of your email.
              <div style={{ marginTop: '5px' }}>
              Or use the Actions dropdown and click Change if you need to change your currently listed email address to change it.
              </div>
   
              <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                <GBButton
                  Action={sendEmail}
                  text="Send verification"
                  width="200px"
                  color="#E06055"
                  icon={mdiSend}
                  iconHoverColor="white"
                  iconColor="white"
                />
              </div>
            </div>
            </div>
          ) : null}
      
      </div>
    </div>
  );
};

export default UserProfile;
