import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BootstrapToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const ToggleButtonGroup = ({ separated, className, ...props }) => {
  const classes = classNames(className, separated && 'btn-group-separated');
  return <BootstrapToggleButtonGroup {...props} className={classes} />;
};

ToggleButtonGroup.defaultProps = {
  separated: false,
  className: undefined,
};

ToggleButtonGroup.propTypes = {
  separated: PropTypes.bool,
  className: PropTypes.string,
};

export default ToggleButtonGroup;
