
const GetConversionFieldWarning =(sourceUIType,destinationUIType) => {

    if(sourceUIType===2)
    {
        if([22,23,1,4].includes(destinationUIType)) return 1;
        if([9,10,11].includes(destinationUIType)) return 4;
        if([3,7,6].includes(destinationUIType)) return 0;
        if ([18,19,20,24,25,21,5,12,8,15,17].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===3)
    {
        if([2,6,7,9].includes(destinationUIType)) return 3;
        if([1,22,23,4].includes(destinationUIType)) return 1;
        if([9,10,11].includes(destinationUIType)) return 4;
        if ([18,19,20,24,25,21,5,12,8,15,17,13].includes(destinationUIType)) return 2;
    }
   
    if(sourceUIType===23)
    {
        if([2,3].includes(destinationUIType)) return 0;
        if([6,7].includes(destinationUIType)) return 1;
        if ([22,1,13,18,19,20,24,25,21,5,12,4,8,15,10,11,22,17].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===22)
    {
        if([2,3].includes(destinationUIType)) return 0;
        if ([6,7,13,23,1,18,19,20,24,25,21,5,12,4,8,15,10,11,17].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===10)
    {
        if([2,3,6,7].includes(destinationUIType)) return 0;
        if([9,11].includes(destinationUIType)) return 4;
        if ([22,23,1,18,19,20,24,25,21,5,12,4,8,15,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===11)
    {
        if([4].includes(destinationUIType)) return 1;
        if([2,3,6,7].includes(destinationUIType)) return 0;
        if([9,10].includes(destinationUIType)) return 4;
        if ([22,23,1,18,19,20,24,25,21,5,12,8,15,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===6)
    {
        if([2,7].includes(destinationUIType)) return 3;
        if([10,11].includes(destinationUIType)) return 4;
        if([22,23].includes(destinationUIType)) return 1;
        if ([1,18,19,20,24,25,21,5,12,4,8,15,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===7)
    {
        if([2,6].includes(destinationUIType)) return 3;
        if([10,11].includes(destinationUIType)) return 4;
        if([22,23].includes(destinationUIType)) return 1;
        if ([1,18,19,20,24,25,21,5,12,4,8,15,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===17)
    {
        if([2,3,6,7,23].includes(destinationUIType)) return 0;
        if(destinationUIType !==17){
            return 2
        } 
    }

    if(sourceUIType===9)
    {
        if([2,3,6,7].includes(destinationUIType)) return 0;
        if ([22,23,1,18,19,20,24,25,21,5,12,4,8,15,10,11,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===5)
    {
        if([2,3,6,7].includes(destinationUIType)) return 0;
        if ([22,23,1,18,19,20,24,25,21,5,12,4,8,15,10,11,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===8)
    {
        if([2,3].includes(destinationUIType)) return 0;
        if ([22,23,1,18,19,20,24,25,21,5,12,4,8,15,10,11,17,13].includes(destinationUIType)) return 2;
    }
    if(sourceUIType===15)
    {
        if([2,3].includes(destinationUIType)) return 0;
        if ([22,23,1,18,19,20,24,25,21,5,12,4,8,15,10,11,17,13].includes(destinationUIType)) return 2;
    }
   
    return 0;
}

export default GetConversionFieldWarning;