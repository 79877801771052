/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core'
import onClickOutside from 'react-onclickoutside';
import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown,mdiChevronDownCircle } from '@mdi/js';

class GBActions extends Component {
  /* 9-17-2020
    Created this class to deail with userProfile =>contactInformation component where MG wants the ability to click
    on a div/row and pop-up a list of actions. I can't use a drop-down, as it causes it's own issues. Using this component,
    you can pass in an array of "Actions"
    I am using this comonent so when you click off it, it will auto-close. We can extedn with colors, etc later.

    Props
    An Action = {name, icon, Action, ActionValue} . 
    Bordercolor = color of border,
    */
  handleClickOutside = () => {
    // this.setState({isExpanded:false})
    this.props.close();
  };

  render() {
    const { Actions, borderColor, iconColor, isExpanded,chevronColor="red" } = this.props;
    return (
      <div>
        {isExpanded ? (
          <div
            style={{
              zIndex: 100,
              position: 'absolute',
              overflow:'auto',
              height:'200px',
              backgroundColor: 'white',
              height: '100px',
              width: '200px',
              borderRadius: '5px',
              top: '0px',
              left: '0px',
              display: 'flex',
              flexDirection: 'column',
              border: `1px solid ${borderColor}`,
            }}
          >
            <div
            // onClick={}
              style={{
                margin: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              Actions
        
            </div>
            {Actions.map((action) => (
              <div
                key={action.name}
                onClick={() => action.Action(action.ActionValue)}
                css={{
                  display: 'flex',
                  zIndex: '100',
                  padding: '5px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  '&:hover': {
                    transition: 'all .3s ease',
                    backgroundColor: `#eee`,
                  },
                }}
              >
                <Icon
                  style={{ marginRight: '5px' }}
                  path={action.icon}
                  size="15px"
                  color={iconColor}
                />
                {action.name}
              </div>
            ))}
          </div>
        ) : (
          <div css={{ zIndex: 0, '&:hover': {
            svg : {
              transition: 'all .3s ease',
              backgroundColor:`${chevronColor}80`,
              borderRadius:'20px',
              color:'white'
            }
          } }}>
            <Icon path={mdiChevronDown} size="20px" />
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(GBActions);
