/* eslint-disable */
import http from '../clients/http';
import cloudrun from '../clients/httpcloudrun';
import Global  from '../clients/global';

export const getZoneAPI = async (zoneid) => {
  const payload={selectedZoneId:zoneid,dbfunction:'getZoneAPI', useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse[0].json_agg[0]
};

export const getZoneMetrics = async (zoneid,plan) => {
  const payload={zoneid,plan}
  const dataresponse = await cloudrun.post('/getZoneMetrics', {payload})
  return dataresponse
};

export const getBlockMetrics = async () => {
  const payload={}
  const dataresponse = await cloudrun.post('/getBlockMetrics', {payload})
  return dataresponse
};

export const getZoneUserAdminBlock = async () => {
  const payload={dbfunction:'getZoneUserAdminBlock'}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse
};

export const updateZoneName = async (zoneid, name) => {
  const payload={zoneid,name,dbfunction:'updateZoneName',useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return "success"
}

// 7-19-23 Added for use-case where new use who is on trial of pro-plan wants to downgrade
// to "free" plan. All other plan updates are done via stripe methods. 
export const updateZonePlan = async (zoneid, plan) => {
  const payload={zoneid,plan,dbfunction:'updateZonePlan',useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return "success"
}

export const updateZoneSettings = async (zoneid, attributes) => {
  const payload={zoneid,attributes,dbfunction:'updateZoneSettings',useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  return "success"
}



export const addZoneAPI = ({ name, pos, token,email }) => {
  
  return cloudrun
    .post('/addZone', { title: name, pos, zonetoken: token, role: 'Zone owner',email })
    .then(({ zoneid }) => ({ id: zoneid }));
};

export const updateZoneAPI = ({ id, name, pos = 30000, token }) => {
  // const collaboratorsList = collaborators.filter(({ email }) => !!email).map(({ email }) => email);
  return cloudrun.post('/updateZone', {
    zoneid: id,
    title: name || 'Graceful Blocks',
    pos,
    zonetoken: token,
    // collaborators: collaboratorsList,
  });
};

export const switchZoneAPI = (zoneid) => {
  Global.zoneid=zoneid;
  return cloudrun.get('/switchZone', {
    params: { zoneid },
  });
};

export const getOwnerZones = async (userid) => {
  const payload={userid,dbfunction:'getOwnerZones',useCentral:true}
  const dbresponse = await cloudrun.post('/executeQueryAPI',{payload})
  return dbresponse[0].json_agg;
}

export const getZoneOwner = async () => {
  const payload={dbfunction:'getZoneOwner',useCentral:true}
  const dbresponse = await cloudrun.post('/executeQueryAPI',{payload})
  return dbresponse[0];
}

export const getZoneOwnersCount = async (zones) => {
  const payload={zones,dbfunction:'getZoneOwnersCount',useCentral:true}
  const dbresponse = await cloudrun.post('/executeQueryAPI',{payload})
  return dbresponse[0].json_agg;
}


export const updateZoneStatus = async (zoneid,isActive,token) => {

  // 11-18-2020 If they deactivate a zone, we need to cancel any active subscriptions
  const zoneInfo = await getZoneAPI(zoneid);

  const payload={activeZoneId:zoneid,isActive,token, dbfunction:'updateZoneStatus', useCentral:true}
  const dbresponse =await cloudrun.post('/executeQueryAPI', {payload})

      //if they have subscription cancel it.
      if(!isActive && zoneInfo.stripeInfo.subscriptionId !==undefined) {
        await stripeCancelSubscription(zoneInfo.stripeInfo)
      }
    
      //if they have any active phone numbers, remove them. //remove logic will also unsubscribe from stripe if there is a subscriptinoid
    if(!isActive && zoneInfo.attributes.PhoneNumbers !== undefined && zoneInfo.attributes.PhoneNumbers.length>0) {
      zoneInfo.attributes.PhoneNumbers.map(async el => {
          if(el.isActive) {
            await removePhoneNumber(el.number)
          }
        })
    }

  return dbresponse
}

export const provisionPhoneNumber = async (mobileNumber,countryLetterCode='',areaCodeSpecified='',isInternational=false,stripeCustomerId=null, planId=null ) => {

  const payload={phoneNumber:mobileNumber,countryLetterCode,areaCodeSpecified,isInternational,stripeCustomerId,planId}
  const TwilioNumber = await cloudrun.post('/provisionPhoneNumber',{payload})
  return TwilioNumber;
}

export const createStripeCustomer = async (email) => {
  
  const payload={email:email}
  const result = await cloudrun.post('/stripeCreateCustomer',{payload})
  return result
}

export const createStripeSubscription = async (stripeInfo, paymentInfo,currentPlan, amount,isExistingPayment,promoCodeID) => {
    
    const payload={stripeInfo,paymentInfo,currentPlan, amount,isExistingPayment,promoCodeID}
    const result = await cloudrun.post('/stripeCreateSubscription',{payload})
    return result
}

export const updateStripeSubscription = async (stripeInfo, paymentInfo,currentPlan, amount,isExistingPayment) => {
    
  const payload={stripeInfo,paymentInfo,currentPlan, amount,isExistingPayment}
  const result = await cloudrun.post('/stripeUpdateSubscription',{payload})
  return result
}

export const stripeUpdatePaymentMethod = async (stripeInfo,paymentMethod) => {
    
  const payload={stripeInfo,paymentMethod}
  const result = await cloudrun.post('/stripeUpdatePaymentMethod',{payload})
  return result
}

export const stripeDetachPaymentMethod = async (stripeInfo,paymentId) => {
    
  const payload={stripeInfo,paymentId}
  const result = await cloudrun.post('/stripeDetachPaymentMethod',{payload})
  return result
}

export const stripeAddPaymentMethod = async (stripeInfo,paymentMethod,isDefault) => {
    
  const payload={stripeInfo,paymentMethod,isDefault}
  const result = await cloudrun.post('/stripeAddPaymentMethod',{payload})
  return result
}

export const stripeGetUpcomingInvoice = async (stripeInfo,planId,quantity,promoCode) => {
  const payload={stripeInfo,planId,quantity,promoCode}
  const result = await cloudrun.post('/stripeGetUpcomingInvoice',{payload})
  return result
}

export const stripeGetPromotionCode = async (promoCode) => {
    
  const payload={promoCode}
  const result = await cloudrun.post('/stripeGetPromotionCode',{payload})
  return result
}

export const stripeGetNextInvoiceDate = async (stripeInfo) => {
    
  const payload={stripeInfo}
  const result = await cloudrun.post('/stripeGetNextInvoiceDate',{payload})
  return result
}

export const stripeInvoiceHistory = async (stripeInfo) => {
  const payload={stripeInfo}
  const result = await cloudrun.post('/stripeGetInvoiceHistory',{payload})
  return result
}

export const stripeAddFunds = async (stripeInfo,priceId,amount,promoId) => {
  const payload={stripeInfo,priceId,amount,promoId}

  const result = await cloudrun.post('/stripeAddFunds',{payload})
  return result
}

export const stripeGetInvoice = async (invoiceid) => {
  const payload={invoiceid}
  const result = await cloudrun.post('/stripeGetInvoice',{payload})
  return result
}

export const stripeUpdateCustomer= async (stripeInfo,newEmail, company,billingaddress) => {
  const payload={stripeInfo,newEmail,company,billingaddress}
  const result = await cloudrun.post('/stripeUpdateCustomer',{payload})
  return result
}

export const stripeCancelSubscription = async (stripeInfo) => {
  const payload={stripeInfo}
  const result = await cloudrun.post('/stripeCancelSubscription',{payload})
  return result
}

export const removePhoneNumber = async (number) => {
  const payload={number}
  const TwilioNumber = await cloudrun.post('/removePhoneNumber',{payload})
  return "success";
}

export const getMessagingFieldsUsedByNumber = async (phoneNumber) => {
  const payload={phoneNumber,dbfunction:'getMessagingFieldsUsedByNumber'}
  const dbresult = await cloudrun.post('/executeQueryAPI',{payload})
  return dbresult;
}