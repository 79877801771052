/* eslint-disable */
import React from 'react';
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import { Editor } from '@tinymce/tinymce-react';
import { mdiClose } from '@mdi/js';
import DOMPurify from 'dompurify';
import { getUsersAPI } from '../../../../api/users';
import { uploadAnonymousFiles } from '../../../../api/images';
import { AccountContext } from '../../../../providers/AccountProvider';
import TextButton from 'src/components/TextButton/TextButton';
import Global from '../../../../clients/global';
import { generateText } from '../../../../api/ai';

class RFTEditor extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      width: '125px',
      top: 30,
      left: 0,
      display: 'none',
      expandDown: false,
      users: null,
      header: '',
      isChanged: false,
    };

    this.mainElementRef = React.createRef();
    this.editorRef = React.createRef();
  }
  static contextType = AccountContext;

  componentDidMount() { }

  setValue(value, callback) {
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  fetchUsers = async () => {
    const usr = await getUsersAPI([], false);
    let userMentions = [];
    usr.map((u) => {
      userMentions.push({
        key: u.userid,
        value: `${u.email}`,
        text: `${u.email} (${u.firstname} ${u.lastname})`,
        url: '#',
      });
    });
    this.setState({ users: userMentions });
  };

  getValue() {
    let v='';
    if (this.editorRef.current) {
      v = this.editorRef.current.getContent()
      // console.log(v)
    }
    return v;
  }

  stopEvent = (event) => {
    event.stopImmediatePropagation();
    // event.preventDefault();
  };

  open() {
    this.setState({ display: 'block', isExpanded: false, isChanged: false });
    document.documentElement.addEventListener('mousedown', this.stopEvent, true);
  }

  close() {
    document.documentElement.removeEventListener('mousedown', this.stopEvent, true);
    this.setState({ display: 'none', isExpanded: false });
    this.finishEditing();
  }

  hidePanel = () => {
    this.close();
  };

  createMarkup = (html) => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const datarow = this.props.GetRowData(row);
    let recordname = '';
    let tableRecordName = '';

    if (datarow !== null) {
      recordname = datarow.recordname;
      tableRecordName = datarow.tableRecordName;
    }

    const tdPosition = td.getBoundingClientRect();
    const halfHeight = window.innerHeight * 0.5;
    const expandDown = tdPosition.y < halfHeight;

    this.setState({
      width: cellProperties.width,
      value: originalValue,
      expandDown,
      top: `${tdPosition.top + 2}`,
      left: `${tdPosition.left + 1}px`,
      header: cellProperties.header,
      recordname,
      tableRecordName,
    });

    if (this.state.users == null) {
      this.fetchUsers();
    }
  }


  uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      let url = '';
      const getimage = async () => {
        const schema = `schema_${Global.zoneid}`;
        const fileUrl = await uploadAnonymousFiles(schema, [file]);
        url = fileUrl[0].gcsurl;
        resolve({ data: { link: url } });
      };
      getimage();
    });
  };

  // eslint-disable-next-line class-methods-use-this
  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {
      value,
      width,
      header,
      recordname,
      tableRecordName,
      isChanged,
    } = this.state;
    let renderResult = null;

    if (this.props.isEditor  && this.state.display==='block') {
      renderResult = (
        <NativeListener
          onMouseDown={this.stopMousedownPropagation}
          //   onKeyDown={this.stopMousedownPropagation}
        >
          <div
            style={{
              overflow: 'auto',
              display: this.state.display,
              position: 'absolute',
              left: window.innerWidth / 2 - 400,
              top: window.innerHeight / 2 - 270,
              //   left: this.state.left,
              //   top: !this.state.expandDown ? `${this.state.top - 200}px` : `${this.state.top}px`,
              // background: '#fff',
              padding: '0px',
              zIndex: 999,
              width: '900px',
              minHeight: '500px',
            }}
            id="editorElement"
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '40px',
                  padding: '5px',
                  backgroundColor: this.props.color,
                  width: '100%',
                  color: 'white',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '10px',
                  }}
                >
                  {header} for {tableRecordName}: {recordname}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItem: 'center',
                    paddingLeft: '10px',
                  }}
                >
                  <TextButton
                    textColor="white"
                    hoverColor="#FFFFFF80"
                    icon={mdiClose}
                    iconPosition="right"
                    text="Save and close"
                    Action={this.hidePanel}
                  />
                </div>
              </div>
              <div
                style={{
                  borderLeft: '1px solid #CCCCCC',
                  borderRight: '1px solid #CCCCCC',
                  borderBottom: '1px solid #CCCCCC',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }}
              >
                <Editor
                  onInit={(evt, editor) => {
                    this.editorRef.current = editor;
                    this.editorRef.current.focus();
                  }}
                  initialValue={value}
                  init={{
                    removed_menuitems: "newdocument" ,
                    convert_urls:false,
                    formats: false,
                    min_height: 500,
                    max_height:500,
                    auto_focus:true,
                    menubar: true,
                    statusbar: false,
                    promotion: false,
                    branding: false,
                    plugins:
                      'advlist anchor autolink autoresize charmap code directionality emoticons fullscreen help image quickbars importcss insertdatetime link  lists media  nonbreaking pagebreak preview  save searchreplace table visualblocks visualchars wordcount',
                    toolbar:
                      'undo redo | formatselect fontselect fontsizeselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  link | bullist numlist outdent indent | removeformat | customAIButton',
                  quickbars_selection_toolbar: 'bold italic | h1 h2 h3 h4 formatselect | quicklink ',
                  setup: function (editor) {
                    // Add a custom button to the toolbar
                    editor.ui.registry.addButton('customAIButton', {
                      text: 'AI', // Button label
                      onAction: async function () {
                        // Custom action when the button is clicked
                        let content = editor.getContent();
                        const lastIdx = content.lastIndexOf('</p>');
                        const finalString = content.slice(0, lastIdx) + '. return in html' + content.slice(lastIdx);
                     
                        // Do your processing on the content here
                        // For example, let's add "Processed:" before the content
                        const processedContent = await generateText(finalString,false)
                        editor.setContent(processedContent);
                      },
                    });
                  },
                  }}
                />
              </div>
            </div>
          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      let markup = <div></div>;
      renderResult = <div></div>;
      if (this.props.value !== undefined && this.props.value !== null && this.props.value !== '') {
        const TagStyle = {
          width: this.props.cellWidth,
          height: this.props.cellHeight - 7,
          // backgroundColor:'#eee',
          float: 'left',
          zIndex: 0,
          whiteSpace: 'wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: '1',
          marginTop: '5px',
          // paddingTop:'5px',
          marginRight: '5px',
          marginLeft: '2px',
        };

        renderResult = (
          <div style={TagStyle}>
            <div
              style={{ minHeight: '30px', width: '100%' }}
              dangerouslySetInnerHTML={this.createMarkup(this.props.value)}
            />
          </div>
        );
      }
    }
    return <>{renderResult}</>;
  }
}

export default RFTEditor;
