/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { mdiEye, mdiEyeOff, mdiClose, mdiDragVertical } from '@mdi/js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextButton from '../../../../components/TextButton/TextButton';
import ColumnItem from './ColumnItem';
import {updateUserTableColumnSettings,updateUserTabSettings} from '../../../../api/tables'

class ColumnManager extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    name: '',
    items: [],
    isLoading: false,
    isChanged:false,
    hasLockedColumns:false,
    sortItems:[],
    groupItems:[],
    calcItems:[]
  };

  async componentDidMount() {
    await this.loadColumns();
  }

  loadColumns = async () => {
    this.setState({ isLoading: true });
    const { tableinfo,userTabSettings } = this.props;
    let hasLockedColumnsTemp=false;
    let itemsTemp = [];
    let initialSortItems=[];
    let initialGroupItems=[];
    let initialCalcItems=[];
    let isChangedTemp = false;

    if(userTabSettings.sortMultipleFields!==undefined) {
      initialSortItems = userTabSettings.sortMultipleFields;
    }
    if(userTabSettings.groups !==undefined) {
      initialGroupItems = userTabSettings.groups;
    }
    if(userTabSettings.calcFields !==undefined) {
      initialCalcItems = userTabSettings.calcFields;
    }

    tableinfo.columns.filter(itm=>itm.data !=='id').map((el) => {
      
      const disableSort =(el.source && el.uitype === 18 && el.source.lookup === 'multiple') ||
      (el.lookup === 'multiple' && (['all', 'unique'].includes(el.specialfunction) || !el.specialfunction) ) ||
      el.uitype === 12 || el.uitype===13

      //This check removes group checkbox if type is message,attachment or rtf.
      const disableGroup = (el.source && [12,4,13].includes(el.source.lookupuitype) || el.uitype===4 || el.uitype===12 || el.uitype===13 || el.uitype ==14)

      //mark field if numeric, to be used to show checkbox for fields you can have calculations for in group view.
      const isNumericField  = ([17,23].includes(el.uitype) && el.data !=='id') || (el.uitype===18 && [17,23].includes(el.source?.lookupuitype) && (!['all','unique'].includes(el.specialfunction) || el.source.lookup==='single') ) || el.fieldType==='numeric'

      itemsTemp.push({ header: el.header, data: el.data, hide: el.hide,width:el.width,locked:el.locked,disableSort,disableGroup,isNumericField });
      if(el.locked !==undefined) hasLockedColumnsTemp=true;
    });
    
    //remnove any sort fields that are no longer valid.
    initialSortItems.length>0 && initialSortItems.map(itm=>{
      const idx = tableinfo.columns.findIndex(col=>col.data===itm.field);
      if(idx===-1) {
        initialSortItems = initialSortItems.filter(itm2=>itm2.field !==itm.field);
        isChangedTemp=true;
      }
    })

    //5-26-23 Logic to check if field in groups still exist in tableinfo. If not, remove them and
    //set isChanged to true.
    initialGroupItems.length > 0 && initialGroupItems.map(itm=>{
      const idx = tableinfo.columns.findIndex(col=>col.data===itm);
      if(idx===-1) {
       initialGroupItems = initialGroupItems.filter(itm2=>itm2 !==itm);
       isChangedTemp=true;
      }
    })

    //5-30-23 remove any existing calcfields which are no longer valid in tableinfo.
    if(initialCalcItems.length>0)
    {
      initialCalcItems.map(itm=>{
        const idx = tableinfo.columns.findIndex(col=>col.data===itm);
        if(idx===-1) {
         initialCalcItems = initialCalcItems.filter(itm2=>itm2 !==itm);
         isChangedTemp=true;
        }
      })
    } else {
      //we set all fields checked by default
      initialCalcItems =tableinfo.columns.filter(col=>[17,23].includes(col.uitype) && col.data !=='id' || (col.uitype===18 && [17,23].includes(col.source?.lookupuitype) && !['all','unique'].includes(col.specialfunction)) || col.fieldType==='numeric').map(el=>el.data);
    }
    

    this.setState({isChanged:isChangedTemp, items: itemsTemp, isLoading: false,hasLockedColumns:hasLockedColumnsTemp,sortItems:initialSortItems, groupItems:initialGroupItems,calcItems:initialCalcItems });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(this.state.items, result.source.index, result.destination.index);

    this.setState(
      {
        items,
        isChanged:true
      },
      //   () => this.reOrderBookMarks(),
    );
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    // width: 250,
  });

  updateColumn = (item) => {
    const {items} = this.state;
    const tempItems = [...items]
    tempItems.filter(el=>el.data===item.data)[0].hide=!item.hide
    this.setState({items:tempItems, isChanged:true})
  };

  updateSortItems=(sortItem) => {
    const {sortItems} = this.state;
    const {plan} = this.props;

    if(plan==='Free') {
      toast.info(<div style={{marginRight:'10px'}}>You must upgrade to a paid subscription plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
      return false;
    }
    
    let sortItemsTemp = sortItems;
    const idx = sortItemsTemp.findIndex(el=>el.field===sortItem.field)
    if(idx===-1 && sortItems.length<3) {
      sortItemsTemp.push(sortItem)
    } else if(idx !==-1) {
      sortItemsTemp = sortItemsTemp.filter(el=>el.field !==sortItem.field)
    }
    this.setState({sortItems:sortItemsTemp,isChanged:true})
  }

  updateSortOrder =(sortItem,order) =>{
    const {sortItems} = this.state;
    let sortItemsTemp = sortItems;
    const idx = sortItemsTemp.findIndex(el=>el.field===sortItem.field)
    if(idx!==-1) {
      sortItemsTemp[idx].sortOrder =order
      this.setState({sortItems:sortItemsTemp,isChanged:true})
    }

  }

  updateGroupItems=(field) =>{
    const {groupItems} = this.state;
    const {plan} = this.props;

    if(plan==='Free') {
      toast.info(<div style={{marginRight:'10px'}}>You must upgrade to a paid subscription plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
      return false;
    }

    let groupItemsTemp = groupItems;
    const idx = groupItemsTemp.findIndex(el=>el===field)
    if(idx===-1 && groupItemsTemp.length<3) {
      groupItemsTemp.push(field)
    } else if(idx !==-1) {
      groupItemsTemp = groupItemsTemp.filter(el=>el !==field)
    }
    this.setState({groupItems:groupItemsTemp,isChanged:true})
  }

  //5-30-23 adding to support logic to allow them to select which numeric fields to show
  //calculations for on the group by page. 
  updateCalcItems =(field) =>{
    const {calcItems} = this.state;
    const {plan} = this.props;

    if(plan==='Free') {
      toast.info(<div style={{marginRight:'10px'}}>You must upgrade to a paid subscription plan in order to use this feature.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });
      return false;
    }

    let calcItemsTemp = calcItems;
    const idx = calcItemsTemp.findIndex(el=>el===field)
    if(idx===-1) {
      calcItemsTemp.push(field)
    } else if(idx !==-1) {
      calcItemsTemp = calcItemsTemp.filter(el=>el !==field)
    }
    this.setState({calcItems:calcItemsTemp,isChanged:true})
  }

  grid = 8;

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    width: '640px',
    padding: 2,
    // border:'1px solid red',
    marginLeft: '20px',
    // marginRight:'20px',

    background: isDragging ? `${this.props.color}80` : 'white',
    ...draggableStyle,
  });

  updateColumnsAndClose = async () => {
      const {tableid,close,closeModalandRefreshTable,userTabSettings} =this.props;
      const {items,isChanged,sortItems,groupItems,calcItems} = this.state;
      if(isChanged) {
      items.unshift({data: "id",width: 25})
      await updateUserTableColumnSettings(tableid,items);

      if(sortItems.length>0){
        userTabSettings.sortMultipleFields= sortItems
      } else {
        delete userTabSettings.sortMultipleFields
      }
      if(groupItems.length>0){
        userTabSettings.groups = groupItems
      } else {
        delete userTabSettings.groups;
      }

      if(calcItems.length>0) {
        userTabSettings.calcFields = calcItems
      } else {
        delete userTabSettings.calcFields
      }
      
      // 11-15-22 save group and sort options
      await updateUserTabSettings(tableid, userTabSettings);

      closeModalandRefreshTable()
      } else {
        close()
      }
  }


  render() {
    const { color, close, tableinfo, tablename } = this.props;
    const { items, isLoading,hasLockedColumns,sortItems,groupItems,calcItems } = this.state;
    // const {tableinfo} = this.s
    return (
      <div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'row',
            height: '40px',
            backgroundColor: color,
            color: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon path={mdiEye} size="25px" />
            <div style={{ marginLeft: '5px',fontSize:'15px' }}>Columns </div>
          </div>
          <TextButton
            text="Hide panel when done"
            Action={this.updateColumnsAndClose}
            textColor="white"
            icon={mdiClose}
            hoverColor="#FFFFFF80"
          />
        </div>
        <div style={{overflow:'auto',height:'calc(100vh - 250px)'}}>
          <div style={{ margin: '10px', marginLeft:'30px', fontWeight: 'bold' }}>
            Total columns for the tab: {tablename} ({tableinfo.columns.length - 1}){' '}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '30px',
              marginRight:'20px',
              marginTop:'10px',
              width: '640px',
            }}
          >
            <div style={{ width: '260px',fontWeight:'bold' }}>Field name</div>
             <div style={{ width: '180px',fontWeight:'bold' }}>Group/Summarize*</div>
             {/* <div style={{ width: '50px',fontWeight:'bold' }}>Sort</div> */}
            <div style={{ width: '80px',fontWeight:'bold' }}>Sort</div>
            <div style={{ width: '75px',fontWeight:'bold' }}>Hide/show</div>
            <div style={{ width: '25px',fontWeight:'bold' }}>Reorder</div>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {items.length > 0 &&
                    !isLoading &&
                    this.state.items.map((item, index) => (
                      <Draggable key={item.data} draggableId={item.data} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <ColumnItem
                              index={index}
                              color={color}
                              item={item}
                              updateColumn={this.updateColumn}
                              sortItems={sortItems}
                              groupItems={groupItems}
                              calcItems={calcItems}
                              updateSortItems={this.updateSortItems}
                              updateGroupItems={this.updateGroupItems}
                              updateSortOrder={this.updateSortOrder}
                              updateCalcItems={this.updateCalcItems}
                              dbname={tableinfo.dbname}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div style={{marginLeft:'30px',marginRight:'30px'}}>* Group and summarize settings only display when using quickview. Numeric fields will have summarize options
(sum, avg, min, max, median, range) appear for groupings only if the summarize option is checked.</div>
          {hasLockedColumns ? (
        <div style={{marginLeft:'30px',marginBottom:'20px',marginRight:'10px'}}>
          🔒 Fields with the lock icon are not available for you to view due to security access level controls.
          It is not possible to view or filter on information stored in these fields.  <a href ="https://graceblocks.zendesk.com/knowledge/articles/4413031923220/en-us?brand_id=360003603191" target="_newwindow">
          Learn more here</a>
        </div>
      ): null}
        </div>
     
       
      </div>
    );
  }
}

export default ColumnManager;
