import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { Popup } from 'semantic-ui-react';
import { mdiInformationOutline} from '@mdi/js';
import { Link } from '@reach/router';
import FinalForm, { Form, FormField, FormCard, validators } from '../../../components/Form';
import Button from '../../../components/Button';
import GoogleButton from '../../../components/GoogleButton';
import MicrosoftButton from '../../../components/MicrosoftButton';
// import Spinner from '../../../components/Spinner/Spinner';
import Loader from '../../../components/Loader';
import { EmailIcon } from '../../../components/Icon';
import LogoIcon from '../../../assets/icons/GraceBlocksNewLogo.svg';

const formValidators = {
  email: validators.composeValidators(validators.required, validators.email),
  password: validators.composeValidators(validators.required),
};

const SignIn = ({ onSubmit, onGoogleSignIn, onMicrosoftSignIn, loading }) => {
  const { REACT_APP_CURRENT_VERSION } = process.env;

  return (
    <>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img src={LogoIcon} alt="Logo" />
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize:'13px',
        }}
      >
        Open Beta&nbsp; <Popup content={`${REACT_APP_CURRENT_VERSION}`} trigger={<Icon path={mdiInformationOutline} size="15px" color="black" />} />
      </div>
      <FormCard title="Sign In">
        <GoogleButton className="mb-4" onClick={onGoogleSignIn}>
          Sign in with Google
        </GoogleButton>
        <MicrosoftButton className="mb-4" onClick={onMicrosoftSignIn}>
          Sign in with Microsoft
          </MicrosoftButton>
        <FormCard.Subtitle className="mb-2 mb-4">or sign in manually</FormCard.Subtitle>
        <FinalForm
          onSubmit={onSubmit}
          // initialValues={{ email: 'flampschelsea@gmail.com', password: '11111111aA!' }}
          id="id-sign-in-form"
        >
          {() => {
            // console.log(args);
            return (
              <>
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Enter your email address."
                  validate={formValidators.email}
                  leftIcon={<EmailIcon />}
                  controlId="id_email"
                />
                <FormField
                  name="password"
                  label="Password"
                  placeholder="Enter your password."
                  validate={formValidators.password}
                  control={Form.PasswordControl}
                  controlId="id_password"
                  helpText={
                    <div className="float-right" style={{ fontSize: '85%' }}>
                      <Link to="/auth/forgot-password">Forgot Password</Link>
                    </div>
                  }
                  helpTextAlways
                />
                <div className="py-5" />
                <Button long type="submit">
                  Sign in
                </Button>
                <div style={{ fontSize: '13px', marginTop: '10px' }}>
                  New to GraceBlocks? <Link to="/auth/sign-up">Sign up for free</Link>
                </div>
              </>
            );
          }}
        </FinalForm>
      </FormCard>
      {loading && <Loader />}
    </>
  );
};

SignIn.defaultProps = {
  onSubmit: () => {},
  onGoogleSignIn: () => {},

  loading: false,
};

SignIn.propTypes = {
  onSubmit: PropTypes.func,
  onGoogleSignIn: PropTypes.func,
  loading: PropTypes.bool,
};

export default SignIn;
