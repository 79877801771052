/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import FinalForm, { Form, FormField, validators } from '../../../components/Form';
import Spinner from '../../../components/Spinner/Spinner';
import { AccountContext } from '../../../providers/AccountProvider';
import GBSubmit from '../../../components/GBSubmit/GBSubmit';
import TextButton from '../../../components/TextButton/TextButton';
import AutoSave from './AutoSave';
import { COLORS, ICONS } from '../constants';
import GBIcons from '../../../utils/GBIcons';
import '../styles/styles.add.scss';
import Global from '../../../clients/global'

const formValidators = {
  name: validators.composeValidators(validators.required),
  password: undefined,
};

const AddBlock = ({ block, onCancel, show, onChange }) => {
  const defaultInformation =
    'Information entered here will appear on the Block Homepage to help Block users understand the what to do inside the application you build. You can edit this information later by going to Block Settings. <i class="cog icon"></i>';

  const { userInfo} = useContext(AccountContext);

  const [selectedColor, setSelectedColor] = useState(block.color);

  useEffect(() => {
    setSelectedColor(block.color);
  }, [block]);

  const initialValues = useMemo(
    () => ({
      color: block.color,
      icon: block.icon,
      name: block.name,
      statement: block.statement,
      // draft: block.draft ? block.draft : defaultEditor,
      draft: block.information ?? defaultInformation
    }),
    [block], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSubmit = useCallback(() => {}, []);

  const handleCancel = useCallback(() => {
    if (block.id) {
      onCancel();
    }
  }, [onCancel, block.id]);

  const handleChange = (values) => {
    
      //8-23-23 this is a fix for when they remove a statement. In that case, it passes old
      //value and wouldnt update. This sets value='' which effectively removes the statement
      if(values.statement===undefined) {
        values.statement=''
      }

        values.isNew = false;
        const colorIdx = COLORS.findIndex((itm) => itm === values.color);
        const activeZone = [...userInfo.zones];
        const tempZone = activeZone.filter(itm=>itm.id===parseInt(Global.zoneid))[0]
       if (colorIdx !== -1 || tempZone.plan !=='Free') {
          onChange({ ...block, ...values });
        } else {
            if (tempZone.role === 'Zone owner') {
          toast.info(
          <div style={{ margin: '5px' }}>
            The free plan does not support custom colors. Upgrade now by clicking the option in the
            top right corner of the Zone dashboard
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          },
        );
      } else {
        toast.info(
          <div style={{ margin: '5px' }}>
            The Free plan does not support custom colors, consider upgrading. Contact your Zone
            owner.
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          },
        );
      }
      }
  };

  const handleChanges = useCallback(
    (values) => {
      setSelectedColor(values.color);
    },
    [block, onChange],
  );

  return (
    <Modal
      enforceFocus={false}
      show={show}
      onHide={handleCancel}
      size="xl"
      centered
      // backdrop="static"
      dialogClassName="add-block-dialog-window"
    >
      <Modal.Header style={{ backgroundColor: selectedColor }}>
        <Modal.Title>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              fontSize: '13px',
              height: '42px',
            }}
          >
            &nbsp;Edit Block properties (Block ID: {block.blockid})
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={initialValues}
          subscription={{ values: true }}
          onSubmit={handleChange}
          id="addBlockForm"
        >
          {({ values: { color } }) => {
            return (
              <>
                <AutoSave debounce={0} onSave={handleChanges} />
                <div className="add-block-dialog-content">
                  <div className="add-block-dialog-content-data">
                    <div style={{ maxWidth: '250px' }}>
                      <FormField
                        name="name"
                        label="Name"
                        placeholder="New block."
                        validate={formValidators.name}
                        controlId="id_name"
                        size="sm"
                        color={color}
                      />
                    </div>
                    <FormField
                      name="draft"
                      label="Information"
                      controlId="id_information"
                      size="sm"
                      color={color}
                      control={Form.DraftControl}
                    />
                    <FormField
                      name="statement"
                      label="Persistent Statement"
                      placeholder="Enter a statement here if one is needed on every page of this block; this can be used for legal notices."
                      controlId="id_statement"
                      maxLength="255"
                      size="sm"
                      color={color}
                    />
                    <div
                      style={{
                        marginleft: '200px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <TextButton textColor="black" text="Cancel" hoverColor="#75757580" Action={handleCancel} />
                      <GBSubmit text="Save" color={selectedColor} />
                    </div>
                  </div>
                  <div className="add-block-dialog-content-colors">
                    <FormField
                      name="color"
                      label="Color"
                      controlId="id_color"
                      size="sm"
                      colors={COLORS}
                      control={Form.ColorPickerControl}
                    />
                  </div>
                  <div className="add-block-dialog-content-icons">
                    <FormField
                      name="icon"
                      controlId="id_icon"
                      size="sm"
                      icons={GBIcons}
                      control={Form.IconPickerControl}
                      color={color}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </FinalForm>
        {!block.id && <Spinner isLoading isDone={false} color="#AE007B" />}
      </Modal.Body>
    </Modal>
  );
};

AddBlock.defaultProps = {
  onCancel: () => {},
  onChange: () => {},
  show: false,
};

AddBlock.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  block: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  show: PropTypes.bool,
};

export default AddBlock;
