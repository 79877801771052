/* eslint-disable */
import React from "react";
import PNL from 'google-libphonenumber';
import NativeListener from "react-native-listener";
import { BaseEditorComponent } from "@handsontable/react";
import {getRegions} from '../../../../components/Form/Controls/PhoneNumber/phoneUtils';
import InputMask from 'react-input-mask';


class PhoneEditor extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.mainElementRef = React.createRef();
    this.phoneInput = React.createRef();
    this.containerStyle = {
      display: "none",
      flexDirection:'row',
      alignItems:'flex-start',
      justifyContent:'flex-start',
      position: "absolute",
      overflow:'hidden',
      left: 0,
      top: 0,
      background: "#fff",
      border: "1px solid #000",
      padding: "0px",
      zIndex: 999,
      width: this.props.cellWidth,
      height: this.props.cellHeight
    };

    this.state = {
      value: "",
      width:'125px',
      top:0,
      selectedCountry:'AW297',
      left:0,
      display:'none',
      regions:[],
      mask:`+1 999-999-9999`,
    };

  }

  componentDidMount(){
    const onlyCountryCodes=[];
    const regions =getRegions({onlyCountryCodes,favoriteCountryCodes: ['US']})
    
    this.setState({regions})
  }

  setValue(value, callback) {
   
    this.setState((state, props) => {
      return { value };
    }, callback);
  }

  selectRegion =(event) => {
    const id = event.target.value;
    const selectedRegion = this.state.regions.filter(itm=>itm.id===id)[0];
    const newMask = `+${selectedRegion.iso2} ${selectedRegion.numberMask}`
    this.setState({mask:newMask})

    // const phoneUtil = PNL.PhoneNumberUtil.getInstance();  
    // const phonenumber = phoneUtil.parse('+1 (978) 793-1633');
    // const intlnumber = phoneUtil.format(phonenumber,PNL.PhoneNumberFormat.E164)
    // console.log(intlnumber)

  }

  getValue() {
    return this.state.value;
  }

  open() {

    if(this.mainElementRef.current !== null){
    this.mainElementRef.current.style.display = "flex";
    this.phoneInput.current.getInputDOMNode().focus();  ;
    }
  }

  close() {
    if(this.mainElementRef.current){
      // this.updateText()
      this.mainElementRef.current.style.display = "none";
    }
    // this.setState({display:'none'})
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    // We'll need to call the `prepare` method from
    // the `BaseEditorComponent` class, as it provides
    // the component with the information needed to use the editor
    // (hotInstance, row, col, prop, TD, originalValue, cellProperties)
    super.prepare(row, col, prop, td, originalValue, cellProperties);
   
    let newMask = `+1 999-999-9999`;
    let selectedCountry ="US1";

  try{
      if(originalValue !==null && originalValue !=='')
      {
        const phoneUtil = PNL.PhoneNumberUtil.getInstance();
        const number = phoneUtil.parse(originalValue,'')
        const countryCode = number.getCountryCode()
      
        const selectedRegion = this.state.regions.filter(itm=>itm.iso2===countryCode)[0];
        selectedCountry = selectedRegion.id;
        newMask = `+${selectedRegion.iso2} ${selectedRegion.numberMask}`
      }
    } catch(error){

    }
    

    const tdPosition = td.getBoundingClientRect();
    this.setState({width:cellProperties.width,mask:newMask,selectedCountry})

    // As the `prepare` method is triggered after selecting
    // any cell, we're updating the styles for the editor element,
    // so it shows up in the correct position.
    if(this.mainElementRef.current !== null){
    this.mainElementRef.current.style.left = `${tdPosition.left  }px`;
    this.mainElementRef.current.style.top = `${tdPosition.top  }px`;
    }
  }

  onKeyPressHandler =(key) => {
      if(key==='Enter') this.updateText()
  }

  updateText=()=> {
  
    const {value} = this.state;
    const phoneUtil = PNL.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(value, '');
    let intlnumber = phoneUtil.format(number,PNL.PhoneNumberFormat.INTERNATIONAL);
      
    this.setState(
      (state, props) => {
        return {value:intlnumber};
      },
      () => {
        this.finishEditing();
      },
    );
  }

  stopMousedownPropagation(e) {
    e.stopPropagation();
  }

  GetOptions =() => (<>
  {this.state.regions.map(itm => (
    <option selected={itm.id===this.state.selectedCountry ? 'selected' : null} key={itm.id} value={itm.id}>{itm.region} +{itm.iso2}</option>
  ))}
  </>
  )

  render() {
    const {value,width,regions,mask,selectedCountry} = this.state;
    return (
      <NativeListener onMouseDown={this.stopMousedownPropagation} >
        <div
          style={this.containerStyle}
          ref={this.mainElementRef}
          id="editorElement"
        // onKeyPress={(e) => this.onKeyPressHandler(e)}
        >
          <select  onChange={this.selectRegion} style={{outline:'none',border:'1px solid #DcDCDC', margin:'2px',  borderRadius:'5px'}}>
            {this.GetOptions()}
          </select>
         <InputMask onKeyPress={e=>this.onKeyPressHandler(e.key)} ref={this.phoneInput} style={{height:this.props.cellHeight-3,outline:'none', border:'0px'}} alwaysShowMask="true" mask={mask} value={value} onChange={(e)=>this.setState({value:e.target.value})} maskChar={'_'} />
        </div>
      </NativeListener> 
    );
  }
}

export default PhoneEditor