import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';

const Callout = ({ callout, updateCallout }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
      <div style={{ marginBottom: '3px',fontSize:'13px' }}>
        <strong> Field callout</strong>
      </div>
      <Form>
        <TextArea
          style={{ fontSize: '12px' }}
          onChange={(e, data) => updateCallout(data.value)}
          value={callout}
          placeholder="Enter explainer text to help users understand this field (optional)"
        />
      </Form>
    </div>
  );
};

export default Callout;
