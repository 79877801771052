/* eslint-disable */
import React, { useState, useRef,useEffect } from 'react';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import TextButton from '../../components/TextButton/TextButton';
import { Dropdown, Input,Checkbox } from 'semantic-ui-react';
import { stripeUpdatePaymentMethod,stripeAddPaymentMethod,stripeDetachPaymentMethod } from '../../api/zone';
import AddPayment from './addPayment';
import { confirmAlert } from 'react-confirm-alert';
import Icon from '@mdi/react';
import { mdiPlus,mdiStar,mdiAlert,mdiChevronDown,mdiChevronUp } from '@mdi/js';

const PaymentMethods = ({ zoneInfo, toggleShowInfo }) => {
  //Stripe mike@gracerock.com / $mashFly2017

  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [payment, setPayment] = useState('');
  const [isDefault, setIsDefault] = useState(true)
  const [isExpanded, setIsExpanded] = useState(true);
  const [expMonth, setExpMonth] = useState()
  const [expYear, setExpYear] = useState()
  const [paymentInfo,setPaymentInfo] = useState(zoneInfo.stripeInfo.paymentInfo)

  const divRef = useRef();

  useEffect(() => {
    // console.log(zoneInfo.stripeInfo.paymentInfo)
  },[])

  const handlePaymentChange = (action, payment) => {
    setShowEdit(true);
    setPayment(payment);
    setExpMonth(payment.exp_month)
    setExpYear(payment.exp_year);
    setIsDefault(payment.isDefault)
  };

  const ToggleShowEdit = () => {
    setShowEdit(!showEdit);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
}

  const updatePayment = async () =>{

    if(isDefault && !payment.isDefault) {
      paymentInfo.map(itm => {
        itm.isDefault=false;
      })
    }

    payment.exp_month = expMonth;
    payment.exp_year = expYear;
    payment.isDefault = isDefault;
    setPayment(payment)

    const idx = paymentInfo.findIndex(el=>el.paymentId===payment.paymentId)
    if(idx !== -1){
      paymentInfo[idx] = payment
    }
    setPaymentInfo(paymentInfo)

    const result = await stripeUpdatePaymentMethod(zoneInfo.stripeInfo,payment)
    if(result==='success') {
      setShowEdit(false)
    }
  }

  const deletePayment = async (selectedPayment) =>{
    if(divRef.current.value==='delete'){

     const result = await stripeDetachPaymentMethod(zoneInfo.stripeInfo,selectedPayment.paymentId)
     if(result==='success') {
      const tempPaymentInfo= paymentInfo.filter(el=>el.paymentId !== selectedPayment.paymentId)
      setPaymentInfo(tempPaymentInfo)
      setShowEdit(false)
    }
  }
  }

  const addPaymentHandler = async (stripeInfo,paymentMethod,Default) => {
    
    stripeInfo.paymentInfo = paymentInfo;
    const result = await stripeAddPaymentMethod(stripeInfo, paymentMethod,Default);
    if(result==='success') {
      
      const newPaymentInfo = {
        paymentId: paymentMethod.id,
        cardBrand: paymentMethod.card.brand,
        cardLast4: paymentMethod.card.last4,
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
        isDefault: Default
      };

      if(Default) {
        paymentInfo.map(itm => {
          itm.isDefault=false;
        })
      }

      paymentInfo.push(newPaymentInfo)
      setPaymentInfo(paymentInfo)
      setShowAdd(false);
    }
  
  }

  const updateMonth = (value) => {
    setExpMonth(value)
  }
  const updateYear =(value) => {
    setExpYear(value)
  }

  const ToggleShowAdd =() => {
    setShowAdd(!showAdd)
  }

  const ToggleDefault =() => {
    setIsDefault(!isDefault)
  }

  const confirmDelete = (isDefault,payment) => {

    let message=''
    if(!isDefault){
      message =<div>
         Are you sure you want to delete this payment information?  <p></p>
      </div>
    } else {
      message=<div>Payment methods currently in use can not be deleted.  First add or edit another payment method to designate it for payments moving forward.</div>
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={!isDefault ? 'Delete payment information' : 'Payment method is in use'}
            action={!isDefault ? deletePayment : onClose}
            actionValue={payment}
            buttonLabel="OK"
            message={message}
            width="300px"
            divRef={divRef}
            showInput={!isDefault}
            showCancelButton={!isDefault}
            confirmAction="delete"
            confirmWord="delete"
            height={!isDefault ? '330px' : '200px'}
            onClose={onClose}
          />
        );
      },
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {showEdit ? (
        <div
          style={{
            zIndex:500,
            position: 'absolute',
            border: '.1px solid #AE007B',
            borderRadius: '10px',
            // borderTopRightRadius: '10px',
            top: '-20px',
            left: '200px',
            width: '500px',
            height: '200px',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              backgroundColor: '#AE007B',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              color: 'white',
              paddingLeft: '10px',
            }}
          >
          Edit payment details
          </div>
          <div style={{ paddingLeft: '10px', marginTop: '10px',display:'flex', flexDirection:'row', alignItems:'center' }}>
           <div style={{display:'flex', flexDirection:'column'}}>
             <div>Credit Card Number</div>
            <Input disabled={true} value={`${payment.cardBrand}*****${payment.cardLast4}`} />
            </div>

            <div style={{ width: '10px' }} />
            <div style={{display:'flex', flexDirection:'column'}}>
            <div>Exp Month</div>
            <Input style={{width:'50px'}} value={expMonth} placeholder="Exp_month" onChange={(e,data)=>updateMonth(data.value)} />
            </div>
            <div style={{ width: '10px' }} />
            <div style={{display:'flex', flexDirection:'column'}}>
            <div>Exp Year</div>
            <Input style={{width:'75px'}} value={expYear} placeholder="Exp_year"  onChange={(e,data)=>updateYear(data.value)} />
            </div>
            <div style={{ width: '10px' }} />
          </div>
          {!payment.isDefault ? (
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center',marginTop:'10px'}}>
          <Checkbox
          label="Use this payment method on future payments"
          checked={isDefault}
          onChange={() => ToggleDefault()}
        /></div>
        ) : <div style={{display:'flex', justifyContent:'center'}}>This is currently your default payment method.</div>}
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center',marginTop:'20px'}}>
            <TextButton text="Cancel" hoverColor="#75757580" Action={ToggleShowEdit} textColor="black" />
            <div style={{width:'10px'}}></div>
            <GBButton width="100px" text="Update" color="#AE007B" Action={updatePayment} />
            </div>
        </div>
      ) : null}

    {showAdd ? (
      <div
      style={{
        zIndex: 500,
        position: 'absolute',
        border: '.1px solid #AE007B',
        borderRadius: '10px',
        // borderTopRightRadius: '10px',
        top: '-20px',
        left: '200px',
        width: '500px',
        height: '200px',
        backgroundColor: 'white',
      }}
    >
       <div
       style={{
         backgroundColor: '#AE007B',
         borderTopLeftRadius: '10px',
         borderTopRightRadius: '10px',
         display: 'flex',
         alignItems: 'center',
         height: '30px',
         color: 'white',
         paddingLeft: '10px',
       }}
     >
     Add payment details
     </div>
       <AddPayment stripeInfo={zoneInfo.stripeInfo} cancel={ToggleShowAdd} addPayment={addPaymentHandler} />
       </div>
    ): null}
      

      <div
        style={{
          zIndex:1,
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
        }}
      >
        <div style={{ marginLeft: '20px' }}>
        <TextButton Action={toggleExpand} textColor="black" hoverColor="#75757580" fontSize="13px" icon={isExpanded ? mdiChevronUp : mdiChevronDown} text="Payment information" iconPosition="left" />
        </div>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ marginLeft: '10px' }}>
            <GBButton
              Action={ToggleShowAdd}
              icon={mdiPlus}
              width="70px"
              borderRadius="10px"
              // height="20px"
              iconHoverColor="White"
              iconHoverBackgroundColor="#757575"
              text="Add"
              textColor="#757575"
              textHoverColor="#757575"
              hoverBorder="2px solid #757575"
              iconPosition="left"
            />
          </div>
        </div>
      </div>
      {isExpanded ? (<>
      {zoneInfo.plan === 'Free' || zoneInfo.plan==='Public' ? (
        <div
          style={{
            marginLeft: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>The Zone currently uses the Free plan.</div>
          <div>First choose a plan that best fits your needs and then add payment info.</div>
          <GBButton
            textColor="#1A1A1A"
            Action={toggleShowInfo}
            text="Change plan"
            color="#FFBE24"
            width="100px"
            height="25px"
            borderRadius="15px"
          />
        </div>
      ) : (
        <div style={{marginLeft:'20px'}}>
          <div
            style={{
              display: 'flex',
              marginLeft: '40px',
              marginRight: '40px',
              marginBottom: '20px',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '200px' }}>Default</div>
            <div style={{ width: '200px' }}>Type</div>
            <div style={{ width: '200px' }}>Expiration date</div>
            <div style={{ width: '200px' }}>Actions</div>
          </div>
          {paymentInfo?.length>0 && paymentInfo?.map((itm) => (
            <div
              key={itm.paymentId}
              style={{
                display: 'flex',
                marginLeft: '40px',
                marginRight: '40px',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom:'20px'
              }}
            >
              <div style={{ width: '200px' }}>
                {itm.isDefault ? (<Icon path={mdiStar} size="20px" />) : null}
                </div>
              <div style={{ width: '200px' }}>
                {itm.cardBrand}****{itm.cardLast4}
              </div>
              <div style={{ width: '200px' }}>
                {itm.exp_month}/{itm.exp_year}
              </div>
              <div style={{ width: '200px' }}>
                {/* <Dropdown options={[{key:1,value:1,text:'Edit payment information'},{key:2, value:2, text:'Delete payment information'}]} /> */}
                <Dropdown>
                  <Dropdown.Menu>
                    <Dropdown.Header content="Actions" position="right" />
                    <Dropdown.Item
                      onClick={() => handlePaymentChange('edit', itm)}
                      icon="edit"
                      text="Edit payment information"
                    />
                    <Dropdown.Item
                      onClick={() => confirmDelete(itm.isDefault, itm)}
                      icon="trash"
                      text="Delete payment information"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      )}
      </>) : null}


    </div>
  );
};

export default PaymentMethods;
