/* eslint-disable */
import React, { useState,useEffect } from 'react';
import { mdiEmail, mdiClose,mdiFileDocument,mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import TextButton from '../../../../components/TextButton/TextButton';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import {getEmailMessageStatus} from '../../../../api/messages'

const ViewFullEmail = ({ color, message, close }) => {
  let tformat = 'DD-MMM-YYYY hh:mm:ss a';

  const [viewPdfContent,setViewPdfContent] =useState(null);
  const [deliverystatus, setDeliveryStatus]=useState(message.deliverystatus);
  const [updatedon,setUpdatedon] = useState(message.updatedon)
  const [opened, setOpened] = useState(message.opened);
  const [clicked, setclicked] = useState(message.clicked)

  // useEffect(()=>{
  //   console.log(message)
  // },[])

  const getToAddress =() =>{
    if(message.tablefrom ==null) {
      return message.systemreplyto;
    } else {
      return message.toaddress ===message.deliveryaddress || message.deliveryaddress===null ? message.toaddress : `${message.deliveryaddress} about ${message.toaddress}`;
    }
  }

  const ShowImageInWindows = (item) => {
    window.open(item.url);
  };

  const getBodyHeight =() =>{
    let removeHeight=0;
    if(message.cc) {
     removeHeight = removeHeight +30
    }
    if(message.bcc) {
     removeHeight = removeHeight +30;
    }
    const finalHeight =410 + removeHeight;
    return `calc(100vh - ${finalHeight}px)`
 }

 const getLatestStatus = async () =>{
  const data = await getEmailMessageStatus(message.messageid)
  setDeliveryStatus(data.deliverystatus)
  setOpened(data.opened)
  setclicked(data.clicked)
  setUpdatedon(data.updatedon)
 }

 const capitalizeFirstLetter= (word) => {
  if(word===null || word===undefined) {
    return 'Processing'
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

  return (
    <div>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          flexDirection: 'row',
          height: '40px',
          backgroundColor: color,
          color: 'white',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <div
          style={{
            marginLeft: '10px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiEmail} size="25px" />
          <div style={{ marginLeft: '5px', fontSize: '15px' }}>Full Email </div>
        </div>
        <TextButton
          text="Hide panel when done"
          Action={close}
          textColor="white"
          icon={mdiClose}
          hoverColor="#FFFFFF80"
        />
      </div>
      <div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px',}}
        >
          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>From:</div>
          <div>{message.tablefrom===null ? message.toaddress : message.fromaddress}</div>
          </div>

          <div style={{position:'absolute', top:50,right:20,display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
            <TextButton text="Refresh" icon={mdiRefresh} fontSize='17px' iconSize='17px' textColor='black' hoverColor="#00000080" Action={getLatestStatus} />
            <div style={{fontSize:'11px'}}>Status:  {capitalizeFirstLetter(deliverystatus)}</div>
            <div style={{fontSize:'11px'}}>Status last updated:  {moment(updatedon).format('DD-MMM-YYYY hh:mm a')} EST</div>
            <div style={{fontSize:'11px'}}>Last opened: {opened !==null ? moment(opened).format('DD-MMM-YYYY hh:mm a') : 'n/a'} EST</div>
            <div style={{fontSize:'11px',marginBottom:'10px'}}>Last clicked: {clicked !==null ? moment(clicked).format('DD-MMM-YYYY hh:mm a') : 'n/a'} EST</div>
            <div></div>
          {/* Show PDF Content links*/}
             {message.pdfcontent !==null ? (
              <div>
             {message.pdfcontent.map(itm=>(
              <div><TextButton textColor={color} hoverColor={`${color}80`} text={itm.name} Action={()=> setViewPdfContent(itm)} /></div>
             ))}
             </div>): null}
         </div>

          {viewPdfContent !==null ? (
                <div
                 style={{
                  position: 'fixed',
                 
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 100,
                  backgroundColor: 'white',
                  width: '70%',
                  height: 'calc(100vh - 300px)',
                  border: '1px solid #aaa',
                  boxShadow: '0px 3px 6px #707070',
                  borderRadius: '10px',
                }}
              > <div
              style={{
                display: 'flex',
                padding: '10px',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: color,
                color: 'white',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            >
              <div
                style={{
                  marginLeft: '10px',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Icon path={mdiFileDocument} size="25px" />
                <div style={{ marginLeft: '5px', fontSize: '15px' }}>Pdf content </div>
              </div>
              <TextButton
                text="Hide panel when done"
                Action={()=>setViewPdfContent(null)}
                textColor="white"
                icon={mdiClose}
                hoverColor="#FFFFFF80"
              />
            </div>
                  <div style={{margin:'10px', overflow:'auto', height: 'calc(100vh - 360px)'}} dangerouslySetInnerHTML={{ __html:viewPdfContent.value }} />
              </div>
            ):null}

        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
        >
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>To:</div>
          <div>{getToAddress()}</div>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
        >
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>From name:</div>
          <div>{message.fromname}</div>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
        >
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>Reply to:</div>
          <div>{message.replyto}</div>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
        >
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>Subject:</div>
          <div>{message.subject}</div>
        </div>
        {message.cc ? (
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
          >
            <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>CC:</div>
            <div>{message.cc}</div>
          </div>
        ) : null}
        {message.bcc ? (
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
          >
            <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>BCC:</div>
            <div>{message.bcc}</div>
          </div>
        ) : null}

        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}
        >
          <div style={{ textAlign: 'right', width: '100px', marginRight: '5px' }}>
           {message.schedule !==null ? 'Scheduled for:' : 'Sent on:'}
          </div>
          <div>
            {moment(message.schedule !== null ? message.schedule : message.addedon).format(tformat)}
          </div>
        </div>
    
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={{ textAlign: 'right', minWidth: '100px', marginRight: '5px' }}>Body:</div>
            <div style={{width:'100%', margin:'10px',overflow:'auto',height:getBodyHeight()}} dangerouslySetInnerHTML={{ __html: message.message }}></div>
        </div>
          {message.files && message.files.length>0 ? (
            <div style={{paddingLeft:'100px',borderTop:'1px solid #eee',display:'flex',flexDirection:'row',alignItems:'center'}}>
             <ImageThumbnails
             items={message.files}
             maxWidth="35px"
             Action={ShowImageInWindows}
             showDeleteIcon={false}
           /></div>
          ): null}

      </div>
    </div>
  );
};

export default ViewFullEmail;
