import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MDIIcon from '@mdi/react';
import './styles.scss';

const Icon = ({ inline, className: cls, ...props }) => {
  const className = classNames('mdn-icon', cls, { 'mdn-icon-inline': inline });
  return <MDIIcon {...props} className={className} />;
};

Icon.defaultProps = {
  size: 1,
  color: null,
  className: null,
  inline: false,
};

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

export default Icon;
