import MESSAGES from './messages';

export default function minLength(length) {
  const message = MESSAGES.MIN_LENGTH.replace('{correctLength}', length);
  return (value) => {
    if (length && value && value.length < length) {
      return message.replace('{length}', value.length);
    }
    return undefined;
  };
}
