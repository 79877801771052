/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

export const getUsersAPI = async (filters=[],includeAnonymous=false,format="raw",definedUsers) => {
  //8-18-22 added "format" attribute in attempt to provide way of returning data in
  //standard formats used in app for either "raw" or "dropdown". 

  
  const payload={filters,includeAnonymous,dbfunction:'getZoneUsers',useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  let usersList=dataresponse[0].array;
  
  if(!includeAnonymous) {
    usersList =usersList.filter(usr=>!usr.email.includes('anonymous_'));
  }

  //1-27-23 Added definedUsers param. We have added new functionality that allows for defining specific users 
  //who should appear in the collaboration list. This will be passed in array of userid's: [15,223]
  if(definedUsers !==undefined && definedUsers !==null) {
    usersList = usersList.filter(usr=>definedUsers.includes(usr.userid));
  }

  const Users = usersList;
  if(format==='raw') {
    return Users
  } else if(format==='dropdown') {
    let dropdownItems=[];
    Users.map(usr =>{
      dropdownItems.push({key:usr.userid,value:usr.userid,text:`${usr.firstname} ${usr.lastname}`});
    })
    return dropdownItems;
  }
}

// 5-5-2022 Addding this method to allow for impersonate user feature. this will search all users
// across all zones and return matching records. 
export const findUserByEmail = async (email) =>{
  const payload={email}
  const dataresponse = await cloudrun.post('/findUserByEmail', {payload})
  return dataresponse;
}

export const impersonate = async (fbuserid) =>{
  const payload={fbuserid}
  const dataresponse = await cloudrun.post('/impersonateUser', {payload})
  return dataresponse;

}

//6-9-23 test method in researching sending workflow email, and using formula field
//for email body contents. This is just testing..not actual functionality at this point.
export const sendEmailTest = async() =>{
  const payload={id:177}
  const dataresponse = await cloudrun.post('/sendEmailTest', {payload})
  return dataresponse;
}

export const getActiveZoneUsers = async () => {
  const payload={dbfunction:'getActiveZoneUsers',useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const Users = dataresponse[0].array
  return Users
}


export const getBlockUsersAPI = async (blockid,inBlock=true,includeAnonymous=false,format='raw') => {
 
  const payload={blockid, inBlock,includeAnonymous}
  //BlockUsrsList returns reduced number of fields for BlockUsrs.
  const result = await cloudrun.post('/getBlockUsersList', {payload})
  let usersList=result;
  if(!includeAnonymous) {
    usersList =result.filter(usr=>!usr.email.includes('anonymous_'));
  }

  if(format==='raw') {
    return usersList
  }  else if(format==='dropdown') {
    let dropdownItems=[];
    usersList.map(usr =>{
      dropdownItems.push({key:usr.userid,value:usr.userid,text:`${usr.firstname} ${usr.lastname}`});
    })
    return dropdownItems;
  }

}


// userType is either "addedby" or "updatedby".
// This query returns distinct list of users who either added or updated rows in a given table.
export const getTableUsersAPI = async (schema, tablename, uitype) => {

  const field = uitype===24 ? 'addedby' : 'updatedby'
  const payload={schema,table:tablename,field,dbfunction:'getTableUsers'}
  const result = await cloudrun.post('/withUsers', {payload})
  return result
}

// remove user from a given block. 
export const RemoveUserFromBlock = async (blockid,userid) => {

  const payload={blockid,userid, dbfunction:'deleteBlockUser'}
  await cloudrun.post('/executeQueryAPI', {payload})
  return "success"  
}

export const UpdateUserStatus = async (userid,status,updatedby) => {
  
  const payload={userid,status,updatedby}
  await cloudrun.post('/updateUserStatus', {payload})
  return "success"  
}

//9-19-22 added new method for new "Add Users" button which is used to add new users to
//system without using Grid. Here they are able to paste string of email addresses and then
//check if they want to send invitations after adding.
export const addNewUsers = async (emailAddresses,blockRoles,sendInvitations) =>{
  const payload={emailAddresses,blockRoles,sendInvitations}
  const dataresponse = await cloudrun.post('/addNewUsers', {payload});
  return dataresponse
}

// 9-19-22 new method for "Add Users" from within Block.
export const addBlockUsers = async (userIds,blockid,role,sendInvitations) =>{
  const payload={userIds,blockid,role,sendInvitations}
  const dataresponse = await cloudrun.post('/addBlockUsers', {payload});
  return dataresponse
}

export const GetUserAPI = async (userid) => {
  
  const payload={userid,dbfunction:'getUserAPI', useCentral:true}
  const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
  const res = dataresponse[0].root[0]
  return res
}

export const DeleteUser= async (userid, zoneid) => {
   
  const user = await GetUserAPI(userid)

  // console.log(zoneid)
  // console.log(user)
  // See if user has been Active or Inactive for this zone. If the zoneidx !==-1, this is the ase
  // The GetUSerAPI filters ZoneUsers on status=Active OR Inactive.

  const zoneidx = user.ZoneUsers.findIndex(el=>el.zoneid===zoneid)
 
  // if user status is still pending, delete User record And ZoneUser record and BlockUser records
  if(user.status==='pending') {
    let payload={userid,dbfunction:'deletePendingUser', useCentral:true}
    await cloudrun.post('/executeQueryAPI', {payload})
    
    payload={userid,dbfunction:'deleteBlockUser' }
    await cloudrun.post('/executeQueryAPI', {payload})
  } 
  else if(zoneidx !==-1) //They are either Active/Inactive in Zone, cannot delete tehm.
  {
    return "NoUserRemoval"
  } else {
      // else just remove zoneusers record  && blockusers
      let payload={userid,dbfunction:'deleteZoneUser', useCentral:true}
      await cloudrun.post('/executeQueryAPI', {payload})

      //7-7-2022 added removing the user from blockusers..as once they are removed from zone, 
      //must also be removed from any blocks. 
      payload={userid,dbfunction:'deleteBlockUser' }
      await cloudrun.post('/executeQueryAPI', {payload})
  }

  return "success"
}

export const getUserSystemAttributes = async () => {
  const payload={dbfunction:'getUserSystemAttributes', useCentral:true}
  const dataresponse  = await cloudrun.post('/executeQueryAPI', {payload})
  return dataresponse[0];
}

export const updateUserSystemAttributesAPI =  async (userid, attributes) => {

  const payload={userid,attributes, dbfunction:'updateUserSystemAttributes', useCentral:true}
  await cloudrun.post('/executeQueryAPI', {payload})
  return "success";
};

export const updateUserInfo = async (userid,items) =>{

  let payload={userid,items,dbfunction:'updateUserInfo',useCentral:true}
  await cloudrun.post('/executeQueryAPI',{payload})

  // 9-5-23 After a user updates their info, we need to clear the user cache, so the UI sees updated info.
  //I'm calling this updateUserStatus, just resetting the user status to Active since it has the code to
  //clear the usercache. 
  payload={userid,status:'Active',updatedby:userid}
  await cloudrun.post('/updateUserStatus',{payload});

}

export const updateUserAttributes= async (attributes) =>{

  const payload={attributes,dbfunction:'updateUserAttributes',useCentral:true}
  await cloudrun.post('/executeQueryAPI',{payload})

}

export const disableMultiFactor = async () =>{
  //12-6-22 don't need payload, as logic can use claims info
  //and query just deletes multiFactor attribute from users.attribute json.
  const payload={att:null}
  await cloudrun.post('/disableMultiFactor',{payload})

}


export const getUserByEmail = async (email) => {

  try{
  const payload={email,dbfunction:'getUserByEmail', useCentral:true}
  const dbuser = await cloudrun.post('/executeQueryAPI', {payload})
  return dbuser[0].root;
  } catch(error) {
    return error.message
  }

}
