import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from '@reach/router';
import SegmentInfoPage from './SegmentInfo';
import PhoneValidatorPage from './PhoneValidator';
import ZoneSetupPage from './ZoneSetup';
import { UserContext } from '../../providers/UserProvider';

const STEPS = ['segment', 'phone', 'zone', 'complete'];

const AuthSteps = () => {
  const { user, userInfo } = useContext(UserContext);
  const steps = useMemo(() => {
    const data = [...STEPS];
    // if (user.phoneNumber) {
    //   data.splice(STEPS.indexOf('phone'), 1);
    // }
    return data;
  }, []);

  const [step, updateStep] = useState(STEPS[0]);
  const [data, updateData] = useState({
    segment: null,
    phone: user.phoneNumber ? { phoneNumber: user.phoneNumber } : null,
    zone: null,
  });

  const handleChange = useCallback(
    (key, value) => {
      updateData((prev) => ({ ...prev, [key]: value }));
      updateStep((prev) => {
        const nextIndex = steps.indexOf(prev) + 1;
        updateStep(steps[nextIndex]);
      });
    },
    [updateData, updateStep, steps],
  );

  const handleBack = useCallback(
    (key) => {
      updateData((prev) => ({ ...prev, [key]: null }));
      updateStep((prev) => {
        const prevIndex = steps.indexOf(prev) - 1;
        updateStep(steps[prevIndex]);
      });
    },
    [steps, updateData, updateStep],
  );

  useEffect(() => {}, [data]);

  if (step === 'segment') {
    return <SegmentInfoPage user={user} onChange={handleChange} initialValues={data.segment} onBack={handleBack} />;
  }
  if (step === 'phone') {
    return (
      <PhoneValidatorPage
        user={user}
        onChange={handleChange}
        onBack={handleBack}
        initialValues={data.phone}
      />
    );
  }
  if (step === 'zone') {
    return (
      <ZoneSetupPage
        user={user}
        userInfo={userInfo}
        onChange={handleChange}
        onBack={handleBack}
        initialValues={data.zone}
        userInfoValues={{ ...data.phone, ...data.segment }}
      />
    );
  }
  return <Redirect to="/" noThrow />;
};

export default AuthSteps;
