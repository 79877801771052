/*
SUMMARY: mph 4-11-20
This file contains a number of methods used to retrieve and insert option lists of data as well 
as a simple query for a relation field. 
The relation method GetRelationRecords is used when a user clicks to "Create new record" from the Relation pop-up form.
Instead of rendering whole relational component, we use the existing GBDropdown component and retrieve the relational records
instead of the options records.

getOptionsAPI is used to retrieve list of options from given options table.
updateOptionsAPI is used to update options list.

PARAMS
getOptionsAPI: [query: string]: This is the final graph ql used to retrieve the options. Note that for option fields
defined tableinfo, the "source" column contains the actual graphql that is used to pass to this method.


*/
/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';
import {getTableAPI,getRelationalTableData} from '../api/tables'
import {getBookmarkByName,getBookmark} from '../api/bookmarks'

export const getOptionsAPI = async (field,tableid,format='list') => {
    /* 8-18-22 I want to have standardized formats for list data, so adding a new "format" paramter
    on this method, as well as getRelationalTableData method in tables.js. two possible values here
    1. dropdown, 2 = list, 
    The dropdown format will format for use with semantic dropdown list control ({key,value,text})
    The list format will be {optionid,option,rank,attributes}

    in getRelationalTableData, it will have 3 formats of dropdown, list, raw (table data )
    */

    const payload={tableid,field,dbfunction:'getOptionsApi'}
    const dbresponse = await cloudrun.post('/executeQueryAPI', {payload})
    const res=dbresponse[0].root

    const Listitems = [];
    if(format==='list') {
      res.forEach(itm => {
          Listitems.push({ "optionid": itm.optionid, "option": itm.option, "rank": itm.rank, "attributes": itm.attributes })
      })
    } else if(format==='dropdown') {
        res.forEach(itm => {
          Listitems.push({ "key": itm.optionid.toString(), "value": itm.optionid, "text":itm.option});
      })
    }

    return Listitems;
  }

  // PARAMS
  // [schema: string]: the current users schema/zone . Retrieved from the AccountProvider context.
  // [tableid: integer]: The relational tableid to retrieve the records from
  // [filter: string]: In the GBDropdown, a user can search/filter on the records, this is value passed in.

  export const getRelationRecords = async (source,filter,ids=null) => {

    const payload={source,filter,ids,dbfunction:'getRelationRecords'}
    const response = await cloudrun.post('/executeQueryAPI', {payload})
    const res=response[0].json_agg

    const Listitems = [];
    if(res !==null) {
    res.forEach(itm => {
        Listitems.push({ "optionid": itm.id.toString(), "option":  itm.fullname  })
    })
  }
     return Listitems;
  }

 export const getRelationalRecordsViaTableQuery= async (colInfo,tableid,blockid,id,role,filter='') => {

  let filters=[];
  
  // if gbdropdowncomponent in detailview, it will have an id. However, if adding new Record, no id will exist.
  if(id !==undefined){
    filters.push(
      {
        field: 'id',
        header: 'id',
        type: 'relation', // indicates if id is relational lookup filter or "table" to search by id on table.
        displayfield: 'id',
        sourcefield: colInfo.data,
        selectmode: colInfo.lookup,
        renderlookup: colInfo.source.lookup,
        operator: 'not',
        value: id,
        uitype: 100, // set to non system number. Used in buildSqlQuery to process the id filter.
        lookupfield: `${colInfo.source.returnfield}_join`,
        originalTableId: tableid,
      }
    )
  }

  if(filter!==''){
    filters.push({
      field: 'id',
      sourcetableid: tableid,
      header: 'field_1',
      value: filter,
      operator: 'contains',
      uitype: 101
    })
  }

  if(colInfo.useBookmark !==undefined && colInfo.useBookmark===true && colInfo.bookmarkName !==undefined){
    const bm = await getBookmark(colInfo.bookmarkName)
    if(bm.length>0 && bm[0].tabsettings.filters !==undefined){
      filters = filters.concat(bm[0].tabsettings.filters)

      const tinfo = await getTableAPI(colInfo.source.lookuptableid);
      let payload={
        tableinfo:tinfo.tableinfo,
        tableid: colInfo.source.lookuptableid,
        blockid:blockid,
        selectMode:'single',
        limit:50,
        role:role,
        offset:0,
        filters,
        sortfield:'custom.fullname',
        sortorder:'asc'}
    
        const tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });
        const shapedData = tabledata.rows;
        
        const listItems = [];
        if(shapedData !==null && shapedData.length>0) {
          shapedData.forEach(itm => {
            listItems.push({ "optionid": itm.id.toString(), "option":  itm.CustomID  })
        })
      }
       return listItems
    } else {
      return await getRelationRecords(colInfo.source,filter)
    }
  } else {
    const opts= await getRelationRecords(colInfo.source,filter);
    return opts
  }

 }

  /*
  PARAMS
    [items: array of options] - The array of option objects ({optionid, option, attributes{color, text}})
    [deletedItems: array of options to delete]
    [fieldname: string]: The field from tableinfo.columns of which we are updating (ex. field_22)
    [tableid: integer]: the tableid of which the options are being added
    [olduitype: integer]: This method is called from defining/updating the field type in grid. 
    This olduitype is the original/starting ui type. If a user is just converting a textfield to single-select, the olduitype =2
    This is used to determine whether we are creating a NEW select table or are simply updating an existing select field
    [uitype:integer]: the currently selected uitype. Note that once you change a field to a single select, the option
    to change is removed. Therefore going forward, the olduitype and uitype will always be the same (6 or 7)
    [schema: string]: the current users schema, as obtianed from AccountProvider context. 
  */
  export const updateOptionsAPI = async (items,deletedItems,fieldname,tableid,olduitype,uitype) => {

    if(olduitype !== 6 && olduitype !==7) {
        //  Need to create new option tables (lookup & join), register field as Option Table.
        const lookupType = uitype===6 ? 'multiple' : 'single';
        const payload = {tableid, fieldname, lookupType};
        const response = await cloudrun
        .post('/addOptionField', { payload } )
    }

    const payload={items,deletedItems,fieldname,tableid,dbfunction:'updateOptionsAPI'}
    const response = await cloudrun.post('/executeQueryAPI', {payload})

  }

  export const updateAttachmentsAPI = async (items,deletedItems,fieldname,tableid,olduitype,uitype,lookupType) => {

    if(olduitype !== 4) {
        const payload = {tableid, fieldname, lookupType};
        const response = await cloudrun
        .post('/addAttachmentField', { payload } )
    }

    const payload={items,deletedItems,fieldname,uitype,tableid,dbfunction:'updateOptionsAPI'}
    const response = await cloudrun.post('/executeQueryAPI', {payload})

  }