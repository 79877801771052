/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useContext,useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Icon from '@mdi/react';
import { Popup,Label } from 'semantic-ui-react';
import {
  mdiBell,
  mdiCog,
  mdiHelpCircleOutline,
  mdiPower,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiContentCopy,
  mdiApi,
  mdiArrowDecision
} from '@mdi/js';
import { AccountContext } from '../../../../providers/AccountProvider';
import { GetUsersTableForBlock } from '../../../../api/blocks';
import SystemWarning from '../../../../components/SystemWarning/SystemWarning';
import {getNotificationCount, getNotifications,deleteNotification} from '../../../../api/notifications';
import { useInterval } from '../../../../utils/useInterval';
import Notification from '../notifications/notification';
import TextButton from '../../../../components/TextButton/TextButton'




const TopNavBar = ({
  icon,
  color,
  name,
  role,
  statement,
  RELOAD,
  blockid,
  showBlockEditorHandler,
  toggleUserProfile,
  toggleNotifications,
  toggleDuplicateBlock,
  showNotifications,
  isMaxRowsExceeded,
  isPublicZoneBlock,
  isSystemBlock,
  ShowConfettiModal,
  userid,
}) => {
  const { signOut,onmessage,ws } = useContext(AccountContext);
  const [notificationCount,setNotificationCount] = useState(0);
  const [maxId, setMaxId] = useState(0);
  const [plan, setPlan]=useState('Free plan');
  const apiUrl = process.env.REACT_APP_API_CLOUDRUN_URL;
 

  useEffect(()=>{
    getCounts();  
  },[showNotifications])

  useEffect(()=>{
    const planSize = name.split('*').length -1;
    if(planSize===1) setPlan('Starter plan')
    if(planSize===2) setPlan('Pro plan')

  },[name])

  useEffect(() =>{

    const handleMessage = (message) => {
      ShowConfettiModal([message.data]);
    };

    if (ws) {
      ws.onmessage = handleMessage;
    }
  
    // Cleanup: Unregister the handler when the component unmounts or 'ws' changes
    return () => {
      if (ws) {
        ws.onmessage = null; // Remove the handler
      }
    };

  },[ws,onmessage])


  useInterval(
    async () => {
        await updateCounts();
    },
    // 10000
     350000,
  );


  const updateCounts = async () => {
    const result = await getNotificationCount(blockid);
    let totalSum=0;
    if(Array.isArray(result)) {
      totalSum = result.reduce((sum, item) => sum + item.count, 0);
    }

    if(totalSum >notificationCount){

      /*  7-24-24. This count will get all notifications, including any "confetti" notifications
      which are type=5. The idea is that increased count will trigger both processing confetti
      as well as normal notifications.

      we wil change logic from using the count from getnotificationcount, to instead using notifcations
      object and filtering where type !=5. That repreents actual count of "normal" notifications. 
      Then any where type=5 will be used to show the confetti message.
      */

      //get latest notifications.
      const notices = await getNotifications(blockid,true);
      const regularNotifications = notices.filter(fl=>fl.type !== 5);
      const confettiMessages = notices.filter(fl=>fl.type===5);

      //update count and maxid
      setNotificationCount(regularNotifications.length)
     
      //# of new notificatoins
      const newNotifications = regularNotifications.length-notificationCount;
      
      //for Confetti Messages, we auto-delete them, and then call method to show the messages.
      if(confettiMessages.length>0) {  
        for(const cm of confettiMessages) {
          await deleteNotification(cm.notificationid,blockid,false);
        }

        ShowConfettiModal(confettiMessages.map(el=>el.htmlmessage));
      }
      

      let message='';
      if(newNotifications===1) {
        toast.info( <div style={{margin:'10px'}} dangerouslySetInnerHTML={{ __html: notices[0].htmlmessage }} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 12000,
        })
      } else if(newNotifications.length>1) {
        toast.info(<div>
           <div style={{margin:'10px'}} dangerouslySetInnerHTML={{ __html: notices[0].htmlmessage }} />
          <TextButton Action={toggleNotifications} text={`and ${newNotifications-1} more notifications`} textColor="black" />
        </div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 12000,
        })
      }
    }
  }

  const getCounts = async ()=> {
    const result = await getNotificationCount(blockid);
    let normalCount =0;
    let max = 0;

    result.map(itm=>{
      if(itm.type !==5) {
        normalCount=normalCount+parseInt(itm.count)
        if(itm.maxid > max) {
          max = itm.maxid
        }
      }
    })
    // const regularNotifications = notices.filter(fl=>fl.type !== 5);

    setNotificationCount(normalCount);
    setMaxId(max)
  }

  const warnings=[{message:'Block over plan limits', tooltip:'The power to solve is even better with an upgrade! This Block has exceeded this plan\'s set limits for disk space  and/or rows of data. If it has not already happened, soon your users will not be able to edit data, add more records, or add attachments in this Block.'}];

  const handleSignout = () => {
    signOut();
  };
  

  const LoadUsersTable = async () => {
    // Get custom tableid for this given block.
    const tableid = await GetUsersTableForBlock(blockid);
    RELOAD(tableid, 'Users', mdiAccountGroup);
  };

  const LaunchAPIDocs = () =>{
    const { REACT_APP_SITE_URL } = process.env;
    window.open(`${REACT_APP_SITE_URL}/apidocs/${blockid}`);
  }

  const LaunchWorkflow = () =>{
    const { REACT_APP_SITE_URL } = process.env;
    window.open(`${REACT_APP_SITE_URL}/workflow/${blockid}`);
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position:'relative',
        justifyContent: 'space-between',
        backgroundColor: color,
        height: '40px',
        color: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '5px',
          marginLeft: '20px',
          fontSize: '20px',
          overflow:'hidden'
        }}
      >
        <Icon path={icon} size="30px" color="white" />

        <div title={name} style={{marginLeft: '10px', marginRight: '20px',overflow:'hidden',whiteSpace:'nowrap', }}>{name}</div>
        
          <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
          {role === 3 ? (<>
            <div
              css={{
                marginLeft: '20px',
                marginRight:'20px',
                cursor: 'pointer',
                alignSelf: 'center',
                '&:hover': {
                  svg: {
                    transition: 'all .3s ease',
                    color: '#FFFFFF80',
                  },
                },
              }}
            >
              <Popup
                mouseEnterDelay={200}
                color="red"
                // position="bottom right"
                mouseLeaveDelay={500}
                content="Update Block"
                trigger={
                  <Icon
                    onClick={() => showBlockEditorHandler()}
                    path={mdiCog}
                    size="20px"
                    color="white"
                  />
                }
              />
            </div>
            {role === 3 && !isSystemBlock? (<div
              css={{
                marginRight: '20px',
                cursor: 'pointer',
                alignSelf: 'center',
                '&:hover': {
                  svg: {
                    transition: 'all .3s ease',
                    color: '#FFFFFF80',
                  },
                },
              }}
              onClick={LaunchWorkflow}
            >
              {' '}
              <Popup
                mouseEnterDelay={200}
                color="red"
                mouseLeaveDelay={500}
                content="Manage workflows for this block"
                trigger={<Icon path={mdiArrowDecision} size="20px" />}
              />
            </div>) : null}
            <div
              css={{
                marginRight: '20px',
                cursor: 'pointer',
                alignSelf: 'center',
                '&:hover': {
                  svg: {
                    transition: 'all .3s ease',
                    color: '#FFFFFF80',
                  },
                },
              }}
              onClick={LoadUsersTable}
              onKeyPress={LoadUsersTable}
            >
              {' '}
              <Popup
                mouseEnterDelay={200}
                color="red"
                mouseLeaveDelay={500}
                content="Users for this Block"
                trigger={<Icon path={mdiAccountGroup} size="20px" />}
              />
            </div>
            </>) : null}
          
            {(role === 3 || isPublicZoneBlock) && !isSystemBlock ? (
            <div
              css={{
                marginRight: '20px',
                cursor: 'pointer',
                alignSelf: 'center',
                '&:hover': {
                  svg: {
                    transition: 'all .3s ease',
                    color: '#FFFFFF80',
                  },
                },
              }}
              onClick={toggleDuplicateBlock}
              onKeyPress={toggleDuplicateBlock}
            >
              {' '}
              <Popup
                mouseEnterDelay={200}
                color="red"
                mouseLeaveDelay={500}
                content="Duplicate Block"
                trigger={
                <Icon path={mdiContentCopy} size="20px" />
              }
              />
              {isPublicZoneBlock ? (<>
                <span style={{fontSize:'13px',marginLeft:'10px'}}>USE THIS PUBLIC BLOCK</span>
              </>): null}
            </div>
            ) : null}
             {role === 3 && !isSystemBlock ? (<>
            <div
              css={{
                marginRight: '20px',
                cursor: 'pointer',
                alignSelf: 'center',
                '&:hover': {
                  svg: {
                    transition: 'all .3s ease',
                    color: '#FFFFFF80',
                  },
                },
              }}
              onClick={LaunchAPIDocs}
              onKeyPress={LaunchAPIDocs}
            >
              {' '}
              <Popup
                mouseEnterDelay={200}
                color="red"
                mouseLeaveDelay={500}
                content="API documentation for this Block"
                trigger={<Icon path={mdiApi} size="25px" />}
              />
            </div>
           
             </>) : null}
          </div>
       
      </div>

      {isMaxRowsExceeded ? (
        <SystemWarning warnings={warnings} />
          ): null}

      <div style={{ display: 'flex', flexDirection: 'row', margin: '5px', marginRight: '30px' }}>
        {statement ? (
          <div
            style={{
              padding: '20px',
              width: '500px',
              color: 'white',
              backgroundColor: '#ffffff40',
              alignSelf: 'center',
              marginRight: '40px',
            }}
          >
            {statement}
          </div>
        ) : null}
        {isPublicZoneBlock ? (
            <span style={{border:'1px solid white', padding:'5px', fontSize:'13px',marginRight:'50px',alignSelf:'center'}}><b>{plan}</b></span>
        ): null}
        <div
          css={{
            marginRight: '25px',
            alignSelf: 'center',
            cursor: 'pointer',
            color: 'white',
            '&:hover': {
              svg: {
                transition: 'all .3s ease',
                color: '#FFFFFF80',
              },
            },
          }}
        >
          <Popup
            content="My profile"
            mouseEnterDelay={200}
            mouseLeaveDelay={500}
            trigger={<Icon onClick={toggleUserProfile} path={mdiAccountCircle} size="25px" />}
          />
        </div>
        <div
          css={{
            position:'relative',
            zIndex:0,
            marginRight: '25px',
            alignSelf: 'center',
            cursor: 'pointer',
            color: 'white',
            '&:hover': {
              svg: {
                transition: 'all .3s ease',
                color: '#FFFFFF80',
              },
            },
          }}
        >
          <Popup
            mouseEnterDelay={200}
            mouseLeaveDelay={500}
            content="Notifications"
            trigger={
            <Icon onClick={toggleNotifications} path={mdiBell} size="25px" />
            // <Icon onClick={()=>showConfettiMessage('Congrats')} path={mdiBell} size="25px" />
            }
          />
          {notificationCount>0 ? <Label style={{transform:'translateY(5px)',userSelect:'none'}} position="relative" top="10px" onClick={toggleNotifications} color="red" size="mini"  floating>{notificationCount}</Label>: null }
        </div>
        <div
          css={{
            marginRight: '25px',
            alignSelf: 'center',
            cursor: 'pointer',
            '&:hover': {
              svg: {
                transition: 'all .3s ease',
                color: '#FFFFFF80',
              },
            },
          }}
        >
          {' '}
          <Popup
            mouseEnterDelay={200}
            color="red"
            mouseLeaveDelay={500}
            content="Get help"
            trigger={
              <Icon
                onClick={() => window.open('https://www.graceblocks.com/bots/kayla', '_blank')}
                path={mdiHelpCircleOutline}
                size="25px"
              />
            }
          />
        </div>
        <div
          css={{
            marginRight: '25px',
            alignSelf: 'center',
            cursor: 'pointer',
            '&:hover': {
              svg: {
                transition: 'all .3s ease',
                color: '#FFFFFF80',
              },
            },
          }}
          onClick={handleSignout}
          onKeyPress={handleSignout}
          role="button"
          tabIndex="-1"
        >
          <Popup
            mouseEnterDelay={200}
            mouseLeaveDelay={500}
            content="Sign out"
            trigger={<Icon path={mdiPower} size="30px" />}
          />
        </div>
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TopNavBar;
