/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getTableAPI, getTableNames } from '../../api/tables';
import axios from 'axios';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { mdiBroom } from '@mdi/js';
import { getOptionsAPI } from '../../api/options';
import { getIdToken } from '../../clients/firebase';
import Fields from './table/fields';
import Spinner from '../../components/Spinner/Spinner';
import ListRecords from './table/listRecords';
import GetRecord from './table/getRecord';
import CreateRecords from './table/createRecords';
import UpdateRecords from './table/updateRecords';
import DeleteRecords from './table/deleteRecords';
import GBButton from '../../components/GBButton/GBButton'
import {clearCacheByRequest} from '../../api/GetData'

const TableApi = ({ tableId, subKey, block, blockKey,color }) => {
  const [tableData, setTableData] = useState(null);
  const [tableOptions, setTableOptions] = useState([]);
  const [relationalTableNames, setRelationalTableNames] = useState([]);
  const [records, setRecords] = useState([]);
  const [listResponse, setListResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (subKey !== null && records&& records.length>0) {
      const divElement = document.getElementById(subKey);
      divElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [subKey]);

  useEffect(() => {
    setTableData(null);
    if(tableId!==null){
      loadData();
    }

  }, [tableId]);

  const loadData = async () => {
    
    setIsLoading(true);
    const tblData = await getTableAPI(tableId);
    
    let AllOptions = [];
    let relationTables = [];

    let tableids = [];
    tblData.tableinfo.columns
      .filter((col) => col.uitype === 1)
      .map((el) => {
        tableids.push(el.source.lookuptableid);
      });

    const loadOptions = async () => {
      return Promise.all(
        tblData.tableinfo.columns
          .filter((el) => el.uitype === 6 || el.uitype === 7)
          .map(async (col) => {
            const fieldOptions = await getOptionsAPI(col.data, tblData.id);
            AllOptions.push({ field: col.data, data: fieldOptions });
          }),
      );
    };
    await loadOptions();


    //Get first 3 records from the External API
    const token = await getIdToken();
    const { REACT_APP_API_URL } = process.env;
    const url = `${REACT_APP_API_URL}/v1/${blockKey}/${tblData.name}?limit=10&fromDocs=true&sortOrder=asc`;
    const data = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const tableRecords = data.data.records.slice(0,3)

    data.data.records = data.data.records.slice(0,3);

    //If the table has relational fields, lookup the name of the relational table, as
    //we need to reference this in the Fields section when describing the relational field.
    if (tableids.length > 0) {
      const ids = tableids.map((el) => el).join();
      relationTables = await getTableNames(ids);
    }

    setTableOptions(AllOptions);
    setListResponse(data.data);
    setRecords(tableRecords);   
    setRelationalTableNames(relationTables);
    setTableData(tblData);
    setIsLoading(false);
  };

  const clearCache =async () =>{
    await clearCacheByRequest(tableId);
    toast.info('The cache has been cleard!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose:3000,
    })
  }


  return (
    <div style={{ flexGrow: 1 }}>
      {tableData && records && records.length>0 && !isLoading ? (
        <div style={{margin:'10px'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} id="fields">
            <Icon path={tableData.icon} size="40px" color={block.color} />
            <span style={{ fontSize: '20px', color: block.color, marginLeft: '5px' }}>
              {tableData.name}
            </span>
          </div>
          <div style={{marginRight:'40px'}}>
            <GBButton 
              Action={clearCache}
              height="35px"
              icon={mdiBroom} 
              iconSize='25px'
              iconColor='white'
              iconPosition='left' 
              text='Clear cache'
              textColor='white'
              iconHoverColor="#FFFFFF"
              textHoverColor='#FFFFFF'
              color={color} />
          </div>
          </div>

          <div>
            Review the information below to explore interacting with the data on the{' '}
            <b>{tableData.name}</b> tab inside the <b>{block.name}</b> Block.
          </div>
          <div>
          The table id for this table is <span style={{backgroundColor:`${block.color}1A`,padding:'4px',borderRadius:'10px'}}>{tableData.internalname}</span>.
          </div>

          <div style={{ paddingTop: '50px', paddingLeft: '40px' }}>
            <Fields
              tableData={tableData}
              block={block}
              records={records}
              tableOptions={tableOptions}
              relationTables={relationalTableNames}
            />
            <ListRecords  
              tableData={tableData}
              block={block}
              blockKey={blockKey}
              response={listResponse} />
            <GetRecord
              tableData={tableData}
              block={block}
              blockKey={blockKey}
              records={records} />
            <CreateRecords
            tableData={tableData}
            block={block}
            blockKey={blockKey}
            records={records} 
            relationalTableNames={relationalTableNames}
            />
             <UpdateRecords
            tableData={tableData}
            block={block}
            blockKey={blockKey}
            records={records} 
            relationalTableNames={relationalTableNames}
            />
             <DeleteRecords
            tableData={tableData}
            block={block}
            blockKey={blockKey}
            records={records} 
            relationalTableNames={relationalTableNames}
            />
            <div style={{marginBottom:'800px'}}></div>
          </div>
        </div>
      ) : !isLoading && !records ? <div style={{margin:'20px',fontSize:'16px'}}>Add at least one row of data to tab in order to review documentation.</div> : <Spinner isLoading color={color} showMessages={true} />}

    </div>
  );
};

export default TableApi;
