/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import {getRegions} from '../../../../components/Form/Controls/PhoneNumber/phoneUtils';
import PNL from 'google-libphonenumber';
import Callout from './Callout';
import http from '../../../../clients/http';
import cloudrun from '../../../../clients/httpcloudrun';


class PhoneField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo, selectedUIType } = this.props

    this.state = {
      showAdvanced: false,
      maxlength: columnConfigData.maxlength,
      callout: columnConfigData.callout,
      uitype: selectedUIType,
      isMobile: columnConfigData.isMobile !== undefined ? columnConfigData.isMobile : true,
      defaultCountry: columnConfigData.defaultCountry !== undefined ? columnConfigData.defaultCountry : 'US',
      tableinfo,
      regions: [],
      isPrivate:columnConfigData.isPrivate ?? false
    };
  }

  static contextType = AccountContext;

  componentDidMount() {

    const onlyCountryCodes = [
      'AU',
      'AT',
      'BD',
      'CA',
      'CL',
      'CZ',
      'DK',
      'EE',
      'FR',
      'GT',
      'IL',
      'HK',
      'HU',
      'LV',
      'LT',
      'MY',
      'MX',
      'NL',
      'PH',
      'PL',
      'PT',
      'PR',
      'SG',
      'SK',
      'ZA',
      'KR',
      'CH',
      'ES',
      'TW',
      'GB',
      'US',
    ];

    const regions =getRegions({onlyCountryCodes,favoriteCountryCodes: ['US']})
    const dropdownValues=[];
    regions.forEach(region => {
      dropdownValues.push({key:region.id,value:region.region,text:`(${region.region}) +${region.iso2}`})
    })
    this.setState({regions:dropdownValues})

    // const phoneUtil = PNL.PhoneNumberUtil.getInstance();
    // onlyCountryCodes.forEach(reg=>{
    //   const iso2 = phoneUtil.getCountryCodeForRegion(reg);
    //   const phoneExample =phoneUtil.getExampleNumber(reg);
    //   // console.log(`${reg} - ${phoneUtil.getCountryCodeForRegion(reg)} - ${phoneUtil.getExampleNumber(reg)}`)
    //   const internationalExample = phoneUtil.format(phoneExample,PNL.PhoneNumberFormat.INTERNATIONAL);
    //   const localNumberExample = internationalExample.replace(`+${iso2}`, '').replace(/^\s+|\s+$/g, '');
    //   const numMask =localNumberExample.replace(/\d/g, '9');
    //   console.log(numMask)
    // })
    // const iso2 = phoneUtil.getCountryCodeForRegion(region);
    // const phoneExample = phoneUtil.getExampleNumber(region);

  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
    const { maxlength, callout, defaultText, tableinfo, uitype,defaultCountry,isMobile,isPrivate } = this.state;
    const { field, tableid, header, selectedUIType,columnConfigData } = this.props;
    const originalUIType = columnConfigData.uitype;

    try {

    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);

    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "header": "${header}",
      "uitype": ${uitype},
      "displayfield": "${field}",
      "detailviewsort": ${columnConfigData.detailviewsort !==undefined ? columnConfigData.detailviewsort : rowindex}
  }`

    const updatedNode = JSON.parse(defaultNode)

    // Update Tableinfo with new settings.

    if (rowindex !== -1) {
     
      updatedNode.defaultCountry=defaultCountry;
      updatedNode.isMobile=isMobile;
      updatedNode.callout=callout;
      updatedNode.defaultText=defaultText;
      updatedNode.editor='PhoneEditor';
      tableinfo.columns[rowindex]=updatedNode;

      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }

      // if we are coming from numeric field OR an ID field, convert numeric back to text.
      if(originalUIType===23)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_numeric_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})

      } else if (originalUIType===22)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
      } else if (originalUIType===2 || originalUIType===3) { //Try to conver text values to real phone numbers
        const payload = { tableid, field };
        await cloudrun.post('/mapTextToPhoneNumbers', { payload })
       
      } else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      }

      // we update the meta data last, otherwise functions above would be retreiving wrong starting metadata.
      await updateTableInfoAPI(tableid, tableinfo,columnConfigData);

      return "success"
    }
  } catch (error) {
    console.log(error)
    return `An Error Occurred: ${error.message}`
  }
    return "Error, no matching column foiund"

  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  isMobileHandler=() => {
    const {isMobile} = this.state;
    this.setState({isMobile:!isMobile})
  }

  updateDefaultCountry=(country) => {
    this.setState({defaultCountry:country})
  }

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  render() {
    const { showAdvanced, callout, defaultText, uitype,isMobile,regions,defaultCountry,isPrivate } = this.state;
    const { color } = this.props;
    return (
      <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <div style={{marginTop: '5px' }}>
        Users will be able to enter a valid phone number into this field. By default, numbers will be designated as mobile and support text messaging using the Messaging Thread Field Type. If that is not desired, disable the mobile designation under More field attributes.
        </div>

        <div style={{height:'15px'}}/>
       <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} />

        {showAdvanced ? (
          <div style={{display:'flex', width:'100%',flexDirection:'column'}}>
            <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
          
            <div style={{ display: 'flex', flexDirection: 'column', width:'100%',marginTop:'10px' }}>
            <GBSwitch color={color} text="Is a mobile number" isChecked={isMobile} Action={this.isMobileHandler} />

              <div style={{ marginBottom: '3px', marginTop:'10px' }}>
                <strong>Default country</strong>
              </div>
              <Dropdown selectOnBlur={false} upward options={regions} selection value={defaultCountry} onChange={(e,data) => this.updateDefaultCountry(data.value)} />
         
            <Callout callout={callout} updateCallout={this.CalloutHandler} />   
            </div>
           </div>
        ) : null}
      </div>
    );
  }
}

PhoneField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default PhoneField;
