/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import {Input} from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiDragVertical,mdiClose } from '@mdi/js';
import GBButton from '../../../../../components/GBButton/GBButton';

const PageBreak = ({fieldData,color,updateFieldLabel,removeItemFromSelectedArray,isEditMode,textColor,btnTextColor}) =>{

    return (
        <div style={{width:'100%'}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {isEditMode ? (
            <Input value={fieldData.button} onChange={(e,data)=>updateFieldLabel(fieldData.id,data.value)} />
          ) : <GBButton width="400px" height="35px" color={color} textColor={btnTextColor} fontSize="17px" text={fieldData.button} Action={null} />}
        </div>
        <div
          css={{
            display: 'flex',
            // backgroundColor: '#eee',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            '&:hover' : {
                '& .editMode' : {
                    display:'flex'
                }
            }
          }}
        >
          <div style={{ width: '45%' }}>
            <hr style={{ border: '1px dashed #757575', width: '100%' }} />
          </div>
          <div
            css={{
              width: '250px',
              height:'40px',
              border: '1px solid #757575',
              borderRadius:'5px',
              display: 'flex',
              margin: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              color:textColor
             
            }}
          >
            <div>Page break</div>
            <div className="editMode" css={{display:'none', flexDirection:'row', alignItems:'center'}}>
                <Icon path={mdiDragVertical} size="20px" />
                <div style={{width:'10px'}} />
                <div
              css={{
                color: '#E06055',
                borderRadius:'100%',
                // width: '10px',
                '&:hover': {
                  svg: { color: 'white' },
                  backgroundColor:'#E06055',
                  transition:'.5s ease all'
                  
                },
              }}
            >
                <Icon onClick={() => removeItemFromSelectedArray(fieldData.id)} path={mdiClose} size="20px" />
                </div>

            </div>
          </div>
          <div style={{ width: '45%' }}>
            <hr style={{ border: '1px dashed #757575', width: '100%' }} />
          </div>
        </div>
      </div>
    )
}

export default PageBreak;