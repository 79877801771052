import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const FormCard = ({ children, className, title, small, ...other }) => {
  return (
    <div className={classNames('form-card', className, { small })} {...other}>
      {!!title && <h4 className="form-card-title">{title}</h4>}
      {children}
    </div>
  );
};

FormCard.defaultProps = {
  className: null,
  title: null,
  small: false,
};

FormCard.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  title: PropTypes.string,
  small: PropTypes.bool,
};

/* eslint-disable react/prop-types */
FormCard.Subtitle = ({ children, className }) => {
  return (
    <div className={classNames('form-card-subtitle', className)}>
      <span>{children}</span>
    </div>
  );
};

FormCard.Description = ({ children, className, ...other }) => {
  return (
    <div className={classNames('form-card-description', className)} {...other}>
      <span>{children}</span>
    </div>
  );
};

export default FormCard;
