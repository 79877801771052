/* eslint-disable */
import React, {useEffect,useState} from 'react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import DougnutControl from './DougnutControl';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import {getZoneMetrics} from '../../api/zone'
import {plans} from './plans'


const PlanMetrics = ({ zoneInfo,toggleShowInfo }) => {

    const [metrics, setMetrics] = useState('')
    const [messageTotal, setMessageTotal] = useState(100)
    const [isExpanded, setIsExpanded] = useState(true);
    const [currentPlan,setCurrentPlan] = useState(plans.filter(itm=>itm.type===zoneInfo.plan)[0])
  
    useEffect(() => {
        let data=''
       const getMetrics = async () => {
           data= await getZoneMetrics(zoneInfo.zoneid,zoneInfo.plan)
           setMetrics(data)
          //  console.log(data)
       }
       getMetrics()
       if(zoneInfo.plan !=='Free') setMessageTotal(zoneInfo.userlicenses*currentPlan.messages)
    },[])

    const toggleExpand = () => {
      setIsExpanded(!isExpanded)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
        }}
      >
        <div style={{ marginLeft: '20px' }}>
        <TextButton textColor="black" Action={toggleExpand} hoverColor="#75757580" fontSize="13px" icon={isExpanded ? mdiChevronUp : mdiChevronDown} text="Plan information" iconPosition="left" />
        </div>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          Plan:&nbsp; <b>{zoneInfo.plan}</b>
          <div style={{ marginLeft: '10px' }}>
            <GBButton
              textColor="#1A1A1A"
              Action={toggleShowInfo}
              text="Change plan"
              color="#FFBE24"
              width="100px"
              height="25px"
              borderRadius="15px"
            />
          </div>
        </div>
      </div>
      {isExpanded ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '50px',
          marginLeft: '20px',
          justifyContent: 'space-evenly',
        }}
      >
        
        <DougnutControl label="Users" currentValue={zoneInfo.userCount} totalMetric={zoneInfo.plan==='Free' ? zoneInfo.userCount :  zoneInfo.userlicenses} />
        <DougnutControl label="Blocks" currentValue={metrics.blockCount} totalMetric={currentPlan.blocks} />
        <DougnutControl label="Attachment space" labelWidth="130px" measure="GB" currentValue={parseFloat(metrics.dataSize).toFixed(3)} totalMetric={currentPlan.data} />
      </div>
      ): null}
    </div>
  );
};

export default PlanMetrics;
