/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { Progress } from 'semantic-ui-react';
import { debounce } from "debounce";
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { mdiDesktopClassic, mdiCloudUpload } from '@mdi/js';
import Icon from '@mdi/react';
import Spinner from '../../../../components/Spinner/Spinner';

const DesktopFiles = ({
  color,
  uploadFiles,
  category,
  selectmode,
  GetImage,
  height = '230px',
  width = '95%',
  callingComponent = 'attachments',
  textColor="#1A1A1A"
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [percent,setPercent]=useState(0);

  useEffect(() => {
    if(callingComponent==='attachments') {
    document.onpaste = (e) => {
      GetImage(e,category);
    };
  }
  });

  const showProgress=(e)=>{
    const p = e.loaded/e.total *100
    setPercent(p)
  }


  const upload = async (acceptedFiles, category) => {

    setIsLoading(true);
    if(callingComponent==='Form') {
      await uploadFiles(acceptedFiles, category,showProgress);
    } else {
      await uploadFiles(acceptedFiles, category);
    }
    setIsLoading(false);
  };

  const displayOptions = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {callingComponent === 'attachments' ? (
          <>
            <div style={{ fontSize: '20px', fontWeight: 'bold', fontColor:{textColor} }}>Drag or paste file(s) here</div>
            <div>or</div>
            <div
              css={{
                marginBottom: '10px',
                padding: '5px',
                cursor: 'pointer',
                borderRadius: '10px',
                border: `1px solid ${color}`,
                '&:hover': {
                  boxShadow: `0px 3px 6px ${color}`,
                  transition: 'all .5s ease',
                },
              }}
            >
              Select files from this device
            </div>
            <Icon path={mdiDesktopClassic} size="40px" color={color} />
          </>
        ) : (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
             
            }}
          >
            <Icon path={mdiCloudUpload} size="25px" />

            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                cursor:'pointer',
                fontColor:{textColor}
              }}
            >
              <span>Click to upload, or drag into this box. </span>
              <span>Supported file formats: </span>
              <span>.doc, .txt, .pdf, .jpg, .png, .gif, .pdf, .xls </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const dropDisplay = () => {
    return (
      <div
        style={{
          display: 'flex',
          fontSize: '20px',
          fontWeight: 'bold',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor:'pointer'
        }}
      >
        Drop files here...
      </div>
    );
  };

  const test=()=>{
    setIsLoading(true);
  }

  return (
    <>
      {!isLoading ? <Dropzone
          multiple={selectmode === 'multiple' ? true : false}
          onDrop={(acceptedFiles) => {
            upload(acceptedFiles, category)
          }
          }
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                css={{
                  display: 'flex',
                  border:
                    callingComponent === 'attachments'
                      ? `1px dashed ${color}`
                      : `1px dashed ${textColor}`,
                  marginTop: '10px',
                  marginBottom: '10px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems:'center',
                  width: width,
                  height: height,
                  borderRadius: '5px',
                  marginLeft: '10px',
                  // marginRight: '20px',
                  color: textColor,
                  '&:hover': {
                    color:color,
                    border: `1px solid ${color}`,
                    svg: {
                      color:color
                    }
                  },
                }}
                {...getRootProps()}
                className={classNames('dropzone', {
                  'dropzone--isActive': isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? dropDisplay() : displayOptions()}
              </div>
            );
          }}
        </Dropzone> :  <Progress percent={percent} indicating />} 
    </>
  );
};

export default DesktopFiles;
