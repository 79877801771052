/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
// import TextControl from '../../components/TextControl/TextControl';
import InputControl from '../../components/InputControl/InputControl';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { AccountContext } from '../../providers/AccountProvider';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import { updateZoneName, updateZoneSettings } from '../../api/zone';
import TextButton from '../../components/TextButton/TextButton';
import { Popup,Input,Dropdown } from 'semantic-ui-react';

const ZoneProperties = ({ zoneInfo, updateZone }) => {
  const zoneNameControl = { data: 'name', uitype: 2, header: 'Zone name: ' };

  const { userInfo } = useContext(AccountContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [zoneName, setZoneName] = useState(zoneInfo.name);
  const [multiFactor, setMultiFactor] = useState(zoneInfo.attributes.multiFactor !== undefined);
  const [reverify, setReverify] = useState(zoneInfo.attributes.reverify ?? null);
  const [delay, setDelay] = useState(zoneInfo.attributes.reverifyPeriod ??  'days')

  const sessionTimeoutOptions=[
    {key:'minutes',value:'minutes',text:'Minute(s)'},
    {key:'hours',value:'hours',text:'Hour(s)'},
    {key:'days',value:'days',text:'Day(s)'}]

  // useEffect(() => {
  //   console.log(zoneInfo.attributes);
  // }, []);

  const handleReverify = async (e) => {
    const zInfo = structuredClone(zoneInfo);
    if(e.target.value!=='') {
      zInfo.attributes.reverify=parseInt(e.target.value);
    } else {
      delete zInfo.attributes.reverify;
    }

    await updateZoneSettings(zInfo.zoneid, zInfo.attributes);
  };

  const handleChangeDelay= async (val) =>{
    const zInfo = structuredClone(zoneInfo);
    if(val !=='') {
      zInfo.attributes.reverifyPeriod=val;
    } else {
      delete zInfo.attributes.reverifyPeriod;
    }

    await updateZoneSettings(zInfo.zoneid, zInfo.attributes);
    setDelay(val);
  }

  const updateValue = (field, value) => {
    setZoneName(value);
  };

  const submitChanges = async () => {
    await updateZoneName(zoneInfo.zoneid, zoneName);
    const idx = userInfo.zones.findIndex((itm) => itm.id === zoneInfo.zoneid);
    if (idx !== -1) {
      userInfo.zones[idx].name = zoneName;
    }
    zoneInfo.name = zoneName;
    updateZone(zoneInfo);
  };

  const toggleZoneMultiFactor = async () => {
    setMultiFactor(!multiFactor);
    const zInfo = structuredClone(zoneInfo);
    if (multiFactor) {
      delete zInfo.attributes.multiFactor;
    } else {
      zInfo.attributes.multiFactor = true;
    }
    await updateZoneSettings(zInfo.zoneid, zInfo.attributes);
    updateZone(zInfo);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
          //   width: '90%',
        }}
      >
        <div style={{ marginLeft: '20px', whiteSpace: 'nowrap' }}>
          <TextButton
            textColor="black"
            Action={toggleExpand}
            hoverColor="#75757580"
            fontSize="13px"
            icon={isExpanded ? mdiChevronUp : mdiChevronDown}
            text="Zone properties"
            iconPosition="left"
          />
        </div>
      </div>
      {isExpanded ? (
        <div
          style={{
            marginTop: '10px',
            marginLeft: '45px',
            display: 'flex',
            marginRight: '20px',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginLeft: '129px' }}>
            <InputControl
              alignControl={true}
              fontWeight="normal"
              colInfo={zoneNameControl}
              value={zoneName}
              submitChanges={submitChanges}
              updateValue={updateValue}
              canEdit={true}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ marginLeft: '128px' }}>
              Zone status: Active{' '}
              <Popup trigger={<Icon path={mdiInformationOutline} size="20px" />}>
                To deactivate this Zone, go to your User Profile page.
              </Popup>
            </div>
          </div>
          <div
            style={{
              marginTop: '10px',
              flexDirection: 'row',
              display: 'flex',
              whiteSpace:'nowrap',
              marginLeft:'115px',
              alignItems:'center'
            }}
          >
            <div>Reverify login: Every </div>
           <Input type="number" onBlur={handleReverify} style={{width:'70px',marginLeft:'5px',marginRight:'5px'}} value={reverify} onChange={(e,data)=>setReverify(data.value)} />
        
            <div>
              days
              {/* <Dropdown value={delay} options={sessionTimeoutOptions} onChange={(e,data)=>handleChangeDelay(data.value)} selection /> */}
            </div>
          </div>
          <div
            style={{
              marginTop: '10px',
              flexDirection: 'row',
              display: 'flex',
              whiteSpace:'nowrap',
              width:'300px',
              justifyContent: 'space-between',
              alignItems:'center'
            }}
          >
            <div style={{marginRight:'5px'}}>Activate multifactor authentication:</div>
            <GBSwitch
              color="#ae007b"
              text=""
              isChecked={multiFactor}
              Action={toggleZoneMultiFactor}
            />
            <div>
            (This only applies to direct username/password accounts.<br/> Google and Microsoft sign-in multifactor is controlled by the login account's setting.)
            </div>
          </div>
          
        </div>
      ) : null}
    </div>
  );
};

export default ZoneProperties;
