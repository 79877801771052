/* eslint-disable */
import React,{Component} from 'react';
import Icon from '@mdi/react';
import { Buffer } from 'buffer';
import { Helmet } from 'react-helmet';
import { AccountContext } from '../../providers/AccountProvider';
import { getBlockAPI } from '../../api/blocks';
import TabBar from './tabbar';
import GeneralInfo from './generalInfo';
import TableApi from './tableApi';
import Global from '../../clients/global'

class ApiDocs extends Component {

    static contextType = AccountContext;
    
    constructor(props) {
        super(props);
        this.state={
            block: null,
            name:'',
            icon:'',
            color: '',
            statement: '',
            information:'',
            blocksettings:null,
            blockKey:'',
            viewingComponent: 'General',
            tableId:null,
            subKey:null
        }
    }

    async componentDidMount() {
        const { blockID } = this.props;
        await this.GetBlocktabInfo(blockID);
    }

    GetBlocktabInfo = async (blockid) => {
        const { userInfo} = this.context;
        const zoneName = userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0].name;

        const blockinfo = await getBlockAPI(blockid, userInfo.id);  
    
        const block = blockinfo[0];
        block.zoneName= zoneName;
        block.id = blockid; //update logic expects "id" instead of "blockid". this is from older code. This block info is used by blockeditor component to update blocks

        const c = Buffer.from(`${Global.zoneid}|${blockid}`);
        let base64data = c.toString('base64');
    
        this.setState({
          block,
          issystemblock: blockinfo[0].issystemblock,
          name: blockinfo[0].name,
          icon: blockinfo[0].icon,
          color: blockinfo[0].color,
          statement: blockinfo[0].statement,
          information: blockinfo[0].information,
          blocksettings: blockinfo[0].BlockUsers[0].settings,
          blockKey: base64data,
        });
    
        // Change page icon to the block icon. Title is changed in render() below using <Helmet>
        const favico = document.getElementById('favicon');
        const color = encodeURIComponent(blockinfo[0].color);
        const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:${color}' viewBox='0 0 24 24'%3E%3Cpath d='${blockinfo[0].icon}'/%3E%3C/svg%3E`;
        favico.href = svgpath;
      };

    LoadTableApi=(tableId, subKey) => {
        const X = ['start','authenticate','errors','paging'].includes(tableId);
        this.setState({
            tableId,
            subKey,
            viewingComponent: X ? 'General' : 'Table'
        })
    }
   

    render() {
      const {color,icon,name,blockKey,viewingComponent,tableId,subKey,block} = this.state;
      const { blockID } = this.props;
      const { userInfo} = this.context;
        
      return (
        <div>
            <Helmet>
            <title>{`${name} API Documentation`}</title>
            {/* <link
            rel="icon"
            sizes="any"
            href="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M6 12c0-4.5 1.93-8.74 6-10a10 10 0 010 20c-4.07-1.26-6-5.5-6-10z'/%3E%3C/svg%3E"
          /> */}
            </Helmet>
           <div style={{display:'flex', alignItems:'center', height:'50px',backgroundColor:color}}>
               <div style={{marginLeft:'20px',display:'flex', height:'50px', flexDirection:'row', alignItems:'center', color:'white'}}>
                    <Icon path={icon} size="30px" color="white" />
                    <div style={{fontSize:'20px',marginLeft:'10px'}}>{name}</div>
                    <div style={{marginLeft:'10px',fontSize:'20px'}}>API Documentation</div>
                </div>
           </div>

           <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{minWidth:'250px'}}>
                    <TabBar blockId={blockID} color={color} LoadTableApi={this.LoadTableApi} />
                </div>
                <div style={{width:'100%', overflow:'auto',height:'calc(100vh - 50px)'}}>
                   {viewingComponent==='General' && block !=null ? (
                       <GeneralInfo tableId={tableId} color={color} block={block} name={name} apiKey={userInfo.systemattributes.apiKey} blockKey={blockKey} />
                   ): (
                       <TableApi tableId={tableId} subKey={subKey}  block={block}  blockKey={blockKey} color={color} />
                   )}
                   
                </div>
           </div>
      

        </div>
        )
    }
    
}

export default ApiDocs