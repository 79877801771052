/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import {Form,Input } from 'semantic-ui-react';
import moment from 'moment-timezone';
import 'react-base-table/styles.css';
import GBButton from '../../components/GBButton/GBButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowDecisionOutline,mdiRefresh } from '@mdi/js';
import { getWorkflowHistory } from '../../api/workflows';
import CreateRecordUrl from '../../utils/CreateRecordUrl';
import Global from '../../clients/global';

const WorkflowHistory = ({ workFlowObj, close }) => {
  const [data, setData] = useState([]);
  const [instanceData,setInstanceData] =useState([]);
  const [showAll, setShowAll] =useState(true);
  const [sortBy,setSortby] =useState('instanceid');
  const [filteredData,setFilteredData] =useState(null);
  const [filterTerm,setFilterTerm] =useState('');

  const closeLocal = async () => {
    close(workFlowObj);
  };

  useEffect(() => {
    getHistory(workFlowObj.tableid);
  }, []);

  const refresh = async () =>{
    await getHistory(workFlowObj.tableid);
  }


  const onColumnSort = (sortCol) => {
    const field = sortCol.column.dataKey;
    let tempData = showAll ? data : instanceData;
  
    tempData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
  
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // String comparison (case-insensitive for better user experience)
        return sortCol.order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        // Numeric or other types (direct comparison)
        return sortCol.order === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
  
    // Update state (choose one based on your structure):
    if (showAll) {
      setData([...tempData]); // Create a copy to trigger re-render
    } else {
      setInstanceData([...tempData]); // Create a copy to trigger re-render
    }
    setSortby(sortCol);
  }

  const getHistory = async (tableid) => {
    const records = await getWorkflowHistory(workFlowObj.workflowid, tableid);
    
    setData(records);
    const idata =[];
    records.map(itm=>{
      const idx = idata.findIndex(i=>i.instanceid===itm.instanceid);
      if(idx ==-1) {
      idata.push({
        instanceid: itm.instanceid,
        addedon:itm.addedon,
        blockname: itm.blockname,
        name: itm.name,
        trigger:itm.trigger,
        instance_status: itm.instance_status,
        fullname: itm.fullname,
        rowid: itm.rowid,
        tableid:itm.tableid,
        blockid: itm.blockid
      })
    }
    })

    

    setInstanceData(idata);

  };


  const filter = () => {

    if(filterTerm==='') {
      setFilteredData(null)
    } else {
    const lowerCaseFilterTerm = filterTerm.toLowerCase();
    const tempData = showAll ? data : instanceData

    const fData = tempData.filter(obj => {
      return Object.values(obj).some(value => {
        const stringValue = String(value); // Convert non-strings to strings
        return stringValue.toLowerCase().includes(lowerCaseFilterTerm);
      });
    });

    setFilteredData(fData);
  }
  
  }

  const DeepLink = ({ cellData, rowData, column }) => {
    // Custom rendering logic based on cellData, rowData, and column info
    const url = CreateRecordUrl(
      Global.zoneid,
      rowData.blockid,
      rowData.tableid,
      rowData.rowid,
      null,
    );
    return (
      <a href={url} target="newwindow">
        {cellData}
      </a>
    );
  };

  const ActionDeepLink = ({ cellData, rowData, column }) => {
    // Custom rendering logic based on cellData, rowData, and column info
    const url = CreateRecordUrl(
      Global.zoneid,
      rowData.blockid,
      rowData.actiontableid,
      rowData.actionrowid,
      null,
    );
    return (
      <a href={url} target="newwindow">
        {cellData}
      </a>
    );
  };

  const DateRender = ({ cellData, rowData, column }) => {
    // Custom rendering logic based on cellData, rowData, and column info
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's timezone
    if(cellData !==null) {
    const newDate = moment.tz(cellData, userTimeZone).format('DD-MMM-YY hh:mm:ss A z'); 
    return newDate;
    } else {
      return '';
    }
  };

  const columns = [
    {
      key: 'col0',
      dataKey: 'instanceid',
      title: 'ID',
      width: 50,
      resizable:true,
      sortable:true,
    },
    {
      key: 'col1',
      dataKey: 'instance_status',
      title: 'Instance status',
      width: 150, 
      resizable:true,
      sortable:true,
    },
    {
      key:'col20',
      dataKey: 'addedon',
      title: 'Trigger date',
      width:150,
      cellRenderer: DateRender,
      resizable:true,
      sortable:true,
    },
    {
      key: 'col1A',
      dataKey: 'blockname',
      title: 'Block',
      width: 70, 
      resizable:true,
      sortable:true,
    },
    {
      key: 'col1B',
      dataKey: 'name',
      title: 'Tab',
      width: 70, 
      resizable:true,
      sortable:true,
    },
    {
      key: 'col2',
      dataKey: 'trigger',
      title: 'Trigger event',
      width: 180,
      resizable:true,
      sortable:true,
    },
    {
      key: 'col3',
      dataKey: 'nodeid',
      title: 'Event ID',
      width: 100,
      resizable:true,
      hidden: !showAll,
      sortable:true,
    },
    {
      key: 'col4',
      dataKey: 'action',
      title: 'Event name',
      width: 200,
      resizable:true,
      hidden: !showAll,
      sortable:true,
    },
    {
      key: 'col5',
      dataKey: 'eventStatus',
      title: 'Event status',
      width: 130,
      resizable:true,
      hidden:!showAll,
      sortable:true,
    },
    {
      key: 'col6',
      dataKey: 'fullname',
      title: 'Triggered record',
      width: 150,
      cellRenderer: DeepLink,
      resizable:true,
      sortable:true,
    },
    {
      key:'col6A',
      dataKey: 'actionblockname',
      title: 'Action block',
      width:100,
      resizable:true,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col6B',
      dataKey: 'actiontabname',
      title: 'Action tab',
      width:100,
      resizable:true,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col9',
      dataKey: 'actionrecordid',
      title: 'Action record',
      width:150,
      cellRenderer: ActionDeepLink,
      resizable:true,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col10',
      dataKey: 'actionstartdate',
      title: 'Action date',
      width:150,
      resizable:true,
      cellRenderer : DateRender,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col11',
      dataKey: 'lastaction',
      title: 'Last action date',
      width:150,
      resizable:true,
      cellRenderer : DateRender,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col12',
      dataKey: 'scheduled',
      title: 'Schedule date',
      width:150,
      resizable:true,
      cellRenderer : DateRender,
      hidden:!showAll,
      sortable:true,
    },
    {
      key:'col13',
      dataKey: 'unfinished',
      title: 'Unfinished',
      width:150,
      resizable:true,
      cellRenderer : DateRender,
      hidden:!showAll,
      sortable:true,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          backgroundColor: '#0D99FF',
        }}
      >
        <div
          onClick={()=>closeLocal()}
          css={{
            marginLeft: '20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize:'25px',
            cursor:'pointer',
            '&:hover': {
              color: '#FFFFFF80',
              transition: 'all .3s ease',
                svg: {
                  color: '#FFFFFF80',
                  transition: 'all .3s ease',
                },
              },
          }}
        >
          <Icon path={mdiArrowLeft} size="30px" />
          <Icon path={mdiArrowDecisionOutline} size="30px" />
          <div>{workFlowObj?.workflowname}</div>
        </div>
      </div>
      <div style={{ margin: '20px' }}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'20px'}}>
          <div>  Total records: {showAll ? data.length : instanceData.length}</div>
          <div style={{marginLeft:'20px'}}>
          <GBSwitch width="150px" color={'#0D99FF'} isChecked={showAll} Action={()=>setShowAll(!showAll)} text="Toggle actions" />
          </div>
          <div style={{marginLeft:'20px'}}>
            <Input 
            clearable
             placeholder="Lookup record" 
             action={{
                icon: "search",
                onClick: () => filter()
              }}
              onChange={(e,data)=>setFilterTerm(data.value.trim())}/>
          </div>
          <div style={{marginLeft:'20px'}}>
          <GBButton
            text="Refresh"
            Action={refresh}
            // Action={handleClick}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#0D9953'}
            icon={mdiRefresh}
            iconSize="30px"
          />
          </div>
        </div>

        <div style={{ width: 'calc(100vw - 50px)', height: 'calc(100vh - 150px)' }}>
        {/* <div style={{ width: 'calc(100vw - 20px)', height: '100vh' }}> */}
          <AutoResizer>
            {({ width, height }) => (
              <BaseTable
                fixed
                sortBy={sortBy}
                onColumnSort={onColumnSort}
                rowKey={(rowData) => `${rowData.instanceid}-${rowData.rowid}`} 
                data={filteredData !==null ? filteredData : showAll ? data : instanceData}
                width={width}
                height={height}
                columns={columns}
                onColumnResizeEnd={()=>{}}
              ></BaseTable>
            )}
          </AutoResizer>
        </div>
      </div>
    </div>
  );
};

export default WorkflowHistory;
