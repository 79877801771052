/* eslint-disable */
import React, {useEffect} from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiKey, mdiFormatPageBreak, mdiSourceCommitStart } from '@mdi/js';

const Errors=({color}) => {

    return (
        <div style={{scrollMargin: '20px'}} id="errors">
            <div style={{marginTop:'50px',display:'flex', flexDirection:'row', alignItems:'flex-start'}} >
                <div style={{width:'50%'}}>
                    <div style={{marginLeft:'10px',display:'flex', flexDirection:'row',alignItems:'center'}}>
                        <div style={{marginBottom:'3px'}}><Icon path={mdiAlertCircleOutline} size="30px" color={color} /></div>
                        <span style={{marginLeft:'5px',fontSize:'25px', color:color}}> Errors</span>
                    </div>
                    <div style={{margin:'10px'}}>
                     GraceBlocks uses standard HTTP response codes to indicate the success or failure of an API request. In general: Codes in the 2xx range indicate success. Codes in the 4xx range indicate an error that failed due to the information provided being insufficient (e.g., a required parameter was omitted). Codes in the 5xx range indicate an error with GraceBlock's servers (these are rare). The table on the right illustrates the errors you may encounter and how to interpret them.      
                    </div>
                </div>
                <div style={{width:'50%', margin:'10px',border:`2px solid ${color}`, backgroundColor:`${color}1A`,  borderRadius:'15px'}}>
                   <div style={{display:'flex', alignItems:'center', padding:'15px', height:'30px', borderTopRightRadius:'15px',color:'white', borderTopLeftRadius:'10px',  backgroundColor:color}}>
                       HTTP status codes
                   </div>
                   <div style={{margin:'10px', display:'flex',flexDirection:'row', alignItems:'flex-start'}}>
                       <div style={{borderRight:'1px solid black',paddingRight:'5px'}}>
                                200 - OK <p/>
                                400 - Bad Request<p/>
                                401 - Unauthorized<p/>
                                402 - Failed request <p/>
                                403 - Forbidden <p/>
                                404 - Not found<p/>
                                409 - Conflict<p/>
                                429 - Too many requests<p/>
                                500 - Server Error<p/>
                       </div>
                       <div style={{paddingLeft:'5px'}}>
                          Everything worked as expected.<p></p>
                          Invalid request, (often due to missing a required parameter).<p/>
                          Invalid API Key.<p/>
                          The parameters were valid, but the request failed.<p/>
                          The API key is not authorized to perform the request.<p/>
                          The requested resource doesn't exist.<p/>
                          The request conflicts with another request.<p/>
                          Too many requests hit the API too quickly.<p/>
                          Something went wrong on the GraceBlocks end.<p/>
                       </div>
                        {/* <div style={{display:'flex', flexDirection:'row', alignItems:'center',  margin:'10px'}}>
                                <div style={{width:'50%'}}>200 - OK</div>
                                <div>Everything worked as expected</div>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', margin:'10px'}}>
                                <div style={{width:'50%'}}>400 - Bad Request</div>
                                <div>Invalid request, (often due to missing a required parameter).</div>
                        </div> */}
                   </div>

                </div>
            </div>

        </div>
    )
}

export default Errors