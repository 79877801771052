/* eslint-disable */
import React, { useState, useEffect, table } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { getBlockUsersAPI,addBlockUsers } from '../../../../api/users';
import { mdiClose } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import GBButton from '../../../../components/GBButton/GBButton';

const AddBlockUsers = ({ blockid, color, tableid, close, closeModalandUpdate }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers,setSelectedUsers] =useState([]);
  const [sendInvitation, setSendInvitation] = useState(false);
  const [role,setRole] =useState('Block user (General)')

  const toggleSend = () => {
    setSendInvitation(!sendInvitation);
  };

  const saveUpdates = async () =>{
    await addBlockUsers(selectedUsers,blockid,role);
    closeModalandUpdate()
  }

  const LoadUsers = async () => {
    let options = [];
    const busers = await getBlockUsersAPI(blockid, false, false);
    busers.map((b) => {
      options.push({ key: b.userid, value: b.userid, text: `${b.firstname} ${b.lastname}` });
    });

    setUsers(options);
  };

  const roleOptions = [
    { key: 'general', value: 'Block user (General)', text: 'Block user (General)' },
    { key: 'level1', value: 'Block user (L1 Security)', text: 'Block user (L1 Security)' },
    { key: 'level2', value: 'Block user (L2 Security)', text: 'Block user (L2 Security)' },
    { key: 'builder', value: 'Block builder', text: 'Block builder' },
  ];

  useEffect(() => {
    LoadUsers();
  }, []);

  return (
    <div>
      <div
        style={{
          height: '42px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
          backgroundColor: color,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ color: 'white', paddingLeft: '10px' }}>Add user(s)</div>
        </div>
        <TextButton
          text="Hide panel when done"
          textColor="white"
          hoverColor="#FFFFFF80"
          icon={mdiClose}
          Action={close}
        />
      </div>
      <div
        style={{
          display: 'flex',
          height: '230px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            width: '500px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '10px',
            backgroundColor: color,
          }}
        >
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            Step 1: Enter the user(s) you wish to add.
          </div>
        </div>

        <div
          style={{
            paddingTop: '10px',
            // overflow: 'auto',
            paddingLeft: '10px',
            paddingRight: '10px',
            width: '500px',
            border: `1px solid ${color}`,
            minHeight: '150px',
            marginBottom:'100px'
          }}
        >
          {users.length > 0 ? (
            <Dropdown
              selectOnBlur={false}
              placeholder="User selector control"
              fluid
              multiple
              selection
              options={users}
              value={selectedUsers}
              onChange={(e,data)=>setSelectedUsers(data.value)}
            />
          ) : null}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          position:'relative',
          zIndex:0,
          height: '260px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            width: '500px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '10px',
            backgroundColor: color,
          }}
        >
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            Step 2: Specify the Block role for the user(s) you wish to add.
          </div>
        </div>

        <div
          style={{
            paddingTop: '10px',
            // position:'absolute',
            zIndex:20,
            // overflow: 'auto',
            paddingLeft: '10px',
            paddingRight: '10px',
            width: '500px',
            border: `1px solid ${color}`,
            minHeight: '150px',
          }}
        >
          {users.length > 0 ? (
            <Form>
            <Dropdown
              selectOnBlur={false}
              placeholder="Select a role for the user(s)"
              value={role}
              onChange={(e,data) =>setRole(data.value)}
              fluid
              selection
              options={roleOptions}
            />
            </Form>
          ) : null}
        </div>
      </div>

      <div style={{ display:'flex',marginTop:'20px', flexDirection:'row',justifyContent:'flex-end',marginRight:'50px',marginBottom:'30px'}}>
          <TextButton text="Cancel" hoverColor="#75757580" Action={close} textColor="black"  />
          <div style={{width:"20px"}}></div>
          <GBButton text="Save" color={color} width="75px" Action={saveUpdates} />
        </div>

    </div>
  );
};

export default AddBlockUsers;
