/* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React from 'react';

import { Popup } from 'semantic-ui-react';
import Icon from '@mdi/react';

const TextButton = ({
  text,
  boldStyle = 'normal',
  tooltip = '',
  textColor = 'white',
  hoverColor,
  Action,
  fontSize = '15px',
  ActionValue,
  icon,
  iconSize = '15px',
  iconPosition = 'right',
  backgroundHoverColor=null,
  marginLeft=null,
  backgroundColor=null
}) => {
  return (
    <Popup 
        mouseEnterDelay={1000}
        on="hover"
        disabled={tooltip === ''}
        trigger={
    <div
      onClick={() => Action(ActionValue)}
      css={{
        // paddingTop: '5px',
        backgroundColor:backgroundColor,
        userSelect: 'none', 
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        color: textColor,
        '&:hover': {
          transition: 'all .3s ease',
          color: hoverColor,
          backgroundColor: backgroundHoverColor,
          svg: {
            transition: 'all .3s ease',
            color: hoverColor,
          },
        },
      }}
    >
      
      {icon !== undefined && iconPosition === 'left' ? (
          <div style={{ marginRight: '5px' }}> {/* Add margin to the wrapper */}
            <Icon path={icon} size={iconSize} /> 
          </div>
      ) : null}
      
      <div css={{ fontSize, fontWeight: boldStyle, marginRight: '5px' ,marginLeft:marginLeft,'&:hover': {
          transition: 'all .3s ease',
          backgroundColor: backgroundHoverColor,
          color: hoverColor,
        
        },}}>{text}</div>

      {icon !== undefined && iconPosition === 'right' ? (
          <Icon path={icon} size={iconSize} /> 
      ) : null}
    </div>
        } content={tooltip} />
  );
};

export default TextButton;
