/* eslint-disable */
/*
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 * Exmaple: setDeep(myObj, ["level1", "level2", "target1"], 3);
 */

export const setDeep = (obj, path, value, setrecursively = false) => {
    path.reduce((a, b, level) => {
        if (setrecursively && typeof a[b] === "undefined" && level !== path.length-1){
            a[b] = {};
            return a[b];
        }
        if (level === path.length-1){
            a[b] = value;
            return value;
        } 
        return a[b];
    }, obj);
}