/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { mdiClose,mdiPlus } from '@mdi/js';
import GBButton from '../../../../components/GBButton/GBButton';
import ActionButton from '../ActionBar/ActionButton';
import TextButton from '../../../../components/TextButton/TextButton';
import {updateShareStatus} from '../../../../api/shares'

const ListShares = ({color,tablename,shares,loadShare,changeControl}) =>{

    const [isActive,setIsActive] = useState(true);
    const [localshares, setLocalShares] = useState(shares);

    // useEffect(() =>{
    //     console.log(shares)
    // },[])

    const updateShare= async (id) =>{
        await updateShareStatus(id,!isActive);
        const temp = [...localshares];

        const idx = temp.findIndex(el=>el.id===id);
        if(idx !==-1) {
            temp[idx].isActive=!isActive;
            setLocalShares(temp)
        }
    }

    // FOR THE <b>{tablename.toUpperCase()}</b> TAB

    return (
        <div style={{margin:'20px'}}>
            <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'center', paddingBottom:'20px',paddingTop:'20px'}}>
                <GBButton color={color} Action={changeControl} ActionValue="create" text="Create new share link"  />
            </div>
            <div>
                EXISTING SHARE LINKS 
            </div>
            <div style={{marginTop:'20px',display:'flex', flexDirection:'row',alignItems:'center', borderBottom:'1px solid #EEEEEE'}}>
              <ActionButton marginBottom="0px" label="Active" Action={setIsActive} ActionValue={true} showBottom={true} color={color} isSelected={isActive} />
              <div style={{width:'30px'}}></div>
              <ActionButton marginBottom="0px" label="Inactive" Action={setIsActive} ActionValue={false} showBottom={true} color={color} isSelected={!isActive} />
            </div>
            {localshares.filter(el=>el.isActive===isActive).map(shr => (
                <div key={shr.id} style={{margin:'10px',display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{color:color,cursor:'pointer'}} onClick={()=>loadShare(shr)}><u>{shr.name}</u></div>
                        <div>
                            {shr.isvalid ? (
                            <TextButton Action={updateShare} ActionValue={shr.id} iconPosition="left" textColor='#757575' hoverColor= {isActive ? 'red' :'green'} text={isActive ? 'Deactivate' : 'Re-activate' } icon={isActive ? mdiClose : mdiPlus} />
                            ): 'no longer invalid'}
                        </div>
                </div>
            ))}
        </div>
    )
}

export default ListShares