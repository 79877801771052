class ApiError extends Error {
  constructor(response, message) {
    super(message);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.date = new Date();
    this.code = 'NetworkError';
    this.message = message;
    if (response) {
      const { status, statusText, data = {} } = response;
      this.status = status;
      this.statusText = statusText;
      if (data.code) {
        this.code = data.code;
      }
      if (data.message) {
        this.message = data.message;
      }
    }
  }

  isUnauthorized = () => this.status === 401;

  isUserError = () => this.type === 'UserError';
}

export default ApiError;
