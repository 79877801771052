/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React,{useEffect,useState} from 'react';
import Icon from '@mdi/react';

import {
  mdiEye,
  mdiEyeOff,
  mdiClose,
  mdiDragVertical,
  mdiNumeric1Circle,
  mdiNumeric2Circle,
  mdiNumeric3Circle,
  mdiSortAscending,
  mdiSortDescending
} from '@mdi/js';
import { Checkbox } from 'semantic-ui-react';

const ColumnItem = ({
  color,
  item,
  updateColumn,
  index,
  sortItems,
  groupItems,
  calcItems,
  updateSortItems,
  updateGroupItems,
  updateSortOrder,
  updateCalcItems,
  dbname
}) => {
  function isOdd(num) {
    return num % 2;
  }

  const sortidx = sortItems.findIndex((el) => el.field === item.data);
  const groupidx = groupItems.findIndex((el) => el === item.data);
  const calcidx = calcItems.findIndex((el) => el === item.data);

  return (
    // <div style={{width:'100%'}}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '25px',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: isOdd(index) === 1 ? `${color}1A` : 'white',
      }}
    >
      <div style={{ width: '300px' }}>{item.header}</div>
      <div style={{ width: '200px',display:'flex',flexDirection:'row' }}>
 
      {groupidx === -1 && !item.disableGroup && !item.locked  && !['Users','BlockUsers'].includes(dbname) ? (
      
          <Checkbox
            checked={false}
            onChange={(e, data) => {
              updateGroupItems(item.data)}
            }
          />
      ) : groupidx !==-1 && !item.disableGroup ? (
        <div style={{cursor:'pointer'}}>
           <Icon path={groupidx===0 ? mdiNumeric1Circle : groupidx===1 ? mdiNumeric2Circle : mdiNumeric3Circle} size="20px" onClick={()=>updateGroupItems(item.data)}/>
        </div>
      ) : null}
      <div style={{width:'10px'}}/>
      {item.isNumericField && !item.locked ? (
        <Checkbox
            disabled={item.hide}
            checked={calcidx!==-1}
            onChange={(e, data) => {
              updateCalcItems(item.data)}
            }
          />
        ): null}        
      
      </div>


      <div style={{ width: '100px' }}>
      {sortidx === -1 && !item.disableSort && !['Users','BlockUsers'].includes(dbname) ? (

        <Checkbox checked={false} 
         onChange={(e, data) => {
          updateSortItems({ field: item.data, sortOrder: 'asc',sortHeader:item.header })}
        }
        />
      ) : sortidx !== -1 && !item.disableSort  ? (
        <div style={{display:'flex',flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
            <Icon path={sortidx===0 ? mdiNumeric1Circle : sortidx===1 ? mdiNumeric2Circle : mdiNumeric3Circle} size="20px" onClick={()=>updateSortItems({ field: item.data, sortOrder: 'asc' })}/>
            {sortItems[sortidx].sortOrder==='asc' ? (
               <Icon path={mdiSortDescending} size="20px" onClick={()=>updateSortOrder(sortItems[sortidx],'desc')} />
            ) : (
              <Icon path={mdiSortAscending} size="20px" onClick={()=>updateSortOrder(sortItems[sortidx],'asc')} />
            )}
           
        </div>
      ): null}
       </div>
      
      <div
        css={{
          color:
            (item.hide === false || item.hide === undefined) && item.locked === undefined
              ? '#1A1A1A'
              : '#1A1A1A80',
          width: '75px',
          '&:hover': {
            color: '#1A1A1A1A',
          },
        }}
      >
        {item.hide === false || item.hide === undefined ? (
          <Icon
            onClick={() => (item.locked === undefined ? updateColumn(item) : null)}
            path={mdiEye}
            size="20px"
          />
        ) : (
          <Icon
            onClick={() => (item.locked === undefined ? updateColumn(item) : null)}
            path={mdiEyeOff}
            size="20px"
          />
        )}
      </div>
      <div style={{ width: '45px',display:'flex',flexDirection:'row' }}>
        <Icon path={mdiDragVertical} size="20px" />
      {item.locked ? <div>🔒</div> : null}
      </div>
    </div>
    // </div>
  );
};
export default ColumnItem;
