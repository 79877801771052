/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';

import { Popup } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';

const SystemWarning = ({ warnings }) => {
  return (
    <div style={{margin:'5px',display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
      {warnings.map((warning) => (
            <Popup
            content={warning.tooltip}
            mouseEnterDelay={200}
            // mouseLeaveDelay={1000}
            on='click'
            trigger={
              <div
                css={{
                  marginLeft: '20px',
                  display: 'flex',
                  height: '30px',
                  cursor:'pointer',
                  fontSize: '12px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  borderRadius: '10px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#E06055',
                  color: 'white',
                  '&:hover': {
                    boxShadow: '0px 3px 6px #757575',
                  }
                }}
              >
                <Icon path={mdiAlert} size="20px" />
                <div style={{ marginLeft: '5px' }}>{warning.message}</div>
              </div>
            }
          />
      ))}
    </div>
  );
};

export default SystemWarning;
