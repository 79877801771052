/* eslint-disable */
import React, { useState, useCallback, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from '@reach/router';
import classNames from 'classnames';
import { mdiDragVertical, mdiPlusBoxOutline, mdiMinusBoxOutline } from '@mdi/js';
import Icon, { SettingsIcon } from '../../components/Icon';
import Button from '../../components/Button';
import { AccountContext } from '../../providers/AccountProvider';
import { getZoneAPI } from '../../api/zone';
import { DASHBOARD } from '../../constants/routes';
import Global from '../../clients/global'


const DragIcon = (props) => <Icon {...props} path={mdiDragVertical} />;
const PlusIcon = (props) => <Icon {...props} path={mdiPlusBoxOutline} />;
const MinusIcon = (props) => <Icon {...props} path={mdiMinusBoxOutline} />;

const ZoneItem = memo(
  React.forwardRef(({ to, item, dragHandleProps,showSettings, ...props }, ref) => {
    const { updateUserZone, userInfo,switchToZone } = useContext(AccountContext);
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [userCount, setUserCount] = useState(0);
    const selected = parseInt(Global.zoneid) === item.id;

    const handleToggle = useCallback(() => {
      setVisible((prev) => !prev);
      if(item.userCount===undefined || userCount===0) {
        updateUserCount()
      }
    }, [setVisible]);


    const handleChangeZone = useCallback(
      (e) => {
                
        e.preventDefault();
        if (!selected) {
          switchToZone(item.id).catch(() => {
            navigate(`${DASHBOARD.ZONE_PREFIX}${Global.zoneid}`, { replace: true });
          });
          navigate(`${DASHBOARD.ZONE_PREFIX}${item.id}`, { replace: true });
        }
      },
      [switchToZone, item.id, selected, navigate, parseInt(Global.zoneid)],
    );

    useEffect(() => {
      if (!selected && visible) {
        setVisible(false);
      }
    }, [selected, visible, setVisible]);

    const updateUserCount = async () => {
      const data = await getZoneAPI(item.id)
      setUserCount(data.userCount)
    }
    // useEffect(() => {
    //   if (selected && item.usersCount===0 && !item.inProgress) {
    //     updateUserZone(item, { inProgress: true });
    //      getZoneAPI(item.id).then((data) => {
    //        console.log(data)
    //       setUserCount(data.userCount)
    //       updateUserZone(data, { ...data, inProgress: false });
    //     });
    //   }
    // }, [selected, item, updateUserZone]);

    const className = classNames('zone-item', !visible && 'short', selected && 'selected');
    const linkClassName = classNames('zone-item-link', !item.id && 'zone-item-link-disabled');

    return (
      <a href="/" to={to} className={linkClassName} onClick={handleChangeZone} ref={ref} {...props}>
        <div className={className}>
          <div className="zone-item-background">
            <h5>{item.name}</h5>
            <div className="zone-item-detail">
              <div>Blocks: {item.blocksCount}</div>
              <div>Users: {userCount}</div>
              {userInfo.zones.length>0 && userInfo.zones.filter(el=>el.id===item.id)[0]?.role==='Zone owner' ? (
                <div>Zone ID: {item.id}</div>
              ):null}
              
            </div>
            <div className="zone-item-accordion">
              <Button variant="clear" className="plus" onClick={handleToggle}>
                <PlusIcon color="#fff" />
              </Button>
              <Button variant="clear" className="minus" onClick={handleToggle}>
                <MinusIcon color="#fff" />
              </Button>
              <div className="line" />
            </div>
            <div className="zone-item-actions">
              <Button variant="clear" {...dragHandleProps}>
                <DragIcon color="#fff" size={0.8} />
              </Button>
              {item.role==='Zone owner' ? (
              <Button variant="clear" onClick={() => showSettings(item.id)}>
                <SettingsIcon color="#fff" size={0.8} />
              </Button>
              ): null}
            </div>
          </div>
        </div>
      </a>
    );
  }),
);

ZoneItem.defaultProps = {
  dragHandleProps: {},
  selected: false,
};

ZoneItem.propTypes = {
  to: PropTypes.string.isRequired,
  dragHandleProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  selected: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    blocksCount: PropTypes.number,
    usersCount: PropTypes.number,
    inProgress: PropTypes.bool,
  }).isRequired,
};

export default ZoneItem;
