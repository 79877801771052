/* eslint-disable */
import React, { memo, useState, useCallback, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Popup, Button, Dropdown, Icon as SIcon } from 'semantic-ui-react';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner/Spinner';
import { AccountContext } from '../../providers/AccountProvider';
import Icon from '@mdi/react';
import { confirmAlert } from 'react-confirm-alert';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import AddMultiFactor from '../User/AddMultiFactor';
import { mdiAlert, mdiArrowLeft, mdiInformationOutline, mdiChevronDown } from '@mdi/js';
import { updateUserAttributes } from '../../api/users';
import EmptyItem from './components/EmptyItem';
import BlockItem from './components/BlockItem';
import GBInput from '../../components/GBInput/GBInput';
import SystemWarning from '../../components/SystemWarning/SystemWarning';
import { toast } from 'react-toastify';
import { checkSystemErrors } from './utils';
import './styles/styles.scss';
import Global from '../../clients/global'

const {
  Header: { Title },
  Container,
} = Content;

const BlocksList = memo(
  ({
    title,
    loading,
    showAddBlock,
    blocks,
    onAddBlock,
    onChangeBlock,
    onCopyBlock,
    onImportBlock,
    onDeleteBlock,
    onRightMoveBlock,
    onLeftMoveBlock,
    maxBlockCount,
    zoneDetail,
    emailStatus,
  }) => {
    const zoneFilter = `ZoneFilter_${zoneDetail.id}`;

    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const { userInfo, userTokenResult } = useContext(AccountContext);
    const [systemWarnings, setSystemWarnings] = useState([]);
    const [isPublicZoneBlock, setIsPublicZoneBlock] = useState(false);
    const divRef = useRef();
    const { REACT_APP_CURRENT_VERSION } = process.env;
    const [Info, setInfo] = useState(false);
    const [complimentaryMessage, setComplimentaryMessage] = useState('');
    const [filterTerm, setFilterTerm] = useState(userInfo[zoneFilter] ?? '');
    const [actions, setActions] =useState([
      { key: 'filter', text: 'Search Blocks by keyword', value: 'filter', icon: 'search',placeholder:'Enter keyword(s) here',clear:false,toolTip:'Filter Blocks by words found in the Block name or homepage text. (case sensitive)' },
    ]);

    useEffect(() => {
      // 6-7-23 new logic to show "complimenary pro" message in top screen for new accounts
      // which have not yet purchased a license.

      if (zoneDetail.plan === 'Pro' && zoneDetail.userlicenses === -1) {
        const givenDate = new Date(userInfo.addedon);
        const currentDay = givenDate.getDate();

        // Add 14 days to the current day
        const newDay = currentDay + 14;

        // Set the new day of the month in the current date
        givenDate.setDate(newDay);

        // Create a current date
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = givenDate.getTime() - currentDate.getTime();

        // Calculate the difference in days
        const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
        let msg = (
          <div>
            🔔 Your free trial of the <b>Pro plan</b> is valid for {daysDifference} more days.{' '}
            <a
              href="https://www.graceblocks.com/support-article/how-to-upgrade"
              target="newWindow"
            >
              Learn more here.
            </a>
          </div>
        );
        // let msg =`🔔 Your free trial of the Pro plan is valid for ${daysDifference} more days. `;
        if (daysDifference < 3) {
          msg = 'Purchase a user license to keep the Pro plan features.';
        }

        setComplimentaryMessage(msg);
      }
      const warnings = checkSystemErrors(zoneDetail, blocks);
      setSystemWarnings(warnings);
      const { REACT_APP_PUBLIC_ZONE_ID } = process.env;
      setIsPublicZoneBlock(parseInt(Global.zoneid) === parseInt(REACT_APP_PUBLIC_ZONE_ID));

      let tempActions=[];
      if(zoneDetail.role==='Zone owner' || zoneDetail.role==='Zone builder') {
        tempActions = [
          { key: 'filter', text: 'Search Blocks by keyword', value: 'filter', icon: 'search',placeholder:'Search Blocks by keyword',clear:false,toolTip:'Filter Blocks by words found in the Block name or homepage text. (case sensitive)' },
          { key: 'import', text: 'Import Block by code', value: 'import', icon: 'upload',placeholder:'Import by Block code',clear:true,toolTip:'Enter the code provided to add a pre-designed Block. (case sensitive)' },
        ];
        setActions(tempActions)
      } 

    }, []);

    const handleHideAlert = useCallback(
      (item, action) => {
        if (action === 'cancel') {
          setShow(false);
        } else {
          onDeleteBlock(item);
          setShow(false);
        }
      },
      [selected, setShow, onDeleteBlock],
    );

    const updateMultiFactor = async (number) => {
      const tempAtt = structuredClone(userInfo.attributes);
      tempAtt.multiFactor = number;
      userInfo.attributes = tempAtt;
      await updateUserAttributes(tempAtt);
      setInfo(true);
    };

    const handleAddBlock = () => {
      if (blocks.length >= maxBlockCount) {
        showUpgradeToast();
      } else {
        onAddBlock();
      }
    };

 


    const handleAction = async (action, value) => {
      if (action === 'filter') {
        filterBlock(value);
      } else if (action === 'import') {
         const result= await onImportBlock(value);
         if(result!=='success') {
          toast.info(
            <div style={{ margin: '5px' }}>
            {result}
            </div>,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 5000,
            },
          );
         }
      }
    };

    const filterBlock = (term) => {
      userInfo[zoneFilter] = term;
      setFilterTerm(term);
    };

    const clearInput = () => {
      userInfo[zoneFilter] = '';
      setFilterTerm('');
    };

    const showUpgradeToast = () => {
      if (zoneDetail.role === 'Zone owner') {
        toast.info(
          <div style={{ margin: '5px' }}>
            You have hit the maximum number of blocks for this plan. Upgrade now by clicking the
            option in the top right corner of the Zone dashboard
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          },
        );
      } else {
        toast.info(
          <div style={{ margin: '5px' }}>
            You have hit the maximum number of blocks for this plan, consider upgrading. Contact
            your Zone owner.
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          },
        );
      }
    };

    const handleDelete = useCallback(
      (item) => {
        setSelected(item);
        setShow(true);

        const message = (
          <div>
            You are attempting to delete the following block: {item.name}
            <p />
            Please consider this action carefully. If you continue, the action is{' '}
            <u>
              <b>not reversable.</b>
            </u>{' '}
            All data inside this Block and any of this Block's related fields residing in other
            Blocks will be fully abolished. Once deleted this data can not be recovered.
          </div>
        );

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <GBConfirm
                title="Delete Block"
                action={deleteBlockLocal}
                actionValue={item}
                buttonLabel="OK"
                message={message}
                width="500px"
                divRef={divRef}
                showInput={true}
                confirmAction="delete"
                confirmWord="CONTINUE WITH IRREVERSABLE DELETE"
                height="500px"
                onClose={onClose}
              />
            );
          },
        });
      },
      [setSelected, setShow],
    );

    const deleteBlockLocal = (item) => {
      if (divRef.current.value === 'CONTINUE WITH IRREVERSABLE DELETE') {
        handleHideAlert(item, 'delete');
      }
    };

    return (
      <>
        <Title>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {title}
            <br />
            <div style={{ fontSize: '13px', marginLeft: '10px' }}>{complimentaryMessage}</div>
            {systemWarnings.length > 0 ? <SystemWarning warnings={systemWarnings} /> : null}
          </div>
        </Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginRight: '20px',
            marginTop: '10px',
          }}
        >
          GraceBlocks Open Beta&nbsp;{' '}
          <Popup
            content={`${REACT_APP_CURRENT_VERSION}`}
            trigger={<Icon path={mdiInformationOutline} size="15px" color="black" />}
          />
        </div>
        {isPublicZoneBlock ? (
          <div style={{ margin: '10px', fontWeight: 'bold' }}>
            An asterisk indicates the minimum plan your Zone must be using in order to copy the
            Block: * Starter plan, ** Pro plan. (Learn more:{' '}
            <a
              href="https://www.graceblocks.com/support-article/graceblocks-plans"
              target="_newWindow"
            >
              GraceBlocks pricing plans
            </a>
            .)
          </div>
        ) : null}

        {/* This is where we'll put Category list. We'll add categoryid tto block and then 
         filter by categoryid. It will be sticky for session and across zoness. 
        */}
        <div
          style={{
            display: 'flex',
            width: '400px',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '30px',
            height:'40px'
          }}
        >
          <GBInput
            actions={actions}
            currentValue={userInfo[zoneFilter]}
            executeAction={handleAction}
            clearInput={clearInput}
            recordSet='blockList' //here set to constant value so it doesn't reset currentvalue. used in blocks to reset term when table and/or filter is changed
          />
       
        </div>

        <Container className="zone-blocks">
          {userInfo.zones.filter(
            (el) =>
              el.attributes !== undefined &&
              el.attributes.multiFactor !== undefined &&
              el.attributes.multiFactor,
          ).length > 0 &&
          userTokenResult.signInProvider === 'password' &&
          userInfo.attributes.multiFactor === undefined ? (
            <div>
              <AddMultiFactor
                callingComponent="blocklist"
                userid={userInfo.id}
                color="#AE007B"
                close={null}
                updateMobile={updateMultiFactor}
              />
            </div>
          ) : (
            <>
              {blocks
                .filter(
                  (e) =>
                    e.information == null ||
                    e.information.includes(filterTerm) ||
                    e.name.toLowerCase().includes(filterTerm.toLowerCase()),
                )
                .map((item, index) => (
                  <BlockItem
                    key={item.key}
                    item={item}
                    onChange={onChangeBlock}
                    onCopy={onCopyBlock}
                    onDelete={handleDelete}
                    firstItem={index === 0}
                    lastItem={index === blocks.length - 1}
                    onRightMove={onRightMoveBlock}
                    onLeftMove={onLeftMoveBlock}
                    count={1}
                  />
                ))}
              {showAddBlock && <EmptyItem onClick={handleAddBlock} />}
            </>
          )}
        </Container>

        {blocks.filter((bl) => !bl.issystemblock).length === 0 &&
        (zoneDetail.role === 'Zone owner' || zoneDetail.role === 'Zone builder') ? (
          <div style={{ marginLeft: '40px', marginTop: '240px' }}>
            <Icon path={mdiArrowLeft} size="20px" /> 🎉Welcome to GraceBlocks! 🎉
            <p />
            For help getting started, Click <b>Explore Public Blocks,</b> under <b>Resources</b> in
            the <b>Left Panel</b>.
            <p />
            You can copy Blocks into your Zone to help jump start your process.
          </div>
        ) : null}
        {loading && <Spinner color="#AE007B" />}
      </>
    );
  },
);

BlocksList.defaultProps = {
  onAddBlock: () => {},
  onChangeBlock: () => {},
  onDeleteBlock: () => {},
  onRightMoveBlock: () => {},
  onLeftMoveBlock: () => {},

  title: '',
  showAddBlock: false,
  loading: false,
};

BlocksList.propTypes = {
  onAddBlock: PropTypes.func,
  onChangeBlock: PropTypes.func,
  onDeleteBlock: PropTypes.func,
  onRightMoveBlock: PropTypes.func,
  onLeftMoveBlock: PropTypes.func,

  title: PropTypes.string,
  showAddBlock: PropTypes.bool,
  loading: PropTypes.bool,
  blocks: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default BlocksList;
