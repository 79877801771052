/* eslint-disable */
import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';

const options = [
  {
    value: 'smoothstep',
    label: 'Smoothstep',
  },
  {
    value: 'step',
    label: 'Step',
  },
  {
    value: 'default',
    label: 'Bezier (default)',
  },
  {
    value: 'straight',
    label: 'Straight',
  },
];

const Select= ({ value, handleId, nodeId })=> {
  // const { setNodes } = useReactFlow();
  // const store = useStoreApi();

  const onChange = (evt) => {
    console.log('testing')
    // const { nodeInternals } = store.getState();
    // setNodes(
    //   Array.from(nodeInternals.values()).map((node) => {
    //     if (node.id === nodeId) {
    //       node.data = {
    //         ...node.data,
    //         selects: {
    //           ...node.data.selects,
    //           [handleId]: evt.target.value,
    //         },
    //       };
    //     }

    //     return node;
    //   }),
    // );
  };

  return (
    <div className="custom-node__select">
      <div>Edge Type</div>
    <div className="nodrag">
      <button onClick={onChange}>Edit</button>
    </div>
       {/* <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}

      <Handle type="source" position={Position.Right} id={handleId} /> 
    </div>
  );
}

const CustomNode =({ id, data }) =>{
  return (
    <>
      <div className="custom-node__header">
        This is a <strong>custom node</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.selects).map((handleId) => (
          <Select key={handleId} nodeId={id} value={data.selects[handleId]} handleId={handleId} />
        ))}
      </div>
    </>
  );
}

export default memo(CustomNode);
