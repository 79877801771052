import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children }) => <>{children}</>;

Body.defaultProps = { children: null };

Body.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Body;
