/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef } from 'react';
import Icon from '@mdi/react';

import {
  mdiAlert,
  mdiClose,
  mdiAsterisk,
  mdiPlus,
  mdiInformationOutline,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js';
import { AccountContext } from '../../providers/AccountProvider';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import c
import { toast } from 'react-toastify';
import {
  updateZoneSettings,
  removePhoneNumber,
  provisionPhoneNumber,
  stripeAddFunds,
  getMessagingFieldsUsedByNumber,
} from '../../api/zone';
import {getTotalMessagingCosts} from '../../api/messages'
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import TextButton from '../../components/TextButton/TextButton';
import AddPhoneNumber from './AddPhoneNumber';
import AddFunds from './AddFunds';
import verifyUSValidPhoneNumber from './verifyUSValidPhoneNumber';

const ZoneMessaging = ({ zoneInfo, color }) => {
  const { userInfo} = useContext(AccountContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [isEmailActive,setIsEmailActive] =useState(true);
  const [showAddNumber, setAddNumber] = useState(false);
  const [showAddFunds, setShowAddFunds] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState(
    zoneInfo.attributes !== null ? zoneInfo.attributes.PhoneNumbers : [],
  );
  const [fromAddresses,setFromAddresses] =useState(zoneInfo.attributes.fromAddresses !== undefined ? zoneInfo.attributes.fromAddresses : []) // {email:"clientemaildelivery@graceblocks.com",isActive:true}
  const [isAutoCharge, setIsAutoCharge] = useState(zoneInfo.attributes.autoCharge);
  const [funds, setFunds] = useState(zoneInfo.messaging_funds_purchased);
  const [isZoneOwnerPhoneValid, setIsZoneOwnerPhoneValid] = useState(true);
  const [totalMessagingCosts,setTotalMessagingCosts] = useState(0);

  const divRef = useRef();
  const divRef2 = useRef();


  useEffect(() => {
    // console.log(userInfo);
    const checkZoneOwner = async () => {
      const result = await verifyUSValidPhoneNumber();
      setIsZoneOwnerPhoneValid(result);
    };
    const getMessageCosts =  async () => {
      const totalcosts = await getTotalMessagingCosts()
      setTotalMessagingCosts(totalcosts[0].total_value)
    }
    //only if on free plan, check zone owner number for valid use for twilio .
    if (zoneInfo.plan === 'Free') {
      checkZoneOwner();
    }
    getMessageCosts()
  }, []);

  const AddNumber = async (selectedCountry, areaCodeSpecified, useInternational, planId) => {
    const result = await provisionPhoneNumber(
      '',
      selectedCountry,
      areaCodeSpecified,
      useInternational,
      zoneInfo.stripeInfo.customerId,
      planId,
    );
    if(result.includes('Error')) {
      toast.info(
        <div style={{ margin: '5px' }}>
         {result}
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        },
      );

    } else {
      setPhoneNumbers(result);  //result would be full array of all configured numbers if successful.
      setAddNumber(false);
    }
  };

  const updateAutoCharge = async (isAutoChargeEnabled) => {
    if (isAutoChargeEnabled) {
      const message = (
        <div>
          You have chosen to enable auto-charge. If you continue, you authorize GraceRock, d.b.a.
          GraceBlocks, to charge you the amount of your most recent messaging funds purchase
          automatically when your funds balance reaches $10.00 US Dollars.
          <p />
          <p />
          The default payment method for this Zone will be used to process these transcations.
          <p />
          <p />
          By clicking the Enable auto-recharge button below you agree to these terms and the
          GraceBlocks{' '}
          <a href=" https://www.graceblocks.com/termsofservice" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="https://www.graceblocks.com/privacy" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      );
      return (
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title="Enable auto-charge"
              action={enableAutoCharge}
              actionValue={isAutoChargeEnabled}
              buttonLabel="Enable auto-recharge"
              buttonWidth="150px"
              cancelButtonWidth="150px"
              message={message}
              divRef={divRef}
              showInput={false}
              height="500px"
              onClose={onClose}
            />
          );
        },
      }))
    } else {
      zoneInfo.attributes.autoCharge = isAutoChargeEnabled;
      await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
      setIsAutoCharge(isAutoChargeEnabled);

      const message =<div>
          You have chosen to disable auto-recharge. When your messagiing funds reaches $0.00 US Dollars,
           you will not be able to send or receive messages until more funds are purchased.
            <p/><p/>
          If you choose to re-activate this option, the auto-recharge option will be re-activated using
          your  most recently purchased messaging funds purchase amount.
        </div>

      return (
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <GBConfirm
                title="Auto-recharge disabled"
                action={onClose}
                showCancelButton={false}
                buttonLabel="OK"
                message={message}
                showInput={false}
                height="400px"
              />
            );
          },
        }))

    }
  };

  const updatePhoneRestrictions = async (number) => {
    const tempNumbers = [...phoneNumbers];
    const idx = tempNumbers.findIndex((itm) => itm.number === number);
    if (idx !== -1) {
      const isRes = tempNumbers[idx].isInternational;
      tempNumbers[idx].isInternational = !isRes;
      zoneInfo.attributes.PhoneNumbers = tempNumbers;
      await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
      setPhoneNumbers(tempNumbers);
    }
  };

  const enableInternational = (number) => {
    if (divRef2.current.value.toLowerCase() === 'i agree') {
      // setUseInternational(!useInternational)
      updatePhoneRestrictions(number);
    }
  };

  const enableAutoCharge = async (isAutoChargeEnabled) => {
      zoneInfo.attributes.autoCharge = isAutoChargeEnabled;
      await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
      setIsAutoCharge(isAutoChargeEnabled);
  };

  const confirmInternational = (number) => {
    const tempNumbers = [...phoneNumbers];
    let isEnabled = true;
    let country = '';
    const idx = tempNumbers.findIndex((itm) => itm.number === number);
    if (idx !== -1) {
      isEnabled = tempNumbers[idx].isInternational;
      country = tempNumbers[idx].country;
    }

    const message = !isEnabled ? (
      <div>
        Are you sure you want to activate international texting for this number? Currently, each
        individual incoming or outgoing text will incur a charge based on the international rates
        and can be as much as $0.22 US per text message segment sent and/or received. This price is
        subject to change.{' '}
        <a
          href="https://www.graceblocks.com/messaging-pricing"
          target="_
    blank"
        >
          View current rates
        </a>
        .
      </div>
    ) : (
      <div>
        Are you sure you want to disable international texting for this number? This will cause any
        attempts to send or receive texts to/from numbers outside the country of this Phone Number{' '}
        {country} to fail.
      </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={isEnabled ? 'Disable international texting' : 'International text fees'}
            action={enableInternational}
            actionValue={number}
            buttonLabel="OK"
            message={message}
            divRef={divRef2}
            showInput={true}
            confirmAction=""
            confirmWord="I agree"
            height="450px"
            onClose={onClose}
          />
        );
      },
    });
  };

  const DeactivatePhone = async (number) => {
    if (divRef.current.value === 'deactivate') {
      const tempNumbers = [...phoneNumbers];
      const idx = tempNumbers.findIndex((itm) => itm.number === number);

      if (idx !== -1) {
        await removePhoneNumber(number);
        tempNumbers[idx].isActive = false;
        zoneInfo.attributes.PhoneNumbers = tempNumbers;
        await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
        setPhoneNumbers(tempNumbers);
      }
    }
  };

  const DeactivateEmail = async (email) => {
    if (divRef.current.value === 'deactivate') {
      const tempEmails = [...fromAddresses];
      const idx = tempEmails.findIndex((itm) => itm.email === email);

      if (idx !== -1) {
        tempEmails[idx].isActive = false;
        zoneInfo.attributes.fromAddresses = tempEmails;
        await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
        setFromAddresses(tempEmails);
      }
    }
  };



  const confirmDeactivate = async (number) => {
    const msgfields = await getMessagingFieldsUsedByNumber(number);
    const isLastNumber = phoneNumbers.filter((el) => el.isActive === true).length <= 1;

    let fields = <p>Number not currently in use.</p>;
    if (msgfields.length > 0) {
      fields = msgfields.map((itm) => (
        <li key={itm.name}>
          {itm.blockname} &gt; {itm.name} &gt; {itm.header}
        </li>
      ));
    }
    const message = (
      <div>
        Are you sure you want to deactivate this phone number? The following messaging threads using
        this number will be impacted. They will still be visible but, sending and receiving any new
        messages will not be possible.
        <p />
        {fields}
        {isLastNumber ? (
          <>
            <p />
            <p>
              This is the only messaging thread number for the zone. Until a new number is added,
              the option to create a message field will not be present.
            </p>
          </>
        ) : null}
      </div>
    );
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Deactivate phone number"
            action={DeactivatePhone}
            actionValue={number}
            buttonLabel="OK"
            message={message}
            width="500px"
            divRef={divRef}
            showInput={true}
            confirmAction="deactivate"
            confirmWord="deactivate"
            height={isLastNumber ? '500px' : '430px'}
            onClose={onClose}
          />
        );
      },
    });
  };

  const confirmDeactivateEmail = async (email) => {
   
    const message = (
      <div>
        Are you sure you want to deactivate this email address? It will no longer be listed as available
        email address when configuring email delivery for future fields.
        <p />
      </div>
    );
    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Deactivate email"
            action={DeactivateEmail}
            actionValue={email}
            buttonLabel="OK"
            message={message}
            width="500px"
            divRef={divRef}
            showInput={true}
            confirmAction="deactivate"
            confirmWord="deactivate"
            height={'430px'}
            onClose={onClose}
          />
        );
      },
    });
  };




  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const showEmailAddresses = () =>
  fromAddresses.length> 0 && fromAddresses.filter(itm=>itm.isActive===isEmailActive).map(itm=> (
    <div
          key={itm.number}
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '700px',
          }}
        >
          <div>{itm.email}</div>
          <div style={{marginLeft:'20px'}}>
            {itm.isActive && itm.email !=='clientemaildelivery@graceblocks.com' ? (
              <GBButton
                text="Deactivate"
                Action={confirmDeactivateEmail}
                width="100px"
                ActionValue={itm.email}
                textColor="#757575"
                borderRadius="10px"
                hoverBackgroundColor="#F1EFEF"
                textHoverColor="#757575"
                hoverColor="#F1EFEF"
                icon={mdiClose}
                iconColor={'#F6685E'}
                iconHoverBackgroundColor={'#F6685E'}
                iconHoverColor="white"
                iconPosition="left"
                hoverBorder="1px solid #757575"
              />
            ) : (
              <div style={{ color: '#00000080', marginRight: '5px' }}>Deactivated</div>
            )}
          </div>
        </div>
  ))

  const showPhoneNumbers = () =>
    phoneNumbers
      .filter((itm) => itm.isActive === isActive)
      .map((itm) => (
        <div
          key={itm.number}
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '700px',
          }}
        >
          <div
            style={{
              zIndex: 10,
              width: '150px',
              fontWeight: 'bold',
              color: itm.isActive ? '#000000DE' : '#00000080',
            }}
          >
            {itm.number}
          </div>
          <div style={{ width: '100px' }}>{itm.country}</div>
          <div style={{ width: '150px' }}>
            <GBSwitch
              color={itm.isInternational ? '#9CCC65' : '#E06055'}
              text={itm.isInternational ? 'ENABLED' : 'DISABLED'}
              isChecked={itm.isInternational}
              // Action={itm.isInternational ? updatePhoneRestrictions : confirmInternational}
              Action={confirmInternational}
              ActionValue={itm.number}
            />
          </div>
          <div>
            {itm.isActive ? (
              <GBButton
                text="Deactivate"
                Action={confirmDeactivate}
                width="100px"
                ActionValue={itm.number}
                textColor="#757575"
                borderRadius="10px"
                hoverBackgroundColor="#F1EFEF"
                textHoverColor="#757575"
                hoverColor="#F1EFEF"
                icon={mdiClose}
                iconColor={'#F6685E'}
                iconHoverBackgroundColor={'#F6685E'}
                iconHoverColor="white"
                iconPosition="left"
                hoverBorder="1px solid #757575"
              />
            ) : (
              <div style={{ color: '#00000080', marginRight: '5px' }}>Deactivated</div>
            )}
          </div>
        </div>
      ));

  const toggleShowAdd = () => {
    setAddNumber(!showAddNumber);
  };

  const toggleShowFunds = () => {
    if (zoneInfo.plan === 'Free') {
      toast.info(
        <div style={{ margin: '5px' }}>
          To add funds, you must upgrade to a paid subscription plan.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        },
      );
    } else {
      setAddNumber(false);
      setShowAddFunds(!showAddFunds);
    }
  };

  const addMessagingFunds = async (priceId, amount, promoId = null) => {
    const newTotal = await stripeAddFunds(zoneInfo.stripeInfo, priceId, amount, promoId);
    if (newTotal !== 0) {
      setFunds(newTotal);
    } else {
      // show toast that something went wrong....
    }

    // We always store the last amount purchased as their Auto-Charge amount.
    if (promoId === null) {
      zoneInfo.attributes.autoChargeAmount = amount;
      await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
    }
    setShowAddFunds(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {showAddNumber ? (
        <div style={{ position: 'absolute', zIndex: 500, top: '-50px', left: '200px' }}>
          <AddPhoneNumber color={color} toggleShowAdd={toggleShowAdd} AddNumber={AddNumber} />
        </div>
      ) : null}
      {showAddFunds ? (
        <div style={{ position: 'absolute', zIndex: 500, top: '-50px', left: '200px' }}>
          <AddFunds
            autoCharge={isAutoCharge}
            color={color}
            toggleShowFunds={toggleShowFunds}
            addMessagingFunds={addMessagingFunds}
            updateAutoCharge={updateAutoCharge}
          />
        </div>
      ) : null}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
          //   width: '90%',
        }}
      >
        <div style={{ marginLeft: '20px', whiteSpace: 'nowrap' }}>
          <TextButton
            Action={toggleExpand}
            textColor="black"
            hoverColor="#75757580"
            fontSize="13px"
            icon={isExpanded ? mdiChevronUp : mdiChevronDown}
            text="Zone messaging number(s), emails and funds"
            iconPosition="left"
          />
        </div>
        <div style={{ marginRight: '20px' }}>
          <GBButton
            textColor="#1A1A1A"
            Action={toggleShowFunds}
            text="Add funds"
            color="#FFBE24"
            width="100px"
            height="25px"
            borderRadius="15px"
          />
        </div>
      </div>
      {isExpanded ? (
        <div
          style={{
            marginTop: '10px',
            marginLeft: '65px',
            display: 'flex',
            marginRight: '20px',
            flexDirection: 'column',
          }}
        >
          <div className="row" style={{ marginLeft: '3px' }}>
            <div>Current messaging funds balance:</div>
            <div
              style={{
                marginLeft: '10px',
                fontSize: '15px',
                fontWeight: 'bold',
                marginRight: '20px',
              }}
            >
              ${(funds - totalMessagingCosts).toLocaleString('en', {minimumFractionDigits:2,maximumFractionDigits:2})}
            </div>
            <div style={{ color: color }}>
              <a href="https://www.graceblocks.com/messaging-pricing" target="_blank">
                View messaging rates
              </a>
            </div>
          </div>
          {zoneInfo.messaging_funds_purchased > 2 ? (
            <div style={{display:'flex',flexDirection:'row',alignItems:'center', marginLeft: '0px', marginTop: '10px',width:'100%' }}>
              <div style={{whiteSpace:'nowrap'}}>Auto-recharge most recent funds amount when funds are low is:</div>
              <div style={{ width: '10px' }}></div>
              <GBSwitch
                color={isAutoCharge ? '#9CCC65' : '#E06055'}
                text={isAutoCharge ? 'ENABLED' : 'DISABLED'}
                isChecked={isAutoCharge}
                Action={updateAutoCharge}
                ActionValue={!isAutoCharge}
              />
            </div>
          ) : null}

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginRight: '20px',
              }}
            >
              <div
                style={{
                  borderBottom: '1px solid #eee',
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <div className="row" style={{ marginLeft: '0px' }}>
                  <div
                    onClick={() => setIsActive(true)}
                    css={{
                      cursor: 'pointer',
                      fontSize: '15px',
                      marginRight: '20px',
                      color: isActive === true ? color : '#757575',
                      borderBottom: isActive === true ? `3px solid ${color}` : null,
                      '&:hover': {
                        color: color,
                      },
                    }}
                  >
                    Active
                  </div>
                  <div
                    onClick={() => setIsActive(false)}
                    css={{
                      cursor: 'pointer',
                      fontSize: '15px',
                      color: isActive === false ? color : '#757575',
                      borderBottom: isActive === false ? `3px solid ${color}` : null,
                      '&:hover': {
                        color: color,
                      },
                    }}
                  >
                    Inactive
                  </div>
                </div>
                <div>
                  {zoneInfo.plan !== 'Free' ? (
                    <GBButton
                      height="30px"
                      text="Add number"
                      Action={toggleShowAdd}
                      width="150px"
                      // ActionValue={itm.number}
                      textColor="#707070"
                      borderRadius="10px"
                      // hoverBackgroundColor="#F1EFEF"
                      textHoverColor="#757575"
                      hoverColor="#F1EFEF"
                      icon={mdiPlus}
                      iconColor="#707070"
                      iconHoverBackgroundColor={'#707070'}
                      iconHoverColor="white"
                      iconPosition="left"
                      hoverBorder="2px solid #757575"
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: '#AE007B',
                        alignItems: 'center',
                        width: '400px',
                      }}
                    >
                      <Icon
                        path={mdiAsterisk}
                        size="30px"
                        color="#AE007B"
                        style={{ marginRight: '10px' }}
                      />{' '}
                      {isZoneOwnerPhoneValid && zoneInfo.attributes.PhoneNumbers === undefined
                        ? 'Automatically obtain your first number by adding a messaging column to a tab, or upgrade now to add other phone numbers.'
                        : 'Upgrade now to add additional phone numbers.'}
                    </div>
                  )}
                </div>



              </div>

              {phoneNumbers && phoneNumbers.length > 0 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '400px',
                    }}
                  >
                    <div style={{ width: '150px' }}>Number</div>
                    <div style={{ width: '100px' }}>Country</div>
                    <div style={{ width: '150px' }}>International texts</div>
                  </div>
                  {showPhoneNumbers()}
                </>
              ) : (
                'No active numbers'
              )}
            </div>
          </div>

        {/* Email Section */}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginRight: '20px',
              }}
            >
              <div
                style={{
                  borderBottom: '1px solid #eee',
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <div className="row" style={{ marginLeft: '0px' }}>
                  <div
                    onClick={() => setIsEmailActive(true)}
                    css={{
                      cursor: 'pointer',
                      fontSize: '15px',
                      marginRight: '20px',
                      color: isEmailActive === true ? color : '#757575',
                      borderBottom: isEmailActive === true ? `3px solid ${color}` : null,
                      '&:hover': {
                        color: color,
                      },
                    }}
                  >
                    Active
                  </div>
                  <div
                    onClick={() => setIsEmailActive(false)}
                    css={{
                      cursor: 'pointer',
                      fontSize: '15px',
                      color: isEmailActive === false ? color : '#757575',
                      borderBottom: isEmailActive === false ? `3px solid ${color}` : null,
                      '&:hover': {
                        color: color,
                      },
                    }}
                  >
                    Inactive
                  </div>
                </div>
                <div>
                  {zoneInfo.plan !== 'Free' ? (
                    <GBButton
                      height="30px"
                      text="Request custom email"
                      Action={()=>window.open('https://www.graceblocks.com/support-article/custom-from-email-addresses-support')}
                      width="220px"
                      // ActionValue={itm.number}
                      textColor="#707070"
                      borderRadius="10px"
                      // hoverBackgroundColor="#F1EFEF"
                      textHoverColor="#757575"
                      hoverColor="#F1EFEF"
                      icon={mdiPlus}
                      iconColor="#707070"
                      iconHoverBackgroundColor={'#707070'}
                      iconHoverColor="white"
                      iconPosition="left"
                      hoverBorder="2px solid #757575"
                    />
                  ) : null}
                </div>
              </div>
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '400px',
                    }}
                  >
                    {fromAddresses.length>0 ? (
                       <div style={{ width: '150px' }}>Email address</div>
                    ):null}
                   
                  </div>
                  {showEmailAddresses()}
                </>
            </div>
          </div>


        </div>
      ) : null}
    </div>
  );
};

export default ZoneMessaging;
