import React from 'react';
import BootstrapForm from 'react-bootstrap/Form';
import {
  FormControl,
  FormPasswordControl,
  FormSelectControl,
  FormPhoneNumberControl,
  FormColorPickerControl,
  FormIconPickerControl,
  FormDraftControl,
} from './Controls';
import FormGroup from './Form.Group';
import ControlTooltip from './ControlTooltip';

const Form = (props) => {
  return <BootstrapForm {...props} />;
};

Form.Group = FormGroup;
Form.Control = FormControl;
Form.PasswordControl = FormPasswordControl;
Form.SelectControl = FormSelectControl;
Form.PhoneNumberControl = FormPhoneNumberControl;
Form.ColorPickerControl = FormColorPickerControl;
Form.IconPickerControl = FormIconPickerControl;
Form.DraftControl = FormDraftControl;

Form.Label = BootstrapForm.Label;
Form.Text = BootstrapForm.Text;
Form.Feedback = BootstrapForm.Control.Feedback;
Form.ControlTooltip = ControlTooltip;

export default Form;
