/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { mdiChevronDown } from '@mdi/js';
import PNL from 'google-libphonenumber';
import { getRegions } from './phoneUtils';
import Icon from '../../../Icon';
import CellPhoneIcon from './cellphone_24px.svg';
import './styles.scss';

const phoneUtil = PNL.PhoneNumberUtil.getInstance();

const onlyCountryCodes = [
  'AU',
  'AT',
  'BE',
  'CA',
  'CL',
  'CZ',
  'DE',
  'DK',
  'EE',
  'FR',
  'IL',
  'HK',
  'HU',
  'LT',
  'MY',
  'NL',
  'PH',
  'PL',
  'PT',
  'SG',
  'ZA',
  'KR',
  'CH',
  'GB',
  'US',
];

const FormPhoneNumberControl = ({
  className,
  selectPlaceholder,
  value,
  hasEmpty,
  disabled,
  id: controlId,
  color,
  ...props
}) => {
  const regions = getRegions({ onlyCountryCodes, favoriteCountryCodes: ['US'] });
  const [selectedRegion, setSelectedRegion] = useState(regions[0]);
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [number, setNumber] = useState('');
  const [isInputOnFocus, setIsInputOnFocus] = useState(false);

  let placeholder;
  let mask = '9';
  let placeholderChar = ' ';
  if (selectedRegion) {
    placeholder = selectedRegion.localNumberExample;
    mask = selectedRegion.numberMask;
    placeholderChar = '_';
  }

  const getPhoneNumber = useCallback(() => {
    if (selectedRegion) {
      return `+${selectedRegion.iso2}${number}`;
    }
    return '';
  }, [number, selectedRegion]);

  const onChange = useCallback(() => {
    if (props.onChange) {
      props.onChange(getPhoneNumber());
    }
  }, [getPhoneNumber, props]);

  const setStateForRegion = (region) => {
    setSelectedRegion(region || null);
    setNumberDisabled(!region);
    setNumber('');
  };

  const setRegionById = (regionId) => {
    const regionsById = regions.filter((region) => {
      return region.id === regionId;
    });
    setStateForRegion(regionsById.length && regionsById[0]);
  };

  const selectRegion = (event) => {
    setRegionById(event.target.value, () => {
      onChange();
    });
  };

  const onNumberChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    setNumber(inputValue);
  };

  const onInputClick = (isOnFocus, e) => {
    const { onFocus, onBlur } = props;
    setIsInputOnFocus(isOnFocus);
    if (isOnFocus) {
      onFocus(e);
    } else {
      onBlur(e);
    }
  };

  const setRegionByCountry = (regionCode, countryCode) => {
    const filteredRegions = regions.filter((region) => {
      return region.id === regionCode + countryCode;
    });
    setStateForRegion(filteredRegions.length && filteredRegions[0]);
  };

  const parsePhoneNumber = (v) => {
    let phone = '';
    try {
      phone = phoneUtil.parse(v);
    } catch (err) {
      // console.warn('Parse phone number error');
    }
    if (phone) {
      const parsedNumber = phone.values_[2].toString(); // eslint-disable-line no-underscore-dangle
      if (parsedNumber !== number) {
        setRegionByCountry(phoneUtil.getRegionCodeForNumber(phone), phone.getCountryCode());
        setNumber(parsedNumber);
      }
    }
  };
  useEffect(() => {
    onChange();
  }, [selectedRegion, numberDisabled, number, onChange]);

  useEffect(() => {
    if (value !== undefined) {
      parsePhoneNumber(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (!value) {
  //     selectRegion({ target: { value: 'US1' } });
  //   }
  // }, []);

  return (
    <>
      <div className={classNames('input-group', 'input-phone-arrow', className)}>
        <div className="input-group-prepend input-group-left-padding" style={{ background: color }} />
        <BootstrapForm.Control
          as="select"
          size="lg"
          className={classNames(
            'input-select-phone',
            'input-phone-left',
            'form-control-lg',
            isInputOnFocus ? 'input-phone-focus' : '',
          )}
          required={value === ''} // Solution for placeholder.
          {...props}
          onChange={selectRegion}
          onBlur={(e) => onInputClick(false, e)}
          onClick={(e) => onInputClick(true, e)}
          value={selectedRegion ? selectedRegion.id : ''}
          id={`${controlId}-region`}
        >
          <option value="" key={selectPlaceholder} className="empty-option" hidden={!hasEmpty}>
            {selectPlaceholder}
          </option>
          {regions.map((v) => (
            <option key={v.id} value={v.id} className="presented-option">
              {`${v.region} +${v.iso2}`}
            </option>
          ))}
        </BootstrapForm.Control>
        <span>
          <Icon path={mdiChevronDown} size={0.75} />
        </span>
        <img 
          className="input-group-left-icon input-group-left-icon-phone"
          src={CellPhoneIcon}
          alt="cellPhoneIcon"
        />
        <div style={{width:'5px'}}></div>
        <InputMask
          mask={mask}
          placeholder={placeholder}
          maskChar={placeholderChar}
          value={number}
          onChange={onNumberChange}
          onFocus={(e) => onInputClick(true, e)}
          onBlur={(e) => onInputClick(false, e)}
          disabled={disabled !== undefined ? disabled : numberDisabled}
          className={classNames(isInputOnFocus ? 'input-phone-focus' : '')}
        >
          {(inputProps) => (
            <BootstrapForm.Control
              type="text"
              size="lg"
              {...inputProps}
              className={classNames('input-phone-right', isInputOnFocus ? 'input-phone-focus' : '')}
              disabled={disabled !== undefined ? disabled : numberDisabled}
              id={controlId}
            />
          )}
        </InputMask>
      </div>
    </>
  );
};

FormPhoneNumberControl.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},

  id: undefined,
  label: null,
  selectPlaceholder: '',
  value: '',
  className: null,
  hasEmpty: false,
  disabled: undefined,
};

FormPhoneNumberControl.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  id: PropTypes.string,
  label: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  className: PropTypes.string,
  hasEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormPhoneNumberControl.whyDidYouRender = true;

export default FormPhoneNumberControl;
