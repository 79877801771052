/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Dropdown, Grid, GridColumn, GridRow, Form, Radio, TextArea } from 'semantic-ui-react';
import cloudrun from '../../../../clients/httpcloudrun';
import { AccountContext } from '../../../../providers/AccountProvider';
import {mdiClose,mdiFilter} from '@mdi/js';
import { getTableAPI, getFieldsAPI, updateTableInfoAPI } from '../../../../api/tables';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import FilterData from '../FilterData/index';
import FilterTags from '../FilterData/FilterTags';
import GBButton from 'src/components/GBButton/GBButton';
import TextButton from 'src/components/TextButton/TextButton';

class LookupField extends Component {
  // Need to lookup other "relational" fields in table and display those field headers as "Baseline" field
  constructor(props) {
    super(props);
    const { columnconfigdata} = this.props;
    this.state = {
      relationfields: [],
      tablefields: [],
      chosenField: '',
      relationField: {},
      relationFieldName: '',
      currentSpecialFunction: '',
      lookupfield: '',
      zIndex: 1,
      showLookupOptions: false,
      showSpecialFunctions: false,
      showNumericFunctions:false,
      showDateFunctions:false,
      showFilters:false,
      radiovalue: 'all',
      specificvalue: '',
      showAdvanced: false,
      callout: '',
      tableinfo: '',
      enableNotify: columnconfigdata.enableNotify !==undefined ? columnconfigdata.enableNotify : false,
      filters:[],
      isPrivate: columnconfigdata.isPrivate ?? false,
      cacheResults: columnconfigdata.cacheResults ??  false
    };
  }


  static contextType = AccountContext;
  
  componentDidMount() {
    const { updateHeight } = this.props;
    this.FindExistingRelationshipFields();
    updateHeight(600);
  }

  handleChange = (value) => {
    const { specificvalue } = this.state;
    this.setState({ radiovalue: value, specificvalue: value !== 'specific' ? '' : specificvalue });
  };

  handleChange2 = (value) => {
    this.setState({ specificvalue: value, radiovalue: 'specific' });
  };

  toggleCacheResults =() =>{
    const {cacheResults} = this.state;
    this.setState({cacheResults: ! cacheResults})
  }


  toggleShowFilters =() => {
    const {showFilters,chosenField} = this.state;
    if(chosenField !=='') {
      this.setState({showFilters:!showFilters})
    }
  }

  updateFilters = (updFilters) =>{
    const {relTableInfo}=this.state;
    updFilters.filter(el=>el.uitype===1).map(itm=>{
      if(itm.filterjointable===undefined) {
        const idx = relTableInfo.columns.findIndex(el=>el.data===itm.field)
        if(idx !==-1) {
          itm.filterjointable = relTableInfo.columns[idx].source.jointable;
          itm.filterreturnfield =relTableInfo.columns[idx].source.returnfield;
          itm.filterlookuptable=relTableInfo.columns[idx].source.lookuptable;
        }
      }
    })
    this.setState({filters:updFilters,showFilters:false})
    // console.log(relTableInfo)
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  FindExistingRelationshipFields = async () => {
    const { tableid, columnconfigdata } = this.props;
    const { tableinfo } = await getTableAPI(tableid);
    let relationFieldName = '';
    let lookupfield = '';
    let currentSpecialFunction = '';
    let callout = '';

    if (columnconfigdata.uitype == 18) {
      const fieldsandTable = await getFieldsAPI(columnconfigdata.source.lookuptableid);
      const relationtablefields = fieldsandTable.fields;
    
      relationFieldName = tableinfo.columns.filter(
        (el) => el.data == columnconfigdata.source.basefield,
      )[0].header;

      currentSpecialFunction = columnconfigdata.specialfunction;

      lookupfield = relationtablefields.filter(
        (el) => el.displayfield == columnconfigdata.source.lookupfield.replace('_options','') 
        || el.key==columnconfigdata.source.lookupfield.replace('_options','') // 8-17-2020 changed to check both displayfield and key field. Special functions use real field name, regular lookups use the data_join for lookup fields.
      )[0].text;
   

      callout = columnconfigdata.callout;
    }

    // const {tableinfo} = tabledata;
    const tmpfields = [];

    tableinfo.columns.forEach((el) => {
      if (el.jointype === 'relational') {
        tmpfields.push({
          key: el.data,
          value: el.data,
          text: el.header,
          lookup: el.lookup,
          source: el.source,
          lookuptableid: el.source.lookuptableid
        });
      }
    });
    this.setState({
      relationfields: tmpfields,
      relationFieldName,
      lookupfield,
      currentSpecialFunction,
      callout,
      tableinfo,
    });
  };

  toggleNotifications=()=>{
    const{enableNotify} = this.state;
    this.setState({enableNotify: !enableNotify})
  }

  SelectedFieldHandler = async (data) => {
  
    const { relationfields } = this.state;
    const item = relationfields.filter((el) => el.value === data);
    
    const fieldAndTable = await getFieldsAPI(item[0].lookuptableid);
    const fields = fieldAndTable.fields;
    
    // we get the tableinfo from relational table. Here we will strip out relational and lookup columns & message field
    // so they are not included in the filter logic.
    const relTableInfo = fieldAndTable.tableinfo;
    relTableInfo.columns= fieldAndTable.tableinfo.columns.filter(col=>col.uitype !==18 && col.uitype !==12);

    // We now remove any fields with jointype=relational-lookup-lookup as we only allow 2 levels of lookup.
    let availablefields = fields.filter((el) => !['relational-lookup-lookup','relational-lookup-special','relational-formula-formula'].includes(el.jointype)  && el.key !== item[0].source.returnfield && el.uitype !==12) ;

    availablefields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
    this.setState({ tablefields: availablefields, relationField: item, relTableInfo,relTableId:item[0].lookuptableid});
  };

  ChosenHandler = async (field) => {
    const { tablefields, relationField } = this.state;
    const item = tablefields.filter((el) => el.value === field);
  
    this.setState({ chosenField: item });

    if (relationField[0].lookup !== 'single' && item[0].uitype !==4) {
      if (item[0].uitype === 7 || (item[0].source && item[0].source.lookupuitype===7)) {
        this.setState({ showSpecialFunctions: true, showLookupOptions: true, showAdvanced: true,showNumericFunctions:false });
      
      } else if([23].includes(item[0].uitype) || (item[0].source && [23].includes(item[0].source.lookupuitype)) || ['count','countdistinct'].includes(item[0].specialfunction) || item[0].fieldType==='numeric' ) {
        this.setState({ showNumericFunctions:true, showDateFunctions:false, showSpecialFunctions: false, showLookupOptions: true, showAdvanced: true });
      } else if ([19,20,22].includes(item[0].uitype) || (item[0].source && [19,20,22].includes(item[0].source.lookupuitype)) || item[0].fieldType==='date' )
      {
        this.setState({ showNumericFunctions:false, showDateFunctions:true, showSpecialFunctions: false, showLookupOptions: true, showAdvanced: true });
      }
      else {
        this.setState({ showSpecialFunctions: false, showNumericFunctions:false, showLookupOptions: true, showAdvanced: true });
      }
    } else { //Single relational...don't show any options
      this.setState({ showSpecialFunctions: false, showLookupOptions: false, showAdvanced: false,showNumericFunctions:false });
    }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
    // !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  Save = async () => {
    const { columnconfigdata, tableid, header } = this.props;
    const {
      chosenField,
      specificvalue,
      relationField,
      radiovalue,
      tableinfo,
      currentSpecialFunction,
      callout,
      enableNotify,
      filters,
      isPrivate,
      cacheResults
    } = this.state;
    
    const fieldname = columnconfigdata.data; 
    const lookuptype = relationField[0] && relationField[0].lookup !== undefined ? relationField[0].lookup : 'single';

    try{
      
      const rowindex = tableinfo.columns.findIndex((el) => el.data == columnconfigdata.data);
      let cleanFilters=[];

      if (rowindex !== -1) {
        tableinfo.columns[rowindex].header = header;

        if(columnconfigdata.uitype !==18) {
            cleanFilters=filters.filter((itm) => itm.operator !== undefined)
        } 

        if (callout === '') {
          delete tableinfo.columns[rowindex].callout;
        } else {
          tableinfo.columns[rowindex].callout = callout;
        }

        if(isPrivate) {
          tableinfo.columns[rowindex].isPrivate =true;
        } else {
          delete tableinfo.columns[rowindex].isPrivate;
        }

        if((chosenField !=='' && chosenField[0].uitype===8) || (columnconfigdata.source && columnconfigdata.source.lookupuitype===8))
        {
          tableinfo.columns[rowindex].enableNotify=enableNotify;
        }

        if(columnconfigdata.uitype !==18){
          tableinfo.columns[rowindex].filters= cleanFilters;
        }

        tableinfo.columns[rowindex].cacheResults=cacheResults;

        await updateTableInfoAPI(tableid, tableinfo,columnconfigdata);
      }

    if(columnconfigdata.uitype !== 18) {
      let  newfieldlookup = (chosenField[0].lookup === undefined && chosenField[0].uitype !==16) ? 'text' : chosenField[0].uitype ===16 ? 'formula' : chosenField[0].lookup;
      // 12-30-2020 For attachments, we use the renderer attribute, for other lookups is uses renderandedit attribute.
      // don't delete renderer for Attachment
      if(columnconfigdata.renderer !=='AttachmentsRenderer'){
        delete tableinfo.columns[rowindex].renderer;
        }

      if (radiovalue==='specific') {

        let tempsourcejointable ='';

        if(chosenField[0].source !== undefined && (chosenField[0].jointype==='relational-special' ||  chosenField[0].jointype==='relational-lookup' || chosenField[0].jointype==='relational-special-special' ))
        {
          tempsourcejointable = [
            {srctable: chosenField[0].source.grouptable,srcjointable: chosenField[0].source.sourcejointable,datafield: chosenField[0].source.sourcejointable_datafield,returnfield:chosenField[0].source.sourcejointable_returnfield}, 
            {reltable: chosenField[0].source.lookuptable,reljointable:chosenField[0].source.relationjointable,datafield: chosenField[0].source.relationjointable_datafield,returnfield:chosenField[0].source.relationjointable_returnfield},
          ]
          
        } else if (chosenField[0].source !== undefined)
        {
          tempsourcejointable = chosenField[0].source.jointable
        } else{
          tempsourcejointable=''
        }

        const payload={
          tableid,
          fieldname,
          lookuptype,
          basefieldLookup:newfieldlookup,
          sourcejointable: tempsourcejointable,
          specialFunction: specificvalue,
          relationFieldObject: relationField[0],
          chosenFieldObject: chosenField[0],
          filters:cleanFilters
        }

        if(chosenField[0].jointype==='relational-lookup' || chosenField[0].jointype==='relational-special' ||  chosenField[0].jointype==='relational-special-special'){
           await cloudrun.post('/addLookupLookupFieldSpecial', { payload });
        } else {
           await cloudrun.post('/addLookupFieldSpecial', { payload });
        }

      } else if (radiovalue === 'all' || radiovalue === 'unique') {
     
        const specialfunction = lookuptype==='single' && chosenField[0].specialfunction !==undefined ? chosenField[0].specialfunction : radiovalue;
        newfieldlookup = (chosenField[0].lookup === undefined && chosenField[0].uitype !==16) ? 'text' : chosenField[0].uitype ===16 ? 'formula' : chosenField[0].lookup;

        let tempsourcejointable='';

        if(chosenField[0].source !== undefined && ['relational-lookup','relational-formula'].includes((chosenField[0].jointype)))
        {
          tempsourcejointable = [
            {srctable: chosenField[0].source.grouptable,srcjointable: chosenField[0].source.sourcejointable,datafield: chosenField[0].source.sourcejointable_datafield,returnfield:chosenField[0].source.sourcejointable_returnfield}, 
            {reltable: chosenField[0].source.lookuptable,reljointable:chosenField[0].source.relationjointable,datafield: chosenField[0].source.relationjointable_datafield,returnfield:chosenField[0].source.relationjointable_returnfield},
          ]
          
          // console.log(tempsourcejointable)

        } else if (chosenField[0].source !== undefined)
        {
          tempsourcejointable = chosenField[0].source.jointable
        } else{
          tempsourcejointable=''
        }
        
        const payload={
          tableid,
          fieldname,
          lookuptype,
          relationfieldLookup:newfieldlookup,
          specialfunction,
          sourcejointable: tempsourcejointable,
          relationFieldObject: relationField[0],
          chosenFieldObject: chosenField[0]
        }

        // console.log(payload)
        await cloudrun.post('/addLookupField', { payload })
      }
    }
    return "success"
  } catch (error) {
    console.log(error)
    return `An Error occurred: ${error.message}`
  }

  };

  render() {
    const {
      zIndex,
      relationfields,
      tablefields,
      showLookupOptions,
      radiovalue,
      specificvalue,
      showSpecialFunctions,
      showNumericFunctions,
      showDateFunctions,
      showAdvanced,
      callout,
      relationFieldName,
      lookupfield,
      currentSpecialFunction,
      enableNotify,
      chosenField,
      showFilters,
      filters,
      relTableInfo,
      relTableId,
      isPrivate,
      cacheResults
    } = this.state;
    const { columnconfigdata,color,blockid,tableinfo,tableid } = this.props;
    return (
      <div style={{position:'relative'}}>
        <div>
          Auto-populate data from relational tab. Once users select one (or possibly more) values
          from a relational tab field in this table, data from that tab will auto-populate according
          to how it's lookup is prescribed to operate.
        </div>

        <Grid style={{marginLeft:'10px',marginRight:'10px'}}>
          <GridColumn>
            <GridRow style={{ marginTop: '10px' }}>
              <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Relational field in this tab
              </div>
            </GridRow>
            <GridRow>
              {relationFieldName == '' ? (
                <Dropdown
                selectOnBlur={false}
                  search
                  fluid
                  style={{
                    zIndex: zIndex === 1 ? '2' : '1',
                    position: 'relative',
                    marginTop: '5px',
                  }}
                  placeholder="Relation Field"
                  onFocus={() => this.setState({ zIndex: 1 })}
                  selection
                  options={relationfields}
                  onChange={(e, data) => {
                    this.SelectedFieldHandler(data.value);
                  }}
                />
              ) : (
                <div>{relationFieldName}</div>
              )}
            </GridRow>
            <GridRow style={{ marginTop: '5px' }}>
              <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Lookup field</div>
            </GridRow>
            <GridRow>
              {lookupfield == '' ? (
                <Dropdown
                selectOnBlur={false}
                  search
                  fluid
                  style={{
                    zIndex: zIndex === 2 ? '2' : '1',
                    position: 'relative',
                    marginTop: '5px',
                  }}
                  placeholder="Lookup Fields"
                  onFocus={() => this.setState({ zIndex: 2 })}
                  selection
                  options={tablefields}
                  onChange={(e, data) => {
                    this.ChosenHandler(data.value);
                  }}
                />
              ) : (
                <div>{lookupfield}</div>
              )}
            </GridRow>
            {currentSpecialFunction !== '' ? (
              <>
                <GridRow style={{ marginTop: '5px' }}>
                  <div style={{ fontSize: '12px', fontWeight: 'bold' }}>Field data setting</div>
                </GridRow>
                <GridRow>
                  <div>{currentSpecialFunction}</div>
                </GridRow>
              </>
            ) : null}
            {currentSpecialFunction !=='' && columnconfigdata.filters && columnconfigdata.filters.length>0 ? (
              <>
              <GridRow>
                <div style={{marginTop:'5px'}}>
                  <FilterTags 
                    sortHeader={'id'}
                    sortField={'id'}
                    sortOrder={'asc'}
                    filters={columnconfigdata.filters}
                    removeFilter={null}
                    removeSort={null} />
                  </div>
              </GridRow>
            </>
            ): null}
            <GridRow>
              <div style={{marginTop:'15px',display:'flex', width:'100%', marginBottom:'20px'}}>
            <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} /> 
            </div>
              {showLookupOptions && showAdvanced ? (
                <Form>
                  <Form.Field>
                    <Radio
                      label="Show all values"
                      name="radioGroup1"
                      value="all"
                      checked={radiovalue === 'all'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Show unique values"
                      name="radioGroup1"
                      value="unique"
                      checked={radiovalue === 'unique'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Show specific value"
                      name="radioGroup1"
                      value="specific"
                      checked={radiovalue === 'specific'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                </Form>
              ) : null}
            </GridRow>

            <GridRow>
              
                <Form>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '5px',
                      marginLeft: '10px',
                    }}
                  >
                    {showLookupOptions && showAdvanced ? (<>
                    <div style={{width:'120px'}}>
                      <Form.Field>
                        <Radio
                          disabled={radiovalue !== 'specific'}
                          label="Newest"
                          name="radioGroup2"
                          value="newest"
                          checked={specificvalue === 'newest'}
                          onChange={(e, data) => this.handleChange2(data.value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          disabled={radiovalue !== 'specific'}
                          label="Oldest"
                          name="radioGroup2"
                          value="oldest"
                          checked={specificvalue === 'oldest'}
                          onChange={(e, data) => this.handleChange2(data.value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          disabled={radiovalue !== 'specific'}
                          label="Most recently updated"
                          name="radioGroup2"
                          value="mostrecent"
                          checked={specificvalue === 'mostrecent'}
                          onChange={(e, data) => this.handleChange2(data.value)}
                        />
                      </Form.Field>
                    </div>
                    <div style={{width:'200px'}}>
                      <Form.Field>
                        <Radio
                          disabled={radiovalue !== 'specific'}
                          label="Count of all values"
                          name="radioGroup2"
                          value="count"
                          checked={specificvalue === 'count'}
                          onChange={(e, data) => this.handleChange2(data.value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          disabled={radiovalue !== 'specific'}
                          label="Count of distinct values"
                          name="radioGroup2"
                          value="countdistinct"
                          checked={specificvalue === 'countdistinct'}
                          onChange={(e, data) => this.handleChange2(data.value)}
                        />
                      </Form.Field>
                    </div>
                        </>) : null}

                    {showSpecialFunctions ? (
                      <div>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Min"
                            name="radioGroup2"
                            value="min"
                            checked={specificvalue === 'min'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Max"
                            name="radioGroup2"
                            value="max"
                            checked={specificvalue === 'max'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                      </div>
                    ) : null}
                     {showNumericFunctions ? (
                      <div>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Sum"
                            name="radioGroup2"
                            value="sum"
                            checked={specificvalue === 'sum'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Avg"
                            name="radioGroup2"
                            value="avg"
                            checked={specificvalue === 'avg'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Min"
                            name="radioGroup2"
                            value="nummin"
                            checked={specificvalue === 'nummin'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Max"
                            name="radioGroup2"
                            value="nummax"
                            checked={specificvalue === 'nummax'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Median"
                            name="radioGroup2"
                            value="median"
                            checked={specificvalue === 'median'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                      </div>
                 ) : null}
                   {showDateFunctions ? (
                      <div>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Min"
                            name="radioGroup2"
                            value="nummin"
                            checked={specificvalue === 'nummin'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            disabled={radiovalue !== 'specific'}
                            label="Max"
                            name="radioGroup2"
                            value="nummax"
                            checked={specificvalue === 'nummax'}
                            onChange={(e, data) => this.handleChange2(data.value)}
                          />
                        </Form.Field>
                      </div>
                 ) : null}
                  </div>
                </Form>
             
            </GridRow>
          </GridColumn>
        </Grid>
        {showAdvanced ? (
        <div style={{marginLeft:'10px'}}>
           <div style={{marginBottom:'10px'}}>
              <GBSwitch
                color={color}
                text="Private field"
                isChecked={isPrivate}
                Action={this.togglePrivate}
              />
          </div>
            
          {/* 4-8-24 removed as we do this at tab level now. */}
          {/* {radiovalue==='specific' || (columnconfigdata.uitype===18 && !['all','unique','lookup'].includes(columnconfigdata.specialFunction)) ? (
            <div>
                <GBSwitch
                color={color}
                text="Cache results for 24 hours"
                isChecked={cacheResults}
                Action={this.toggleCacheResults}
              />
            </div>
          ): null} */}

          {(chosenField !=='' && chosenField[0].uitype===8) || (columnconfigdata.source && columnconfigdata.source.lookupuitype===8) ? (
          <div style={{marginTop:'10px',marginBottom:'5px'}}><GBSwitch color={color} text="Activate collaborator notifications" isChecked={enableNotify} Action={this.toggleNotifications} /></div>
          ): null}

          {!showFilters && columnconfigdata.uitype !==18 ? (
          <div style={{marginTop:'10px'}}>
            <GBButton text={filters.length===0 ? 'Add filters' : 'View filters'} Action={this.toggleShowFilters} color={color} />
          </div>
           
          ): null}

          {showFilters && columnconfigdata.uitype !==18 ? (
            <div style={{position:'absolute',border:'1px solid black',paddingTop:'10px', bottom:-60,marginBottom:'20px',backgroundColor:'white',zIndex:20,width:'100%',minHeight:'200px'}}>
          <div style={{display:'flex', fontSize:'15px', flexDirection:'row',alignItems:'center',margin:'10px',justifyContent:'space-between'}}>
            <div>Choose filters to add</div>
            <div><TextButton icon={mdiClose} text='Close' iconPosition='left' Action={this.toggleShowFilters} textColor='#312602' hoverColor='#31260280' /></div>
          </div>
            <FilterData
            title="Add filters"
            icon={mdiFilter}
            saveText="Apply filter criteria"
            tableinfo={relTableInfo}
            tableid={relTableId}
            close={this.toggleShowFilters}
            FilterData={this.updateFilters}
            filters={filters}
            color={color}
            blockid={blockid} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
            callingComponent='lookupConfig'
          /></div>
          ):null}

            <Callout callout={callout} updateCallout={this.CalloutHandler} />   
        </div>) : null}
     
      </div>
    );
  }
}

LookupField.propTypes = {
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnconfigdata: PropTypes.object.isRequired,
};

export default LookupField;
