import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Tooltip = ({ invalid, color, text }) => {
  const className = classNames({
    'form-control-tooltip': true,
    'is-invalid': invalid,
  });
  if (invalid) {
    return <div className={className}>Not yet valid</div>;
  }

  const styles = {
    background: color,
  };
  return (
    <div className={className} style={styles}>
      {text}
    </div>
  );
};

Tooltip.defaultProps = {
  invalid: false,
  color: '#4DB6AC',
  text: 'Success',
};

Tooltip.propTypes = {
  invalid: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
};

export default Tooltip;
