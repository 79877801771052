/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import Icon from '@mdi/react';


import {
  mdiSend,
  mdiMicrophone,
  mdiEmoticonHappyOutline,
  mdiImageOutline,
  mdiCogClockwise,
  mdiFileDocument,
  mdiClose,
} from '@mdi/js';
import Picker from 'emoji-picker-react';
import { Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-autosize-textarea';
import SendButton from './SendButton';
import GBButton from '../../../../components/GBButton/GBButton';
import MessageSettings from './MessageSettings';
import Spinner from '../../../../components/Spinner/Spinner';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import validExtensions from '../../../../utils/validExtensions';


import { uploadFiles, deleteFile } from '../../../../api/images';

const SearchBar = ({
  color,
  emojiPosition = '-400px',
  messageChangeHandler,
  message,
  Action,
  isEnabled,
  messageSetting,
  updateMessageSetting,
  dataID,
  setShowTemplates,
  zoneid,
  updateFileUrls,
  fileUrls,
  callingComponent,
  updatedUsrMsg,
  userListActive,
  handleKeyForUserList,
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showUpload, setShowUpload] = useState('');
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tempUser, setTempUser]=useState('');

  const myInput = useRef();

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };

  const updateFilesArray = (urls) => {
    // setFileUrls(urls) // update locally.
    updateFileUrls(urls); // update in messages component.
  };

  const [inputRef, setInputFocus] = useFocus();

  const SettingsIconPath =
    'M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z';

  useEffect(() => {
    setShowEmoji(false);
  }, [dataID]);

  useEffect(() => {
    /** 1-28-2022 This is a HACK :) 
     * The code here is quite squirlly, I just was not able to find a good solution for how to implement
     * the logic that shifts focus between editable div and the pop-up user list and all that goes with it.
     * One existing issue is that clicking the mouse button to add user does not retain the cursor position
     * versus hitting the enter key. I've setup logic such that if the user clicks the mouse button in
     * collaboratorMessage, it prepends the || to the message, so that the code here can detect this
     * and instead of calling pastHTML which works for "Enter" key, it simply appends the message to the end.
     * By default, it would add the name to start of message which is worse.
     * 
     * so, if user is in middel of message and clicks to add user, it will add to end of the textbox. 
     * This can be revisted later, and hopefully can implement much clearer solution. 
     * 
    */
    if (callingComponent === 'collaborators' && updatedUsrMsg !== '') {
      let msg=''
      document.getElementById('message').focus();
      if(updatedUsrMsg.startsWith('||')){
        document.getElementById('message').innerHTML= document.getElementById('message').innerHTML+updatedUsrMsg.replace('||','');
         msg = document.getElementById('message').innerHTML;
         setEndOfContenteditable(document.getElementById('message')) //This positions cursor at end of textbox . Without it, cursor is at start
      } else{
      pasteHtmlAtCaret(updatedUsrMsg);
         msg = document.getElementById('message').innerHTML;
      }
      messageChangeHandler(msg);
    }
  }, [updatedUsrMsg]);

  useEffect(() => {
    if (!userListActive && callingComponent === 'collaborators' && message !== '') {
      document.getElementById('message').focus();
    }
  }, [userListActive]);

  function handleKeyDown(e) {
    if (e.key === '@') {
      handleKeyForUserList();
    }
  }


  function pasteHtmlAtCaret(html) {
    var sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            var el = document.createElement("div");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(), node, lastNode;
            while ( (node = el.firstChild) ) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if (document.selection && document.selection.type != "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
    }
}


 // Function is used to place cursor at end of text of the given element.
  function setEndOfContenteditable(contentEditableElement) {
    var range, selection;
    if (document.createRange) {
      //Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange(); //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      selection.removeAllRanges(); //remove any selections already made
      selection.addRange(range); //make the range you have just created the visible selection
    } else if (document.selection) {
      //IE 8 and lower
      range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
      range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      range.select(); //Select the range (make it the visible selection
    }
  }

  // 2-15-2021 Added callingComponent to pass "grid" or "detailview".  If detailview we don't
  // want to set focus on textarea because it interferes with editing controls on left panel.
  // in future, can find a more elegant solution.
  // 5-25-23 commented out, because when focus is set automatically, backspace key closes modal
  //need to keep keydownevent active on messageeditor so the # key works. Fr now, users
  //will need to click into textarea to enter message..andthis sovles backspace issue.

  // useEffect(() => {
  //   if (callingComponent === 'grid') setInputFocus();
  // });

  // const handleChange = (e) => {
  //   messageChangeHandler(e.target.value);
  // };

  const handleUploadChange = (type) => {
    setShowUpload(type);
    if (type === 'url') {
      setShowUrlInput(true);
    }
  };

  const closeInput = () => {
    setShowUrlInput(false);
    setShowUpload('');
  };

  const onKeyPressHandler = (e) => {
    if (e.key === '#') {
      setShowTemplates(true);
      return false;
    }
  };

  const onEditorStateChange = (editorState) =>{
    messageChangeHandler(editorState);
  }

  const sendMessage = async () => {

    if (callingComponent === 'collaborators') {
      const msg = document.getElementById('message').textContent;
      var myarray=[];
      const x = document.getElementById('message').querySelectorAll("a");
      for (var i=0; i<x.length; i++){
        var cleanLink = x[i].name;
        myarray.push(cleanLink)
      }
      messageChangeHandler(msg);
      document.getElementById('message').innerHTML = '';
      await Action(myarray);
    } else {
      await Action()
    }

  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    if (callingComponent === 'collaborators') {
      const msg = document.getElementById('message').innerHTML;
      messageChangeHandler(msg + emojiObject.emoji);
      document.getElementById('message').innerHTML = msg + emojiObject.emoji;
    } else {
      messageChangeHandler(message + emojiObject.emoji);
    }
    setShowEmoji(false);
  };

  const updateSetting = () => {
    setShowSettings(!showSettings);
  };

  const fetchImageUrl = () => {
    const urlItem = { url: url, guidname: url, uploadType: 'url' };

    const ext = url.split('.').pop();
    const findExt = validExtensions.findIndex((itm) => itm === ext.toLowerCase());

    if (findExt === -1) {
      setUrl('');
      toast.info(
        <div style={{ margin: '5px' }}>
          The link was not of a media type accepted for text messaging services.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          closeButton: false,
        },
      );
      return false;
    }

    if (fileUrls && fileUrls.length > 0) {
      fileUrls.push(urlItem);
      updateFilesArray(fileUrls);
    } else {
      updateFilesArray([urlItem]);
    }
    setUrl('');
    setShowUpload('');
    setShowUrlInput(false);
  };

  const deleteImage = async (file) => {
    let result = '';
    if (file.uploadtype === 'desktop') {
      const schema = `schema_${zoneid}`;
      result = await deleteFile(schema, null, null, file.guidname, 'messages');
    } else {
      result = 'success'; // For URL fetches, we just need to remove them from array.
    }

    if (result === 'success') {
      const tempurls = [...fileUrls];
      const idx = tempurls.findIndex((itm) => itm.name === file.guidname);
      tempurls.splice(idx, 1);
      updateFilesArray(tempurls);
      if (tempurls.length === 0) {
        setShowUpload('');
      }
    }
  };

  const handleImageUpload = async (e) => {
    if (e.target.files.length > 0) {
      let newArray = [];
      let hasInvalidFiles = false;
      let exceedsFileSizeLimit = false;

      // loop over uploaded files and determin if any have invalid extensions
      // For any invalid file, remove from array and set flag to show toast notification.
      const tempArray = Array.from(e.target.files);
      let toastMessage = '';

      tempArray.forEach((itm) => {
        const ext = itm.name.split('.').pop();
        const findExt = validExtensions.findIndex((itm) => itm === ext.toLowerCase());
        if (findExt !== -1) {
          newArray.push(itm);
        } else {
          hasInvalidFiles = true;
        }
        if (itm.size > 5000000) {
          exceedsFileSizeLimit = true;
        }
      });

      if (hasInvalidFiles) {
        toastMessage +=
          'One of the files you attempted to upload does not meet the valid media types for text messaging. ';
      }
      if (exceedsFileSizeLimit) {
        toastMessage += "Can't load file; limit of 5MB per message exceeded.";
      }

      if (hasInvalidFiles || exceedsFileSizeLimit) {
        toast.info(<div style={{ margin: '5px' }}>{toastMessage}</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
          closeButton: false,
        });
        setIsLoading(false);
        myInput.current.value = null;
        return false;
      }

      setShowUpload('');
      setIsLoading(true);
      setShowUrlInput(false);
      const schema = `schema_${zoneid}`;
      const urls = await uploadFiles(schema, newArray, 'messages');

      if (fileUrls && fileUrls.length > 0) {
        fileUrls.push.apply(fileUrls, urls);
        updateFilesArray(fileUrls);
      } else {
        updateFilesArray(urls);
      }
      setIsLoading(false);
      myInput.current.value = null; // 12-14-2020 set value of file input to null so can reselect same images if use rwants to resend.
    }
  };

  return (
    <div
      style={{
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        // border:'1px solid red',
        // width:'100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          position: 'relative',
          width: '100%',
          // zIndex:100
          //   border:'1px solid blue',
        }}
      >
        {callingComponent === 'collaborators' ? (
          <div
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyDown}
            onInput={(e)=> messageChangeHandler(e.target.innerHTML)}
            style={{
              overflow:'auto',
              border: `1px solid ${color}`,
              borderRadius: '5px',
              minHeight: '35px',
              padding: '3px',
              marginBottom: '5px',
              maxHeight:'300px'
            }}
            id="message"
            contentEditable="true"
          ></div>
        ) 
        :
        (
          <TextareaAutosize
            disabled={!isEnabled}
            value={message}
            ref={inputRef}
            onKeyDown={(e) => onKeyPressHandler(e)}
            onChange={(e) => messageChangeHandler(e.target.value)}
            // autoFocus
            placeholder="Enter message here, press # to access templates"
            maxRows={5}
            css={{
              paddingLeft: '10px',
              minHeight: '25px',
              borderColor: `${color}80`,
              borderRadius: '5px',
              marginBottom: '5px',
              '&:focus': {
                borderColor: color,
                outline: 'none',
              },
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            position: 'relative',
            justifyContent: 'space-between',
            marginBottom: '5px',
            height: '40px',
            // border:'1px solid blue',
          }}
        >
          {showEmoji ? (
            <div
              style={{
                position: 'absolute',
                zIndex: '100',
                top: emojiPosition,
                backgroundColor: 'white',
                width: '300px',
                height: '250px',
              }}
            >
              <Picker disableAutoFocus onEmojiClick={onEmojiClick} />
            </div>
          ) : null}

          <div
            css={{
              cursor: 'pointer',
              height: '30px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {/* <Icon
              css={{
                marginRight: '10px',
                '&:hover': {
                  color: `${color}80`,
                },
              }}
              path={mdiMicrophone}
              size="20px"
            /> */}
            {/* don't show emoji for email RTF editor */}
            {callingComponent !=='emailMessages' ? (
               <Icon
               onClick={() => setShowEmoji(!showEmoji)}
               css={{
                 marginRight: '10px',
                 cursor: 'pointer',
                 '&:hover': {
                   color: `${color}80`,
                 },
               }}
               path={mdiEmoticonHappyOutline}
               size="20px"
             />
            ): null}
           

            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '&:hover': {
                  '& .imageHover': {
                    display: showUpload !== 'url' ? 'block' : 'none',
                    transition: 'all .3s ease',
                  },
                  '& .urlHover': {
                    display: 'block',
                    transition: 'all .3s ease',
                  },
                },
              }}
            >
              <Icon
                onClick={(e) => myInput.current.click()}
                css={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: `${color}80`,
                  },
                }}
                path={mdiImageOutline}
                size="20px"
              />
              <div
                onClick={(e) => (showUpload === '' ? myInput.current.click() : null)}
                className="imageHover"
                css={{
                  marginLeft: '10px',
                  paddingTop: '50px',
                  paddingBottom: '50px',
                  display: showUpload === 'desktop' ? 'block' : 'none',
                  '&:hover': { color: '#75757580' },
                }}
              >
                {'Add files(s) from desktop'}
                <input
                  id="myInput"
                  type="file"
                  multiple
                  ref={myInput}
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageUpload(e)}
                />
              </div>
              <div
                onClick={() => (!showUrlInput ? handleUploadChange('url') : null)}
                className="urlHover"
                css={{
                  display: showUpload === 'url' ? 'block' : 'none',
                  marginLeft: '20px',
                  '&:hover': { color: '#75757580' },
                }}
              >
                {!showUrlInput ? 'Add files(s) from Url' : null}
                {showUpload === 'url' && showUrlInput ? (
                  <div style={{ width: '300px' }} className="row">
                    <Input
                      onChange={(e, data) => setUrl(data.value)}
                      value={url}
                      style={{ width: '70%', marginRight: '5px' }}
                      fluid
                    />
                    <GBButton Action={fetchImageUrl} text="fetch" color={color} width="50px" />
                    <Icon
                      style={{ marginLeft: '5px' }}
                      onClick={() => closeInput()}
                      path={mdiClose}
                      size="20px"
                    />
                  </div>
                ) : null}
              </div>
              
            </div>
            {callingComponent==='collaborators' ? (
                <div onClick={()=>handleKeyForUserList()} style={{marginLeft:'10px',fontSize:'20px'}}>@</div>
            ):null}
          

          </div>

          <div>
            {showSettings ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  backgroundColor: 'white',
                  top: callingComponent === 'grid' ? '-170px' : '-30px',
                  right: '100px',
                }}
              >
                <MessageSettings
                  color={color}
                  close={updateSetting}
                  messageSetting={messageSetting}
                  updateMessageSetting={updateMessageSetting}
                />
              </div>
            ) : null}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {callingComponent !== 'collaborators' ? (
                <div
                  onClick={() => updateSetting()}
                  css={{
                    marginRight: '20px',
                    whiteSpace: 'nowrap',
                    color: 'black',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#00000080',
                      svg: {
                        color: '#00000080',
                      },
                    },
                  }}
                >
                  <Icon path={SettingsIconPath} size="20px" /> Message Settings
                </div>
              ) : null}

              <SendButton
                iconPosition="right"
                color={color}
                icon={mdiSend}
                Action={isEnabled ? sendMessage : null}
                text={
                  callingComponent === 'collaborators'
                    ? 'Save comment'
                    : messageSetting.interval === 'none'
                    ? 'Send now'
                    : `Send in ${messageSetting.number} ${messageSetting.interval}`
                }
                width={
                  callingComponent === 'collaborators'
                    ? '150px'
                    : messageSetting.interval === 'none'
                    ? '130px'
                    : '170px'
                }
              />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: '10px' }} className="row">
          <ImageThumbnails items={fileUrls} Action={deleteImage} />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
