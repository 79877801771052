/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Form, Radio } from 'semantic-ui-react';
import { AccountContext } from '../../../../providers/AccountProvider';
import { updateTableInfoAPI,checkIfNestedField } from '../../../../api/tables';

import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import {getBlockUsersAPI } from '../../../../api/users';
import {configureRecordNotifications,clearNotificationCache} from '../../../../api/notifications'
import UserDropdown from '../../../../components/UserDropdown/UserDropdown';
import Global from '../../../../clients/global'

class CollaborationField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData} = this.props;

    this.state = {
      showAdvanced: false,
      callout: columnConfigData.callout,
      options:[],
      isMultiple:true,
      enableNotify: columnConfigData.enableNotify !==undefined ? columnConfigData.enableNotify : false,
      defaultText: columnConfigData.defaultText ?? [],
      defaultChanged: false,
      userType: columnConfigData.userType ?? 'zone',
      definedUsers: columnConfigData.definedUsers ?? '',
      isPrivate: columnConfigData.isPrivate ?? false
    };
  }
  static contextType = AccountContext;

 componentDidMount() {
      this.FetchUsers();
  }

  handleChange = (value) => {
    this.setState({ userType: value});
  };

  FetchUsers = async () => {
    const { blockid,columnConfigData } = this.props;
    let result =  await getBlockUsersAPI(blockid,true);
    
    let tmpOptions=[];

    if(result.length>0) {
        result.map(el=>{
            tmpOptions.push({key:el.userid,value:el.email,text:`${el.firstname} ${el.lastname}`})
        })
     }

     this.setState({
         options:tmpOptions,
        //  defaultText: columnConfigData.defaultText,
         isMultiple: columnConfigData.lookup !==undefined ? columnConfigData.lookup==='multiple' : 'multiple'
     })
  
  };

//   onDefaultUserSelection

  Save = async () => {
    const { callout,defaultText,isMultiple,enableNotify,definedUsers,userType,defaultChanged,isPrivate} = this.state;
    const { field, tableid, header, selectedUIType,tableinfo,columnConfigData,blockid  } = this.props;

    const {userInfo} = this.context;
    const idx = userInfo.zones.findIndex(el=>el.id===parseInt(Global.zoneid));
    let currentZone='';
    if(idx !==-1)
    {
      currentZone = userInfo.zones[idx];
    }

    //1-27-23 if They choose specific users to use, but don't select any, show error and return
    if(userType==='specific' && definedUsers.length===0){
      return 'You must select at least one user for this option.';
    }

    //If they have selected to turn on notfications, and this has not yet been configured for the zone,
    // call API to configure the notificatios.
    if(enableNotify && currentZone.attributes.recordNotifications===undefined){
        
        currentZone.attributes.recordNotifications=true;
        await configureRecordNotifications(currentZone.attributes);
        userInfo.zones[idx] = currentZone;
    } else {
      // We will always clear the noticiation cache on saving field update because we don't know overall current status.
      // this will force cache to get latest check on next update.
      await clearNotificationCache(tableid);
    }

    const originalUIType = columnConfigData.uitype;

    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "displayfield": "${field}",
      "detailviewsort": ${columnConfigData.detailviewsort}
  }`

    const updatedNode = JSON.parse(defaultNode);

    if(isPrivate) {
      updatedNode.isPrivate =true;
    } else {
      delete updatedNode.isPrivate;
    }

    // 11-8-2021 in this usecase defaultText will be storing either a single userid (15) or an array of userid's ([15,21])
    // Logic needs to process this when inserting new rows to process either single/or array of userid's into join table.
    // const dtext= defaultText.join('%|%')
    let payload = { field, tableid, defaultText:defaultText.join('%|%'), uitype: selectedUIType };
    if ( defaultChanged) {
        await cloudrun.post('/updateFieldDefaultText', { payload });
        if(defaultText.length>0) {
          updatedNode.defaultText = defaultText;
        } else {
          delete updatedNode.defaultText;
        }
    } else if(defaultText.length>0){
      updatedNode.defaultText = defaultText;
    } else {
      delete updatedNode.defaultText;
    }

    if(definedUsers.length>0) {
      updatedNode.definedUsers =definedUsers
    } else {
      delete updatedNode.definedUsers;
    }

    updatedNode.userType=userType
    updatedNode.blockid=blockid;

    try {
    // Update Tableinfo with new settings.
    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
    if (rowindex !== -1) {
      updatedNode.callout=callout;
      updatedNode.lookup=isMultiple ? 'multiple' : 'single',
      updatedNode.uitype=selectedUIType;
      updatedNode.header=header;
      updatedNode.renderandedit='Collaborator'
      updatedNode.enableNotify=enableNotify

      tableinfo.columns[rowindex]=updatedNode;

      if(originalUIType===23)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_numeric_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } else if (originalUIType===22)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_date_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } 
      // if we're coming from select field, we need to map optios to text values
      // and then remove associated/related tables. This is doen via removefield API passing removeStructureOnly=true
      else if (originalUIType===6 || originalUIType===7){
        const table=`tbl_${tableid}`;
        // await MapOptionValuesToText(table,columnConfigData.data,255)
        const payload={tableid,fieldname:columnConfigData.data,removeRelatedStructureOnly:true}
        await cloudrun.post('/removeField', { payload })
      } else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'citext',castFunction:'cast_integer_to_text' };
        await cloudrun.post('/convertField', { payload })
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      } else if (originalUIType===9) {
        const payload = { tableid, tableinfo, field };
        payload.dbfunction="deleteFieldValues"
        await cloudrun.post('/executeQueryAPI', {payload})
      }

      // Create the Collaborator table
      if(originalUIType !==8){
        await cloudrun.post('/addCollaboratorField',{payload});
      }

      await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
    }
    return "success"
  } catch (error) {
    console.log(error)
    return `An error occurred: ${error.message}`
  }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    this.setState({ showAdvanced: !showAdvanced });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  toggleMultiple = async () => {
      const{isMultiple} = this.state;
      const {plan,field,tableid} = this.props;

      //8-4-22 add check if field is used in nested fields. If yes, they cannot switch to multiple
      //until it's removed from nested field definition.
      if(plan==='Pro' ||  plan==='Enterprise') {
        const nestedFields= await checkIfNestedField(field,tableid);
          if(nestedFields.length >0) {
            let fields=
            nestedFields.map(el=>(
              <ul>{el.name}: {el.header}</ul>
            ))

            toast.info(<div style={{margin:'10px'}}>This field is used in a relational nested fields. It can not be changed to allow multiple items until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: false,
            });
            return false
          }
      }

      this.setState({isMultiple: !isMultiple})
  }

  toggleNotifications=()=>{
    const{enableNotify} = this.state;
    const {plan}=this.props;
    if(plan==='Free') {
      toast.info(<div style={{margin:'10px'}}>To enable collaborator notifications, please upgrade to a paid plan.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:7000,
      })
      return false;
    }
    this.setState({enableNotify: !enableNotify})
  }

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  //1-27-23 changed how we store userids to array of numbers instead of delimited string
  //also added defaultChanged attribute to indicate if they made some change, which I can 
  //then use to fire API call to updateDefaultTExt, otherwise I don't change it. Before was 
  //running any time they made a field change.
  updateDefaultUsers = async (value) =>  {
     const userids = value.map(el=>el.userid)  //.join('%|%');
    this.setState({
      defaultText:userids,
      defaultChanged:true
    })
  }

  updateDefinedUsers = async (value) =>  {
    const userids = value.map(el=>el.userid);
   this.setState({
     definedUsers:userids
   })
 }

  Description = () => {
    const { selectedUIType } = this.props;
    return(
    <div style={{marginTop: '5px' }}>
         Choose users to collaborate with. Collaborators will have access to the tab and can be notified when changes /updates occur.
         <div style={{display:'flex', marginTop:'10px', flexDirection:'row', width:'200px', alignItems:'center',justifyContent:'space-between'}}>
        </div>
        </div>
    )
  }

  // defaultUserSelection=(value) => {
  //    this.setState({defaultText:value})
  // }

  render() {
    const { showAdvanced, callout,defaultText,options,isMultiple,enableNotify,userType,definedUsers,isPrivate } = this.state;
    const { color,blockid,tableid } = this.props;
    const tablename=`tbl_${tableid}`;
    // const selectedUsers=[];
    
    //11-10-2021. The userDropdown allows 2 ways of passing in "Selected users". One is based on
    // how it's used with filters, where the filter.value stores the userid's. This is a much easier
    // way to pass in the array of userid's versus passing array of user objects. So, I am repurposing
    // that here. The defaultText stores array of userid's, and then we use filter.value to pass these back in.
    const filter={value:defaultText};

    // 1-26-23 adding new ability to specify which active zone users appear for collaboration field
    //we will use the same format as the defaultText...a %|% delimited string of userid's, which 
    //the userdropdown parses to array and adds matching users.
    const specificUsers ={value:definedUsers} 
    return (
      <div>
        {this.Description()}
        <div style={{height:'15px'}}>
        </div>
       
       <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} />

        {showAdvanced ? (
            <>
             <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            <div style={{height:'10px'}}></div>
             <GBSwitch color={color} text="Enable multiple" isChecked={isMultiple} Action={this.toggleMultiple} />
             <div style={{marginTop:'10px',marginBottom:'5px'}}>
                <GBSwitch color={color} text="Activate collaborator notifications" isChecked={enableNotify} Action={this.toggleNotifications} />
            </div>
             <div style={{marginTop:'10px',marginBottom:'10px'}}>Collaborators can be:</div>
             <div>
             <Form>
                  <Form.Field>
                    <Radio
                      label="Users of this Block"
                      name="radioGroup1"
                      value="block"
                      checked={userType === 'block'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Active users for the entire Zone"
                      name="radioGroup1"
                      value="zone"
                      checked={userType === 'zone'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Only specific users"
                      name="radioGroup1"
                      value="specific"
                      checked={userType === 'specific'}
                      onChange={(e, data) => this.handleChange(data.value)}
                    />
                  </Form.Field>
                  {userType==='specific' ? (
                    <div style={{marginLeft:'20px',marginTop:'5px'}}>
                      <UserDropdown
                        field="fld1"
                        uitype={8}
                        filter={specificUsers}
                        updateFilter={this.updateDefinedUsers}
                        color={color}
                        lookup={ "multiple"}
                        tablename={tablename}
                        blockid={blockid}
                        type={"restricted"} 
                  /> 
                  </div>   
                  ): null}
                </Form>
             </div>
             <div style={{height:'10px'}}></div>
            <div>Default collaborator</div>
             <div> 
             <UserDropdown
               field="fld2"
              uitype={8}
              updateFilter={this.updateDefaultUsers}
              color={color}
              lookup={isMultiple ? "multiple"  :'single'}
              filter={filter}
              tablename={tablename}
              blockid={blockid}
              type={"restricted"} 
              userType="zone"
            />          
        </div>
         
           <Callout callout={callout} updateCallout={this.CalloutHandler} />       
           </>
        ) : null}
      </div>
    );
  }
}

export default CollaborationField
