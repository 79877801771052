/* eslint-disable */
import React, { useState, useEffect } from 'react';
import TextButton from '../../components/TextButton/TextButton';
import InputControl from '../../components/InputControl/InputControl';
import GBButton from '../../components/GBButton/GBButton';
import { mdiHistory, mdiChevronDown, mdiChevronUp,mdiPencil } from '@mdi/js';
import { updateZoneSettings, stripeUpdateCustomer } from '../../api/zone';
import BillingAddress from './BillingAddress';

const Invoices = ({ zoneInfo, startingBalance, toggleShowInvoiceHistory,color }) => {
  const [emailInvoices, setEmailInvoices] = useState(true);
  const [company, setCompany] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [showBillingAddress, setShowBillingAddress] =useState(false)

  useEffect(() => {
    if (zoneInfo.attributes !== null) {
      setEmailInvoices(
        zoneInfo.attributes.emailInvoices !== undefined ? zoneInfo.attributes.emailInvoices : true,
      );
      setCompany(
        zoneInfo.attributes.company !== undefined ? zoneInfo.attributes.company : zoneInfo.name,
      );
      setInvoiceEmail(
        zoneInfo.attributes.invoiceEmail !== undefined ? zoneInfo.attributes.invoiceEmail : '',
      );
      setBillingAddress(zoneInfo.attributes.BillingAddress !== undefined ? zoneInfo.attributes.BillingAddress : '');
    }
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleShowBilling = () => {
    setShowBillingAddress(!showBillingAddress)
  }

  const submitChanges = async () => {
    const result = await stripeUpdateCustomer(zoneInfo.stripeInfo, invoiceEmail.trim(), company, billingAddress);
    if (result === 'success') {
      if (zoneInfo.attributes !== null) {
        zoneInfo.attributes.emailInvoices = emailInvoices;
        zoneInfo.attributes.company = company;
        zoneInfo.attributes.invoiceEmail = invoiceEmail;
        zoneInfo.attributes.BillingAddress = billingAddress;
      } else {
        zoneInfo.attributes = { emailInvoices, company, invoiceEmail, billingAddress };
      }
      await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
    }
  };

  const updateInvoiceEmail = (field, value) => {
    setInvoiceEmail(value);
  };

  const updatecompany = (field, value) => {
    setCompany(value);
  };

  const updateAddress = async (value) => {
    // 2-16-2021 I do the update directly when updating the billing address because setting
    // billing address and calling submitchanges does not get the latest values. This is 
    //somethiung that I need to solve longer term. HEre we udpate direclty. ;
    setBillingAddress(value)
    const result = await stripeUpdateCustomer(zoneInfo.stripeInfo, invoiceEmail, company,value);
    zoneInfo.attributes.emailInvoices = emailInvoices;
    zoneInfo.attributes.company = company;
    zoneInfo.attributes.invoiceEmail = invoiceEmail;
    zoneInfo.attributes.BillingAddress = value;
    await updateZoneSettings(zoneInfo.zoneid, zoneInfo.attributes);
    toggleShowBilling()
  };

  const companyNameControl = { data: 'company', uitype: 2, header: 'Bill to name: ' };
  const emailInvoiceControl = { data: 'email', uitype: 2, header: 'Email invoice noticess to: ' };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#F1EFEF',
          borderRadius: '25px',
        }}
      >
        <div style={{ marginLeft: '20px' }}>
          <TextButton
            Action={toggleExpand}
            textColor="black"
            hoverColor="#75757580"
            fontSize="13px"
            icon={isExpanded ? mdiChevronUp : mdiChevronDown}
            text="Invoice delivery"
            iconPosition="left"
          />
        </div>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ marginLeft: '10px' }}>
            <GBButton
              Action={toggleShowInvoiceHistory}
              icon={mdiHistory}
              iconHoverColor="White"
              width="200px"
              iconHoverBackgroundColor="#757575"
              textColor="#757575"
              textHoverColor="#757575"
              hoverBorder="2px solid #757575"
              borderRadius="10px"
              hoverColor="#75757580"
              iconPosition="left"
              text="View invoice history"
            />
          </div>
        </div>
      </div>
      {isExpanded ? (
        <div style={{ display: 'flex', flexDirection: 'column', position:'relatitve' }}>
          {showBillingAddress ? (
          <div style={{position:'absolute', top:'200px',left:'200px',zIndex:100}}>
            <BillingAddress close={toggleShowBilling} billingAddressData={zoneInfo.attributes.BillingAddress} color={color} updateAddress={updateAddress} />
          </div>
          ): null}
          <div style={{ marginLeft: '0px' }}>
            <InputControl
              alignControl={false}
              colInfo={emailInvoiceControl}
              value={invoiceEmail}
              submitChanges={submitChanges}
              updateValue={updateInvoiceEmail}
              canEdit={true}
            />
          </div>
          <div style={{ marginLeft: '0px' }}>
            <InputControl
              alignControl={false}
              colInfo={companyNameControl}
              value={company}
              submitChanges={submitChanges}
              updateValue={updatecompany}
              canEdit={true}
            />
          </div>
          <div style={{ marginLeft: '137px', display:'flex', flexDirection:'row', alignItems:'flex-start' }}>
            <div style={{fontWeight:'bold',marginRight:'10px'}}>Bill to address: </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.address1 !==undefined ? zoneInfo.attributes.BillingAddress.address1 : ''}</div>
              <div>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.address2 !==undefined ? zoneInfo.attributes.BillingAddress.address2 : ''}</div>
              <div className="row" style={{marginLeft:'0px'}}>
                  <div style={{marginRight:'3px'}}>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.city !==undefined ? zoneInfo.attributes.BillingAddress.city : ''} {' '}</div>
                  <div style={{marginRight:'2px'}}>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.state !==undefined ? zoneInfo.attributes.BillingAddress.state : ''}{', '}</div>
                  <div>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.postal !==undefined ? zoneInfo.attributes.BillingAddress.postal : ''}</div>
              </div>
              <div>{zoneInfo.attributes.BillingAddress !== undefined && zoneInfo.attributes.BillingAddress.country !==undefined ? zoneInfo.attributes.BillingAddress.country : ''}</div>
            </div>
            <div style={{marginLeft:'100px',marginBottom:'20px'}}>
              {/* <TextButton icon={mdiPencil} text="Change" hoverColor="#75757580" Action={toggleShowBilling} /> */}
              <GBButton
              Action={toggleShowBilling}
              icon={mdiPencil}
              // iconHoverColor="White"
              width="100px"
              // iconHoverBackgroundColor="#757575"
              textColor="#757575"
              textHoverColor='#75757580'
              hoverBorder="1px solid #75757580"
              borderRadius="10px"
              hoverColor="#000000"
              iconPosition="left"
              text="Change"
            />
            </div>
            
          </div>
          {startingBalance < 0 ? (
            <div
              style={{
                width: '300px',
                margin: '10px',
                borderRadius: '10px',
                height: '100px',
                marginLeft:'60px',
                border: '2px solid #FED95B',
              }}
            >
              <div style={{ margin: '10px' }}>
                Zone credit to be applied automatically to future invoices:
              </div>
              <div style={{ margin: '10px' }}>${(startingBalance * -0.01).toFixed(2)}</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Invoices;
