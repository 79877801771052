import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Header = ({ children }) => {
  return <>{children}</>;
};

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Header;
