/* eslint-disable */
import React from 'react';
import Icon from '@mdi/react';
import { mdiDragVertical,mdiClose } from '@mdi/js';

// 11-6-2021 added code to change ID value to use dash (-) instead of underscoare(_).
//url params will be passed using field-<value> instead of field_<value>

const FormObject = ({ id, name,type,removeFormElement }) => {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '10px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #757575',
        margin: '10px',
        marginRight:'50px',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '90%',
        height: 'maxContent',
        minHeight:'50px'
      }}
    >
      <div style={{ marginLeft: '20px' }}>{name} {type==='field' ? `(${id.replace("_",'-')})` : null}</div>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',}}>
        {['h1','h2','h3','pb','rtf'].includes(type) ? (
            <Icon onClick={()=>removeFormElement(id)} style={{marginRight:'10px'}} path={mdiClose} size="20px" color="#E06055" />
        ): null}
        <Icon path={mdiDragVertical} size="30px" />
      </div>
    </div>
  );
};

export default FormObject;
