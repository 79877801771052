import React from 'react';
import { FormCard } from '../../../components/Form';
import { ProfileIcon } from '../../../components/Icon';
import { ReactComponent as Logo } from './Success.svg';

const Success = () => {
  return (
    <>
      <FormCard.Description className="mb-2 mb-4 text-left">
        Click continue to complete your account setup.
      </FormCard.Description>
      <div className="my-3">
        <Logo />
      </div>
      <h1 style={{ fontSize: '3.8rem' }}>
        <strong>Success!</strong>
      </h1>
      <FormCard.Description className="my-2 text-left" style={{ color: '#757575' }}>
        Your phone has been validated. If you want to change the phone linked to your account, will
        can do so by updating to your profile settings{' '}
        <ProfileIcon color="#757575" size={0.5} style={{ marginTop: '-5px' }} /> once you are inside
        the system.
      </FormCard.Description>
    </>
  );
};

export default Success;
