/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import diff from 'object-diff';

const AutoSave = ({ values, debounce, onSave }) => {
  const [state, setState] = useState(values);

  const handleSave = useCallback(async () => {
    const difference = diff(state, values);
    if (Object.keys(difference).length) {
      setState(values);
      onSave(values, difference);
    }
  }, [values, state, setState, onSave]);

  useEffect(() => {
    const timeout = setTimeout(handleSave, debounce);
    return () => {
      clearTimeout(timeout);
    };
  }, [debounce, handleSave]);
  return null;
};

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);
