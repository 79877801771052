/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';

export const saveBookMark = async (name,tableid,tabsettings,columnsettings) => {
    const payload={name,tableid,tabsettings,columnsettings,dbfunction:'saveBookmark'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse[0].bookmarkid;
}

export const getBookmarks = async (tableid,userbookmarks=[],includeAll=false) => {
    const payload={tableid,userbookmarks,includeAll}
    const dataresponse = await cloudrun.post('/getBookmarks', {payload})
    return dataresponse
}

export const getBookmark = async (bookmarkid) =>{
    const payload={bookmarkid,dbfunction:'getBookmark'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

export const getBookmarkByName = async (tableid, bookmarkName) => {
    const payload={tableid,bookmarkName,dbfunction:'getBookmarkByName'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

// Initial method created when the only thing we could update is the name of bookmark and 
// the public/privite/is public default.
export const updateBookmark = async (name,bookmarkid,ispublic,isdefault,tableid) => {
    const payload={name,bookmarkid,ispublic,isdefault,tableid,dbfunction:'updateBookmark'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

//10-4-22 Added this method when we added logic to allow user to udpate the contents of the bookmark
//including the filters and column settings.
export const updateBookmarkContent = async (bookmarkid, name, tabsettings,columnsettings ) =>{
    const payload={name,bookmarkid,tabsettings,columnsettings,dbfunction:'updateBookmarkContent'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse;
}

export const deleteBookmark = async (bookmarkid,tableid) => {
    const payload={bookmarkid,tableid,dbfunction:'deleteBookmark'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}



export const renameBookmark = (bookmarkId, name) => {

}