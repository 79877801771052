/* eslint-disable no-unused-vars */
const LetterColors = [
    { color: '#E53935', letter: 'a' },
    { color: '#455A64', letter: 'b' },
    { color: '#7C4DFF', letter: 'c' },
    { color: '#FBC02D', letter: 'd' },
    { color: '#689F38', letter: 'e' },
    { color: '#00796B', letter: 'f' },
    { color: '#0288D1', letter: 'g' },
    { color: '#C2185B', letter: 'h' },
    { color: '#7B1FA2', letter: 'i' },
    { color: '#304FFE', letter: 'j' },
    { color: '#0097A7', letter: 'k' },
    { color: '#FF6F00', letter: 'l' },
    { color: '#6D4C41', letter: 'm' },
    { color: '#FFD600', letter: 'n' },
    { color: '#AEEA00', letter: '0' },
    { color: '#880E4F', letter: 'p' },
    { color: '#AA00FF', letter: 'q' },
    { color: '#1A237E', letter: 'r' },
    { color: '#00B8D4', letter: 's' },
    { color: '#004D40', letter: 't' },
    { color: '#F9A825', letter: 'u' },
    { color: '#D50000', letter: 'v' },
    { color: '#FF4081', letter: 'w' },
    { color: '#D500F9', letter: 'x' },
    { color: '#2196F3', letter: 'y' },
    { color: '#006064', letter: 'z' },
  ];

  export default LetterColors